/* eslint-disable jsx-a11y/label-has-associated-control */
import React from "react";
import { Input, Flex } from "@chakra-ui/react";

import useLang from "hooks/useLang";
import { inputStyle } from "theme/commonStyles";

import { string, func } from "prop-types";

const ProgramName = ({ enName, esName, setName }) => {
  const { t } = useLang();
  return (
    <Flex flexDirection="column" gridGap={2}>
      <label htmlFor="english_name">
        {t("en_program_name")}
        <Input
          variant="filled"
          value={enName}
          onChange={({ target }) => setName((prev) => ({ ...prev, en: target.value }))}
          id="english_name"
          name="english_name"
        />
      </label>

      <label htmlFor="spanish_name">
        {t("es_program_name")}
        <Input
          variant="filled"
          value={esName}
          onChange={({ target }) => setName((prev) => ({ ...prev, es: target.value }))}
          id="spanish_name"
          name="spanish_name"
        />
      </label>
    </Flex>
  );
};

ProgramName.propTypes = {
  enName: string.isRequired,
  esName: string.isRequired,
  setName: func.isRequired,
};

export default ProgramName;
