/* eslint-disable no-return-assign */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-children-prop */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  IconButton,
  Image,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Button,
} from "@chakra-ui/react";

import DeleteIcon from "@material-ui/icons/Delete";

import notFound from "assets/7.png";
import useLang from "hooks/useLang";
import hasFilterValue from "helpers/hasFilterValue";
import { useProgramContext } from "context/ProgramContext";
import { deletePreprogrammed, getPreprogrammed } from "services/preProgrammed";
import { deleteTemplate } from "services/template";
import styles from "./List.module.css";

const List = ({ options, name, data, handleDeleteProgram }) => {
  const { page, handlePageTypeChange, handleCurrentRecord, currentData, toggleIsLoading } =
    useProgramContext();
  const { lang, t } = useLang();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [recordsPerPage] = useState(5);
  const { role } = useSelector(({ user }) => user);

  return (
    <>
      {data && (
        <div className={styles.container}>
          <ul>
            {data.map((item) => (
              <>
                <li
                  key={item._id}
                  aria-hidden="true"
                  id={`${page.mode}/${page.owner}`}
                  style={{
                    justifyContent: "space-between",
                  }}
                  className={`${styles.listItem} ${
                    item?._id === currentData?._id && styles.active
                  }`}
                  onClick={(e) => {
                    handlePageTypeChange(e);
                    handleCurrentRecord(item);
                  }}>
                  {page.owner === "admin" && role === "admin" && (
                    <Button
                      size="sm"
                      mb={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCurrentRecord(item);
                        onOpen();
                      }}
                      variant="solid"
                      p={0}
                      bg="transparent"
                      _hover={{ bg: "none" }}>
                      <DeleteIcon color="white" />
                    </Button>
                  )}
                  {page.owner !== "admin" && role === "trainer" && (
                    <Button
                      size="sm"
                      mb={2}
                      onClick={(e) => {
                        e.stopPropagation();
                        handleCurrentRecord(item);
                        onOpen();
                      }}
                      variant="solid"
                      p={0}
                      bg="transparent"
                      _hover={{ bg: "none" }}>
                      <DeleteIcon color="white" />
                    </Button>
                  )}
                  <span>{item?.workout_name[lang]}</span>
                </li>
              </>
            ))}
          </ul>
          {data.length <= 0 && (
            <div className={styles.notFound}>
              <Image src={notFound} />
              <p>{t("not_fount_programs")}</p>
            </div>
          )}
        </div>
      )}
      {isOpen && (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>{t("delete_routine")}</AlertDialogHeader>
              <AlertDialogBody>{t("delete_confirmation")}</AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={onClose}>{t("cancel")}</Button>
                <Button
                  colorScheme="red"
                  onClick={() => {
                    handleDeleteProgram(currentData._id);
                    onClose();
                  }}
                  ml={3}>
                  {t("delete")}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </>
  );
};

export default List;
