/* eslint-disable consistent-return */
import jwtDecode from "jwt-decode";

import { addInfo } from "reducers/user.reducer";
import { getTrainerByUserId } from "services/trainer";
import { getLoggedUser } from "services/user";
import notificationDisplay from "utils/notificationDisplay";

const reLoggedUser = async (token, dispatch, auth, history, callback) => {
  /* const { from } = { from: { pathname: "/user/dashboard" } };
  const { exp } = jwtDecode(token);
  const expirationTime = exp * 1000 - 60000;
  if (Date.now() >= expirationTime) return localStorage.removeItem("userAuth"); */
  try {
    const response = await getLoggedUser();
    if (response.data) throw new Error(response.data.error);
    const trainerInfo = await getTrainerByUserId(response._id);
    if (response.role === "admin" || (response && trainerInfo)) {
      const responseToSend = {
        token,
        ...response,
        ...trainerInfo,
        id_user: response._id,
        membership: response.membership,
      };
      dispatch(addInfo(responseToSend));
      /*  auth.signin(() => {
          history.replace(from);
        }); */
      callback();
    }
  } catch (error) {
    notificationDisplay(dispatch, error.message, "error");
  }
};

export default reLoggedUser;
