/* eslint-disable import/prefer-default-export */
import FoodsBeef from "../../../assets/FoodList/ico_res.svg";
import FoodsChicken from "../../../assets/FoodList/ico_pollo.svg";
import FoodsFish from "../../../assets/FoodList/ico pescado.svg";
import FoodsVegetables from "../../../assets/FoodList/ico vegetales.svg";
import FoodFruis from "../../../assets/FoodList/ico_frutas.svg";
import FoodsDairy from "../../../assets/FoodList/ico lacteos.svg";
import FoodsTurkey from "../../../assets/FoodList/ico_pavo.svg";
import FoodsPig from "../../../assets/FoodList/ico_cerdo.svg";
import FoodSoja from "../../../assets/FoodList/ico_soja.svg";
import FoodSeafood from "../../../assets/FoodList/ico_mariscos.svg";
import FoodsGluten from "../../../assets/FoodList/ico gluten.svg";

export const foodListInclude = [
    {
        id: 1,
        item: "foods.beef",
        image: FoodsBeef
    },
    {
        id: 2,
        item: "foods.pig",
        image: FoodsPig
    },
    {
        id: 3,
        item: "foods.turkey",
        image: FoodsTurkey
    },
    {
        id: 4,
        item: "foods.chicken",
        image: FoodsChicken
    },
    {
        id: 5,
        item: "foods.fish",
        image: FoodsFish
    },
    {
        id: 6,
        item: "foods.vegetables",
        image: FoodsVegetables
    },
    {
        id: 7,
        item: "foods.fruits",
        image: FoodFruis
    },
    {
        id: 8,
        item: "foods.dairy",
        image: FoodsDairy
    },
];


export const foodListAvoid = [
    {
        id: 1,
        item: "foods.beef",
        image: FoodsBeef
    },
    {
        id: 2,
        item: "foods.pig",
        image: FoodsPig
    },
    {
        id: 3,
        item: "foods.turkey",
        image: FoodsTurkey
    },
    {
        id: 5,
        item: "foods.chicken",
        image: FoodsChicken
    },
    {
        id: 6,
        item: "foods.seafood",
        image: FoodSeafood
    },
    {
        id: 7,
        item: "foods.gluten",
        image: FoodsGluten
    },
    {
        id: 8,
        item: "foods.soy",
        image: FoodSoja
    },
    {
        id: 9,
        item: "foods.dairy",
        image: FoodsDairy
    },
];
