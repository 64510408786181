/* eslint-disable eqeqeq */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex, columns) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default reorder;

export const reorderColumns = ({ columnsMap, source, destination }) => {
  const copyColumns = [...columnsMap]
  /// const fourthSubarray = copyColumns.splice(source.index, 1);
  /// copyColumns.unshift(fourthSubarray[destination.index]);

  copyColumns.splice(1, 0, copyColumns.splice(source.index, 1)[0]);
  copyColumns.splice(destination.index, 0, copyColumns.splice(1, 1)[0]);

  const sortBoard = copyColumns?.map((arr, i) => {
    const arrMap = arr?.map((obj, j) => ({ ...obj, week: 1 + i }));
    return arrMap;
  });

  return {
    columnsMap: [ ...sortBoard ],
  };
};

export const reorderExercisesMap = ({ quoteMap, source, destination }) => {
  const sourceDay = source.droppableId.split("-")[0];
  const sourceWeek = source.droppableId.split("-")[1];
  const destinationDay = destination.droppableId.split("-")[0];
  const destinationWeek = destination.droppableId.split("-")[1];

  const columnsReduce = quoteMap.flat();
  const findDay = columnsReduce.find((item) => item.day == sourceDay && item.week == sourceWeek);
  const findNextDay = columnsReduce.find(
    (item) => item.day == destinationDay && item.week == destinationWeek,
  );
  const target = findDay.exercises[source.index];

  // move in the same list day
  if (sourceDay == destinationDay && sourceWeek == destinationWeek) {
    const reorderExercise = reorder(findDay.exercises, source.index, destination.index);
    const currentExercises = { ...findDay, exercises: reorderExercise };
    const updateDay = columnsReduce.map((item) =>
      item.day === currentExercises.day && item.week === currentExercises.week
        ? currentExercises
        : item,
    );
    // Create a copy of the updated array
    const FormatDays = [...updateDay];

    // Extract the week property from each object in the array
    const array = FormatDays?.map(({ week }) => week);

    // Remove duplicates from the array
    const newarray = [...new Set(array)];

    // Group the objects by week property
    const dataFilter = newarray?.map((item) =>
      updateDay?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );

    return {
      quoteMap: { ...dataFilter },
    };
  }

  // move to other day in the same week

  // remove from original
  findDay.exercises.splice(source.index, 1);
  // insert into next
  findNextDay.exercises.splice(destination.index, 0, target);

  const updateDay = columnsReduce.map((item) =>
    item.day === findDay.day && item.week === findDay.week ? findDay : item,
  );

  const updateNewDay = updateDay.map((item) =>
    item.day === findNextDay.day && item.week === findNextDay.week ? findNextDay : item,
  );

  const cleanEmplyDays = updateNewDay.filter((item) => item.exercises.length >= 1);

  // Create a copy of the updated array
  const FormatDays = [...cleanEmplyDays];

  // Extract the week property from each object in the array
  const array = FormatDays?.map(({ week }) => week);

  // Remove duplicates from the array
  const newarray = [...new Set(array)];

  // Group the objects by week property
  const dataFilter = newarray?.map((item) =>
    cleanEmplyDays?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
  );

  const orderBoard = dataFilter.map((arr, i) => {
    const arrMap = arr.map((obj, j) => {
      const subArrMap = { ...obj, day: 1 + j, week: 1 + i };
      return subArrMap;
    });
    return arrMap;
  });

  const cleanWeeks = orderBoard.filter((week) => week.length > 0);

  return {
    quoteMap: { ...cleanWeeks },
  };
};

export const reorderQuoteMap = ({
  quoteMap, source, destination,
}) => {
  const current = [...quoteMap[source.droppableId]];
  const next = [...quoteMap[destination.droppableId]];
  const target = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);
    const result = {
      ...quoteMap,
      [source.droppableId]: reordered,
    };
    let toArrayResult = [];
    for (let i in result) {
      toArrayResult.push(result[i]);
    }

    const orderBoard = toArrayResult.map((arr, i) => {
      const arrMap = arr.map((obj, j) => {
        const subArrMap = { ...obj, day: (1 + j), week: (1 + i) };
        return subArrMap;
      });
      return arrMap;
    });
    const arrayOfObjects = orderBoard.reduce((acc, val) => acc.concat(val), []);

    return {
      quoteMap: { ...orderBoard },
    };
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  const result = {
    ...quoteMap,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };

  let toArrayResult = [];
  for (let i in result) {
    toArrayResult.push(result[i]);
  }

  const orderBoard = toArrayResult.map((arr, i) => {
    const arrMap = arr.map((obj, j) => {
      const subArrMap = { ...obj, day: 1 + j, week: 1 + i };
      return subArrMap;
    });
    return arrMap;
  });
  const cleanWeeksEmply = orderBoard.filter((week) => week.length > 0);

  const orderBoardFinal = cleanWeeksEmply.map((arr, i) => {
    const arrMap = arr.map((obj, j) => {
      const subArrMap = { ...obj, day: 1 + j, week: 1 + i };
      return subArrMap;
    });
    return arrMap;
  });

  return {
    quoteMap: { ...orderBoardFinal },
  };
};

export function moveBetween({
  list1, list2, source, destination,
}) {
  const newFirst = Array.from(list1.values);
  const newSecond = Array.from(list2.values);

  const moveFrom = source.droppableId === list1.id ? newFirst : newSecond;
  const moveTo = moveFrom === newFirst ? newSecond : newFirst;

  const [moved] = moveFrom.splice(source.index, 1);
  moveTo.splice(destination.index, 0, moved);

  return {
    list1: {
      ...list1,
      values: newFirst,
    },
    list2: {
      ...list2,
      values: newSecond,
    },
  };
}
