/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from "react";
import { bool, func, shape, string } from "prop-types";
import useLang from "hooks/useLang";
import {
  Box,
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  useDisclosure,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Text,
} from "@chakra-ui/react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import DownloadIcon from "@mui/icons-material/Download";
import { infoStyle } from 'theme/commonStyles';
import CopyIcon from "@material-ui/icons/FileCopy";
import { deleteRoutine } from "services/customWorkout";
import { useSelector } from "react-redux";
import useDelete from "hooks/useDelete";
import DeleteAlert from "components/common/DeleteAlert";
import WorkOutNewPdf from "utils/workOutNewPdf";
import {
  root,
  container,
  title,
  subtitle,
  text,
  active,
  manuButon,
  text_create,
} from "./RoutineCard.module.css";
import ClientModalCopy from "../ClientModalCopy";
import ModalCreatePreprogram from "../ClientModals/ModalCreatePreprogram";
import ModalEditRoutine from "../ClientModals/ModalEditRoutine";

const RoutineCard = ({
  isActive,
  name,
  sub,
  phase,
  createdDate,
  handleClick,
  toggleEdit,
  routineId,
  programData,
  handleEdit,
  getRoutines,
  queryClient,
  setProgramData,
}) => {
  const { t } = useLang();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isOpenClone, onOpen: onOpenClone, onClose: onCloseClone } = useDisclosure();
  const { isOpen: isOpenCreate, onOpen: onOpenCreate, onClose: onCloseCreate } = useDisclosure();
  const { isOpen: isOpenEdit, onOpen: onOpenEdit, onClose: onCloseEdit } = useDisclosure();
  const datatrainer = useSelector(({ user }) => user);
  const handleCancelClone = () => onCloseClone();

  const handleDeleteProgram = async () => {
    await deleteRoutine(routineId)
    getRoutines();
    queryClient.invalidateQueries("routines");
  }

  return (
    // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
    <article className={root}>
      <Menu margin={0}>
        <MenuButton
          as={IconButton}
          aria-label="Options"
          icon={<MoreVertIcon />}
          background="transparent"
          color={isActive ? "white" : "#b42519"}
          className={manuButon}
          _hover="none"
          _active="none"
        />
        <MenuList>
          <MenuItem icon={<EditIcon />} onClick={() => onOpenEdit()}>
            {t("rename_program")}
          </MenuItem>
          <MenuItem icon={<SaveIcon />} onClick={() => onOpenCreate()}>
            {t("create_preprogrammed")}
          </MenuItem>
          <MenuItem onClick={() => onOpen()} color="red" icon={<DeleteIcon />}>
            {t("delete")}
          </MenuItem>
        </MenuList>
      </Menu>
      <div className={`${container} ${isActive && active}`}>
        <div onClick={handleClick}>
          <h4 className={title}>{name}</h4>
          <Text fontSize="xs" className={subtitle}>
            {t("type")} {sub}
          </Text>
          <Text fontSize="xs" className={text}>{`${t("phase_title")} ${phase}`}</Text>
          <Text fontSize="xs" className={text_create}>
            {t("created_at")} {`${createdDate}`}
          </Text>
        </div>
      </div>
      {isOpenEdit && (
        <ModalEditRoutine
          isOpen={isOpenEdit}
          onClose={onCloseEdit}
          data={programData}
          handleEdit={handleEdit}
        />
      )}
      {isOpen && (
        <AlertDialog isOpen={isOpen} onClose={onClose}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>{t('delete_routine')}</AlertDialogHeader>
              <AlertDialogBody>{t("delete_confirmation")}</AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={onClose}>{t("cancel")}</Button>
                <Button colorScheme="red" onClick={handleDeleteProgram} ml={3}>
                  {t("delete")}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
      {isOpenCreate && (
        <ModalCreatePreprogram
          isOpen={isOpenCreate}
          onOpen={onOpenCreate}
          onClose={() => onCloseCreate()}
          createPreprogram={programData?.sessions}
        />
      )}
    </article>
  );
};
RoutineCard.defaultProps = {
  isActive: false,
  phase: "1",
};

RoutineCard.propTypes = {
  isActive: bool,
  phase: string,
  name: string.isRequired,
  sub: string.isRequired,
  createdDate: string.isRequired,
  handleClick: func.isRequired,
  toggleEdit: func.isRequired,
  routineId: string.isRequired,
  programData: shape({}).isRequired,
  handleEdit: func.isRequired,
};

export default RoutineCard;
