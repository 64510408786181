/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { shape } from "prop-types";
import useLang from "hooks/useLang";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import { superSetColorsHex } from "theme/commonStyles";

const styles = StyleSheet.create({
  container: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: 8,
  },
  excersiseTitle: {
    display: "flex",
    flexDirection: "row",
    color: "black",
    marginBottom: 3,
  },
  exerciseTitleSuperSet: {
    paddingHorizontal: 5,
  },
  list: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "75%",
  },
  numberSet: {
    marginRight: 3,
    width: 100,
  },
  set: {
    marginLeft: 50,
    fontWeight: "bold",
    color: "red",
  },
});

const ExerciseDisplayPdf = ({ exercise }) => {
  const { lang, t } = useLang();
  return (
    <View style={styles.container}>
      {exercise.colors.hard !== "white" ? (
        <View
          style={{
            backgroundColor: `${superSetColorsHex[exercise.colors.hard]}`,
            borderRadius: 15,
            marginBottom: 4,
            padding: 3.5,
            color: "black",
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
          }}>
          <View style={{ width: "25%" }}>
            <Text>{exercise?.id_exercise?.name[lang]}</Text>
            <View style={{ fontSize: 6, paddingHorizontal: 5 }}>
              <Text>
                {t("notes_exercise")}: {exercise?.notes_exercise?.[lang]}
              </Text>
            </View>
          </View>
          <View style={styles.list}>
            {exercise?.sets?.map((s) => (
              <Text style={styles.numberSet}>
                {"    "}
                <Text style={styles.set}>Set {s?.set_number} </Text>{" "}
                {s?.load?.trim() === "0" || s?.load?.trim() === ""
                  ? "     "
                  : `${s?.load} ${s?.typeWeight === "others" ? "" : s?.typeWeight}`}
                /
                {s?.reps?.trim() === "0" || s?.reps?.trim() === ""
                  ? "     "
                  : `${s?.reps} ${s?.typeReps ?? ""}`}
              </Text>
            ))}
          </View>
        </View>
      ) : (
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            width: "100%",
            marginBottom: 4,
            padding: 3.5,
          }}>
          <View style={{ width: "25%" }}>
            <Text>{exercise?.id_exercise?.name[lang]}</Text>
            <View style={{ fontSize: 6, paddingHorizontal: 5 }}>
              <Text>
                {t("notes_exercise")}: {exercise?.notes_exercise?.[lang]}
              </Text>
            </View>
          </View>
          <View style={styles.list}>
            {exercise?.sets?.map((s) => (
              <Text style={styles.numberSet}>
                {"    "}
                <Text style={styles.set}>Set {s?.set_number} </Text>{" "}
                {s?.load?.trim() === "0" || s?.load?.trim() === ""
                  ? "     "
                  : `${s?.load} ${s?.typeWeight === "others" ? "" : s?.typeWeight}`}
                /
                {s?.reps?.trim() === "0" || s?.reps?.trim() === ""
                  ? "     "
                  : `${s?.reps} ${s?.typeReps ?? ""}`}
              </Text>
            ))}
          </View>
        </View>
      )}
    </View>
  );
};
ExerciseDisplayPdf.propTypes = {
  exercise: shape({}).isRequired,
};

export default ExerciseDisplayPdf;
