/* eslint-disable react/prop-types */
import React, { useRef } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Button,
  Box,
  useDisclosure,
} from "@chakra-ui/react";
import useLang from "hooks/useLang";
import ModalSaveNutrition from "./ModalSaveNutrition";
import LoadingResponseNutrition from "./LoadingResponse";
import TypingEffect from "./TypingEffect";

const DrawerGenerateResponse = ({
  isOpen,
  onClose,
  handleTryAdain,
  loader,
  response,
  client,
  clientName,
  protein,
  fat,
  carbs,
  calories,
  selectTrainer,
  stylediet,
  setupdateListSave
}) => {
  const { isOpen: isOpenSave, onOpen: onOpenSave, onClose: onCloseSave } = useDisclosure();
  const { t } = useLang();
  const initialRef = useRef();

  return (
    <>
      <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t("nutrition")}</DrawerHeader>

          <DrawerBody>
            <Box
              display="flex"
              flexDirection="column"
              w="full"
              bg="white"
              h="100vh"
              justifyContent="space-between"
              borderRadius="15px"
              p={5}>
              <Box display="flex">
                {loader ? (
                  <LoadingResponseNutrition />
                ) : (
                  <>
                    <TypingEffect speed={10} text={response} />
                  </>
                )}
              </Box>
            </Box>
          </DrawerBody>

          {response && (
            <DrawerFooter display="flex" justifyContent="space-between" my={2}>
              <Button onClick={(e) => handleTryAdain(e)}>
                {t("nutrition_section.generate_another")}
              </Button>
              <Button
                variant="solid"
                bg="#b42519"
                border="1px solid #b42519"
                _hover={{
                  bg: "white",
                  border: "1px solid #b42519",
                  color: "#b42519",
                }}
                onClick={() => onOpenSave()}
                color="white">
                {t("save")}
              </Button>
            </DrawerFooter>
          )}
          {isOpenSave && (
            <ModalSaveNutrition
              isOpen={isOpenSave}
              onClose={onCloseSave}
              initialRef={initialRef}
              client={client}
              clientName={clientName}
              calories={calories}
              protein={protein}
              carbs={carbs}
              fat={fat}
              stylediet={stylediet}
              diet={response}
              selectTrainer={selectTrainer}
              setupdateListSave={setupdateListSave}
            />
          )}
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default DrawerGenerateResponse;
