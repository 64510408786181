// Import the necessary libraries
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

// Use the i18next plugins
i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ["en", "es", "fr"], // Specify the supported languages
    fallbackLng: localStorage.getItem("i18nextLng"), // Specify the fallback language
    detection: {
      order: ["localStorage", "htmlTag", "cookies", "path", "subdomain"], // Specify the order in which to detect the language
      cache: ["localStorage"], // Specify where to cache the language detection result
    },
    backend: {
      loadPath: "/assets/locales/{{lng}}/translation.json", // Specify the path to the translation files
    },
  });

// Export the i18n object
export default i18n;
