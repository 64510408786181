import { useQuery } from 'react-query';

const useGetAll = (word, id, func) => useQuery(word, async () => {
  try {
    const response = await func(id);
    // console.log('response in getall', response);
    // if (response.data) throw new Error(response.data.message);
    if (response.data) throw new Error('catching the problem');

    return response;
  } catch (error) {
    // console.log('error in getall', error);
    console.log('error in getall', error.message);
    throw new Error(error.message);
  }
});
export default useGetAll;
