/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  useDisclosure,
  Flex,
  HStack,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { bool, func, shape } from "prop-types";
import useLang from "hooks/useLang";
import useEditWeekValueContext from "./useEditWeekValueContext";
import EditWeekContent from "./EditWeekContent";

const EditWeekContext = createContext();

const EditWeek = ({ isOpen, onClose, AiTitle, quotes, setColumns, columns }) => {
  const { t } = useLang();
  const [boardWeeks, setBoardWeeks] = useState(quotes);
  const [openDialog, setopenDialog] = useState(false);

  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    setIsEdit(true);
    setBoardWeeks(quotes);
  }, [quotes]);

  const [values, obj] = useEditWeekValueContext(boardWeeks, isEdit);

  const handleSaveEdit = () => {
    // Flatten the array of objects
    const filterWeeks = columns.flat().filter(({ week }) => week !== AiTitle);

    // Joining both arrays
    const finalWeek = filterWeeks.concat(values);

    // Updates the training routine with the filtered columns.
    const FormatDays = [...finalWeek];

    // Extract the week property from each object in the array
    const array = FormatDays?.map(({ week }) => week);

    // Remove duplicates from the array
    const newarray = [...new Set(array)];

    newarray.sort((a, b) => a - b);
    // Group the objects by week property
    const dataFilter = newarray?.map((item) =>
      finalWeek?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );

    // Update the state variable and close the modal
    setColumns(dataFilter);

    // Close the modal
    onClose();
  };

  const handleConfirmClose = () => {
    if (quotes !== values) {
      setopenDialog(true);
      return;
    }
    onClose();
    setIsEdit(false);
    setBoardWeeks([]);
  };

  const showButton = values.map((day) => day.exercises.length > 0).every(Boolean);

  return (
    <EditWeekContext.Provider
      value={{
        ...obj,
      }}>
      <Modal
        isOpen={isOpen}
        closeOnOverlayClick={false}
        onClose={() => {
          handleConfirmClose();
        }}
        size="xl">
        <ModalOverlay />

        <ModalContent borderTop="13px solid #b42519" borderRadius={10}>
          <ModalHeader>
            <p>
              {t("edit_week")} <span style={{ color: "#b42519" }}>{AiTitle}</span>
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Flex overflowX="auto">
              <HStack spacing="10">
                {values.map((day) => (
                  <Box width="520px" height="600px" overflowY="scroll" padding="0px .5rem">
                    <EditWeekContent
                      inicialValue={day}
                      date={{ day: day.day, week: day.week }}
                      isEdit={isEdit}
                      key={day.day}
                    />
                  </Box>
                ))}
              </HStack>
            </Flex>
            {showButton && (
              <Button
                type="submit"
                bg="#6bca21"
                color="white"
                _hover={{ bg: "#6bca21" }}
                float="right"
                onClick={() => handleSaveEdit()}>
                {t("save")}
              </Button>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
      {openDialog && (
        <AlertDialog isOpen={openDialog} onClose={() => setopenDialog(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
                {t("delete_confirmation")}
              </AlertDialogHeader>
              <AlertDialogBody>{t("confirm_save_or_discard")}</AlertDialogBody>
              <AlertDialogFooter>
                <Box
                  display="flex"
                  flexDirection="column"
                  w="full"
                  gridGap={2}
                  justifyContent="center"
                  alignItems="center">
                  <Button w="70%" border="1px solid #b42519" onClick={() => setopenDialog(false)}>
                    {t("backBtn")}
                  </Button>
                  <Button
                    w="70%"
                    bg="#b42519"
                    color="white"
                    _hover={{ bg: "white", border: "1px solid #b42519", color: "black" }}
                    onClick={() => onClose()}>
                    {t("discard")}
                  </Button>
                  <Button
                    w="70%"
                    bg="#6bca21"
                    color="white"
                    _hover={{ bg: "#6bca21" }}
                    onClick={() => handleSaveEdit()}>
                    {t("save")}
                  </Button>
                </Box>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </EditWeekContext.Provider>
  );
};

const useEditWeekContext = () => useContext(EditWeekContext);
export default EditWeek;

export { useEditWeekContext };
