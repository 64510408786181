/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  IconButton,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  useDisclosure,
} from "@chakra-ui/react";
import { bool, func, number, shape } from "prop-types";
import { useSelector } from "react-redux";
import ViewListIcon from "@mui/icons-material/ViewList";
import AddNewExerciseModal from "components/routines/AddNewExerciseModal";
import GridViewIcon from "@mui/icons-material/GridView";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import useLang from "hooks/useLang";
import useGetAll from "hooks/useGetAll";
import { getTrainerExercises } from "services/exercise";

import MoreProgramsExercises from "components/programs/MoreProgramsExercises";
import { Search2Icon, AddIcon } from "@chakra-ui/icons";
import ErrorBoundary from "components/common/ErrorBoundary";
import ExerciseCard from "components/exercises/exerciseCard";

const ExerciseListModal = ({ isOpen, onClose, filters, changeExercise }) => {
  const { id_user } = useSelector(({ user }) => user);
  const { isLoading, isError, isSuccess, data } = useGetAll("exercises", null, () =>
    getTrainerExercises(id_user),
  );
  const { t, lang } = useLang();
  const [displayMode, setDisplayMode] = useState("grid");
  const { isOpen: isOpenContent, onOpen: onOpenContent, onClose: onCloseContent } = useDisclosure();
  const [search, setSearch] = useState("");
  const { isOpen: isOpenAddNew, onOpen: onOpenAddNew, onClose: onCloseAddNew } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const [nPages, setNPages] = useState(0);

  const dataFiltered = data?.filter((item) => {
    if (filters.action === "") return item;
    const itemKeys = Object.keys(item);

    const actionProp = itemKeys.find((string) => string === "action");
    const muscleProp = itemKeys.find((string) => string === "muscle_system");
    const categoryProp = itemKeys.find((string) => string === "category_id");

    const hasAction =
      filters.action.toLowerCase() === "all" ? true : item[actionProp]?.includes(`opt_${filters.action.toLowerCase()}`);
    const hasMuscle =
      filters.muscle.toLowerCase() === "all" ? true : item[muscleProp]?.includes(`opt_${filters.muscle.toLowerCase()}`);
    const hasCategory = item[categoryProp][0]?.name === filters.category;

    return hasAction && hasMuscle && hasCategory;
  });

  useEffect(() => {
    if (data) setNPages(Math.ceil(data.length / recordsPerPage));
  }, [isSuccess]);

  const handleEvent = (item) => {
    changeExercise(item);
    onClose();
  };
  const changeFilters = ({ name }) => {
    setSearch(name);
    const { length } = dataFiltered.filter((a) =>
      a.name[lang]?.toLowerCase().includes(name?.toLowerCase()),
    );
    setNPages(Math.ceil(length / recordsPerPage));
    setCurrentPage(1);
  };

  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) setCurrentPage(nPages);
  };
  return (
    <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent borderTop="15px solid #b42519" borderRadius={10}>
        <ModalHeader>
          {t("choose_exercise")}{" "}
          {filters.category && filters.category !== "" ? `- ${filters.category}` : ""}
        </ModalHeader>
        <ModalCloseButton />
        {isLoading && <p>Loading data</p>}
        {isError && <p>There is an error</p>}
        {isSuccess && (
          <>
            <ModalBody>
              <ErrorBoundary>
                <Flex align="center" justify="space-between" w="100%" mb="1rem" gridGap={4}>
                  <InputGroup w="50%">
                    <InputLeftElement pointerEvents="none">
                      <Search2Icon color="gray.600" />
                    </InputLeftElement>
                    <Input
                      variant="outline"
                      type="text"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) =>
                        changeFilters({
                          name: e.target.value,
                        })
                      }
                    />
                  </InputGroup>
                  <div>
                    <IconButton
                      isRound
                      variant="ghost"
                      colorScheme="blackAlpha"
                      aria-label="Done"
                      fontSize="20px"
                      icon={displayMode === "grid" ? <GridViewIcon /> : <ViewListIcon />}
                      onClick={() =>
                        setDisplayMode((prev) => {
                          localStorage.setItem("displayMode", prev === "grid" ? "list" : "grid");
                          return prev === "grid" ? "list" : "grid";
                        })
                      }
                    />
                    <Button
                      ml={3}
                      rightIcon={<AddIcon />}
                      colorScheme="ptRed"
                      onClick={() => onOpenAddNew()}>
                      {t("add_exercise")}
                    </Button>
                  </div>
                </Flex>
                <SimpleGrid
                  columns={displayMode === "grid" ? [1, 2, 3, 4] : 1}
                  spacing={4}
                  w="100%">
                  {dataFiltered
                    .filter((a) => a.name[lang]?.toLowerCase().includes(search.toLowerCase()))
                    .slice(indexOfFirstRecord, indexOfLastRecord)
                    .map((exercise) => (
                      <div aria-hidden="true">
                        <ExerciseCard
                          isSelect
                          selectExercise={handleEvent}
                          exercise={exercise}
                          key={exercise._id}
                          displayMode={displayMode}
                        />
                      </div>
                    ))}
                </SimpleGrid>
                <nav>
                  <ul className="pagination">
                    <li>
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="blackAlpha"
                        aria-label="Done"
                        fontSize="20px"
                        disabled={currentPage === 1}
                        icon={<FirstPageIcon />}
                        onClick={goToFirstPage}
                      />
                    </li>
                    <li>
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="blackAlpha"
                        aria-label="Done"
                        fontSize="20px"
                        icon={<ArrowBackIosIcon />}
                        disabled={currentPage === 1}
                        onClick={goToPrevPage}
                      />
                    </li>
                    <li>
                      {search !== "" ? 1 : currentPage}/
                      {filters.action === ""
                        ? nPages
                        : Math.ceil(dataFiltered.length / recordsPerPage)}
                    </li>
                    <li>
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="blackAlpha"
                        aria-label="Done"
                        fontSize="20px"
                        disabled={
                          currentPage >=
                          (filters.action === ""
                            ? nPages
                            : Math.ceil(dataFiltered.length / recordsPerPage))
                        }
                        icon={<ArrowForwardIosIcon />}
                        onClick={goToNextPage}
                      />
                    </li>{" "}
                    <li>
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="blackAlpha"
                        aria-label="Done"
                        fontSize="20px"
                        disabled={
                          currentPage >=
                          (filters.action === ""
                            ? nPages
                            : Math.ceil(dataFiltered.length / recordsPerPage))
                        }
                        icon={<LastPageIcon />}
                        onClick={goToLastPage}
                      />
                    </li>
                  </ul>
                </nav>
              </ErrorBoundary>
            </ModalBody>
            {isOpenAddNew && (
              <AddNewExerciseModal
                onCloseMain={onClose}
                selectExercise={handleEvent}
                isOpen={isOpenAddNew}
                onClose={onCloseAddNew}
              />
            )}
            <ModalFooter>
              <Button colorScheme="ptRed" mr={3} onClick={onClose}>
                {t("close")}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ExerciseListModal;
