/* eslint-disable no-unused-vars */
import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  IconButton,
} from "@chakra-ui/react";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import { useSelector } from "react-redux";
import propTypes from "prop-types";

import AddWord from "./AddWord";
import Info from "./Info";
import Edit from "./Edit";
import styles from "./Popup.module.scss";

const Popup = ({ onClose, isOpen, title, mode, other, switchMode, category }) => {
  const { role } = useSelector(({ user }) => user);
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader className={styles.header}>
          {title}{" "}
          {role === "admin" && mode === "info" && (
            <IconButton
              variant="link"
              icon={<EditIcon />}
              onClick={() => switchMode(other, "edit")}
              fontSize="20px"
            />
          )}
          {mode === "edit" && (
            <IconButton
              variant="link"
              icon={<ViewIcon />}
              onClick={() => switchMode(other, "info")}
              fontSize="20px"
            />
          )}
        </ModalHeader>
        <ModalCloseButton style={{ color: "#ececec" }} />
        <ModalBody className={styles.body}>
          {mode === "add" && <AddWord close={onClose} category={category} />}
          {mode === "info" && <Info help={other} />}
          {mode === "edit" && <Edit help={other} close={onClose} category={category} />}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

Popup.defaultProps = {
  other: [],
};

Popup.propTypes = {
  onClose: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  title: propTypes.string.isRequired,
  mode: propTypes.string.isRequired,
  other: propTypes.shape([]),
  switchMode: propTypes.func.isRequired,
  category: propTypes.string.isRequired,
};

export default Popup;
