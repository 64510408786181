/* eslint-disable no-constant-condition */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import useLang from "hooks/useLang";
import notificationDisplay from "utils/notificationDisplay";
import Select from "react-select";
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import { createNutrition, createStylesDiets } from "services/nutrition";

const ModalSaveNutrition = ({
  onClose,
  isOpen,
  initialRef,
  client,
  diet,
  clientName,
  protein,
  calories,
  carbs,
  fat,
  selectTrainer,
  stylediet,
  setupdateListSave
}) => {
  const { t } = useLang();
  const { id_user } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const [data, setData] = useState({
    name: "",
    goal: "",
  });

  const goslClient = [
    {
      goal: "weight_loss",
    },
    {
      goal: "muscle_gain",
    },
    {
      goal: "maintenance",
    },
  ];

  const options = goslClient?.map(({ goal }) => {
    const returnOption = {
      value: goal,
      label: t(goal),
    };
    return returnOption;
  });

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = async () => {
    const arrDiets = [
      "Paleo",
      "Keto",
      "Vegana",
      "Mediterranea",
      "Dieta de zona",
      "Alta en proteina",
      ""
    ];
    if (!arrDiets.includes(stylediet)) {
      const sendStyleDiet = {
        name: stylediet,
        carb: carbs,
        fat,
        protein,
        id_trainer: id_user
      }
      await createStylesDiets(sendStyleDiet)
    }

    const sendData = {
      name: data.name,
      goals: data.goal,
      id_trainer: id_user,
      id_client: selectTrainer ? null : client,
      diet,
    };

    try {
      const response = await createNutrition(sendData);
      setupdateListSave((prev) => !prev)
      if (response.data) return notificationDisplay(dispatch, t("something_wrong"), "error");
      notificationDisplay(dispatch, t("succesful_addition", { data: t("programs") }), "success");
    } catch (error) {
      console.error(error);
    }
    onClose();
  };

  return (
    <Modal initialFocusRef={initialRef} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("nutrition")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={4}>
          <FormControl>
            <FormLabel>
              {t("nutrition_section.name_diet")}
              <p style={{ fontSize: "13px" }}>
                (Recomend: {clientName} {calories} cal - P:{protein}% - C: {carbs}% - G:{fat}%)
              </p>
            </FormLabel>
            <Input
              ref={initialRef}
              placeholder={t("nutrition_section.name_diet")}
              name="name"
              onChange={onChange}
            />
          </FormControl>

          <Box mt={4}>
            <FormLabel>{t("nutrition_section.main_objective_diet")}</FormLabel>
            <Select
              placeholder={t("table_select")}
              options={options}
              onChange={(e) => setData({ ...data, goal: e.value })}
            />
          </Box>
        </ModalBody>

        <ModalFooter>
          <Button colorScheme="green" mr={3} onClick={() => handleSave()}>
            {t("save")}
          </Button>
          <Button onClick={onClose}>{t("cancel")}</Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalSaveNutrition;
