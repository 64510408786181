/* eslint-disable import/prefer-default-export */
import instance from "./_axiosConfig";

const api = instance;

const getAllCategories = async () => {
  try {
    const res = await api.get("/category/");
    return res.data.categories;
  } catch (error) {
    return error.message;
  }
};

export { getAllCategories };
