/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { Box, Button, useDisclosure } from "@chakra-ui/react";
import DeleteAlert from "components/common/DeleteAlert";
import useLang from "hooks/useLang";
import useDelete from "hooks/useDelete";
import { deleteRoutine } from "services/customWorkout";
import AlternativeBoard from "components/routines/dnd/AlternativeBoard";
import AddIcon from "@mui/icons-material/Add";
import AddDay from "components/routines/dnd/board/AddDay/AddDay";
import { box, empty } from "./ClientRoutineDisplay.module.css";
import ModalCreatePreprogram from "../ClientModals/ModalCreatePreprogram";

const ClientRoutineDisplay = ({
  children,
  days,
  handleUpdateDnd,
  routineId,
  clientInfo,
  nameWorkout,
  sharedFilter,
  updateSharedFilter,
  handleDeleteProgram,
  setShowRenderPdf,
  showRenderPdf,
  setcopyAlertData,
  setProgramData,
  allDataCurrentProgram,
  verifyBetween,
  setVerifyBetween
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useLang();
  const { isOpen: isOpenCreate, onOpen: onOpenCreate, onClose: onCloseCreate } = useDisclosure();
  const { isOpen: isOpenDay, onOpen: onOpenDay, onClose: onCloseDay } = useDisclosure();
  const [createPreprogram, setCreatePreprogram] = useState([]);
  const handleClose = () => setDialogOpen(false);

  useEffect(() => {
    if (days === undefined) {
      return (
        <div className={`${box} ${empty}`}>
          <p>{t("select_program")}</p>
        </div>
      );
    }

    if (days.length <= 0) {
      return (
        <div className={`${box} ${empty}`}>
          <p>No existing data</p>
          <Box>
            <Button
              backgroundColor="white"
              color="#b42519"
              _hover={{ backgroundColor: "#b42519", color: "white" }}
              variant="outline"
              borderRadius="full"
              p="2em"
              width="320px"
              mt={8}
              onClick={() => onOpenDay()}>
              <AddIcon />
              {t("create_firts_day")}
            </Button>
          </Box>
          {isOpenDay && (
            <AddDay
              isOpen={isOpenDay}
              onClose={onCloseDay}
              key={isOpenDay}
              routineId={routineId}
              weeks={days}
              handleSubmitCopySameProgram={handleUpdateDnd}
            />
          )}
        </div>
      );
    }
  }, []);

  return (
    <div className={box}>
      {days && (
        <AlternativeBoard
          preInfo={days}
          key={routineId}
          clientInfo={clientInfo}
          setVerifyBetween={setVerifyBetween}
          routineId={routineId}
          nameWorkout={nameWorkout}
          sharedFilter={sharedFilter}
          updateSharedFilter={updateSharedFilter}
          verifyBetween={verifyBetween}
          setCreatePreprogram={setCreatePreprogram}
          setShowRenderPdf={setShowRenderPdf}
          showRenderPdf={showRenderPdf}
          onOpenCreate={onOpenCreate}
          setcopyAlertData={setcopyAlertData}
          handleUpdateDnd={handleUpdateDnd}
          setProgramData={setProgramData}
          allDataCurrentProgram={allDataCurrentProgram}>
          {children}
        </AlternativeBoard>
      )}
      {isOpenCreate && (
        <ModalCreatePreprogram
          isOpen={isOpenCreate}
          onClose={() => {
            onCloseCreate();
          }}
          createPreprogram={createPreprogram}
        />
      )}
      {dialogOpen && (
        <DeleteAlert
          open={dialogOpen}
          close={handleClose}
          id={routineId}
          title="Delete Program"
          func={() => useDelete("routines", deleteRoutine)}
          handleDeleteProgram={handleDeleteProgram}
        />
      )}
    </div>
  );
};

export default ClientRoutineDisplay;
