/* eslint-disable react/no-this-in-sfc */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { Link } from "react-router-dom";
import {
  Button,
  Menu as MenuLang,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Avatar,
  Flex,
  Divider,
  Heading,
} from "@chakra-ui/react";
import UsaLogo from "assets/eeuu.png";
import SpainLogo from "assets/spain.png";
import useLang from "hooks/useLang";
import languages from "utils/languages";
import { STRIPE_TABLE_IDS } from "utils/stripeids";
import i18next from "i18next";
import zelle from "assets/icons/zelle.png";
import paypal from "assets/icons/paypal.svg";
import contact from "assets/icons/003-datos-de-contacto.png";
import payment from "assets/icons/002-transferencia-mvil.png";
import validation from "assets/icons/004-ordenador-porttil.png";
import access from "assets/icons/001-control-de-acceso.png";
import { sendAlternativePayment } from "services/user";
import Basic from "./Basic";
import Data from "./Data";
import { deleteInfo } from "../../../reducers/user.reducer";
import styles from "./register.module.scss";
import logo from "../../../assets/3.png";

const SignUp = ({ location, ...props }) => {
  const [steps, setSteps] = useState(location.state ? location.state.step : 1);
  /* const [steps, setSteps] = useState(3); */
  const [basic, setBasic] = useState(
    location.state
      ? { id_user: location.state.id_user }
      : JSON.parse(localStorage.getItem("user_data")) ?? {},
  );
  const [data, setData] = useState({});
  const dispatch = useDispatch();
  const currentYear = new Date().getFullYear();

  const { t, lang } = useLang();
  const onLanguageChange = (code) => {
    i18next.changeLanguage(code);
  };

  if (location.state) dispatch(deleteInfo());

  const sendNotification = async () => {
    try {
      console.log(basic, data);
      await sendAlternativePayment({
        email: basic.email,
        name: `${data.trainer.name} ${data.trainer.lastName}`,
      });
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    if (window.location.href.includes("/confirmation")) {
      const user_data = localStorage.getItem("user_data");
      const isSuccess = new URLSearchParams(props.history.location.search).get("success");
      const sessionId = new URLSearchParams(props.history.location.search).get("session_id");
      const isCanceled = new URLSearchParams(props.history.location.search).get("canceled");

      if (isCanceled === "true" && user_data) {
        // eslint-disable-next-line no-alert
        alert("La compra ha sido cancelada");
        setBasic({ id_user: user_data.id_user });
        // eslint-disable-next-line no-param-reassign
        props.history.push("/signup");
        setSteps(2);
      }

      if (isSuccess === "true") {
        console.log("sessionId", sessionId);
        localStorage.removeItem("user_data");
        setSteps(4);
      }
    }
  }, [props.history.location.pathname]);

  return (
    <section className={styles.root}>
      <aside className={styles.sideAuth} />
      <aside className={styles.formSide}>
        <div className={styles.header}>
          <MenuLang>
            <MenuButton as={Button} variant="ghost" fontSize="40px" padding={0} borderRadius="full">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  name="english lang"
                  src={lang === "en" ? UsaLogo : SpainLogo}
                  width="30px"
                  height="30px"
                />
              </Box>
            </MenuButton>
            <MenuList zIndex="101">
              {languages.map(({ code, name, countryCode }) => (
                <MenuItem key={code} color="ptText.500" onClick={() => onLanguageChange(code)}>
                  <span className={`flag-icon flag-icon-${countryCode}`} />
                  <span>{name}</span>
                </MenuItem>
              ))}
            </MenuList>
          </MenuLang>
        </div>
        <div className={styles.formContainer}>
          <img className={styles.logo} src={logo} alt="perfecttrainer logo" id="logo" />
          {steps === 1 && <Basic move={setSteps} set={setBasic} setData={setData} info={basic} />}
          {steps === 2 && <Data move={setSteps} set={setData} info={{ ...basic, ...data }} />}
          {steps === 3 && (
            <div className={styles.payments}>
              <stripe-pricing-table
                style={{ width: "90%" }}
                pricing-table-id={STRIPE_TABLE_IDS[lang]}
                publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                client-reference-id={basic.id_user}
                customer-email={basic.email}
                locale={lang}
              />
              <Divider mb={8} />
              <div
                className={styles.alternativePage}
                onClick={() => setSteps(4)}
                aria-hidden="true">
                <p>{t("alternativePay.buttonAction")}</p>
                <Flex alignItems="center" gridGap="1rem" justifyContent="center">
                  <img src={zelle} alt="zelle" />
                  <img src={paypal} alt="paypal" />
                </Flex>
              </div>
            </div>
          )}
          {steps === 4 && (
            <div>
              <div className={styles.instructionsTitle}>
                <Heading size="lg" textAlign="center" mb="1rem">
                  {t("alternativePay.instructionsTitle")}
                </Heading>
                <p>
                  <b>{t("alternativePay.importantNote")}:</b>{" "}
                  {t("alternativePay.importantNoteText")}
                </p>
              </div>

              <Flex
                m={8}
                className={styles.instructions}
                gridGap={4}
                flexDirection="column"
                flexWrap="wrap"
                justifyContent="center">
                <Flex gridGap={4} flexWrap="wrap" justifyContent="center">
                  <div className={styles.itemInstruction}>
                    <span>1</span>
                    <img src={contact} alt="icon" />
                    <b>{t("alternativePay.contact")}</b>
                    <p>
                      {t("alternativePay.contactText")}{" "}
                      <a href="https://wa.link/8cunpd" target="_blank" rel="noreferrer">
                        Whatsapp
                      </a>
                    </p>
                  </div>
                  <div className={styles.itemInstruction}>
                    <span>2</span>
                    <img src={payment} alt="icon" />
                    <b>{t("alternativePay.paymentInformation")}</b>
                    <p>{t("alternativePay.paymentInformationText")} </p>
                  </div>
                </Flex>
                <Flex gridGap={4} flexWrap="wrap" justifyContent="center">
                  <div className={styles.itemInstruction}>
                    <span>3</span>
                    <img src={validation} alt="icon" />
                    <b>{t("alternativePay.validation")}</b>
                    <p>{t("alternativePay.validationText")}</p>
                  </div>
                  <div className={styles.itemInstruction}>
                    <span>4</span>
                    <img src={access} alt="icon" />
                    <b>{t("alternativePay.access")}</b>
                    <p>{t("alternativePay.accessText")}</p>
                  </div>
                </Flex>
              </Flex>
              <Flex gridGap={4} justifyContent="center">
                <Button onClick={() => setSteps(3)} type="submit" variant="outline">
                  {t("backBtn")}
                </Button>
                <Button
                  onClick={sendNotification}
                  as={Link}
                  to="/login"
                  type="submit"
                  colorScheme="ptRed">
                  {t("letsGo")}
                </Button>
              </Flex>
            </div>
          )}
        </div>
        <div className={styles.footer}>
          <div className={styles.term}>
            <a href="#a">{t("privacy_policy")}</a>
            <a href="#a">{t("terms_use")}</a>
            <a href="#a">Legal</a>
          </div>
          <span>
            Copyright © {currentYear} The Institute Of Human Performance | {t("copyright_text")}
          </span>
        </div>
      </aside>
    </section>
  );
};

export default SignUp;
