import React from 'react';
import { Spinner } from '@chakra-ui/react';
import { root } from './Loader.module.css';

const Loader = () => (
  <div className={root}>
    <Spinner thickness="5px" color="red" style={{ width: 100, height: 100 }} />
  </div>
);

export default Loader;
