/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { Children, useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import CopyIcon from "@material-ui/icons/FileCopy";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import { createRoutine } from "services/customWorkout";
import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import useLang from "hooks/useLang";
import generateId from "helpers/generateId";
import notificationDisplay from "utils/notificationDisplay";
import Column from "./Column";
import reorder, { reorderColumns, reorderExercisesMap, reorderQuoteMap } from "./reorder";
import styles from "../../../../clients/ClientDashboard/ClientRoutineDisplay/ClientRoutineDisplay.module.css";
import Combine from "../../Preprogrammed/components/Combine";
import SuperSet from "../../SuperSet";
import RoutineInfo from "../../Features/RoutineInfo";

const Board = ({
  children,
  isCombineEnabled,
  initial,
  useClone,
  containerHeight,
  withScrollableColumns,
  routineId,
  keyRefrest,
  client,
  clientInfo,
  setFiltered,
}) => {
  const [name, setName] = useState("");
  const [phase, setPhase] = useState("");
  const [columns, setColumns] = useState(initial);
  const [ordered, setOrdered] = useState(Object.keys(initial));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { _id } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const history = useHistory();
  const [listDaySelected, setListDaySelected] = useState([]);
  const [cloneUniqueDay, setCloneUniqueDay] = useState([]);
  const [cloneState, setCloneState] = useState(false);
  const { lang, t } = useLang();
  const [sortWeeks, setSortWeeks] = useState([]);
  const { isOpen: isOpenMore, onOpen: onOpenMore, onClose: onCloseMore } = useDisclosure();
  const [superSetState, setSuperSetState] = useState({ data: [], position: 0 });
  const scrollHorizontalBoard = useRef(null);

  const handleCancelClone = () => {
    setListDaySelected([]);
    setCloneState(false);
  };

  const handleChangeSelect = (items) => () => {
    setListDaySelected([
      ...listDaySelected,
      {
        ...items,
        author: {
          ...items.author,
        },
        exercises: items.exercises.map((exercise) => ({
          ...exercise,
          _id: generateId(),
        })),
      },
    ]);
  };

  const handleChangeRemove = (item) => () => {
    setListDaySelected((items) => items.filter((i) => i._id !== item._id));
  };

  const onDragEnd = (result) => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    if (result.type === "EXERCISE") {
      const reorderExercises = reorderExercisesMap({
        quoteMap: columns,
        source,
        destination,
      });
      const arrayOfArrays = Object.values(reorderExercises.quoteMap).map((arr) => [...arr]);
      setColumns(arrayOfArrays);
      setOrdered(Object.keys(reorderExercises.quoteMap));
      return;
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      const sortColumns = reorderColumns({
        columnsMap: columns,
        source,
        destination,
      });
      setOrdered(Object.keys(sortColumns.columnsMap));
      setColumns(sortColumns.columnsMap);
      return;
    }

    const data = reorderQuoteMap({
      quoteMap: columns,
      source,
      destination,
    });

    const arrayOfArrays = Object.values(data.quoteMap).map((arr) => [...arr]);

    setColumns(arrayOfArrays);
    setOrdered(Object.keys(data.quoteMap));
  };
  const cloneWeek = (week) => {
    const arrayOfObjects = columns.reduce((acc, val) => acc.concat(val), []);
    const lastWeek = arrayOfObjects[arrayOfObjects.length - 1];
    const changeWeek = week.map((item) => {
      const copy = {
        ...item,
        week: lastWeek.week + 1,
        _id: generateId(),
        id: generateId(),
        exercises: item.exercises.map((i) => {
          const setIdExercise = {
            ...i,
            _id: generateId(),
            sets: i.sets.map((e) => {
              const newId = {
                ...e,
                _id: generateId(),
              };
              return newId;
            }),
          };
          return setIdExercise;
        }),
      };
      return copy;
    });
    console.log(changeWeek);
    const concatNewDay = columns.concat([changeWeek]);
    setColumns(concatNewDay);
    setOrdered(Object.keys(concatNewDay));
  };
  const handleAddWeek = () => {
    const arrayOfObjects = columns.reduce((acc, val) => acc.concat(val), []);
    const lastWeek = arrayOfObjects[arrayOfObjects.length - 1];
    const lastday = columns[columns.length - 1];
    const generateWeek = lastday.map((item) => {
      const setItem = {
        ...item,
        week: lastWeek.week + 1,
        _id: generateId(),
        id: generateId(),
        exercises: item.exercises.map((i) => {
          const setIdExercise = {
            ...i,
            _id: generateId(),
            sets: i.sets.map((e) => {
              const newId = {
                ...e,
                _id: generateId(),
              };
              return newId;
            }),
          };
          return setIdExercise;
        }),
      };
      return setItem;
    });

    const concatNewDay = columns.concat([[]]);
    setColumns(concatNewDay);
    setOrdered(Object.keys(concatNewDay));
    const ContentMainScroll = scrollHorizontalBoard.current;
    const horizontalScroll = ContentMainScroll.scrollWidth;
    setTimeout(() => {
      ContentMainScroll.scrollTo(horizontalScroll, 0);
    }, 100);
  };

  const handleSave = async () => {
    const routine = {
      id_trainer: _id,
      id_client: client.split("___")[1],
      filterOptions: [[], [], []],
      workout_name: { en: name, es: "" },
      source: "custom",
      preProgrammed: false,
      phase,
      sessions: columns.flat(),
      date: null,
    };

    try {
      const response = await createRoutine(routine);
      if (response.data) return notificationDisplay(dispatch, "something went wrong", "error");
      setTimeout(() => {
        history.push({
          pathname: "/user/clients",
          state: {
            clientInfo,
          },
          programReturn: {
            response: response.customWorkout,
          },
        });
      }, 1000);
    } catch (error) {
      console.log(error.message);
    }
  };

  const validacionSave = () => {
    if (name === "") {
      notificationDisplay(dispatch, "Name is empty", "error");
    }
    if (phase === "") {
      notificationDisplay(dispatch, "Phase is empty", "error");
    }
    if (columns.length <= 0) {
      notificationDisplay(dispatch, t("errors.empty_program"), "error");
    }
  };

  useEffect(() => {
    console.log(columns);
    setColumns(initial);
    setOrdered(Object.keys(initial));
    setSortWeeks(initial.reduce((acc, val) => acc.concat(val), []));
  }, [initial]);

  if (superSetState?.data.length > 0) {
    return (
      <SuperSet
        setSuperSetState={setSuperSetState}
        superSetState={superSetState}
        setColumns={setColumns}
      />
    );
  }

  const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
    if (isDraggingOver) return "#FFEBE6";
    if (isDraggingFrom) return "#E6FCF8";
    return "transparent";
  };

  const collapseAndExpandAll = () => {
    const items = Array.from(document.getElementsByClassName("day_content"));
    const spandButton = Array.from(document.getElementsByClassName("spand-button"));
    if (items.length > 0) {
      if (items.some((a) => a.classList.contains("collapsed"))) {
        items.forEach((element) => {
          element.classList.remove("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.remove("btn-collapsed");
        });
      } else {
        items.forEach((element) => {
          element.classList.add("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.add("btn-collapsed");
        });
      }
    }
  };
  const onDragStart = (result) => {
    if (result.type === "DAYS") {
      const items = Array.from(document.getElementsByClassName("day_content"));
      const spandButton = Array.from(document.getElementsByClassName("spand-button"));
      if (items.length > 0) {
        items.forEach((element) => {
          element.classList.add("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.add("btn-collapsed");
        });
      }
    }
  };
  return (
    <>
      <Flex gridGap="1rem" alignItems="flex-start" className="boardContent">
        <Box className="form-new-program">
          <RoutineInfo setPhase={setPhase} phase={phase} setName={setName} name={name} />
          {name.trim() !== "" && phase.trim() !== "" && columns.length >= 1 ? (
            <Button onClick={() => handleSave()} colorScheme="ptRed" mt="1rem">
              {t("save_program")}
            </Button>
          ) : (
            <Button onClick={() => validacionSave()} colorScheme="ptRed" mt="1rem">
              {t("save_program")}
            </Button>
          )}
        </Box>
        <Box w="100%">
          {/* Board options */}
          <Flex justifyContent="space-between" gridGap="10px" flexWrap="wrap" mb={4}>
            <Flex gridGap={2}>
              <Button
                size="sm"
                onClick={() =>
                  cloneState
                    ? handleCancelClone()
                    : (setCloneState(true), onDragStart({ type: "DAYS" }))
                }
                variant="outline"
                leftIcon={
                  cloneState ? <CloseIcon fontSize="inherit" /> : <CopyIcon fontSize="inherit" />
                }>
                {cloneState ? t("clone_cancel") : t("clone")}
              </Button>
              <Button size="sm" variant="outline" onClick={() => collapseAndExpandAll()}>
                {t("expand_or_collapse")}
              </Button>
              <Button
                size="sm"
                variant="outline"
                colorScheme="orange"
                leftIcon={<AddIcon fontSize="inherit" />}
                onClick={onOpenMore}>
                {t("more_exercise")}
              </Button>
            </Flex>
            <Flex gridGap="10px">
              {Children.map(children[0], (child) => (
                <>{child}</>
              ))}
            </Flex>
          </Flex>
          <Box className="board-container" ref={scrollHorizontalBoard}>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <Droppable droppableId="board" type="COLUMN" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    className="board_column"
                    style={{
                      backgroundColor: `${getBackgroundColor(
                        snapshot.isDraggingOver,
                        snapshot.isDraggingFrom,
                      )}`,
                      justifyContent: columns.length === 0 ? "center" : "start",
                      alignItems: "start",
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {ordered?.map((key, index) => (
                      <Column
                        key={key}
                        index={index}
                        title={key}
                        quotes={columns[key]}
                        isScrollable={withScrollableColumns}
                        isCombineEnabled={isCombineEnabled}
                        useClone={useClone}
                        columns={columns}
                        scrollHorizontalBoard={scrollHorizontalBoard}
                        keyRefrest={keyRefrest}
                        setColumns={setFiltered}
                        setOrdered={setOrdered}
                        setFiltered={setFiltered}
                        cloneState={cloneState}
                        listDaySelected={listDaySelected}
                        handleChangeSelect={handleChangeSelect}
                        handleChangeRemove={handleChangeRemove}
                        handleCancelClone={handleCancelClone}
                        setSuperSetState={setSuperSetState}
                        onCloneWeek={cloneWeek}
                        setCloneUniqueDay={setCloneUniqueDay}
                        cloneUniqueDay={cloneUniqueDay}
                        currentClient={client}
                      />
                    ))}
                    {provided.placeholder}
                    {ordered.length > 0 && (
                      <button
                        className={styles.Btn__add_week}
                        type="button"
                        onClick={() => handleAddWeek()}>
                        <Text mr={3}>{t("add_week")}</Text>
                        <AddIcon />
                      </button>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            {Children.map(children[1], (child) => (
              <>{child}</>
            ))}
          </Box>
        </Box>
      </Flex>

      {isOpenMore && (
        <Combine
          isOpen={isOpenMore}
          onClose={onCloseMore}
          days={sortWeeks}
          setFiltered={setFiltered}
          client={client}
        />
      )}
    </>
  );
};

// Board.defaultProps = {
//   isCombineEnabled: false,
// };

// Board.propTypes = {
//   isCombineEnabled: PropTypes.bool,
// };

export default Board;
