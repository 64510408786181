import useLang from "hooks/useLang";
import styles from "./Dashboard.module.scss";
import securityIcon from "../../assets/icons/ico_security.svg";
import chatIcon from "../../assets/icons/ico_chat.svg";
import photoIcon from "../../assets/icons/ico_photo.svg";
import updateIcon from "../../assets/icons/ico_updates.svg";
import NewsEn from "../../assets/Image_news_dashboard_en.png";
import NewsEs from "../../assets/Image_news_dashboard_es.png";

const Features = () => {
  const { t, lang } = useLang();
  return (
    <>
      <picture>
        <img src={lang === "en" ? NewsEn : NewsEs} alt="icon" />
      </picture>
      <div className={styles.features}>
        <div className={styles.header}>
          <img src={updateIcon} alt="icon" />
          <p>{t("dashboard_feature.new_feature")}</p>
        </div>
        <div className={styles.feature}>
          <img src={securityIcon} alt="icon" />
          <p>{t("dashboard_feature.upload_your_logo_to_reports")}</p>
        </div>
        <hr />
        <div className={styles.feature}>
          <img src={chatIcon} alt="icon" />
          <p>{t("dashboard_feature.share_programs_with_qr")}</p>
        </div>
        <hr />
        <div className={styles.feature}>
          <img src={photoIcon} alt="icon" />
          <p>{t("dashboard_feature.upload_photo_exercise")}</p>
        </div>
      </div>
    </>
  );
};

export default Features;
