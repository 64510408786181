/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Text,
  Tooltip,
  Flex,
} from "@chakra-ui/react";
import { bool, func, shape } from "prop-types";
import useLang from "hooks/useLang";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import useEditDayValueContext from "./useEditDayValueContext";
import EditContent from "./EditContent";

const EditDayContext = createContext();

const EditDay = ({
  isOpen,
  onClose,
  date,
  titleHeader,
  quotes,
  handleUpdateDnd,
  routineId,
  columns,
  setColumns,
  setOrdered,
  currentClient,
  columnScroll,
  scrollHorizontalBoard,
  listdays,
}) => {
  const [open, setopen] = useState(false);
  const { t } = useLang();
  const [openDialog, setopenDialog] = useState(false);
  const mainDiv = useRef(null);

  const { isOpen: isOpenContent, onOpen: onOpenContent, onClose: onCloseContent } = useDisclosure();

  const isEdit = true;

  const [values, obj] = useEditDayValueContext(quotes, isEdit);

  const dateInfo = { day: quotes[0].day, week: quotes[0].week };

  const handleSaveEdit = () => {
    // Update the object with the edited quote
    const updatedDay = listdays.map((objArr) =>
      objArr._id === values[0]._id ? values[0] : objArr,
    );

    let weekColumn = [...columns];
    const arrIndex = titleHeader - 1;
    weekColumn.splice(arrIndex, 1, updatedDay);

    // Update the state variable and close the modal
    setColumns(weekColumn);
    const ContentListColumn = columnScroll.current;
    const ContentMainScroll = scrollHorizontalBoard.current;
    setTimeout(() => {
      const height = ContentListColumn.scrollTop;
      const horizontalScroll = ContentMainScroll.scrollLeft;
      ContentMainScroll.scrollTo(horizontalScroll, 0);
      ContentListColumn.scrollTo(0, height);
    }, 100);
    onClose();
  };

  const handleConfirmDelete = () => {
    // Delete out the quote to be deleted
    const deletedDay = listdays.filter(({ _id }) => _id !== quotes[0]._id);
    const orderBoard = deletedDay.map((object, i) => ({ ...object, day: 1 + i }));
    let weekColumn = [...columns];
    const arrIndex = titleHeader - 1;
    weekColumn.splice(arrIndex, 1, orderBoard);

    // Update the state variables
    setColumns(weekColumn);
    setOrdered(Object.keys(weekColumn));
    const ContentListColumn = columnScroll.current;
    const ContentMainScroll = scrollHorizontalBoard.current;
    setTimeout(() => {
      const height = ContentListColumn.scrollTop;
      const horizontalScroll = ContentMainScroll.scrollLeft;
      ContentMainScroll.scrollTo(horizontalScroll, 0);
      ContentListColumn.scrollTo(0, height);
    }, 100);
    setopen(false);
    onClose();
  };

  const handleConfirmClose = () => {
    if (quotes[0] !== values[0]) {
      setopenDialog(true);
      return;
    }
    onClose();
  };

  return (
    <EditDayContext.Provider
      value={{
        ...obj,
      }}>
      <Modal
        closeOnOverlayClick={false}
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={() => handleConfirmClose()}
        size="xl"
        scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent borderTop="13px solid #b42519" borderRadius={10}>
          <ModalHeader display="flex" justifyContent="space-between">
            <Box>
              <Text display="inline">
                {t("edit_day")}{" "}
                <Text display="inline" color="#b42519">
                  {quotes[0].day}
                </Text>{" "}
                {t("in_week")}{" "}
                <Text display="inline" color="#b42519">
                  {quotes[0].week}
                </Text>
              </Text>
            </Box>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody ref={mainDiv}>
            <Flex justifyContent="flex-end" mb={4}>
              <Button
                size="xs"
                leftIcon={<DeleteIcon fontSize="inherit" />}
                onClick={() => setopen(true)}>
                {t("delete_day")}
              </Button>
            </Flex>
            <Box>
              {values.map((day) => (
                <EditContent
                  inicialValue={day}
                  isEdit={isEdit}
                  mainDiv={mainDiv}
                  date={dateInfo}
                  currentClient={currentClient}
                  isOpen={isOpenContent}
                  onClose={onCloseContent}
                />
              ))}
            </Box>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="space-between" marginTop={2} marginBottom={2}>
            <Button
              color="gray"
              border="1px solid #E3E3E3"
              borderRadius="8px"
              bg="transparent"
              _hover={{ color: "#b42519", border: "1px solid #b42519" }}
              leftIcon={<AddIcon />}
              onClick={onOpenContent}>
              {t("add_exercise")}
            </Button>
            {values.map((day) => (
              <>
                {day.exercises.length >= 1 && (
                  <Button
                    type="submit"
                    bg="#6bca21"
                    color="white"
                    _hover={{ bg: "#6bca21" }}
                    onClick={() => {
                      handleSaveEdit();
                      onClose();
                    }}>
                    {t("save")}
                  </Button>
                )}
              </>
            ))}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {openDialog && (
        <AlertDialog isOpen={openDialog} onClose={() => setopenDialog(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
                {t("delete_confirmation")}
              </AlertDialogHeader>
              <AlertDialogBody>{t("confirm_save_or_discard")}</AlertDialogBody>
              <AlertDialogFooter>
                <Box
                  display="flex"
                  flexDirection="column"
                  w="full"
                  gridGap={2}
                  justifyContent="center"
                  alignItems="center">
                  <Button w="70%" border="1px solid #b42519" onClick={() => setopenDialog(false)}>
                    {t("backBtn")}
                  </Button>
                  <Button
                    w="70%"
                    bg="#b42519"
                    color="white"
                    _hover={{ bg: "white", border: "1px solid #b42519", color: "black" }}
                    onClick={() => onClose()}>
                    {t("discard")}
                  </Button>
                  <Button
                    w="70%"
                    bg="#6bca21"
                    color="white"
                    _hover={{ bg: "#6bca21" }}
                    onClick={() => handleSaveEdit()}>
                    {t("save")}
                  </Button>
                </Box>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
      {open && (
        <AlertDialog isOpen={open} onClose={() => setopen(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>{t("delete_day")}</AlertDialogHeader>
              <AlertDialogBody>{t("delete_confirmation")}</AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={() => setopen(false)}>{t("cancel")}</Button>
                <Button colorScheme="red" onClick={() => handleConfirmDelete()} ml={3}>
                  {t("delete")}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </EditDayContext.Provider>
  );
};

const useEditDayContext = () => useContext(EditDayContext);
export default EditDay;

export { useEditDayContext };
