import React, { useEffect } from "react";
import { getAllTrainers } from "services/trainer";
import { bigContainerStyle } from "theme/commonStyles";
import Board from "./Board";

const Trainers = () => {
  useEffect(() => {
    getAllTrainers().then((trainers) => console.log(trainers));
  });
  return (
    <div style={bigContainerStyle}>
      <Board />
    </div>
  );
};

export default Trainers;
