/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation, useHistory, Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import {
  Button,
  Menu as MenuLang,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  Box,
  Avatar,
} from "@chakra-ui/react";
import UsaLogo from "assets/eeuu.png";
import SpainLogo from "assets/spain.png";
import useLang from "hooks/useLang";
import languages from "utils/languages";
import i18next from "i18next";

import notificationDisplay from "utils/notificationDisplay";
import { loaded, loading } from "reducers/loader.reducer";

import { inputStyle } from "theme/commonStyles";
import styles from "./changePass.module.scss";
import PasswordInput from "../../common/PasswordInput";
import logo from "../../../assets/3.png";

const baseURL = `${process.env.REACT_APP_API_URL_PROD}/`;

const MySwal = withReactContent(Swal);

const sendEmail = (email, history) =>
  fetch(`${baseURL}email/recoverypass/${email}`, { method: "POST" })
    .then((res) => {
      if (res.status === 200) {
        MySwal.fire({
          title: <p>Correo enviado!</p>,
          text: "Revisa tu correo para continuar con el proceso",
          icon: "success",
          showConfirmButton: true,
          confirmButtonColor: "#b42519",
        }).then(() => history.push("/login"));
      }
    })
    .catch((err) => {
      MySwal.fire({
        title: <p>Error!</p>,
        icon: "error",
        text: err,
        showConfirmButton: true,
        confirmButtonColor: "#b42519",
      });
    });
const savePassword = async (password, token, history) => {
  const data = { token, newPassword: password };
  await fetch(`${baseURL}user/password`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(data),
  })
    .then((res) => {
      if (res.status === 200) {
        MySwal.fire({
          title: <p>Contraseña cambiada!</p>,
          showConfirmButton: true,
          confirmButtonColor: "#b42519",
        }).then(() => history.push("/login"));
      }
    })
    .catch((err) => {
      MySwal.fire({
        title: <p>Error!</p>,
        icon: "error",
        text: err,
        showConfirmButton: true,
        confirmButtonColor: "#b42519",
      });
    });
};

const ChangePass = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const [token, setToken] = useState(null);
  const { search } = useLocation();

  const currentYear = new Date().getFullYear();
  useEffect(() => {
    setToken(new URLSearchParams(search).get("token") ?? null);
  });
  const { t } = useTranslation();
  const { lang } = useLang();
  const onLanguageChange = (code) => {
    i18next.changeLanguage(code);
  };

  const onSubmit = async (data) => {
    dispatch(loading());
    try {
      if (token) {
        if (data.password === data.password1) {
          await savePassword(data.password, token, history);
        } else notificationDisplay(dispatch, "Passwords don't match", "error");
      } else {
        await sendEmail(data.email, history);
      }
    } catch (exception) {
      notificationDisplay(dispatch, exception.message, "error");
    }
    dispatch(loaded());
  };

  return (
    <section className={styles.root}>
      <aside className={styles.sideAuth} />
      <aside className={styles.formSide}>
        <div className={styles.header}>
          <MenuLang>
            <MenuButton as={Button} variant="ghost" fontSize="40px" padding={0} borderRadius="full">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  name="english lang"
                  src={lang === "en" ? UsaLogo : SpainLogo}
                  width="30px"
                  height="30px"
                />
              </Box>
            </MenuButton>
            <MenuList zIndex="101">
              {languages.map(({ code, name, countryCode }) => (
                <MenuItem key={code} color="ptText.500" onClick={() => onLanguageChange(code)}>
                  <span className={`flag-icon flag-icon-${countryCode}`} />
                  <span>{name}</span>
                </MenuItem>
              ))}
            </MenuList>
          </MenuLang>
        </div>
        <div className={styles.formContainer}>
          <img className={styles.logo} src={logo} alt="perfecttrainer logo" />
          <Box my={4} w="100%">
            {!token && (
              <Button
                size="xs"
                color="gray"
                colorScheme="gray"
                variant="outline"
                as={Link}
                to="/login"
                leftIcon={<ArrowBackIosIcon fontSize="inherit" />}>
                {t("backBtn")}
              </Button>
            )}
          </Box>

          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            {token ? (
              <>
                <h4 className={`${styles.title} ${styles.left}`}>{t("new_password")}</h4>
                <Controller
                  control={control}
                  defaultValue=""
                  name="password"
                  rules={{ required: true, minLength: 4, maxLength: 12 }}
                  render={({ field }) => (
                    <PasswordInput field={field} placeholder={t("password")} />
                  )}
                />
                {errors.password?.type === "required" && (
                  <span className={styles.message}>{t("error_required")}</span>
                )}
                {errors.password?.type === "minLength" && (
                  <span className={styles.message}>{t("error_min", { num: 4 })}</span>
                )}
                {errors.password?.type === "maxLength" && (
                  <span className={styles.message}>{t("error_max", { num: 12 })}</span>
                )}
                <Controller
                  control={control}
                  defaultValue=""
                  name="password1"
                  rules={{ required: true, minLength: 4, maxLength: 12 }}
                  render={({ field }) => (
                    <PasswordInput field={field} placeholder={t("repeat_password")} />
                  )}
                />
                {errors.password?.type === "required" && (
                  <span className={styles.message}>{t("error_required")}</span>
                )}
                {errors.password?.type === "minLength" && (
                  <span className={styles.message}>{t("error_min", { num: 4 })}</span>
                )}
                {errors.password?.type === "maxLength" && (
                  <span className={styles.message}>{t("error_max", { num: 12 })}</span>
                )}
              </>
            ) : (
              <>
                <h4 className={`${styles.title} ${styles.left}`}>{t("recover_pass")}</h4>
                <Controller
                  control={control}
                  defaultValue=""
                  name="email"
                  rules={{
                    required: true,
                    pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                  }}
                  render={({ field }) => (
                    <Input {...field} {...inputStyle} placeholder={t("email")} mb="0" />
                  )}
                />
                {errors.email?.type === "required" && (
                  <span className={styles.message}>{t("error_required")}</span>
                )}
                {errors.email?.type === "pattern" && (
                  <span className={styles.message}>{t("error_email")}</span>
                )}
              </>
            )}

            <button type="submit" className={styles.submit}>
              {token ? t("save") : t("send_email")}
            </button>
            <div className={styles.signup}>
              {t("have_account")}
              <Link to="/login" className={styles.link}>
                {t("login")}
              </Link>
            </div>
          </form>
        </div>

        <div className={styles.footer}>
          <div className={styles.term}>
            <a href="#a">{t("privacy_policy")}</a>
            <a href="#a">{t("terms_use")}</a>
            <a href="#a">Legal</a>
          </div>
          <span>
            Copyright © {currentYear} The Institute Of Human Performance | {t("copyright_text")}
          </span>
        </div>
      </aside>
    </section>
  );
};

export default ChangePass;
