/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
/* eslint-disable object-curly-newline */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  useDisclosure,
  IconButton,
  SimpleGrid,
  Flex,
  Skeleton,
  Image,
  Heading,
} from "@chakra-ui/react";
import { EditIcon, ViewIcon } from "@chakra-ui/icons";
import MaterialTable from "material-table";
import { useHistory } from "react-router-dom";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import DisplayMessage from "components/common/DisplayMessage";
import useLang from "hooks/useLang";
import useGetAll from "hooks/useGetAll";
import Fetch from "components/common/Fetch";

import notFound from "assets/notfound.png";
import { getExercises, getTrainerExercises } from "services/exercise";
import localizationMaterialTable from "../../helpers/localizationMaterialTable";
import customFilterAndSearch from "../../helpers/customFilterAndSearch";

import style from "./Board.module.scss";

import Popup from "./Popup";
import tableIcons from "../../theme/tableIcons";
import Options from "./Options";
import ExerciseCard from "./exerciseCard";

const Board = () => {
  const { id_user, role } = useSelector(({ user }) => user);
  const { t, lang } = useLang();
  const {
    isLoading = true,
    isError,
    isSuccess = false,
    data,
    error,
  } = useGetAll("exercises", null, () => getTrainerExercises(id_user));
  const { location } = useHistory();
  const myParamModalMode = location?.state?.mode;
  const [exerciseData, setExerciseData] = useState({});
  const [mode, setMode] = useState(myParamModalMode || "info");
  const [displayMode, setDisplayMode] = useState("grid");
  const [search, setSearch] = useState("");
  const [actionFilter, setActionFilter] = useState("");
  const [muscleFilter, setMuscleFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [equipmentFilter, setEquipmentFilter] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const [nPages, setNPages] = useState(0);
  const myParamModalAdd = location?.state?.add;
  const [open, setopen] = useState(!!myParamModalAdd);

  /* const getAllExercises = async () => {
    try {
      const res = await getTrainerExercises(id_user);
      if (res.data) throw new Error(res.data.message);
      setNPages(Math.ceil(res.length / recordsPerPage));
      console.log(res);
      setData(res);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  }; */

  useEffect(() => {
    if (data) setNPages(Math.ceil(data.length / recordsPerPage));
  }, [isSuccess]);
  // const errorObj = error.message ? JSON.parse(error.message) : {};
  useEffect(() => {
    document.title = "Perfect Trainer App | Exercises";
    setDisplayMode(localStorage.getItem("displayMode") || "grid");
  }, []);

  const changeFilters = ({ name, action, muscle, category, equipment }) => {
    setSearch(name);
    setActionFilter(action);
    setMuscleFilter(muscle);
    setCategoryFilter(category);
    setEquipmentFilter(equipment);
    const { length } = data
      ?.filter((a) => (name !== "" ? a.name[lang]?.toLowerCase().includes(name?.toLowerCase()) : a))
      ?.filter((a) => (action !== "" ? a.action?.includes(action) : a))
      ?.filter((a) => (muscle !== "" ? a.muscle_system?.includes(muscle) : a))
      ?.filter((a) =>
        category !== "" ? a.category_id.findIndex((item) => item._id === category) >= 0 : a,
      )
      ?.filter((a) => (equipment !== "" ? a.equipment?.includes(equipment) : a));
    setNPages(Math.ceil(length / recordsPerPage));
    setCurrentPage(1);
  };
  const onClick = (exercise, type) => {
    setExerciseData(exercise);
    setMode(type);
    setopen(true);
    // onOpen();
  };
  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
    document.getElementById("main_content_sections").scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
    document.getElementById("main_content_sections").scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
    document.getElementById("main_content_sections").scrollTo({ top: 0, behavior: "smooth" });
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) setCurrentPage(nPages);
    document.getElementById("main_content_sections").scrollTo({ top: 0, behavior: "smooth" });
  };
  const localization = localizationMaterialTable();

  if (isError) return <DisplayMessage message={t("something_wrong")} />;
  if (isLoading)
    return (
      <>
        <Flex gridGap={4} justifyContent="space-between">
          <Skeleton height="40px" width="80%" />
          <Skeleton height="40px" w="150px" />
        </Flex>
        <Flex gridGap={4} flexWrap="wrap" mt="3rem">
          <Skeleton height="285px" w="220px" />
          <Skeleton height="285px" w="220px" />
          <Skeleton height="285px" w="220px" />
          <Skeleton height="285px" w="220px" />
          <Skeleton height="285px" w="220px" />
          <Skeleton height="285px" w="220px" />
        </Flex>
      </>
    );
  return (
    <section className={style.root}>
      <Options
        displayMode={displayMode}
        onClick={onClick}
        setDisplayMode={setDisplayMode}
        search={search}
        actionFilter={actionFilter}
        muscleFilter={muscleFilter}
        categoryFilter={categoryFilter}
        equipmentFilter={equipmentFilter}
        setActionFilter={(e) =>
          changeFilters({
            name: search,
            action: e,
            category: categoryFilter,
            equipment: equipmentFilter,
            muscle: muscleFilter,
          })
        }
        setMuscleFilter={(e) =>
          changeFilters({
            name: search,
            action: actionFilter,
            category: categoryFilter,
            equipment: equipmentFilter,
            muscle: e,
          })
        }
        setCategoryFilter={(e) =>
          changeFilters({
            name: search,
            action: actionFilter,
            category: e,
            equipment: equipmentFilter,
            muscle: muscleFilter,
          })
        }
        setEquipmentFilter={(e) =>
          changeFilters({
            name: search,
            action: actionFilter,
            category: categoryFilter,
            equipment: e,
            muscle: muscleFilter,
          })
        }
        setSearch={(e) =>
          changeFilters({
            name: e,
            action: actionFilter,
            category: categoryFilter,
            equipment: equipmentFilter,
            muscle: muscleFilter,
          })
        }
      />
      {/* Material-table implementation for exercises */}
      {/* <MaterialTable
            style={{ width: "100%" }}
            className={style.table}
            icons={tableIcons}
            columns={columns}
            data={data.filter((d) => d.name[lang])}
            title={t("exercisesDB")}
            localization={localization}
          /> */}
      <Flex gridGap={4} flexWrap="wrap">
        {data
          .filter((a) => a.name[lang]?.toLowerCase().includes(search.toLowerCase()))
          .filter((a) => (actionFilter !== "" ? a.action?.includes(actionFilter) : a))
          .filter((a) => (muscleFilter !== "" ? a.muscle_system?.includes(muscleFilter) : a))
          .filter((a) =>
            categoryFilter !== ""
              ? a.category_id.findIndex((item) => item._id === categoryFilter) >= 0
              : a,
          )
          .filter((a) => (equipmentFilter !== "" ? a.equipment?.includes(equipmentFilter) : a))
          .slice(indexOfFirstRecord, indexOfLastRecord)
          .map((exercise) => (
            <ExerciseCard
              onClick={(e, type) => onClick(e, type)}
              exercise={exercise}
              key={exercise._id}
              displayMode={displayMode}
            />
          ))}
      </Flex>
      {!isLoading && (
        <Flex flexDirection="column" alignItems="center" justifyContent="center">
          {data
            .filter((a) => a.name[lang]?.toLowerCase().includes(search.toLowerCase()))
            .filter((a) => (actionFilter !== "" ? a.action?.includes(actionFilter) : a))
            .filter((a) => (muscleFilter !== "" ? a.muscle_system?.includes(muscleFilter) : a))
            .filter((a) =>
              categoryFilter !== ""
                ? a.category_id.findIndex((item) => item._id === categoryFilter) >= 0
                : a,
            )
            .filter((a) => (equipmentFilter !== "" ? a.equipment?.includes(equipmentFilter) : a))
            .length <= 0 && (
            <>
              <Image src={notFound} w="10rem" alt="not found" />
              <Heading> {t("exercises_not_found")}</Heading>
            </>
          )}
        </Flex>
      )}
      <nav>
        <ul className="pagination">
          <li>
            <IconButton
              isRound
              variant="ghost"
              colorScheme="blackAlpha"
              aria-label="Done"
              fontSize="20px"
              disabled={currentPage === 1}
              icon={<FirstPageIcon />}
              onClick={goToFirstPage}
            />
          </li>
          <li>
            <IconButton
              isRound
              variant="ghost"
              colorScheme="blackAlpha"
              aria-label="Done"
              fontSize="20px"
              disabled={currentPage === 1}
              icon={<ArrowBackIosIcon />}
              onClick={goToPrevPage}
            />
          </li>
          <li>
            {currentPage}/{nPages}
          </li>
          <li>
            <IconButton
              isRound
              variant="ghost"
              colorScheme="blackAlpha"
              aria-label="Done"
              fontSize="20px"
              disabled={currentPage === nPages}
              icon={<ArrowForwardIosIcon />}
              onClick={goToNextPage}
            />
          </li>
          <li>
            <IconButton
              isRound
              variant="ghost"
              colorScheme="blackAlpha"
              aria-label="Done"
              fontSize="20px"
              disabled={currentPage === nPages}
              icon={<LastPageIcon />}
              onClick={goToLastPage}
            />
          </li>
        </ul>
      </nav>
      {open && (
        <Popup exercise={exerciseData} isOpen={open} onClose={() => setopen(false)} mode={mode} />
      )}
    </section>
  );
};

export default Board;
