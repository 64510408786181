/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import { Box, Text } from "@chakra-ui/react";
import useLang from "hooks/useLang";
import styles from "./nutrition.module.scss";

const MacroNutrientsCalculator = ({
  calories,
  protein,
  setProtein,
  carbs,
  setCarbs,
  fat,
  setFat,
}) => {
  const { t } = useLang();

  const calcularMacronutrientes = () => {
    const caloriasCarbohidratos = (calories * carbs) / 100;
    const caloriasProteinas = (calories * protein) / 100;
    const caloriasGrasas = (calories * fat) / 100;

    const gramosCarbohidratos = caloriasCarbohidratos / 4;
    const gramosProteinas = caloriasProteinas / 4;
    const gramosGrasas = Math.round(caloriasGrasas / 9);

    return {
      gramosCarbohidratos,
      gramosProteinas,
      gramosGrasas,
    };
  };

  const { gramosCarbohidratos, gramosProteinas, gramosGrasas } = calcularMacronutrientes();

  const handleProteinChange = (e) => {
    const newProtein = parseInt(e.target.value);
    const maxFat = 100 - newProtein;
    const newFat = fat > maxFat ? maxFat : fat;
    const newCarbs = 100 - newProtein - newFat;
    setProtein(newProtein);
    setFat(newFat);
    setCarbs(newCarbs);
  };

  const handleFatChange = (e) => {
    const newFat = parseInt(e.target.value);
    const maxProtein = 100 - newFat;
    const newProtein = protein > maxProtein ? maxProtein : protein;
    const newCarbs = 100 - newProtein - newFat;
    setFat(newFat);
    setProtein(newProtein);
    setCarbs(newCarbs);
  };

  return (
    <div>
      <Box
        display="flex"
        justifyContent="space-between"
        flexDirection="column"
        bg="white"
        p={5}
        borderRadius="10px"
        style={{ gap: "1em" }}>
        <Text fontWeight="bold" fontSize="x-large">
          {t("nutrition_section.macronutrients")}
        </Text>
        <Box display="flex" justifyContent="space-between" flexDirection="column">
          <Box display="flex" w="full" style={{ gap: "1em" }}>
            <Box display="flex" justifyContent="space-between" w="90%" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <label>{t("nutrition_section.protein_percentage")}</label>
                <Text fontSize="16px" fontWeight="bold">
                  {gramosProteinas} g
                </Text>
              </Box>
              <input
                type="range"
                min="0"
                className={styles.customRange__protein}
                max="100"
                value={protein}
                onChange={handleProteinChange}
              />
            </Box>
            <Box w="10%">
              <Box
                bg="#fbd69d"
                w="3em"
                h="3em"
                borderRadius="10px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontWeight="bold">
                {protein}%
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" flexDirection="column">
          <Box display="flex" w="full" style={{ gap: "1em" }}>
            <Box display="flex" justifyContent="space-between" w="90%" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <label>{t("nutrition_section.carbs_percentage")}</label>
                <Text fontSize="16px" fontWeight="bold">
                  {gramosCarbohidratos} g
                </Text>
              </Box>
              <input
                type="range"
                min="0"
                max="100"
                disabled
                value={carbs}
                readOnly
                className={styles.customRange__carbs}
              />
            </Box>
            <Box w="10%">
              <Box
                bg="#e7d9fa"
                w="3em"
                h="3em"
                borderRadius="10px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontWeight="bold">
                {carbs}%
              </Box>
            </Box>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" flexDirection="column">
          <Box display="flex" w="full" style={{ gap: "1em" }}>
            <Box display="flex" justifyContent="space-between" w="90%" flexDirection="column">
              <Box display="flex" justifyContent="space-between" alignItems="center">
                <label>{t("nutrition_section.fats_percentage")}</label>
                <Text fontSize="16px" fontWeight="bold">
                  {gramosGrasas} g
                </Text>
              </Box>
              <input
                type="range"
                min="0"
                max="100"
                className={styles.customRange__fat}
                value={fat}
                onChange={handleFatChange}
              />
            </Box>
            <Box w="10%">
              <Box
                bg="#c0f9f9"
                w="3em"
                h="3em"
                borderRadius="10px"
                display="flex"
                justifyContent="center"
                alignItems="center"
                fontWeight="bold">
                {fat}%
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default MacroNutrientsCalculator;
