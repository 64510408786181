/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import useLang from "hooks/useLang";
import notFound from "assets/notfound.png";
import { Box, Heading, Image, useDisclosure } from "@chakra-ui/react";
import DrawerInformationNutrition from "components/clients/ClientDashboard/ClientNutrition/DrawerInformationNutrition";
import { useSelector } from "react-redux";
import { getNutritionByTrainer } from "services/nutrition";
import styles from "./nutrition.module.scss";

const MyDiets = ({ updateListSave }) => {
  const { t, lang } = useLang();
  const [diets, setDiets] = useState([]);
  const { id_user } = useSelector(({ user }) => user);
  const [nutritionDetails, setnutritionDetails] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure();

  const loadDiets = async () => {
    try {
      const res = await getNutritionByTrainer(id_user);
      const filterResult = res.diets.filter((diet) => diet.id_client === null);
      setDiets(filterResult);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadDiets();
  }, [updateListSave]);
  return (
    <div style={{ marginTop: "1em" }}>
      {diets?.length <= 0 && (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Image src={notFound} w="10rem" alt="not found" />
          <Heading fontSize="2xl">
            {t('not_have_diet')}
          </Heading>
        </Box>
      )}
      <div className={styles.container}>
        {diets?.map((diet) => (
          <div key={diet._id} className={styles.box}>
            <div onClick={() => { setnutritionDetails(diet); onOpen() }} className={styles.box_information}>
              <div className={styles.box_flex}>
                <p className={styles.diet__NAME}>{diet.name}</p>
                <p className={styles.badge}>{t(diet.goals)}</p>
              </div>
              <p className={styles.creation}>
                {t("created_at")}:{" "}
                {dayjs(diet.created_at).format(lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY")}
              </p>
            </div>
          </div>
        ))}
      </div>
      {isOpen && (
        <DrawerInformationNutrition
          isOpen={isOpen}
          key={nutritionDetails._id}
          onClose={onClose}
          diet={nutritionDetails}
          setDiets={setDiets}
        />
      )}
    </div>
  );
};

export default MyDiets;
