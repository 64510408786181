/* eslint-disable no-useless-escape */
import React, { useState, useEffect } from "react";
// ? External Dependencies
import { useForm, Controller } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Menu as MenuLang,
  MenuButton,
  MenuList,
  MenuItem,
  Input,
  Box,
  Avatar,
} from "@chakra-ui/react";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";

// ? local Dependencies
import { login, loginAlternative } from "services/user";
import { useAuth } from "utils/authContext";
import { addInfo } from "reducers/user.reducer";
import notificationDisplay from "utils/notificationDisplay";
import { getTrainerByUserId } from "services/trainer";

import UsaLogo from "assets/eeuu.png";
import SpainLogo from "assets/spain.png";
import { inputStyle } from "theme/commonStyles";
import useLang from "hooks/useLang";
import languages from "utils/languages";
import i18next from "i18next";
import styles from "./login.module.scss";
import PasswordInput from "../../common/PasswordInput";
import googleSvg from "../../../assets/google.svg";
import logo from "../../../assets/3.png";

const Login = () => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const history = useHistory();
  const auth = useAuth();
  const { t } = useTranslation();
  const { from } = { from: { pathname: "/user/dashboard" } };
  const currentYear = new Date().getFullYear();

  const [loading, setLoading] = useState(false);

  const { lang } = useLang();
  const onLanguageChange = (code) => {
    i18next.changeLanguage(code);
  };

  useEffect(() => {
    document.title = "Perfect Trainer App ";
  }, []);
  const onSubmit = async (data) => {
    /*  dispatch(loading()); */
    setLoading(true);
    try {
      const response = await login(data);
      if (response.data) throw new Error(response.data.error);
      localStorage.setItem("userAuth", response.token);
      localStorage.setItem("refreshToken", response.refreshToken);
      localStorage.setItem("role", response.role);
      const trainerInfo = await getTrainerByUserId(response.id_user);
      /* console.log(response);
      console.log(trainerInfo); */
      if (response.role === "admin" || (response.token && trainerInfo)) {
        const responseToSend = {
          ...response,
          ...trainerInfo,
        };
        console.log("get in response to send", responseToSend);
        dispatch(addInfo(responseToSend));
        onLanguageChange(trainerInfo.language);
        auth.signin(() => {
          history.replace(from);
        });
      } else throw new Error("something went wrong");
    } catch (exception) {
      notificationDisplay(dispatch, exception.message, "error");
    }
    /* dispatch(loaded()); */
    setLoading(false);
  };

  const loginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      /*  dispatch(loading()); */
      setLoading(true);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          },
        )
        .then(async (res) => {
          try {
            const response = await loginAlternative({
              email: res.data.email,
              password: res.data.email + res.data.id,
            });
            if (response.data) throw new Error(response.data.error);
            localStorage.setItem("userAuth", response.token);
            localStorage.setItem("refreshToken", response.refreshToken);
            localStorage.setItem("role", response.role);
            const trainerInfo = await getTrainerByUserId(response.id_user);
            if (response.role === "admin" || (response.token && trainerInfo)) {
              const responseToSend = {
                ...response,
                ...trainerInfo,
              };
              dispatch(addInfo(responseToSend));
              onLanguageChange(trainerInfo.language);

              auth.signin(() => {
                history.replace(from);
              });
            } else throw new Error("something went wrong");
          } catch (exception) {
            notificationDisplay(dispatch, exception.message, "error");
          }
          setLoading(false);
          /*  dispatch(loaded()); */
        })
        .catch((err) => setLoading(true));
    },
  });

  return (
    <section className={styles.root}>
      <aside className={styles.sideAuth} />
      <aside className={styles.formSide}>
        <div className={styles.header}>
          <MenuLang>
            <MenuButton as={Button} variant="ghost" fontSize="40px" padding={0} borderRadius="full">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  name="english lang"
                  src={lang === "en" ? UsaLogo : SpainLogo}
                  width="30px"
                  height="30px"
                />
              </Box>
            </MenuButton>
            <MenuList zIndex="101">
              {languages.map(({ code, name, countryCode }) => (
                <MenuItem key={code} color="ptText.500" onClick={() => onLanguageChange(code)}>
                  <span className={`flag-icon flag-icon-${countryCode}`} />
                  <span>{name}</span>
                </MenuItem>
              ))}
            </MenuList>
          </MenuLang>
        </div>
        <div className={styles.formContainer}>
          <img className={styles.logo} src={logo} alt="perfecttrainer logo" />
          <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
            <h4 className={`${styles.title} ${styles.left}`}>{t("sign_in")}</h4>
            <div className={styles.googleButton} onClick={() => loginGoogle()} aria-hidden="true">
              <img src={googleSvg} alt="google" />
              {t("loginWithGoogle")}
            </div>
            <div className={styles.emailLogin}>
              <span>{t("loginWithEmail")}</span>
            </div>
            <Controller
              control={control}
              defaultValue=""
              name="email"
              rules={{
                required: true,
                pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
              }}
              render={({ field }) => (
                <Input
                  variant="filled"
                  {...field}
                  {...inputStyle}
                  placeholder={t("email")}
                  mb="0"
                />
              )}
            />
            {errors.email?.type === "required" && (
              <span className={styles.message}>{t("error_required")}</span>
            )}
            {errors.email?.type === "pattern" && (
              <span className={styles.message}>{t("error_email")}</span>
            )}

            <Controller
              control={control}
              defaultValue=""
              name="password"
              rules={{ required: true, minLength: 4 }}
              render={({ field }) => <PasswordInput field={field} placeholder={t("password")} />}
            />
            {errors.password?.type === "required" && (
              <span className={styles.message}>{t("error_required")}</span>
            )}
            {errors.password?.type === "minLength" && (
              <span className={styles.message}>{t("error_min", { num: 4 })}</span>
            )}
            <div className={styles.forgot}>
              <Link to="changepass" className={styles.link}>
                {t("forget_password")}
              </Link>
            </div>

            {/* <button type="submit" className={styles.submit}>
             
            </button> */}
            <Button
              className={styles.submit}
              w="100%"
              colorScheme="ptRed"
              type="submit"
              isLoading={loading}>
              {t("login")}
            </Button>
            <div className={styles.signup}>
              {t("not_account")}
              <Link to="/signup" className={styles.link}>
                {t("sign_up")}
              </Link>
            </div>
          </form>
        </div>

        <div className={styles.footer}>
          <div className={styles.term}>
            <a href="#a">{t("privacy_policy")}</a>
            <a href="#a">{t("terms_use")}</a>
            <a href="#a">Legal</a>
          </div>
          <span>
            Copyright © {currentYear} The Institute Of Human Performance | {t("copyright_text")}
          </span>
        </div>
      </aside>
    </section>
  );
};

export default Login;
