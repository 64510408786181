/* eslint-disable no-useless-escape */
import React from "react";
// ? External Dependencies
import { useForm, Controller } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import { CheckCircleIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import {
  Button,
  Menu as MenuLang,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Avatar,
  Text,
} from "@chakra-ui/react";

// ? local Dependencies

import UsaLogo from "assets/eeuu.png";
import SpainLogo from "assets/spain.png";
import useLang from "hooks/useLang";
import languages from "utils/languages";
import i18next from "i18next";
import styles from "../login/login.module.scss";
import logo from "../../../assets/3.png";

const SubscriptionDone = () => {
  const { t } = useTranslation();
  const { from } = { from: { pathname: "/user/dashboard" } };
  const currentYear = new Date().getFullYear();

  const { lang } = useLang();
  const onLanguageChange = (code) => {
    i18next.changeLanguage(code);
  };

  return (
    <section className={styles.root}>
      <aside className={styles.formSide}>
        <div className={styles.header}>
          <MenuLang>
            <MenuButton as={Button} variant="ghost" fontSize="40px" padding={0} borderRadius="full">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  name="english lang"
                  src={lang === "en" ? UsaLogo : SpainLogo}
                  width="30px"
                  height="30px"
                />
              </Box>
            </MenuButton>
            <MenuList zIndex="101">
              {languages.map(({ code, name, countryCode }) => (
                <MenuItem key={code} color="ptText.500" onClick={() => onLanguageChange(code)}>
                  <span className={`flag-icon flag-icon-${countryCode}`} />
                  <span>{name}</span>
                </MenuItem>
              ))}
            </MenuList>
          </MenuLang>
        </div>
        <div className={styles.formContainer}>
          <img className={styles.logo} src={logo} alt="perfecttrainer logo" />
          <div className={styles.form}>
            <CheckCircleIcon boxSize={8} color="green" mt={8} mb={4} />
            <Text textAlign="center" mb={8}>
              {t("subscriptionDone")}
            </Text>
            <Button as={Link} to="/login" type="submit" w="100%" colorScheme="ptRed">
              {t("letsGo")}
            </Button>
          </div>
        </div>

        <div className={styles.footer}>
          <div className={styles.term}>
            <a href="#a">{t("privacy_policy")}</a>
            <a href="#a">{t("terms_use")}</a>
            <a href="#a">Legal</a>
          </div>
          <span>
            Copyright © {currentYear} The Institute Of Human Performance | {t("copyright_text")}
          </span>
        </div>
      </aside>
    </section>
  );
};

export default SubscriptionDone;
