/* eslint-disable eqeqeq */
/* eslint-disable no-mixed-operators */
/* eslint-disable no-bitwise */
export default function generateId() {
  let d = new Date().getTime();
  const uuid = 'xxxxxxxxx4xxxyxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = (d + Math.random() * 16) % 16 | 0;
    d = Math.floor(d / 16);
    return (c == 'x' ? r : (r & 0x3 | 0x8)).toString(16);
  });
  return uuid;
}
