/* eslint-disable import/prefer-default-export */
import instance from "./_axiosConfig";

const api = instance;

const getAllActions = async () => {
  try {
    const res = await api.get("/action/");
    return res.data.actions;
  } catch (error) {
    return error.message;
  }
};

export { getAllActions };
