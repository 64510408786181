/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useReducer, useState } from "react";
import { Badge, Box, Button, Text } from "@chakra-ui/react";

import { superSetColorsHex } from "theme/commonStyles";
import styled from "styled-components";
import useLang from "hooks/useLang";

import NoteIcon from "assets/icons/note.png";
import CommentIcon from "@mui/icons-material/Comment";
import stylesExercise from "../ExerciseDisplay/ExerciseDisplay.module.css";
import stylesdays from "../DayDisplay/DayDisplay.module.css";

const HistoryClientDays = ({ days }) => {
  const { t, lang } = useLang();
  const [isNoteDisplay, toggleNoteDisplay] = useState(false);

  const Container = styled.a`
    border-radius: 20px;
    background-color: white;
    box-shadow: "none";
    box-sizing: border-box;
    padding: 0px;
    margin-bottom: 10px;
    user-select: none;

    /* anchor overrides */
    color: #091e42;

    &:hover,
    &:active {
      color: #091e42;
      text-decoration: none;
    }

    &:focus {
      outline: none;
      border-color: white;
      box-shadow: none;
    }

    /* flexbox */
    display: flex;
  `;
  return (
    <Container>
      <article className={stylesdays.container} style={{ width: "320px" }}>
        <Box display="flex" flexDirection="row" fontSize=" 0.8rem" justifyContent="space-between">
          <Box display="flex" justifyContent="center" alignItems="center" style={{ gap: "10px" }}>
            <Button
              bg="#3a3a3a"
              borderRadius="10px"
              color="white"
              variant="ghost"
              colorScheme="black"
              type="button"
              gap="10px"
              size="xs">
              <Text fontWeight="bold">{`${t("list_day")} ${days.day}`}</Text>
            </Button>
          </Box>
          <Box>
            <Button
              className={stylesdays.notes__button}
              size="xs"
              bg="transparent"
              onClick={() => toggleNoteDisplay(!isNoteDisplay)}>
              <Text>
                <img
                  className={days.notes[lang] !== "" ? stylesdays.notesActive : null}
                  src={NoteIcon}
                  alt="note icon"
                  style={{ width: "20px" }}
                />
              </Text>
            </Button>
          </Box>
        </Box>
        <div className={stylesdays.main}>
          {isNoteDisplay && (
            <div className={stylesdays.notes}>
              <div className={stylesdays.notes}>
                <textarea disabled id={`textarea-${days._id}`} defaultValue={days.notes[lang]} />
              </div>
            </div>
          )}
          {days.exercises?.map((exercise) => (
            <article
              className={stylesExercise.container}
              style={{ backgroundColor: `${superSetColorsHex[exercise.colors.hard]}` }}>
              <h5 style={{ display: "flex", justifyContent: "space-between" }}>
                <span>
                  {exercise.id_exercise?.name[lang] || (
                    <span style={{ textTransform: "uppercase", color: "red" }}>
                      {t("undefined_exercise")}
                    </span>
                  )}
                </span>
              </h5>
              <div className={stylesExercise.list}>
                {exercise.sets?.map((s) => (
                  <p>
                    <span className={stylesExercise.set}>Set {s.set_number}</span>
                    {s.load}
                    {s.typeWeight === "others" ? "" : s.typeWeight} / {s.reps}
                    {s?.typeReps ?? ""}
                  </p>
                ))}
              </div>
            </article>
          ))}
        </div>
      </article>
    </Container>
  );
};

export default HistoryClientDays;
