/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import useLang from "hooks/useLang";

import Board from "./components/Board";

const Template = ({ preInfo, days, name, phase, client, setIsCreate, clientInfo }) => {
  const [filtered, setFiltered] = useState([]);
  const { t } = useLang();
  useEffect(() => {
    const daysField = preInfo.map((i) => {
      const add = {
        ...i,
        id: i._id,
        author: {
          id: i._id,
          name: i.week,
          colors: {
            soft: "white",
            hard: "white",
          },
        },
      };
      return add;
    });
    const FormatDays = [...daysField];
    const array = FormatDays?.map(({ week }) => week);
    const newarray = [...new Set(array)];
    const dataFilter = newarray?.map((item) =>
      daysField?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );
    setFiltered(dataFilter);
  }, []);
  useEffect(() => {
    const sidebar = document.getElementsByClassName("sidebar")[0];
    window.onbeforeunload = (e) => {
      console.log(e);
      return "Leaving this page will reset the wizard";
    };
    window.history.pushState(null, document.title, window.location.href);
    window.onpopstate = () => {
      const leavePage = window.confirm(t("back_confirmation"));
      if (leavePage) {
        window.onpopstate = null;
        window.history.back();
      } else {
        window.history.pushState(null, document.title, window.location.href);
      }
    };
    if (sidebar) sidebar.setAttribute("class", "sidebar collapse");
    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
      if (sidebar) sidebar.setAttribute("class", "sidebar");
    };
  }, []);
  return (
    <div id="board">
      <Board
        initial={filtered}
        withScrollableColumns
        name={name}
        setIsCreate={setIsCreate}
        clientSource={clientInfo}
        phase={phase}
        client={client}
        setFiltered={setFiltered}
      />
    </div>
  );
};

Template.propTypes = {};

export default Template;
