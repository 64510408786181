/* eslint-disable max-len */
/* eslint-disable react/prop-types */
// import useGetAll from 'hooks/useGetAll';
import React, { createContext, useContext, useEffect, useState, useReducer } from "react";
import { getPreprogrammed } from "services/preProgrammed";
import { getTemplates } from "services/template";

const ProgramContext = createContext();

const ProgramContextProvider = ({ children }) => {
  const [page, setPage] = useState({ mode: "main", type: "", owner: "" });
  const [data, setData] = useState(undefined);
  const [currentData, setCurrentData] = useState(null);
  const [isError, toggleError] = useReducer((prev) => !prev, false);
  const [isLoading, toggleIsLoading] = useReducer((prev) => !prev, false);

  /* useEffect(() => {
    setData(undefined);
    setCurrentData(undefined);
    if (page.mode === "template") {
      toggleIsLoading();
      getTemplates()
        .then((res) =>
          page.owner === "admin"
            ? res.filter((item) => !item.trainer)
            : res.filter((item) => item.trainer === page.owner),
        )
        .then((res) => setData(res))
        .catch(() => toggleError())
        .finally(() => toggleIsLoading());
    }
    if (page.mode === "preprograms") {
      toggleIsLoading();
      getPreprogrammed()
        .then((res) =>
          page.owner === "admin"
            ? res.filter((item) => !item.trainer)
            : res.filter((item) => item.trainer === page.owner),
        )
        .then((res) => setData(res))
        .catch(() => toggleError())
        .finally(() => toggleIsLoading());
    }
  }, [page.mode, page.owner]); */

  const handlePageModeChange = ({ target }) => {
    console.log("handle?", target);
    setPage({ mode: target.id.split("/")[0], type: "list", owner: target.id.split("/")[1] });
  };
  const handlePageTypeChange = ({ target }) =>
    setPage({ mode: target.id.split("/")[0], type: "preview", owner: target.id.split("/")[1] });
  const handleCurrentRecord = (item) => {
    console.log(item);
    setCurrentData(item);
  };
  const handleDeleteData = ({ id }) => setData((prev) => prev.filter((item) => item._id !== id));

  return (
    <ProgramContext.Provider
      value={{
        handlePageModeChange,
        handlePageTypeChange,
        handleCurrentRecord,
        handleDeleteData,
        toggleIsLoading,
        page,
        data,
        currentData,
        isError,
        isLoading,
      }}>
      {children}
    </ProgramContext.Provider>
  );
};

export default ProgramContextProvider;

const useProgramContext = () => useContext(ProgramContext);

export { useProgramContext };
