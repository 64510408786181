/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React from "react";
import Select from "react-select";

const Selector = ({ name, values, onChange, currentValue, isMulti, label }) => (
  <div style={{ marginBottom: "10px", zIndex: "200" }}>
    <label htmlFor={name}>{label}</label>
    <Select
      options={values}
      name={name}
      onChange={onChange}
      defaultValue={currentValue}
      isMulti={isMulti}
    />
  </div>
);

export default Selector;
