/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useReducer, useEffect } from "react";
import { Heading } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { string, func } from "prop-types";

import { getAllEvaluationExercises } from "services/exercise";
import { getFunctionaProgramModel } from "utils/exerciseModel";
import notificationDisplay from "utils/notificationDisplay";
import useLang from "hooks/useLang";

import functionalReducer, { initialState, updateData } from "./functionalReducer";
import BoardAssessments from "../Board";

const FunctionalProgram = ({ client, setStep }) => {
  const [state, dispatch] = useReducer(functionalReducer, initialState);
  const [isBtnLoading, toggleBtn] = useReducer((prev) => !prev, false);
  const reduxDispatch = useDispatch();
  const { t, lang } = useLang();

  const handleGenerateProgram = async () => {
    toggleBtn();
    const generateRandom = (size) => Math.floor(Math.random() * size);
    const fillFields = (data, exercises) => {
      data.forEach((item, idx) => {
        const weakList = exercises.filter((exer) => exer.weakness === item.weakness);
        const number = generateRandom(weakList.length);
        const selected = weakList[number];
        dispatch(updateData(selected.name[lang], idx, "exercise"));
        dispatch(updateData(selected._id, idx, "id"));
      });
    };

    try {
      const allExercises = await getAllEvaluationExercises();
      fillFields(state.data, allExercises);
      toggleBtn();
    } catch (e) {
      toggleBtn();
      return notificationDisplay(reduxDispatch, t("something_wrong", "error"));
    }
  };

  // set random exercieses in the field in the first render
  useEffect(() => {
    handleGenerateProgram();
  }, []);

  return (
    <div>
      <Heading size="sm" textAlign="center" mb="10px">
        {t("functional_program")}
      </Heading>
      <BoardAssessments
        preInfo={getFunctionaProgramModel({ sessions: state.data })}
        workout="functional_program"
        client={client}
        isBtnLoading={isBtnLoading}
        handleGenerateProgram={handleGenerateProgram}
      />
    </div>
  );
};

FunctionalProgram.propTypes = {
  client: string.isRequired,
  setStep: func.isRequired,
};
export default FunctionalProgram;
