/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import {
  Heading,
  Flex,
  Button,
  ButtonGroup,
  IconButton,
  Input,
  AbsoluteCenter,
  Box,
  Text,
  Popover,
  PopoverTrigger,
  Portal,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverBody,
  useToast,
} from "@chakra-ui/react";
import CopyIcon from "@material-ui/icons/FileCopy";
import ShareIcon from "@mui/icons-material/Share";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "react-qr-code";
import { useSelector } from "react-redux";
import { getClientById } from "services/clients";
import GeneratePdfAssessments from "utils/AssessmentPdf";
import useLang from "hooks/useLang";
import ReportCard from "./ReportCard";
import styles from "./Welcome.module.scss";

// eslint-disable-next-line react/prop-types
const Report = ({
  client,
  evaluations,
  evaluationId,
  dispatch,
  setStep,
  isEdit,
  saveClientEvaluation,
}) => {
  const { t, lang } = useLang();
  const [dataClient, setDataClient] = useState({});
  const datatrainer = useSelector(({ user }) => user);
  const [showRenderPdf, setShowRenderPdf] = useState(null);

  const toast = useToast();
  const loaderGeneratePdf = (id) => {
    setTimeout(() => {
      setShowRenderPdf(id);
    }, 500);
  };

  useEffect(() => {
    const getClientInformation = async () => {
      try {
        console.log(client);
        const res = await getClientById(client.split("___")[1]);
        if (res.data) throw new Error(res.data.message);
        setDataClient(res.client);
      } catch (err) {
        console.log(err);
      }
    };
    getClientInformation();
  }, []);
  const copyClipboard = () => {
    const copyText = document.getElementById("routine-url");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard.writeText(copyText.value);
    toast({
      position: "bottom",
      render: () => (
        <Box color="white" p={3} bg="black" textAlign="center">
          {t("copiedClipboard")}
        </Box>
      ),
    });
  };
  return (
    <>
      <div>
        <div className={styles.reportHeading}>
          <Heading size="lg">
            {t("eval_name", {
              name: `${client.split("___")[0]}${
                dataClient.lastName ? ` ${dataClient?.lastName}` : ""
              }`,
            })}
          </Heading>
          <ButtonGroup justifyContent="end" flexWrap="wrap" gridGap={4}>
            <Popover>
              <PopoverTrigger>
                <Button variant="outline" size="sm" leftIcon={<ShareIcon fontSize="inherit" />}>
                  {t("shareAssessment")}
                </Button>
              </PopoverTrigger>
              <Portal>
                <PopoverContent>
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverBody>
                    <Text
                      fontWeight="bold"
                      textAlign="center"
                      my={4}
                      fontSize="xs"
                      colorScheme="gray">
                      {t("scanQr")}
                    </Text>
                    <div style={{ height: "auto", margin: "0 auto", maxWidth: 120, width: "100%" }}>
                      <QRCode
                        size={256}
                        style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                        value={`${window.location.protocol}//${window.location.host}/shared-assessment/${evaluationId}`}
                        viewBox="0 0 256 256"
                      />
                    </div>
                    <Box position="relative" my={6} p={2}>
                      <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs">
                        {t("orCopyLink")}
                      </AbsoluteCenter>
                    </Box>
                    <Flex gridGap={4} alignItems="center">
                      <Input
                        disabled
                        readOnly
                        value={`${window.location.protocol}//${window.location.host}/shared-assessment/${evaluationId}`}
                        cursor="pointer"
                        id="routine-url"
                        fontSize="12px"
                        onClick={copyClipboard}
                      />
                      <IconButton
                        isRound
                        size="sm"
                        colorScheme="red"
                        variant="ghost"
                        onClick={copyClipboard}
                        icon={<ContentCopyIcon fontSize="inherit" />}
                      />
                    </Flex>
                    <Flex my={2} justifyContent="flex-end">
                      <Button
                        leftIcon={<VisibilityIcon fontSize="inherit" />}
                        as="a"
                        href={`${window.location.protocol}//${window.location.host}/shared-assessment/${evaluationId}`}
                        target="_blank"
                        size="sm">
                        Preview
                      </Button>
                    </Flex>
                  </PopoverBody>
                </PopoverContent>
              </Portal>
            </Popover>
            {showRenderPdf === evaluations._id ? (
              <GeneratePdfAssessments
                evaluation={evaluations}
                dataClient={dataClient}
                datatrainer={datatrainer}
                pageType="report"
              />
            ) : (
              <Button
                size="sm"
                variant="outline"
                colorScheme="red"
                onClick={() => loaderGeneratePdf(evaluations._id)}>
                {t("generasting_pdf")}
              </Button>
            )}

            <Button
              colorScheme="ptRed"
              size="sm"
              onClick={() => {
                setStep((prev) => prev + 1);
              }}>
              {t("create_program")}
            </Button>
          </ButtonGroup>
        </div>

        <Flex wrap="wrap" gridGap={4} className={styles.reportsContainer}>
          {evaluations?.map((evaluation, index) => (
            <ReportCard
              isEdit={isEdit}
              evaluation={evaluation}
              key={index}
              dispatch={dispatch}
              lang={lang}
              saveClientEvaluation={saveClientEvaluation}
              t={t}
            />
          ))}
        </Flex>
      </div>
      {/* <Alert onClose={onClose} isOpen={isOpen} t={t} setProgram={handleSelectProgram} /> */}
    </>
  );
};

export default Report;
