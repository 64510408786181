import React from 'react';
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import propTypes from 'prop-types';

const DeleteAlert = ({ isOpen, onClose, onDelete }) => (
  <AlertDialog
    isOpen={isOpen}
    onClose={onClose}
  >
    <AlertDialogOverlay>
      <AlertDialogContent>
        <AlertDialogHeader fontSize="lg" fontWeight="bold">
          Delete Customer
        </AlertDialogHeader>

        <AlertDialogBody>
          Are you sure? You can&apos;t undo this action afterwards.
        </AlertDialogBody>

        <AlertDialogFooter>
          <Button onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="red" onClick={onDelete} ml={3}>
            Delete
          </Button>
        </AlertDialogFooter>
      </AlertDialogContent>
    </AlertDialogOverlay>
  </AlertDialog>
);

DeleteAlert.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  onDelete: propTypes.func.isRequired,
};

export default DeleteAlert;
