import instance from "./_axiosConfig";

const api = instance;

const getExercises = async () => {
  try {
    const response = await api.get("/exercise/");
    return response.data.exercises;
  } catch (error) {
    return error.response;
  }
};

const getTrainerExercises = async (trainerId) => {
  try {
    const response = await api.get(`/exercise/trainer/${trainerId}`);
    return response.data.exercises;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const getEvaluationExercises = async (number) => {
  try {
    const response = await api.get(`/exercise/weakness/${number}`);
    return response.data.exercises;
  } catch (error) {
    return error.response;
  }
};

const getAllEvaluationExercises = async () => {
  try {
    const response = await api.get("/exercise/weakness/all");
    return response.data.exercises;
  } catch (error) {
    throw new Error(error);
  }
};

const addExercises = async (data) => {
  try {
    const response = await api.post("/exercise/", data);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const updateExercise = async (idExercise, data) => {
  try {
    const response = await api.put(`/exercise/${idExercise}`, data);
    return response.data;
  } catch (exception) {
    return exception.response;
  }
};

const deleteExercise = async (idExercise) => {
  try {
    const response = await api.delete(`/exercise/${idExercise}`);
    return response.data;
  } catch (exception) {
    return exception.response;
  }
};

export {
  getExercises,
  addExercises,
  updateExercise,
  deleteExercise,
  getTrainerExercises,
  getEvaluationExercises,
  getAllEvaluationExercises,
};
