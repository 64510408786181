import React from 'react';
import { string, func } from 'prop-types';

import ErrorBoundary from 'components/common/ErrorBoundary';
import FunctionalProgram from './FunctionalProgram';
import HybridProgram from './HybridProgram';

const ProgramCreation = ({ program, client, setStep }) => (
  <>
    {
    program === 'functional' && (
      <ErrorBoundary>
        <FunctionalProgram client={client} setStep={setStep} />
      </ErrorBoundary>
    )
  }
    {
    program === 'hybrid' && (
      <ErrorBoundary>
        <HybridProgram client={client} setStep={setStep} />
      </ErrorBoundary>
    )
  }
  </>
);

ProgramCreation.propTypes = {
  program: string.isRequired,
  client: string.isRequired,
  setStep: func.isRequired,
};

export default ProgramCreation;
