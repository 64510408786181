const categoriesOptions = [
  { value: "express", label: "express" },
  { value: "metabolic", label: "metabolic" },
  { value: "general", label: "general" },
  { value: "women' favourite", label: "women' favourite" },
  { value: "men' favourite", label: "men' favourite" },
  { value: "performance", label: "performance" },
  { value: "cardio", label: "cardio" },
];

const bodyPartOptions = [
  { value: "full body", label: "full body" },
  { value: "upper body", label: "upper body" },
  { value: "lower body", label: "lower body" },
  { value: "leg/hips", label: "opt_legs" },
  { value: "opt_lumbar", label: "opt_lumbar" },
  { value: "glutes", label: "glutes" },
  { value: "isquiotibiales", label: "opt_calves" },
  { value: "cuadriceps", label: "cuadriceps" },
  { value: "opt_adductors", label: "opt_adductors" },
  { value: "opt_abductors", label: "opt_abductors" },
  { value: "calf", label: "calf" },
  { value: "arms", label: "opt_arms" },
  { value: "chest", label: "chest" },
  { value: "back", label: "opt_back" },
  { value: "shoulder", label: "shoulder" },
  { value: "biceps", label: "biceps" },
  { value: "triceps", label: "triceps" },
  { value: "opt_forearms", label: "opt_forearms" },
  { value: "core", label: "core" },
  { value: "posterior core", label: "posterior core" },
  { value: "anterior core", label: "anterior core" },
  { value: "opt_abs", label: "opt_abs" },
  { value: "opt_hip_flexors", label: "opt_hip_flexors" },
  { value: "lateral core", label: "lateral core" },
  { value: "opt_rotational_core", label: "opt_rotational_core" },
  { value: "others", label: "others" },

];

const equipmentOptions = [
  { value: "body weight", label: "body weight" },
  { value: "barbell", label: "barbell" },
  { value: "dumbells/kettlebell", label: "dumbells/kettlebell" },
  { value: "machine", label: "machine" },
  { value: "bands/pulleys", label: "bands/pulleys" },
  { value: "resistance_band", label: "resistance_band" },
  { value: "medicine ball", label: "medicine ball" },
  { value: "stability ball", label: "stability ball" },
  { value: "agility ladders", label: "agility ladders" },
  { value: "multiple", label: "multiple" },
  { value: "disc", label: "disc" },
  { value: "suspension", label: "suspension" },
  { value: "rope", label: "rope" },
  { value: "ez_bar", label: "ez_bar" },
  { value: "smith_machine", label: "smith_machine" },
  { value: "sled", label: "sled" },
  { value: "landmine", label: "landmine" },
  { value: "hg360", label: "hg360" },
  { value: "agility_ladder", label: "agility_ladder" },
  { value: "cones", label: "cones" },
  { value: "vallas", label: "vallas" },
  { value: "others", label: "others" },
];

export { bodyPartOptions, equipmentOptions, categoriesOptions };
