import React from 'react'
import { Skeleton, Stack } from "@chakra-ui/react";

const LoadingResponseNutrition = () => {
  return (
    <Stack>
      <Skeleton height="20px" w="300px" />
      <Skeleton height="20px" w="400px" />
      <Skeleton height="20px" w="300px" />
      <Skeleton height="20px" w="320px" />
      <Skeleton height="20px" w="475px" />
      <Skeleton height="20px" w="300px" />
      <Skeleton height="20px" w="300px" />
      <Skeleton height="20px" w="440px" />
      <Skeleton height="20px" w="300px" />
      <Skeleton height="20px" w="200px" />
      <Skeleton height="20px" w="300px" />
      <Skeleton height="20px" w="267px" />
      <Skeleton height="20px" w="100px" />
      <Skeleton height="20px" w="300px" />
      <Skeleton height="20px" w="300px" />
      <Skeleton height="20px" w="380px" />
      <Skeleton height="20px" w="300px" />
      <Skeleton height="20px" w="400px" />
      <Skeleton height="20px" w="500px" />
    </Stack>
  );
};

export default LoadingResponseNutrition;
