/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable react/prop-types */

import React, { useEffect, useState } from 'react';
import propTypes from 'prop-types';
import {
  Button,
  Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Badge,
} from '@chakra-ui/react';
import MaterialTable from 'material-table';

import { infoStyle } from 'theme/commonStyles';
import localizationMaterialTable from 'helpers/localizationMaterialTable';

import customFilterAndSearch from 'helpers/customFilterAndSearch';
import tableIcons from 'theme/tableIcons';
import { getEvaluationExercises } from 'services/exercise';

import useLang from 'hooks/useLang';
import ErrorBoundary from 'components/common/ErrorBoundary';
import Toggle from 'components/common/Toggle';
import Recomendation from 'components/routines/workout/Recomendation';
import { updateData } from '../FunctionalProgram/functionalReducer';

const ProgramExercises = ({
  isOpen, onClose, final, position, dispatch, defaultValue, setDefault, weakness, traditional,
}) => {
  const [data, setData] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(undefined);
  const { t, lang } = useLang();
  const localization = localizationMaterialTable();

  const handleClick = (rowIdx, columnIdx, name, exerciseId) => {
    dispatch(updateData(name, rowIdx, columnIdx, exerciseId));
    onClose();
  };

  useEffect(() => {
    if (weakness !== 0) {
      setIsLoading(true);
      getEvaluationExercises(weakness)
        .then((resolve) => (traditional
          ? resolve.filter((item) => item.category_id[0].name === 'Traditional')
          : resolve))
        .then((resolve) => setData(resolve))
        .then(() => setIsLoading(false))
        .catch((e) => {
          setError(e);
          setIsLoading(false);
        });
    }
  }, [weakness]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      finalFocusRef={final}
    >
      <ModalOverlay />
      <ModalContent maxWidth="35rem">
        <ModalHeader>
          {t('select_exercise')}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {
          isLoading && <p style={infoStyle}>{t('loading_exercises')}</p>
        }
          {
          error && <p style={infoStyle}>{t('something_wrong')}</p>
        }
          {
            (data) && (
              <ErrorBoundary>
                <Toggle Component={<Badge variant="outline">{t('favourite_exercises')}</Badge>}>
                  <Recomendation handleClick={handleClick} position={position} />
                </Toggle>
              </ErrorBoundary>
            )
          }
          {
          (data) && (
            <MaterialTable
              columns={[
                {
                  title: t('table_name'),
                  field: 'name',
                  cellStyle: { width: '80%' },
                  customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'name'),
                },
                {
                  title: t('table_category'),
                  field: 'category',
                  customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'category'),
                },
                { title: t('table_options'), field: 'options' },
              ]}
              data={data.map(({ name, category_id, _id }) => ({
                name: name[lang],
                category: category_id[0].name,
                options: <Button onClick={
                  () => handleClick(position.rowIdx, position.columnIdx, name[lang], _id)
                }
                >
                  select
                </Button>,
              }))}
              title=""
              icons={tableIcons}
              localization={localization}
            />
          )
        }
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ProgramExercises.defaultProps = {
  final: null,
};

ProgramExercises.propTypes = {
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  dispatch: propTypes.func.isRequired,
  position: propTypes.shape({
    rowIdx: propTypes.number.isRequired,
    columnIdx: propTypes.string.isRequired,
  }).isRequired,
  final: propTypes.node,
};

export default ProgramExercises;
