import React, { Component } from 'react';
import propTypes from 'prop-types';
import ErrorScreen from '../ErrorScreen';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this._state = { error: null };
  }

  get state() {
    return this._state;
  }

  set state(value) {
    this._state = value;
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  render() {
    const { error } = this.state;
    const { children, Fallback } = this.props;

    if (error && !Fallback) return <ErrorScreen error={error} />;
    if (error) return <Fallback error={error} />;
    return children;
  }
}

ErrorBoundary.defaultProps = {
  Fallback: undefined,
};

ErrorBoundary.propTypes = {
  children: propTypes.node.isRequired,
  Fallback: propTypes.func,
};

export default ErrorBoundary;
