/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable no-alert */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Grid, IconButton } from "@chakra-ui/react";
import { EditIcon, CloseIcon } from "@chakra-ui/icons";
import { Redirect } from "react-router-dom";
import { login } from "reducers/trainer.reducer";

import useGetAll from "hooks/useGetAll";
import { getTrainerByUserId } from "services/trainer";
import notificationDisplay from "utils/notificationDisplay";

import Info from "./Info";
import Edit from "./Edit";
import styles from "./Profile.module.scss";
import ShowFields from "./ShowFields";

const Profile = () => {
  const [edit, setEdit] = useState(false);
  const [showFieldPdf, setShowFieldPdf] = useState(false);
  const { id_user } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const { data, isError, isLoading, isSuccess } = useGetAll("", id_user, getTrainerByUserId);
  if (data) dispatch(login(data));
  if (isSuccess && data === undefined) {
    const config = {
      pathname: "/signup",
      state: {
        id_user,
        step: 2,
      },
    };
    notificationDisplay(dispatch, "Please complete your trainer data", "error");
    return <Redirect to={config} />;
  }

  useEffect(() => {
    document.title = "Perfect Trainer App | Profile";
  }, []);

  return (
    <div className={styles.root}>
      <div className={styles.container}>
        {isLoading && <p style={{ padding: "2rem 0" }}>Loading Profile Data</p>}
        {isError && <p style={{ padding: "2rem 0" }}>Something went wrong</p>}
        {isSuccess && edit && <Edit setEdit={setEdit} edit={edit} />}
        {isSuccess && showFieldPdf && !edit && (
          <ShowFields setShowFieldPdf={setShowFieldPdf} showFieldPdf={showFieldPdf} />
        )}
        {isSuccess && !edit && !showFieldPdf && (
          <Info
            data={data}
            setEdit={setEdit}
            showFieldPdf={showFieldPdf}
            setShowFieldPdf={setShowFieldPdf}
            edit={edit}
          />
        )}
      </div>
    </div>
  );
};

export default Profile;
