/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
// ? External Dependecies
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { func } from "prop-types";
import {
  Button,
  ButtonGroup,
  Checkbox,
  Container,
  Flex,
  IconButton,
  Select,
  Box,
} from "@chakra-ui/react";

// ? Local Dependecies
import useAdd from "hooks/useAdd";
import { transformClientData } from "utils/transformData";
import notificationDisplay from "utils/notificationDisplay";
import { createClient } from "services/clients";

import { infoStyle } from "theme/commonStyles";
import TextInput from "components/common/TextInput";
import DateInput from "components/common/DateInput";

const groupStyle = {
  alignItems: "center",
  display: "flex",
  justifyContent: "flex-end",
  margin: "1rem 0",
};
const addressBtn = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  color: "#444444",
};

const CreateNewOrGuestClient = ({ setStep, setClient }) => {
  const [secondCity, setSecondCity] = useState(false);
  const { id_user } = useSelector(({ user }) => user);
  const { control, handleSubmit, register, watch } = useForm({
    defaultValues: {
      isGuest: true,
    },
  });
  const { lang, t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  /* const { mutate, isLoading, isSuccess, isError } = useAdd("clients", createClient); */
  const onClose = (id, name) => {
    setClient(`${name}___${id}`);
    setStep((prev) => prev + 1);
  };
  const onSubmit = async (data) => {
    try {
      const response = await createClient(transformClientData(data, id_user, lang, data.isGuest));
      if (response.data) throw new Error(response.data.error);
      onClose(response.client._id, response.client.name);
    } catch (err) {
      notificationDisplay(err.message, "something went wrong", "error");
    }
  };

  /* if (isLoading) return <p style={infoStyle}>{t("data_addition")}</p>;
  if (isError) return <p style={infoStyle}>{t("something_wrong")}</p>; */
  /*  if (isSuccess) {
    onClose();
    notificationDisplay(dispatch, "Client added succesfully", "success");
  } */
  return (
    <Box bg="white" border="16px" padding="1rem">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Checkbox {...register("isGuest")}>{t("isGuest")}</Checkbox>
        <Flex gridGap={4}>
          <TextInput control={control} name="name" place={t("name")} />
          <TextInput control={control} name="lastName" place={t("lastName")} />
        </Flex>
        <Flex gridGap={4}>
          <TextInput control={control} name="phone" place={t("phonenumber")} />
          <TextInput control={control} name="email" place={t("email")} />
        </Flex>
        {watch("isGuest") === false ? (
          <>
            <Flex gridGap={4}>
              <DateInput
                control={control}
                name="birth_date"
                place={t("birth_date")}
                required={false}
              />
              <div style={{ width: "100%" }}>
                <p style={{ marginBottom: "0.5rem" }}>{t("gender")}</p>
                <Controller
                  control={control}
                  name="gender"
                  defaultValue=""
                  required={false}
                  render={({ field }) => (
                    <Select
                      placeholder={t("select_gender")}
                      backgroundColor="#ececec"
                      focusBorderColor="ptRed.500"
                      color="#444444"
                      {...field}>
                      <option value="male">{t("opt_male")}</option>
                      <option value="female">{t("opt_female")}</option>
                    </Select>
                  )}
                />
              </div>
            </Flex>

            <div role="group" style={addressBtn}>
              <TextInput
                control={control}
                name="street1"
                place={t("principal_address")}
                required={false}
              />
              <IconButton
                mb="4"
                icon={!secondCity ? <AddIcon /> : <MinusIcon />}
                onClick={() => setSecondCity(!secondCity)}
              />
            </div>

            {secondCity && (
              <TextInput
                control={control}
                name="street2"
                place={t("secondary_address")}
                required={false}
              />
            )}
            <TextInput control={control} name="city" place={t("city")} required={false} />
            <TextInput control={control} name="state" place={t("state")} required={false} />
            <TextInput control={control} name="zip" place={t("zip")} required={false} />

            <fieldset>
              <TextInput
                control={control}
                name="speciality"
                place={t("main_goals")}
                required={false}
              />
            </fieldset>
          </>
        ) : null}

        <ButtonGroup style={groupStyle} colorScheme="ptRed">
          <Button type="submit">{t("save")}</Button>
        </ButtonGroup>
      </form>
    </Box>
  );
};

export default CreateNewOrGuestClient;
