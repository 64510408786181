/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
// ? External Dependencies
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

// ? Local Dependencies
import { addWeek, addDay, resetWeeks, deleteDay, deleteWeek } from "reducers/weekReducer.reducer";
import notificationDisplay from "utils/notificationDisplay";
import {
  createWeekDayStructure,
  returnPredefinePreprogrammed,
  returnPredefineTemplateProgram,
} from "utils/workout.utils";

import { bigContainerStyle } from "theme/commonStyles";
import ReturnBtn from "components/common/ReturnBtn";
import useLang from "hooks/useLang";
// import DateList from 'components/common/DateList';

// import generateId from 'helpers/generateId';
import DeleteAlert from "components/routines/DeleteAlert";
import { Button } from "@chakra-ui/react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import CreateTemplate from "./createTemplate";
import CreatePreprogrammed from "./createPreprogrammed";

import Type from "./Type";

const Create = () => {
  const { location } = useHistory();
  const [mode, setMode] = useState(location?.state?.mode ? location?.state?.mode : "main");
  const [preSelected, setPreSelected] = useState([]);
  const [name, setName] = useState({ en: "", es: "" });
  // const { role, id_user } = useSelector(({ user }) => user);
  const { control } = useForm({ mode: "onChange" });
  const { t } = useLang();
  const dispatch = useDispatch();
  const history = useHistory();
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [deleteType, setDeleteType] = useState("week");

  const [actualWeek, setActualWeek] = useState(1);
  const [actualDay, setActualDay] = useState(1);

  const weekData = useSelector(({ weekList }) => weekList);

  // end new data controller
  const onDeleteClose = () => setIsDeleteOpen(false);
  const handleAddWeek = () => {
    dispatch(addWeek());
    setActualWeek(actualWeek + 1);
    setActualDay(1);
  };

  const handleSelectWeek = (week) => {
    setActualWeek(week);
    setActualDay(1);
  };

  // days func
  const handleAddDay = () => {
    dispatch(addDay(actualWeek));
    setActualDay(actualDay + 1);
  };

  const handleSelectDay = (day) => setActualDay(day);

  const handleSaveEdit = async () => {
    try {
      // if (mode === 'template') {
      //   dataToSend.workout_name = name;
      //   response = await updateTemplates(dataToSend, location.state.data._id);
      // }
      // // preprogrammed
      // if (mode === 'preprograms') {
      //   dataToSend.workout_name = name;
      //   dataToSend.preProgrammed = true;
      //   response = await updatePreprogrammed(dataToSend, location.state.data._id);
      // }
      // if (response.data) throw new Error(t('error_save'));
      // notificationDisplay(dispatch, t('data_updated'), 'success');
      // history.push('/user/programs');
    } catch (error) {
      notificationDisplay(dispatch, error.message, "error");
    }
    // dispatch(loaded());
  };

  const handleCancel = () => history.push("/user/programs");

  const handleDeleteWeek = () => {
    setIsDeleteOpen(true);
    setDeleteType("week");
  };

  const handleDeleteDay = () => {
    // dispatch(deleteDay(actualWeek, weekData[actualWeek].days.length - 1));
    setIsDeleteOpen(true);
    setDeleteType("day");
  };

  const onDeleteSession = () => {
    // dispatch(deleteSession(sessionsData.length));
    if (deleteType === "day") {
      dispatch(deleteDay(actualWeek, weekData[actualWeek - 1].days.length));
      setActualDay(weekData[actualWeek - 1].days.length - 1);
    }
    if (deleteType === "week") {
      dispatch(deleteWeek(weekData.length));
      setActualWeek(weekData.length - 1);
      setActualDay(1);
    }
    // display the user to the actual last day
    setIsDeleteOpen(false);
  };

  useEffect(() => {
    if (mode === "main") {
      setActualDay(1);
      setActualWeek(1);
      dispatch(resetWeeks());
      setName({ en: "", es: "" });
    }
    if (location?.state) dispatch(resetWeeks());
    console.log("LOCATION", location);
  }, [mode]);

  useEffect(() => {
    if (location.state) {
      setMode(location?.state?.mode);
      createWeekDayStructure(location?.state?.data, dispatch);

      setName({
        en: location?.state?.data?.workout_name?.en,
        es: location?.state?.data?.workout_name?.es,
      });

      const finalData =
        mode === "template"
          ? returnPredefineTemplateProgram(location?.state?.data)
          : returnPredefinePreprogrammed(location?.state?.data);
      setPreSelected(finalData);
    }
  }, []);
  const goBack = () => {
    const leavePage = window.confirm(t("back_confirmation"));
    if (leavePage) {
      history.push("/user/programs");
    }
  };
  return (
    <div style={bigContainerStyle}>
      {mode === "main" && <Type setMode={setMode} />}
      {mode !== "main" && (
        <>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}>
            <Button
              size="xs"
              onClick={() => goBack()}
              colorScheme="gray"
              variant="outline"
              leftIcon={<ArrowBackIosIcon fontSize="inherit" />}
              color="gray">
              {t("backBtn")}
            </Button>
          </div>
          {(mode === "template" || mode === "userTemplate") && (
            <CreateTemplate
              data={location?.state?.data}
              currentName={location?.state?.data?.workout_name}
            />
          )}
          {(mode === "preprograms" || mode === "userPreprograms") && (
            <CreatePreprogrammed
              data={location?.state?.data}
              currentName={location?.state?.data?.workout_name}
            />
          )}
          {/* {
              (!location.state && isEmpty(weekData)) && (
                <Button onClick={handleSave} colorScheme="ptRed" mt="1rem">
                  {t('save_program')}
                </Button>
              )
            }
            {
              (location.state && isEmpty(weekData)) && (
                <Button onClick={handleSaveEdit} colorScheme="ptRed" mt="1rem">
                  {t('save_edit')}
                </Button>
              )
            } */}
        </>
      )}
      <DeleteAlert isOpen={isDeleteOpen} onClose={onDeleteClose} onDelete={onDeleteSession} />
    </div>
  );
};

export default Create;
