/* eslint-disable consistent-return */
import React from 'react';

const Fetch = ({
  uri,
  succesfulRender,
  useFn,
  loadingFallback = <p>Loading...</p>,
  renderError = (error) => <pre>{JSON.stringify(error, null, 2)}</pre>,
}) => {
  const {
    error, isLoading, isError, data,
  } = useFn(uri);
  if (isLoading) return loadingFallback;
  if (isError) return renderError(error);
  if (data) return succesfulRender({ data });
};

export default Fetch;
