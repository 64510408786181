/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
import React from "react";
import propTypes from "prop-types";
import dayjs from "dayjs";
import useLang from "hooks/useLang";
import { Text, View, StyleSheet } from "@react-pdf/renderer";
import ExerciseDisplayPdf from "./ExerciseDisplayPdf";

const styles = StyleSheet.create({
  dayStyles: {
    width: "8%",
    paddingHorizontal: 6,
    paddingVertical: 4,
    borderRadius: 5,
    textAlign: "center",
    marginVertical: 5,
    fontSize: 8,
    backgroundColor: "black",
    color: "white",
  },
  containerDays: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: 8,
  },
});

const DayDisplayPdf = ({ day }) => {
  const { t, lang } = useLang();

  return (
    <View break>
      <View style={{ display: "flex", flexWrap: "nowrap", flexDirection: "row", width: "100%" }}>
        <View style={styles.dayStyles}>
          <Text>
            {t("list_day")} {day.day}
          </Text>
        </View>
        <View style={{ marginLeft: 3 }}>
          <Text style={{ color: "gray", fontSize: 8, marginVertical: 5, paddingVertical: 4 }}>
            {day.date
              ? dayjs(day?.date).format(lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY")
              : t("no_date")}
          </Text>
        </View>
      </View>
      <View
        style={{
          fontSize: 7,
          color: "black",
          marginLeft: 3,
          marginBottom: 5,
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "row",
          width: "100%",
        }}>
        <Text>
          {t("notes")}: {day?.notes?.[lang]}
        </Text>
      </View>
      <View>
        {day.exercises?.map((exercise, idx) => (
          <View key={exercise._id} break>
            <ExerciseDisplayPdf
              exercise={exercise}
              date={{ day: day.day, week: day.week }}
              id={idx}
            />
          </View>
        ))}
      </View>
    </View>
  );
};

DayDisplayPdf.propTypes = {
  day: propTypes.shape({}).isRequired,
};

export default DayDisplayPdf;
