/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import styled from 'styled-components';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import AddIcon from '@mui/icons-material/Add';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { Button, useDisclosure } from '@chakra-ui/react';
import QuoteItem from './item';
import AddDay from './AddDay/AddDay';

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) {
    return '#FFEBE6';
  }
  if (isDraggingFrom) {
    return '#E6FCF8';
  }
  return 'transparent';
};

const Wrapper = styled.div`
  background-color: ${(props) => getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : 'inherit')};
  padding: 0px;
  border: 0px;
  padding-bottom: 0;
  transition: background-color 0.2s ease, opacity 0.1s ease;
  user-select: none;
  width: 320px;
`;

// $ExpectError - not sure why
const Title = styled.h4`
  padding: 0px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  &:focus {
    outline: 2px solid #998dd9;
    outline-offset: 2px;
  }
`;

const scrollContainerHeight = 700;

const DropZone = styled.div`
  /* stop the list collapsing when empty */
  man-height: ${scrollContainerHeight}px;
  /*
    not relying on the items for a margin-bottom
    as it will collapse when the list is empty
  */
  padding-bottom: 0px;
`;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div``;
/* stylelint-enable */

const InnerQuoteList = React.memo((props) => props.quotes.map((quote, index) => (
  <Draggable key={quote._id} draggableId={quote._id} index={index}>
    {(dragProvided, dragSnapshot) => (
      <QuoteItem
        key={quote._id}
        quote={quote}
        isDragging={dragSnapshot.isDragging}
        isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
        provided={dragProvided}
        columns={props.columns}
        setColumns={props.setColumns}
        setOrdered={props.setOrdered}
        cloneState={props.cloneState}
        onChange={
          props.isSelected(quote)
            ? props.handleChangeRemove(quote)
            : props.handleChangeSelect(quote)
        }
        isChecked={props.isSelected(quote)}
      />
    )}
  </Draggable>
)));

function InnerList(props) {
  const {
    quotes,
    dropProvided,
    columns,
    setColumns,
    cloneState,
    listDaySelected,
    handleChangeRemove,
    handleChangeSelect,
    setOrdered,
  } = props;
  const title = props.title ? <Title>{props.title}</Title> : null;

  const isSelected = ({ _id }) => listDaySelected.some((i) => i._id === _id);

  return (
    <Container>
      {title}
      <DropZone ref={dropProvided.innerRef}>
        <InnerQuoteList
          quotes={quotes}
          columns={columns}
          setColumns={setColumns}
          cloneState={cloneState}
          listDaySelected={listDaySelected}
          handleChangeRemove={handleChangeRemove}
          handleChangeSelect={handleChangeSelect}
          isSelected={isSelected}
          setOrdered={setOrdered}
        />
        {dropProvided.placeholder}
      </DropZone>
    </Container>
  );
}

export default function QuoteList(props) {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId = 'LIST',
    listType,
    style,
    quotes,
    title,
    useClone,
    titleHeader,
    columns,
    setColumns,
    thelastday,
    cloneState,
    listDaySelected,
    handleChangeRemove,
    handleChangeSelect,
    handleCancelClone,
    setOrdered,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();

  const PasteSelectedDays = () => {
    let copyDays = [...quotes];
    let weekColumn = [...columns];
    const arrPosition = (titleHeader - 1);
    let cocatValues = copyDays.concat(listDaySelected);
    const orderBoard = cocatValues.map((arr, i) => {
      const subArrMap = { ...arr, day: (1 + i), week: titleHeader };
      return subArrMap;
    });
    weekColumn.splice(arrPosition, 1, orderBoard);
    setColumns(weekColumn);
    handleCancelClone();
  };

  return (
    <>
      <Droppable
        droppableId={listId}
        type={listType}
        ignoreContainerClipping={ignoreContainerClipping}
        isDropDisabled={isDropDisabled}
        isCombineEnabled={isCombineEnabled}
        renderClone={
          useClone
            ? (provided, snapshot, descriptor) => (
              <QuoteItem
                quote={quotes[descriptor.source.index]}
                provided={provided}
                isDragging={snapshot.isDragging}
                isClone
                columns={columns}
              />
            )
            : null
        }
      >
        {(dropProvided, dropSnapshot) => (
          <Wrapper
            style={style}
            isDraggingOver={dropSnapshot.isDraggingOver}
            isDropDisabled={isDropDisabled}
            isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
            {...dropProvided.droppableProps}
          >
            {internalScroll ? (
              <ScrollContainer style={scrollContainerStyle}>
                <InnerList
                  quotes={quotes}
                  title={title}
                  dropProvided={dropProvided}
                  cloneState={cloneState}
                  listDaySelected={listDaySelected}
                  handleChangeRemove={handleChangeRemove}
                  handleChangeSelect={handleChangeSelect}
                  columns={columns}
                  setColumns={setColumns}
                  setOrdered={setOrdered}
                />
              </ScrollContainer>
            ) : (
              <InnerList
                quotes={quotes}
                title={title}
                columns={columns}
                cloneState={cloneState}
                dropProvided={dropProvided}
                listDaySelected={listDaySelected}
                handleChangeRemove={handleChangeRemove}
                handleChangeSelect={handleChangeSelect}
                setColumns={setColumns}
                setOrdered={setOrdered}
              />
            )}
          </Wrapper>
        )}
      </Droppable>
      {/* {!cloneState ? (
        <Button
          backgroundColor="white"
          color="#b42519"
          _hover={{ backgroundColor: '#b42519', color: 'white' }}
          variant="outline"
          borderRadius={20}
          width="320px"
          mt={4}
          onClick={() => onOpen()}
        >
          <AddIcon />
          New Day
        </Button>
      ) : (
        <Button
          backgroundColor="#b42519"
          color="white"
          _hover={{ backgroundColor: '#b42519', color: 'white' }}
          variant="outline"
          borderRadius={20}
          width="320px"
          mt={4}
          onClick={() => PasteSelectedDays()}
        >
          <ContentCopyIcon />
          Paste Here
        </Button>
      )} */}
      <AddDay
        isOpen={isOpen}
        onClose={onClose}
        titleHeader={titleHeader}
        quotes={quotes}
        columns={columns}
        key={isOpen}
        setColumns={setColumns}
        thelastday={thelastday}
        setOrdered={setOrdered}
      />
    </>
  );
}
