/* eslint-disable react/prop-types */
import React from "react";
import { Controller } from "react-hook-form";
import { Input, Select, Textarea } from "@chakra-ui/react";
import propTypes from "prop-types";

import { inputStyle } from "theme/commonStyles";

import { useTranslation } from "react-i18next";
import CustomInput from "../main/CustomInput";

const SelectInput = ({ control, def, name, place, required, type, rules, options }) => {
  const { t } = useTranslation();
  return (
    <Controller
      control={control}
      defaultValue={def}
      name={name}
      rules={rules}
      render={({ field }) => (
        <CustomInput label={place} required={required}>
          <Select {...inputStyle} {...field} p={0}>
            {options.map((option, index) => (
              <option value={option}>{t(option)}</option>
            ))}
          </Select>
        </CustomInput>
      )}
    />
  );
};

SelectInput.defaultProps = {
  type: "text",
  required: true,
  def: "",
  rules: null,
};

export default SelectInput;
