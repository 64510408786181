const languages = [
  {
    code: 'en',
    name: 'English',
    countryCode: 'us',
  },
  {
    code: 'es',
    name: 'Español',
    countryCode: 'es',
  },
  // {
  //   code: 'fr',
  //   name: 'Francais',
  //   countryCode: 'fr',
  // },
];

export default languages;
