/* eslint-disable react/prop-types */
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Tooltip,
  IconButton,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import useLang from "hooks/useLang";
import CalendarScreen from "components/calendar/CalendarScreen";

import { eventStartLoading } from "../../../../actions/events";

const ClientPlanning = ({ client }) => {
  const dispatch = useDispatch();
  const { lang, t } = useLang();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openModal = () => {
    dispatch(eventStartLoading(client._id));
    onOpen();
  };
  const closeModal = () => {
    onClose();
  };

  return (
    <>
      <Tooltip label={t("planning")}>
        <IconButton
          colorScheme="gray"
          color="#808080"
          isRound
          variant="solid"
          aria-label="done"
          size="sm"
          icon={<CalendarMonthIcon />}
          onClick={() => {
            openModal();
          }}
        />
      </Tooltip>

      <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={closeModal} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{client.name}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <CalendarScreen client={client._id} isEdit />
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeModal}>
              {t("close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ClientPlanning;
