/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import tableIcons from 'theme/tableIcons';
import {
  IconButton, useDisclosure,
} from '@chakra-ui/react';
import {
  ViewIcon, DeleteIcon,
} from '@chakra-ui/icons';

import useGetAll from 'hooks/useGetAll';
import { getAllTrainers } from 'services/trainer';

import MaterialTable from 'material-table';
import DisplayMessage from 'components/common/DisplayMessage';
import Popup from './Popup';
import DeleteAlert from './DeleteAlert';
import localizationMaterialTable from '../../helpers/localizationMaterialTable';
import customFilterAndSearch from '../../helpers/customFilterAndSearch';

const Board = () => {
  const { data, isLoading, isError } = useGetAll('trainers', null, getAllTrainers);
  const [trainerSelected, setTrainerSelected] = useState(undefined);
  const { onClose, onOpen, isOpen } = useDisclosure();
  const [dialogOpen, setDialogOpen] = useState(false);
  const { t } = useTranslation();

  const handleClose = () => setDialogOpen(false);
  const handleOpenPop = (info) => {
    setTrainerSelected(info);
    onOpen();
  };

  const localization = localizationMaterialTable();

  if (isLoading) return <DisplayMessage message={t('loading_trainers')} loading />;
  if (isError) return <DisplayMessage message={t('something_wrong')} />;

  const columns = [
    {
      title: t('fullname'),
      name: 'name',
      customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'name'),
      render: (rowData) => <h6>{rowData.name}</h6>,
    },
    {
      title: t('profile_speciality'),
      name: 'speciality',
      customFilterAndSearch: (term, rowData) => customFilterAndSearch(term, rowData, 'speciality'),
      render: (rowData) => <h6>{rowData.speciality}</h6>,
    },
    {
      title: t('table_options'),
      cellStyle: { width: '40%' },
      headerStyle: { fontSize: '16px', fontWeight: '600' },
      render: (rowData) => (
        <>
          <IconButton
            fontSize="20px"
            icon={<ViewIcon />}
            onClick={() => handleOpenPop(rowData)}
            variant="link"
          />
          <IconButton
            fontSize="20px"
            icon={<DeleteIcon />}
            onClick={() => console.log(rowData)}
            variant="link"
          />
        </>
      ),
    },
  ];
  return (
    <section style={{ width: '90%' }}>
      <h2>Trainers</h2>
      <MaterialTable
        style={{ width: '100%' }}
        columns={columns}
        data={data.trainers}
        icons={tableIcons}
        title=""
        localization={localization}
      />
      {
        isOpen && <Popup isOpen={isOpen} onClose={onClose} info={trainerSelected} />
      }
      <DeleteAlert open={dialogOpen} close={handleClose} />
    </section>
  );
};

export default Board;
