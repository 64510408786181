import React from 'react';
import { Switch, Route, useRouteMatch } from 'react-router-dom';
import Glossary from './glossary';
import Steps from './steps';
import SelectionTypeHelp from './SelectionTypeHelp';

const Helps = () => {
  const { path } = useRouteMatch();
  return (
    <Switch>
      <Route exact path={path} component={SelectionTypeHelp} />
      <Route path={`${path}/glossary`} component={Glossary} />
      <Route path={`${path}/steps`} component={Steps} />
    </Switch>
  );
};

export default Helps;
