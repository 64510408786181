import instance from "./_axiosConfig";

const api = instance;
const createTemplates = async (data) => {
  try {
    const res = await api.post("/template/", data);
    return res.data.template;
  } catch (error) {
    throw new Error(error.message);
  }
};

const updateTemplates = async (data, id) => {
  try {
    const res = await api.put(`/template/${id}`, data);
    return res.data.template;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getTemplates = async ({ name, options, lang, user, page, itemsPerPage, combine }) => {
  try {
    const query = [
      `?lang=${lang}`,
      name ? `name=${name}` : "",
      options && options.length > 0 ? `options=${options}` : "",
      user ? `trainer=${user}` : "",
      page ? `page=${page}` : "",
      itemsPerPage ? `itemsPerPage=${itemsPerPage}` : "",
      combine ? `combine=${combine}` : "",
    ]
      .filter((a) => a !== "")
      .join("&");
    const res = await api.get(`/template${query}`);
    return res.data;
  } catch (error) {
    return error.message;
  }
};

const getTemplatesCombined = async ({ name, options, lang, user, page, itemsPerPage, combine }) => {
  try {
    const query = [
      `?lang=${lang}`,
      name ? `name=${name}` : "",
      options && options.length > 0 ? `options=${options}` : "",
      user ? `trainer=${user}` : "",
      page ? `page=${page}` : "",
      itemsPerPage ? `itemsPerPage=${itemsPerPage}` : "",
      combine ? `combine=${combine}` : "",
    ]
      .filter((a) => a !== "")
      .join("&");
    const res = await api.get(`/template_combined${query}`);
    return res.data;
  } catch (error) {
    return error.message;
  }
};


const deleteTemplate = async (id) => {
  try {
    const res = await api.delete(`/template/${id}`);
    return res.data;
  } catch (error) {
    return error.message;
  }
};

export { createTemplates, getTemplatesCombined, getTemplates, deleteTemplate, updateTemplates };
