/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef } from "react";
import styled from "styled-components";
import { Droppable, Draggable } from "react-beautiful-dnd";
import useLang from "hooks/useLang";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import generateId from "helpers/generateId";
import { Button, useDisclosure } from "@chakra-ui/react";
import AddIcon from "assets/icons/plus.png";
import QuoteItem from "./item";
import AddDay from "./AddDay/AddDay";

import { add_exercise_icon } from "../../../clients/ClientDashboard/DayDisplay/DayDisplay.module.css";

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) {
    return "#FFEBE6";
  }
  if (isDraggingFrom) {
    return "#E6FCF8";
  }
  return "transparent";
};

// $ExpectError - not sure why
const Title = styled.h4`
  padding: 0px;
  transition: background-color ease 0.2s;
  flex-grow: 1;
  user-select: none;
  position: relative;
  &:focus {
    outline: 2px solid #998dd9;
    outline-offset: 2px;
  }
`;

const scrollContainerHeight = 700;

const ScrollContainer = styled.div`
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${scrollContainerHeight}px;
`;

/* stylelint-disable block-no-empty */
const Container = styled.div`
  min-height: 14rem;
`;
/* stylelint-enable */

const InnerQuoteList = React.memo((props) => (
  <>
    {props.quotes.map((quote, index) => (
      <Draggable key={quote._id} draggableId={quote._id} index={index}>
        {(dragProvided, dragSnapshot) => (
          <QuoteItem
            key={quote._id}
            quote={quote}
            isDragging={dragSnapshot.isDragging}
            isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
            provided={dragProvided}
            columns={props.columns}
            setColumns={props.setColumns}
            setOrdered={props.setOrdered}
            cloneState={props.cloneState}
            columnScroll={props.columnScroll}
            scrollHorizontalBoard={props.scrollHorizontalBoard}
            titleHeader={props.titleHeader}
            listdays={props.quotes}
            onChange={
              props.isSelected(quote)
                ? props.handleChangeRemove(quote)
                : props.handleChangeSelect(quote)
            }
            isChecked={props.isSelected(quote)}
            setCloneUniqueDay={props.setCloneUniqueDay}
            cloneUniqueDay={props.cloneUniqueDay}
          />
        )}
      </Draggable>
    ))}
    {props.quotes.length <= 0 && (
      <p style={{ textAlign: "center", fontSize: "12px", color: "gray" }}>
        {props.t("no_days_in_week")}
      </p>
    )}
  </>
));

function InnerList(props) {
  const {
    quotes,
    dropProvided,
    columns,
    setColumns,
    cloneState,
    listDaySelected,
    handleChangeRemove,
    handleChangeSelect,
    setOrdered,
    cloneUniqueDay,
    setCloneUniqueDay,
    titleHeader,
    scrollHorizontalBoard,
    columnScroll,
  } = props;
  const title = props.title ? <Title>{props.title}</Title> : null;
  const { t } = useLang();
  const isSelected = ({ _id }) => listDaySelected.some((i) => i._id === _id);

  return (
    <Container>
      {title}
      <div
        className="dropZone"
        ref={dropProvided.innerRef}
        style={{ justifyContent: quotes.length <= 0 ? "center" : "start" }}>
        <InnerQuoteList
          quotes={quotes}
          columnScroll={columnScroll}
          columns={columns}
          setColumns={setColumns}
          cloneState={cloneState}
          listDaySelected={listDaySelected}
          scrollHorizontalBoard={scrollHorizontalBoard}
          handleChangeRemove={handleChangeRemove}
          handleChangeSelect={handleChangeSelect}
          isSelected={isSelected}
          setOrdered={setOrdered}
          t={t}
          setCloneUniqueDay={setCloneUniqueDay}
          cloneUniqueDay={cloneUniqueDay}
          titleHeader={titleHeader}
        />
        {dropProvided.placeholder}
      </div>
    </Container>
  );
}

export default function QuoteList(props) {
  const {
    ignoreContainerClipping,
    internalScroll,
    scrollContainerStyle,
    isDropDisabled,
    isCombineEnabled,
    listId,
    quotes,
    title,
    useClone,
    titleHeader,
    columns,
    setColumns,
    thelastday,
    cloneState,
    listDaySelected,
    handleChangeRemove,
    handleChangeSelect,
    handleCancelClone,
    scrollHorizontalBoard,
    setOrdered,
    cloneUniqueDay,
    setCloneUniqueDay,
  } = props;
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useLang();
  const columnScroll = useRef(null);

  const PasteSelectedDays = (selected) => {
    const resetIdList = selected.map((i) => ({
      ...i,
      _id: generateId(),
      id: generateId(),
      exercises: i.exercises.map((exercise) => ({
        ...exercise,
        _id: generateId(),
      })),
    }));
    let copyDays = [...quotes];
    let weekColumn = [...columns];
    const arrPosition = titleHeader - 1;
    let cocatValues = copyDays.concat(resetIdList);
    const orderBoard = cocatValues.map((arr, i) => {
      const subArrMap = { ...arr, day: 1 + i, week: titleHeader };
      return subArrMap;
    });
    weekColumn.splice(arrPosition, 1, orderBoard);
    setColumns(weekColumn);
    handleCancelClone();
    setCloneUniqueDay([]);
  };

  return (
    <>
      <Droppable
        droppableId={listId}
        type="DAYS"
        ignoreContainerClipping={ignoreContainerClipping}
        isDropDisabled={isDropDisabled}
        isCombineEnabled={isCombineEnabled}
        renderClone={
          useClone
            ? (provided, snapshot, descriptor) => (
                <QuoteItem
                  quote={quotes[descriptor.source.index]}
                  provided={provided}
                  isDragging={snapshot.isDragging}
                  isClone
                  columns={columns}
                />
              )
            : null
        }>
        {(dropProvided, dropSnapshot) => (
          <div
            className="list_wrapper"
            style={{
              backgroundColor: getBackgroundColor(
                dropSnapshot.isDraggingOver,
                dropSnapshot.isDraggingFrom,
              ),
            }}
            isDraggingOver={dropSnapshot.isDraggingOver}
            isDropDisabled={isDropDisabled}
            isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
            {...dropProvided.droppableProps}>
            {internalScroll ? (
              <ScrollContainer style={scrollContainerStyle} ref={columnScroll}>
                <InnerList
                  quotes={quotes?.filter((sub) => sub?.exercises?.length >= 1)}
                  title={title}
                  dropProvided={dropProvided}
                  cloneState={cloneState}
                  listDaySelected={listDaySelected}
                  handleChangeRemove={handleChangeRemove}
                  handleChangeSelect={handleChangeSelect}
                  columns={columns}
                  setColumns={setColumns}
                  setOrdered={setOrdered}
                  scrollHorizontalBoard={scrollHorizontalBoard}
                  setCloneUniqueDay={setCloneUniqueDay}
                  cloneUniqueDay={cloneUniqueDay}
                  columnScroll={columnScroll}
                  titleHeader={titleHeader}
                />
              </ScrollContainer>
            ) : (
              <InnerList
                quotes={quotes?.filter((sub) => sub?.exercises?.length >= 1)}
                title={title}
                columns={columns}
                cloneState={cloneState}
                listDaySelected={listDaySelected}
                handleChangeRemove={handleChangeRemove}
                handleChangeSelect={handleChangeSelect}
                setColumns={setColumns}
                columnScroll={columnScroll}
                setOrdered={setOrdered}
                scrollHorizontalBoard={scrollHorizontalBoard}
                setCloneUniqueDay={setCloneUniqueDay}
                cloneUniqueDay={cloneUniqueDay}
                titleHeader={titleHeader}
              />
            )}
          </div>
        )}
      </Droppable>
      {!cloneState && cloneUniqueDay?.length <= 0 ? (
        <Button
          backgroundColor="transparent"
          border="2px solid #3a3a3a"
          borderColor="#3a3a3a"
          color="#3a3a3a"
          borderRadius={20}
          mt={4}
          minHeight="2.5rem"
          onClick={() => onOpen()}>
          <img className={add_exercise_icon} src={AddIcon} alt="add icon" />
          {t("add_day")}
        </Button>
      ) : (
        <Button
          backgroundColor="#b42519"
          color="white"
          borderRadius={20}
          mt={4}
          minHeight="2.5rem"
          onClick={() =>
            PasteSelectedDays(cloneUniqueDay?.length <= 0 ? listDaySelected : cloneUniqueDay)
          }>
          <ContentCopyIcon />
          {t("paste_here")}
        </Button>
      )}
      {isOpen && (
        <AddDay
          isOpen={isOpen}
          onClose={onClose}
          columnScroll={columnScroll}
          key={isOpen}
          titleHeader={titleHeader}
          quotes={quotes}
          scrollHorizontalBoard={scrollHorizontalBoard}
          columns={columns}
          setColumns={setColumns}
          thelastday={thelastday}
          setOrdered={setOrdered}
        />
      )}
    </>
  );
}
