/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable import/prefer-default-export */
import React from "react";
import useLang from "hooks/useLang";
import { StyleSheet, Text, View } from "@react-pdf/renderer";
import DayDisplayPdf from "./DayDisplayPdf";

export const WeeksDisplayPdf = ({ headertitle, weeks }) => {
  const {t} = useLang()
  const styles = StyleSheet.create({
    container: {
      border: "1.2px solid black",
      marginTop: 25,
      borderTopLeftRadius: 5,
      borderTopRightRadius: 15,
      borderBottomRightRadius: 15,
      borderBottomLeftRadius: 15,
    },
    headerWeek: {
      backgroundColor: "#b42519",
      color: "white",
      fontSize: 12,
      paddingVertical: 5,
      width: "20%",
      fontWeight: "extrabold",
      top: -1.5,
      left: -2,
      paddingLeft: 10,
      borderTopRightRadius: 25,
      borderBottomRightRadius: 25,
    },
    contaienrMapDays: {
      display: "flex",
      flexDirection: "column",
      marginHorizontal: 10,
      marginVertical: 7,
    },
  });

  return (
    <View style={styles.container} break>
      <View style={{ display: "flex", flexWrap: "nowrap", flexDirection: "row", width: "100%" }}>
        <View style={styles.headerWeek}>
          <Text>{t('list_week')} {Math.floor(headertitle) + 1}</Text>
        </View>
        <View
          style={{
            backgroundColor: "lightgray",
            left: -13,
            zIndex: -10,
            width: "5%",
            height: "95%",
            borderTopRightRadius: 20,
            borderBottomRightRadius: 20,
          }}
        />
      </View>
      {weeks.map((day) => (
        <View style={styles.contaienrMapDays}>
          <DayDisplayPdf day={day} />
        </View>
      ))}
    </View>
  );
};
