/* eslint-disable react/prop-types */
import { Flex, Heading, Image } from "@chakra-ui/react";
import useLang from "hooks/useLang";
import React from "react";
import notFound from "assets/notfound.png";

const NoInfoComponent = () => {
  const { t } = useLang();
  return (
    <Flex flexDirection="column" alignItems="center" justifyContent="center">
      <Image src={notFound} w="10rem" alt="not found" />
      <Heading> {t("have_no_client")}</Heading>
    </Flex>
  );
};

export default NoInfoComponent;
