/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import propTypes from "prop-types";
import { Spinner } from "@chakra-ui/react";

const FrameVideo = ({ embedId }) => (
  <div style={{ boder: "1px gray solid", width: "300", height: "300" }}>
    <Spinner
      style={{
        position: "relative",
        top: "150px",
        left: "0px",
        zIndex: "-1",
      }}
      size="lg"
      color="red.500"
    />
    <iframe
      width="380"
      height="300"
      src={`https://www.youtube.com/embed/${embedId}`}
      title="YouTube video player"
      frameBorder="1"
      allow="accelerometer; autoPlay playsinline muted; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  </div>
);

FrameVideo.propTypes = {
  embedId: propTypes.string.isRequired,
};

export default FrameVideo;
