import React from 'react';
import { useSelector } from 'react-redux';

import ReturnBtn from 'components/common/ReturnBtn';
import HelpBoard from '../HelpBoard';
import Search from '../Search';

const Glossary = () => {
  const { role } = useSelector(({ user }) => user);
  return (
    <>
      <ReturnBtn mode="help" />
      {
          role === 'admin' && <Search category="glossary" />
        }
      <HelpBoard category="glossary" />
    </>
  );
};

export default Glossary;
