/* eslint-disable react/prop-types */
import { Center, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";

const BoxInfoCard = ({ title, value, icon, pointer }) => (
  <Flex
    cursor={pointer ? "pointer" : "default"}
    direction="column"
    align="center"
    margin={4}
    color="gray">
    <Center padding={3} backgroundColor="gray" borderRadius="50px">
      <img src={icon} alt="icon" />
    </Center>
    <Text>{title}</Text>
    <Text as="b">{value}</Text>
  </Flex>
);

export default BoxInfoCard;
