/* eslint-disable no-param-reassign */
// import { getTrainerByUserId } from 'services/trainer';
import Swal from "sweetalert2";
import { fetchConToken } from "../helpers/fetch";
import prepareEvents from "../helpers/prepareEvents";
import types from "../types/types";

const eventAddNew = (event) => ({
  type: types.eventAddNew,
  payload: event,
});

const eventDeleted = (id) => ({
  type: types.eventDeleted,
  payload: id,
});

export const eventLoaded = (events) => ({
  type: types.eventLoaded,
  payload: events,
});

export const eventStartAddNew = (event) => async (dispatch, getState) => {
  const { id_user, name } = getState().user;
  event.user = id_user;
  try {
    const resp = await fetchConToken("events", event, "POST");
    const body = await resp.json();

    if (body.ok) {
      event.id = body.evento.id;
      event.user = {
        _id: id_user,
        name,
      };
      dispatch(eventAddNew(body.evento));
    }
  } catch (error) {
    console.log(error);
  }
};

export const eventSetActive = (event) => ({
  type: types.eventSetActive,
  payload: event,
});

export const eventSetSlotEmpty = (event) => ({
  type: types.eventSetSlotEmpty,
  payload: event,
});

export const eventClearActiveEvent = () => ({
  type: types.eventClearActiveEvent,
});

const eventUpdated = (event) => ({
  type: types.eventUpdated,
  payload: event,
});

export const eventStartUpdate = (event) => async (dispatch) => {
  try {
    const resp = await fetchConToken(`events/${event.id}`, event, "PUT");
    const body = await resp.json();
    if (body.ok) {
      dispatch(eventUpdated(body.evento));
    } else {
      Swal.fire("Error", body.msg, "error");
    }
  } catch (error) {
    console.log(error);
  }
};

export const eventStartDelete = () => async (dispatch, getState) => {
  const { id } = getState().calendar.activeEvent;
  try {
    const resp = await fetchConToken(`events/${id}`, {}, "DELETE");
    const body = await resp.json();

    if (body.ok) {
      dispatch(eventDeleted(id));
    } else {
      Swal.fire("Error", body.msg, "error");
    }
  } catch (error) {
    console.log(error);
  }
};

export const eventStartLoading = (id_client) => async (dispatch, getState) => {
  const { id_user } = getState().user;
  try {
    const resp = await fetchConToken(`events?client=${id_client}&user=${id_user}`);
    const body = await resp.json();

    const events = prepareEvents(body.event);
    dispatch(eventLoaded(events));
  } catch (error) {
    console.log(error);
  }
};

export const eventLogout = () => ({
  type: types.eventLogout,
});
