/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from "react";
import {
  VStack,
  Input,
  Button,
  Table,
  Tbody,
  Thead,
  Th,
  Td,
  Tr,
  Badge,
  Box,
  Flex,
  IconButton,
  Text,
  Avatar,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";

import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import useGetAll from "hooks/useGetAll";
import { getTrainerClients } from "services/clients";

import { inputStyle } from "theme/commonStyles";
import styles from "../clients/ClientDashboard/ClientDasboard.module.css";
import DisplayMessage from "./DisplayMessage";

const SearchClient = ({ setClient, setStep }) => {
  const { t } = useTranslation();
  const [nameForSeach, setNameForSearch] = useState("");

  const [showFilters, setShowFilters] = useState(false);
  const [filterType, setFilterType] = useState("all");
  const { id_user } = useSelector(({ user }) => user);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const [nPages, setNPages] = useState(0);
  const { data, isLoading, isError } = useGetAll("clients", id_user, getTrainerClients);

  const handleChange = ({ target }) => setNameForSearch(target.value);
  const handleClick = (name, id) => {
    setClient(`${name}___${id}`);
    setStep((prev) => prev + 1);
  };

  useEffect(() => {
    setNPages(
      Math.ceil(
        data
          ?.filter(
            (client) =>
              client?.name?.toLowerCase().includes(nameForSeach.toLowerCase()) ||
              client?.lastName?.toLowerCase().includes(nameForSeach.toLowerCase()),
          )
          .filter((client) =>
            filterType === "all" ? true : filterType === "guest" ? client.isGuest : !client.isGuest,
          ).length / recordsPerPage,
      ),
    );
  }, [data, filterType, nameForSeach]);
  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) setCurrentPage(nPages);
  };
  if (isLoading) return <DisplayMessage message={t("loading_clients")} loading />;
  if (isError) return <DisplayMessage message={t("something_wrong")} />;
  return (
    <Box bg="white" border="16px" padding="1rem">
      <Box className={styles.searchOptions}>
        <Flex gridGap="10px" alignItems="center" w="50%" mb="1rem">
          <Input
            variant="outline"
            placeholder={t("search_client")}
            onChange={handleChange}
            value={nameForSeach}
            w="50%"
          />
          <Button
            colorScheme="blackAlpha"
            size="xs"
            leftIcon={<FilterAltIcon fontSize="inherit" />}
            onClick={() => setShowFilters(!showFilters)}>
            {t("filters")}
          </Button>
        </Flex>

        <Box>
          <Flex gridGap={4} minH="42px" wrap="wrap">
            {showFilters ? (
              <>
                <Button
                  colorScheme={filterType === "all" ? "green" : "gray"}
                  size="xs"
                  onClick={() => setFilterType("all")}>
                  {t("showAll")}
                </Button>
                <Button
                  colorScheme={filterType === "clients" ? "green" : "gray"}
                  size="xs"
                  onClick={() => setFilterType("clients")}>
                  {t("onlyClients")}
                </Button>
                <Button
                  colorScheme={filterType === "guest" ? "green" : "gray"}
                  size="xs"
                  onClick={() => setFilterType("guest")}>
                  {t("onlyGuests")}
                </Button>
              </>
            ) : null}
          </Flex>
        </Box>
      </Box>

      <>
        <Table className={styles.clientTable} variant="striped" colorScheme="gray">
          <Thead>
            <Tr>
              <Th>{t("table_name")}</Th>
              <Th textTransform="uppercase" textAlign="center">
                {t("status")}
              </Th>
              <Th>{t("table_gender")}</Th>
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            {data
              ?.filter(
                (client) =>
                  client?.name?.toLowerCase().includes(nameForSeach.toLowerCase()) ||
                  client?.lastName?.toLowerCase().includes(nameForSeach.toLowerCase()),
              )
              .filter((client) =>
                filterType === "all"
                  ? true
                  : filterType === "guest"
                  ? client.isGuest
                  : !client.isGuest,
              )
              .slice(nameForSeach !== "" ? 0 : indexOfFirstRecord, indexOfLastRecord)

              .map((client) => (
                <Tr key={client._id}>
                  <Td>
                    <Flex gridGap={4} alignItems="center">
                      <Avatar
                        name={`${client.name} ${client?.lastName}`}
                        bg="gray"
                        color="white"
                        alt="deault profile"
                        src={client.pic || ""}
                        style={{ pointerEvents: "none" }}
                        width="40px"
                        height="40px"
                        fontSize="30px"
                      />
                      <Box className={styles.clientName}>
                        <Text>{`${client.name} ${client.lastName ?? ""}`} </Text>
                        <Badge
                          id="profile"
                          className={styles.badge}
                          bg={!client.isGuest ? "#6bca21" : "#b42519"}
                          color="white"
                          borderRadius="20px"
                          fontSize="10px"
                          padding="1px 8px">
                          {!client.isGuest ? t("client") : t("guest")}
                        </Badge>
                      </Box>
                    </Flex>
                  </Td>
                  <Td>
                    <Flex justifyContent="center">
                      <Badge
                        id="profile"
                        bg={!client.isGuest ? "#6bca21" : "#b42519"}
                        color="white"
                        borderRadius="20px"
                        mx={2}
                        fontSize="10px"
                        padding="1px 8px">
                        {!client.isGuest ? t("client") : t("guest")}
                      </Badge>
                    </Flex>
                  </Td>
                  <Td>{t(client.gender)}</Td>
                  <Td>
                    <Button
                      colorScheme="ptRed"
                      size="sm"
                      w="100%"
                      onClick={() => handleClick(client.name, client._id)}>
                      {t("table_select")}
                    </Button>
                  </Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
        {data
          ?.filter(
            (item) =>
              item.name?.toLowerCase().includes(nameForSeach.toLowerCase()) ||
              item.lastName?.toLowerCase().includes(nameForSeach.toLowerCase()),
          )
          .filter((client) =>
            filterType === "all" ? true : filterType === "guest" ? client.isGuest : !client.isGuest,
          ).length <= 0 && (
          <Text marginY={4} textAlign="center">
            {t("notFoundClient")}
          </Text>
        )}
        <nav>
          <ul className="pagination">
            <li>
              <IconButton
                isRound
                variant="ghost"
                colorScheme="blackAlpha"
                aria-label="Done"
                fontSize="20px"
                disabled={currentPage === 1}
                icon={<FirstPageIcon />}
                onClick={goToFirstPage}
              />
            </li>
            <li>
              <IconButton
                isRound
                variant="ghost"
                colorScheme="blackAlpha"
                aria-label="Done"
                fontSize="20px"
                icon={<ArrowBackIosIcon />}
                onClick={goToPrevPage}
                disabled={currentPage === 1}
              />
            </li>
            <li>
              {nameForSeach !== "" ? 1 : currentPage}/{nPages <= 0 ? 1 : nPages}
            </li>
            <li>
              <IconButton
                isRound
                variant="ghost"
                colorScheme="blackAlpha"
                aria-label="Done"
                fontSize="20px"
                icon={<ArrowForwardIosIcon />}
                onClick={goToNextPage}
                disabled={currentPage >= nPages}
              />
            </li>
            <li>
              <IconButton
                isRound
                variant="ghost"
                colorScheme="blackAlpha"
                aria-label="Done"
                fontSize="20px"
                disabled={currentPage === nPages}
                icon={<LastPageIcon />}
                onClick={goToLastPage}
              />
            </li>
          </ul>
        </nav>
      </>
    </Box>
  );
};

SearchClient.propTypes = {
  setClient: propTypes.func.isRequired,
  setStep: propTypes.func.isRequired,
};
export default SearchClient;
