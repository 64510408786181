/* eslint-disable array-callback-return */
/* eslint-disable max-len */
/* eslint-disable no-array-constructor */

import generateId from "helpers/generateId";

const getLoadAndReps = (number, value) => {
  const pureValue = value?.en || value;
  const valueSplit = pureValue.split("/");
  const isOther = /(?:kg|lb)/g.test(valueSplit[0]);
  const [load, typeWeight] = isOther
    ? valueSplit[0].match(/(:?kg|lb|([0-9]+)(:?-[0-9]+)?)/g)
    : [valueSplit[0], "others"];
  const [reps, typeReps] = valueSplit[1].match(/(:?reps|sec|([0-9]+)(:?-[0-9]+)?)/g);

  return {
    set_number: number,
    load,
    reps,
    typeWeight,
    typeReps,
  };
};

const setsToShow = (number, item) => {
  const array = [];
  Array.from(Array(number)).forEach((_, id) => {
    array.push(getLoadAndReps(id + 1, item[`set${id + 1}`]));
  });
  return array;
};

// const getExerciseModel = (info) => {
//   const sessions = [];

//   info.sessions.forEach((obj) => {
//     const exercises = obj.exercises.map(({
//       exercise, set1, set2, set3, set4,
//     }) => ({
//       id_exercise: exercise.split('*')[1],
//       sets: [
//         getLoadAndReps(1, set1),
//         getLoadAndReps(2, set2),
//         getLoadAndReps(3, set3),
//         getLoadAndReps(4, set4),
//       ],
//     }));

//     const day = {
//       number: obj.number,
//       exercises,
//     };
//     sessions.push(day);
//   });

//   const routine = {
//     id_trainer: info.trainer,
//     id_client: info.client,
//     workout_name: info.name,
//     date: info.date ? new Date(info.date) : '',
//     source: info.source,
//     sessions,
//   };

//   return routine;
// };

const getExerciseModel = (info) => {
  const sessions = [];
  info.sessions.forEach((week) => {
    week.days.forEach((day) =>
      sessions.push({
        session: day.session,
        day: day.number,
        week: week.number,
        notes: { en: day.notes, es: "" },
        date: day.date,
        exercises: day.exercises.map((item) => ({
          id_exercise: item.id,
          sets: setsToShow(day.show, item),
        })),
      }),
    );
  });

  const routine = {
    id_trainer: info.trainer,
    id_client: info.client,
    workout_name: { en: info.name, es: "" },
    phase: info.phase,
    source: info.source,
    sessions,
  };

  return routine;
};

const getExerciseModelFromTemplate = (info) => {
  const sessions = [];

  info.sessions.forEach((week) => {
    week.days.forEach((day) =>
      sessions.push({
        session: day.session,
        day: day.number,
        week: week.number,
        date: day.date,
        // soon to change
        notes: { en: day.notes, es: "" },
        exercises: day.exercises.map((exercise) => ({
          id_exercise: exercise.id,
          sets: Array.from(Array(5)).map((_, id) =>
            getLoadAndReps(id + 1, exercise[`set${id + 1}`]),
          ),
        })),
      }),
    );
  });

  const routine = {
    id_trainer: info.trainer,
    id_client: info.client,
    workout_name: { en: info.name, es: "" },
    phase: info.phase,
    source: info.source,
    sessions,
  };

  return routine;
};

const getFunctionaProgramModel = (info) => {
  const exercises = [];
  info.sessions.map((obj) => {
    const exercise = {
      colors: { hard: 'white', soft: 'white' },
      id_exercise: {
        _id: obj.id,
        name: {
          en: obj.exercise,
          es: obj.exercise
        }
      },
      _id: generateId(),
      sets: Array.from(Array(+obj.sets)).map((_, idx) =>
        idx < +obj.sets
          ? getLoadAndReps(idx + 1, `${obj.load}kg/${obj.reps}`)
          : getLoadAndReps(idx + 1, "0kg/0"),
      ),
    };
    exercises.push(exercise);
  });

  const day = {
    week: 1,
    day: 1,
    exercises,
    _id: generateId(),
    id: generateId(),
    notes: { en: "", es: "" },
  };

  const routine = {
    id_trainer: info.trainer,
    id_client: info.client,
    workout_name: { en: info.name, es: info.name },
    source: info.source,
    date: info?.date || "",
    sessions: [day],
  };
  return routine;
};

export { getExerciseModel, getExerciseModelFromTemplate, getFunctionaProgramModel };
