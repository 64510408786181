import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { deleteMeasurementService, getMeasurementsByUser } from "services/evaluations";
import useLang from "hooks/useLang";
import { getClientById } from "services/clients";
import EditIcon from "@mui/icons-material/Edit";
import GeneratePdfGoals from "utils/GoalsPdf";
import { Button, Flex } from "@chakra-ui/react";
import GoalResume from "./goalResume";
import MeasurementsTable from "./measurementsTable";
import styles from "./goalForm.module.scss";

/* eslint-disable react/prop-types */

const GoalAndMeasurementsReport = ({ client, goal, setStep, isEdit }) => {
  const [measurements, setMeasurements] = useState([]);
  const { t } = useLang();
  const [showRenderPdf, setShowRenderPdf] = useState(null);
  const [dataClient, setDataClient] = useState({});
  const datatrainer = useSelector(({ user }) => user);

  const getMeasurementsClient = async () => {
    try {
      console.log(client);
      const res = await getMeasurementsByUser(client.split("___")[1]);
      if (res.data) throw new Error(res.data.message);
      setMeasurements(res);
    } catch (exception) {
      console.log(exception);
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };
  const deleteMeasurements = async (id) => {
    try {
      const res = await deleteMeasurementService(id);
      if (res.data) throw new Error(res.data.message);
      await getMeasurementsClient();
    } catch (exception) {
      console.log(exception);
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };

  const loaderGeneratePdf = (id) => {
    setTimeout(() => {
      setShowRenderPdf(id);
    }, 500);
  };

  const getClientInformation = async () => {
    const res = await getClientById(goal.client._id || goal.client);
    setDataClient(res.client);
  };

  console.log(goal.client);
  useEffect(() => {
    getMeasurementsClient();
    getClientInformation();
  }, []);

  return (
    <div className={styles.rootResume} id="goal-report">
      <Flex width="full" justifyContent="end">
        {isEdit && (
          <Button
            mr={3}
            onClick={() => setStep((prev) => prev - 1)}
            colorScheme="red"
            leftIcon={<EditIcon fontSize="inherit" />}>
            {t("edit")}
          </Button>
        )}
        {showRenderPdf === goal._id ? (
          <GeneratePdfGoals
            evaluation={goal}
            dataClient={dataClient}
            datatrainer={datatrainer}
            pageType="report"
          />
        ) : (
          <Button variant="outline" colorScheme="red" onClick={() => loaderGeneratePdf(goal._id)}>
            {t("generasting_pdf")}
          </Button>
        )}
      </Flex>
      <div className={styles.content}>
        <GoalResume goal={goal} />
      </div>
      <div className={styles.content}>
        <MeasurementsTable
          measurements={measurements}
          client={client}
          getMeasurementsClient={getMeasurementsClient}
          deleteMeasurements={deleteMeasurements}
        />
      </div>
    </div>
  );
};
export default GoalAndMeasurementsReport;
