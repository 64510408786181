/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import useLang from "hooks/useLang";
import { string } from "prop-types";
import { Button } from "@chakra-ui/react";
import React from "react";
import { Link } from "react-router-dom";
import CustomImg from "../../../../assets/routine/ico_custom.png";
import TemplateImg from "../../../../assets/routine/ico_template.png";
import PreprogramImg from "../../../../assets/routine/ico_preprogram.png";
import Assesments from "../../../../assets/routine/ico_assesments.png";

import { container, btnGroup, title, card, subtitle } from "./NoProgramsDisplay.module.css";

const NoProgramsDisplay = ({ name, idClient, message, clientInfo }) => {
  const { t } = useLang();
  const getWorkType = (n) => {
    if (n === 1) return "template";
    if (n === 2) return "preprogrammed";
    return "custom";
  };

  const setConfig = (numberType) => ({
    pathname: "/user/routines",
    state: {
      step: 3,
      client: `${name}___${idClient}___${clientInfo?.lastName}`,
      clientInfo,
      workType: getWorkType(numberType),
    },
  });
  return (
    <div className={container}>
      <div className={card}>
        <p className={title}>{t("evaluation")}</p>
        <img src={Assesments} alt="evaluation program" />
        <p className={subtitle}>{t("create_new_program_assessments")}</p>

        <Button
          w="100%"
          mt="1rem"
          as={Link}
          to={`evaluation/${name}___${idClient}`}
          variant="outline"
          colorScheme="teal">
          {t("start")}
        </Button>
      </div>
      <div className={card}>
        <p className={title}>{t("from_scratch")}</p>
        <img src={CustomImg} alt="scratch program" />
        <p className={subtitle}>{t("create_new_program_from_scratch")}</p>
        <Button w="100%" mt="1rem" as={Link} to={setConfig()} variant="outline" colorScheme="teal">
          {t("start")}
        </Button>
      </div>
      <div className={card}>
        <p className={title}>{t("preprogrammed_workout")}</p>
        <img src={PreprogramImg} alt="preprogrammed program" />
        <p className={subtitle}>{t("create_new_program_preprogrammed")}</p>
        <Button w="100%" mt="1rem" as={Link} to={setConfig(2)} variant="outline" colorScheme="teal">
          {t("start")}
        </Button>
      </div>
      <div className={card}>
        <p className={title}>{t("template_workout")}</p>
        <img src={TemplateImg} alt="template program" />
        <p className={subtitle}>{t("create_new_program_template")}</p>
        <Button w="100%" mt="1rem" as={Link} to={setConfig(1)} variant="outline" colorScheme="teal">
          {t("start")}
        </Button>
      </div>
    </div>
  );
};

NoProgramsDisplay.defaultProps = {
  message: "No program Found",
};

NoProgramsDisplay.propTypes = {
  name: string.isRequired,
  idClient: string.isRequired,
  message: string,
};

export default NoProgramsDisplay;
