import React, { useState } from 'react';
import propTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useUploadFileS3 from 'hooks/useUploadFileS3';
import { Button, ButtonGroup, Flex } from '@chakra-ui/react';
import { ArrowUpIcon } from '@chakra-ui/icons';

import { transformHelpData } from 'utils/transformData';
import useUpdate from 'hooks/useUpdate';
import { updateHelp } from 'services/helps';
import notificationDisplay from 'utils/notificationDisplay';

import Carousel from 'components/common/Carousel';
import TextInput from 'components/common/TextInput';

const groupStyle = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '1rem 0',
};
const infoStyle = { padding: '2rem 0', textAlign: 'center' };

const Edit = ({ help, close }) => {
  const lang = window.localStorage.getItem('i18nextLng');
  const [carouselStep, setCarouselStep] = useState(0);
  const [imgArr, setImgArr] = useState(help?.images || []);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { handleSubmit, control, register } = useForm({
    mode: 'onChange',
  });
  const uploadFileS3 = useUploadFileS3();
  const {
    mutate, isLoading, isSuccess, isError,
  } = useUpdate('helps', help.id, updateHelp);

  const handleCarouselClick = (movement) => {
    setCarouselStep((prev) => (movement === 'prev' ? prev - 1 : prev + 1));
  };
  const deleteImage = (index) => {
    setImgArr(imgArr.filter((_, id) => id !== index));
    setCarouselStep((prev) => (carouselStep === 0 ? 0 : prev - 1));
  };
  const onChange = async (data) => {
    const file = document.querySelector('.file');
    let response = '';
    if (data.images[0]) {
      setLoading(true);
      response = await uploadFileS3.mutateAsync(data.images[0], data.name);
      if (response.location) {
        notificationDisplay(dispatch, 'Your image have been saved', 'success');
        setImgArr((prev) => prev.concat(response.location));
      }
      file.value = '';
      setLoading(false);
    }
  };

  const onSubmit = (data) => mutate(transformHelpData(data, imgArr, lang));

  if (isLoading) return <p style={infoStyle}>Updating Data</p>;
  if (isError) return <p style={infoStyle}>Something went wrong</p>;
  if (isSuccess) {
    setTimeout(() => close(), 1500);
    return <p style={infoStyle}>Data was updated succesfully</p>;
  }
  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={handleSubmit(onChange)}>
      <fieldset>
        <Flex align="center" justify="center" as="fieldset">
          <Flex align="center" justify="center" direction="column">
            <Carousel
              step={carouselStep}
              click={handleCarouselClick}
              images={imgArr}
              mode="edit"
              erase={deleteImage}
            />
            <Button
              colorScheme="ptRed"
              my="1rem"
              rightIcon={<ArrowUpIcon />}
              onClick={() => document.querySelector('.file').click()}
              isLoading={loading}
            >
              upload New Image
            </Button>
            <input
              accept="image/*"
              className="file"
              type="file"
              {...register('images')}
              style={{ display: 'none' }}
            />
          </Flex>
        </Flex>
        <TextInput
          control={control}
          def={help.title[lang]}
          name="title"
          place={t('title')}
        />
        <TextInput
          control={control}
          def={help.description[lang]}
          type="textarea"
          name="description"
          place={t('description')}
        />
        <TextInput
          control={control}
          def={help.url}
          name="url"
          place={t('video')}
          required={false}
        />

      </fieldset>
      <ButtonGroup style={groupStyle} colorScheme="ptRed">
        <Button onClick={() => close()}>{t('cancel')}</Button>
        <Button type="submit">{t('save')}</Button>
      </ButtonGroup>
    </form>
  );
};

Edit.propTypes = {
  help: propTypes.shape({
    id: propTypes.string.isRequired,
    title: propTypes.string.isRequired,
    description: propTypes.string.isRequired,
    images: propTypes.shape([]).isRequired,
    url: propTypes.string,
  }).isRequired,
  close: propTypes.func.isRequired,
};

export default Edit;
