import React from 'react';
import { useDispatch } from 'react-redux';
import { uiOpenModal } from '../../actions/ui';

const AddNewFab = () => {
  const dispatch = useDispatch();

  const handleclickNew = () => {
    dispatch(uiOpenModal());
  };
  return (
    <button className="btn btn-primary fabi" onClick={handleclickNew} type="button">
      <i className=" fas fa-plus" />
    </button>
  );
};

export default AddNewFab;
