/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React from "react";
import propTypes from "prop-types";
import { useForm } from "react-hook-form";
import { useSelector, useDispatch } from "react-redux";
import { loading, loaded } from "reducers/loader.reducer";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup } from "@chakra-ui/react";
import dayjs from "dayjs";

import useUpdateClient from "hooks/useUpdateClient";
import { transformClientData } from "utils/transformData";
import { updateClient } from "services/clients";

import { infoStyle } from "theme/commonStyles";
import styles from "../../Edit.module.scss";

import DateInput from "../../../common/DateInput";
import TextInput from "../../../common/TextInput";

const groupStyle = {
  alignItems: "center",
  display: "flex",
  justifyContent: "center",
  margin: "1rem 0",
};

const EditClient = ({ client, setEdit, handleUpdateClient }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { handleSubmit, control } = useForm();
  const { mutate, isLoading, isError, isSuccess } = useUpdateClient(client._id, setEdit);
  // dispatch(loading());
  // mutate(transformClientData(data));
  // dispatch(loaded());

  const onSubmit = (data) => {
    handleUpdateClient(client._id, data);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
      <fieldset className={styles.left}>
        <legend>{t("client_basic_info")}</legend>
        <TextInput control={control} def={client.name} name="name" place={t("fullname")} />

        <DateInput
          control={control}
          def={dayjs(client.birthdate).format("YYYY-MM-DD")}
          name="birth_date"
          place={t("birth_date")}
        />

        <TextInput
          control={control}
          def={client.address.street1}
          name="street1"
          place={t("principal_address")}
        />

        <TextInput
          control={control}
          def={client.address.street2}
          name="street1"
          place={t("secondary_address")}
        />

        <TextInput control={control} def={client.address.city} name="city" place={t("city")} />

        <TextInput control={control} def={client.address.state} name="state" place={t("state")} />
        <TextInput control={control} def={client.address.zip} name="zip" place={t("zip")} />
        <TextInput control={control} def={client.phone} name="phone" place={t("phonenumber")} />
      </fieldset>
      <fieldset className={styles.right}>
        <legend>{t("client_user_info")}</legend>
        <TextInput control={control} def={client.email} name="email" place={t("email")} />
        <TextInput
          control={control}
          def={client.speciality}
          name="speciality"
          place={t("main_goals")}
        />
      </fieldset>
      <Button className={styles.btn} colorScheme="ptRed" type="submit">
        {t("save_changes")}
      </Button>
    </form>
  );
};

EditClient.propTypes = {
  client: propTypes.shape({
    name: propTypes.string.isRequired,
    birthdate: propTypes.string.isRequired,
    speciality: propTypes.string.isRequired,
    address: propTypes.shape({
      street1: propTypes.string.isRequired,
      street2: propTypes.string.isRequired,
      city: propTypes.string.isRequired,
      state: propTypes.string.isRequired,
      zip: propTypes.string.isRequired,
    }).isRequired,
    phone: propTypes.string.isRequired,
    email: propTypes.string.isRequired,
    _id: propTypes.string.isRequired,
  }).isRequired,
};

export default EditClient;
