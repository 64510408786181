import React, { useEffect } from "react";
import { Button, ButtonGroup, Flex, useDisclosure } from "@chakra-ui/react";
import { useProgramContext } from "context/ProgramContext";
import AddIcon from "@mui/icons-material/Add";
import useLang from "hooks/useLang";
import { useHistory, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import styles from "./Header.module.css";
import ModalType from "./ModalType";

const Header = () => {
  const { id_user } = useSelector(({ user }) => user);
  const { page, handlePageModeChange } = useProgramContext();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useLang();
  const { program_type } = useParams();
  useEffect(() => {
    if (program_type) {
      document.getElementById(`${program_type}/${id_user}`)?.click();
    }
  }, []);
  return (
    <header className={styles.header}>
      <Flex justifyContent="flex-end">
        <Button onClick={onOpen} leftIcon={<AddIcon />} colorScheme="ptRed">
          {t("create_new_program")}
        </Button>
      </Flex>

      <div className={styles.headerTop}>
        <h3>
          {t("select_workout")} <span>{t("workout")}</span>
        </h3>
      </div>
      <Flex justifyContent="space-between" className={styles.headerOptions}>
        <Flex alignItems="center" gridGap={2} className={styles.headerBtnGroup}>
          <p>{t("preprogrammed_workout")}</p>
          <Flex gridGap={2} className={styles.buttons}>
            <Button
              size="sm"
              colorScheme={`${
                page.mode === "template" && page.owner === "admin" ? "red" : "blackAlpha"
              }`}
              variant="solid"
              onClick={handlePageModeChange}
              id="template/admin">
              {t("template_workout")}
            </Button>
            <Button
              size="sm"
              colorScheme={`${
                page.mode === "preprograms" && page.owner === "admin" ? "red" : "blackAlpha"
              }`}
              variant="solid"
              onClick={handlePageModeChange}
              id="preprograms/admin">
              {t("programs")}
            </Button>
          </Flex>
        </Flex>
        <Flex alignItems="center" gridGap={2} className={styles.headerBtnGroup}>
          <p>{t("create_by_me")}</p>
          <Flex gridGap={2} className={styles.buttons}>
            {" "}
            <Button
              size="sm"
              colorScheme={`${
                page.mode === "userTemplate" && page.owner !== "admin" ? "red" : "blackAlpha"
              }`}
              variant="solid"
              onClick={handlePageModeChange}
              id={`userTemplate/${id_user}`}>
              {t("my_template_workout")}
            </Button>
            <Button
              size="sm"
              colorScheme={`${
                page.mode === "userPreprograms" && page.owner !== "admin" ? "red" : "blackAlpha"
              }`}
              variant="solid"
              onClick={handlePageModeChange}
              id={`userPreprograms/${id_user}`}>
              {t("my_programs")}
            </Button>
          </Flex>
        </Flex>
      </Flex>
      {isOpen && <ModalType isOpen={isOpen} onClose={onClose} />}
    </header>
  );
};

export default Header;
