/* eslint-disable react/prop-types */
import { useEffect, useReducer, useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
} from "@chakra-ui/react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import assessment from "assets/icons/ico_assessment.svg";

import ProgramCreation from "components/evaluation/ProgramCreation";
import Report from "components/evaluation/Report";
import evaluationReducer, {
  initialState,
  setEvaluation,
} from "components/evaluation/evaluationReducer";
import useLang from "hooks/useLang";
import {
  getEvaluationsClient,
  getEvaluationsTrainer,
  getGoalsByClient,
} from "services/evaluations";
import TypeProgramSelector from "components/evaluation/TypeProgramSelector";
import { useSelector } from "react-redux";
import Welcome from "components/evaluation/Welcome";
import { useHistory } from "react-router-dom";
import GoalAndMeasurementsReport from "components/evaluation/goals/goalAndMeasurementsReport";
import BoxInfoCard from "../ClientProfile/BoxInfoCard";

const ClientAssessment = ({ client }) => {
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [evaluations, setEvaluations] = useState([]);
  const [goals, setGoals] = useState([]);
  const [program, setProgram] = useState("");
  const [state, dispatch] = useReducer(evaluationReducer, initialState);
  const [newGoal, setNewGoal] = useState(null);
  const [newMeasurement, setNewMeasurement] = useState(null);
  const [type, setType] = useState("");

  const [evaluationId, setEvaluationId] = useState("");
  const [isEdit, setIsEdit] = useState(true);
  const userData = useSelector(({ user }) => user);
  const { lang, t } = useLang();

  const [loadingEvaluations, setLoadingEvaluations] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const getEvaluations = async () => {
    try {
      const res = await getEvaluationsTrainer(userData.id_user);
      if (res.data) throw new Error(res.data.message);
      setEvaluations(res);
    } catch (exception) {
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };
  const getGoals = async () => {
    try {
      const res = await getGoalsByClient(client._id);
      if (res.data) throw new Error(res.data.message);
      setGoals(res);
    } catch (exception) {
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };

  const getEvaluation = async () => {
    try {
      const res = await getEvaluationsClient(client._id);
      if (res.data) throw new Error(res.data.message);
      // setClient(`${data.id_client?.name}-${data.id_client?._id}`);
      // dispatch(setEvaluation(res.evaluations));
      setEvaluations(res);
      setLoadingEvaluations(false);
      // notificationDisplay(dispatch, t("bio_start"), "success");
    } catch (exception) {
      setLoadingEvaluations(false);
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };
  useEffect(() => {
    getEvaluation();
    getGoals();
  }, []);
  const openModal = () => {
    onOpen();
  };
  const closeModal = () => {
    setStep(1);
    dispatch(setEvaluation([]));
    onClose();
  };
  const goToNewAssessMent = () => {
    history.push(`evaluation/${client.name}___${client._id}`);
  };
  return (
    <>
      <div
        className="infoCard"
        aria-hidden="true"
        onClick={() => {
          openModal();
        }}>
        <BoxInfoCard
          title={` Biomechanical / Goals`}
          value={`${evaluations.length} / ${goals.length}`}
          icon={assessment}
          pointer
        />
      </div>

      <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={closeModal} size="6xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader />
          <ModalCloseButton />
          <ModalBody>
            <>
              {step !== 1 && (
                <Button
                  onClick={() => setStep((prev) => prev - 1)}
                  colorScheme="gray"
                  variant="ghost"
                  leftIcon={<ArrowBackIosIcon fontSize="inherit" />}
                  color="gray">
                  {t("backTo", { page: t("main") })}
                </Button>
              )}
              {step === 1 && (
                <>
                  <Flex justifyContent="end" gridGap={4} mt={8}>
                    <Button type="button" colorScheme="ptRed" onClick={goToNewAssessMent}>
                      {t("start_evaluation")}
                    </Button>
                  </Flex>
                  <Welcome
                    set={setStep}
                    dispatch={dispatch}
                    setClient={() => {}}
                    setEvaluations={setEvaluations}
                    setType={setType}
                    setIsEdit={setIsEdit}
                    loadingEvaluations={loadingEvaluations}
                    evaluations={evaluations}
                    goals={goals}
                    setNewGoal={setNewGoal}
                    setNewMeasurement={setNewMeasurement}
                    setEvaluationId={setEvaluationId}
                    isModal
                  />
                </>
              )}
              {step === 2 &&
                (type === "bio" ? (
                  <Report
                    client={`${client.name}___${client._id}`}
                    evaluations={state}
                    dispatch={dispatch}
                    setProgram={setProgram}
                    setStep={setStep}
                    saveClientEvaluation={() => {}}
                  />
                ) : (
                  <GoalAndMeasurementsReport
                    client={`${client.name}___${client._id}`}
                    measurement={newMeasurement}
                    goal={newGoal}
                  />
                ))}
              {step === 3 && <TypeProgramSelector setProgram={setProgram} setStep={setStep} />}
              {step === 4 && (
                <ProgramCreation
                  program={program}
                  client={`${client.name}___${client._id}`}
                  setStep={setStep}
                />
              )}
            </>
            {/* ) : (
              <Flex direction="column" alignItems="center" gridGap={4} mt={8}>
                <p>{t("no_assessment")}</p>
                <Button type="button" colorScheme="ptRed" onClick={goToNewAssessMent}>
                  {t("start_evaluation")}
                </Button>
              </Flex>
            )} */}
          </ModalBody>

          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeModal}>
              {t("close")}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default ClientAssessment;
