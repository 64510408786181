import instance from "./_axiosConfig";

const api = instance;

const getHelps = async (category) => {
  try {
    const token = localStorage.getItem("userAuth");
    const response = await api.get(`/help/cat/${category}`);
    return response.data.helps;
  } catch (error) {
    return error.response;
  }
};
const createHelp = async (data) => {
  try {
    const response = await api.post("/help/", data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const updateHelp = async (id, data) => {
  try {
    const response = await api.put(`/help/${id}`, data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
const deleteHelp = async (id) => {
  try {
    const response = await api.delete(`/help/${id}`);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export { getHelps, createHelp, updateHelp, deleteHelp };
