const columns = [
  {
    Header: { en: "Exercise", es: "Ejercicio" },
    accessor: "exercise",
  },
  {
    Header: { en: "Load", es: "Carga" },
    accessor: "load",
  },
  {
    Header: { en: "Sets", es: "Sets" },
    accessor: "sets",
  },
  {
    Header: { en: "Reps", es: "Reps" },
    accessor: "reps",
  },
  {
    Header: { en: "Option", es: "Opción" },
    accessor: "opt",
  },
];

const data = [
  {
    exercise: "Core posterior",
    load: "0",
    sets: "3",
    reps: "10",
    opt: "delete",
    weakness: 1,
    id: "",
  },
  {
    exercise: "Escapular empuje",
    load: "0",
    sets: "3",
    reps: "10",
    opt: "delete",
    weakness: 3,
    id: "",
  },
  {
    exercise: "Rigidez Core",
    load: "0",
    sets: "3",
    reps: "10",
    opt: "delete",
    weakness: 6,
    id: "",
  },
  {
    exercise: "Rotadores externos cadera",
    load: "0",
    sets: "3",
    reps: "10",
    opt: "delete",
    weakness: 5,
    id: "",
  },
  {
    exercise: "Escapular tracción",
    load: "0",
    sets: "3",
    reps: "10",
    opt: "delete",
    weakness: 4,
    id: "",
  },
  {
    exercise: "Core anterior",
    load: "0",
    sets: "3",
    reps: "10",
    opt: "delete",
    weakness: 2,
    id: "",
  },
  {
    exercise: "Core posterior",
    load: "0",
    sets: "3",
    reps: "10",
    opt: "delete",
    weakness: 1,
    id: "",
  },
  {
    exercise: "Escapular empuje",
    load: "0",
    sets: "3",
    reps: "10",
    opt: "delete",
    weakness: 3,
    id: "",
  },
  {
    exercise: "Escapular tracción",
    load: "0",
    sets: "3",
    reps: "10",
    opt: "delete",
    weakness: 4,
    id: "",
  },
];

const initialState = {
  columns,
  data,
};

const functionalReducer = (state, action) => {
  const { type, rowIdx, columnIdx, value } = action;
  switch (type) {
    case "INIT_FUNCTIONAL":
      return initialState;
    case "DELETE_ROW":
      return {
        ...state,
        data: state.data.filter((row, index) => index !== +value),
      };
    case "UPDATE_DATA":
      return {
        ...state,
        data: state.data.map((row, index) => {
          if (index === rowIdx) {
            return {
              ...state.data[rowIdx],
              // [columnIdx]: exerciseIdx ? `${value}*${exerciseIdx}` : value,
              [columnIdx]: value,
            };
          }
          return row;
        }),
      };
    default:
      return state;
  }
};

const deleteRow = (value) => ({ type: "DELETE_ROW", value });
const updateData = (value, rowIdx, columnIdx, exerciseIdx) => ({
  type: "UPDATE_DATA",
  value,
  rowIdx,
  columnIdx,
  exerciseIdx,
});

export default functionalReducer;

export { initialState, deleteRow, updateData };
