/* eslint-disable react/prop-types */
/* eslint-disable no-unreachable */
/* eslint-disable no-lone-blocks */
/* eslint-disable no-useless-return */
/* eslint-disable no-undef */
/* eslint-disable max-len */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect, useReducer, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@chakra-ui/react";
import { Redirect } from "react-router-dom";

import propTypes from "prop-types";

// testing
import { addDay, addWeek, deleteDay, deleteWeek, resetWeeks } from "reducers/weekReducer.reducer";
// testing end
import notificationDisplay from "utils/notificationDisplay";
import { isEmpty } from "utils/checkEmptyness";
import { getExerciseModel, getExerciseModelFromTemplate } from "utils/exerciseModel";
import {
  createWeekDayStructure,
  returnPredefinePreprogrammed,
  returnPredefineTemplate,
} from "utils/workout.utils";
import { createRoutine, getRoutine, updateRoutine } from "services/customWorkout";
import useLang from "hooks/useLang";

import DateList from "components/common/DateList";
import DisplayMessage from "components/common/DisplayMessage";
import ErrorBoundary from "components/common/ErrorBoundary";
import RoutineInfo from "components/routines/workout/Features/RoutineInfo";
import DeleteAlert from "components/routines/DeleteAlert";
import RoutineDisplay from "components/routines/workout/Features/RoutineDisplay";

// newVersion
const WorkoutCreator = ({ client, workout, preInfo, setIsCreate, clientInfo }) => {
  const [loadingBtn, toggleBtn] = useReducer((prev) => !prev, false);
  const [loading, toggleLoading] = useReducer((prev) => !prev, true);
  const [preSelected, setPreSelected] = useState([]);
  const [isDeleteOpen, setIsDeleteOpen] = useState(false);
  const [redirection, setRedirection] = useState(false);

  const [name, setName] = useState("");
  const [phase, setPhase] = useState("");

  // testing
  const [actualWeek, setActualWeek] = useState(1);
  const [actualDay, setActualDay] = useState(1);
  const [deleteType, setDeleteType] = useState("day");

  const weekData = useSelector(({ weekList }) => weekList);
  // testing end
  const { _id } = useSelector(({ user }) => user);
  const { t, lang } = useLang();
  const dispatch = useDispatch();
  // let response = '';

  const actual = {
    day: actualDay,
    week: actualWeek,
  };

  const onDeleteClose = () => setIsDeleteOpen(false);

  // week func
  const handleAddWeek = () => {
    dispatch(addWeek());
    setActualWeek(actualWeek + 1);
    setActualDay(1);
    console.log("week");
  };

  const handleSelectWeek = (week, days) => {
    setActualWeek(week);
    setActualDay(1);
  };
  const handleDeleteWeek = () => {
    setIsDeleteOpen(true);
    setDeleteType("week");
  };

  // days func
  const handleAddDay = () => {
    dispatch(addDay(actualWeek));
    setActualDay(actualDay + 1);
  };

  const handleSelectday = (day) => {
    setActualDay(day);
  };
  const handleDeleteDay = () => {
    // dispatch(deleteDay(actualWeek, weekData[actualWeek].days.length - 1));
    setIsDeleteOpen(true);
    setDeleteType("day");
  };

  const handlePredefineWorkout = (item, mode) => {
    createWeekDayStructure(item, dispatch);
    // organize the fetched data to the preSelected model
    const finalPreSelect =
      mode === "template" ? returnPredefineTemplate(item) : returnPredefinePreprogrammed(item);

    // set the basic week/day structure
    console.log({ finalPreSelect });
    setPreSelected(finalPreSelect);
  };

  const onDeleteSession = () => {
    // dispatch(deleteSession(sessionsData.length));
    if (deleteType === "day") {
      dispatch(deleteDay(actualWeek, weekData[actualWeek - 1].days.length));
      setActualDay(weekData[actualWeek - 1].days.length - 1);
    }
    if (deleteType === "week") {
      dispatch(deleteWeek(weekData.length));
      setActualWeek(weekData.length - 1);
      setActualDay(1);
    }
    // display the user to the actual last day
    setIsDeleteOpen(false);
  };

  const handleSave = async () => {
    if (!name) return notificationDisplay(dispatch, "Please enter the routine Name", "error");

    toggleBtn();
    const routine = {
      trainer: _id,
      source: workout === "preprogrammed" ? "pre-programmed" : workout,
      client: client.split("___")[1],
      name,
      phase,
      sessions: weekData,
    };

    try {
      const response = await createRoutine(getExerciseModel(routine));
      if (response.data) return notificationDisplay(dispatch, "something went wrong", "error");
      notificationDisplay(dispatch, "Routine created", "success");
    } catch (error) {
      console.log(error.message);
    }
    setTimeout(() => {
      setRedirection(true);
    }, 2000);
  };

  useEffect(() => {
    dispatch(resetWeeks());
    if (workout !== "custom") handlePredefineWorkout(preInfo, workout);
    toggleLoading();
  }, []);

  if (redirection) {
    const redirectionConfig = { pathname: "/user/clients" };
    return <Redirect to={redirectionConfig} />;
  }

  return (
    <>
      {/* <ErrorBoundary>
        <RoutineInfo
          setPhase={setPhase}
          phase={phase}
          setName={setName}
          name={name}
        />
      </ErrorBoundary> */}

      {!loading && (
        <RoutineDisplay
          workout={workout}
          data={weekData}
          preSelected={preSelected}
          actual={actual}
          clientInfo={clientInfo}
          setIsCreate={setIsCreate}
          preInfo={preInfo.sessions}
          client={client}
          name={name}
          phase={phase}
        />
      )}

      {/* {
        (isEmpty(weekData)) && (
          <Button onClick={handleSave} colorScheme="ptRed" mt="1rem" isLoading={loadingBtn}>
            {t('save_routine')}
          </Button>
        )
      }
      */}

      <DeleteAlert isOpen={isDeleteOpen} onClose={onDeleteClose} onDelete={onDeleteSession} />
    </>
  );
};

export default WorkoutCreator;
