import { Avatar } from "@chakra-ui/react";
import useLang from "hooks/useLang";
import styles from "./Dashboard.module.scss";
import updateIcon from "../../assets/icons/ico_updates.svg";
import image from "../../assets/image1.png";

const LatestEntries = () => {
  const {t} = useLang()
  return (
    <div className={styles.entries}>
      <div className={styles.header}>
        <img src={updateIcon} alt="icon" />
        <p>{t('dashboard_LatestEntries.lastset_blog')}</p>
      </div>
      {/* <div className={styles.feature}>
        <Avatar src={image} />
        <p>{t('dashboard_LatestEntries.resolutions_new_year')}</p>
      </div>
      <hr />
      <div className={styles.feature}>
        <Avatar src={image} />
        <p>{t('dashboard_LatestEntries.resolutions_new_year')}</p>
      </div>
      <hr />
      <div className={styles.feature}>
        <Avatar src={image} />
        <p>{t('dashboard_LatestEntries.resolutions_new_year')}</p>
      </div> */}
    </div>
  );
};

export default LatestEntries;
