/* eslint-disable import/prefer-default-export */
import instance from "./_axiosConfig";

const api = instance;

const getAllMuscles = async () => {
  try {
    const res = await api.get("/muscle/");
    return res.data.muscles;
  } catch (error) {
    return error.message;
  }
};

export { getAllMuscles };
