import instance from "./_axiosConfig";

const api = instance;
const startBio = async (data) => {
  try {
    const response = await api.post("/biomechanicalanalysis/", data);
    return response.data.biomechanicalAnalisys;
  } catch (error) {
    return error.response;
  }
};
const updateBio = async (id, data) => {
  try {
    const response = await api.put(`/biomechanicalanalysis/${id}`, data);
    return response.data.biomechanicalAnalisys;
  } catch (error) {
    return error.response;
  }
};
const getEvaluation = async (id) => {
  try {
    const response = await api.get(`/biomechanicalanalysis/${id}`);
    return response.data.biomechanicalAnalysis;
  } catch (error) {
    return error.response;
  }
};

const getEvaluationsTrainer = async (id) => {
  try {
    const response = await api.get(`/biomechanicalanalysis/trainer/${id}`);
    return response.data.biomechanicalAnalysis;
  } catch (error) {
    return error.response;
  }
};
const getEvaluationsClient = async (id) => {
  try {
    const response = await api.get(`/biomechanicalanalysis/client/${id}`);
    return response.data.biomechanicalAnalysis;
  } catch (error) {
    return error.response;
  }
};

const deleteEvaluation = async (id) => {
  try {
    const response = await api.delete(`/biomechanicalanalysis/${id}`);
    return response.data.msg;
  } catch (error) {
    return error.response;
  }
};
const getGoalsByUser = async (id) => {
  try {
    const response = await api.get(`/client_goals?user=${id}`);
    return response.data.goals;
  } catch (error) {
    return error.response;
  }
};
const getGoalsByClient = async (id) => {
  try {
    const response = await api.get(`/client_goals?client=${id}`);
    return response.data.goals;
  } catch (error) {
    return error.response;
  }
};
const getMeasurementsByUser = async (clientId) => {
  try {
    const response = await api.get(`/client_measurements?client=${clientId}`);
    return response.data.measurements;
  } catch (error) {
    return error.response;
  }
};
const saveNewGoal = async (data) => {
  try {
    const response = await api.post(`/client_goals`, data);
    return response.data.goals;
  } catch (error) {
    return error.response;
  }
};
const saveNewMeasurement = async (data) => {
  try {
    const response = await api.post(`/client_measurements`, data);
    return response.data.measurements;
  } catch (error) {
    return error.response;
  }
};
const updateGoal = async (id, data) => {
  try {
    const response = await api.put(`/client_goals/${id}`, data);
    return response.data.goal;
  } catch (error) {
    return error.response;
  }
};
const updateMeasurement = async (id, data) => {
  try {
    const response = await api.put(`/client_measurements/${id}`, data);
    return response.data.measurements;
  } catch (error) {
    return error.response;
  }
};
const deleteGoalService = async (id) => {
  try {
    const response = await api.delete(`/client_goals/${id}`);
    return response.data.goals;
  } catch (error) {
    return error.response;
  }
};
const deleteMeasurementService = async (id) => {
  try {
    const response = await api.delete(`/client_measurements/${id}`);
    return response.data.measurements;
  } catch (error) {
    return error.response;
  }
};
export {
  startBio,
  updateBio,
  getEvaluationsTrainer,
  deleteEvaluation,
  getEvaluationsClient,
  getGoalsByUser,
  getMeasurementsByUser,
  saveNewGoal,
  saveNewMeasurement,
  updateGoal,
  updateMeasurement,
  getGoalsByClient,
  deleteGoalService,
  deleteMeasurementService,
  getEvaluation,
};
