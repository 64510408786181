/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import { useEffect, useState } from "react";
import {
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  HStack,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Radio,
  RadioGroup,
  VStack,
} from "@chakra-ui/react";
import useLang from "hooks/useLang";
import styles from "./goalForm.module.scss";

const GoalForm = ({ goal, formStep, setNewGoal, saveGoal }) => {
  const { t, lang } = useLang();

  const [primary_goals, setPrimaryGoals] = useState(goal?.primary_goals || [""]);
  const [time_frame, setTimeFrame] = useState(goal?.time_frame || [""]);
  const [measures_success, setMeasuresSuccess] = useState(goal?.measures_success || "");
  const [lose_weight, setLoseWeight] = useState(goal?.lose_weight || "");
  const [lose_inches, setLoseInches] = useState(goal?.lose_inches || "");
  const [lose_inches_where, setLoseInchesWhere] = useState(goal?.lose_inches_where || "");
  const [lose_fat, setLoseFat] = useState(goal?.lose_fat || "");
  const [lose_fat_where, setLoseFatWhere] = useState(goal?.lose_fat_where || "");
  const [gain_weight, setGainWeight] = useState(goal?.gain_weight || "");
  const [gain_muscle, setGainMuscle] = useState(goal?.gain_muscle || "");
  const [gain_muscle_where, setGainMuscleWhere] = useState(goal?.gain_muscle_where || "");
  const [activity_enhancement, setActivityEnhancement] = useState(
    goal?.activity_enhancement.split(",") || [],
  );
  const [physical_abilities, setPhysicalAbilities] = useState(
    goal?.physical_abilities.split(",") || [],
  );
  const [performance_components, setPerformanceComponents] = useState(
    goal?.performance_components.split(",") || [],
  );
  const [performance_qualities, setPerformanceQualities] = useState(
    goal?.performance_qualities.split(",") || [],
  );
  const [contraindications, setContraindications] = useState(
    goal?.contraindications || [{ condition: "", contraindicate_movements: "" }],
  );

  const [hobbyInput, setHobbyInput] = useState("");
  const [sportInput, setSportInput] = useState("");
  const [fitnessInput, setFitnessInput] = useState("");

  const handlePrimaryGoalsChange = (index, value) => {
    const data = [...primary_goals];
    data[index] = value;
    setPrimaryGoals(data);
  };
  const addPrimaryGoals = () => {
    const data = [...primary_goals];
    data.push("");
    setPrimaryGoals(data);
  };
  const handleTimeFrameChange = (index, value) => {
    const data = [...time_frame];
    data[index] = value;
    setTimeFrame(data);
  };
  const addTimeFrame = () => {
    const data = [...time_frame];
    data.push("");
    setTimeFrame(data);
  };
  const handleContraindicationsChange = (index, name, value) => {
    const data = [...contraindications];
    data[index][name] = value;
    setContraindications(data);
  };
  const addContraindications = () => {
    const data = [...contraindications];
    data.push({ condition: "", contraindicate_movements: "" });
    setContraindications(data);
  };
  const getActivitiEnhancement = () => {
    let text = "";
    text = text.concat(
      activity_enhancement.some((e) => e === "General Fitness")
        ? `General fitness: ${fitnessInput},`
        : "",
    );
    text = text.concat(
      activity_enhancement.some((e) => e === "Hobby") ? `Hobby: ${hobbyInput},` : "",
    );
    text = text.concat(
      activity_enhancement.some((e) => e === "Sport") ? `Sport: ${sportInput}` : "",
    );
    return text;
  };

  const handleSave = () => {
    const newGoal = {
      primary_goals,
      time_frame,
      measures_success,
      lose_weight,
      lose_inches,
      lose_inches_where,
      lose_fat,
      lose_fat_where,
      gain_weight,
      gain_muscle,
      gain_muscle_where,
      activity_enhancement: getActivitiEnhancement(),
      physical_abilities: physical_abilities.join(),
      performance_components: performance_components.join(),
      performance_qualities: performance_qualities.join(),
      contraindications,
    };
    saveGoal(newGoal);
  };
  useEffect(() => {
    return () => {
      if (formStep === 4) handleSave();
    };
  }, [formStep]);
  return (
    <div className={styles.form}>
      <Heading>{t("goalForm.clientGoals")}</Heading>
      {formStep === 1 && (
        <div>
          <Heading as="h4" size="md">
            {t("goalForm.generalInformation")}
          </Heading>
          <FormControl mt={4}>
            <FormLabel>{t("goalForm.primaryGoals")}</FormLabel>
            {primary_goals.map((input, index) => (
              <Input
                mb={2}
                type="text"
                variant="outline"
                value={input}
                onChange={(e) => handlePrimaryGoalsChange(index, e.target.value)}
              />
            ))}

            <Button size="sm" mt={2} onClick={addPrimaryGoals}>
              {t("goalForm.addMore")}
            </Button>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("goalForm.timeFrame")}</FormLabel>
            {time_frame.map((input, index) => (
              <Input
                mb={2}
                type="text"
                variant="outline"
                value={input}
                onChange={(e) => handleTimeFrameChange(index, e.target.value)}
              />
            ))}
            <Button size="sm" mt={2} onClick={addTimeFrame}>
              {t("goalForm.addMore")}
            </Button>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("goalForm.measuresSuccess")}</FormLabel>
            <Input
              type="text"
              variant="outline"
              value={measures_success}
              onChange={(e) => setMeasuresSuccess(e.target.value)}
            />
          </FormControl>
        </div>
      )}
      {formStep === 2 && (
        <div>
          <Heading as="h4" size="md">
            {t("goalForm.bodyComposition")}
          </Heading>
          <FormControl mt={4}>
            <FormLabel>{t("goalForm.loseWeight")}</FormLabel>

            <InputGroup>
              <Input
                type="number"
                variant="outline"
                value={lose_weight}
                onChange={(e) => setLoseWeight(e.target.value)}
              />
              <InputRightElement children={lang === "es" ? "kg" : "lb"} />
            </InputGroup>
          </FormControl>
          <Flex gridGap={4} alignItems="end">
            <FormControl mt={4}>
              <FormLabel>{t("goalForm.loseInches")}</FormLabel>

              <InputGroup>
                <Input
                  type="number"
                  variant="outline"
                  value={lose_inches}
                  onChange={(e) => setLoseInches(e.target.value)}
                />
                <InputRightElement children={lang === "es" ? "cm" : "in"} />
              </InputGroup>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("goalForm.where")}</FormLabel>
              <Input
                type="text"
                variant="outline"
                value={lose_inches_where}
                onChange={(e) => setLoseInchesWhere(e.target.value)}
              />
            </FormControl>
          </Flex>
          <Flex gridGap={4} alignItems="end">
            <FormControl mt={4}>
              <FormLabel>{t("goalForm.loseFat")}</FormLabel>

              <InputGroup>
                <Input
                  type="number"
                  variant="outline"
                  value={lose_fat}
                  onChange={(e) => setLoseFat(e.target.value)}
                />
                <InputRightElement children={lang === "es" ? "gr" : "lb"} />
              </InputGroup>
            </FormControl>

            <FormControl mt={4}>
              <FormLabel>{t("goalForm.where")}</FormLabel>
              <Input
                type="text"
                variant="outline"
                value={lose_fat_where}
                onChange={(e) => setLoseFatWhere(e.target.value)}
              />
            </FormControl>
          </Flex>
          <FormControl mt={4}>
            <FormLabel>{t("goalForm.gainWeight")}</FormLabel>

            <InputGroup>
              <Input
                type="number"
                variant="outline"
                value={gain_weight}
                onChange={(e) => setGainWeight(e.target.value)}
              />
              <InputRightElement children={lang === "es" ? "kg" : "lb"} />
            </InputGroup>
          </FormControl>
          <Flex gridGap={4} alignItems="end">
            <FormControl mt={4}>
              <FormLabel>{t("goalForm.gainMuscle")}</FormLabel>

              <InputGroup>
                <Input
                  type="number"
                  variant="outline"
                  value={gain_muscle}
                  onChange={(e) => setGainMuscle(e.target.value)}
                />
                <InputRightElement children={lang === "es" ? "kg" : "lb"} />
              </InputGroup>
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>{t("goalForm.where")}</FormLabel>
              <Input
                type="text"
                variant="outline"
                value={gain_muscle_where}
                onChange={(e) => setGainMuscleWhere(e.target.value)}
              />
            </FormControl>
          </Flex>
        </div>
      )}
      {formStep === 3 && (
        <div>
          <Heading as="h4" size="md">
            {t("goalForm.performanceFitness")}
          </Heading>
          <FormControl mt={4}>
            <FormLabel>{t("goalForm.activityEnhancement")}</FormLabel>
            <CheckboxGroup onChange={setActivityEnhancement} value={activity_enhancement}>
              <VStack spacing="24px" align="stretch">
                <Flex gridGap={4}>
                  <Checkbox value="General Fitness" minW="150px">
                    {t("goalForm.generalFitness")}
                  </Checkbox>
                  <Input
                    id="input-hobby"
                    type="text"
                    variant="outline"
                    value={fitnessInput}
                    onChange={(e) => setFitnessInput(e.target.value)}
                  />
                </Flex>
                <Flex gridGap={4}>
                  <Checkbox value="Hobby">{t("goalForm.hobby")}</Checkbox>
                  <Input
                    id="input-hobby"
                    type="text"
                    variant="outline"
                    value={hobbyInput}
                    onChange={(e) => setHobbyInput(e.target.value)}
                  />
                </Flex>
                <Flex gridGap={4}>
                  <Checkbox value="Sport">{t("goalForm.sport")}</Checkbox>
                  <Input
                    id="input-sport"
                    type="text"
                    variant="outline"
                    value={sportInput}
                    onChange={(e) => setSportInput(e.target.value)}
                  />
                </Flex>
              </VStack>
            </CheckboxGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("goalForm.physicalAbilities")}</FormLabel>
            <CheckboxGroup onChange={setPhysicalAbilities} value={physical_abilities}>
              <HStack spacing="24px">
                <Checkbox value="Size">{t("goalForm.size")}</Checkbox>
                <Checkbox value="Strength">{t("goalForm.strength")}</Checkbox>
                <Checkbox value="Power">{t("goalForm.power")}</Checkbox>
                <Checkbox value="Power Endurance">{t("goalForm.powerEndurance")}</Checkbox>
              </HStack>
            </CheckboxGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("goalForm.performanceComponents")}</FormLabel>
            <CheckboxGroup onChange={setPerformanceComponents} value={performance_components}>
              <HStack spacing="24px">
                <Checkbox value="Speed">{t("goalForm.speed")}</Checkbox>
                <Checkbox value="Agility">{t("goalForm.agility")}</Checkbox>
                <Checkbox value="Quickness">{t("goalForm.quickness")}</Checkbox>
              </HStack>
            </CheckboxGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("goalForm.performanceQualities")}</FormLabel>
            <CheckboxGroup onChange={setPerformanceQualities} value={performance_qualities}>
              <HStack spacing="24px">
                <Checkbox value="Stability">{t("goalForm.stability")}</Checkbox>
                <Checkbox value="Balance">{t("goalForm.balance")}</Checkbox>
                <Checkbox value="Flexibility">{t("goalForm.flexibility")}</Checkbox>
                <Checkbox value="Coordination">{t("goalForm.coordination")}</Checkbox>
              </HStack>
            </CheckboxGroup>
          </FormControl>
        </div>
      )}
      {formStep === 4 && (
        <div>
          <Heading as="h4" size="md">
            {t("goalForm.diagnosedContraindications")}
          </Heading>
          {contraindications.map((input, index) => (
            <Flex gridGap={4}>
              <FormControl mt={4}>
                <FormLabel>{t("goalForm.condition")}</FormLabel>
                <Input
                  type="text"
                  variant="outline"
                  value={input.condition}
                  onChange={(e) =>
                    handleContraindicationsChange(index, "condition", e.target.value)
                  }
                />
              </FormControl>
              <FormControl mt={4}>
                <FormLabel>{t("goalForm.contraindicatedMovements")}</FormLabel>
                <Input
                  type="text"
                  variant="outline"
                  value={input.contraindicate_movements}
                  onChange={(e) =>
                    handleContraindicationsChange(index, "contraindicate_movements", e.target.value)
                  }
                />
              </FormControl>
            </Flex>
          ))}

          <Button size="sm" mt={2} onClick={addContraindications}>
            {t("goalForm.addMore")}
          </Button>
        </div>
      )}
    </div>
  );
};
export default GoalForm;
