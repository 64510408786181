/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, Input } from "@chakra-ui/react";
import useLang from "hooks/useLang";

const CaloriesTotal = ({ setCalories }) => {
  const { t } = useLang();
  return (
    <Box>
      <label>{t("nutrition_section.total_calores")}</label>
      <Input placeholder="2000" name="calories" onChange={(e) => setCalories(e.target.value)} />
    </Box>
  );
};

export default CaloriesTotal;
