/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { shape, number } from "prop-types";
import { getClientById } from "services/clients";

import useLang from "hooks/useLang";
import Board from "./components/Board";

const BoardAssessments = ({ preInfo, client, workout, handleGenerateProgram, isBtnLoading }) => {
  const [filtered, setFiltered] = useState([]);
  const [clientData, setClientData] = useState({})
  const { t } = useLang();

  const getClientInformation = async () => {
    try {
      const res = await getClientById(client.split("___")[1])
      setClientData(res.client)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    const sidebar = document.getElementsByClassName("sidebar")[0];

    if (sidebar) sidebar.setAttribute("class", "sidebar collapse");
    return () => {
      if (sidebar) sidebar.setAttribute("class", "sidebar");
    };
  }, []);
  useEffect(() => {
    getClientInformation()
    const daysField = preInfo.sessions.map((i) => {
      const add = {
        ...i,
        id: i._id,
        author: {
          id: i._id,
          name: i.week,
          colors: {
            soft: "white",
            hard: "white",
          },
        },
      };
      return add;
    });
    const FormatDays = [...daysField];
    const array = FormatDays?.map(({ week }) => week);
    const newarray = [...new Set(array)];
    const dataFilter = newarray?.map(( item) =>
      daysField?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );
    setFiltered(dataFilter);
  }, [preInfo]);

  return (
    <>
      <Board
        initial={filtered}
        withScrollableColumns
        workout={workout}
        client={client}
        isBtnLoading={isBtnLoading}
        clientData={clientData}
        handleGenerateProgram={handleGenerateProgram}
        setFiltered={setFiltered}
      />
    </>
  );
};

BoardAssessments.propTypes = {};

export default BoardAssessments;
