import React from "react";
import { string } from "prop-types";
import Swal from "sweetalert2";

const Notification = ({ message, status }) => {
  const ToastAlert = () => {
    const Toast = Swal.mixin({
      toast: true,
      position: "top-end",
      showConfirmButton: false,
      timer: 3000,
      timerProgressBar: true,
      didOpen: (toast) => {
        toast.addEventListener("mouseenter", Swal.stopTimer);
        toast.addEventListener("mouseleave", Swal.resumeTimer);
      },
    });
    Toast.fire({
      icon: `${status}`,
      title: `${message}`,
    });

    return Toast;
  };

  return <ToastAlert />;
};

Notification.propTypes = {
  message: string.isRequired,
  status: string.isRequired,
};

export default Notification;
