import React from "react";
// import styles from './Exercises.module.scss';
import { bigContainerStyle } from "theme/commonStyles";
import Board from "./Board";

const Exercises = () => (
  <>
    <Board />
  </>
);

export default Exercises;
