/* eslint-disable eqeqeq */
import React, { useState } from "react";
import { useSelector } from "react-redux";

import { Image, Badge, IconButton, Flex } from "@chakra-ui/react";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { deleteExercise } from "services/exercise";
import useLang from "hooks/useLang";
import useDelete from "hooks/useDelete";
import logo from "assets/img2.png";
import styles from "./Exercises.module.scss";
import DeleteAlert from "./DeleteAlert";

/* eslint-disable react/prop-types */
const ExerciseCard = ({ exercise, displayMode, onClick, isSelect, selectExercise }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const { role, id_user } = useSelector(({ user }) => user);
  const { t, lang } = useLang();
  const handleDeleteClose = () => setDeleteOpen(false);
  const handleDeleteOpen = () => setDeleteOpen(true);

  return (
    <div className={`${styles.card} ${styles[displayMode]}`}>
      <div className={styles.preview}>
        {exercise.images.length <= 0 ? (
          <Image src={logo} fallbackSrc={logo} alt="file exercise" />
        ) : (
          <>
            {!exercise.images[0].includes("mp4") && !exercise.images[0].includes("mov") ? (
              <Image
                src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${exercise.images[0]}`}
                fallbackSrc={logo}
                alt="file exercise"
                loading="lazy"
              />
            ) : (
              <video
                width="400"
                preload="metadata"
                autoPlay
                playsinline
                muted
                fallbackSrc={logo}
                loop
                loading="lazy">
                <source
                  src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${exercise.images[0]}#t=0.1`}
                  type="video/mp4"
                  fallbackSrc={exercise.images[0]}
                />
                <track kind="captions" />
              </video>
            )}
          </>
        )}
      </div>

      <div className={styles.content}>
        <div className={styles.header}>
          <h3 className={styles.title}>{exercise.name[lang]}</h3>
          <Flex gridGap={4} justifyContent="center">
            {!isSelect && (
              <IconButton
                onClick={() => onClick(exercise, "info")}
                isRound
                fontSize="10px"
                w={4}
                icon={<VisibilityIcon fontSize="small" />}
              />
            )}
            {((!isSelect && role === "admin") ||
              (!isSelect && exercise?.trainerId === id_user)) && (
              <IconButton
                onClick={() => onClick(exercise, "edit")}
                isRound
                fontSize="10px"
                w={4}
                icon={<EditIcon fontSize="small" />}
              />
            )}
            {((!isSelect && role === "admin") ||
              (!isSelect && exercise?.trainerId === id_user)) && (
              <IconButton
                onClick={handleDeleteOpen}
                isRound
                fontSize="10px"
                w={4}
                icon={<DeleteIcon fontSize="small" />}
                colorScheme="red"
              />
            )}
            {isSelect && (
              <IconButton
                onClick={() =>
                  selectExercise({ ...exercise, colors: { hard: "white", soft: "white" } })
                }
                isRound
                fontSize="10px"
                w={4}
                icon={<AddIcon fontSize="small" />}
                colorScheme="red"
              />
            )}
          </Flex>
        </div>
        <div className={styles.extraInfo}>
          <h6 className={styles.subtitle}>{t("table_action")}</h6>
          {exercise.action
            .filter((a) => a !== "")
            .map((name) => (
              <Badge colorScheme="yellow" mr="0.5rem" key={name}>
                {name}
              </Badge>
            ))}
        </div>
        <div className={styles.extraInfo}>
          <h6 className={styles.subtitle}>{t("categories")}</h6>
          {exercise.category_id
            .filter((a) => a?.name !== "")
            .map(({ _id, name }) => (
              <Badge colorScheme="yellow" mr="0.5rem" key={_id}>
                {name}
              </Badge>
            ))}
        </div>
        <div className={styles.extraInfo}>
          <h6 className={styles.subtitle}>{t("muscle_system")}</h6>
          {exercise.muscle_system.map((muscle) => (
            <Badge colorScheme="gray" mr="0.5rem" key={muscle}>
              {muscle}
            </Badge>
          ))}
        </div>
      </div>
      {deleteOpen && (
        <DeleteAlert
          open={deleteOpen}
          close={handleDeleteClose}
          id={exercise._id}
          title={t("delete_exercise")}
          func={() => useDelete("exercises", deleteExercise)}
        />
      )}
    </div>
  );
};

export default ExerciseCard;
