/* eslint-disable no-nested-ternary */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable react/prop-types */
import React from "react";
import {
  Document,
  Page,
  View,
  StyleSheet,
  PDFDownloadLink,
  Image,
  Text,
} from "@react-pdf/renderer";
import useLang from "hooks/useLang";
import { Button } from "@chakra-ui/react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import Logo from "../assets/1.png";
import Alternative from "../assets/icons/world.png";
import TopImage from "../assets/headerPdf.png";
import Facebook from "../assets/icons/facebook_logo.png";
import Instagram from "../assets/icons/instagram_logo.png";
import X from "../assets/icons/x_logo.png";
import DayDisplayPdf from "./DayDisplayPdf";
import { WeeksDisplayPdf } from "./WeeksDisplayPdf";

const styles = StyleSheet.create({
  widthFull: {
    width: "100%",
  },
  headerTitleInformation: {
    fontSize: 10,
    color: "white",
    paddingLeft: 10,
    paddingVertical: 3,
    backgroundColor: "black",
    borderTop: "2px solid black",
    textTransform: "uppercase",
    borderTopLeftRadius: 15,
    borderTopRightRadius: 15,
  },
  headerInformation: {
    display: "flex",
    flexDirection: "row",
  },
  clientContainer: {
    width: "40%",
    height: 170,
    fontSize: 8,
    border: "1.5px solid black",
    borderRadius: 15,
  },
  containerFields: {
    paddingVertical: 4,
  },
  clientBox: {
    display: "flex",
    paddingHorizontal: 10,
    paddingVertical: 3,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  clientLabel: {
    width: "30%",
    paddingVertical: 3,
  },
  clientInformation: {
    backgroundColor: "#f6f6f6",
    padding: 3,
    borderBottom: "1px solid #b42519",
    width: "70%",
    borderRadius: 5,
  },
  trainerContainer: {
    width: "55%",
    height: 170,
    fontSize: 8,
    border: "1.5px solid black",
    borderRadius: 15,
  },
  trainerMainBox: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  trainerBox: {
    width: "29%",
    height: 100,
    padding: 5,
    margin: "auto",
  },
  trainerBoxContactInfo: {
    width: "67%",
    height: 170,
    padding: 5,
  },
  divider: {
    height: "100%",
    width: "0.3%",
    backgroundColor: "black",
  },
  trainertBoxField: {
    display: "flex",
    paddingVertical: 2,
    flexDirection: "row",
    flexWrap: "wrap",
  },
  trainertLabel: {
    width: "32%",
    paddingVertical: 3,
  },
  trainertInformation: {
    backgroundColor: "#f6f6f6",
    padding: 3,
    borderBottom: "1px solid #b42519",
    width: "68%",
    borderRadius: 5,
  },
  logosMedia: {
    width: 10,
  },
  imageLogoTrainer: {
    position: "relative",
  },
  imagenLogo: {
    position: "absolute",
    left: 0,
    top: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    right: 0,
  },
  containerWeeks: {
    display: "flex",
    flexDirection: "column",
    flexWrap: "wrap",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 5,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "gray",
  },
  footer: {
    position: "absolute",
    width: "100%",
    bottom: 0,
    height: 40,
  },
  footerItem: {
    backgroundColor: "#383838",
    borderBottom: "3px solid #b42519",
    width: "100%",
    height: 25,
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    justifyContent: "center",
    alignItems: "center",
  },
});

const RenderPDF = ({ workouts, datatrainer, clientInfo, orderWeeks }) => {
  const { t } = useLang();

  const chooseImageCover = () => {
    if (datatrainer?.showField?.pic && datatrainer?.showField?.logo) {
      return (
        <View style={{ display: 'flex', flexDirection: 'column' }}>
          <Image
            src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${datatrainer?.pic}`}
            style={{ width: 50, marginBottom: 2 }}
          />
          <Image
            src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${datatrainer?.logo}`}
            style={{ width: 50, marginTop: 2 }}
          />
        </View>
      )
    }


    if (datatrainer?.showField?.pic) {
      return (
        <Image
          src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${datatrainer?.pic}`}
          style={{ width: 50 }}
        />
      )
    }

    if (datatrainer?.showField?.logo) {
      return (
        <Image
          src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${datatrainer?.logo}`}
          style={{ width: 50 }}
        />
      )
    }

    if (datatrainer?.pic) {
      return (
        <Image
          src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${datatrainer?.pic}`}
          style={{ width: 50 }}
        />
      )
    }

    if (datatrainer?.logo) {
      return (
        <Image
          src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${datatrainer?.logo}`}
          style={{ width: 50 }}
        />
      )
    }

    return (
      <Image
        src={Logo}
        style={{ width: 50 }}
      />
    )
  }

  return (
    <Document>
      <Page size="A4" orientation="portrait" wrap={false}>
        <View style={styles.widthFull}>
          <Image src={TopImage} style={{ width: "100%" }} />
        </View>
        <View style={{ marginHorizontal: 30, marginTop: 30, marginBottom: 40 }}>
          <View style={styles.headerInformation}>
            <View style={styles.clientContainer}>
              <View style={styles.headerTitleInformation}>
                <Text>{t("client")}</Text>
              </View>
              <View style={styles.containerFields}>
                <View style={styles.clientBox}>
                  <View style={styles.clientLabel}>
                    <Text>{t("table_name")}</Text>
                  </View>
                  <View style={styles.clientInformation}>
                    <Text>
                      {`${clientInfo?.name} `} {clientInfo?.lastName}
                    </Text>
                  </View>
                </View>
                <View style={styles.clientBox}>
                  <View style={styles.clientLabel}>
                    <Text>{t("phonenumber")}</Text>
                  </View>
                  <View style={styles.clientInformation}>
                    <Text>{clientInfo?.phone}</Text>
                  </View>
                </View>
                <View style={styles.clientBox}>
                  <View style={styles.clientLabel}>
                    <Text>{t("profile_address")}</Text>
                  </View>
                  <View style={styles.clientInformation}>
                    <Text>{`${clientInfo?.address.state}, ${clientInfo.address.city}, ${clientInfo.address.street1
                      } ${clientInfo.address.street2 ?? ""}, ${clientInfo.address.zip}`}</Text>
                  </View>
                </View>
                <View style={styles.clientBox}>
                  <View style={styles.clientLabel}>
                    <Text>Email</Text>
                  </View>
                  <View style={styles.clientInformation}>
                    <Text>{clientInfo?.email}</Text>
                  </View>
                </View>
              </View>
            </View>
            <View style={{ width: "5%" }} />
            <View style={styles.trainerContainer}>
              <View style={styles.headerTitleInformation}>
                <Text>
                  {t("trainer")} {`${datatrainer?.name} `} {datatrainer?.lastName}
                  {datatrainer?.showField?.speciality && `, ${t(datatrainer.speciality)}`}
                </Text>
              </View>
              <View style={styles.trainerMainBox}>
                <View style={styles.trainerBoxContactInfo}>
                  <View style={styles.trainertBoxField}>
                    <View style={styles.trainertLabel}>
                      <Text>Email</Text>
                    </View>
                    <View style={styles.trainertInformation}>
                      <Text>{datatrainer.email}</Text>
                    </View>
                  </View>
                  {datatrainer?.showField?.phone && (
                    <View style={styles.trainertBoxField}>
                      <View style={styles.trainertLabel}>
                        <Text>{t("phonenumber")}</Text>
                      </View>
                      <View style={styles.trainertInformation}>
                        <Text>{datatrainer.phone}</Text>
                      </View>
                    </View>
                  )}
                  {datatrainer?.showField?.address && (
                    <View style={styles.trainertBoxField}>
                      <View style={styles.trainertLabel}>
                        <Text>{t("profile_address")}</Text>
                      </View>
                      <View style={styles.trainertInformation}>
                        <Text>{`${datatrainer?.address.state}, ${datatrainer.address.city}, ${datatrainer.address.street1
                          } ${datatrainer.address.street2 ?? ""}, ${datatrainer.address.zip}`}</Text>
                      </View>
                    </View>
                  )}
                  {datatrainer?.showField?.certifications && (
                    <View style={styles.trainertBoxField}>
                      <View style={styles.trainertLabel}>
                        <Text>{t("certifications")}</Text>
                      </View>
                      <View style={styles.trainertInformation}>
                        <Text>{datatrainer.certifications}</Text>
                      </View>
                    </View>
                  )}
                  {datatrainer?.showField?.job_description && (
                    <View style={styles.trainertBoxField}>
                      <View style={styles.trainertLabel}>
                        <Text>{t("job_description")}</Text>
                      </View>
                      <View style={styles.trainertInformation}>
                        <Text>{t(datatrainer.job_description)}</Text>
                      </View>
                    </View>
                  )}
                </View>
                <View style={styles.divider} />
                <View style={styles.trainerBox}>
                  <View style={styles.imageLogoTrainer}>
                    <View style={styles.imagenLogo}>
                      {chooseImageCover()}
                    </View>
                  </View>
                </View>
              </View>
            </View>
          </View>
          <View>
            {orderWeeks.map((key, index) => (
              <View style={styles.containerWeeks}>
                <WeeksDisplayPdf headertitle={index} weeks={workouts[key]} />
              </View>
            ))}
          </View>
        </View>
        <View style={styles.footer} fixed>
          <View
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginBottom: 4,
            }}>
            <Text
              style={{ color: "gray", fontSize: 10 }}
              render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
            />
          </View>
          <View style={styles.footerItem}>
            <Image src={Alternative} style={{ width: 7, marginRight: 3 }} />
            <Text style={{ color: "white", fontSize: 7 }}>Perfecttrainerapp.com</Text>
          </View>
        </View>
      </Page>
    </Document>
  );
};

const WorkOutNewPdf = ({ workouts, datatrainer, clientInfo, nameWorkout }) => {
  const { t } = useLang();

  const FormatDays = [...workouts];
  const array = FormatDays?.map(({ week }) => week);
  const newarray = [...new Set(array)];
  const dataFilter = newarray?.map((item) =>
    workouts?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
  );
  const orderWeeks = Object.keys(dataFilter);

  return (
    <PDFDownloadLink
      document={
        <RenderPDF
          workouts={dataFilter}
          orderWeeks={orderWeeks}
          datatrainer={datatrainer}
          clientInfo={clientInfo}
        />
      }
      fileName={`${clientInfo.name} - ${nameWorkout?.en}`}>
      {({ loading }) =>
        loading ? (
          <Button
            isLoading={loading}
            loadingText={t("generasting_pdf")}
            spinnerPlacement="start"
            variant="outline"
            size="sm">
            {t("generasting_pdf")}
          </Button>
        ) : (
          <Button
            size="sm"
            variant="solid"
            colorScheme="ptRed"
            id="download-pdf"
            leftIcon={<FileDownloadIcon fontSize="inherit" />}>
            {t("download")}
          </Button>
        )
      }
    </PDFDownloadLink>
  );
};
export default WorkOutNewPdf;
