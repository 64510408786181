import instance from "./_axiosConfig";

const api = instance;
const getClients = async () => {
  try {
    const response = await api.get("/client/");
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const getClientById = async (id) => {
  try {
    const response = await api.get(`/client/${id}`);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const getTrainerClients = async (idTrainer) => {
  try {
    const response = await api.get(`/client/trainer/${idTrainer}`);
    return response.data.clients;
  } catch (error) {
    return error.response;
  }
};
const updateClient = async (idClient, data) => {
  try {
    const response = await api.put(`/client/${idClient}`, data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
const createClient = async (data) => {
  try {
    const response = await api.post("/client/", data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const deleteClient = async (idClient) => {
  try {
    const token = localStorage.getItem("userAuth");
    const response = await api.delete(`/client/${idClient}`);
    return response.data;
  } catch (error) {
    throw Error(error.response.statusText);
    // return error.response;
  }
};

export { createClient, deleteClient, getClients, getClientById, getTrainerClients, updateClient };
