/* eslint-disable react/prop-types */
/* eslint-disable react/no-array-index-key */
import React, { useState } from "react";
import {
  VStack,
  Heading,
  Text,
  Badge,
  Button,
  IconButton,
  Textarea,
  Box,
  Flex,
} from "@chakra-ui/react";
import { EditIcon, CloseIcon, CheckCircleIcon } from "@chakra-ui/icons";
import { useForm } from "react-hook-form";
import propTypes from "prop-types";

import TextInput from "components/common/TextInput";
import { updateEvaluation, isNewDominant } from "./evaluationReducer";
import styles from "./Welcome.module.scss";

const updateData = (oldInfo, addInfo) => {
  const newInfo = {
    ...oldInfo,
    dominant_leg: isNewDominant(addInfo),
    notes: oldInfo.notes.map((note, id) => ({ ...note, content: [addInfo[`content-${id}`]] })),
    succesfulMovements: {
      leftLeg: +addInfo.leftLeg,
      rightLeg: +addInfo.rightLeg,
    },
    generalNote: {
      left: addInfo.generalNoteLeft || "",
      right: addInfo.generalNoteRight || "",
      both: addInfo.generalNoteBoth || "",
    },
  };
  return newInfo;
};

const isEvaluationWithLeg = (exercise) => {
  const exercisesWithoutLeg = ["Push Up", "Recline Pull", "Rotation withour Pivot"];
  return !exercisesWithoutLeg.includes(exercise);
};

const ReportCard = ({ evaluation, dispatch, lang, t, isEdit, saveClientEvaluation }) => {
  const [edit, setEdit] = useState(false);
  const { handleSubmit, control, register } = useForm();

  const onSubmit = (data) => {
    const dataToSend = updateData(evaluation, data);
    dispatch(updateEvaluation(dataToSend, evaluation.exercise));
    saveClientEvaluation();
    setEdit(false);
  };
  return (
    <Box
      as={edit ? "form" : "section"}
      onSubmit={handleSubmit(onSubmit)}
      className={styles.evaluation}
      style={{ backgroundColor: evaluation.notes.length <= 0 ? "#f3fff4" : "white" }}>
      {isEdit && (
        <IconButton
          aria-label="edit"
          color="ptRed.500"
          fontSize="20px"
          icon={edit ? <CloseIcon /> : <EditIcon />}
          onClick={() => setEdit(!edit)}
          position="absolute"
          right="10px"
          top="10px"
        />
      )}
      {evaluation.notes.length <= 0 && (
        <Flex flexDirection="column" justifyContent="center" textAlign="center" alignItems="center">
          <Flex mb={4} gridGap={4} alignItems="center">
            <CheckCircleIcon boxSize={4} color="green" />
            <Heading as="h3" size="md" color="green">
              {t("congratulation")}
            </Heading>
          </Flex>
        </Flex>
      )}
      <Heading isTruncated size="md" textAlign="center" mb={4}>
        {evaluation.exercise[lang]}
      </Heading>
      {isEvaluationWithLeg(evaluation.exercise) && (
        <>
          {evaluation.dominant_leg !== "both" ? (
            <Text textAlign="center">
              {t("dominant_leg")} <Badge colorScheme="yellow">{evaluation.dominant_leg}</Badge>
            </Text>
          ) : null}
          <Text>
            {t("succesful_movement_in_left")}{" "}
            {edit ? (
              <TextInput
                control={control}
                name="leftLeg"
                def={evaluation.succesfulMovements.leftLeg}
                required={false}
              />
            ) : (
              <span style={{ fontWeight: "bold" }}> {evaluation.succesfulMovements.leftLeg}</span>
            )}
          </Text>
          <Text>
            {t("succesful_movement_in_right")}{" "}
            {edit ? (
              <TextInput
                control={control}
                name="rightLeg"
                def={evaluation.succesfulMovements.rightLeg}
                required={false}
              />
            ) : (
              <span style={{ fontWeight: "bold" }}> {evaluation.succesfulMovements.rightLeg}</span>
            )}
          </Text>
        </>
      )}
      <Heading size="sm" my={4} color="gray">
        {t("observations")}
      </Heading>
      <ul style={{ marginLeft: "1rem" }}>
        {evaluation.notes.map((note) => (
          <li>
            <Text size="md">{note.defaultMsg[lang]}</Text>
          </li>
        ))}
      </ul>
      {evaluation.notes.length <= 0 && <Text>{t("congratulation_assessment")}</Text>}

      {Object.values(evaluation.generalNote).some((note) => note !== "") && (
        <>
          <Heading size="sm" color="gray" my={4}>
            {t("general_notes")}
          </Heading>
          <Box mb={4}>
            {!edit ? (
              Object.values(evaluation.generalNote).map((note) => (
                <ul style={{ marginLeft: "1rem" }}>
                  {note !== "" ? (
                    <li>
                      <Text>{note}</Text>
                    </li>
                  ) : null}
                </ul>
              ))
            ) : (
              <>
                {evaluation.generalNote.right !== "" && (
                  <Textarea
                    mb={2}
                    {...register("generalNoteRight")}
                    defaultValue={evaluation.generalNote.right}
                    required={false}
                  />
                )}
                {evaluation.generalNote.left !== "" && (
                  <Textarea
                    mb={2}
                    {...register("generalNoteLeft")}
                    defaultValue={evaluation.generalNote.left}
                    required={false}
                  />
                )}
                {evaluation.generalNote.both !== "" && (
                  <Textarea
                    mb={2}
                    {...register("generalNoteBoth")}
                    defaultValue={evaluation.generalNote.both}
                    required={false}
                  />
                )}
              </>
            )}
          </Box>
        </>
      )}
      {edit && (
        <Button type="submit" colorScheme="ptRed" my={4}>
          {" "}
          Save Edit
        </Button>
      )}
    </Box>
  );
};

export default ReportCard;
