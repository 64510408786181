import instance from "./_axiosConfig";

const api = instance;
const createPreprogrammed = async (data) => {
  try {
    const res = await api.post("/preprogrammed/", data);
    return res.data.preProgrammedWorkout;
  } catch (error) {
    return error.message;
  }
};

const updatePreprogrammed = async (data, id) => {
  try {
    const res = await api.put(`/preprogrammed/${id}`, data);
    return res.data.preProgrammedWorkout;
  } catch (error) {
    throw new Error(error.message);
  }
};

const getPreprogrammed = async ({ name, options, lang, user, page, itemsPerPage, combine }) => {
  try {
    const query = [
      `?lang=${lang}`,
      name ? `name=${name}` : "",
      options && options.length > 0 ? `options=${options}` : "",
      user ? `trainer=${user}` : "",
      page ? `page=${page}` : "",
      itemsPerPage ? `itemsPerPage=${itemsPerPage}` : "",
      combine ? `combine=${combine}` : "",
    ]
      .filter((a) => a !== "")
      .join("&");
    const res = await api.get(`/preprogrammed${query}`);
    return res.data;
  } catch (error) {
    return error.message;
  }
};

const getPreprogrammedCombine = async ({ name, options, lang, user, page, itemsPerPage, combine }) => {
  try {
    const query = [
      `?lang=${lang}`,
      name ? `name=${name}` : "",
      options && options.length > 0 ? `options=${options}` : "",
      user ? `trainer=${user}` : "",
      page ? `page=${page}` : "",
      itemsPerPage ? `itemsPerPage=${itemsPerPage}` : "",
      combine ? `combine=${combine}` : "",
    ]
      .filter((a) => a !== "")
      .join("&");
    const res = await api.get(`/preprogrammed_combined${query}`);
    return res.data;
  } catch (error) {
    return error.message;
  }
};

const deletePreprogrammed = async (id) => {
  try {
    const res = await api.delete(`/preprogrammed/${id}`);
    return res.data;
  } catch (error) {
    return error.message;
  }
};

export { getPreprogrammed, createPreprogrammed, deletePreprogrammed, updatePreprogrammed, getPreprogrammedCombine };
