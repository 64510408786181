import React from "react";
import { Input, Box, Text, Flex } from "@chakra-ui/react";
import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

const RoutineInfo = ({ setName, setPhase, name, phase }) => {
  const { t } = useTranslation();
  return (
    <form>
      <Flex gridGap={2} flexDirection="column">
        <Box>
          <Text as="label" htmlFor="name">
            {t("routine_name")}
          </Text>
          <Input
            name="name"
            variant="filled"
            onChange={({ target }) => setName(target.value)}
            placeholder={t("routine_name")}
            value={name}
          />
        </Box>
        <Box>
          <Text as="label" htmlFor="phase">
            {t("phase_title")}
          </Text>
          <Input
            name="phase"
            variant="filled"
            onChange={({ target }) => setPhase(target.value)}
            placeholder={t("phase_program")}
            value={phase}
            type="text"
          />
        </Box>
      </Flex>
    </form>
  );
};

RoutineInfo.propTypes = {
  setPhase: propTypes.func.isRequired,
  setName: propTypes.func.isRequired,
  phase: propTypes.string.isRequired,
  name: propTypes.string.isRequired,
};

export default RoutineInfo;
