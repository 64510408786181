/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  IconButton,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import { bool, func, number, shape } from "prop-types";
import { useSelector } from "react-redux";
import MaterialTable from "material-table";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import useLang from "hooks/useLang";
import useGetAll from "hooks/useGetAll";
import { getTrainerExercises } from "services/exercise";
import customFilterAndSearch from "helpers/customFilterAndSearch";
import localizationMaterialTable from "helpers/localizationMaterialTable";
import tableIcons from "theme/tableIcons";

import { Search2Icon } from "@chakra-ui/icons";
import ErrorBoundary from "components/common/ErrorBoundary";
import ExerciseCard from "components/exercises/exerciseCard";
import { useAddDayContext } from "./AddDay";

const AddExerciseAddDay = ({ isOpen, onClose, date, id }) => {
  const { id_user } = useSelector(({ user }) => user);
  const { isLoading, isError, isSuccess, data } = useGetAll("exercises", null, () =>
    getTrainerExercises(id_user),
  );
  const { addExercise, changeExercise } = useAddDayContext();
  const { t, lang } = useLang();
  const [displayMode, setDisplayMode] = useState("grid");
  const [search, setSearch] = useState("");
  const localization = localizationMaterialTable();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const [nPages, setNPages] = useState(0);
  useEffect(() => {
    if (data) setNPages(Math.ceil(data.length / recordsPerPage));
  }, [isSuccess]);

  const handleEvent = (item) => {
    if (id >= 0) changeExercise(date, item, id);
    else addExercise(date, item);
    onClose();
  };

  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) setCurrentPage(nPages);
  };
  return (
    <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent borderTop="15px solid #b42519" borderRadius={10}>
        <ModalHeader>{t("choose_exercise")}</ModalHeader>
        <ModalCloseButton />
        {isLoading && <p>Loading data</p>}
        {isError && <p>There is an error</p>}
        {isSuccess && (
          <>
            <ModalBody>
              <ErrorBoundary>
                <Flex align="center" justify="space-between" w="100%" mb="1rem" gridGap={4}>
                  <InputGroup w="50%">
                    <InputLeftElement pointerEvents="none">
                      <Search2Icon color="gray.600" />
                    </InputLeftElement>
                    <Input
                      variant="outline"
                      type="text"
                      placeholder="Search..."
                      value={search}
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </InputGroup>
                  <div>
                    <IconButton
                      isRound
                      variant="ghost"
                      colorScheme="blackAlpha"
                      aria-label="Done"
                      fontSize="20px"
                      icon={displayMode === "grid" ? <GridViewIcon /> : <ViewListIcon />}
                      onClick={() =>
                        setDisplayMode((prev) => {
                          localStorage.setItem("displayMode", prev === "grid" ? "list" : "grid");
                          return prev === "grid" ? "list" : "grid";
                        })
                      }
                    />
                  </div>
                </Flex>
                <SimpleGrid
                  columns={displayMode === "grid" ? [1, 2, 3, 4] : 1}
                  spacing={4}
                  w="100%">
                  {data
                    .filter((a) => a.name[lang]?.toLowerCase().includes(search.toLowerCase()))
                    .slice(indexOfFirstRecord, indexOfLastRecord)
                    .map((exercise) => (
                      <div aria-hidden="true">
                        <ExerciseCard
                          isSelect
                          selectExercise={handleEvent}
                          exercise={exercise}
                          key={exercise._id}
                          displayMode={displayMode}
                        />
                      </div>
                    ))}
                </SimpleGrid>
                <nav>
                  <ul className="pagination">
                    <li>
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="blackAlpha"
                        aria-label="Done"
                        fontSize="20px"
                        disabled={currentPage === 1}
                        icon={<FirstPageIcon />}
                        onClick={goToFirstPage}
                      />
                    </li>
                    <li>
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="blackAlpha"
                        aria-label="Done"
                        fontSize="20px"
                        disabled={currentPage === 1}
                        icon={<ArrowBackIosIcon />}
                        onClick={goToPrevPage}
                      />
                    </li>
                    <li>
                      {currentPage}/{nPages}
                    </li>
                    <li>
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="blackAlpha"
                        aria-label="Done"
                        fontSize="20px"
                        disabled={currentPage === nPages}
                        icon={<ArrowForwardIosIcon />}
                        onClick={goToNextPage}
                      />
                    </li>
                    <li>
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="blackAlpha"
                        aria-label="Done"
                        fontSize="20px"
                        disabled={currentPage === nPages}
                        icon={<LastPageIcon />}
                        onClick={goToLastPage}
                      />
                    </li>
                  </ul>
                </nav>
              </ErrorBoundary>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="ptRed" mr={3} onClick={onClose}>
                {t("close")}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

AddExerciseAddDay.propTypes = {
  isOpen: bool.isRequired,
  onClose: func.isRequired,
  date: shape({}).isRequired,
  id: number.isRequired,
};

export default AddExerciseAddDay;
