/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/prop-types */
import React from "react";
import useLang from "hooks/useLang";
import { useDisclosure } from "@chakra-ui/react";
import TagIcon from "@mui/icons-material/Tag";
import styles from "./itemSection.module.scss";
import InformationAbout from "./InformationAbout";

const ItemSection = ({ data, onCloseAll }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t } = useLang();
  return (
    <>
      <li className={styles.box} onClick={onOpen}>
        <div className={styles.icon}>
          <TagIcon />
        </div>
        <div className={styles.data_container}>
          <span style={{ textTransform: 'lowercase' }}>{t(data.section)}</span>
          <h4>{t(data.about)}</h4>
        </div>
      </li>
      {isOpen && (
        <InformationAbout onClose={onClose} data={data} isOpen={isOpen} onCloseAll={onCloseAll} />
      )}
    </>
  );
};

export default ItemSection;
