/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { Tooltip, useDisclosure } from "@chakra-ui/react";
import useLang from "hooks/useLang";
import FolderSpecialIcon from "@mui/icons-material/FolderSpecial";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import HelpAboutAppModal from "components/help";
import logo from "../../assets/4.png";
import logoReduce from "../../assets/2.png";
import { menu, optionsMenu } from "./menu";
import help from "../../assets/icons/ico_help.svg";

const Sidebar = () => {
  const { t } = useLang();
  const history = useHistory();
  const { role } = useSelector(({ user }) => user);
  const [isActive, setIsActive] = useState(useLocation().pathname);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const location = useLocation();
  const goTo = (to) => {
    const board = document.getElementById("board");
    if (board) {
      if (isActive !== to) {
        const leavePage = window.confirm(t("back_confirmation"));
        if (leavePage) {
          history.push(to);
          setIsActive(to);
        }
      }
    } else {
      history.push(to);
      setIsActive(to);
    }
  };
  useEffect(() => {
    setIsActive(location.pathname);
  }, [location.pathname]);
  const collapseAndSpand = () => {
    const sidebar = document.getElementsByClassName("sidebar")[0];
    if (!sidebar.className.includes("collapse")) sidebar.setAttribute("class", "sidebar collapse");
    else if (sidebar) sidebar.setAttribute("class", "sidebar");
  };
  return (
    <aside className="sidebar">
      <div className="logo">
        <div onClick={() => goTo("/user/dashboard")}>
          <img className="normal" src={logo} alt="perfecttrainer logo" />
          <img className="reduce" src={logoReduce} alt="perfecttrainer logo" />
        </div>
      </div>
      <div>
        <ul className="menu">
          {menu.map((item, index) => (
            <li key={index}>
              <Tooltip hasArrow label={t(item.name)} placement="right">
                <div
                  onClick={() => goTo(item.url)}
                  className={isActive.includes(item.url) ? "active" : ""}>
                  <span>
                    <img src={item.icon} alt="icon" />
                  </span>
                  <span>{t(item.name)}</span>
                  <span>{/* 1 */}</span>
                </div>
              </Tooltip>
            </li>
          ))}
          {role === "admin" && (
            <li>
              <Tooltip hasArrow label={t("help")} placement="right">
                <div
                  onClick={() => goTo("management")}
                  className={isActive.includes("management") && "active"}>
                  <span>
                    <FolderSpecialIcon />
                  </span>
                  <span>{t("management")}</span>
                  <span>{/* 1 */}</span>
                </div>
              </Tooltip>
            </li>
          )}
        </ul>
        <hr />
        <ul className="menu">
          <li>
            <Tooltip hasArrow label={t("help")} placement="right">
              <div onClick={onOpen}>
                <span>
                  <img src={help} alt="icon" />
                </span>
                <span>{t("help")}</span>
                <span>{/* 1 */}</span>
              </div>
            </Tooltip>
          </li>
          {optionsMenu.map((item, index) => (
            <li key={index}>
              <Tooltip hasArrow label={t(item.name)} placement="right">
                <div to={item.url} className={isActive.includes(item.url) ? "active" : ""}>
                  {" "}
                  <span>
                    <img src={item.icon} alt="icon" />
                  </span>
                  <span>{t(item.name)}</span>
                  <span>{/* 1 */}</span>
                </div>
              </Tooltip>
            </li>
          ))}
        </ul>
      </div>
      <div className="showHideSidebar" onClick={collapseAndSpand}>
        <span>
          <ChevronLeftIcon />
        </span>
      </div>
      {isOpen && <HelpAboutAppModal isOpen={isOpen} onClose={onClose} />}
    </aside>
  );
};

export default Sidebar;
