/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import generateId from "helpers/generateId";
import useLang from "hooks/useLang";
import { useEffect, useState } from "react";
import isSameDate from "utils/isSameDate";

const useExerciseEditValueContext = (initialValue, isEdit) => {
  const [values, setValues] = useState(initialValue);
  const { lang } = useLang();

  useEffect(() => {
    if (isEdit) setValues(initialValue);
  }, [isEdit]);

  const addExercise = (date, item) => {
    const exerciseToAdd = {
      _id: generateId(),
      colors: { hard: "white", soft: "white" },
      notes_exercise: { en: "", es: "" },
      id_exercise: { ...item },
      sets: [
        {
          set_number: 1,
          load: "",
          reps: "",
          typeWeight: "kg",
          typeReps: "reps",
        },
      ],
    };

    setValues((prev) =>
      prev.map((value) => {
        const NewExercise = {
          ...value,
          exercises: value.exercises.concat(exerciseToAdd),
        };
        return NewExercise;
      }),
    );
  };

  const addMultipleExercise = (date, items) => {
    const flattenedArray = [].concat(...items.flat());
    const exercisesExported = flattenedArray.map((item) => item.exercises).flat();

    setValues((prev) =>
      prev.map((value) =>
        isSameDate(value, date)
          ? { ...value, exercises: value.exercises.concat(exercisesExported) }
          : value,
      ),
    );
  };

  const changeExercise = (date, item, id) => {
    const dayToChange = values.find((value) => isSameDate(value, date));
    const exerciseToChange = dayToChange.exercises.find((_, idx) => idx === id);
    const exerciseUpdated = { ...exerciseToChange, id_exercise: { ...item } };
    const editedExercise = dayToChange.exercises.map((obj, idx) =>
      idx === id ? exerciseUpdated : obj,
    );
    setValues((prev) =>
      prev.map((day) => (isSameDate(day, date) ? { ...day, exercises: editedExercise } : day)),
    );
  };

  const addRow = (date, id) => {
    /* Finding the day that is being changed. */
    const dayToChange = values.find((value) => isSameDate(value, date));
    /* Finding the exercise that is being changed. */
    const exerciseToChange = dayToChange.exercises.find((_, idx) => idx === id);

    const setToCopy = exerciseToChange.sets.at(-1);
    /* Copying the last set in the array and adding 1 to the set number. */
    const setsModified = exerciseToChange.sets.concat({
      ...setToCopy,
      set_number: setToCopy.set_number + 1,
    });

    /* Copying the exerciseToChange object and adding the setsModified array to it. */
    const exerciseModified = { ...exerciseToChange, sets: setsModified };
    const newExercises = dayToChange.exercises.map((exercise, idx) =>
      idx === id ? exerciseModified : exercise,
    );

    const dayModified = { ...dayToChange, exercises: newExercises };
    setValues((prev) => prev.map((day) => (isSameDate(day, date) ? dayModified : day)));
  };

  const deleteRow = (date, id, set_number) => {
    const dayToChange = values.find((value) => isSameDate(value, date));
    const exerciseToChange = dayToChange.exercises.find((_, idx) => idx === id);

    const setsModified = exerciseToChange.sets
      .filter((set) => set.set_number !== set_number)
      .map((set, idx) => ({ ...set, set_number: idx + 1 }));

    const newExercises = dayToChange.exercises.map((exercise, idx) =>
      idx === id ? { ...exercise, sets: setsModified } : exercise,
    );
    const dayModified = { ...dayToChange, exercises: newExercises };

    setValues((prev) => prev.map((day) => (isSameDate(day, date) ? dayModified : day)));
  };

  const changeValue = (targetValue, date, id) => {
    const dayToChange = values.find((value) => isSameDate(value, date));
    const exerciseToChange = dayToChange.exercises.find((_, idx) => idx === id);
    const newSets = exerciseToChange.sets.map((set) =>
      set.set_number === targetValue.set_number ? targetValue : set,
    );
    const newExercises = dayToChange.exercises.map((exercise, idx) =>
      idx === id ? { ...exercise, sets: newSets } : exercise,
    );
    setValues((prev) =>
      prev.map((value) =>
        isSameDate(value, date) ? { ...value, exercises: newExercises } : value,
      ),
    );
  };
  const changeCircuit = (targetValue, date, id) => {
    const dayToChange = values.find((value) => isSameDate(value, date));
    const exerciseToChange = dayToChange.exercises.find((_, idx) => idx === id);
    const newExercises = dayToChange.exercises.map((exercise, idx) =>
      idx === id ? { ...exercise, colors: targetValue } : exercise,
    );
    setValues((prev) =>
      prev.map((value) =>
        isSameDate(value, date) ? { ...value, exercises: newExercises } : value,
      ),
    );
  };

  const changeExerciseNotes = (targetValue, date, id) => {
    const dayToChange = values.find((value) => isSameDate(value, date));
    const exerciseToChange = dayToChange.exercises.find((_, idx) => idx === id);
    const newExercises = dayToChange.exercises.map((exercise, idx) =>
      idx === id
        ? { ...exercise, notes_exercise: { ...exercise.notes_exercise, [lang]: targetValue } }
        : exercise,
    );
    setValues((prev) =>
      prev.map((value) =>
        isSameDate(value, date) ? { ...value, exercises: newExercises } : value,
      ),
    );
  };
  return [
    values,
    {
      changeValue,
      addMultipleExercise,
      deleteRow,
      addRow,
      addExercise,
      changeExercise,
      changeCircuit,
      changeExerciseNotes
    },
  ];
};

export default useExerciseEditValueContext;
