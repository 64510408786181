/* eslint-disable radix */
/* eslint-disable jsx-a11y/label-has-associated-control */
import {
  Box,
  Button,
  Checkbox,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import useGetAll from "hooks/useGetAll";
import Select from "react-select";
import { useLocation } from "react-router";
import AutoAwesomeIcon from "@mui/icons-material/AutoAwesome";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getTrainerClients } from "services/clients";
import { generateNutrition, getStylesDietsByTrainer } from "services/nutrition";
import useLang from "hooks/useLang";
import { generatePromptNutrition } from "./propmt";
import MacroNutrientsCalculator from "./MacroNutrientsCalculator";
import AmountFood from "./AmountFood";
import FoodList from "./FoodList";
import DietStyles from "./DietStyle";
import CaloriesTotal from "./CaloriesTotal";
import DrawerGenerateResponse from "./DrawerGenerateResponse";
import MyDiets from "./MyDiets";
import Workbooks from "./Workbooks";

const Nutrition = () => {
  const { id_user, subscription_plan } = useSelector(({ user }) => user);
  const location = useLocation();
  const { t, lang } = useLang();
  const [response, setResponse] = useState("");
  const [updateListSave, setupdateListSave] = useState(false)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { data: dataNameClient } = useGetAll("clients", id_user, getTrainerClients);
  const [client, setClient] = useState("");
  const [loader, setloader] = useState(false);
  const [clientName, setClientName] = useState("");
  const [diet, setDiet] = useState("");
  const [customDiets, setCustomDiets] = useState([]);
  const [foodAvoid, setFoodAvoid] = useState([]);
  const [foodAddAvoid, setFoodAddAvoid] = useState([]);
  const [foodInclude, setFoodInclude] = useState([]);
  const [foodAddInclude, setFoodAddInclude] = useState([]);
  const [newFood, setNewFood] = useState("");
  const [newFoodAvoid, setNewFoodAvoid] = useState("");
  const [newDiets, setNewDiets] = useState("");
  const [showMessage, setShowMessage] = useState(false)
  const [selectTrainer, setselectTrainer] = useState(false);
  const myParam = location?.state?.tab;


  const [snack, setSnack] = useState(0);
  const [meal, setMeal] = useState(0);
  const [shake, setShake] = useState(0);

  const [calories, setCalories] = useState(0);
  const [protein, setProtein] = useState(30);
  const [fat, setFat] = useState(30);
  const [carbs, setCarbs] = useState(40);

  const options = dataNameClient?.map(({ name, lastName, _id }) => {
    const returnOptions = {
      value: _id,
      label: `${name} ${lastName || ""}`,
    };
    return returnOptions;
  });

  const chooseClient = (e) => {
    setClient(e.value);
    setClientName(e.label);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (client === "" && selectTrainer === false) {
      setShowMessage(true)
    } else {
      const message = generatePromptNutrition({
        lang,
        protein,
        fat,
        carbs,
        meal,
        shake,
        foodAvoid,
        foodInclude,
        calories,
        diet,
        snack,
      });

      try {
        setloader(true);
        onOpen();
        const res = await generateNutrition({ message });
        setloader(false);
        const formattedText = res.response
          .replace(/\n/g, "<br />")
          .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
        setResponse(formattedText);
        setShowMessage(false)
      } catch (error) {
        console.error("Error fetching data from ChatGPT:", error);
        setResponse("Sorry, have a error.");
      }
    }
  };

  const handleTryAdain = async (e) => {
    e.preventDefault();

    const message =
      lang === "en"
        ? `give me another diet, remove markdown formatting, ${generatePromptNutrition({
          lang,
          protein,
          fat,
          carbs,
          meal,
          shake,
          foodAvoid,
          foodInclude,
          calories,
          diet,
          snack,
        })}`
        : `dame otra dieta, , remueve el formato markdown, ${generatePromptNutrition({
          lang,
          protein,
          fat,
          carbs,
          meal,
          shake,
          foodAvoid,
          foodInclude,
          calories,
          diet,
          snack,
        })}`;

    try {
      setloader(true);
      const res = await generateNutrition({ message });
      setloader(false);
      const formattedText = res.response
        .replace(/\n/g, "<br />")
        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
      setResponse(formattedText);
    } catch (error) {
      console.error("Error fetching data from ChatGPT:", error);
      setResponse("Sorry, have a error.");
    }
  };

  const getCustomDietsByTrainer = async () => {
    try {
      const res = await getStylesDietsByTrainer(id_user)
      setCustomDiets(res.stylesdiets)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    getCustomDietsByTrainer()
  }, [])

  return (
    <>
      <h3 style={{ fontSize: "34px", lineHeight: "2rem" }}>
        {t("nutrition_section.header")}{" "}
        <span style={{ color: "#b42519", fontWeight: "bold" }}>Perfect Trainer</span> {t("using")}{" "}
        <span style={{ color: "#b42519", fontWeight: "bold" }}>IA</span>
      </h3>
      <Tabs defaultIndex={myParam === true ? 2 : 0}>
        <TabList mb="1em">
          <Tab>Express</Tab>
          <Tab>{t("my_diets")}</Tab>
          {subscription_plan === "year" && (
            <Tab>{t('workbooks')}</Tab>
          )}
        </TabList>
        <TabPanels>
          <TabPanel display="flex" justifyContent="space-between" flexWrap="wrap" w="full" gridGap={4}>
            <Box
              display="flex"
              gridGap={4}
              flexDirection="column"
              w={{ base: "full", md: "50%", lg: "32%" }}
              flexWrap="wrap">
              <Box
                width="full"
                display="flex"
                justifyContent="space-between"
                flexDirection="column">
                <Box w="full">
                  <span>{t("nutrition_section.assign_nutrition")}</span>
                  <Select
                    placeholder={t("select_a_client")}
                    options={options}
                    onChange={chooseClient}
                    isDisabled={selectTrainer}
                  />
                  <Checkbox
                    colorScheme="red"
                    mt={3}
                    my={2}
                    isChecked={selectTrainer}
                    onChange={() => {
                      setselectTrainer((prev) => !prev);
                      setClient("");
                      setClientName("");
                    }}>
                    <Text fontSize="sm" color="gray">
                      {t("just_for_me")}
                    </Text>
                  </Checkbox>
                </Box>
                <Box w="full" my={3}>
                  <CaloriesTotal setCalories={setCalories} />
                </Box>
              </Box>
              <Box>
                <div>
                  <DietStyles
                    setDiet={setDiet}
                    diet={diet}
                    setNewDiets={setNewDiets}
                    newDiets={newDiets}
                    setProtein={setProtein}
                    setCarbs={setCarbs}
                    setFat={setFat}
                    setCustomDiets={setCustomDiets}
                    customDiets={customDiets}
                  />
                </div>
              </Box>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              gridGap={4}
              w={{ base: "full", md: "50%", lg: "32%" }}
              flexWrap="wrap">
              <div>
                <MacroNutrientsCalculator
                  calories={calories}
                  protein={protein}
                  setProtein={setProtein}
                  carbs={carbs}
                  setCarbs={setCarbs}
                  fat={fat}
                  setFat={setFat}
                />
              </div>
              <div>
                <AmountFood
                  setShake={setShake}
                  shake={shake}
                  setMeal={setMeal}
                  meal={meal}
                  setSnack={setSnack}
                  snack={snack}
                />
              </div>
            </Box>
            <Box
              display="flex"
              flexDirection="column"
              flexWrap="wrap"
              w={{ base: "full", md: "full", lg: "32%" }}>
              <div>
                <FoodList
                  setFoodAddInclude={setFoodAddInclude}
                  setFoodAddAvoid={setFoodAddAvoid}
                  setFoodInclude={setFoodInclude}
                  setFoodAvoid={setFoodAvoid}
                  foodInclude={foodInclude}
                  foodAvoid={foodAvoid}
                  foodAddAvoid={foodAddAvoid}
                  foodAddInclude={foodAddInclude}
                  newFood={newFood}
                  setNewFood={setNewFood}
                  setNewFoodAvoid={setNewFoodAvoid}
                  newFoodAvoid={newFoodAvoid}
                />
              </div>
              <Box bg="white" py="2em" px="1em">
                {showMessage && (
                  <Box bg="#fed7d7" color="black" textAlign="center" p={3} my={1} borderRadius="5px">
                    <p>{t('message_validation_choose_client_nutrition')}</p>
                  </Box>
                )}
                <Button
                  onClick={handleSubmit}
                  leftIcon={<AutoAwesomeIcon fontSize="inherit" />}
                  variant="solid"
                  w="full"
                  py={6}
                  bg="#b42519"
                  border="1px solid #b42519"
                  _hover={{
                    bg: "white",
                    border: "1px solid #b42519",
                    color: "#b42519",
                  }}
                  color="white"
                  type="submit">
                  {t("generate")}
                </Button>
              </Box>
            </Box>
            {isOpen && (
              <DrawerGenerateResponse
                isOpen={isOpen}
                onClose={onClose}
                handleTryAdain={handleTryAdain}
                loader={loader}
                response={response}
                client={client}
                clientName={clientName}
                protein={protein}
                fat={fat}
                carbs={carbs}
                selectTrainer={selectTrainer}
                calories={calories}
                stylediet={diet}
                setupdateListSave={setupdateListSave}
              />
            )}
          </TabPanel>
          <TabPanel>
            <MyDiets updateListSave={updateListSave} />
          </TabPanel>
          <TabPanel>
            <Workbooks />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </>
  );
};

export default Nutrition;
