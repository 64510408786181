/* eslint-disable import/prefer-default-export */
const inputStyle = {
  backgroundColor: "#ececec",
  border: "1px solid #d6d6d6",
  borderRadius: "5px",
  color: "#444",
  fontWeight: 400,
  focusBorderColor: "#b42519",
  letterSpacing: "1px",
  marginBottom: "1rem",
  padding: "0.5rem",
  width: "100%",
  transition: "border 2s cubic-bezier(0.075, 0.82, 0.165, 1)",
  _focus: {
    border: "1px solid #b42519",
    borderRadius: "5px",
    outline: "none",
  },
};
const selectStyle = {
  backgroundColor: "#ececec",
  border: "1px solid #d6d6d6",
  borderRadius: "5px",
  color: "#444",
  fontWeight: 400,
  focusBorderColor: "#b42519",
  letterSpacing: "1px",
  marginBottom: "1rem",
  padding: "0rem",
  width: "100%",
  transition: "border 2s cubic-bezier(0.075, 0.82, 0.165, 1)",
  _focus: {
    border: "1px solid #b42519",
    borderRadius: "5px",
    outline: "none",
  },
};

const passwordStyle = {
  backgroundColor: "#ececec",
  border: "1px solid #d6d6d6 ",
  borderRadius: "5px",
  color: "#444444",
  fontWeight: 400,
  focusBorderColor: "#b42519",
  letterSpacing: "1px",
  marginTop: "0.5rem",
  padding: "0.5rem",
  paddingRight: "3rem",
  transition: "border 2s cubic-bezier(0.075, 0.82, 0.165, 1)",
  _focus: {
    border: "1px solid #b42519",
    borderRadius: "5px",
    outline: "none",
  },
};

const infoStyle = {
  padding: "2rem 0",
  textAlign: "center",
};

const tagStyle = {
  color: "#3a3a3a",
  fontWeight: 700,
  marginTop: "1rem",
};

const bigContainerStyle = {
  display: "flex",
  flexDirection: "column",
  gap: ".5rem",
};

const superSetColors = ["red", "orange", "yellow", "green", "blue", "purple", "white"];
const superSetColorsHex = {
  red: "#FED7D7",
  orange: "#FEEBC8",
  yellow: "#FEFCBF",
  green: "#C6F6D5",
  blue: "#bee3f8",
  purple: "#E9D8FD",
  white: "#ffffff",
};

export {
  inputStyle,
  infoStyle,
  tagStyle,
  passwordStyle,
  bigContainerStyle,
  superSetColors,
  superSetColorsHex,
  selectStyle,
};
