/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable no-unused-vars */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
/* eslint-disable prefer-const */
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex, columns) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

export default reorder;

export const reorderQuoteMap = ({
  quoteMap, source, destination,
}) => {
  const current = [...quoteMap[source.droppableId]];
  const next = [...quoteMap[destination.droppableId]];
  const target = current[source.index];

  // moving to same list
  if (source.droppableId === destination.droppableId) {
    const reordered = reorder(current, source.index, destination.index);
    const result = {
      ...quoteMap,
      [source.droppableId]: reordered,
    };
    let toArrayResult = [];
    for (let i in result) {
      toArrayResult.push(result[i]);
    }

    const orderBoard = toArrayResult.map((arr, i) => {
      const arrMap = arr.map((obj, j) => {
        const subArrMap = { ...obj, day: (1 + j), week: (1 + i) };
        return subArrMap;
      });
      return arrMap;
    });
    const arrayOfObjects = orderBoard.reduce((acc, val) => acc.concat(val), []);

    return {
      quoteMap: { ...orderBoard },
    };
  }

  // moving to different list

  // remove from original
  current.splice(source.index, 1);
  // insert into next
  next.splice(destination.index, 0, target);

  const result = {
    ...quoteMap,
    [source.droppableId]: current,
    [destination.droppableId]: next,
  };

  let toArrayResult = [];
  for (let i in result) {
    toArrayResult.push(result[i]);
  }

  const orderBoard = toArrayResult.map((arr, i) => {
    const arrMap = arr.map((obj, j) => {
      const subArrMap = { ...obj, day: (1 + j), week: (1 + i) };
      return subArrMap;
    });
    return arrMap;
  });
  const cleanWeeks = orderBoard.filter((week) => week.length > 0);

  return {
    quoteMap: { ...cleanWeeks },
  };
};

export function moveBetween({
  list1, list2, source, destination,
}) {
  const newFirst = Array.from(list1.values);
  const newSecond = Array.from(list2.values);

  const moveFrom = source.droppableId === list1.id ? newFirst : newSecond;
  const moveTo = moveFrom === newFirst ? newSecond : newFirst;

  const [moved] = moveFrom.splice(source.index, 1);
  moveTo.splice(destination.index, 0, moved);

  return {
    list1: {
      ...list1,
      values: newFirst,
    },
    list2: {
      ...list2,
      values: newSecond,
    },
  };
}
