/* eslint-disable react/no-children-prop */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import useLang from "hooks/useLang";
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  InputRightElement,
  Select,
  Textarea,
  Tooltip,
  useDisclosure,
} from "@chakra-ui/react";
import DatePicker from "react-date-picker";
import { CloseIcon, PhoneIcon } from "@chakra-ui/icons";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import CategoryOverlay from "components/create/Features/CategoryOverlay";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { useEditWeekContext } from "./EditWeek";
import EditExerciseEditWeek from "./EditExerciseEditWeek";

const EditWeekContent = ({ isEdit, inicialValue, date }) => {
  const { lang, t } = useLang();
  const {
    changeDate,
    changeSession,
    changeNote,
    addRow,
    deleteExercise,
    changeValue,
    deleteRow,
    addExercise,
  } = useEditWeekContext();

  const { isOpen, onOpen, onClose } = useDisclosure();
  const [filters, setFilters] = useState({
    category: "",
    muscle: "",
    action: "",
    position: 0,
  });
  const [positionExercise, setpositionExercise] = useState("");
  const handleChangeSession = ({ target }) => changeSession(inicialValue, target.value);

  return (
    <Box>
      <Box>
        <InputGroup bg="#f6f6f6" marginBottom={5}>
          <InputGroup mr={1}>
            <InputLeftAddon bg="#f6f6f6" children={t("list_day")} />
            <Input
              type="text"
              bg="#f6f6f6"
              id={inicialValue.day}
              value={inicialValue.day}
              disabled
            />
          </InputGroup>
          <InputLeftAddon children={t("date")} />
          <DatePicker
            id={inicialValue._id}
            onChange={(e) => changeDate(inicialValue, e)}
            value={inicialValue?.date || ""}
            format={lang === "es" ? "dd/MM/yyy" : "MM/dd/yyy"}
            style={{ width: "100%" }}
          />
        </InputGroup>
        <InputGroup bg="#f6f6f6" marginBottom={5}>
          <InputLeftAddon children={t("notes")} />
          <Textarea
            type="text"
            bg="#f6f6f6"
            id={inicialValue._id}
            onChange={({ target }) => changeNote(target.value, date)}
            value={inicialValue.notes[lang]}
            _focus={false}
            border="none"
          />
        </InputGroup>
        {isOpen && (
          <EditExerciseEditWeek
            isOpen={isOpen}
            onClose={onClose}
            date={date}
            key={inicialValue.day}
            filters={filters}
            id={filters.position}
          />
        )}
        {inicialValue.exercises?.map((exercise, idx) => (
          <article key={exercise._id} className="exercise_content">
            <Flex gridGap={4} justifyContent="space-between" alignItems="center">
              <h4 className="exercise_content__title">
                {exercise?.id_exercise?.name[lang] || (
                    <>
                      {exercise.category?.name}
                      {exercise.muscle?.name && <Badge>{exercise.muscle.name}</Badge>}
                      {exercise.action?.name && (
                        <Badge colorScheme="purple">{exercise.action.name}</Badge>
                      )}
                    </>
                  ) ||
                  ""}
              </h4>
              {exercise.category?.name ? (
                <Button
                  colorScheme="gray"
                  mb="10px"
                  onClick={() => {
                    setFilters({
                      category: exercise?.category?.name || "",
                      action: exercise?.action?.name || "",
                      muscle: exercise?.muscle?.name || "",
                      position: idx,
                    });
                    setpositionExercise(idx);
                    onOpen();
                  }}>
                  {`Select ${exercise?.category?.name}`}
                </Button>
              ) : (
                <Tooltip hasArrow placement="left" label={t("change_exercise")}>
                  <Button
                    colorScheme="gray"
                    onClick={() => {
                      setFilters({
                        category: exercise?.category?.name || "",
                        action: exercise?.action?.name || "",
                        muscle: exercise?.muscle?.name || "",
                        position: idx,
                      });
                      setpositionExercise(idx);
                      onOpen();
                    }}>
                    {t("select_exercise")}
                  </Button>
                </Tooltip>
              )}
            </Flex>
            <Flex gridGap={2} justifyContent="end" fontSize="12px" color="gray">
              <p>{t("number_of_sets")}:</p>
              <b>{exercise.sets.length}</b>
            </Flex>
            <div>
              {exercise.sets?.map((s) => (
                <Box>
                  <Flex
                    gridGap={2}
                    bg="#f9f9f9"
                    borderRadius="10px"
                    padding=".5rem"
                    margin=".5rem 0px"
                    alignItems="center ">
                    <div>
                      <InputGroup bg="white">
                        <InputLeftElement pointerEvents="none" h="2rem" ml="10px">
                          <p className="exercise_content__set-title">{t("loads")}:</p>
                        </InputLeftElement>
                        <Input
                          size="sm"
                          type="tel"
                          value={s.load}
                          onChange={({ target }) =>
                            changeValue({ ...s, [target.id]: target.value }, date, idx)
                          }
                          id="load"
                          textAlign="center"
                        />
                        <Select
                          size="sm"
                          variant="filled"
                          value={s.typeWeight}
                          id="typeWeight"
                          onChange={({ target }) =>
                            changeValue({ ...s, [target.id]: target.value }, date, idx)
                          }>
                          <option value="kg">kg</option>
                          <option value="lb">lb</option>
                          <option value="others">{t("others")}</option>
                        </Select>
                      </InputGroup>
                    </div>
                    <div>
                      <InputGroup bg="white">
                        <InputLeftElement pointerEvents="none" h="2rem" ml="5px">
                          <p className="exercise_content__set-title">{t("Reps")}:</p>
                        </InputLeftElement>
                        <Input
                          size="sm"
                          type="tel"
                          id="reps"
                          value={s.reps}
                          onChange={({ target }) =>
                            changeValue({ ...s, [target.id]: target.value }, date, idx)
                          }
                          textAlign="center"
                        />
                        <Select
                          size="sm"
                          variant="filled"
                          value={s.typeReps}
                          id="typeReps"
                          onChange={({ target }) =>
                            changeValue({ ...s, [target.id]: target.value }, date, idx)
                          }>
                          <option value="reps">reps</option>
                          <option value="sec">sec</option>
                        </Select>
                      </InputGroup>
                    </div>

                    <IconButton
                      isRound
                      icon={<ClearIcon fontSize="inherit" />}
                      variant="ghost"
                      colorScheme="red"
                      disabled={exercise.sets.length <= 1}
                      onClick={() => deleteRow(date, idx, s.set_number)}
                    />
                  </Flex>
                </Box>
              ))}
              <Flex justifyContent="end">
                <Button
                  leftIcon={<AddIcon fontSize="inherit" />}
                  colorScheme="gray"
                  onClick={() => addRow(date, idx)}>
                  {t("add_sets")}
                </Button>
              </Flex>
            </div>
            <Button
              leftIcon={<DeleteIcon fontSize="inherit" />}
              variant="ghost"
              colorScheme="red"
              onClick={() => deleteExercise(date, idx)}>
              {t("delete_category")}
            </Button>
          </article>
        ))}
        <Button
          color="gray"
          border="1px solid #E3E3E3"
          borderRadius="8px"
          marginBottom={5}
          bg="transparent"
          _hover={{ color: "#b42519", border: "1px solid #b42519" }}
          leftIcon={<AddIcon />}
          onClick={() => {
            setFilters({
              category: "",
              action: "",
              muscle: "",
              position: -1,
            });
            setpositionExercise(-1);
            onOpen();
          }}>
          {t("add_exercise")}
        </Button>
      </Box>
    </Box>
  );
};

export default EditWeekContent;
