/* eslint-disable react/no-children-prop */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import useLang from "hooks/useLang";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Flex,
  IconButton,
  InputGroup,
  InputLeftElement,
  Input,
  Select,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Badge,
  Tooltip,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  Textarea,
  InputLeftAddon,
} from "@chakra-ui/react";

import DeleteIcon from "@material-ui/icons/Delete";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { superSetColors, superSetColorsHex } from "theme/commonStyles";
import BrushIcon from "assets/icons/brush.png";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import ClearIcon from "@mui/icons-material/Clear";

import EditExerciseAddDay from "../ExerciseEdit/EditExerciseAddDay";
import ExerciseListModal from "./ExerciseListModal";

const EditExercise = ({ exercise, idx, date, saveExercise, onCloseMain, deleteExercise }) => {
  const { t, lang } = useLang();
  const [openEdit, setOpenEdit] = useState(false);
  const [exerciseEdit, setExerciseEdit] = useState(null);
  const [openDialog, setopenDialog] = useState(false);
  const [showNotesExercise, setShowNotesExercise] = useState(false);
  const { isOpen: isOpenChange, onOpen: onOpenChange, onClose: onCloseChange } = useDisclosure();
  const [positionExercise, setpositionExercise] = useState("");
  /* const { addRow, changeValue, deleteRow } = useExerciseEditContext(); */
  useEffect(() => {
    setExerciseEdit(exercise);
  }, [openEdit, exercise]);
  const handleSaveEdit = () => {
    saveExercise(exerciseEdit);
    setOpenEdit(false);
  };

  const changeSetsData = (index, data) => {
    const setsNew = [...exerciseEdit.sets];
    setsNew[index] = { ...setsNew[index], ...data };
    const newExercise = { ...exerciseEdit, sets: setsNew };
    setExerciseEdit(newExercise);
  };

  const deleteRow = (set_number) => {
    const setsModified = exerciseEdit.sets
      .filter((set) => set.set_number !== set_number)
      .map((set, i) => ({ ...set, set_number: i + 1 }));

    const exerciseModified = { ...exerciseEdit, sets: setsModified };
    setExerciseEdit(exerciseModified);
  };

  const addRow = () => {
    const setToCopy = exerciseEdit.sets.at(-1);
    /* Copying the last set in the array and adding 1 to the set number. */
    const setsModified = exerciseEdit.sets.concat({
      ...setToCopy,
      set_number: setToCopy.set_number + 1,
    });

    /* Copying the exerciseEdit object and adding the setsModified array to it. */
    const exerciseModified = { ...exerciseEdit, sets: setsModified };
    setExerciseEdit(exerciseModified);
  };

  const setCircuit = (data) => {
    const newExercise = { ...exerciseEdit, colors: data };
    setExerciseEdit(newExercise);
  };

  const changeExercise = (data) => {
    const newExercise = { ...exerciseEdit, id_exercise: data };
    console.log(newExercise, data);
    setExerciseEdit(newExercise);
  };

  const changeExerciseNotes = (data) => {
    const newNotes = {
      ...exerciseEdit,
      notes_exercise: { ...exercise?.notes_exercise, [lang]: data },
    };
    setExerciseEdit(newNotes);
  };

  const handleConfirmClose = () => {
    if (exercise !== exerciseEdit) {
      setopenDialog(true);
      return;
    }
    setOpenEdit();
  };

  return (
    <div key={exerciseEdit?._id}>
      <IconButton
        icon={<EditIcon fontSize="inherit" />}
        onClick={() => setOpenEdit(true)}
        size="xs"
      />
      <Modal
        returnFocusOnClose={false}
        isOpen={openEdit}
        closeOnOverlayClick={false}
        onClose={() => handleConfirmClose()}
        size="xl"
        id={exerciseEdit?._id}
        onCloseComplete={() => setExerciseEdit(null)}>
        <ModalOverlay />
        <ModalContent borderTop="13px solid #b42519" borderRadius={10}>
          <ModalHeader display="flex" justifyContent="space-between">
            {/*  <Box>
              <Text display="inline">
                {`${t("add_exercise")} ${t("in_day")} `}
                <Text display="inline" color="#b42519">
                  {quotes[0].day}
                </Text>
                {` ${t("list_week")} `}
                <Text display="inline" color="#b42519">
                  {quotes[0].week}
                </Text>
              </Text>
            </Box> */}
            <Box>
              <ModalCloseButton />
            </Box>
          </ModalHeader>
          <ModalBody>
            <Box>
              <article key={exercise._id} className="exercise_content">
                <Flex gridGap={4} justifyContent="space-between" alignItems="center">
                  <h4 className="exercise_content__title">
                    {exerciseEdit?.id_exercise?.name[lang] || (
                      <span style={{ textTransform: "uppercase", color: "red" }}>
                        {t("undefined_exercise")}
                      </span>
                    )}
                  </h4>
                  <Box>
                    <Tooltip hasArrow placement="top" label={t("change_exercise")}>
                      <Button
                        size="sm"
                        colorScheme="gray"
                        onClick={() => {
                          onOpenChange();
                          setpositionExercise(idx);
                        }}>
                        {t("change_exercise")}
                      </Button>
                    </Tooltip>
                    <Button
                      variant="ghost"
                      colorScheme="red"
                      onClick={() => deleteExercise(exercise)}>
                      <DeleteIcon fontSize="inherit" />
                    </Button>
                  </Box>
                </Flex>
                <Flex justifyContent="space-between" alignItems="center">
                  <Menu>
                    <MenuButton
                      as={Button}
                      aria-label="Options"
                      leftIcon={<img src={BrushIcon} alt="brush icon" style={{ width: "14px" }} />}
                      size="xs"
                      style={{
                        backgroundColor: `${
                          superSetColorsHex[exerciseEdit?.colors?.hard || "#fff"]
                        }`,
                      }}>
                      {t("set_circuit")}
                    </MenuButton>
                    <MenuList zIndex="99999">
                      {superSetColors.map((color, id) => (
                        <MenuItem
                          onClick={() => {
                            setCircuit({ hard: color, soft: color });
                          }}>
                          {color === "white" ? (
                            <Badge colorScheme={color}>None</Badge>
                          ) : (
                            <Badge colorScheme={color}>
                              {t("circuit")} {id + 1} {color}
                            </Badge>
                          )}
                        </MenuItem>
                      ))}
                    </MenuList>
                  </Menu>
                  <Flex gridGap={2} justifyContent="end" fontSize="12px" color="gray">
                    <p>{t("number_of_sets")}:</p>
                    <b>{exerciseEdit?.sets.length || 0}</b>
                  </Flex>
                </Flex>

                <div>
                  {exerciseEdit?.sets?.map((s, index) => (
                    <Box key={index}>
                      <Flex
                        gridGap={2}
                        bg="#f9f9f9"
                        borderRadius="10px"
                        padding=".5rem"
                        margin=".5rem 0px"
                        alignItems="center ">
                        <div>
                          <InputGroup bg="white">
                            <InputLeftElement pointerEvents="none" h="2rem" ml="10px">
                              <p className="exercise_content__set-title">{t("loads")}:</p>
                            </InputLeftElement>
                            <Input
                              size="sm"
                              type="tel"
                              value={s.load}
                              placeholder="0"
                              onChange={({ target }) =>
                                changeSetsData(index, { [target.id]: target.value })
                              }
                              id="load"
                              textAlign="center"
                            />
                            <Select
                              size="sm"
                              variant="filled"
                              value={s.typeWeight}
                              id="typeWeight"
                              onChange={({ target }) =>
                                changeSetsData(index, { [target.id]: target.value })
                              }>
                              <option value="kg">kg</option>
                              <option value="lb">lb</option>
                              <option value="others">{t("others")}</option>
                            </Select>
                          </InputGroup>
                        </div>
                        <div>
                          <InputGroup bg="white">
                            <InputLeftElement pointerEvents="none" h="2rem" ml="5px">
                              <p className="exercise_content__set-title">{t("Reps")}:</p>
                            </InputLeftElement>
                            <Input
                              size="sm"
                              type="tel"
                              id="reps"
                              placeholder="0"
                              value={s.reps}
                              onChange={({ target }) =>
                                changeSetsData(index, { [target.id]: target.value })
                              }
                              textAlign="center"
                            />
                            <Select
                              size="sm"
                              variant="filled"
                              value={s.typeReps}
                              id="typeReps"
                              onChange={({ target }) =>
                                changeSetsData(index, { [target.id]: target.value })
                              }>
                              <option value="reps">reps</option>
                              <option value="sec">sec</option>
                            </Select>
                          </InputGroup>
                        </div>

                        <IconButton
                          isRound
                          icon={<ClearIcon fontSize="inherit" />}
                          variant="ghost"
                          colorScheme="red"
                          disabled={exerciseEdit?.sets?.length <= 1}
                          onClick={() => deleteRow(s.set_number)}
                        />
                      </Flex>
                    </Box>
                  ))}
                  <Flex justifyContent="space-between" my={2}>
                    <Button
                      leftIcon={<StickyNote2OutlinedIcon fontSize="inherit" />}
                      bg={exerciseEdit?.notes_exercise?.[lang] !== "" && "#C6F6D5"}
                      colorScheme="gray"
                      onClick={() => setShowNotesExercise((prev) => !prev)}>
                      {t("notes_exercise")}
                    </Button>
                    <Button
                      leftIcon={<AddIcon fontSize="inherit" />}
                      colorScheme="gray"
                      onClick={() => addRow()}>
                      {t("add_sets")}
                    </Button>
                  </Flex>
                </div>
                {showNotesExercise && (
                  <InputGroup bg="#f6f6f6" my={3}>
                    <Textarea
                      type="text"
                      bg="#f6f6f6"
                      placeholder={t("notes_exercise")}
                      id={exerciseEdit?._id}
                      value={exerciseEdit?.notes_exercise?.[lang]}
                      onChange={({ target }) => changeExerciseNotes(target.value)}
                      border="none"
                    />
                  </InputGroup>
                )}
              </article>
              <Box display="flex" justifyContent="flex-end" marginTop={2} marginBottom={2}>
                <Button
                  type="submit"
                  bg="#6bca21"
                  color="white"
                  _hover={{ bg: "#6bca21" }}
                  onClick={() => handleSaveEdit()}>
                  {t("save")}
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      {openDialog && (
        <AlertDialog isOpen={openDialog} onClose={() => setopenDialog(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
                {t("delete_confirmation")}
              </AlertDialogHeader>
              <AlertDialogBody>{t("confirm_save_or_discard")}</AlertDialogBody>
              <AlertDialogFooter>
                <Box
                  display="flex"
                  flexDirection="column"
                  w="full"
                  gridGap={2}
                  justifyContent="center"
                  alignItems="center">
                  <Button w="70%" border="1px solid #b42519" onClick={() => setopenDialog(false)}>
                    {t("backBtn")}
                  </Button>
                  <Button
                    w="70%"
                    bg="#b42519"
                    color="white"
                    _hover={{ bg: "white", border: "1px solid #b42519", color: "black" }}
                    onClick={() => setOpenEdit()}>
                    {t("discard")}
                  </Button>
                  <Button
                    w="70%"
                    bg="#6bca21"
                    color="white"
                    _hover={{ bg: "#6bca21" }}
                    onClick={() => handleSaveEdit()}>
                    {t("save")}
                  </Button>
                </Box>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
      {isOpenChange && (
        <ExerciseListModal
          isOpen={isOpenChange}
          key={positionExercise}
          onClose={onCloseChange}
          changeExercise={changeExercise}
        />
      )}
    </div>
  );
};
export default EditExercise;
