import instance from "./_axiosConfig";

const api = instance;
const createRoutine = async (data) => {
  try {
    const response = await api.post("/customWorkout/", data);
    return response.data;
  } catch (exception) {
    return exception.response;
  }
};

const updateRoutine = async (id, data) => {
  try {
    const response = await api.put(`/customWorkout/${id}`, data);
    return response.data;
  } catch (exception) {
    return exception.response;
  }
};

const updateCompletedDay = async (sessionId) => {
  try {
    const response = await api.put(`/customWorkout/completed/${sessionId}`);
    return response.data;
  } catch (exception) {
    console.log(exception.response);
    throw new Error(exception.response);
  }
};

const copyAndPasteDay = async (programid, data) => {
  try {
    const response = await api.put(`/customWorkout/completed/sessions/${programid}`, data);
    return response.data;
  } catch (exception) {
    console.log(exception.response);
    throw new Error(exception.response);
  }
};

const getRoutine = async (id) => {
  try {
    const response = await api.get(`/customWorkout/${id}`);
    return response.data.customWorkout;
  } catch (exception) {
    return exception.response;
  }
};

const getClientRoutines = async (id) => {
  try {
    const response = await api.get(`/customWorkout/client/${id}`);
    return response.data.customWorkoutsByClientId;
  } catch (exception) {
    return exception.response;
  }
};

const deleteRoutine = async (id) => {
  try {
    const response = await api.delete(`/customWorkout/${id}`);
    return response.data;
  } catch (exception) {
    return exception.response;
  }
};

export {
  createRoutine,
  updateRoutine,
  getRoutine,
  getClientRoutines,
  deleteRoutine,
  updateCompletedDay,
  copyAndPasteDay,
};
