/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useReducer } from "react";
// ? External Dependencies
import { Grid, Button, IconButton, Flex, Image, Skeleton, Box } from "@chakra-ui/react";
import { useSelector } from "react-redux";
// ? Local Dependencies
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import notFound from "assets/7.png";
import useLang from "hooks/useLang";
import { getTemplates } from "services/template";
import hasFilterValue from "helpers/hasFilterValue";
import listStyle from "../../../programs/List.module.css";
import previeStyle from "../../../programs/Preview.module.css";
import mainStyle from "../../../programs/Main.module.css";

const TemplateList = ({ toggle, setFinalData, currentPage, nPages, data, getProgram, loading }) => {
  const [weekStep, setWeekStep] = useState(1);
  const [weeksToDisplay, setWeeksToDisplay] = useState(0);
  const [isError, toggleIsError] = useReducer((prev) => !prev, false);
  const [selectInfo, setSelectInfo] = useState(null);
  const { id_user } = useSelector(({ user }) => user);
  const { t, lang } = useLang();

  const goToNextPage = () => {
    if (currentPage !== nPages) getProgram(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 0) getProgram(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 0) getProgram(0);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) getProgram(nPages - 1);
  };
  const handleClick = () => {
    toggle();
    setFinalData(selectInfo);
  };
  const select = (info) => {
    const w = info?.sessions?.reduce((total, curr) => {
      if (total[curr.week - 1] === undefined) {
        total.push([curr]);
        return total;
      }
      const dataL = total[curr.week - 1].concat(curr);
      return total.map((item, id) => (id === curr.week - 1 ? dataL : item));
    }, []);
    console.log(info, w);
    setWeeksToDisplay(w);
    setSelectInfo(info);
  };

  if (isError) return <p>Something went wrong</p>;

  return (
    <div className={mainStyle.content}>
      <div className={listStyle.container}>
        {loading ? (
          <Flex w="100%" flexDirection="column" gridGap={4}>
            <Skeleton height="5rem" w="100%" />
            <Skeleton height="5rem" w="100%" />
            <Skeleton height="5rem" w="100%" />
            <Skeleton height="5rem" w="100%" />
            <Skeleton height="5rem" w="100%" />
          </Flex>
        ) : (
          <>
            {data.length <= 0 && (
              <div className={listStyle.notFound}>
                <Image src={notFound} />
                <p>{t("not_fount_programs")}</p>
              </div>
            )}
            <ul>
              {data.map((item) => (
                <li
                  className={`${listStyle.listItem} ${
                    item?._id === selectInfo?._id && listStyle.active
                  }`}
                  onClick={() => select(item)}
                  key={item._id}
                  aria-hidden="true">
                  {item.workout_name[lang]}
                </li>
              ))}
            </ul>
          </>
        )}

        {data.length > 0 && (
          <nav>
            <ul className="pagination">
              <li>
                <IconButton
                  isRound
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="Done"
                  fontSize="20px"
                  disabled={currentPage === 0}
                  icon={<FirstPageIcon />}
                  onClick={goToFirstPage}
                />
              </li>
              <li>
                <IconButton
                  isRound
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="Done"
                  fontSize="20px"
                  disabled={currentPage === 0}
                  icon={<ArrowBackIosIcon />}
                  onClick={goToPrevPage}
                />
              </li>
              <li>
                {currentPage + 1}/{nPages}
              </li>
              <li>
                <IconButton
                  isRound
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="Done"
                  fontSize="20px"
                  disabled={currentPage + 1 === nPages}
                  icon={<ArrowForwardIosIcon />}
                  onClick={goToNextPage}
                />
              </li>
              <li>
                <IconButton
                  isRound
                  variant="ghost"
                  colorScheme="blackAlpha"
                  aria-label="Done"
                  fontSize="20px"
                  disabled={currentPage + 1 === nPages}
                  icon={<LastPageIcon />}
                  onClick={goToLastPage}
                />
              </li>
            </ul>
          </nav>
        )}
      </div>
      {selectInfo && (
        <div className={previeStyle.container}>
          <Flex justifyContent="center" mb={4}>
            <Button onClick={handleClick} colorScheme="red" rightIcon={<ArrowForwardIosIcon />}>
              {t("select_this_template")}
            </Button>
          </Flex>
          <div className={previeStyle.body}>
            <IconButton
              isRound
              variant="ghost"
              colorScheme="blackAlpha"
              icon={<ArrowBackIosIcon />}
              size="sm"
              disabled={weekStep === 1}
              onClick={() => setWeekStep(weekStep - 1)}
            />
            {weeksToDisplay
              .filter((arrData) => arrData[0].week === weekStep)
              .map((arrData) => (
                <>
                  <Flex
                    className={previeStyle.weekHeader}
                    gridGap={4}
                    flexDirection="column"
                    alignItems="center"
                    justifyContent="center">
                    <h3>
                      {t("list_week")} {arrData[0].week}
                    </h3>

                    <div className={previeStyle.bodyContainer}>
                      {arrData.map((day) => (
                        <div className={previeStyle.day}>
                          <h4>
                            {t("list_day")} {day.day}
                          </h4>
                          <ul>
                            {day.exercises
                              ? day.exercises.map((exercise) => (
                                  <li>
                                    <p className={previeStyle.exerciseName}>
                                      {exercise.id_exercise.name[lang]}
                                    </p>
                                    <p className={previeStyle.exerciseData}>
                                      {exercise.sets[0].load !== "" &&
                                      exercise.sets[0].load !== "0" ? (
                                        <>
                                          {exercise.sets.length} Sets x{" "}
                                          {exercise.sets?.map((s) => (
                                            <span>
                                              {s.load}
                                              {s.typeWeight === "others" ? "" : s.typeWeight}/
                                              {s.reps}
                                              {s?.typeReps ?? ""},{" "}
                                            </span>
                                          ))}
                                        </>
                                      ) : (
                                        <>
                                          {exercise.sets.length} Sets x {exercise.sets[0].reps}
                                          {exercise.sets[0].typeReps ?? ""}
                                        </>
                                      )}
                                    </p>
                                  </li>
                                ))
                              : day.categoryList.map((category) => (
                                  <li>{`${category.category?.name}-${category.muscle?.name}-${category.action?.name}`}</li>
                                ))}
                          </ul>
                        </div>
                      ))}
                    </div>
                  </Flex>
                </>
              ))}
            <IconButton
              isRound
              variant="ghost"
              colorScheme="blackAlpha"
              icon={<ArrowForwardIosIcon />}
              size="sm"
              disabled={weekStep === weeksToDisplay.length}
              onClick={() => setWeekStep(weekStep + 1)}
            />
          </div>

          <div className={previeStyle.pagination}>
            <IconButton
              isRound
              variant="ghost"
              colorScheme="blackAlpha"
              icon={<ArrowBackIosIcon />}
              size="sm"
              onClick={() => setWeekStep(1)}
            />
            {weeksToDisplay.map((_, idx) => (
              <Button
                className={weekStep === idx + 1 ? previeStyle.active : ""}
                as="div"
                size="sm"
                onClick={() => setWeekStep(idx + 1)}>
                {idx + 1}
              </Button>
            ))}
            <IconButton
              isRound
              variant="ghost"
              colorScheme="blackAlpha"
              icon={<ArrowForwardIosIcon />}
              size="sm"
              onClick={() => setWeekStep(weeksToDisplay.length)}
            />
          </div>
        </div>
      )}
      {!selectInfo && (
        <div className={mainStyle.contentPreview}>
          <span>{t("select_routine_word")}</span>
        </div>
      )}
    </div>
  );
};

export default TemplateList;
