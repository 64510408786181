/* eslint-disable react/prop-types */
/* eslint-disable no-alert */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useRef } from "react";

import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import useLang from "hooks/useLang";
import esLocale from "@fullcalendar/core/locales/es";
import enLocale from "@fullcalendar/core/locales/fr";
import "moment/locale/es";

import ShareIcon from "@mui/icons-material/Share";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {
  Button,
  Flex,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  Box,
  Input,
  IconButton,
  AbsoluteCenter,
  useToast,
} from "@chakra-ui/react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import multiMonthPlugin from "@fullcalendar/multimonth";

import QRCode from "react-qr-code";
import interactionPlugin from "@fullcalendar/interaction";
// import { Navbar } from '../ui/Navbar';
import "react-big-calendar/lib/css/react-big-calendar.css";
import { useDispatch, useSelector } from "react-redux";
import CalendarModal from "./CalendarModal";
import { uiOpenModal } from "../../actions/ui";
import { eventClearActiveEvent, eventSetActive, eventSetSlotEmpty } from "../../actions/events";
import AddNewFab from "../ui/AddNewFab";
import "./Calendar.module.scss";

const CalendarScreen = ({ client = null, isEdit = false }) => {
  const calendarRef = useRef(null);
  const dispatch = useDispatch();
  const toast = useToast();
  // TODO : Leer del store los eventos
  const { events, activeEvent } = useSelector((state) => state.calendar);
  const { id_user } = useSelector((state) => state.user);
  const [lastView, setLastView] = useState(localStorage.getItem("lastView") || "multiMonthYear");
  const { t, lang } = useLang();

  const onSelectEvent = (e) => {
    const eventFind = events.filter((event) => event.id === e.event._def.publicId)[0];
    if (eventFind) {
      eventFind.end = moment(eventFind.end).subtract(24, "h");
      console.log("select event", eventFind);
      dispatch(eventSetActive(eventFind));
      dispatch(uiOpenModal());
    }
  };
  const onSelectSlot = (e) => {
    if (isEdit) {
      e.end = e.end ? moment(e.end).subtract(24, "h") : e.date;
      const calendarApi = calendarRef.current.getApi();
      console.log(e, isEdit);
      calendarApi.gotoDate(e.date ?? e.start);
      dispatch(uiOpenModal());
      dispatch(eventClearActiveEvent());
      dispatch(eventSetSlotEmpty(e));
    }
  };

  const onViewChange = (e) => {
    setLastView(e);
    localStorage.setItem("lastView", e);
  };
  const copyClipboard = () => {
    const copyText = document.getElementById("routine-url");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard.writeText(copyText.value);
    toast({
      position: "bottom",
      render: () => (
        <Box color="white" p={3} bg="black" textAlign="center">
          {t("copiedClipboard")}
        </Box>
      ),
    });
  };
  return (
    <div className="calendar-screen">
      {client && (
        <Flex justifyContent="end" mb={4}>
          <Popover>
            <PopoverTrigger>
              <Button leftIcon={<ShareIcon fontSize="inherit" />}>{t("sharePlanning")}</Button>
            </PopoverTrigger>
            <Portal>
              <PopoverContent zIndex="99999999">
                <PopoverArrow />
                <PopoverCloseButton />
                <PopoverBody>
                  <Text
                    fontWeight="bold"
                    textAlign="center"
                    my={4}
                    fontSize="xs"
                    colorScheme="gray">
                    {t("scanQr")}
                  </Text>
                  <div style={{ height: "auto", margin: "0 auto", maxWidth: 120, width: "100%" }}>
                    <QRCode
                      size={256}
                      style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                      value={`${window.location.protocol}//${window.location.host}/shared-planning/${client}`}
                      viewBox="0 0 256 256"
                    />
                  </div>
                  <Box position="relative" my={6} p={2}>
                    <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs">
                      {t("orCopyLink")}
                    </AbsoluteCenter>
                  </Box>
                  <Flex gridGap={4} alignItems="center">
                    <Input
                      disabled
                      readOnly
                      value={`${window.location.protocol}//${window.location.host}/shared-planning/${client}`}
                      cursor="pointer"
                      id="routine-url"
                      fontSize="12px"
                      onClick={copyClipboard}
                    />
                    <IconButton
                      isRound
                      size="sm"
                      colorScheme="red"
                      variant="ghost"
                      onClick={copyClipboard}
                      icon={<ContentCopyIcon fontSize="inherit" />}
                    />
                  </Flex>
                  <Flex my={2} justifyContent="flex-end">
                    <Button
                      z-index="999"
                      leftIcon={<VisibilityIcon fontSize="inherit" />}
                      as="a"
                      href={`${window.location.protocol}//${window.location.host}/shared-planning/${client}`}
                      target="_blank"
                      size="sm">
                      Preview
                    </Button>
                  </Flex>
                </PopoverBody>
              </PopoverContent>
            </Portal>
          </Popover>
        </Flex>
      )}
      <FullCalendar
        ref={calendarRef}
        height="100%"
        selectable="true"
        plugins={[dayGridPlugin, multiMonthPlugin, interactionPlugin]}
        initialView={lastView}
        headerToolbar={{
          left: "today",
          center: "prev,title,next",
          right: "multiMonthYear,dayGridMonth,dayGridWeek",
        }}
        events={events}
        dateClick={(e) => onSelectSlot(e)}
        select={(e) => onSelectSlot(e)}
        eventClick={(e) => onSelectEvent(e)}
        locales={[esLocale, enLocale]}
        locale={lang}
        dragScroll={false}
        scrollTimeReset={false}
      />
      {/* <Calendar
        localizer={localizer}
        events={events}
        startAccessor="start"
        endAccessor="end"
        messages={messages[lang]}
        eventPropGetter={eventStyleGetter}
        onDoubleClickEvent={onDoubleClick}
        onSelectEvent={onSelectEvent}
        onView={onViewChange}
        onSelectSlot={onSelectSlot}
        selectable
        view={lastView}
        views={category !== "all" ? viewCategoryType : ["month", "day", "week", "agenda"]}
        components={{
          event: CalendarEvent,
        }}
      /> */}
      {isEdit && <AddNewFab />}

      {/*  {activeEvent && <DeleteEventFab />}
      {activeEvent && <UpdateEventFab />} */}
      <CalendarModal client={client} isEdit={isEdit} />
    </div>
  );
};

export default CalendarScreen;
