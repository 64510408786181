/* eslint-disable react/no-children-prop */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
import { useState } from "react";
import {
  Button,
  Divider,
  Flex,
  Heading,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

import { useDispatch, useSelector } from "react-redux";
import notificationDisplay from "utils/notificationDisplay";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useForm } from "react-hook-form";
import { updateMeasurement } from "services/evaluations";
import CloseIcon from "@mui/icons-material/Close";
import CheckIcon from "@mui/icons-material/Check";
import useLang from "hooks/useLang";
import moment from "moment";
import AddMeasurementModal from "./addMeasurementModal";
import style from "./goalForm.module.scss";

const MeasurementsTable = ({ client, measurements, getMeasurementsClient, deleteMeasurements }) => {
  const { t, lang } = useLang();
  const { register, getValues } = useForm();
  const [indexEdit, setIndexEdit] = useState(-1);
  const [idEdit, setIdEdit] = useState(-1);

  const notiDispatch = useDispatch();

  const updateMeasurementValues = async () => {
    const data = getValues();
    console.log(data);
    try {
      const res = await updateMeasurement(idEdit, data);
      if (res.data) throw new Error(res.data.message);
      getMeasurementsClient();
      notificationDisplay(notiDispatch, t("measurement_start"), "success");
    } catch (exception) {
      console.log(exception);
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };

  const selectMeasurement = (index, id) => {
    setIndexEdit(index !== indexEdit ? index : -1);
    if (index === indexEdit) updateMeasurementValues();
    else setIdEdit(id);
  };
  const showOthers = (data, atribute) => {
    if (data.length > 0) {
      const dataMap = data
        .map((a, index) =>
          a[atribute].others.map((item) => {
            return { ...item, index }; /* add index to diference objects */
          }),
        )
        .reduce((a, b) => a.concat(b));
      /* group and get key to show */
      const objectGroup = Object.groupBy(dataMap, ({ name }) => name);
      const objectKeys = Object.keys(objectGroup).map((key) => (
        <>
          <Tr>
            <Td w="150px" fontWeight="bold">
              {key}
            </Td>
            {data.map((a, index) => (
              <Td w="150px">
                {objectGroup[key].map((item) => {
                  return index === item.index ? item.value : "";
                })}
              </Td>
            ))}
          </Tr>
        </>
      ));
      return objectKeys;
    }
    return <Td w="150px" />;
  };
  return (
    <>
      <Heading textAlign="center">{t("measurementForm.clientMeasurement")}</Heading>
      <AddMeasurementModal
        client={client}
        measurement={null}
        getMeasurementsClient={getMeasurementsClient}
      />
      <TableContainer className={style.table}>
        <Table variant="simple" size="sm">
          <Thead>
            <Tr>
              <Th />
              <Th />
              <Th />
              <Th />
              <Th />
              <Th />
            </Tr>
          </Thead>
          <Tbody>
            <Tr>
              <Td w="150px" colSpan="100%">
                <Heading as="h4" size="md" textAlign="center">
                  {t("measurementForm.generalInformation")}
                </Heading>
              </Td>
            </Tr>
            <Tr>
              <Td w="150px" />
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  <Flex gridGap={4} justifyContent="center">
                    <IconButton
                      icon={
                        index !== indexEdit ? (
                          <EditIcon fontSize="inherit" />
                        ) : (
                          <CheckIcon type="submit" fontSize="inherit" />
                        )
                      }
                      size="sm"
                      onClick={() => selectMeasurement(index, measurement._id)}
                    />
                    <IconButton
                      icon={<DeleteIcon fontSize="inherit" />}
                      colorScheme="red"
                      size="sm"
                      onClick={() => deleteMeasurements(measurement._id)}
                    />
                  </Flex>
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("date")}
              </Td>
              {measurements.map((measurement) => (
                <Td w="150px">
                  {moment(measurement.created_at || Date.now()).format(
                    lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.weight")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("generalInformation.weight", {
                          value: measurement.generalInformation?.weight,
                        })}
                      />
                      <InputRightElement children={lang === "es" ? "cm" : "in"} />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.generalInformation?.weight}
                      <span style={{ color: "gray" }}> {lang === "es" ? "cm" : "in"}</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.height")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("generalInformation.height", {
                          value: measurement.generalInformation?.height,
                        })}
                      />
                      <InputRightElement children={lang === "es" ? "cm" : "in"} />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.generalInformation?.height}
                      <span style={{ color: "gray" }}> {lang === "es" ? "cm" : "in"}</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.bp")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("generalInformation.bp", {
                          value: measurement.generalInformation?.bp,
                        })}
                      />
                    </InputGroup>
                  ) : (
                    <Text>{measurement.generalInformation?.bp}</Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.hr")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("generalInformation.hr", {
                          value: measurement.generalInformation?.hr,
                        })}
                      />
                    </InputGroup>
                  ) : (
                    <Text>{measurement.generalInformation?.hr}</Text>
                  )}
                </Td>
              ))}
            </Tr>
            {showOthers(measurements, "generalInformation")}
            <Tr>
              <Td w="150px" colSpan="100%">
                <Heading as="h4" size="md" textAlign="center">
                  {t("measurementForm.circunferenceMeasurements")}
                </Heading>
              </Td>
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.arm")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("circunference.arm", {
                          value: measurement.circunference?.arm,
                        })}
                      />
                      <InputRightElement children={lang === "es" ? "cm" : "in"} />
                    </InputGroup>
                  ) : (
                    <Text>
                      {" "}
                      {measurement.circunference?.arm}
                      <span style={{ color: "gray" }}> {lang === "es" ? "cm" : "in"}</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.chest")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("circunference.chest", {
                          value: measurement.circunference?.chest,
                        })}
                      />
                      <InputRightElement children={lang === "es" ? "cm" : "in"} />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.circunference?.chest}
                      <span style={{ color: "gray" }}> {lang === "es" ? "cm" : "in"}</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.abdominals")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("circunference.abdominals", {
                          value: measurement.circunference?.abdominals,
                        })}
                      />
                      <InputRightElement children={lang === "es" ? "cm" : "in"} />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.circunference?.abdominals}
                      <span style={{ color: "gray" }}> {lang === "es" ? "cm" : "in"}</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.hip")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("circunference.hip", {
                          value: measurement.circunference?.hip,
                        })}
                      />
                      <InputRightElement children={lang === "es" ? "cm" : "in"} />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.circunference?.hip}
                      <span style={{ color: "gray" }}> {lang === "es" ? "cm" : "in"}</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.thigh")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("circunference.thigh", {
                          value: measurement.circunference?.thigh,
                        })}
                      />
                      <InputRightElement children={lang === "es" ? "cm" : "in"} />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.circunference?.thigh}
                      <span style={{ color: "gray" }}> {lang === "es" ? "cm" : "in"}</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.calf")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("circunference.calf", {
                          value: measurement.circunference?.calf,
                        })}
                      />
                      <InputRightElement children={lang === "es" ? "cm" : "in"} />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.circunference?.calf}
                      <span style={{ color: "gray" }}> {lang === "es" ? "cm" : "in"}</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            {showOthers(measurements, "circunference")}
            <Tr>
              <Td w="150px" colSpan="100%">
                <Heading as="h4" size="md" textAlign="center">
                  {t("measurementForm.skinfoldMeasurements")}
                </Heading>
              </Td>
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.chest")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("skinfold.chest", {
                          value: measurement.skinfold?.chest,
                        })}
                      />
                      <InputRightElement children="mm" />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.skinfold?.chest}
                      <span style={{ color: "gray" }}> mm</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.triceps")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("skinfold.triceps", {
                          value: measurement.skinfold?.triceps,
                        })}
                      />
                      <InputRightElement children="mm" />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.skinfold?.triceps}
                      <span style={{ color: "gray" }}> mm</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.subscapula")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("skinfold.subscapula", {
                          value: measurement.skinfold?.subscapula,
                        })}
                      />
                      <InputRightElement children="mm" />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.skinfold?.subscapula}
                      <span style={{ color: "gray" }}> mm</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.midaxillary")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("skinfold.midaxillary", {
                          value: measurement.skinfold?.midaxillary,
                        })}
                      />
                      <InputRightElement children="mm" />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.skinfold?.midaxillary}
                      <span style={{ color: "gray" }}> mm</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.suprailiac")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("skinfold.suprailiac", {
                          value: measurement.skinfold?.suprailiac,
                        })}
                      />
                      <InputRightElement children="mm" />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.skinfold?.suprailiac}
                      <span style={{ color: "gray" }}> mm</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.abdominal")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("skinfold.abdominal", {
                          value: measurement.skinfold?.abdominal,
                        })}
                      />
                      <InputRightElement children="mm" />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.skinfold?.abdominal}
                      <span style={{ color: "gray" }}> mm</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.thigh")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("skinfold.thigh", {
                          value: measurement.skinfold?.thigh,
                        })}
                      />
                      <InputRightElement children="mm" />
                    </InputGroup>
                  ) : (
                    <Text>
                      {measurement.skinfold?.thigh}
                      <span style={{ color: "gray" }}> mm</span>
                    </Text>
                  )}
                </Td>
              ))}
            </Tr>
            {showOthers(measurements, "skinfold")}
            <Tr>
              <Td w="150px" colSpan="100%">
                <Heading as="h4" size="md" textAlign="center">
                  {t("measurementForm.performanceMeasurements")}
                </Heading>
              </Td>
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.vertical")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("performance.vertical", {
                          value: measurement.performance?.vertical,
                        })}
                      />
                    </InputGroup>
                  ) : (
                    <Text>{measurement.performance?.vertical}</Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.mbChest")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("performance.mb_chest", {
                          value: measurement.performance?.mb_chest,
                        })}
                      />
                    </InputGroup>
                  ) : (
                    <Text>{measurement.performance?.mb_chest}</Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.yrd300")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("performance.yrd_300", {
                          value: measurement.performance?.yrd_300,
                        })}
                      />
                    </InputGroup>
                  ) : (
                    <Text>{measurement.performance?.yrd_300}</Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.bench1rm")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("performance.bench_1rm", {
                          value: measurement.performance?.bench_1rm,
                        })}
                      />
                    </InputGroup>
                  ) : (
                    <Text>{measurement.performance?.bench_1rm}</Text>
                  )}
                </Td>
              ))}
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold">
                {t("measurementForm.sq1rm")}
              </Td>
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("performance.sq_1rm", {
                          value: measurement.performance?.sq_1rm,
                        })}
                      />
                    </InputGroup>
                  ) : (
                    <Text>{measurement.performance?.sq_1rm}</Text>
                  )}
                </Td>
              ))}
            </Tr>
            {showOthers(measurements, "performance")}
            <Tr>
              <Td w="150px" colSpan="100%">
                <Heading as="h4" size="md" textAlign="center">
                  {t("measurementForm.painScale")}
                </Heading>
              </Td>
            </Tr>
            <Tr>
              <Td w="150px" fontWeight="bold" />
              {measurements.map((measurement, index) => (
                <Td w="150px">
                  {index === indexEdit ? (
                    <InputGroup w="150px" size="sm">
                      <Input
                        size="sm"
                        variant="filled"
                        type="number"
                        {...register("pain_scale", {
                          value: measurement.pain_scale,
                        })}
                      />
                    </InputGroup>
                  ) : (
                    <Text>{measurement.pain_scale || ""}</Text>
                  )}
                </Td>
              ))}
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <Heading as="h6" size="sm" my={4}>
        {t("measurementForm.notesTherapist")}:
      </Heading>
      <div>
        {measurements.map((measurement, index) =>
          measurement.therapist_notes !== "" ? (
            <>
              <Text fontSize="xs" color="gray">
                {moment(measurement.created_at || Date.now()).format(
                  lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                )}
              </Text>
              {index === indexEdit ? (
                <InputGroup w="150px" size="sm">
                  <Input
                    size="sm"
                    variant="filled"
                    type="text"
                    {...register("therapist_notes", {
                      value: measurement.therapist_notes,
                    })}
                  />
                </InputGroup>
              ) : (
                <Text mb={2}>{measurement.therapist_notes || ""}</Text>
              )}
            </>
          ) : null,
        )}
      </div>
      <Heading as="h6" size="sm" my={4}>
        {t("measurementForm.notesFromTrainer")}
      </Heading>
      <div>
        {measurements.map((measurement, index) =>
          measurement.trainer_notes !== "" ? (
            <>
              <Text fontSize="xs" color="gray">
                {moment(measurement.created_at || Date.now()).format(
                  lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                )}
              </Text>

              {index === indexEdit ? (
                <InputGroup w="150px" size="sm">
                  <Input
                    size="sm"
                    variant="filled"
                    type="text"
                    {...register("trainer_notes", {
                      value: measurement.trainer_notes,
                    })}
                  />
                </InputGroup>
              ) : (
                <Text mb={2}>
                  <Text mb={2}>{measurement.trainer_notes}</Text>
                </Text>
              )}
            </>
          ) : null,
        )}
      </div>
    </>
  );
};

export default MeasurementsTable;
