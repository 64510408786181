import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";

import Info from "./Info";
import Edit from "./Edit";
import AddExercise from "./AddExercise";

const Popup = ({ exercise, isOpen, onClose, mode }) => {
  const { lang, t } = useTranslation();
  return (
    <>
      {mode !== "info" ? (
        <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} size="6xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{mode === "add" ? t("add_exercise") : exercise?.name[lang]}</ModalHeader>
            <ModalCloseButton />
            <ModalBody my={4}>
              {mode === "edit" && <Edit exercise={exercise} />}
              {mode === "add" && <AddExercise />}
            </ModalBody>
          </ModalContent>
        </Modal>
      ) : (
        <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{mode === "add" ? t("add_exercise") : exercise?.name[lang]}</ModalHeader>
            <ModalCloseButton />
            <ModalBody my={4}>{mode === "info" && <Info exercise={exercise} />}</ModalBody>
          </ModalContent>
        </Modal>
      )}
    </>
  );
};

Popup.propTypes = {
  exercise: propTypes.shape({
    name: propTypes.string.isRequired,
  }).isRequired,
  isOpen: propTypes.func.isRequired,
  onClose: propTypes.func.isRequired,
  mode: propTypes.string.isRequired,
};
export default Popup;
