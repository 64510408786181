import { useMutation, useQueryClient } from "react-query";

const useUpdate = (word, id, func) => {
  const queryClient = useQueryClient();
  return useMutation((data) => func(id, data), {
    onMutate: async (updatedData) => {
      await queryClient.cancelMutations(word);

      const previous = queryClient.getQueryData(word);
      queryClient.setQueryData(word, (old) => {
        console.log("old", old);
        return [...old, updatedData];
      });
      return { previous };
    },
    onError: (context) => {
      queryClient.setQueryData(word, context.previous);
    },
    onSettled: () => {
      queryClient.invalidateQueries(word);
    },
  });
};

export default useUpdate;
