/* eslint-disable react/jsx-filename-extension */
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import Joyride, { STATUS } from 'react-joyride';

const joyrideStyles = {
  options: {
    zIndex: 10000,
    primaryColor: '#b42519',
  },
};
export default function useTour(steps, localStorageKey) {
  const [run, setRun] = useState(false);
  const resetTour = () => {
    setRun(!run);
    window.localStorage.removeItem('reset');
  };
  const finallyTour = () => {
    setRun(false);
  };
  useEffect(() => {
    if (localStorageKey === 'reset') {
      window.localStorage.removeItem('LS_KEY');
    }
    if (!localStorageKey) {
      setRun(true);
      return;
    }
    const tourViewed = window.localStorage.getItem(localStorageKey);
    if (tourViewed) {
      return;
    }
    window.localStorage.setItem(localStorageKey, '1');
    setRun(true);
  }, [localStorageKey]);
  const handleJoyrideCallback = useCallback((data) => {
    const { status } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];
    if (finishedStatuses.includes(status)) {
      setRun(false);
      console.log('haz finalizado el tour');
    }
  }, []);
  const tour = useMemo(
    () => (
      <Joyride
        callback={handleJoyrideCallback}
        continuous
        run={run}
        disableOverlayClose
        scrollToFirstStep
        showProgress
        showSkipButton
        hideCloseButton
        steps={steps}
        styles={joyrideStyles}
      />
    ),
    [steps, handleJoyrideCallback, run],
  );
  return [tour, resetTour, run, finallyTour];
}
