/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
/* eslint-disable no-useless-escape */
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Button, Input } from "@chakra-ui/react";
import propTypes from "prop-types";
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";

import { addInfo } from "reducers/user.reducer";
import { useAuth } from "utils/authContext";
import { getTrainerByUserId } from "services/trainer";
import { inputStyle } from "theme/commonStyles";

import { signup } from "services/user";
import notificationDisplay from "utils/notificationDisplay";
import PasswordInput from "components/common/PasswordInput";

import styles from "./Basic.module.scss";

import googleSvg from "../../../assets/google.svg";

const Basic = ({ move, set, info, setData }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  const history = useHistory();
  const { from } = { from: { pathname: "/user/dashboard" } };

  const onSubmit = async (data) => {
    if (data.password !== data.confirm) {
      notificationDisplay(dispatch, "Passwords entered do not match", "error");
      return;
    }

    const dataToSend = {
      email: data.email,
      password: data.password,
      role: "trainer",
      language: i18n.language,
    };
    setLoading(true);
    // dispatch(loading());

    try {
      const basicResponse = await signup(dataToSend);

      if (basicResponse.data) {
        throw new Error(basicResponse.data.error || basicResponse.data);
      }

      dataToSend.id_user = basicResponse.user._id;
      const user_data = {
        id_user: dataToSend.id_user,
        date_valid: Date.now(),
        email: dataToSend.email,
      };
      localStorage.setItem("user_data", JSON.stringify(user_data));
      set(dataToSend);

      move((prev) => prev + 1);
    } catch (exception) {
      setLoading(false);
      notificationDisplay(dispatch, exception.message, "error");
    }
    setLoading(false);
    // dispatch(loaded());
  };
  const loginGoogle = useGoogleLogin({
    onSuccess: (codeResponse) => {
      setLoading(true);
      axios
        .get(
          `https://www.googleapis.com/oauth2/v1/userinfo?access_token=${codeResponse.access_token}`,
          {
            headers: {
              Authorization: `Bearer ${codeResponse.access_token}`,
              Accept: "application/json",
            },
          },
        )
        .then(async (res) => {
          try {
            const dataToSend = {
              email: res.data.email,
              password: res.data.email + res.data.id,
              role: "trainer",
              name: res.data.given_name,
              lastName: res.data.family_name,
              language: i18n.language,
            };
            const response = await signup(dataToSend);
            if (response.data) throw new Error(response.data.error || response.data);

            dataToSend.id_user = response.user._id;
            const user_data = { id_user: dataToSend.id_user, date_valid: Date.now() };
            localStorage.setItem("user_data", JSON.stringify(user_data));
            set(dataToSend);
            setData({
              name: res.data.given_name,
              lastName: res.data.family_name,
            });
            move((prev) => prev + 1);
          } catch (exception) {
            setLoading(false);
            notificationDisplay(dispatch, exception.message, "error");
          }
          // dispatch(loaded());
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    },
  });
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)} className={styles.form}>
        <h4 className={styles.title}>{t("create_account")}</h4>
        <div className={styles.googleButton} onClick={() => loginGoogle()} aria-hidden="true">
          <img src={googleSvg} alt="google" />
          {t("registerWithGoogle")}
        </div>
        <div className={styles.emailLogin}>
          <span>{t("RegisterWithEmail")}</span>
        </div>
        <Controller
          control={control}
          defaultValue={info.email || ""}
          name="email"
          rules={{ required: true, pattern: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g }}
          render={({ field }) => (
            <Input variant="filled" placeholder={t("email")} {...field} {...inputStyle} mb="0" />
          )}
        />
        {errors.email?.type === "required" && (
          <span className={styles.message}>{t("error_required")}</span>
        )}
        {errors.email?.type === "pattern" && (
          <span className={styles.message}>{t("error_email")}</span>
        )}

        <Controller
          control={control}
          defaultValue={info.password || ""}
          name="password"
          rules={{ required: true, minLength: 4 }}
          render={({ field }) => <PasswordInput field={field} placeholder={t("password")} />}
        />
        {errors.password?.type === "required" && (
          <span className={styles.message}>{t("error_required")}</span>
        )}
        {errors.password?.type === "minLength" && (
          <span className={styles.message}>{t("error_min", { num: 4 })}</span>
        )}

        <Controller
          control={control}
          defaultValue=""
          name="confirm"
          rule={{ required: true }}
          render={({ field }) => (
            <PasswordInput field={field} placeholder={t("confirm_password")} />
          )}
        />
        {errors.confirm?.type === "required" && (
          <span className={styles.message}>{t("error_required")}</span>
        )}

        <Button type="submit" w="100%" my={8} isLoading={loading} colorScheme="ptRed">
          <span>{t("sign_up")}</span>
        </Button>
        <div className={styles.signin}>
          {t("have_account")}
          <Link to="/login" className={styles.link}>
            {t("login")}
          </Link>
        </div>
      </form>
    </>
  );
};

export default Basic;
