/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { shape, string } from "prop-types";
import { Badge, Menu, MenuButton, MenuList, IconButton, MenuItem } from "@chakra-ui/react";

import { superSetColors, superSetColorsHex } from "theme/commonStyles";
import { Draggable, Droppable } from "react-beautiful-dnd";
import useLang from "hooks/useLang";
import styled from "styled-components";

import BrushIcon from "assets/icons/brush.png";
import stylesExercise from "../../../clients/ClientDashboard/ExerciseDisplay/ExerciseDisplay.module.css";

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) return "#FFEBE6";
  if (isDraggingFrom) return "#E6FCF8";
  return "transparent";
};

const getBackgroundColorSubItem = (isDragging, isGroupedOver) => {
  if (isDragging) return "white";
  if (isGroupedOver) return "#FFFFFF";

  return "#FFFFFF";
};

const Container = styled.a`
  border-radius: 20px;
  background-color: ${(props) =>
    getBackgroundColorSubItem(props.isDragging, props.isGroupedOver, props.colors)};
  box-shadow: ${({ isDragging }) => (isDragging ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : "none")};
  box-sizing: border-box;
  padding: 0px;
  min-height: 40px;
  margin-bottom: 10px;
  user-select: none;
  width: 100%;

  /* anchor overrides */
  color: #091e42;

  &:hover,
  &:active {
    color: #091e42;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: white;
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;

function getStyle(provided, style) {
  if (!style) return provided.draggableProps.style;

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

const Wrapper = styled.div`
  background-color: ${(props) => getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : "inherit")};
  padding: 0px;
  border: 0px;
  padding-bottom: 0;
  transition:
    background-color 0.2s ease,
    opacity 0.1s ease;
  user-select: none;
  width: 100%;
`;

const DropZone = styled.div`
  man-height: 700px;
  padding-bottom: 0px;
`;

const SubItem = ({ categoryExercises, day, week }) => {
  const { lang } = useLang();
  const style = "";

  return (
    <>
      <Droppable key={day} droppableId={`${day}-${week}`} type="EXERCISE">
        {(dropProvided, dropSnapshot) => (
          <Wrapper
            isDraggingOver={dropSnapshot.isDraggingOver}
            style={{ backgroundColor: dropSnapshot.isDragging ? "transparent" : null }}
            isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
            {...dropProvided.droppableProps}>
            <div ref={dropProvided.innerRef} className="exercises_dropZone">
              {categoryExercises.categoryList?.map((category, index) => (
                <Draggable key={category._id} draggableId={category._id} index={index}>
                  {(dragProvided, dragSnapshot) => (
                    <Container
                      isDragging={dragSnapshot.isDragging}
                      ref={dragProvided.innerRef}
                      style={getStyle(dragProvided, style)}
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                      data-is-dragging={dragSnapshot.isDragging}
                      isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                      data-testid={category._id}
                      data-index={index}
                      aria-label={`${`${category.category.name}-${category.muscle.name}-${category.action.name}`}`}>
                      <article
                        className={stylesExercise.container}
                        style={{
                          backgroundColor: `${superSetColorsHex[category.colors?.hard || "white"]}`,
                        }}>
                        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                          <h5 style={{ fontWeight: "bold" }}>
                            <>
                              {category.category?.name}
                              {category.muscle?.name && (
                                <Badge margin="0px .2rem">{category.muscle.name}</Badge>
                              )}
                              {category.action?.name && (
                                <Badge colorScheme="purple">{category.action.name}</Badge>
                              )}
                            </>
                          </h5>
                        </div>
                        <div className={stylesExercise.list}>
                          <p className={stylesExercise.exerciseData}>
                            {category.sets[0].load !== "" && category.sets[0].load !== "0" ? (
                              <>
                                {category.sets.length} Sets x{" "}
                                {category.sets?.map((s) => (
                                  <span>
                                    {s.load}
                                    {s.typeWeight === "others" ? "" : s.typeWeight}/{s.reps}
                                    {s?.typeReps ?? ""},{" "}
                                  </span>
                                ))}
                              </>
                            ) : (
                              <>
                                {category.sets.length} Sets x {category.sets[0].reps}
                                {category.sets[0].typeReps ?? ""}
                              </>
                            )}
                          </p>
                        </div>
                      </article>
                    </Container>
                  )}
                </Draggable>
              ))}
              {dropProvided.placeholder}
            </div>
          </Wrapper>
        )}
      </Droppable>
    </>
  );
};

export default SubItem;
