/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useRef } from "react";

import { useParams } from "react-router-dom";
// ? External Dependencies
import { useTranslation } from "react-i18next";
import {
  Button,
  Menu as MenuLang,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Avatar,
  Text,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  useToast,
  Divider,
  AbsoluteCenter,
  Input,
  Badge,
  IconButton,
} from "@chakra-ui/react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "react-qr-code";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { EditIcon, CloseIcon, CheckCircleIcon } from "@chakra-ui/icons";
// ? local Dependencies
import { getEvaluation } from "services/evaluations";
import InfoIcon from "@mui/icons-material/Info";
import UsaLogo from "assets/eeuu.png";
import SpainLogo from "assets/spain.png";
import useLang from "hooks/useLang";
import languages from "utils/languages";
import moment from "moment/moment";
import i18next from "i18next";
import styles from "./sharedAssessment.module.scss";
import logo from "../../../assets/3.png";

function useComponentVisible(initialIsVisible) {
  const [isComponentVisible, setIsComponentVisible] = useState(initialIsVisible);

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setIsComponentVisible(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  return { ref, isComponentVisible, setIsComponentVisible };
}

const SharedAssessment = () => {
  const { t, lang } = useLang();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);
  const currentYear = new Date().getFullYear();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const onLanguageChange = (code) => {
    i18next.changeLanguage(code);
  };
  const onGetAssessment = async () => {
    try {
      const response = await getEvaluation(id);
      if (response.data) throw new Error("something went wrong");
      setData(response);
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    onGetAssessment();
  }, []);

  const copyClipboard = () => {
    const copyText = document.getElementById("routine-url");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard.writeText(copyText.value);
    toast({
      position: "bottom",
      render: () => (
        <Box color="white" p={3} bg="black" textAlign="center">
          {t("copiedClipboard")}
        </Box>
      ),
    });
  };
  const isEvaluationWithLeg = (exercise) => {
    const exercisesWithoutLeg = ["Push Up", "Recline Pull", "Rotation withour Pivot"];
    return !exercisesWithoutLeg.includes(exercise);
  };
  return (
    <section className={styles.root}>
      <header>
        <div className={styles.header}>
          <div onClick={onOpen}>
            <InfoIcon />
          </div>
          <img src={logo} alt="logo" className={styles.logo} />
          <MenuLang>
            <MenuButton as={Button} variant="ghost" fontSize="40px" padding={0} borderRadius="full">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  name="english lang"
                  src={lang === "en" ? UsaLogo : SpainLogo}
                  width="30px"
                  height="30px"
                />
              </Box>
            </MenuButton>
            <MenuList zIndex="101">
              {languages.map(({ code, name, countryCode }) => (
                <MenuItem key={code} color="ptText.500" onClick={() => onLanguageChange(code)}>
                  <span className={`flag-icon flag-icon-${countryCode}`} />
                  <span>{name}</span>
                </MenuItem>
              ))}
            </MenuList>
          </MenuLang>
        </div>
      </header>

      {data.id_client?.status === "inactive" && (
        <div className={styles.content}>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gridGap={4}>
            <Text textAlign="center" fontSize="2xl" fontWeight="bold">
              {t('title_disable_link')}
            </Text>
            <Text textAlign="center">
              {t('message_disable_link')}
            </Text>
          </Box>
        </div>
      )}

      {data.id_client?.status !== "inactive" && (
        <div className={styles.content}>
          {data.evaluations?.map((evaluation, index) => (
            <div
              className={styles.evaluation}
              key={index}
              style={{ backgroundColor: evaluation.notes.length <= 0 ? "#f3fff4" : "white" }}>
              <div>
                <Heading isTruncated size="md" textAlign="center" mb={4}>
                  {evaluation.exercise[lang]}
                </Heading>
                {isEvaluationWithLeg(evaluation.exercise) && (
                  <>
                    {evaluation.dominant_leg !== "both" ? (
                      <Text textAlign="center">
                        {t("dominant_leg")}{" "}
                        <Badge colorScheme="yellow">{evaluation.dominant_leg}</Badge>
                      </Text>
                    ) : null}
                    <Text textAlign="center">
                      {t("succesful_movement_in_left")}
                      <span>{evaluation.succesfulMovements.leftLeg}</span>
                    </Text>
                    <Text textAlign="center">
                      {t("succesful_movement_in_right")}
                      <span>{evaluation.succesfulMovements.rightLeg}</span>
                    </Text>
                  </>
                )}
              </div>
              <div>
                {evaluation.notes.length > 0 && (
                  <Heading size="md" my={4}>
                    {t("observations")}
                  </Heading>
                )}
                <ul style={{ marginLeft: "1rem" }}>
                  {evaluation.notes.map((note) => (
                    <li>
                      <Text size="md">{note.defaultMsg[lang]}</Text>
                    </li>
                  ))}
                </ul>
                {evaluation.notes.length <= 0 && (
                  <Flex
                    flexDirection="column"
                    justifyContent="center"
                    textAlign="center"
                    alignItems="center"
                    my={4}>
                    <Flex gridGap={4} alignItems="center">
                      <CheckCircleIcon boxSize={4} color="green" />
                      <Heading as="h4" size="md">
                        {t("congratulation")}
                      </Heading>
                    </Flex>

                    <Text>{t("congratulation_assessment")}</Text>
                  </Flex>
                )}
                {Object.values(evaluation.generalNote).some((note) => note !== "") && (
                  <>
                    <Heading size="md" my={4}>
                      {t("general_notes")}
                    </Heading>
                    <Box mb={4}>
                      <ul style={{ marginLeft: "1rem" }}>
                        {Object.values(evaluation.generalNote).map((note) => (
                          <>
                            {note !== "" ? (
                              <li>
                                <Text>{note}</Text>
                              </li>
                            ) : null}
                          </>
                        ))}
                      </ul>
                    </Box>
                  </>
                )}
              </div>
            </div>
          ))}
        </div>
      )}

      <div className={styles.footer}>
        <div className={styles.term}>
          <a href="#a">{t("privacy_policy")}</a>
          <a href="#a">{t("terms_use")}</a>
          <a href="#a">Legal</a>
        </div>
        <span>
          Copyright © {currentYear} The Institute Of Human Performance | {t("copyright_text")}
        </span>
      </div>
      {/*  */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            style={{
              backgroundColor: "white",
              top: "0px",
              right: "0px",
              borderRadius: "50%",
            }}
          />
          <ModalBody my={4}>
            <div className={styles.usersInfo}>
              <div>
                <div className={styles.trainerInfo}>
                  <Avatar
                    size="lg"
                    name={data.id_trainer?.trainer?.name || "PT"}
                    src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${data.id_trainer?.trainer?.pic !== ""
                        ? data.id_trainer?.trainer?.pic
                        : data.id_trainer?.trainer?.logo
                      }`}
                  />
                  <div>
                    <span className={styles.badge}>Trainer</span>
                    <Heading size="md" as="h4">
                      {data.id_trainer?.trainer?.name || ""}
                    </Heading>
                    <Text>{t(data.id_trainer?.trainer?.speciality || "")}</Text>
                  </div>
                </div>
                <Text textAlign="center" fontSize="12px" color="gray">
                  {data.id_trainer?.phone}
                </Text>
                <Text textAlign="center" fontSize="12px" color="gray">
                  {data.id_trainer?.trainer?.address?.city},{" "}
                  {data.id_trainer?.trainer?.address?.state}.{" "}
                  {data.id_trainer?.trainer?.address?.street1}{" "}
                  {data.id_trainer?.trainer?.address?.street2}.{" "}
                  {data.id_trainer?.trainer?.address?.zip}{" "}
                </Text>
              </div>
              <Box position="relative" my={8}>
                <Divider />
                <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs" color="gray">
                  {t("clientInfo")}
                </AbsoluteCenter>
              </Box>
              <div>
                <Heading textAlign="center" size="md" as="h4">
                  {data.id_client?.name} {data.id_client?.lastName}
                </Heading>
                <Text textAlign="center">{data.id_client?.email}</Text>
              </div>
            </div>
            <Box position="relative" my={8}>
              <Divider />
              <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs" color="gray">
                {t("shareProgram")}
              </AbsoluteCenter>
            </Box>
            <Text textAlign="center" my={4} fontSize="xs" colorScheme="gray">
              {t("scanQr")}
            </Text>
            <div style={{ height: "auto", margin: "0 auto", maxWidth: 120, width: "100%" }}>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={`${window.location.protocol}//${window.location.host}/shared-assessment/${id}`}
                viewBox="0 0 256 256"
              />
            </div>
            <Box position="relative" my={6}>
              <Text textAlign="center" bg="white" px="4" fontSize="xs">
                {t("orCopyLink")}
              </Text>
            </Box>
            <Flex gridGap={4} alignItems="center">
              <Input
                disabled
                readOnly
                value={`${window.location.protocol}//${window.location.host}/shared-assessment/${id}`}
                cursor="pointer"
                id="routine-url"
                fontSize="12px"
                onClick={copyClipboard}
              />
              <IconButton
                isRound
                size="sm"
                colorScheme="red"
                variant="ghost"
                onClick={copyClipboard}
                icon={<ContentCopyIcon fontSize="inherit" />}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </section>
  );
};

export default SharedAssessment;
