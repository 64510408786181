import React from 'react';
import { useTranslation } from 'react-i18next';
import Optionimages from './OptionImages';
import styles from './Options.module.scss';

const SelectionTypeHelp = () => {
  const { t } = useTranslation();
  return (
    <div className={styles.containergeneral}>
      <Optionimages url="help/glossary" textButton={t('key_terms')} />
      <Optionimages url="help/steps" textButton={t('user_guide')} pic="user" />
    </div>
  );
};

export default SelectionTypeHelp;
