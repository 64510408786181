/* eslint-disable react/prop-types */
import { Box, Button, ButtonGroup, Heading } from "@chakra-ui/react";
import DownloadIcon from "@mui/icons-material/Download";
import PdfSpanish from "assets/evaluation/Evaluación Funcional Biomecánica IHP.docx";
import PdfEnglish from "assets/evaluation/IHP PT Assessment.docx";
import useLang from "hooks/useLang";

const TypeEvaluationSelector = ({ setType, setStep, setNewMeasurement, setNewGoal }) => {
  const { t, lang } = useLang();
  const handleSelectProgram = (type) => {
    setNewGoal(null);
    setNewMeasurement(null);
    setType(type);
    setStep((prev) => prev + 1);
  };
  const downloadPdf = () => {
    const link = document.createElement("a");
    link.href = lang === "en" ? PdfEnglish : PdfSpanish; // Ruta al archivo PDF en la carpeta public
    link.download =
      lang === "en" ? "IHP PT Assessment.docx" : "Evaluación Funcional Biomecánica IHP.docx"; // Nombre con el que se descargará el archivo
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      <Heading mb={4}>{t("select_type_evaluation")}</Heading>
      <p>{t("select_type_evaluation_desc")}</p>
      <Box display="flex" justifyContent="space-between" flexWrap="wrap">
        <ButtonGroup mt={4} colorScheme="ptRed">
          <Button onClick={() => handleSelectProgram("bio")}>{t("evaluation")}</Button>
          <Button onClick={() => handleSelectProgram("goal")}>{t("goals")}</Button>
        </ButtonGroup>
        <Button
          mt={4}
          leftIcon={<DownloadIcon fontSize="inherit" />}
          variant="solid"
          colorScheme="ptRed"
          onClick={() => downloadPdf()}>
          {t("assessments_download")}
        </Button>
      </Box>
    </>
  );
};
export default TypeEvaluationSelector;
