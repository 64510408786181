/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import {
  Button, ButtonGroup, Flex, Text,
} from '@chakra-ui/react';
import { ArrowUpIcon } from '@chakra-ui/icons';
import useUploadFileS3 from 'hooks/useUploadFileS3';

import propTypes from 'prop-types';

import useAdd from 'hooks/useAdd';
import { transformHelpData } from 'utils/transformData';
import { createHelp } from 'services/helps';
import notificationDisplay from 'utils/notificationDisplay';

import { infoStyle } from 'theme/commonStyles';

import TextInput from '../common/TextInput';

const groupStyle = {
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'flex-end',
  margin: '1rem 0',
};

const AddWord = ({ close, category }) => {
  const [imgArr, setImgArr] = useState([]);
  const [loading, setLoading] = useState(false);
  const { control, handleSubmit, register } = useForm({
    mode: 'onChange',
  });
  const uploadFileS3 = useUploadFileS3();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    mutate, isLoading, isSuccess, isError,
  } = useAdd('helps', createHelp);
  const lang = window.localStorage.getItem('i18nextLng');
  const handleImageClick = () => document.querySelector('.file').click();

  const onChange = async (data) => {
    const file = document.querySelector('.file');
    let response = '';
    if (data.images[0]) {
      setLoading(true);
      response = await uploadFileS3.mutateAsync(data.images[0], data.name);
      if (response.location) {
        notificationDisplay(dispatch, 'Your image have been saved', 'success');
        setImgArr((prev) => prev.concat(response.location));
      }
      file.value = '';
      setLoading(false);
    }
  };

  const onSubmit = async (data) => {
    const alterData = data;
    alterData.category = category;
    mutate(transformHelpData(alterData, imgArr, lang));
  };
  if (isLoading) return <p style={infoStyle}>Updating Data</p>;
  if (isError) return <p style={infoStyle}>Something went wrong</p>;
  if (isSuccess) return <p style={infoStyle}>Data was updated succesfully</p>;
  return (
    <form onSubmit={handleSubmit(onSubmit)} onChange={handleSubmit(onChange)}>
      <fieldset>
        <Flex align="center" justify="center" as="fieldset">
          <Flex align="center" justify="center" direction="column">
            <Text>
              You have uploaded
              {' '}
              {imgArr.length}
            </Text>
            <Button
              colorScheme="ptRed"
              my="1rem"
              rightIcon={<ArrowUpIcon />}
              onClick={handleImageClick}
              isLoading={loading}
            >
              Upload New Image
            </Button>
            <input
              accept="image/*"
              className="file"
              type="file"
              {...register('images')}
              style={{ display: 'none' }}
            />
          </Flex>
        </Flex>

        <TextInput
          control={control}
          name="title"
          place={t('title')}
        />

        <TextInput
          control={control}
          type="textarea"
          name="description"
          place={t('description')}
        />
        <TextInput
          control={control}
          name="url"
          place={t('video')}
          required={false}
        />
      </fieldset>
      <ButtonGroup style={groupStyle} colorScheme="ptRed">
        <Button onClick={() => close()}>{t('cancel')}</Button>
        <Button type="submit">{t('save')}</Button>
      </ButtonGroup>
    </form>
  );
};

AddWord.propTypes = {
  close: propTypes.func.isRequired,
  category: propTypes.string.isRequired,
};

export default AddWord;
