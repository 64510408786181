/* eslint-disable react/no-array-index-key */
/* eslint-disable no-unused-vars */
import React, { useState, useReducer, useMemo, useEffect } from "react";

import { useDispatch, useSelector } from "react-redux";
import SearchClient from "components/common/SearchClient";

import { getEvaluationsTrainer, getGoalsByUser, startBio, updateBio } from "services/evaluations";
import notificationDisplay from "utils/notificationDisplay";
import { Box, Heading, Button, Flex, Switch, FormControl, FormLabel } from "@chakra-ui/react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import EditIcon from "@mui/icons-material/Edit";
import useLang from "hooks/useLang";
import { useHistory, useParams } from "react-router-dom";
import dayjs from "dayjs";
import Report from "./Report";
import Selector from "./Selector";
import Welcome from "./Welcome";

import evaluationReducer, {
  initialState,
  resetEvaluation,
  setEvaluation,
} from "./evaluationReducer";
import ProgramCreation from "./ProgramCreation";
import styles from "./Welcome.module.scss";
import TypeProgramSelector from "./TypeProgramSelector";
import TypeEvaluationSelector from "./typeEvaluation";
import GoalForm from "./goals/goalForm";
import GoalsAndMeasurements from "./goals/goalsAndMeasurements";
import GoalAndMeasurementsReport from "./goals/goalAndMeasurementsReport";
import CreateNewOrGuestClient from "./createNewOrGuestClient";

const Container = () => {
  const [step, setStep] = useState(1);
  const [type, setType] = useState("");
  const [client, setClient] = useState("");
  const [evaluations, setEvaluations] = useState([]);
  const [goals, setGoals] = useState([]);
  const [newGoal, setNewGoal] = useState(null);
  const [newMeasurement, setNewMeasurement] = useState(null);
  const [loadingEvaluations, setLoadingEvaluations] = useState(true);
  const [program, setProgram] = useState("");
  const [isEdit, setIsEdit] = useState(true);
  const [isNewUser, setIsNewUser] = useState(false);
  const [evaluationId, setEvaluationId] = useState("");
  const [state, dispatch] = useReducer(evaluationReducer, initialState);
  const notiDispatch = useDispatch();
  const history = useHistory();
  const { t } = useLang();
  const { client_id } = useParams();
  const userData = useSelector(({ user }) => user);

  const createAssessment = () => {
    setStep((prev) => prev + 1);
    setClient("");
    setNewGoal(null);
    setNewMeasurement(null);
    setIsEdit(true);
    setEvaluationId("");
    dispatch(setEvaluation(initialState));
  };
  const saveClientEvaluation = async () => {
    console.log(state);
    const biomechanical = {
      id_trainer: userData.id_user,
      id_client: client_id ? client_id.split("___")[1] : client.split("___")[1],
      date: dayjs(new Date()).set("MM-DD-YYYY"),
      evaluations: state,
    };
    try {
      const res = await startBio(biomechanical);
      if (res.data) throw new Error(res.data.message);
      setEvaluationId(res._id);
      notificationDisplay(notiDispatch, t("bio_start"), "success");
    } catch (exception) {
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };
  const updateClientEvaluation = async () => {
    const biomechanical = {
      evaluations: state?.filter((item) => item.notes.length > 0),
    };
    try {
      const res = await updateBio(evaluationId, biomechanical);
      if (res.data) throw new Error(res.data.message);
      notificationDisplay(notiDispatch, t("bio_start"), "success");
    } catch (exception) {
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };
  const handleSubmit = () => {
    if (evaluationId === "") saveClientEvaluation();
    else updateClientEvaluation();
  };
  const getEvaluations = async () => {
    try {
      const res = await getEvaluationsTrainer(userData.id_user);
      const resGoals = await getGoalsByUser(userData._id);
      if (res.data) throw new Error(res.data.message);
      setEvaluations(res);
      setGoals(resGoals);
      setLoadingEvaluations(false);
    } catch (exception) {
      setLoadingEvaluations(false);
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };
  const pageName = () => {
    switch (step) {
      case 2: {
        return t("main");
      }
      case 3: {
        return t("selectClient");
      }
      case 4: {
        return t("selectType");
      }
      case 5: {
        return t("main");
      }
      case 6: {
        return t("report");
      }
      case 7: {
        return t("typeProgram");
      }
      default:
        return "";
    }
  };
  const goBack = () => {
    switch (step) {
      case 2: {
        setStep(1);
        dispatch(setEvaluation([]));
        getEvaluations();
        break;
      }
      case 4: {
        const leavePage = window.confirm(t("back_confirmation"));
        if (leavePage) {
          setStep(step - 1);
        }

        break;
      }
      case 5: {
        setStep(1);
        dispatch(setEvaluation([]));
        getEvaluations();
        break;
      }
      case 7: {
        const leavePage = window.confirm(t("back_confirmation"));
        if (leavePage) {
          setStep(step - 1);
        }
        break;
      }
      default: {
        setStep(step - 1);
      }
    }
    /* if (step === 3 || step === 6) {
      const leavePage = window.confirm(t("back_confirmation"));
      if (leavePage) {
        if (step === 6) setStep(step - 1);
        else setStep(1);
        dispatch(setEvaluation([]));
        getEvaluations();
      }
    } else {
      setStep(step - 1);
    } */

    // history.push("/user/evaluation");
  };

  useEffect(() => {
    document.title = "Perfect Trainer App | Evaluation";
    if (client_id) {
      setClient(client_id);
      setStep(3);
      setIsEdit(false);
    }
    getEvaluations();
  }, []);

  return (
    <>
      {step !== 1 && step !== 5 && (
        <div className={styles.back}>
          <Button
            size="sm"
            color="gray"
            colorScheme="gray"
            variant="outline"
            onClick={() => goBack()}
            leftIcon={<ArrowBackIosIcon fontSize="inherit" />}>
            {t("backBtn")}
          </Button>
          <Heading size="md">{t("evaluation")}</Heading>
        </div>
      )}

      {step === 1 ? (
        <>
          <Flex justifyContent="flex-end">
            <Button
              onClick={() => createAssessment()}
              type="button"
              colorScheme="ptRed"
              mb={4}
              loadingText={t("creating_bio")}>
              {t("start_evaluation")}
            </Button>
          </Flex>
          <Welcome
            set={setStep}
            dispatch={dispatch}
            setClient={setClient}
            setEvaluations={setEvaluations}
            setType={setType}
            setIsEdit={setIsEdit}
            loadingEvaluations={loadingEvaluations}
            evaluations={evaluations}
            setEvaluationId={setEvaluationId}
            goals={goals}
            setGoals={setGoals}
            setNewGoal={setNewGoal}
            setNewMeasurement={setNewMeasurement}
          />
        </>
      ) : null}
      {step === 2 ? (
        <>
          <FormControl display="flex" alignItems="center" my={4}>
            <Switch
              isChecked={isNewUser}
              id="email-alerts"
              onChange={(e) => setIsNewUser(e.target.checked)}
            />
            <FormLabel htmlFor="email-alerts" mb="0" mx={4}>
              {t("guestOrNew")}
            </FormLabel>
          </FormControl>
          {!isNewUser ? (
            <SearchClient setClient={setClient} setStep={setStep} />
          ) : (
            <CreateNewOrGuestClient setClient={setClient} setStep={setStep} />
          )}
        </>
      ) : null}
      {step === 3 ? (
        <TypeEvaluationSelector
          setType={setType}
          setStep={setStep}
          setNewMeasurement={setNewMeasurement}
          setNewGoal={setNewGoal}
        />
      ) : null}
      {step === 4 &&
        (type === "bio" ? (
          <div id="board">
            <Selector
              setStep={setStep}
              state={state}
              dispatch={dispatch}
              evaluations={evaluations}
              saveClientEvaluation={handleSubmit}
              setType={setType}
            />
          </div>
        ) : (
          <div id="board">
            <GoalsAndMeasurements
              goal={newGoal}
              measurement={newMeasurement}
              setNewGoal={setNewGoal}
              setStep={setStep}
              setNewMeasurement={setNewMeasurement}
              client={client}
            />
          </div>
        ))}
      {step === 5 &&
        (type === "bio" ? (
          <Report
            isEdit={isEdit}
            client={client}
            evaluations={state}
            evaluationId={evaluationId}
            dispatch={dispatch}
            setStep={setStep}
            saveClientEvaluation={handleSubmit}
          />
        ) : (
          <GoalAndMeasurementsReport
            client={client}
            measurement={newMeasurement}
            isEdit={isEdit}
            goal={newGoal}
            setStep={setStep}
          />
        ))}
      {step === 6 && <TypeProgramSelector setProgram={setProgram} setStep={setStep} />}
      {step === 7 && (
        <div id="board">
          <ProgramCreation program={program} client={client} setStep={setStep} />
        </div>
      )}
    </>
  );
};

export default Container;
