/* eslint-disable react/prop-types */
import { Button, ButtonGroup, Heading } from "@chakra-ui/react";
import useLang from "hooks/useLang";

const TypeProgramSelector = ({ setProgram, setStep }) => {
  const { t } = useLang();
  const handleSelectProgram = (program) => {
    setProgram(program);
    setStep((prev) => prev + 1);
  };
  return (
    <>
      <Heading mb={4}>{t("create_program")}</Heading>
      <p>{t("program_selection_info")}</p>
      <ButtonGroup mt={4} colorScheme="ptRed">
        <Button onClick={() => handleSelectProgram("functional")}>{t("functional_program")}</Button>
        <Button onClick={() => handleSelectProgram("hybrid")}>{t("hybrid_program")}</Button>
      </ButtonGroup>
    </>
  );
};
export default TypeProgramSelector;
