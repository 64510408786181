/* eslint-disable react/prop-types */
import React, { useState } from "react";
import { AbsoluteCenter, Badge, Box, Divider, Heading } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";

import Carousel from "components/common/Carousel";

const tagStyle = {
  color: "#d9ad42",
  fontWeight: 700,
  marginRight: "0.5rem",
};

const Info = ({ exercise }) => {
  const [carouselStep, setCarouselStep] = useState(0);
  const lang = window.localStorage.getItem("i18nextLng");
  const { t } = useTranslation();

  const handleCarouselClick = (movement) => {
    setCarouselStep((prev) => (movement === "prev" ? prev - 1 : prev + 1));
  };

  return (
    <>
      <Carousel step={carouselStep} images={exercise.images} click={handleCarouselClick} />
      <Heading as="h4" size="md" my={4}>
        {exercise?.name?.[lang] || ""}
      </Heading>

      <Box position="relative" padding="8">
        <Divider />
        <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="12px" color="gray">
          {t("instructions")}
        </AbsoluteCenter>
      </Box>
      <ol style={{ fontSize: "0.8rem", padding: "0.5rem 1rem 0.5rem 2rem" }}>
        {exercise?.instructions?.[lang]
          .filter((instruction) => instruction !== "")
          .map((instruction) => (
            <li key={instruction}>{instruction}</li>
          ))}
      </ol>

      {exercise?.notes_exercise?.[lang] && (
        <>
          <Box position="relative" padding="8">
            <Divider />
            <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="12px" color="gray">
              {t("notes_exercise")}
            </AbsoluteCenter>
          </Box>
          <ol style={{ fontSize: "0.8rem", padding: "0.5rem 1rem 0.5rem 2rem" }}>
            {exercise.notes_exercise[lang]}
          </ol>
        </>
      )}
      <Box position="relative" padding="4">
        <Divider />
        <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="12px" color="gray">
          {t("muscle_system")}
        </AbsoluteCenter>
      </Box>
      {exercise?.muscle_system
        ?.filter((muscle) => muscle !== "")
        .map((muscle) => (
          <Badge colorScheme="gray" mr="0.5rem" key={muscle}>
            {t(muscle)}
          </Badge>
        ))}
      <Box position="relative" padding="4">
        <Divider />
        <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="12px" color="gray">
          {t("equipment")}
        </AbsoluteCenter>
      </Box>
      {exercise?.equipment?.map((eq) => (
        <Badge colorScheme="gray" key={eq}>
          {t(eq)}
        </Badge>
      ))}

      {/*  <Carousel step={carouselStep} images={exercise.images} click={handleCarouselClick} />
      <p style={{ marginTop: "0.5rem" }}>
        <span style={tagStyle}>{t("categories")}</span>
        {exercise.category_id.map(({ _id, name }) => (
          <Badge colorScheme="yellow" mr="0.5rem" key={_id}>
            {t(name)}
          </Badge>
        ))}
      </p> */}
      {/* <p>
        <span style={tagStyle}>{t("abbrevations")}</span>
        {typeof exercise.abbreviations[lang] !== "undefined" &&
          exercise.abbreviations[lang].map((abbr) => (
            <Badge colorScheme="gray" mr="0.5rem" key={abbr}>
              {t(abbr)}
            </Badge>
          ))}
      </p> */}
      {/*  <p>
        <span style={tagStyle}>{t("muscle_system")}</span>
        {exercise.muscle_system.map((muscle) => (
          <Badge colorScheme="gray" mr="0.5rem" key={muscle}>
            {t(muscle)}
          </Badge>
        ))}
      </p>
      <p>
        <span style={tagStyle}>{t("action")}</span>
        {exercise.action.map((ac) => (
          <Badge colorScheme="gray" mr="0.5rem" key={ac}>
            {t(ac)}
          </Badge>
        ))}
      </p>
      <p>
        <span style={tagStyle}>{t("equipment")}</span>
        {exercise.equipment.map((eq) => (
          <Badge colorScheme="gray" key={eq}>
            {t(eq)}
          </Badge>
        ))}
      </p>
      <p style={tagStyle}>{t("instructions")}</p>
      <ol style={{ fontSize: "0.8rem", padding: "0.5rem 1rem 0.5rem 2rem" }}>
        {exercise.instructions[lang]
          .filter((instruction) => instruction !== "")
          .map((instruction) => (
            <li key={instruction}>{instruction}</li>
          ))}
      </ol> */}
    </>
  );
};

export default Info;
