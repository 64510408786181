/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import { Box, Heading, Text } from "@chakra-ui/react";
import useLang from "hooks/useLang";
import React from "react";
import HistoryClientDays from "./HistoryClientDays";

const HistoryClientColumn = ({ weeks, index }) => {
  const { t } = useLang();
  return (
    <Box p="10px" m="5px 10px" display="flex" width="320px" flexDirection="column">
      <Box mb="15px" width="320px">
        <Box
          borderRadius="20px"
          bg="#3a3a3a"
          p=".5rem 1rem"
          display="flex"
          justifyContent="space-between"
          alignItems="center">
          <Box>
            <Heading fontSize="xl" fontWeight="medium" color="white">
              {t("list_week")} {index + 1}
            </Heading>
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" width="320px" mt={5}>
          <Box maxHeight="700px" overflowY="auto" overflowX="hidden">
            {weeks?.map((day) => (
              <HistoryClientDays days={day} />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HistoryClientColumn;
