/* eslint-disable no-unused-vars */
/* eslint-disable arrow-body-style */
import { useMutation } from "react-query";
import S3 from "react-aws-s3";
import { uploadFile } from "services/files";
import { useDispatch } from "react-redux";
import { addPicture } from "../reducers/picture.reducer";

const config = {
  bucketName: "ptrainer-bucket",
  dirName: "images" /* optional */,
  region: "us-east-1",
  accessKeyId: "AKIAY7HAEHEIRQF5VPFZ",
  secretAccessKey: "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",
  s3Url: "https://ptrainer-bucket.s3.amazonaws.com",
};

const ReactS3Client = new S3(config);

/* This is optional */
// const newFileName = 'test-file';

export default function useUploadFileS3() {
  return useMutation((file) => uploadFile(file), {
    onSuccess: (data) => {
      console.log("Update Here: ", data);
      return data;
    },
    onError: (error, variables) => {
      // console.log('Error Here: ', error);
      // console.log('variables Here: ', variables);
    },
  });
}
