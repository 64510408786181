import React from "react";
import { bigContainerStyle } from "theme/commonStyles";
import Container from "./Container";

const Evaluation = () => (
  <div style={bigContainerStyle}>
    <Container />
  </div>
);

export default Evaluation;
