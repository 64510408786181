/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";

import notificationDisplay from "utils/notificationDisplay";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import RoutineInfo from "components/routines/workout/Features/RoutineInfo";
import { updateRoutine } from "services/customWorkout";

const ModalEditRoutine = ({ isOpen, onClose, data, handleEdit }) => {
  const [name, setName] = useState(data.workout_name.en);
  const [phase, setPhase] = useState(data.phase);
  const { id_user } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleSave = async () => {
    const prevState = { ...data, workout_name: { en: name, es: name }, phase };
    try {
      const response = await updateRoutine(data._id, {
        workout_name: { en: name, es: name },
        phase,
      });
      if (response.data) throw new Error(response.data.error);
      handleEdit(prevState);
      onClose();
    } catch (error) {
      notificationDisplay(dispatch, error.message, "error");
      return console.log(error);
    }
  };

  return (
    <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("rename_program")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w={["100%", "50%"]}>
            <RoutineInfo setPhase={setPhase} phase={phase} setName={setName} name={name} />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleSave()} colorScheme="ptRed">
            {t("save")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalEditRoutine;
