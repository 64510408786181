import React from "react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import propTypes from "prop-types";

import useLang from "hooks/useLang";

const Type = ({ setMode }) => {
  const { t } = useLang();
  return (
    <ButtonGroup
      alignItems="flex-end"
      backgroundColor="ptGray.500"
      border="1px solid black"
      borderRadius="10px"
      colorScheme="ptRed"
      display="flex"
      height="350px"
      isAttached
      padding="1rem">
      <Button onClick={() => setMode("template")}>{t("template_workout")}</Button>
      <Button onClick={() => setMode("preprograms")}>{t("preprogrammed_workout")}</Button>
    </ButtonGroup>
  );
};

Type.propTypes = {
  setMode: propTypes.func.isRequired,
};

export default Type;
