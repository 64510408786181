/* eslint-disable react/prop-types */
// ? Dependencies
import React from 'react';
// import { CopyIcon } from '@chakra-ui/icons';
// import { Button } from '@chakra-ui/react';

// ? Hooks
// import useLang from 'hooks/useLang';
import Board from './components/Board';

const SuperSet = ({
  setSuperSetState,
  superSetState,
  setColumns,
}) => {
  const initial = superSetState.data.map((s) => s.exercises);
  const setSuperColumns = (columnsModified) => {
    setColumns((prev) => prev.map((item, idx) => (idx === superSetState.position
      ? item.map((sub, id) => ({ ...sub, exercises: columnsModified[id] }))
      : item)));
  };
  return (
    <Board
      initial={initial}
      setSuperSetState={setSuperSetState}
      setSuperColumns={setSuperColumns}
    />
  );
};

export default SuperSet;
