/* eslint-disable max-len */
import React from 'react';
import useGetAll from 'hooks/useGetAll';
import { getClientRoutines } from 'services/customWorkout';
import { string } from 'prop-types';
import { container } from './ClientHistory.module.css';
// import DayDisplay from '../DayDisplay';
import NoProgramsDisplay from '../Features/NoProgramsDisplay';
// import ClientRoutineProvider from '../ClientRoutineContext';
import HistoryClientRoutineProvider from '../ClientRoutineContext/HistoryClientRoutine';

const ClientHistory = ({ idClient }) => {
  const { data, isLoading, isError } = useGetAll('routines', idClient, getClientRoutines);

  if (isLoading) return <p>Loading data</p>;
  if (isError) return <p>Smething wrong</p>;
  if (data.length === 0) return <NoProgramsDisplay idClient={idClient} message="No Program added yet" />;

  return (
    <div className={container}>
      {/* {
        data?.map((day) => day?.sessions?.map((info) => (
          <DayDisplay day={info} trainer={day.id_trainer.name} isEdit={false} />
        )))
      } */}
      {
        data?.map((day) => <HistoryClientRoutineProvider data={day.sessions} isEdit={false} trainer={day?.id_trainer?.name} />)
      }
    </div>
  );
};

ClientHistory.propTypes = {
  idClient: string.isRequired,
};

export default ClientHistory;
