/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import moment from "moment";
import useLang from "hooks/useLang";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  Input,
  Textarea,
  InputGroup,
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  FormControl,
  FormLabel,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Heading,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { uiCloseModal } from "../../actions/ui";
import {
  eventClearActiveEvent,
  eventStartAddNew,
  eventStartDelete,
  eventStartUpdate,
} from "../../actions/events";
import style from "./Calendar.module.scss";

const COLORS_EVENT = [
  "rgb(213, 0, 0)",
  "rgb(230, 124, 115)",
  "rgb(244, 81, 30)",
  "rgb(246, 191, 38)",
  "rgb(51, 182, 121)",
  "rgb(11, 128, 67)",
  "rgb(3, 155, 229)",
  "rgb(63, 81, 181)",
  "rgb(121, 134, 203)",
  "rgb(142, 36, 170)",
  "rgb(97, 97, 97)",
];

const now = moment().minutes(0).seconds(0).add(1, "hours");
// const nowEnd = moment(now).add(1, 'hours');
const nowPlus1 = now.clone().add(1, "hours");

const initEvent = {
  title: "",
  notes: "",
  start: now.toDate(),
  end: nowPlus1.toDate(),
};

const CalendarModal = ({ client, isEdit = false }) => {
  const { modalOpen } = useSelector((state) => state.ui);
  const { lang, t } = useLang();
  const { activeEvent, eventSetSlotEmpty } = useSelector((state) => state.calendar);
  const dispatch = useDispatch();

  const [dateStart, setDateStart] = useState(now.toDate());
  const [dateEnd, setDateEnd] = useState(nowPlus1.toDate());
  const [titleValid, setTitleValid] = useState(true);
  const [eventColor, setEventColor] = useState("rgb(213, 0, 0)");

  const [formValues, setFormValues] = useState(initEvent);

  const { notes, title, start, end } = formValues;

  useEffect(() => {
    if (activeEvent) {
      setFormValues(activeEvent);
      setEventColor(activeEvent.backgroundColor);
    } else {
      let initOtherDate = null;
      console.log(eventSetSlotEmpty);
      if (eventSetSlotEmpty) {
        initOtherDate = {
          ...initEvent,
          start: eventSetSlotEmpty.start ? eventSetSlotEmpty.start : eventSetSlotEmpty.date,
          end: eventSetSlotEmpty.end ? eventSetSlotEmpty.end : eventSetSlotEmpty.date,
        };
      }
      setFormValues(initOtherDate || initEvent);
    }
  }, [activeEvent, setFormValues, eventSetSlotEmpty, setDateStart, setDateEnd]);

  const handleInputChange = ({ target }) => {
    setFormValues({
      ...formValues,
      [target.name]: target.value,
    });
  };

  const closeModal = () => {
    // todo : cerrar el modal
    dispatch(uiCloseModal());
    dispatch(eventClearActiveEvent());
    setFormValues(initEvent);
  };

  const handleStartDateChange = (e) => {
    setDateStart(moment(e.target.value));
    setFormValues({
      ...formValues,
      start: moment(e.target.value),
    });
  };

  const handleEndDateChange = (e) => {
    setDateEnd(moment(e.target.value));
    setFormValues({
      ...formValues,
      end: moment(e.target.value),
    });
  };
  const handleSubmitForm = (e) => {
    e.preventDefault();

    const momentStart = moment(start);
    const momentEnd = moment(end);

    /*  if (momentStart.isSameOrAfter(momentEnd)) {
      return Swal.fire("Error", "La fecha fin debe de ser mayor a la fecha de inicio", "error");
    } */
    if (title.trim().length < 2) {
      return setTitleValid(false);
    }
    const data = {
      ...formValues,
      allDay: true,
      backgroundColor: eventColor,
      borderColor: eventColor,
      client,
    };

    if (activeEvent) {
      data.end = moment(data.end).add(24, "h");
      dispatch(eventStartUpdate(data));
    } else {
      data.end = moment(data.end).add(24, "h");
      dispatch(eventStartAddNew(data));
    }
    setTitleValid(true);
    closeModal();
  };
  const handleDelete = () => {
    dispatch(eventStartDelete());

    closeModal();
  };
  return (
    <Modal isOpen={modalOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{activeEvent ? "" : t("new_event")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {isEdit ? (
            <form onSubmit={handleSubmitForm} className="container">
              <FormControl my={4}>
                <FormLabel>{t("date_start")}</FormLabel>
                <Input
                  onChange={handleStartDateChange}
                  value={moment(start || dateStart).format("YYYY-MM-DD")}
                  type="date"
                  variant="filled"
                />
              </FormControl>

              <FormControl my={4}>
                <FormLabel>{t("date_end")}</FormLabel>
                <Input
                  onChange={handleEndDateChange}
                  value={moment(end || dateEnd).format("YYYY-MM-DD")}
                  min={moment(start || dateStart).format("YYYY-MM-DD")}
                  type="date"
                  variant="filled"
                />
              </FormControl>

              <hr />
              <FormControl my={4}>
                <FormLabel>{t("title_notes")}</FormLabel>
                <Input
                  variant="filled"
                  type="text"
                  placeholder={t("title_event")}
                  name="title"
                  autoComplete="off"
                  value={title}
                  onChange={handleInputChange}
                />
                <Textarea
                  variant="filled"
                  my={4}
                  placeholder={t("notes")}
                  rows="5"
                  name="notes"
                  value={notes}
                  onChange={handleInputChange}
                />
              </FormControl>
              <FormControl>
                <Menu>
                  <MenuButton
                    as={Button}
                    aria-label="Options"
                    rightIcon={<KeyboardArrowDownIcon />}>
                    <span className={style.dotColor} style={{ backgroundColor: eventColor }} />
                  </MenuButton>
                  <MenuList zIndex="99999" minW="7rem" w="7rem" display="flex" flexWrap="wrap">
                    {COLORS_EVENT.map((color, index) => (
                      <MenuItem
                        key={index}
                        flex="1 1 50%"
                        onClick={() => {
                          setEventColor(color);
                        }}>
                        <span className={style.dotColor} style={{ backgroundColor: color }} />
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </FormControl>
              <Flex justifyContent={activeEvent ? "space-between" : "end"} mt={8}>
                {activeEvent && (
                  <Button onClick={handleDelete} leftIcon={<DeleteIcon />}>
                    {t("deleteEvent")}
                  </Button>
                )}
                <Button type="submit" colorScheme="ptRed" disabled={title === ""}>
                  {t("save")}
                </Button>
              </Flex>
            </form>
          ) : (
            <>
              <Heading>{title}</Heading>
              <Text fontSize="sm" color="gray">
                {moment(start || dateStart).format(lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY")} to{" "}
                {moment(end || dateEnd)
                  .subtract(1, "d")
                  .format(lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY")}
              </Text>
              <Text>{notes}</Text>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CalendarModal;
