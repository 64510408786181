import instance from "./_axiosConfig";

const api = instance;

const saveToDo = async (body) => {
  try {
    const response = await api.post(`/todo/`, body);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const updateToDo = async (id, body) => {
  try {
    const response = await api.put(`/todo/${id}`, body);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
const deleteToDo = async (id) => {
  try {
    const response = await api.delete(`/todo/${id}`);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
const getToDoByUser = async (id) => {
  try {
    const response = await api.get(`/todo/user/${id}`);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export { saveToDo, updateToDo, deleteToDo, getToDoByUser };
