import React from "react";
import { Switch, Route, useRouteMatch, Redirect } from "react-router-dom";

import Login from "./login";
import Signup from "./signup";
import ChangePass from "./changePass";
import SubscriptionDone from "./subscriptionDone";
import SharedRoutine from "./sharedRoutine";
import SharedAssessment from "./sharedAssessment";
import SharedPlanning from "./sharedPlanning";
import SharedNutrition from "./sharedNutrition";
// import Principal from './principal';

const Main = () => {
  const { path } = useRouteMatch();
  return (
    <div>
      <Switch>
        <Route exact path={path}>
          <Redirect to="login" />
        </Route>
        <Route path={`${path}login`} component={Login} />
        <Route path={`${path}signup`} component={Signup} />
        <Route path={`${path}changepass`} component={ChangePass} />
        <Route path={`${path}subscription-done`} component={SubscriptionDone} />
        <Route path={`${path}shared-routine/:id`} component={SharedRoutine} />
        <Route path={`${path}shared-assessment/:id`} component={SharedAssessment} />
        <Route path={`${path}shared-planning/:id`} component={SharedPlanning} />
        <Route path={`${path}shared-nutrition/:id`} component={SharedNutrition} />
      </Switch>
    </div>
  );
};

export default Main;
