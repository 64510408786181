/* eslint-disable no-unused-vars */
import React, { useState, useRef, useReducer, useEffect } from "react";
import { shape, number } from "prop-types";
import { useHistory } from "react-router-dom";
import useLang from "hooks/useLang";
import Board from "./components/Board";

const CreatePreprogrammed = ({ actual, date, data, currentName, filterOptions }) => {
  const [filtered, setFiltered] = useState([]);
  const history = useHistory();
  const { t } = useLang();
  const inicialValue = data?.sessions?.length >= 1 ? data?.sessions : [];

  useEffect(() => {
    if (inicialValue?.length >= 1) {
      const daysField = inicialValue.map((i) => {
        const add = {
          ...i,
          id: i._id,
          author: {
            id: i._id,
            name: i.week,
            colors: {
              soft: "white",
              hard: "white",
            },
          },
        };
        return add;
      });
      const FormatDays = [...daysField];
      const array = FormatDays?.map(({ week }) => week);
      const newarray = [...new Set(array)];
      const dataFilter = newarray?.map((item) =>
        daysField?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
      );
      setFiltered(dataFilter);
    }
  }, []);
  const goToPreviousScreen = (button) => {
    if (button === 1) {
      history.back(); // go to previous screen, if presses "Yes"
    }
    return false; // No action, if presses "cancel"
  };
  useEffect(() => {
    const sidebar = document.getElementsByClassName("sidebar")[0];
    window.onbeforeunload = (e) => {
      console.log(e);
      return "Leaving this page will reset the wizard";
    };
    window.history.pushState(null, document.title, window.location.href);
    window.onpopstate = () => {
      const leavePage = window.confirm(t("back_confirmation"));
      if (leavePage) {
        window.onpopstate = null;
        window.history.back();
      } else {
        window.history.pushState(null, document.title, window.location.href);
      }
    };
    if (sidebar) sidebar.setAttribute("class", "sidebar collapse");
    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
      if (sidebar) sidebar.setAttribute("class", "sidebar");
    };
  }, []);

  return (
    <div id="board">
      <Board
        initial={filtered}
        withScrollableColumns
        filterOptions={filterOptions}
        currentName={currentName}
        dataEdit={data}
        idPreprogrammed={data?._id}
        setFiltered={setFiltered}
      />
    </div>
  );
};

CreatePreprogrammed.defaultProps = {
  data: undefined,
};

CreatePreprogrammed.propTypes = {
  date: shape({
    day: number.isRequired,
    week: number.isRequired,
  }).isRequired,
  actual: number.isRequired,
  data: shape({}),
  filterOptions: shape([]).isRequired,
  currentName: shape([]).isRequired,
};

export default CreatePreprogrammed;
