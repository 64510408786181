/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect, useState, useRef } from "react";
// ? Dependecies
import { bool, shape, string, func } from "prop-types";
import { Redirect } from "react-router";
import { Link, useHistory } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import styled from "styled-components";
import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import CopyIcon from "@material-ui/icons/FileCopy";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
// ? helpers and Services and custom Hooks
import generateId from "helpers/generateId";
import { createRoutine } from "services/customWorkout";
import notificationDisplay from "utils/notificationDisplay";
import useLang from "hooks/useLang";
// ? Components or others
import Column from "./Column";
import reorder, { reorderColumns, reorderExercisesMap, reorderQuoteMap } from "./reorder";
import styles from "../../../../clients/ClientDashboard/ClientRoutineDisplay/ClientRoutineDisplay.module.css";
import Combine from "./Combine";
import SuperSet from "../../SuperSet";
import RoutineInfo from "../../Features/RoutineInfo";

const Board = ({
  isCombineEnabled,
  initial,
  containerHeight,
  withScrollableColumns,
  // name,
  // phase,
  client,
  setFiltered,
  setIsCreate,
  clientSource,
  workout,
}) => {
  // ? states
  const [columns, setColumns] = useState(initial);
  const [ordered, setOrdered] = useState(Object.keys(initial));
  const [listDaySelected, setListDaySelected] = useState([]);
  const [cloneState, setCloneState] = useState(false);
  const [cloneUniqueDay, setCloneUniqueDay] = useState([]);
  const [sortWeeks, setSortWeeks] = useState([]);
  const history = useHistory();
  const [superSetState, setSuperSetState] = useState({ data: [], position: 0 });
  const [name, setName] = useState("");
  const scrollHorizontalBoard = useRef(null);
  const [phase, setPhase] = useState("");

  // ? Hooks
  const { t } = useLang();
  const { _id } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const { isOpen: isOpenMore, onOpen: onOpenMore, onClose: onCloseMore } = useDisclosure();

  const handleCancelClone = () => {
    setListDaySelected([]);
    setCloneState(false);
  };

  const handleChangeSelect = (items) => () => {
    // const reset = { ...items, day: 0, _id: undefined };
    setListDaySelected([
      ...listDaySelected,
      {
        ...items,
        author: {
          ...items.author,
        },
        exercises: items.exercises.map((exercise) => ({
          ...exercise,
          _id: generateId(),
        })),
      },
    ]);
  };

  const handleChangeRemove = (item) => () => {
    setListDaySelected((items) => items.filter((i) => i._id !== item._id));
  };

  const onDragEnd = (result) => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    if (result.type === "EXERCISE") {
      const reorderExercises = reorderExercisesMap({
        quoteMap: columns,
        source,
        destination,
      });
      const arrayOfArrays = Object.values(reorderExercises.quoteMap).map((arr) => [...arr]);
      setColumns(arrayOfArrays);
      setOrdered(Object.keys(reorderExercises.quoteMap));
      return;
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      const sortColumns = reorderColumns({
        columnsMap: columns,
        source,
        destination,
      });
      setOrdered(Object.keys(sortColumns.columnsMap));
      setColumns(sortColumns.columnsMap);
      return;
    }

    const data = reorderQuoteMap({
      quoteMap: columns,
      source,
      destination,
    });

    const arrayOfArrays = Object.values(data.quoteMap).map((arr) => [...arr]);
    setColumns(arrayOfArrays);
    setOrdered(Object.keys(data.quoteMap));
  };
  const cloneWeek = (week) => {
    const arrayOfObjects = columns.reduce((acc, val) => acc.concat(val), []);
    const lastWeek = arrayOfObjects[arrayOfObjects.length - 1];
    const changeWeek = week.map((item) => {
      const copy = {
        ...item,
        week: lastWeek.week + 1,
        _id: generateId(),
        id: generateId(),
        exercises: item.exercises.map((i) => {
          const setIdExercise = {
            ...i,
            _id: generateId(),
            sets: i.sets.map((e) => {
              const newId = {
                ...e,
                _id: generateId(),
              };
              return newId;
            }),
          };
          return setIdExercise;
        }),
      };
      return copy;
    });
    console.log(changeWeek);
    const concatNewDay = columns.concat([changeWeek]);
    setColumns(concatNewDay);
    setOrdered(Object.keys(concatNewDay));
  };
  const handleAddWeek = () => {
    const arrayOfObjects = columns.reduce((acc, val) => acc.concat(val), []);
    const lastWeek = arrayOfObjects[arrayOfObjects.length - 1];
    const lastday = columns[columns.length - 1];
    const generateWeek = lastday.map((item) => {
      const setItem = {
        ...item,
        week: lastWeek.week + 1,
        _id: generateId(),
        id: generateId(),
        exercises: item.exercises.map((i) => {
          const setIdExercise = {
            ...i,
            _id: generateId(),
            sets: i.sets.map((e) => {
              const newId = {
                ...e,
                _id: generateId(),
              };
              return newId;
            }),
          };
          return setIdExercise;
        }),
      };
      return setItem;
    });

    const concatNewDay = columns.concat([[]]);
    setColumns(concatNewDay);
    setOrdered(Object.keys(concatNewDay));
    const ContentMainScroll = scrollHorizontalBoard.current;
    const horizontalScroll = ContentMainScroll.scrollWidth;
    setTimeout(() => {
      ContentMainScroll.scrollTo(horizontalScroll, 0);
    }, 100);
  };

  const handleSave = async () => {
    const routine = {
      id_trainer: _id,
      id_client: client.split("___")[1],
      filterOptions: [[], [], []],
      workout_name: { en: name, es: "" },
      source: "pre-programmed",
      preProgrammed: false,
      phase,
      sessions: columns.flat(),
      daet: null,
    };

    try {
      const response = await createRoutine(routine);
      if (response.data) return notificationDisplay(dispatch, "something went wrong", "error");
      setTimeout(() => {
        history.push({
          pathname: "/user/clients",
          state: {
            clientInfo: clientSource,
          },
          programReturn: {
            response: response.customWorkout,
          },
        });
      }, 1000);
    } catch (error) {
      console.log(error.message);
    }
  };

  const validacionSave = () => {
    if (name === "") {
      notificationDisplay(dispatch, "Name is empty", "error");
    }
    if (phase === "") {
      notificationDisplay(dispatch, "Phase is empty", "error");
    }
    if (columns.length <= 0) {
      notificationDisplay(dispatch, t("errors.empty_program"), "error");
    }
  };

  useEffect(() => {
    setColumns(initial);
    setOrdered(Object.keys(initial));
    setSortWeeks(initial.reduce((acc, val) => acc.concat(val), []));
  }, [initial]);

  if (superSetState.data?.length > 0) {
    return (
      <SuperSet
        setSuperSetState={setSuperSetState}
        superSetState={superSetState}
        setColumns={setColumns}
      />
    );
  }
  const goBack = () => {
    const leavePage = window.confirm(t("back_confirmation"));
    if (leavePage) {
      setIsCreate();
    }
  };

  const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
    if (isDraggingOver) return "#FFEBE6";
    if (isDraggingFrom) return "#E6FCF8";
    return "transparent";
  };
  const collapseAndExpandAll = () => {
    const items = Array.from(document.getElementsByClassName("day_content"));
    const spandButton = Array.from(document.getElementsByClassName("spand-button"));
    if (items.length > 0) {
      if (items.some((a) => a.classList.contains("collapsed"))) {
        items.forEach((element) => {
          element.classList.remove("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.remove("btn-collapsed");
        });
      } else {
        items.forEach((element) => {
          element.classList.add("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.add("btn-collapsed");
        });
      }
    }
  };
  const onDragStart = (result) => {
    if (result.type === "DAYS") {
      const items = Array.from(document.getElementsByClassName("day_content"));
      const spandButton = Array.from(document.getElementsByClassName("spand-button"));
      if (items.length > 0) {
        items.forEach((element) => {
          element.classList.add("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.add("btn-collapsed");
        });
      }
    }
  };

  return (
    <>
      <Box>
        <Flex mb="1rem" w="100%" justifyContent="space-between" alignItems="center">
          <Button
            onClick={() => goBack()}
            colorScheme="gray"
            variant="outline"
            size="xs"
            leftIcon={<ArrowBackIosNewIcon fontSize="inherit" />}
            color="gray">
            {t("backBtn")}
          </Button>
          <Text fontSize="sm">
            {t("creating_program")}
            <span
              style={{
                color: "#b42519",
                fontWeight: "bold",
              }}>
              {` ${clientSource.name || client.split("___")[0]} ${
                (clientSource?.lastName || client.split("___")[2]) ?? ""
              }`}
            </span>
          </Text>
          <div />
        </Flex>
        <Flex gridGap="1rem" alignItems="flex-start" className="boardContent">
          <Box className="form-new-program">
            <RoutineInfo setPhase={setPhase} phase={phase} setName={setName} name={name} />
            {name.trim() !== "" && phase.trim() !== "" && columns.length >= 1 ? (
              <Button onClick={() => handleSave()} colorScheme="ptRed" mt="1rem">
                {t("save_program")}
              </Button>
            ) : (
              <Button onClick={() => validacionSave()} colorScheme="ptRed" mt="1rem">
                {t("save_program")}
              </Button>
            )}
          </Box>
          <Box w="100%">
            {/* Board options */}
            <Flex justifyContent="space-between" gridGap="10px" flexWrap="wrap" mb={4}>
              <Flex gridGap={2}>
                <Button
                  size="sm"
                  onClick={() =>
                    cloneState
                      ? handleCancelClone()
                      : (setCloneState(true), onDragStart({ type: "DAYS" }))
                  }
                  variant="outline"
                  leftIcon={
                    cloneState ? <CloseIcon fontSize="inherit" /> : <CopyIcon fontSize="inherit" />
                  }>
                  {cloneState ? t("clone_cancel") : t("clone")}
                </Button>
                <Button size="sm" variant="outline" onClick={() => collapseAndExpandAll()}>
                  {t("expand_or_collapse")}
                </Button>
              </Flex>
              <Button
                size="sm"
                variant="outline"
                colorScheme="orange"
                leftIcon={<AddIcon fontSize="inherit" />}
                onClick={onOpenMore}>
                {t("more_exercise")}
              </Button>
            </Flex>
            <Box className="board-container" ref={scrollHorizontalBoard}>
              <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Droppable droppableId="board" type="COLUMN" direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      className="board_column"
                      style={{
                        backgroundColor: `${getBackgroundColor(
                          snapshot.isDraggingOver,
                          snapshot.isDraggingFrom,
                        )}`,
                        justifyContent: columns.length === 0 ? "center" : "start",
                        alignItems: columns.length === 0 ? "center" : "start",
                      }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}>
                      {ordered?.map((key, index) => (
                        <Column
                          key={key}
                          index={index}
                          title={key}
                          scrollHorizontalBoard={scrollHorizontalBoard}
                          quotes={columns[key]}
                          cloneState={cloneState}
                          handleChangeSelect={handleChangeSelect}
                          handleChangeRemove={handleChangeRemove}
                          handleCancelClone={handleCancelClone}
                          listDaySelected={listDaySelected}
                          isScrollable={withScrollableColumns}
                          isCombineEnabled={isCombineEnabled}
                          columns={columns}
                          setColumns={setFiltered}
                          setFiltered={setFiltered}
                          setOrdered={setOrdered}
                          setSuperSetState={setSuperSetState}
                          onCloneWeek={cloneWeek}
                          setCloneUniqueDay={setCloneUniqueDay}
                          cloneUniqueDay={cloneUniqueDay}
                          currentClient={client}
                        />
                      ))}
                      {provided.placeholder}
                      <button
                        className={styles.Btn__add_week}
                        type="button"
                        onClick={() => handleAddWeek()}>
                        <Text mr={3}>{t("add_week")}</Text>
                        <AddIcon />
                      </button>
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </Box>
        </Flex>
      </Box>

      {isOpenMore && (
        <Combine
          isOpen={isOpenMore}
          onClose={onCloseMore}
          days={sortWeeks}
          setFiltered={setFiltered}
          client={client}
        />
      )}
    </>
  );
};

Board.propTypes = {
  isCombineEnabled: bool.isRequired,
  initial: shape([]).isRequired,
  containerHeight: bool.isRequired,
  withScrollableColumns: bool.isRequired,
  name: string.isRequired,
  phase: string.isRequired,
  client: shape([]).isRequired,
  setFiltered: func.isRequired,
};

export default Board;
