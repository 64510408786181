/* eslint-disable react/prop-types */
/* eslint-disable consistent-return */
/* eslint-disable prefer-template */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useEffect, useState } from "react";
import propTypes, { func } from "prop-types";
import MaterialTable from "material-table";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
} from "@chakra-ui/react";
import { useDispatch } from "react-redux";

import useGetAll from "hooks/useGetAll";
import { getAllCategories } from "services/category";
import { getAllMuscles } from "services/muscle";
import { getAllActions } from "services/action";
import useLang from "hooks/useLang";
import { getAllEvaluationExercises } from "services/exercise";
import notificationDisplay from "utils/notificationDisplay";

import tableIcons from "../../../theme/tableIcons";
import localizationMaterialTable from "../../../helpers/localizationMaterialTable";
import customFilterAndSearch from "../../../helpers/customFilterAndSearch";

const CategoryOverlay = ({
  onClose,
  isOpen,
  position,
  final,
  save,
  date,
  addExercise,
  onCloseMain,
  mainDiv,
}) => {
  const dispatch = useDispatch();
  const { t } = useLang();
  const { data, isLoading, isError, isSuccess } = useGetAll("categories", null, getAllCategories);
  const {
    data: muscleList,
    isLoading: isMuscleLoading,
    isError: isMuscleError,
    isSuccess: isMuscleSuccess,
  } = useGetAll("muscles", null, getAllMuscles);
  const {
    data: actionList,
    isLoading: isActionLoading,
    isError: isActionError,
    isSuccess: isActionSuccess,
  } = useGetAll("actions", null, getAllActions);
  const {
    data: exerciseList,
    isLoading: isexerciseLoading,
    isError: isexerciseError,
    isSuccess: isexerciseSuccess,
  } = useGetAll("exercises", null, getAllEvaluationExercises);
  const [subCategory, setSubCategory] = useState([
    JSON.stringify({ name: "abs", id: "64976b7d2b9336ed72d46f32" }),
    JSON.stringify({ name: "all", id: "64976dd955f2f898a4f27695" }),
  ]);
  const localization = localizationMaterialTable();

  const handleSelect = ({ target }) => {
    setSubCategory((prev) =>
      target.name === "action" ? [prev[0], target.value] : [target.value, prev[1]],
    );
  };

  // const hasExercise = ({ category, muscle, action }) => {
  //   // const arr = [...new Set(exerciseList
  //   //   .map((exercise) => [...exercise.action.en, ...exercise.category_id.map((cat) => cat.name), ...exercise.muscle_system.en.filter((d) => d !== '')])
  //   //   .flat())];
  //   // const hasCategory = arr.includes(category);
  //   // const hasMuscle = muscle.name === 'all' || arr.includes(muscle.name);
  //   // const hasAction = action.name === 'all' || arr.includes(action.name);
  //   // const result = hasCategory && hasMuscle && hasAction;
  //   // console.log('category', category);
  //   // console.log('muscle', muscle);
  //   // console.log('action', action);
  //   // console.log('arr', arr);
  //   // console.log('return ', result);
  //   const result = exerciseList?.filter((item) => {
  //     const itemKeys = Object.keys(item);
  //     console.log({ item });

  //     const actionProp = itemKeys.find((string) => string === 'action');
  //     const muscleProp = itemKeys.find((string) => string === 'muscle_system');
  //     const categoryProp = itemKeys.find((string) => string === 'category_id');

  //     const hasAction = item[actionProp].en.includes(action.name);
  //     const hasMuscle = item[muscleProp].en.includes(muscle.name);
  //     const hasCategory = item[categoryProp][0].name === category;
  //     return hasAction && hasMuscle && hasCategory;
  //   });
  //   console.log('result', result);
  //   return result.length;
  // };

  return (
    <Modal
      returnFocusOnClose={false}
      isOpen={isOpen}
      onClose={
        onCloseMain !== null
          ? () => {
              onClose();
              onCloseMain();
            }
          : () => onClose()
      }
      finalFocusRef={final}>
      <ModalOverlay />
      <ModalContent maxWidth="40rem">
        <ModalHeader>{t("select_category")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {(isLoading || isMuscleLoading || isActionLoading || isexerciseLoading) && (
            <p> Loading </p>
          )}
          {(isError || isMuscleError || isActionError || isexerciseError) && (
            <p> Somenthing went wrong </p>
          )}
          {data && isSuccess && isMuscleSuccess && isActionSuccess && isexerciseSuccess && (
            <MaterialTable
              columns={[
                {
                  title: "Name",
                  field: "name",
                  customFilterAndSearch: (term, rowData) =>
                    customFilterAndSearch(term, rowData, "name"),
                },
                { title: "Muscle", field: "muscle" },
                { title: "Action", field: "action" },
                { title: "Options", field: "options" },
              ]}
              data={[
                ...data.map(({ name, category_id, _id }) => ({
                  name,
                  muscle: (
                    <select
                      name="muscle_system"
                      id="muscle_system"
                      onChange={handleSelect}
                      value={subCategory[0]}>
                      {muscleList?.map((muscle) => (
                        <option
                          value={JSON.stringify({
                            name: muscle.name,
                            id: muscle._id,
                          })}>
                          {muscle.name}
                        </option>
                      ))}
                    </select>
                  ),
                  action: (
                    <select
                      name="action"
                      id="action"
                      onChange={handleSelect}
                      value={subCategory[1]}>
                      {actionList?.map((action) => (
                        <option
                          value={JSON.stringify({
                            name: action.name,
                            id: action._id,
                          })}>
                          {action.name}
                        </option>
                      ))}
                    </select>
                  ),
                  options: (
                    <Button
                      variant="link"
                      onClick={() => {
                        // ? comment while we see what to do with this feature
                        // if (
                        //   hasExercise({
                        //     category: name,
                        //     muscle: JSON.parse(subCategory[0]),
                        //     action: JSON.parse(subCategory[1]),
                        //   }) < 1
                        // ) {
                        //   return notificationDisplay(
                        //     dispatch,
                        //     'The combination of categories, muscles and action selected, match no exercise, please select another combination',
                        //     'error',
                        //   );
                        // }
                        addExercise(date, {
                          category: { name, id: _id },
                          muscle: JSON.parse(subCategory[0]),
                          action: JSON.parse(subCategory[1]),
                        });
                        const Content = mainDiv.current;
                        setTimeout(() => {
                           const height = Content.scrollHeight 
                           Content.scrollTo(0, height)
                        }, 100);
                        onClose();
                      }}
                      // onClick={() => { addExercise(date, { _id, name: `${name}-${subCategory[0]}-${subCategory[1]}` }); onClose(); }}
                    >
                      {t("set_btn")}
                    </Button>
                  ),
                })),
              ]}
              title=""
              icons={tableIcons}
              localization={localization}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

CategoryOverlay.defaultProps = {
  final: null,
  onCloseMain: null,
};

CategoryOverlay.propTypes = {
  addExercise: propTypes.func.isRequired,
  date: propTypes.string.isRequired,
  save: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  onClose: propTypes.func.isRequired,
  position: propTypes.shape({
    rowIdx: propTypes.number.isRequired,
    columnIdx: propTypes.string.isRequired,
  }).isRequired,
  final: propTypes.node,
  onCloseMain: func,
};

export default CategoryOverlay;
