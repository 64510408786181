/* eslint-disable react/no-children-prop */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import useLang from "hooks/useLang";
import {
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Select,
  Textarea,
  Tooltip,
  useDisclosure,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Badge,
} from "@chakra-ui/react";
import DatePicker from "react-date-picker";
import BrushIcon from "assets/icons/brush.png";
import { superSetColors, superSetColorsHex } from "theme/commonStyles";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import dayjs from "dayjs";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { useAddDayContext } from "./AddDay";
import AddExerciseAddDay from "./AddExerciseAddDay";

const DayContent = ({ isEdit, inicialValue, date, onClose, isOpen, mainDiv }) => {
  const { lang, t } = useLang();
  const {
    changeDate,
    changeSession,
    changeNote,
    changeCircuit,
    addRow,
    deleteExercise,
    changeValue,
    changeExerciseNotes,
    deleteRow,
  } = useAddDayContext();

  const { isOpen: isOpenChange, onOpen: onOpenChange, onClose: onCloseChange } = useDisclosure();
  const [positionExercise, setpositionExercise] = useState("");
  const handleChangeSession = ({ target }) => changeSession(inicialValue, target.value);
  const [showNotesExercise, setShowNotesExercise] = useState(false);

  return (
    <Box>
      <Box>
        <InputGroup bg="#f6f6f6" marginBottom={5}>
          <InputLeftAddon children={t("date")} />
          <DatePicker
            id={inicialValue._id}
            onChange={(e) => changeDate(inicialValue, e)}
            value={inicialValue?.date || ""}
            format={lang === "es" ? "dd/MM/yyy" : "MM/dd/yyy"}
            style={{ width: "100%" }}
          />
        </InputGroup>
        <InputGroup bg="#f6f6f6" marginBottom={5}>
          <InputLeftAddon children={t("notes")} />
          <Textarea
            type="text"
            bg="#f6f6f6"
            id={inicialValue._id}
            onChange={({ target }) => changeNote(target.value, date)}
            value={inicialValue.notes[lang]}
            _focus={false}
            border="none"
          />
        </InputGroup>
        {isOpen && (
          <AddExerciseAddDay
            mainDiv={mainDiv}
            isOpen={isOpen}
            onClose={onClose}
            date={inicialValue.exercises?.map((exercise) => exercise)}
          />
        )}
        {inicialValue.exercises?.map((exercise, idx) => (
          <article key={exercise._id} className="exercise_content">
            <Flex gridGap={4} justifyContent="space-between" alignItems="center">
              <h4 className="exercise_content__title">
                {exercise?.id_exercise?.name[lang] || (
                  <span style={{ textTransform: "uppercase", color: "red" }}>
                    {t("undefined_exercise")}
                  </span>
                )}
              </h4>
              <Box>
                <Tooltip hasArrow placement="top" label={t("change_exercise")}>
                  <Button
                    size="sm"
                    colorScheme="gray"
                    onClick={() => {
                      onOpenChange();
                      setpositionExercise(idx);
                    }}>
                    {t("change_exercise")}
                  </Button>
                </Tooltip>
                <Button variant="ghost" colorScheme="red" onClick={() => deleteExercise(date, idx)}>
                  <DeleteIcon fontSize="inherit" />
                </Button>
              </Box>
            </Flex>
            <Flex justifyContent="space-between" alignItems="center">
              <Menu>
                <MenuButton
                  as={Button}
                  aria-label="Options"
                  leftIcon={<img src={BrushIcon} alt="brush icon" style={{ width: "14px" }} />}
                  size="xs"
                  style={{
                    backgroundColor: `${superSetColorsHex[exercise?.colors?.hard || "#fff"]}`,
                  }}>
                  {t("set_circuit")}
                </MenuButton>
                <MenuList zIndex="99999">
                  {superSetColors.map((color, id) => (
                    <MenuItem
                      onClick={() => {
                        changeCircuit({ hard: color, soft: color }, date, idx);
                      }}>
                      {color === "white" ? (
                        <Badge colorScheme={color}>None</Badge>
                      ) : (
                        <Badge colorScheme={color}>
                          {t("circuit")} {id + 1} {color}
                        </Badge>
                      )}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <Flex gridGap={2} justifyContent="end" fontSize="12px" color="gray">
                <p>{t("number_of_sets")}:</p>
                <b>{exercise.sets.length}</b>
              </Flex>
            </Flex>
            <div>
              {exercise.sets?.map((s) => (
                <Box>
                  <Flex
                    gridGap={2}
                    bg="#f9f9f9"
                    borderRadius="10px"
                    padding=".5rem"
                    margin=".5rem 0px"
                    alignItems="center ">
                    <div>
                      <InputGroup bg="white">
                        <InputLeftElement pointerEvents="none" h="2rem" ml="10px">
                          <p className="exercise_content__set-title">{t("loads")}:</p>
                        </InputLeftElement>
                        <Input
                          size="sm"
                          type="tel"
                          value={s.load}
                          placeholder="0"
                          onChange={({ target }) =>
                            changeValue({ ...s, [target.id]: target.value }, date, idx)
                          }
                          id="load"
                          textAlign="center"
                        />
                        <Select
                          size="sm"
                          variant="filled"
                          value={s.typeWeight}
                          id="typeWeight"
                          onChange={({ target }) =>
                            changeValue({ ...s, [target.id]: target.value }, date, idx)
                          }>
                          <option value="kg">kg</option>
                          <option value="lb">lb</option>
                          <option value="others">{t("others")}</option>
                        </Select>
                      </InputGroup>
                    </div>
                    <div>
                      <InputGroup bg="white">
                        <InputLeftElement pointerEvents="none" h="2rem" ml="5px">
                          <p className="exercise_content__set-title">{t("Reps")}:</p>
                        </InputLeftElement>
                        <Input
                          size="sm"
                          type="tel"
                          placeholder="0"
                          id="reps"
                          value={s.reps}
                          onChange={({ target }) =>
                            changeValue({ ...s, [target.id]: target.value }, date, idx)
                          }
                          textAlign="center"
                        />
                        <Select
                          size="sm"
                          variant="filled"
                          value={s.typeReps}
                          id="typeReps"
                          onChange={({ target }) =>
                            changeValue({ ...s, [target.id]: target.value }, date, idx)
                          }>
                          <option value="reps">reps</option>
                          <option value="sec">sec</option>
                        </Select>
                      </InputGroup>
                    </div>

                    <IconButton
                      isRound
                      icon={<ClearIcon fontSize="inherit" />}
                      variant="ghost"
                      colorScheme="red"
                      disabled={exercise.sets.length <= 1}
                      onClick={() => deleteRow(date, idx, s.set_number)}
                    />
                  </Flex>
                </Box>
              ))}
              <Flex justifyContent="space-between" my={2}>
                <Button
                  leftIcon={<StickyNote2OutlinedIcon fontSize="inherit" />}
                  bg={exercise?.notes_exercise?.[lang] !== "" && "#C6F6D5"}
                  colorScheme="gray"
                  onClick={() => setShowNotesExercise((prev) => !prev)}>
                  {t("notes_exercise")}
                </Button>
                <Button
                  leftIcon={<AddIcon fontSize="inherit" />}
                  colorScheme="gray"
                  onClick={() => addRow(date, idx)}>
                  {t("add_sets")}
                </Button>
              </Flex>
            </div>
            {showNotesExercise && (
              <InputGroup bg="#f6f6f6" my={3}>
                <Textarea
                  type="text"
                  bg="#f6f6f6"
                  placeholder={t("notes_exercise")}
                  id={exercise?._id}
                  value={exercise?.notes_exercise?.[lang]}
                  onChange={({ target }) => changeExerciseNotes(target.value, date, idx)}
                  border="none"
                />
              </InputGroup>
            )}
          </article>
        ))}
      </Box>
      {isOpenChange && (
        <AddExerciseAddDay
          mainDiv={mainDiv}
          isOpen={isOpenChange}
          key={positionExercise}
          onClose={onCloseChange}
          date={date}
          id={positionExercise}
        />
      )}
    </Box>
  );
};

export default DayContent;
