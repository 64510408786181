/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
  useDisclosure,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { bool, func, shape } from "prop-types";
import useLang from "hooks/useLang";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import useEditDayValueContext from "./useEditDayValueContext";
import EditContent from "./EditContent";

const EditDayContext = createContext();

const EditDay = ({
  isOpen,
  onClose,
  date,
  titleHeader,
  quotes,
  handleUpdateDnd,
  routineId,
  columns,
  setColumns,
}) => {
  const [open, setopen] = useState(false);
  const { t } = useLang();

  const { isOpen: isOpenContent, onOpen: onOpenContent, onClose: onCloseContent } = useDisclosure();

  const isEdit = true;

  const [values, obj] = useEditDayValueContext(quotes, isEdit);

  const dateInfo = { day: quotes[0].day, week: quotes[0].week };
  const handleSaveEdit = () => {
    // Create a copy of the columns array and each object in it
    const orderBoard = columns.map((arr) => {
      const arrMap = arr.map((objArr) => {
        const subArrMap = { ...objArr };
        return subArrMap;
      });
      return arrMap;
    });

    // Flatten the array of objects
    const reducerForColumn = orderBoard.reduce((acc, val) => acc.concat(val), []);

    // Update the object with the edited quote
    const updatedArr = reducerForColumn.map((objArr) =>
      objArr._id === values._id ? values : objArr,
    );

    // Create a copy of the updated array
    const FormatDays = [...updatedArr];

    // Extract the week property from each object in the array
    const array = FormatDays?.map(({ week }) => week);

    // Remove duplicates from the array
    const newarray = [...new Set(array)];

    // Group the objects by week property
    const dataFilter = newarray?.map((item) =>
      updatedArr?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );

    // Update the state variable and close the modal
    setColumns(dataFilter);
    onClose();
  };

  const handleConfirmDelete = () => {
    // Create a copy of the columns array
    const orderBoard = columns.map((arr) => {
      // Create a copy of each object in the array
      const arrMap = arr.map((objArr) => {
        const subArrMap = { ...objArr };
        return subArrMap;
      });
      return arrMap;
    });

    // Flatten the array of objects
    const arrayOfObjects = orderBoard.reduce((acc, val) => acc.concat(val), []);

    // Filter out the quote to be deleted
    const filterDays = arrayOfObjects.filter(({ _id }) => _id !== quotes._id);

    // Create a copy of the filtered array
    const FormatDays = [...filterDays];

    // Extract the week property from each object in the array
    const array = FormatDays?.map(({ week }) => week);

    // Remove duplicates from the array
    const newarray = [...new Set(array)];

    // Group the objects by week property
    const dataFilter = newarray?.map((item) =>
      filterDays?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );

    // Update the state variables
    setColumns(dataFilter);
    setopen(false);
    onClose();
  };

  return (
    <EditDayContext.Provider
      value={{
        ...obj,
      }}>
      <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderTop="13px solid #b42519" borderRadius={10}>
          <ModalHeader display="flex" justifyContent="space-between">
            <Box>
              <Text display="inline">
                Edit day{" "}
                <Text display="inline" color="#b42519">
                  {quotes[0].day}
                </Text>{" "}
                in week{" "}
                <Text display="inline" color="#b42519">
                  {quotes[0].week}
                </Text>
              </Text>
              <Tooltip hasArrow label="Delete Day">
                <Button
                  size="xs"
                  ml={2}
                  borderRadius="full"
                  bg="lightgray"
                  type="button"
                  height="22px"
                  width="10px"
                  _hover={{ bg: "lightgray", border: "none" }}
                  onClick={() => setopen(true)}>
                  <DeleteIcon fontSize="inherit" />
                </Button>
              </Tooltip>
            </Box>
            <Box>
              <ModalCloseButton />
            </Box>
          </ModalHeader>
          <ModalBody>
            <Box>
              {values.map((day) => (
                <EditContent
                  inicialValue={day}
                  isEdit={isEdit}
                  date={dateInfo}
                  isOpen={isOpenContent}
                  onClose={onCloseContent}
                />
              ))}
              <Box display="flex" justifyContent="space-between" marginTop={2} marginBottom={2}>
                <Button
                  color="gray"
                  border="1px solid #E3E3E3"
                  borderRadius="8px"
                  bg="transparent"
                  _hover={{ color: "#b42519", border: "1px solid #b42519" }}
                  leftIcon={<AddIcon />}
                  onClick={onOpenContent}>
                  Add Exercise
                </Button>
                {values.map((day) => (
                  <>
                    {day.exercises.length >= 1 && (
                      <Button
                        type="submit"
                        bg="#6bca21"
                        color="white"
                        _hover={{ bg: "#6bca21" }}
                        onClick={() => handleSaveEdit()}>
                        Done
                      </Button>
                    )}
                  </>
                ))}
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      {open && (
        <AlertDialog isOpen={open} onClose={() => setopen(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>Delete Day</AlertDialogHeader>
              <AlertDialogBody>{t("delete_confirmation")}</AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={() => setopen(false)}>{t("cancel")}</Button>
                <Button colorScheme="red" onClick={() => handleConfirmDelete()} ml={3}>
                  {t("delete")}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </EditDayContext.Provider>
  );
};

const useEditDayContext = () => useContext(EditDayContext);
export default EditDay;

export { useEditDayContext };
