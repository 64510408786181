import React from "react";
import Main from "components/main";
// import HomeSlider from 'components/HomeSlider';
// import ImgCarrousel from 'components/ImgCarrousel';

const Home = () => (
  <>
    {/* <HomeSlider /> */}
    {/* <ImgCarrousel /> */}
    <Main />
  </>
);

export default Home;
