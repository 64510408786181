/* eslint-disable max-len */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { useEffect, useState, useReducer } from 'react';
// ? Dependecies
import {
  bool, shape, string, func,
} from 'prop-types';
import { Redirect } from 'react-router';
import { Link } from 'react-router-dom';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import AddIcon from '@mui/icons-material/Add';
import {
  Button, Flex, Text, useDisclosure,
} from '@chakra-ui/react';
import CopyIcon from '@material-ui/icons/FileCopy';
import CloseIcon from '@mui/icons-material/Close';
import Step1SuperSet from 'assets/videos/supersets/super_sert_step_1.gif';
import { useDispatch, useSelector } from 'react-redux';
// ? helpers and Services and custom Hooks
import generateId from 'helpers/generateId';
import { createRoutine } from 'services/customWorkout';
import notificationDisplay from 'utils/notificationDisplay';
import useLang from 'hooks/useLang';
import useTour from 'hooks/useTour';
// ? Components or others
import Column from './Column';
import reorder, { reorderQuoteMap } from './reorder';
import styles from '../../../../clients/ClientDashboard/ClientRoutineDisplay/ClientRoutineDisplay.module.css';
import Combine from './Combine';

const STEPS = [
  {
    content: <h2>Start with the tour in SuperSet feature</h2>,
    locale: { skip: <strong aria-label="skip">S-K-I-P</strong> },
    placement: 'center',
    target: 'body',
  },
  {
    content: <div><img src={Step1SuperSet} alt="Step 1 supersets" /></div>,
    floaterProps: {
      disableAnimation: true,
    },
    title: 'Drag and Drop the exercise and change the color',
    spotlightPadding: 20,
    target: '#step-1',
  },
  {
    placement: 'right',
    styles: {
      options: {
        width: 300,
      },
    },
    target: '#step-2',
    title: 'Finally back to the board and view the changes',
  },
];

const Board = ({
  isCombineEnabled,
  initial,
  containerHeight,
  withScrollableColumns,
  name,
  phase,
  client,
  setFiltered,
  setSuperSetState,
  setSuperColumns,
}) => {
  // ? states
  const [columns, setColumns] = useState(initial);
  const [ordered, setOrdered] = useState(Object.keys(initial));
  const [listDaySelected, setListDaySelected] = useState([]);
  const [cloneState, setCloneState] = useState(false);
  const [sortWeeks, setSortWeeks] = useState([]);
  const [inittour, setinittour] = useState('LS_KEY');

  const tour = useTour(STEPS, inittour);

  // ? Hooks
  const { t } = useLang();
  const { _id, id_user } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const { isOpen: isOpenMore, onOpen: onOpenMore, onClose: onCloseMore } = useDisclosure();

  const handleCancelClone = () => {
    setListDaySelected([]);
    setCloneState(false);
  };

  const handleChangeSelect = (items) => () => setListDaySelected([...listDaySelected, items]);

  const handleChangeRemove = (item) => () => {
    setListDaySelected((items) => items.filter((i) => i._id !== item._id));
  };

  const onDragEnd = (result) => {
    if (result.combine) {
      if (result.type === 'COLUMN') {
        const shallow = [...ordered];
        shallow.splice(result.source.index, 1);
        setOrdered(shallow);
        return;
      }

      const column = columns[result.source.droppableId];
      const withQuoteRemoved = [...column];

      withQuoteRemoved.splice(result.source.index, 1);

      const orderedColumns = {
        ...columns,
        [result.source.droppableId]: withQuoteRemoved,
      };
      setColumns(orderedColumns);
      return;
    }

    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    // did not move anywhere - can bail early
    if (
      source.droppableId === destination.droppableId
      && source.index === destination.index
    ) {
      return;
    }

    // reordering column
    if (result.type === 'COLUMN') {
      const reorderedorder = reorder(ordered, source.index, destination.index, columns);

      setOrdered(reorderedorder);

      return;
    }

    const data = reorderQuoteMap({
      quoteMap: columns,
      source,
      destination,
    });

    const arrayOfArrays = Object.values(data.quoteMap).map((arr) => [...arr]);

    // setSuperColumns(arrayOfArrays);
    setColumns(arrayOfArrays);
    setOrdered(Object.keys(data.quoteMap));
  };

  const handleAddWeek = () => {
    const copyColumns = [...columns];
    const arrayOfObjects = sortWeeks.reduce((acc, val) => acc.concat(val), []);
    const lastday = arrayOfObjects[arrayOfObjects.length - 1];
    const GenerateWeek = [
      {
        ...lastday,
        author: {
          ...lastday.author,
          id: generateId(),
          name: (1 + lastday.week),
        },
        day: 1,
        week: (1 + lastday.week),
        _id: generateId(),
        id: generateId(),
        exercises: lastday.exercises.map((i) => {
          const setIdExercise = {
            ...i,
            _id: generateId(),
            sets: i.sets.map((e) => {
              const newId = {
                ...e,
                _id: generateId(),
              };
              return newId;
            }),
          };
          return setIdExercise;
        }),
      },
    ];

    const concatNewDay = copyColumns.concat([GenerateWeek]);
    setColumns(concatNewDay);
    setOrdered(Object.keys(concatNewDay));
  };
  const handleBackToRoutine = () => {
    setSuperColumns(columns);
    setSuperSetState({ data: [], position: 0 });
  };

  const handleSave = async () => {
    const routine = {
      id_trainer: _id,
      id_client: client.split('-')[1],
      filterOptions: [],
      workout_name: { en: name, es: '' },
      source: 'pre-programmed',
      preProgrammed: false,
      phase,
      sessions: columns.flat(),
      daet: null,
    };

    try {
      const response = await createRoutine(routine);
      if (response.data) return notificationDisplay(dispatch, 'something went wrong', 'error');
      return <Redirect to="/user/clients" />;
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    setColumns(initial);
    setOrdered(Object.keys(initial));
    setSortWeeks(initial.reduce((acc, val) => acc.concat(val), []));
  }, [initial]);

  return (
    <>
      <Flex>
        {tour}
        {/* check for the next phase to use in edit */}
        {/* <Button
          variant="ghost"
          border="1px solid #b42519"
          color="#b42519"
          borderRadius="8px"
          onClick={onOpenMore}
          _hover={{ background: '#b42519', color: 'white' }}
        >
          <Text display="inline">
            <Text display="inline">+</Text>
            {' '}
            More Exercises
          </Text>
        </Button>
        {cloneState && (
          <Button
            onClick={() => handleCancelClone()}
            variant="ghost"
            bg="white"
            color="656565"
            borderRadius="8px"
            border="1px solid lightgray"
            _hover={{ background: '#b42519', color: 'white' }}
            ml={4}
          >
            <CloseIcon fontSize="inherit" />
            {' '}
            Cancel Clone
          </Button>
        )}
        {!cloneState && (
          <Button
            onClick={() => setCloneState(true)}
            variant="ghost"
            bg="white"
            color="656565"
            borderRadius="8px"
            border="1px solid lightgray"
            _hover={{ background: '#b42519', color: 'white' }}
            ml={4}
          >
            <CopyIcon fontSize="inherit" />
            {t('clone')}
          </Button>
        )} */}
        <Button
          onClick={handleBackToRoutine}
          variant="ghost"
          bg="white"
          color="656565"
          id="step-2"
          borderRadius="8px"
          border="1px solid lightgray"
          _hover={{ background: '#b42519', color: 'white' }}
          ml={4}
        >
          <ArrowBackIosNewIcon
            style={{ pointerEvents: 'none' }}
          />
          {t('back_to_routine')}
        </Button>
      </Flex>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable
          droppableId="board"
          xtype="COLUMN"
          direction="horizontal"
          ignoreContainerClipping={Boolean(containerHeight)}
          isCombineEnabled={isCombineEnabled}
        >
          {(provided) => (
            <div
              className={styles.container__routine}
              ref={provided.innerRef}
              {...provided.droppableProps}
              id="step-1"
            >
              {ordered?.map((key, index) => (
                <Column
                  key={key}
                  index={index}
                  title={key}
                  quotes={columns[key]}
                  cloneState={cloneState}
                  handleChangeSelect={handleChangeSelect}
                  handleChangeRemove={handleChangeRemove}
                  isScrollable={withScrollableColumns}
                  listDaySelected={listDaySelected}
                  isCombineEnabled={isCombineEnabled}
                  columns={columns}
                  setColumns={setColumns}
                  handleCancelClone={handleCancelClone}
                  setOrdered={setOrdered}
                />
              ))}
              {provided.placeholder}
              {/* check for the next phase to use in edit */}
              {/* <button className={styles.Btn__add_week} type="button" onClick={() => handleAddWeek()}>
                <Text mr={3}>Add Week</Text>
                <AddIcon />
              </button> */}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      {/* <Combine
        isOpen={isOpenMore}
        onClose={onCloseMore}
        days={sortWeeks}
        setFiltered={setColumns}
        client={client}
      /> */}
    </>
  );
};

Board.propTypes = {
  isCombineEnabled: bool.isRequired,
  initial: shape([]).isRequired,
  containerHeight: bool.isRequired,
  withScrollableColumns: bool.isRequired,
  name: string.isRequired,
  phase: string.isRequired,
  client: shape([]).isRequired,
  setFiltered: func.isRequired,
};

export default Board;
