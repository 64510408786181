import React, { useState } from "react";
import { SettingsIcon } from "@chakra-ui/icons";
import { Button, Flex, IconButton, useDisclosure } from "@chakra-ui/react";
import DeleteIcon from "@material-ui/icons/Delete";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import { superSetColorsHex } from "theme/commonStyles";
import { useProgramContext } from "context/ProgramContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import useLang from "hooks/useLang";
import DeleteAlert from "components/common/DeleteAlert";
import { deletePreprogrammed } from "services/preProgrammed";
import { deleteTemplate } from "services/template";
import useDelete from "hooks/useDelete";
import styles from "./Preview.module.css";
import Popup from "../exercises/Popup";

const Preview = () => {
  const [weekStep, setWeekStep] = useState(1);
  const { lang, t } = useLang();
  const { currentData, page } = useProgramContext();
  const { role } = useSelector(({ user }) => user);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [exerciseData, setExerciseData] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();


  const weeksToDisplay = currentData?.sessions?.reduce((total, curr) => {
    if (total[curr.week - 1] === undefined) {
      total.push([curr]);
      return total;
    }
    const data = total[curr.week - 1].concat(curr);
    return total.map((item, id) => (id === curr.week - 1 ? data : item));
  }, []);

  const handleClose = () => setDialogOpen(false);
  const handleDeleteClick = () => setDialogOpen(true);

  const openInfoExercise = (item) => {
    const exerciseInformation = {
      id_exercise: { notes_exercise: item.notes_exercise, ...item.id_exercise },
    };
    setExerciseData(exerciseInformation.id_exercise);
    onOpen();
  };

  const config = {
    pathname: "/user/create",
    state: {
      mode: page.mode,
      data: currentData,
    },
  };
  return (
    <article className={styles.container}>
      <div className={styles.header}>
        {/* <p>{currentData.workout_name && currentData?.workout_name[lang]}</p>
         */}
        {page.owner === "admin" && role === "admin" && (
          <>
            <Button
              colorScheme="gray"
              size="sm"
              variant="ghost"
              leftIcon={<SettingsIcon />}
              as={Link}
              to={config}>
              {t("edit")}
            </Button>
            {/* <Button
              colorScheme="red"
              size="sm"
              variant="ghost"
              leftIcon={<DeleteIcon />}
              onClick={handleDeleteClick}>
              {t("delete")}
            </Button> */}
          </>
        )}
        {page.owner !== "admin" && role === "trainer" && (
          <>
            <Button
              colorScheme="gray"
              size="sm"
              variant="ghost"
              leftIcon={<SettingsIcon />}
              as={Link}
              to={config}>
              {t("edit")}
            </Button>
            {/* <Button
              colorScheme="red"
              size="sm"
              variant="ghost"
              leftIcon={<DeleteIcon />}
              onClick={handleDeleteClick}>
              {t("delete")}
            </Button> */}
          </>
        )}
      </div>

      <div>
        <div className={styles.body}>
          {/* <IconButton
            variant="solid"
            colorScheme="blackAlpha"
            icon={<ArrowBackIosIcon />}
            size="sm"
            disabled={weekStep === 1}
            onClick={() => setWeekStep(weekStep - 1)}
          /> */}
          {weeksToDisplay
            ?.filter((arrData) => arrData[0].week === weekStep)
            ?.map((arrData) => (
              <>
                <Flex
                  className={styles.weekHeader}
                  gridGap={4}
                  flexDirection="column"
                  alignItems="center"
                  justifyContent="center">
                  <h3>
                    {t("list_week")} {arrData[0].week}
                  </h3>

                  <div className={styles.bodyContainer}>
                    {arrData.map((day) => (
                      <div className={styles.day}>
                        <h4>
                          {t("list_day")} {day.day}
                        </h4>
                        <ul>
                          {day.exercises
                            ? day.exercises.map((exercise) => (
                              <li style={{ backgroundColor: `${superSetColorsHex[exercise.colors.hard]}`, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <div>
                                  <p className={styles.exerciseName}>
                                    {exercise.id_exercise?.name[lang] || (
                                      <span style={{ textTransform: "uppercase", color: "red" }}>
                                        {t("undefined_exercise")}
                                      </span>
                                    )}
                                  </p>
                                  <p className={styles.exerciseData}>
                                    {exercise.sets[0].load !== "" &&
                                      exercise.sets[0].load !== "0" ? (
                                      <>
                                        {exercise.sets.length} Sets x{" "}
                                        {exercise.sets?.map((s) => (
                                          <span>
                                            {s.load}
                                            {s.typeWeight === "others" ? "" : s.typeWeight}/{s.reps}
                                            {s?.typeReps ?? ""},{" "}
                                          </span>
                                        ))}
                                      </>
                                    ) : (
                                      <>
                                        {exercise.sets.length} Sets x {exercise.sets[0].reps}
                                        {exercise.sets[0].typeReps ?? ""}
                                      </>
                                    )}
                                  </p>
                                </div>

                                {exercise.id_exercise && (
                                  <IconButton
                                    onClick={() => openInfoExercise(exercise)}
                                    size="xs"
                                    variant="ghost"
                                    icon={<VisibilityIcon fontSize="inherit" />}
                                  />
                                )}
                              </li>
                            ))
                            : day.categoryList.map((category) => (
                              <li style={{ backgroundColor: `${superSetColorsHex[category.colors.hard]}` }}>{`${category.category?.name}-${category.muscle?.name}-${category.action?.name}`}</li>
                            ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </Flex>
              </>
            ))}
          {/*  <IconButton
            isRound
            variant="ghost"
            colorScheme="blackAlpha"
            icon={<ArrowForwardIosIcon />}
            size="sm"
            disabled={weekStep === weeksToDisplay.length}
            onClick={() => setWeekStep(weekStep + 1)}
          /> */}
        </div>

        <div className={styles.pagination}>
          <IconButton
            isRound
            variant="ghost"
            colorScheme="blackAlpha"
            icon={<ArrowBackIosIcon />}
            size="sm"
            onClick={() => setWeekStep(1)}
          />
          {weeksToDisplay?.map((_, idx) => (
            <Button
              className={weekStep === idx + 1 ? styles.active : ""}
              as="div"
              size="sm"
              onClick={() => setWeekStep(idx + 1)}>
              {idx + 1}
            </Button>
          ))}
          <IconButton
            isRound
            variant="ghost"
            colorScheme="blackAlpha"
            icon={<ArrowForwardIosIcon />}
            size="sm"
            onClick={() => setWeekStep(weeksToDisplay.length)}
          />
        </div>
      </div>
      {isOpen && exerciseData && (
        <Popup exercise={exerciseData} isOpen={isOpen} onClose={onClose} mode="info" />
      )}
      {dialogOpen && (
        <DeleteAlert
          open={dialogOpen}
          close={handleClose}
          id={currentData._id}
          title="Delete PreProgram"
          func={() =>
            useDelete(
              "routines",
              page.mode === "preprograms" ? deletePreprogrammed : deleteTemplate,
            )
          }
          handleDeleteProgram={handleClose}
        />
      )}
    </article>
  );
};

export default Preview;
