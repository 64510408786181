/* eslint-disable no-throw-literal */
/* eslint-disable import/prefer-default-export */
import instance from "./_axiosConfig";

const api = instance;

const getRecomendationByTrainerId = async (trainerId) => {
  try {
    const res = await api.get(`/recomendation/${trainerId}`);
    if (res.status !== 200 || res.data.recomendation.length < 1) {
      // const myError = new Error('No list');
      const myError = JSON.stringify({
        en: "there's no recomendation list",
        es: "No hay lista de recomendación",
      });
      throw new Error(myError);
    }
    return res.data.recomendation[0].recomendationsList;
  } catch (error) {
    console.log("erorr from func", error.message);
    throw new Error(error.message);
  }
};

const getAllRecomendations = async () => {
  try {
    const res = await api.get("/recomendation/");
    return res.data.recomendations;
  } catch (error) {
    return error.message;
  }
};

export { getAllRecomendations, getRecomendationByTrainerId };
