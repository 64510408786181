/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React from "react";
import ProgramContextProvider from "context/ProgramContext";
import Header from "./Header";
import Main from "./Main";

const Programs = () => (
  <ProgramContextProvider>
    <>
      <Header />
      <Main />
    </>
  </ProgramContextProvider>
);

export default Programs;
