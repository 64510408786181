/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
import React, { useEffect, useReducer, useState, useRef } from "react";
// ? External Dependencies
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import CopyIcon from "@material-ui/icons/FileCopy";
import { useDispatch, useSelector } from "react-redux";
import { Box, Button, Flex, Text, useDisclosure } from "@chakra-ui/react";
import { Controller, useForm } from "react-hook-form";

// ? Local Dependencies
import generateId from "helpers/generateId";
import notificationDisplay from "utils/notificationDisplay";
import ProgramName from "components/create/Features/ProgramName";
import { createTemplates, updateTemplates } from "services/template";
import useLang from "hooks/useLang";
import MoreTemplates from '../../../programs/MoreTemplates'

import Selector from "../../Features/Selector";
import Column from "./Column";
import { reorderCategoryMap, reorderColumns, reorderQuoteMap } from "./reorder";
import styles from "../../../clients/ClientDashboard/ClientRoutineDisplay/ClientRoutineDisplay.module.css";
import AddDay from "./AddDay/AddDay";
import { categoriesOptions, bodyPartOptions, equipmentOptions } from "../../Features/options";

const Board = ({
  isCombineEnabled,
  initial,
  useClone,
  withScrollableColumns,
  currentName,
  keyRefrest,
  dataEdit,
  setFiltered,
}) => {
  const history = useHistory();
  const { location } = useHistory();
  const { t } = useLang();
  const [columns, setColumns] = useState(initial);
  const [ordered, setOrdered] = useState(Object.keys(initial));
  const { control } = useForm({ mode: "onChange" });
  // testing clone
  const [cloneState, setCloneState] = useState(false);
  const [cloneUniqueDay, setCloneUniqueDay] = useState([]);
  const [listDaySelected, setListDaySelected] = useState([]);
  const { isOpen: isOpenMore, onOpen: onOpenMore, onClose: onCloseMore } = useDisclosure();
  const [isEdit, toggleEdit] = useReducer((prev) => !prev, false);
  const [name, setName] = useState({ en: currentName?.en ?? "", es: currentName?.es ?? "" });
  const { isOpen: isOpenAddDay, onOpen: onOpenAddDay, onClose: onCloseAddDay } = useDisclosure();
  const scrollHorizontalBoard = useRef(null);
  const { id_user, role } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const [filterOptions, setFilterOptions] = useState([[], [], []]);
  const handleChange = (data, actionMeta) => {
    const filteredData = data.map((item) => item.value);
    if (actionMeta.name === "category") {
      setFilterOptions((prev) => prev.map((item, id) => (id === 0 ? filteredData : item)));
    }
    if (actionMeta.name === "bodyParts") {
      setFilterOptions((prev) => prev.map((item, id) => (id === 1 ? filteredData : item)));
    }
    if (actionMeta.name === "equipment") {
      setFilterOptions((prev) => prev.map((item, id) => (id === 2 ? filteredData : item)));
    }
  };
  // testing clone
  const handleCancelClone = () => {
    setListDaySelected([]);
    setCloneState(false);
  };
  const handleChangeSelect = (items) => () => {
    setListDaySelected([
      ...listDaySelected,
      {
        ...items,
        author: {
          ...items.author,
        },
        categoryList: items.categoryList.map((category) => ({
          ...category,
          _id: generateId(),
        })),
      },
    ]);
  };

  const handleChangeRemove = (item) => () => {
    setListDaySelected((items) => items.filter((i) => i._id !== item._id));
  };

  const onDragEnd = (result) => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    if (result.type === "EXERCISE") {
      const reorderExercises = reorderCategoryMap({
        quoteMap: columns,
        source,
        destination,
      });
      const arrayOfArrays = Object.values(reorderExercises.quoteMap).map((arr) => [...arr]);
      setColumns(arrayOfArrays);
      setOrdered(Object.keys(reorderExercises.quoteMap));
      return;
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      const sortColumns = reorderColumns({
        columnsMap: columns,
        source,
        destination,
      });
      setOrdered(Object.keys(sortColumns.columnsMap));
      setColumns(sortColumns.columnsMap);
      return;
    }

    const data = reorderQuoteMap({
      quoteMap: columns,
      source,
      destination,
    });

    const arrayOfArrays = Object.values(data.quoteMap).map((arr) => [...arr]);
    setColumns(arrayOfArrays);
    setOrdered(Object.keys(data.quoteMap));
  };
  const cloneWeek = (week) => {
    const arrayOfObjects = columns.reduce((acc, val) => acc.concat(val), []);
    const lastWeek = arrayOfObjects[arrayOfObjects.length - 1];
    const changeWeek = week.map((item) => {
      const copy = {
        ...item,
        week: lastWeek.week + 1,
        _id: generateId(),
        id: generateId(),
        categoryList: item.categoryList.map((i) => ({
          ...i,
          _id: generateId(),
          sets: i.sets.map((s) => ({
            ...s,
            _id: generateId(),
          })),
        })),
      };
      return copy;
    });
    const concatNewDay = columns.concat([changeWeek]);
    setColumns(concatNewDay);
    setOrdered(Object.keys(concatNewDay));
  };
  const handleAddWeek = () => {
    const arrayOfObjects = columns.reduce((acc, val) => acc.concat(val), []);
    const lastday = arrayOfObjects[arrayOfObjects.length - 1];
    const lastWeek = columns[columns.length - 1];
    const GenerateWeek = lastWeek.map((e) => ({
      ...e,
      week: 1 + lastday.week,
      _id: generateId(),
      id: generateId(),
      categoryList: e.categoryList.map((i) => ({
        ...i,
        _id: generateId(),
        sets: i.sets.map((s) => ({
          ...s,
          _id: generateId(),
        })),
      })),
    }));

    const concatNewDay = columns.concat([[]]);
    setColumns(concatNewDay);
    setOrdered(Object.keys(concatNewDay));
    const ContentMainScroll = scrollHorizontalBoard.current;
    const horizontalScroll = ContentMainScroll.scrollWidth;
    setTimeout(() => {
      ContentMainScroll.scrollTo(horizontalScroll, 0);
    }, 100);
  };
  const filtersIsDone = () => {
    return (
      (filterOptions[0].length > 0 || dataEdit?.filterOptions[0].length > 0) &&
      (filterOptions[1].length > 0 || dataEdit?.filterOptions[1].length > 0) &&
      (filterOptions[2].length > 0 || dataEdit?.filterOptions[2].length > 0)
    );
  };
  const handleUpdate = async () => {
    const optionCurrency = [
      filterOptions[0] <= 0 ? dataEdit?.filterOptions[0] : filterOptions[0],
      filterOptions[1] <= 0 ? dataEdit?.filterOptions[1] : filterOptions[1],
      filterOptions[2] <= 0 ? dataEdit?.filterOptions[2] : filterOptions[2],
    ];
    const sendUpdateData = {
      ...dataEdit,
      workout_name: name,
      sessions: columns.flat().map((item) => ({
        ...item,
        categoryList: item.categoryList.map((subItem) => ({
          ...subItem,
          category: subItem.category.id || subItem.category._id,
          muscle: subItem.muscle.id || subItem.muscle._id,
          action: subItem.action.id || subItem.action._id,
        })),
      })),
      filterOptions: optionCurrency,
    };
    try {
      await updateTemplates(sendUpdateData, dataEdit._id);
      notificationDisplay(dispatch, "Updated", "success");
      history.push("/user/programs");
    } catch (error) {
      notificationDisplay(dispatch, error.message, "error");
    }
  };
  const handleSave = async () => {
    const optionCurrency = [
      filterOptions[0] <= 0 ? [] : filterOptions[0],
      filterOptions[1] <= 0 ? [] : filterOptions[1],
      filterOptions[2] <= 0 ? [] : filterOptions[2],
    ];
    const dataToSend = {
      workout_name: name,
      sessions: columns.flat().map((item) => ({
        ...item,
        categoryList: item.categoryList.map((subItem) => ({
          ...subItem,
          category: subItem.category.id,
          muscle: subItem.muscle.id,
          action: subItem.action.id,
        })),
      })),
      trainer: role === "admin" ? undefined : id_user,
      filterOptions: optionCurrency,
      preProgrammed: false,
    };

    try {
      const response = await createTemplates(dataToSend);
      if (response.data) return notificationDisplay(dispatch, "something went wrong", "error");

      return history.push("/user/programs");
    } catch (error) {
      notificationDisplay(dispatch, error.message, "error");
    }
  };

  const validacionSave = (action) => {
    if (name.es === "") {
      notificationDisplay(dispatch, "Spanish name is empty", "error");
      return;
    }
    if (name.en === "") {
      notificationDisplay(dispatch, "English name is empty", "error");
      return;
    }
    if (columns.length <= 0) {
      notificationDisplay(dispatch, t("errors.empty_program"), "error");
      return;
    }
    action();
  };

  useEffect(() => {
    setColumns(initial);
    setOrdered(Object.keys(initial));
  }, [initial]);

  const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
    if (isDraggingOver) return "#FFEBE6";
    if (isDraggingFrom) return "#E6FCF8";
    return "transparent";
  };

  const collapseAndExpandAll = () => {
    const items = Array.from(document.getElementsByClassName("day_content"));
    const spandButton = Array.from(document.getElementsByClassName("spand-button"));
    if (items.length > 0) {
      if (items.some((a) => a.classList.contains("collapsed"))) {
        items.forEach((element) => {
          element.classList.remove("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.remove("btn-collapsed");
        });
      } else {
        items.forEach((element) => {
          element.classList.add("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.add("btn-collapsed");
        });
      }
    }
  };
  const onDragStart = (result) => {
    if (result.type === "DAYS") {
      const items = Array.from(document.getElementsByClassName("day_content"));
      const spandButton = Array.from(document.getElementsByClassName("spand-button"));
      if (items.length > 0) {
        items.forEach((element) => {
          element.classList.add("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.add("btn-collapsed");
        });
      }
    }
  };

  return (
    <>
      <Flex gridGap="1rem" alignItems="flex-start" className="boardContent">
        {/* Form info */}
        <Box className="form-new-program">
          <ProgramName enName={name.en} esName={name.es} setName={setName} />
          <Box mt="10px" className="selectors">
            <Controller
              name="category"
              control={control}
              render={({ field }) => (
                <Selector
                  isMulti
                  name="category"
                  label={t("table_category")}
                  values={categoriesOptions.map((item) => ({
                    value: item.value,
                    label: t(item.label),
                  }))}
                  currentValue={
                    location?.state?.data?.filterOptions[0]?.map((item) => ({
                      value: item,
                      label: t(item),
                    })) ?? false
                  }
                  onChange={handleChange}
                />
              )}
            />
            <Controller
              name="bodyParts"
              control={control}
              render={({ field }) => (
                <Selector
                  isMulti
                  name="bodyParts"
                  label={t("bodypart")}
                  values={bodyPartOptions.map((item) => ({
                    value: item.value,
                    label: t(item.label),
                  }))}
                  currentValue={
                    location?.state?.data?.filterOptions[1]?.map((item) => ({
                      value: item,
                      label: t(item),
                    })) ?? false
                  }
                  onChange={handleChange}
                />
              )}
            />
            <Controller
              name="equipment"
              control={control}
              render={({ field }) => (
                <Selector
                  isMulti
                  name="equipment"
                  label={t("equipment")}
                  values={equipmentOptions.map((item) => ({
                    value: item.value,
                    label: t(item.label),
                  }))}
                  currentValue={
                    location?.state?.data?.filterOptions[2]?.map((item) => ({
                      value: item,
                      label: t(item),
                    })) ?? false
                  }
                  onChange={handleChange}
                />
              )}
            />
          </Box>
          <Button
            mt={4}
            onClick={
              dataEdit ? () => validacionSave(handleUpdate) : () => validacionSave(handleSave)
            }
            colorScheme="ptRed">
            {dataEdit ? t("save_edit") : t("save_program")}
          </Button>
        </Box>
        {/*  */}
        <Box w="100%">
          {/* Board options */}
          <Flex justifyContent="space-between" gridGap="10px" flexWrap="wrap" mb={4}>
            <Flex gridGap={2}>
              <Button
                size="sm"
                onClick={() =>
                  cloneState
                    ? handleCancelClone()
                    : (setCloneState(true), onDragStart({ type: "DAYS" }))
                }
                variant="outline">
                {cloneState ? (
                  <>
                    <CloseIcon fontSize="inherit" /> {t("clone_cancel")}
                  </>
                ) : (
                  <>
                    <CopyIcon fontSize="inherit" />
                    {t("clone")}
                  </>
                )}
              </Button>
              <Button size="sm" onClick={() => collapseAndExpandAll()} variant="outline">
                {t("expand_or_collapse")}
              </Button>
            </Flex>
            <Button size="sm" colorScheme="teal" variant="outline" onClick={onOpenMore}>
              <Text display="inline">
                <Text display="inline">+</Text> {t("more_template")}
              </Text>
            </Button>
          </Flex>
          <Box className="board-container" ref={scrollHorizontalBoard}>
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              <Droppable droppableId="board" type="COLUMN" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    className="board_column"
                    style={{
                      backgroundColor: `${getBackgroundColor(
                        snapshot.isDraggingOver,
                        snapshot.isDraggingFrom,
                      )}`,
                      justifyContent: columns.length === 0 ? "center" : "start",
                      alignItems: columns.length === 0 ? "center" : "start",
                    }}
                    {...provided.droppableProps}
                    ref={provided.innerRef}>
                    {ordered?.map((key, index) => (
                      <Column
                        key={key}
                        index={index}
                        title={key}
                        quotes={columns[key]}
                        isScrollable={withScrollableColumns}
                        isCombineEnabled={isCombineEnabled}
                        useClone={useClone}
                        columns={columns}
                        keyRefrest={keyRefrest}
                        setColumns={setFiltered}
                        setOrdered={setOrdered}
                        cloneState={cloneState}
                        scrollHorizontalBoard={scrollHorizontalBoard}
                        handleCancelClone={handleCancelClone}
                        listDaySelected={listDaySelected}
                        handleChangeSelect={handleChangeSelect}
                        handleChangeRemove={handleChangeRemove}
                        onCloneWeek={cloneWeek}
                        setCloneUniqueDay={setCloneUniqueDay}
                        cloneUniqueDay={cloneUniqueDay}
                      />
                    ))}
                    {provided.placeholder}
                    {columns.length === 0 && (
                      <Box>
                        <Button
                          backgroundColor="white"
                          color="#b42519"
                          _hover={{ backgroundColor: "#b42519", color: "white" }}
                          variant="outline"
                          borderRadius="full"
                          p="2em"
                          id="stepMain-3"
                          width="320px"
                          mt={8}
                          onClick={() => onOpenAddDay()}>
                          <AddIcon />
                          {t("create_firts_day")}
                        </Button>
                      </Box>
                    )}
                    {columns.length >= 1 && !cloneState && (
                      <button
                        className={styles.Btn__add_week}
                        type="button"
                        onClick={() => handleAddWeek()}>
                        <Text mr={3}>{t("add_week")}</Text>
                        <AddIcon />
                      </button>
                    )}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </Box>
        </Box>
      </Flex>

      {isOpenMore && (
        <MoreTemplates
          isOpen={isOpenMore}
          columns={columns}
          onClose={onCloseMore}
          days={columns.flat()}
          setFiltered={setFiltered}
        />
      )}

      {isOpenAddDay && (
        <AddDay
          isOpen={isOpenAddDay}
          onClose={onCloseAddDay}
          columns={columns}
          setColumns={setFiltered}
          key={isOpenAddDay}
          isEdit={isEdit}
          toggleEdit={toggleEdit}
        />
      )}
    </>
  );
};

export default Board;
