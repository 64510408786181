/* eslint-disable react/prop-types */
/* eslint-disable no-lone-blocks */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";

import ReturnBtn from "components/common/ReturnBtn";
import {
  initEvaluation,
  addNotes,
  updateReps,
  setDominant,
  saveEvaluation,
  addGeneralNote,
} from "./evaluationReducer";

import SingleEvaluation from "./SingleEvaluation";

import dataToMapEvaluation from "./dataToMapEvaluation";

const setValueForEvaluation = (number) => {
  if (number === 1 || number === 2) return 0;
  if (number === 3 || number === 4) return 1;
  if (number === 5) return 2;
  if (number === 6 || number === 7) return 3;
  if (number === 8) return 4;
  if (number === 9) return 5;
  if (number === 10) return 6;
  if (number === 11) return 7;
};

const Selector = ({ setStep, state, dispatch, saveClientEvaluation, setType }) => {
  const [localStep, setLocalStep] = useState(1);
  const [dataEvaluation, setData] = useState([...dataToMapEvaluation]);
  const { t } = useTranslation();

  const handleClick = (data, general, reps, side, local, direction) => {
    const dataFiltered = data.filter((radio) => radio.checked);
    setLocalStep((prev) => (direction === "prev" ? prev - 1 : prev + 1));
    dispatch(updateReps(side, reps, setValueForEvaluation(local)));
    dispatch(addNotes(dataFiltered, setValueForEvaluation(local)));
    dispatch(setDominant(setValueForEvaluation(local)));
    dispatch(addGeneralNote(general, side, setValueForEvaluation(local)));
    if (direction === "save") {
      /*  dispatch(saveEvaluation()); */
    }
  };

  useEffect(() => {
    switch (localStep) {
      case 1:
        // case 1: dispatch(initEvaluation('61a5594b2f7ea4933fcf220c'));
        dispatch(
          initEvaluation({ en: "Single Leg Anterior Reach", es: "Alcance Anterior a 1 Pierna" }, 1),
        );
        break;
      case 3:
        {
          // dispatch(initEvaluation('61a559972f7ea4933fcf220e'));
          dispatch(initEvaluation({ en: "Single Leg Squat", es: "Sentadilla a 1 Pierna" }, 2));
        }
        break;
      case 5:
        {
          // dispatch(initEvaluation('61a559d52f7ea4933fcf2210'));
          dispatch(initEvaluation({ en: "Squat", es: "Sentadilla" }, 3));
        }
        break;
      case 6:
        {
          // dispatch(initEvaluation('61a559f22f7ea4933fcf2212'));
          dispatch(initEvaluation({ en: "Lunges", es: "Estocadas" }, 4));
        }
        break;
      case 8:
        {
          // dispatch(initEvaluation('61a55a452f7ea4933fcf2216'));
          dispatch(
            initEvaluation({ en: "Push Up", es: "Plancha. Flexión-Extensión de Brazos" }, 5),
          );
        }
        break;
      case 9:
        {
          // dispatch(initEvaluation('61a55a682f7ea4933fcf221b'));
          dispatch(initEvaluation({ en: "Recline Pull", es: "Remo Reclinado" }, 6));
        }
        break;
      case 10:
        {
          // dispatch(initEvaluation('61a55ac82f7ea4933fcf221f'));
          dispatch(initEvaluation({ en: "Rotation with Pivot", es: "Rotación con Pivote" }, 7));
        }
        break;
      case 11:
        {
          // dispatch(initEvaluation('61a55ac82f7ea4933fcf221f'));
          dispatch(initEvaluation({ en: "Rotation without Pivot", es: "Rotación sin Pivote" }, 8));
        }
        break;
      case 12: {
        setStep((prev) => prev + 1);
        saveClientEvaluation();
        setType("bio");
        break;
      }
      default:
        return null;
    }
  }, [localStep]);
  useEffect(() => {
    setData([...dataToMapEvaluation]);
    console.log("start component", dataEvaluation);
  }, []);
  return (
    <>
      {/* <ReturnBtn setStep={setStep} /> */}
      {dataEvaluation.map((evaluation) => (
        <>
          {localStep === evaluation.number && (
            <SingleEvaluation
              title={t(evaluation.title)}
              move={handleClick}
              notes={state[evaluation.state]?.notes}
              legRep={
                evaluation.side === "right"
                  ? state[evaluation.state]?.succesfulMovements.rightLeg
                  : state[evaluation.state]?.succesfulMovements.leftLeg
              }
              local={localStep}
              side={evaluation.side}
              radios={evaluation.radios}
              className={evaluation.className}
              general={state[evaluation.state]?.generalNote}
              key={evaluation.number}
            />
          )}
        </>
      ))}
    </>
  );
};

export default Selector;
