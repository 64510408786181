/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import {
  Button,
  IconButton,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Tooltip,
  Skeleton,
  Input,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  Flex,
  Box,
  Heading,
  Badge,
  Text,
  Image,
} from "@chakra-ui/react";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import VisibilityIcon from "@mui/icons-material/Visibility";
import propTypes from "prop-types";
import GeneratePdfAssessments from "utils/AssessmentPdf";
import GeneratePdfGoals from "utils/GoalsPdf";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { deleteEvaluation, deleteGoalService, getMeasurementsByUser } from "services/evaluations";
import useLang from "hooks/useLang";

import notFound from "assets/notfound.png";
import notificationDisplay from "utils/notificationDisplay";
import { generateEvaluationPDF } from "utils/pdfGenerators";
import styles from "./Welcome.module.scss";
import { setEvaluation } from "./evaluationReducer";

const Welcome = ({
  set,
  setType,
  dispatch,
  evaluations,
  goals,
  setGoals,
  setClient,
  setEvaluations,
  loadingEvaluations,
  setEvaluationId,
  setIsEdit,
  setNewGoal,
  setNewMeasurement,
  isModal = false,
}) => {
  const [btnLoading, setBtnloading] = useState(false);
  const [search, setSearch] = useState("");

  const [showFilters, setShowFilters] = useState(false);
  const [showType, setShowType] = useState("ev");
  const [filterType, setFilterType] = useState("all");
  const { t, lang } = useLang();
  const [loadingState, setloadingState] = useState({
    state: false,
    value: "",
  });
  const [showRenderPdf, setShowRenderPdf] = useState(null);
  const datatrainer = useSelector(({ user }) => user);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const [nPages, setNPages] = useState(0);

  useEffect(() => {
    if (evaluations && showType === "ev") {
      setNPages(
        Math.ceil(
          evaluations
            ?.filter(
              (item) =>
                item.id_client?.name?.toLowerCase().includes(search.toLowerCase()) ||
                item.id_client?.lastName?.toLowerCase().includes(search.toLowerCase()),
            )
            .filter(({ id_client }) =>
              filterType === "all"
                ? true
                : filterType === "guest"
                ? id_client.isGuest
                : !id_client.isGuest,
            ).length / recordsPerPage,
        ),
      );
    }
  }, [evaluations, filterType, search]);
  useEffect(() => {
    if (goals && showType === "go") {
      setNPages(
        Math.ceil(
          goals
            ?.filter(
              (item) =>
                item.client?.name?.toLowerCase().includes(search.toLowerCase()) ||
                item.client?.lastName?.toLowerCase().includes(search.toLowerCase()),
            )
            .filter(({ client }) =>
              filterType === "all"
                ? true
                : filterType === "guest"
                ? client.isGuest
                : !client.isGuest,
            ).length / recordsPerPage,
        ),
      );
    }
  }, [goals, filterType, search]);

  const showEvaluation = (data) => {
    set((prev) => (isModal ? 2 : 5));
    setClient(`${data.id_client?.name}___${data.id_client?._id}`);
    dispatch(setEvaluation(data.evaluations));
    setEvaluationId(data._id);
    setIsEdit(false);
    setType("bio");
  };
  const showGoalEvaluation = (data) => {
    set((prev) => (isModal ? 2 : 5));
    setIsEdit(false);
    setClient(`${data.client?.name}___${data.client?._id}`);
    setNewGoal(data);
    setNewMeasurement(null);
    setType("goal");
  };
  const deleteItem = async (id) => {
    try {
      const res = await deleteEvaluation(id);
      if (res.data) throw new Error(res.data.message);
      const evalData = [...evaluations];
      const index = evalData.findIndex((a) => a._id === id);
      evalData.splice(index, 1);
      console.log(evalData);
      setEvaluations(evalData);
      // notificationDisplay(dispatch, t("bio_start"), "success");
    } catch (exception) {
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };
  const deleteGoal = async (id) => {
    try {
      const res = await deleteGoalService(id);
      if (res.data) throw new Error(res.data.message);
      const evalData = [...goals];
      const index = evalData.findIndex((a) => a._id === id);
      evalData.splice(index, 1);
      console.log(evalData);
      setGoals(evalData);
      // notificationDisplay(dispatch, t("bio_start"), "success");
    } catch (exception) {
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };

  const loaderGeneratePdf = (id) => {
    setloadingState({
      state: true,
      value: id,
    });

    setTimeout(() => {
      setShowRenderPdf(id);
      setloadingState({
        state: false,
        value: "",
      });
    }, 500);
  };
  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) setCurrentPage(nPages);
  };
  return (
    <div className={styles.root}>
      <Tabs variant="enclosed-colored" colorScheme="red">
        <TabList>
          <Tab
            onClick={() => {
              setloadingState({ state: false, value: "" });
              setShowRenderPdf(false);
              setShowType("ev");
            }}>
            {t("evaluation")}
          </Tab>
          <Tab
            onClick={() => {
              setloadingState({ state: false, value: "" });
              setShowRenderPdf(false);
              setShowType("go");
            }}>
            {t("goals")}
          </Tab>
        </TabList>
        <TabPanels>
          <TabPanel bgColor="white">
            <div className={styles.table}>
              <Flex gridGap={4} alignItems="center">
                <Input
                  variant="outline"
                  placeholder={t("search_client")}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  w="20%"
                />

                <Box>
                  <Flex gridGap={4}>
                    <Button
                      colorScheme="blackAlpha"
                      size="xs"
                      leftIcon={<FilterAltIcon fontSize="inherit" />}
                      onClick={() => setShowFilters(!showFilters)}>
                      {t("filters")}
                    </Button>
                    {showFilters ? (
                      <>
                        <Button
                          colorScheme={filterType === "all" ? "green" : "gray"}
                          size="xs"
                          onClick={() => setFilterType("all")}>
                          {t("showAll")}
                        </Button>
                        <Button
                          colorScheme={filterType === "clients" ? "green" : "gray"}
                          size="xs"
                          onClick={() => setFilterType("clients")}>
                          {t("onlyClients")}
                        </Button>
                        <Button
                          colorScheme={filterType === "guest" ? "green" : "gray"}
                          size="xs"
                          onClick={() => setFilterType("guest")}>
                          {t("onlyGuests")}
                        </Button>
                      </>
                    ) : null}
                  </Flex>
                </Box>
              </Flex>

              <Heading as="h4" size="md" my={4}>
                {isModal ? t("assessmentHistory") : t("assessmentsHistory")}
              </Heading>
              <TableContainer>
                <Table variant="striped" colorScheme="gray" className={styles.tableAssessment}>
                  <Thead>
                    <Tr>
                      <Th>{t("date")}</Th>
                      <Th>{isModal ? t("client") : t("clients")}</Th>
                      <Th />
                    </Tr>
                  </Thead>

                  <Tbody>
                    {evaluations
                      ?.filter(
                        (item) =>
                          item.id_client?.name?.toLowerCase().includes(search.toLowerCase()) ||
                          item.id_client?.lastName?.toLowerCase().includes(search.toLowerCase()),
                      )
                      ?.filter(({ id_client }) =>
                        filterType === "all"
                          ? true
                          : filterType === "guest"
                          ? id_client.isGuest
                          : !id_client.isGuest,
                      )
                      .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
                      .slice(indexOfFirstRecord, indexOfLastRecord)
                      .map((evaluation, index) => (
                        <Tr>
                          <Td>
                            {moment(evaluation.date).format(
                              lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                            )}
                          </Td>
                          <Td>
                            <Flex gridGap={4}>
                              <Text>
                                {evaluation.id_client?.name} {evaluation.id_client?.lastName}
                              </Text>
                              <Badge
                                id="profile"
                                bg={!evaluation.id_client?.isGuest ? "#6bca21" : "#b42519"}
                                color="white"
                                borderRadius="20px"
                                fontSize="10px"
                                padding="1px 8px">
                                {!evaluation.id_client?.isGuest ? t("client") : t("guest")}
                              </Badge>
                            </Flex>
                          </Td>
                          <Td>
                            <div className={styles.actions}>
                              <Tooltip label={t("download_pdf")}>
                                {showRenderPdf === evaluation._id ? (
                                  <GeneratePdfAssessments
                                    evaluation={evaluation.evaluations}
                                    dataClient={evaluation.id_client}
                                    datatrainer={datatrainer}
                                  />
                                ) : (
                                  <Button
                                    colorScheme="teal"
                                    variant="outline"
                                    size="xs"
                                    onClick={() => loaderGeneratePdf(evaluation._id)}>
                                    {t("generasting_pdf")}
                                  </Button>
                                )}
                              </Tooltip>

                              <Button
                                size="xs"
                                colorScheme="teal"
                                onClick={() => showEvaluation(evaluation)}
                                type="button"
                                leftIcon={<VisibilityIcon fontSize="small" />}>
                                {t("show_evaluation")}
                              </Button>
                              <Tooltip label={t("delete")}>
                                <IconButton
                                  size="xs"
                                  variant="solid"
                                  colorScheme="red"
                                  onClick={() => deleteItem(evaluation._id)}
                                  icon={<DeleteIcon fontSize="small" />}
                                />
                              </Tooltip>
                            </div>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
              {!loadingEvaluations && (
                <>
                  {evaluations
                    ?.filter(
                      (item) =>
                        item.id_client?.name?.toLowerCase().includes(search.toLowerCase()) ||
                        item.id_client?.lastName?.toLowerCase().includes(search.toLowerCase()),
                    )
                    ?.filter(({ id_client }) =>
                      filterType === "all"
                        ? true
                        : filterType === "guest"
                        ? id_client.isGuest
                        : !id_client.isGuest,
                    ).length <= 0 && (
                    <Flex flexDirection="column" alignItems="center" justifyContent="center">
                      <Image src={notFound} w="10rem" alt="not found" />
                      <Heading size="md"> {t("notFoundData")}</Heading>
                    </Flex>
                  )}
                  <nav>
                    <ul className="pagination">
                      <li>
                        <IconButton
                          isRound
                          variant="ghost"
                          colorScheme="blackAlpha"
                          aria-label="Done"
                          fontSize="20px"
                          disabled={currentPage === 1}
                          icon={<FirstPageIcon />}
                          onClick={goToFirstPage}
                        />
                      </li>
                      <li>
                        <IconButton
                          isRound
                          variant="ghost"
                          colorScheme="blackAlpha"
                          aria-label="Done"
                          fontSize="20px"
                          icon={<ArrowBackIosIcon />}
                          onClick={goToPrevPage}
                          disabled={currentPage === 1}
                        />
                      </li>
                      <li>
                        {search !== "" ? 1 : currentPage}/{nPages <= 0 ? 1 : nPages}
                      </li>
                      <li>
                        <IconButton
                          isRound
                          variant="ghost"
                          colorScheme="blackAlpha"
                          aria-label="Done"
                          fontSize="20px"
                          icon={<ArrowForwardIosIcon />}
                          onClick={goToNextPage}
                          disabled={currentPage >= nPages}
                        />
                      </li>
                      <li>
                        <IconButton
                          isRound
                          variant="ghost"
                          colorScheme="blackAlpha"
                          aria-label="Done"
                          fontSize="20px"
                          disabled={currentPage === nPages}
                          icon={<LastPageIcon />}
                          onClick={goToLastPage}
                        />
                      </li>
                    </ul>
                  </nav>
                </>
              )}

              {loadingEvaluations && evaluations.length <= 0 && (
                <div>
                  <Skeleton height="40px" marginBottom="10px" />
                  <Skeleton height="40px" marginBottom="10px" />
                  <Skeleton height="40px" marginBottom="10px" />
                </div>
              )}
            </div>
          </TabPanel>
          <TabPanel bgColor="white">
            <div className={styles.table}>
              <Flex gridGap={4} alignItems="center">
                <Input
                  variant="outline"
                  placeholder={t("search_client")}
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  w="20%"
                />
                <Box>
                  <Flex gridGap={4}>
                    <Button
                      colorScheme="blackAlpha"
                      size="xs"
                      leftIcon={<FilterAltIcon fontSize="inherit" />}
                      onClick={() => setShowFilters(!showFilters)}>
                      {t("filters")}
                    </Button>
                    {showFilters ? (
                      <>
                        <Button
                          colorScheme={filterType === "all" ? "green" : "gray"}
                          size="xs"
                          onClick={() => setFilterType("all")}>
                          {t("showAll")}
                        </Button>
                        <Button
                          colorScheme={filterType === "clients" ? "green" : "gray"}
                          size="xs"
                          onClick={() => setFilterType("clients")}>
                          {t("onlyClients")}
                        </Button>
                        <Button
                          colorScheme={filterType === "guest" ? "green" : "gray"}
                          size="xs"
                          onClick={() => setFilterType("guest")}>
                          {t("onlyGuests")}
                        </Button>
                      </>
                    ) : null}
                  </Flex>
                </Box>
              </Flex>

              <Heading as="h4" size="md" my={4}>
                {t("goalsHistory")}
              </Heading>
              <TableContainer>
                <Table variant="striped" colorScheme="gray" className={styles.tableAssessment}>
                  <Thead>
                    <Tr>
                      <Th>{t("date")}</Th>
                      <Th>{t("clients")}</Th>
                      <Th />
                    </Tr>
                  </Thead>

                  <Tbody>
                    {goals
                      ?.filter(
                        (item) =>
                          item.client?.name?.toLowerCase().includes(search.toLowerCase()) ||
                          item.client?.lastName?.toLowerCase().includes(search.toLowerCase()),
                      )
                      .filter(({ client }) =>
                        filterType === "all"
                          ? true
                          : filterType === "guest"
                          ? client.isGuest
                          : !client.isGuest,
                      )
                      .sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
                      .slice(indexOfFirstRecord, indexOfLastRecord)
                      .map((goal, index) => (
                        <Tr>
                          <Td>
                            {moment(goal.created_at).format(
                              lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                            )}
                          </Td>
                          <Td>
                            <Flex gridGap={4}>
                              <Text>
                                {goal.client?.name} {goal.client?.lastName}
                              </Text>
                              <Badge
                                id="profile"
                                bg={!goal.client?.isGuest ? "#6bca21" : "#b42519"}
                                color="white"
                                borderRadius="20px"
                                mx={2}
                                fontSize="10px"
                                padding="1px 8px">
                                {!goal.client?.isGuest ? t("client") : t("guest")}
                              </Badge>
                            </Flex>
                          </Td>
                          <Td>
                            <div className={styles.actions}>
                              <Tooltip label={t("download_pdf")}>
                                {showRenderPdf === goal._id ? (
                                  <GeneratePdfGoals
                                    evaluation={goal}
                                    dataClient={goal.client}
                                    datatrainer={datatrainer}
                                  />
                                ) : (
                                  <Button
                                    colorScheme="teal"
                                    variant="outline"
                                    size="xs"
                                    onClick={() => loaderGeneratePdf(goal._id)}>
                                    {t("generasting_pdf")}
                                  </Button>
                                )}
                              </Tooltip>

                              <Button
                                size="xs"
                                colorScheme="teal"
                                onClick={() => showGoalEvaluation(goal)}
                                type="button"
                                leftIcon={<VisibilityIcon fontSize="small" />}>
                                {t("show_goal")}
                              </Button>
                              <Tooltip label={t("delete")}>
                                <IconButton
                                  size="xs"
                                  variant="solid"
                                  colorScheme="red"
                                  onClick={() => deleteGoal(goal._id, index)}
                                  icon={<DeleteIcon fontSize="small" />}
                                />
                              </Tooltip>
                            </div>
                          </Td>
                        </Tr>
                      ))}
                  </Tbody>
                </Table>
              </TableContainer>
              {!loadingEvaluations && (
                <>
                  {goals
                    ?.filter(
                      (item) =>
                        item.client?.name?.toLowerCase().includes(search.toLowerCase()) ||
                        item.client?.lastName?.toLowerCase().includes(search.toLowerCase()),
                    )
                    .filter(({ client }) =>
                      filterType === "all"
                        ? true
                        : filterType === "guest"
                        ? client.isGuest
                        : !client.isGuest,
                    ).length <= 0 && (
                    <Flex flexDirection="column" alignItems="center" justifyContent="center">
                      <Image src={notFound} w="10rem" alt="not found" />
                      <Heading size="md"> {t("notFoundData")}</Heading>
                    </Flex>
                  )}
                  <nav>
                    <ul className="pagination">
                      <li>
                        <IconButton
                          isRound
                          variant="ghost"
                          colorScheme="blackAlpha"
                          aria-label="Done"
                          fontSize="20px"
                          disabled={currentPage === 1}
                          icon={<FirstPageIcon />}
                          onClick={goToFirstPage}
                        />
                      </li>
                      <li>
                        <IconButton
                          isRound
                          variant="ghost"
                          colorScheme="blackAlpha"
                          aria-label="Done"
                          fontSize="20px"
                          icon={<ArrowBackIosIcon />}
                          onClick={goToPrevPage}
                          disabled={currentPage === 1}
                        />
                      </li>
                      <li>
                        {search !== "" ? 1 : currentPage}/{nPages <= 0 ? 1 : nPages}
                      </li>
                      <li>
                        <IconButton
                          isRound
                          variant="ghost"
                          colorScheme="blackAlpha"
                          aria-label="Done"
                          fontSize="20px"
                          icon={<ArrowForwardIosIcon />}
                          onClick={goToNextPage}
                          disabled={currentPage >= nPages}
                        />
                      </li>
                      <li>
                        <IconButton
                          isRound
                          variant="ghost"
                          colorScheme="blackAlpha"
                          aria-label="Done"
                          fontSize="20px"
                          disabled={currentPage === nPages}
                          icon={<LastPageIcon />}
                          onClick={goToLastPage}
                        />
                      </li>
                    </ul>
                  </nav>
                </>
              )}

              {loadingEvaluations && evaluations.length <= 0 && (
                <div>
                  <Skeleton height="40px" marginBottom="10px" />
                  <Skeleton height="40px" marginBottom="10px" />
                  <Skeleton height="40px" marginBottom="10px" />
                </div>
              )}
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default Welcome;
