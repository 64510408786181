/* eslint-disable react/no-danger */
/* eslint-disable consistent-return */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Drawer,
  DrawerBody,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  DrawerContent,
  DrawerCloseButton,
  Input,
  Button,
  FormControl,
  FormLabel,
  Box,
  InputGroup,
  InputRightElement,
} from "@chakra-ui/react";
import Select from "react-select";
import useLang from "hooks/useLang";
import { deleteNutrition, generateNutrition, updateNutrition } from "services/nutrition";
import notificationDisplay from "utils/notificationDisplay";
import { useDispatch, useSelector } from "react-redux";
import LoadingResponseNutrition from "components/nutrition/LoadingResponse";

const DrawerInformationNutrition = ({ isOpen, onClose, diet, setDiets }) => {
  const { t, lang } = useLang();
  const { id_user } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const [dietCurrent, setdietCurrent] = useState(diet.diet)
  const [loader, setLoader] = useState(false)
  const [newPrompt, setnewPrompt] = useState("");
  const [data, setData] = useState({
    name: diet.name,
    goal: diet.goals,
  });

  const goslClient = [
    {
      goal: "weight_loss",
    },
    {
      goal: "muscle_gain",
    },
    {
      goal: "maintenance",
    },
  ];

  const options = goslClient?.map(({ goal }) => {
    const returnOption = {
      value: goal,
      label: t(goal),
    };
    return returnOption;
  });

  const onChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const submitUpdateDiets = async (e) => {
    e.preventDefault()

    if (newPrompt !== "") {
      const message = lang === "en" ? `${newPrompt} the this diet ${dietCurrent}, remove markdown formatting` : `${newPrompt} de esta dieta ${dietCurrent}, remueve el formato markdown`
      setLoader(true)
      const res = await generateNutrition({ message });
      setLoader(false)
      const formattedText = res.response
        .replace(/\n/g, "<br />")
        .replace(/\*\*(.*?)\*\*/g, "<b>$1</b>");
      setdietCurrent(formattedText)
    }
  };

  const handleUpdate = async () => {
    const sendData = {
      name: data.name,
      goals: data.goal,
      id_trainer: id_user,
      id_client: diet?.id_client ?? null,
      diet: dietCurrent,
    };
    try {
      const response = await updateNutrition(diet._id, sendData);
      if (response.data) return notificationDisplay(dispatch, t("something_wrong"), "error");
      notificationDisplay(dispatch, t("succesful_addition", { data: t("programs") }), "success");
      setDiets((prev) => prev.map((item) => item._id === diet._id ? sendData : item))
    } catch (error) {
      console.error(error);
    }
    onClose();
  }

  const handleDelete = async () => {
    try {
      await deleteNutrition(diet._id);
      setDiets((prev) => prev.filter(({ _id }) => diet._id !== _id));
      onClose();
    } catch (error) {
      console.error(error);
    }
  };
  return (
    <Drawer isOpen={isOpen} placement="right" onClose={onClose} size="lg">
      <DrawerOverlay />
      <DrawerContent>
        <DrawerCloseButton />
        <DrawerHeader>{t("nutrition_section.diet_information")}</DrawerHeader>
        <DrawerBody>
          <FormControl>
            <FormLabel>{t("nutrition_section.name_diet")}</FormLabel>
            <Input value={data.name} name="name" type="text" onChange={(e) => onChange(e)} />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("nutrition_section.main_objective_diet")}</FormLabel>
            <Select
              placeholder={t("table_select")}
              options={options}
              defaultValue={{ value: diet.goals, label: t(diet.goals) }}
              onChange={(e) => setData({ ...data, goal: e.value })}
            />
          </FormControl>
          <div dangerouslySetInnerHTML={{ __html: dietCurrent }} />
          {/* {loader ? (<LoadingResponseNutrition />) : (
          )}
          <form onSubmit={submitUpdateDiets}>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type="text"
                value={newPrompt}
                placeholder="You need remove or add? Write here"
                name="newPrompt"
                onChange={(e) => setnewPrompt(e.target.value)}
              />
              <InputRightElement width="7rem">
                <Button bg="black" color="white">
                  {t('generate')}
                </Button>
              </InputRightElement>
            </InputGroup>
          </form> */}
        </DrawerBody>
        <DrawerFooter display="flex" justifyContent="space-between" alignItems="center">
          <Box>
            <Button variant="solid" colorScheme="red" onClick={() => handleDelete()}>
              {t("delete")}
            </Button>
          </Box>
          <Box>
            <Button variant="outline" mr={3} onClick={onClose}>
              {t("cancel")}
            </Button>
            <Button
              bg="#6bca21"
              variant="unstyled"
              onClick={handleUpdate}
              p="0.5em 1em"
              color="white">
              {t("save")}
            </Button>
          </Box>
        </DrawerFooter>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerInformationNutrition;
