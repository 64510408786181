/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import generateId from "helpers/generateId";
import useLang from "hooks/useLang";
import { useEffect, useState } from "react";
import isSameDate from "utils/isSameDate";

const useAddDayValueContext = (initialValue, isEdit) => {
  const [values, setValues] = useState(initialValue);
  const { lang } = useLang();

  useEffect(() => {
    setValues(initialValue);
  }, [isEdit]);

  const changeDate = (date, newValue) =>
    setValues((prev) =>
      prev.map((day) => (isSameDate(day, date) ? { ...day, date: newValue } : day)),
    );
  const changeDay = (data, newValue) =>
    setValues((prev) =>
      prev.map((day) => (isSameDate(day, data) ? { ...day, day: newValue } : day)),
    );
  const changeWeek = (data, newValue) =>
    setValues((prev) =>
      prev.map((day) => (isSameDate(day, data) ? { ...day, week: newValue } : day)),
    );
  const changeSession = (data, newValue) =>
    setValues((prev) =>
      prev.map((day) => (isSameDate(day, data) ? { ...day, session: newValue } : day)),
    );
  const addExercise = (date, item) => {
    const categoryToAdd = {
      ...item,
      _id: generateId(),
      colors: { hard: 'white', soft: 'white' },
      notes_exercise: { en: "", es: "" },
      sets: [
        {
          set_number: 1,
          load: "",
          reps: "",
          typeWeight: "kg",
          typeReps: "reps",
        },
      ],
    };

    setValues((prev) =>
      prev.map((value) => {
        const NewCategory = {
          ...value,
          categoryList: value.categoryList.concat(categoryToAdd),
        };
        return NewCategory;
      }),
    );
  };
  const changeExercise = (date, item) => {};
  const deleteCategory = (date, id) => {
    const dayToChange = values.find((value) => isSameDate(value, date));
    const categoriesFiltered = dayToChange.categoryList.filter((_, idx) => idx !== id);

    const dayModified = { ...dayToChange, categoryList: categoriesFiltered };
    setValues((prev) => prev.map((day) => (isSameDate(day, date) ? dayModified : day)));
  };

  const deleteDay = (id) => setValues((prev) => prev.filter(({ _id }) => _id !== id));

  const addRow = (date, id) => {
    /* Finding the day that is being changed. */
    const dayToChange = values.find((value) => isSameDate(value, date));
    /* Finding the exercise that is being changed. */
    const categoryToChange = dayToChange.categoryList.find((_, idx) => idx === id);

    const setToCopy = categoryToChange.sets.at(-1);
    /* Copying the last set in the array and adding 1 to the set number. */
    const setsModified = categoryToChange.sets.concat({
      ...setToCopy,
      set_number: setToCopy.set_number + 1,
    });

    /* Copying the exerciseToChange object and adding the setsModified array to it. */
    const categoryModified = { ...categoryToChange, sets: setsModified };
    const newCategories = dayToChange.categoryList.map((exercise, idx) =>
      idx === id ? categoryModified : exercise,
    );
    const dayModified = { ...dayToChange, categoryList: newCategories };
    setValues((prev) => prev.map((day) => (isSameDate(day, date) ? dayModified : day)));
  };

  const deleteRow = (date, id, set_number) => {
    const dayToChange = values.find((value) => isSameDate(value, date));
    const categoryToChange = dayToChange.categoryList.find((_, idx) => idx === id);
    const setsModified = categoryToChange.sets
      .filter((set) => set.set_number !== set_number)
      .map((set, idx) => ({ ...set, set_number: idx + 1 }));
    const newCategories = dayToChange.categoryList.map((category, idx) =>
      idx === id ? { ...category, sets: setsModified } : category,
    );
    const dayModified = { ...dayToChange, categoryList: newCategories };

    setValues((prev) => prev.map((day) => (isSameDate(day, date) ? dayModified : day)));
  };
  const changeNote = (newValue, date) => {
    setValues((prev) =>
      prev.map((day) =>
        isSameDate(day, date) ? { ...day, notes: { ...day.notes, [lang]: newValue } } : day,
      ),
    );
  };
  const changeValue = (targetValue, date, id) => {
    const dayToChange = values.find((value) => isSameDate(value, date));
    const categoryToChange = dayToChange.categoryList.find((_, idx) => idx === id);
    const newSets = categoryToChange.sets.map((set) =>
      set.set_number === targetValue.set_number ? targetValue : set,
    );
    const newCategories = dayToChange.categoryList.map((category, idx) =>
      idx === id ? { ...category, sets: newSets } : category,
    );
    setValues((prev) =>
      prev.map((value) =>
        isSameDate(value, date) ? { ...value, categoryList: newCategories } : value,
      ),
    );
  };

  const changeCircuit = (targetValue, date, id) => {
    const dayToChange = values.find((value) => isSameDate(value, date));

    const newCategories = dayToChange.categoryList.map((category, idx) =>
      idx === id ? { ...category, colors: targetValue } : category,
    );
    setValues((prev) =>
      prev.map((value) =>
        isSameDate(value, date) ? { ...value, categoryList: newCategories } : value,
      ),
    );
  };
  return [
    values,
    {
      changeDate,
      changeDay,
      changeWeek,
      changeSession,
      changeValue,
      deleteRow,
      addRow,
      addExercise,
      changeExercise,
      deleteCategory,
      deleteDay,
      changeNote,
      changeCircuit,
    },
  ];
};

export default useAddDayValueContext;
