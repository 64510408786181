/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
// Library
import React from 'react';
import { string, shape, func } from 'prop-types';

// Components
import ErrorBoundary from 'components/common/ErrorBoundary';
import Template from '../Template';
import Preprogrammed from '../Preprogrammed';
import Custom from '../Custom';
import FromClient from '../FromClient';

const RoutineDisplay = ({
  workout,
  data,
  preSelected,
  actual,
  preInfo,
  client,
  name,
  phase,
  setIsCreate,
  clientInfo,
}) => (
  <ErrorBoundary>
    {workout === 'template' && (
    <Template
      preSelected={preSelected}
      setIsCreate={setIsCreate}
      workout={workout}
      preInfo={preInfo}
      name={name}
      phase={phase}
      clientInfo={clientInfo}
      client={client}
    />
    )}
    {workout === 'preprogrammed' && (
    <Preprogrammed
      preSelected={preSelected}
      client={client}
      preInfo={preInfo}
      name={name}
      clientInfo={clientInfo}
      setIsCreate={setIsCreate}
      workout={workout}
      phase={phase}
    />
    )}
    {workout === 'custom' && (
    <Custom
      // name={name}
      // phase={phase}
      client={client}
      setIsCreate={setIsCreate}
      clientInfo={clientInfo}
      workout={workout}
    />
    )}
    {workout === 'fromclient' && <FromClient />}
  </ErrorBoundary>
);

RoutineDisplay.propTypes = {
  workout: string.isRequired,
  data: shape([]).isRequired,
  preSelected: shape([]).isRequired,
  actual: shape({}).isRequired,
  preInfo: shape([]).isRequired,
  client: func.isRequired,
};

export default RoutineDisplay;
