/* eslint-disable no-unused-vars */
import React, { useEffect } from "react";
import { bigContainerStyle } from "theme/commonStyles";
import NewRoutine from "./newRoutine";
// import ProgramsComplete from './ProgramsComplete';

// import Selector from './Selector';

const Routines = () => {
  useEffect(() => {
    document.title = "Perfect Trainer App | Routines";
    const sidebar = document.getElementsByClassName("sidebar")[0];
    if (sidebar) sidebar.setAttribute("class", "sidebar collapse");
    return () => {
      if (sidebar) sidebar.setAttribute("class", "sidebar");
    };
  }, []);
  return (
    <div style={bigContainerStyle}>
      {/* <ProgramsComplete /> */}
      <NewRoutine />
    </div>
  );
};
export default Routines;
