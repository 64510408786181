/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  useDisclosure,
  AlertDialogOverlay,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { bool, func, shape } from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import useLang from "hooks/useLang";
import generateId from "helpers/generateId";
import useAddDayValueContext from "./useAddDayValueContext";
import DayContent from "./DayContent";
import MorePrograms from "../MorePrograms";

const AddDayContext = createContext();

const AddDay = ({
  isOpen,
  onClose,
  date,
  titleHeader,
  weeks,
  handleSubmitCopySameProgram,
  routineId,
  handleUpdateDnd,
  currentClient,
  columns,
  thelastday: IncDay,
}) => {
  const { isOpen: isOpenContent, onOpen: onOpenContent, onClose: onCloseContent } = useDisclosure();
  const {
    isOpen: isOpenPrograms,
    onOpen: onOpenPrograms,
    onClose: onClosePrograms,
  } = useDisclosure();
  const [openDialog, setopenDialog] = useState(false);

  const { t } = useLang();

  const inicialValue = [
    {
      _id: generateId(),
      completed: false,
      date: null,
      day: IncDay ?? 1,
      session: "",
      week: titleHeader ?? 1,
      notes: {
        en: "",
        es: "",
      },
      exercises: [],
    },
  ];
  const [isEdit, setisEdit] = useState(false);

  const [values, obj] = useAddDayValueContext(inicialValue, isEdit);

  const dateInfo = { day: inicialValue[0].day, week: inicialValue[0].week };

  const handleConfirmClose = () => {
    if (values[0].exercises.length <= 0) {
      onClose();
      return;
    }
    if (weeks !== values[0]) {
      setopenDialog(true);
      return;
    }
    onClose();
  };

  useEffect(() => {
    setisEdit(true);
  }, []);

  return (
    <>
      <AddDayContext.Provider
        value={{
          ...obj,
        }}>
        <Modal
          closeOnOverlayClick={false}
          returnFocusOnClose={false}
          isOpen={isOpen}
          onClose={() => handleConfirmClose()}
          size="xl">
          <ModalOverlay />
          <ModalContent borderTop="13px solid #b42519" borderRadius={10}>
            <ModalHeader>
              <p>
                {t("add_day")} <span style={{ color: "#b42519" }}>{IncDay}</span> {t("in_week")}{" "}
                <span style={{ color: "#b42519" }}>{titleHeader}</span>
              </p>
            </ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <Box>
                {values.map((day) => (
                  <DayContent
                    inicialValue={day}
                    isEdit={isEdit}
                    date={dateInfo}
                    currentClient={currentClient}
                    isOpen={isOpenContent}
                    onClose={onCloseContent}
                  />
                ))}
                <Box display="flex" justifyContent="space-between" marginTop={2} marginBottom={2}>
                  <Button
                    color="gray"
                    border="1px solid #E3E3E3"
                    borderRadius="8px"
                    bg="transparent"
                    _hover={{ color: "#b42519", border: "1px solid #b42519" }}
                    leftIcon={<AddIcon />}
                    onClick={onOpenContent}>
                    {t("add_exercise")}
                  </Button>
                  <Button
                    color="gray"
                    border="1px solid #E3E3E3"
                    borderRadius="8px"
                    bg="transparent"
                    _hover={{ color: "#b42519", border: "1px solid #b42519" }}
                    onClick={() => onOpenPrograms()}>
                    {t("preprogrammed_workout")}
                  </Button>
                  {values.map((day) => (
                    <>
                      {day.exercises.length >= 1 && (
                        <Button
                          type="submit"
                          bg="#6bca21"
                          color="white"
                          _hover={{ bg: "#6bca21" }}
                          onClick={() => {
                            handleSubmitCopySameProgram(routineId, values);
                            onClose();
                          }}>
                          {t("save")}
                        </Button>
                      )}
                    </>
                  ))}
                </Box>
              </Box>
            </ModalBody>
          </ModalContent>
        </Modal>
        {openDialog && (
          <AlertDialog isOpen={openDialog} onClose={() => setopenDialog(false)}>
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
                  {t("delete_confirmation")}
                </AlertDialogHeader>
                <AlertDialogBody>{t("confirm_save_or_discard")}</AlertDialogBody>
                <AlertDialogFooter>
                  <Box
                    display="flex"
                    flexDirection="column"
                    w="full"
                    gridGap={2}
                    justifyContent="center"
                    alignItems="center">
                    <Button w="70%" border="1px solid #b42519" onClick={() => setopenDialog(false)}>
                      {t("backBtn")}
                    </Button>
                    <Button
                      w="70%"
                      bg="#b42519"
                      color="white"
                      _hover={{ bg: "white", border: "1px solid #b42519", color: "black" }}
                      onClick={() => onClose()}>
                      {t("discard")}
                    </Button>
                    <Button
                      w="70%"
                      bg="#6bca21"
                      color="white"
                      _hover={{ bg: "#6bca21" }}
                      onClick={() => {
                        handleSubmitCopySameProgram(routineId, values);
                        onClose();
                      }}>
                      {t("save")}
                    </Button>
                  </Box>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        )}
        {isOpenPrograms && (
          <MorePrograms
            isOpen={isOpenPrograms}
            onClose={onClosePrograms}
            onCloseMain={onClose}
            days={weeks}
            lastday={IncDay}
            routineId={routineId}
            currentClient={currentClient}
            currentWeek={titleHeader}
            columns={columns}
            handleUpdateDnd={handleUpdateDnd}
          />
        )}
      </AddDayContext.Provider>
    </>
  );
};

const useAddDayContext = () => useContext(AddDayContext);
export default AddDay;

export { useAddDayContext };
