/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/prop-types */
import React from 'react';
import useLang from 'hooks/useLang';
import {
  container, title, subtitle, text, active, text_create,
} from './Combine.module.css';

const ProgramList = ({
  isActive,
  name,
  sub,
  phase,
  createdDate,
  handleClick,
}) => {
  const { t, lang } = useLang();
  return (
    <article
      className={`${container} 
        ${isActive && active}`}
      onClick={handleClick}
    >
      <div>
        <h4 className={title} style={{ fontSize: "1.2rem" }}>{name[lang] || name.en}</h4>
        <p className={text}>{`${t('phase_title')} ${phase}`}</p>
        <p className={text_create}>{`Created ${createdDate}`}</p>
      </div>
    </article>
  );
};

export default ProgramList;
