import React, { memo } from 'react';
import { Grid, Button, IconButton } from '@chakra-ui/react';
import { AddIcon, DeleteIcon } from '@chakra-ui/icons';
import { func, shape, string } from 'prop-types';

const setSelectedButton = (item, actual, type) => {
  if (type === 'Day' || type === 'Dia') return item.number === actual.day;
  return item.number === actual.week;
};

const DateList = memo(({
  list, select, add, type, actual, erase,
}) => (
  <Grid templateColumns="repeat(6, 1fr)" autoRows>
    {
      list.map((item) => (
        <Button
          onClick={() => select(item.number, item.days)}
          variant={setSelectedButton(item, actual, type) ? 'solid' : 'ghost'}
          colorScheme="ptRed"
          minWidth="6.5rem"
          maxWidth="7rem"
        >
          {`${type} ${item.number}`}
        </Button>
      ))
    }
    <IconButton aria-label={`Add ${type}`} icon={<AddIcon />} onClick={add} variant="ghost" />
    <IconButton aria-label={`Add ${type}`} icon={<DeleteIcon />} onClick={erase} variant="ghost" />
  </Grid>
));

DateList.propTypes = {
  list: shape([]).isRequired,
  select: func.isRequired,
  add: func.isRequired,
  erase: func.isRequired,
  type: string.isRequired,
  actual: shape({}).isRequired,
};

export default DateList;
