/* eslint-disable no-unused-vars */
import React from "react";
import { Grid, GridItem, IconButton, Image, Center, Flex, Box } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon, DeleteIcon } from "@chakra-ui/icons";
import propTypes from "prop-types";
import logo from "assets/1.png";
import useLang from "hooks/useLang";

const Carousel = ({ step, click, images, mode, erase }) => {
  const { t, lang } = useLang();
  return (
    <>
      <Box position="relative">
        <IconButton
          position="absolute"
          top="45%"
          zIndex="1"
          isRound
          aria-label="previous image"
          onClick={() => click("prev")}
          icon={<ChevronLeftIcon id="prev" />}
          isDisabled={step === 0}
        />
        <IconButton
          isRound
          position="absolute"
          top="45%"
          zIndex="1"
          right="0"
          aria-label="next image"
          onClick={() => click("next")}
          icon={<ChevronRightIcon />}
          isDisabled={images?.length === 0 || step === images?.length - 1}
        />
        <Box className="carousel-content">
          {images?.length === 0 ? (
            <Center>{t("no_files")}</Center>
          ) : (
            images?.map((image, index) => (
              <>
                {!image.includes("mp4") && !image.includes("mov") ? (
                  <Image
                    display={step === index ? "block" : "none"}
                    fallbackSrc={logo}
                    src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${image}`}
                    alt="file exercise"
                  />
                ) : (
                  <video
                    style={{
                      display: step === index ? "block" : "none",
                      maxHeight: "25rem",
                      objectFit: "contain",
                      backgroundColor: "black",
                    }}
                    width="400"
                    preload="metadata"
                    muted
                    loop
                    autoPlay
                    playsInline
                    allowFullScreen="false"
                    fallbackSrc={logo}
                    src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${image}#t=0.1`}
                    type="video/mp4"
                  />
                )}
              </>
            ))
          )}
        </Box>
      </Box>
    </>
  );
};

Carousel.defaultProps = {
  mode: "",
  erase: null,
};

Carousel.propTypes = {
  step: propTypes.number.isRequired,
  click: propTypes.func.isRequired,
  images: propTypes.shape([]).isRequired,
  mode: propTypes.string,
  erase: propTypes.func,
};

export default Carousel;
