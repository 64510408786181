const customFilterAndSearch = (term, rowData, field, lang = null) => {
  // If doesn't have 2 o more languages, left lang empty on calling funciton
  // Some times rowData ends up being an array like for example in muscle_system,
  // add "filtering: false" in material table call, futher work is needed
  // to make that search in arrays work
  // console.log('rowData', rowData);
  // console.log('lang', lang);
  let fieldSelected = lang !== null ? rowData[field][lang] : rowData[field];
  if (field === 'category_id') fieldSelected = fieldSelected.map((item) => item.name);
  const rowDataField = fieldSelected.toString().normalize('NFD').replace(/\p{Diacritic}/gu, '')
    .toLowerCase()
    .includes(
      term
        .toLowerCase()
        .normalize('NFD')
        .replace(/\p{Diacritic}/gu, ''),
    );

  return rowDataField;
};

export default customFilterAndSearch;

// Documentation 2/23/202: https://material-table.com/#/docs/features/filtering
