/* eslint-disable react/no-children-prop */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import useLang from "hooks/useLang";
import {
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  InputGroup,
  InputLeftAddon,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Select,
  Textarea,
  useDisclosure,
} from "@chakra-ui/react";
import { superSetColors, superSetColorsHex } from "theme/commonStyles";
import BrushIcon from "assets/icons/brush.png";
import AddIcon from "@mui/icons-material/Add";
import CategoryOverlay from "components/create/Features/CategoryOverlay";
import dayjs from "dayjs";
import DeleteIcon from "@material-ui/icons/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import { useExerciseEditContext } from "./ExerciseEdit";
import EditExerciseAddDay from "./EditExerciseAddDay";

const ExerciseEditContent = ({ isEdit, inicialValue, date, isOpen, onCloseMain, mainDiv }) => {
  const { lang, t } = useLang();
  const { addExercise, addRow, changeValue, deleteRow, changeCircuit } = useExerciseEditContext();

  const { isOpen: isOpenChange, onOpen: onOpenChange, onClose: onCloseChange } = useDisclosure();
  const [positionExercise, setpositionExercise] = useState("");
  const [showListExercise, setshowListExercise] = useState(true);

  const handleCloseCategoryList = () => setshowListExercise(false);

  return (
    <Box>
      <Box>
        {showListExercise && (
          <CategoryOverlay
            isOpen={showListExercise}
            onClose={handleCloseCategoryList}
            mainDiv={mainDiv}
            addExercise={addExercise}
            onCloseMain={onCloseMain}
          />
        )}
        {inicialValue.categoryList?.map((category, idx) => (
          <article key={category._id} className="exercise_content">
            <Flex gridGap={4} justifyContent="space-between" alignItems="center">
              <h4 className="exercise_content__title">
                <>
                  {category.category?.name}
                  {category.muscle?.name && <Badge>{category.muscle.name}</Badge>}
                  {category.action?.name && (
                    <Badge colorScheme="purple">{category.action.name}</Badge>
                  )}
                </>
              </h4>
            </Flex>{" "}
            <Flex justifyContent="space-between" alignItems="center">
              <Menu>
                <MenuButton
                  as={Button}
                  aria-label="Options"
                  leftIcon={<img src={BrushIcon} alt="brush icon" style={{ width: "14px" }} />}
                  size="xs"
                  style={{
                    backgroundColor: `${superSetColorsHex[category?.colors?.hard || "#fff"]}`,
                  }}>
                  {t("set_circuit")}
                </MenuButton>
                <MenuList zIndex="99999">
                  {superSetColors.map((color, id) => (
                    <MenuItem
                      onClick={() => {
                        changeCircuit({ hard: color, soft: color }, date, idx);
                      }}>
                      {color === "white" ? (
                        <Badge colorScheme={color}>None</Badge>
                      ) : (
                        <Badge colorScheme={color}>
                          {t("circuit")} {id + 1} {color}
                        </Badge>
                      )}
                    </MenuItem>
                  ))}
                </MenuList>
              </Menu>
              <Flex gridGap={2} justifyContent="end" fontSize="12px" color="gray">
                <p>{t("number_of_sets")}:</p>
                <b>{category.sets.length}</b>
              </Flex>
            </Flex>
            <div>
              {category.sets?.map((s) => (
                <Box>
                  <Flex
                    gridGap={2}
                    bg="#f9f9f9"
                    borderRadius="10px"
                    padding=".5rem"
                    margin=".5rem 0px"
                    alignItems="center ">
                    <div>
                      <InputGroup bg="white">
                        <InputLeftElement pointerEvents="none" h="2rem" ml="10px">
                          <p className="exercise_content__set-title">{t("loads")}:</p>
                        </InputLeftElement>
                        <Input
                          size="sm"
                          type="tel"
                          value={s.load}
                          placeholder="0"
                          onChange={({ target }) =>
                            changeValue({ ...s, [target.id]: target.value }, date, idx)
                          }
                          id="load"
                          textAlign="center"
                        />
                        <Select
                          size="sm"
                          variant="filled"
                          value={s.typeWeight}
                          id="typeWeight"
                          onChange={({ target }) =>
                            changeValue({ ...s, [target.id]: target.value }, date, idx)
                          }>
                          <option value="kg">kg</option>
                          <option value="lb">lb</option>
                          <option value="others">{t("others")}</option>
                        </Select>
                      </InputGroup>
                    </div>
                    <div>
                      <InputGroup bg="white">
                        <InputLeftElement pointerEvents="none" h="2rem" ml="5px">
                          <p className="exercise_content__set-title">{t("Reps")}:</p>
                        </InputLeftElement>
                        <Input
                          size="sm"
                          type="tel"
                          id="reps"
                          placeholder="0"
                          value={s.reps}
                          onChange={({ target }) =>
                            changeValue({ ...s, [target.id]: target.value }, date, idx)
                          }
                          textAlign="center"
                        />
                        <Select
                          size="sm"
                          variant="filled"
                          value={s.typeReps}
                          id="typeReps"
                          onChange={({ target }) =>
                            changeValue({ ...s, [target.id]: target.value }, date, idx)
                          }>
                          <option value="reps">reps</option>
                          <option value="sec">sec</option>
                        </Select>
                      </InputGroup>
                    </div>

                    <IconButton
                      isRound
                      icon={<ClearIcon fontSize="inherit" />}
                      variant="ghost"
                      colorScheme="red"
                      disabled={category.sets.length <= 1}
                      onClick={() => deleteRow(date, idx, s.set_number)}
                    />
                  </Flex>
                </Box>
              ))}
              <Flex justifyContent="end">
                <Button
                  leftIcon={<AddIcon fontSize="inherit" />}
                  colorScheme="gray"
                  onClick={() => addRow(date, idx)}>
                  {t("add_sets")}
                </Button>
              </Flex>
            </div>
          </article>
        ))}
      </Box>
    </Box>
  );
};

export default ExerciseEditContent;
