/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-closing-tag-location */
/* eslint-disable import/no-cycle */
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  SimpleGrid,
  IconButton,
  Flex,
  InputGroup,
  InputLeftElement,
  Input,
  useDisclosure,
  Select,
} from "@chakra-ui/react";
import { bool, func, number, shape } from "prop-types";
import { useSelector } from "react-redux";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";

import useLang from "hooks/useLang";
import useGetAll from "hooks/useGetAll";
import AddNewExerciseModal from "components/routines/AddNewExerciseModal";
import { getTrainerExercises } from "services/exercise";

import MoreProgramsExercises from "components/programs/MoreProgramsExercises";
import { Search2Icon, AddIcon } from "@chakra-ui/icons";
import ErrorBoundary from "components/common/ErrorBoundary";
import ExerciseCard from "components/exercises/exerciseCard";

const ExerciseListModal = ({ isOpen, onClose, changeExercise }) => {
  const { id_user } = useSelector(({ user }) => user);
  const { isLoading, isError, isSuccess, data } = useGetAll("exercises", null, () =>
    getTrainerExercises(id_user),
  );
  const { t, lang } = useLang();
  const [displayMode, setDisplayMode] = useState("grid");
  const { isOpen: isOpenContent, onOpen: onOpenContent, onClose: onCloseContent } = useDisclosure();
  const { isOpen: isOpenAddNew, onOpen: onOpenAddNew, onClose: onCloseAddNew } = useDisclosure();
  const [search, setSearch] = useState("");
  const [actionFilter, setActionFilter] = useState("");
  const [muscleFilter, setMuscleFilter] = useState("");
  const [categoryFilter, setCategoryFilter] = useState("");
  const [equipmentFilter, setEquipmentFilter] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(10);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const [nPages, setNPages] = useState(0);
  useEffect(() => {
    if (data) setNPages(Math.ceil(data.length / recordsPerPage));
  }, [isSuccess]);

  const handleEvent = (item) => {
    changeExercise(item);
    onClose();
  };

  const changeFilters = ({ name, action, muscle, category, equipment }) => {
    setSearch(name);
    setActionFilter(action);
    setMuscleFilter(muscle);
    setCategoryFilter(category);
    setEquipmentFilter(equipment);
    const { length } = data
      .filter((a) => (name !== "" ? a.name[lang]?.toLowerCase().includes(name?.toLowerCase()) : a))
      .filter((a) => (action !== "" ? a.action?.includes(action) : a))
      .filter((a) => (muscle !== "" ? a.muscle_system?.includes(muscle) : a))
      .filter((a) =>
        category !== "" ? a.category_id.findIndex((item) => item._id === category) >= 0 : a,
      )
      .filter((a) => (equipment !== "" ? a.equipment?.includes(equipment) : a));
    setNPages(Math.ceil(length / recordsPerPage));
    setCurrentPage(1);
  };

  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) setCurrentPage(nPages);
  };
  return (
    <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} size="6xl">
      <ModalOverlay />
      <ModalContent borderTop="15px solid #b42519" borderRadius={10}>
        <ModalHeader>{t("choose_exercise")}</ModalHeader>
        <ModalCloseButton />
        {isLoading && <p>Loading data</p>}
        {isError && <p>There is an error</p>}
        {isSuccess && (
          <>
            <ModalBody>
              <ErrorBoundary>
                <Flex align="center" justify="space-between" w="100%" mb="1rem" gridGap={4}>
                  <Flex alignItems="center" gridGap="10px" w="50%">
                    <InputGroup w="50%">
                      <InputLeftElement pointerEvents="none">
                        <Search2Icon color="gray.600" />
                      </InputLeftElement>
                      <Input
                        variant="outline"
                        type="text"
                        placeholder="Search..."
                        value={search}
                        onChange={(e) =>
                          changeFilters({
                            name: e.target.value,
                            action: actionFilter,
                            category: categoryFilter,
                            equipment: equipmentFilter,
                            muscle: muscleFilter,
                          })
                        }
                      />
                    </InputGroup>
                    <Button
                      colorScheme={
                        actionFilter !== "" ||
                        categoryFilter !== "" ||
                        muscleFilter !== "" ||
                        equipmentFilter !== ""
                          ? "green"
                          : "blackAlpha"
                      }
                      size="xs"
                      leftIcon={<FilterAltIcon fontSize="inherit" />}
                      onClick={() => setShowFilters(!showFilters)}>
                      {t("filters")}
                    </Button>
                  </Flex>

                  <div>
                    <IconButton
                      isRound
                      variant="ghost"
                      colorScheme="blackAlpha"
                      aria-label="Done"
                      fontSize="20px"
                      icon={displayMode === "grid" ? <GridViewIcon /> : <ViewListIcon />}
                      onClick={() =>
                        setDisplayMode((prev) => {
                          localStorage.setItem("displayMode", prev === "grid" ? "list" : "grid");
                          return prev === "grid" ? "list" : "grid";
                        })
                      }
                    />
                    <Button
                      ml={3}
                      rightIcon={<AddIcon />}
                      colorScheme="ptRed"
                      onClick={() => onOpenAddNew()}>
                      {t("add_exercise")}
                    </Button>
                  </div>
                </Flex>
                <Flex gridGap={4} alignItems="end" minH="42px" wrap="wrap" mb="1rem">
                  {showFilters && (
                    <>
                      <div>
                        <p style={{ fontSize: "10px" }}>{t("muscle_system")}</p>
                        <Select
                          size="xs"
                          variant="filled"
                          value={muscleFilter}
                          onChange={(e) => setMuscleFilter(e.target.value)}>
                          <option value="">{t("all")}</option>
                          <option value="opt_all_body">{t("opt_all_body")}</option>
                          <option value="opt_lower_body">{t("opt_lower_body")}</option>
                          <option value="opt_legs">{t("opt_legs")}</option>
                          <option value="opt_posterior_core">{t("opt_posterior_core")}</option>
                          <option value="opt_lumbar">{t("opt_lumbar")}</option>
                          <option value="opt_gluteus">{t("opt_gluteus")}</option>
                          <option value="opt_hamstring">{t("opt_hamstring")}</option>
                          <option value="opt_quadriceps">{t("opt_quadriceps")}</option>
                          <option value="opt_adductors">{t("opt_adductors")}</option>
                          <option value="opt_abductors">{t("opt_abductors")}</option>
                          <option value="opt_calves">{t("opt_calves")}</option>
                          <option value="opt_upper_body">{t("opt_upper_body")}</option>
                          <option value="opt_arms">{t("opt_arms")}</option>
                          <option value="opt_chest">{t("opt_chest")}</option>
                          <option value="opt_back">{t("opt_back")}</option>
                          <option value="opt_shoulder">{t("opt_shoulder")}</option>
                          <option value="opt_biceps">{t("opt_biceps")}</option>
                          <option value="opt_triceps">{t("opt_triceps")}</option>
                          <option value="opt_forearms">{t("opt_forearms")}</option>
                          <option value="opt_anterior_core">{t("opt_anterior_core")}</option>
                          <option value="opt_abs">{t("opt_abs")}</option>
                          <option value="opt_hip_flexors">{t("opt_hip_flexors")}</option>
                          <option value="opt_lateral_core">{t("opt_lateral_core")}</option>
                          <option value="opt_rotational_core">{t("opt_rotational_core")}</option>
                          <option value="opt_other">{t("opt_other")}</option>
                        </Select>
                      </div>

                      <div>
                        <p style={{ fontSize: "10px" }}>{t("categories")}</p>
                        <Select
                          size="xs"
                          variant="filled"
                          value={categoryFilter}
                          onChange={(e) => setCategoryFilter(e.target.value)}>
                          <option value="">{t("all")}</option>
                          <option value="6171d84aec64ada0efa05721">{t("opt_traditional")}</option>
                          <option value="61a62b7fb2f0481a2d9db38e">{t("opt_functional")}</option>
                          <option value="61be5e479abd1ebc3d540764">{t("opt_rehab")}</option>
                          <option value="61a62ba3b2f0481a2d9db38f">{t("opt_power")}</option>
                          <option value="61be5e799abd1ebc3d540767">{t("opt_metabolic")}</option>
                          <option value="61be5e969abd1ebc3d54076a">{t("opt_saq")}</option>
                          <option value="669a8c4f1d9afe6f1f05328a">
                            {t("opt_flexibility_mobility")}
                          </option>
                          <option value="66bd27617fa55c9d9bd4b809">{t("opt_cardio")}</option>
                          <option value="669a90631d9afe6f1f05328c">{t("opt_other")}</option>
                        </Select>
                      </div>

                      <div>
                        <p style={{ fontSize: "10px" }}>{t("action")}</p>
                        <Select
                          size="xs"
                          variant="filled"
                          value={actionFilter}
                          onChange={(e) => setActionFilter(e.target.value)}>
                          <option value="">{t("all")}</option>
                          <option value="opt_level_change">{t("opt_level_change")}</option>
                          <option value="opt_hip_hinge">{t("opt_hip_hinge")}</option>
                          <option value="opt_push">{t("opt_push")}</option>
                          <option value="opt_pull">{t("opt_pull")}</option>
                          <option value="opt_push_pull">{t("opt_push_pull")}</option>
                          <option value="opt_core_stiffness">{t("opt_core_stiffness")}</option>
                          <option value="opt_rotation">{t("opt_rotation")}</option>
                          <option value="opt_locomotion">{t("opt_locomotion")}</option>
                          <option value="opt_skip">{t("opt_skip")}</option>
                          <option value="opt_jumps">{t("opt_jumps")}</option>
                          <option value="opt_tumbling">{t("opt_tumbling")}</option>
                          <option value="opt_isometric">{t("opt_isometric")}</option>
                          <option value="opt_other">{t("opt_other")}</option>
                        </Select>
                      </div>
                      <div>
                        <p style={{ fontSize: "10px" }}>{t("equipment")}</p>
                        <Select
                          size="xs"
                          variant="filled"
                          value={equipmentFilter}
                          onChange={(e) => setEquipmentFilter(e.target.value)}>
                          <option value="">{t("all")}</option>
                          <option value="opt_bw">{t("opt_bw")}</option>
                          <option value="opt_bb">{t("opt_bb")}</option>
                          <option value="opt_db/kb">{t("opt_db/kb")}</option>
                          <option value="opt_m">{t("opt_m")}</option>
                          <option value="opt_bp">{t("opt_bp")}</option>
                          <option value="opt_rb">{t("opt_rb")}</option>
                          <option value="opt_mb">{t("opt_mb")}</option>
                          <option value="opt_sb">{t("opt_sb")}</option>
                          <option value="opt_plate">{t("opt_plate")}</option>
                          <option value="opt_sus">{t("opt_sus")}</option>
                          <option value="opt_ropes">{t("opt_ropes")}</option>
                          <option value="opt_ez">{t("opt_ez")}</option>
                          <option value="opt_sm">{t("opt_sm")}</option>
                          <option value="opt_sled">{t("opt_sled")}</option>
                          <option value="opt_lm">{t("opt_lm")}</option>
                          <option value="opt_hg360">{t("opt_hg360")}</option>
                          <option value="opt_agl">{t("opt_agl")}</option>
                          <option value="opt_cones">{t("opt_cones")}</option>
                          <option value="opt_hur">{t("opt_hur")}</option>
                          <option value="opt_multiple">{t("opt_multiple")}</option>
                          <option value="opt_other">{t("opt_other")}</option>
                        </Select>
                      </div>
                    </>
                  )}
                </Flex>
                <SimpleGrid
                  columns={displayMode === "grid" ? [1, 2, 3, 4] : 1}
                  spacing={4}
                  w="100%">
                  {data
                    .filter((a) => a.name[lang]?.toLowerCase().includes(search.toLowerCase()))
                    .filter((a) => (actionFilter !== "" ? a.action?.includes(actionFilter) : a))
                    .filter((a) =>
                      muscleFilter !== "" ? a.muscle_system?.includes(muscleFilter) : a,
                    )
                    .filter((a) =>
                      categoryFilter !== ""
                        ? a.category_id.findIndex((item) => item._id === categoryFilter) >= 0
                        : a,
                    )
                    .filter((a) =>
                      equipmentFilter !== "" ? a.equipment?.includes(equipmentFilter) : a,
                    )
                    .slice(indexOfFirstRecord, indexOfLastRecord)
                    .map((exercise) => (
                      <div aria-hidden="true">
                        <ExerciseCard
                          isSelect
                          selectExercise={handleEvent}
                          exercise={exercise}
                          key={exercise._id}
                          displayMode={displayMode}
                        />
                      </div>
                    ))}
                </SimpleGrid>
                <nav>
                  <ul className="pagination">
                    <li>
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="blackAlpha"
                        aria-label="Done"
                        fontSize="20px"
                        disabled={currentPage === 1}
                        icon={<FirstPageIcon />}
                        onClick={goToFirstPage}
                      />
                    </li>
                    <li>
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="blackAlpha"
                        aria-label="Done"
                        fontSize="20px"
                        disabled={currentPage === 1}
                        icon={<ArrowBackIosIcon />}
                        onClick={goToPrevPage}
                      />
                    </li>
                    <li>
                      {currentPage}/{nPages}
                    </li>
                    <li>
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="blackAlpha"
                        aria-label="Done"
                        fontSize="20px"
                        disabled={currentPage === nPages}
                        icon={<ArrowForwardIosIcon />}
                        onClick={goToNextPage}
                      />
                    </li>
                    <li>
                      <IconButton
                        isRound
                        variant="ghost"
                        colorScheme="blackAlpha"
                        aria-label="Done"
                        fontSize="20px"
                        disabled={currentPage === nPages}
                        icon={<LastPageIcon />}
                        onClick={goToLastPage}
                      />
                    </li>
                  </ul>
                </nav>
                {isOpenAddNew && (
                  <AddNewExerciseModal
                    onCloseMain={onClose}
                    selectExercise={handleEvent}
                    isOpen={isOpenAddNew}
                    onClose={onCloseAddNew}
                  />
                )}
              </ErrorBoundary>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="ptRed" mr={3} onClick={onClose}>
                {t("close")}
              </Button>
            </ModalFooter>
          </>
        )}
      </ModalContent>
    </Modal>
  );
};

export default ExerciseListModal;
