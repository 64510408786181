import React from "react";
import { Skeleton, Stack } from "@chakra-ui/react";
import { container } from "./Combine.module.css";

const LoadingProgramList = () => {
  return (
    <>
      <div className={container}>
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" width="30%" />
          <Skeleton height="20px" width="60%" />
        </Stack>
      </div>
      <div className={container}>
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" width="30%" />
          <Skeleton height="20px" width="60%" />
        </Stack>
      </div>
      <div className={container}>
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" width="30%" />
          <Skeleton height="20px" width="60%" />
        </Stack>
      </div>
      <div className={container}>
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" width="30%" />
          <Skeleton height="20px" width="60%" />
        </Stack>
      </div>      <div className={container}>
        <Stack>
          <Skeleton height="20px" />
          <Skeleton height="20px" width="30%" />
          <Skeleton height="20px" width="60%" />
        </Stack>
      </div>
    </>
  );
};

export default LoadingProgramList;
