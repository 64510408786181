import instance from "./_axiosConfig";

const api = instance;
const getTrainer = async (id) => {
  try {
    const response = await api.get(`/trainer/${id}`);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const getTrainerByUserId = async (idUser) => {
  try {
    const response = await api.get(`/trainer/user/${idUser}`);
    return response.data.trainer;
  } catch (error) {
    return error.response;
  }
};

const createTrainer = async (data) => {
  try {
    const response = await api.post("/trainer/", data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
const updateTrainer = async (id, data) => {
  try {
    const response = await api.put(`/trainer/${id}`, data);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const getAllTrainers = async () => {
  try {
    const response = await api.get("/trainer/");
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const deleteTrainer = async (idUser) => {
  try {
    const response = await api.delete(`/trainer/${idUser}`);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export {
  createTrainer,
  deleteTrainer,
  getTrainer,
  getTrainerByUserId,
  updateTrainer,
  getAllTrainers,
};
