/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { shape, string } from "prop-types";
import { IconButton, Flex, useDisclosure } from "@chakra-ui/react";
import Popup from "components/exercises/Popup";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { superSetColors, superSetColorsHex } from "theme/commonStyles";
import { Draggable, Droppable } from "react-beautiful-dnd";
import useLang from "hooks/useLang";
import styled from "styled-components";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BrushIcon from "assets/icons/brush.png";
import stylesExercise from "../../../clients/ClientDashboard/ExerciseDisplay/ExerciseDisplay.module.css";
import EditExercise from "./editExercise/editExercise";

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) return "#FFEBE6";
  if (isDraggingFrom) return "#E6FCF8";
  return "transparent";
};

const getBackgroundColorSubItem = (isDragging, isGroupedOver) => {
  if (isDragging) return "white";
  if (isGroupedOver) return "#FFFFFF";

  return "#FFFFFF";
};

const Container = styled.a`
  border-radius: 20px;
  background-color: ${(props) =>
    getBackgroundColorSubItem(props.isDragging, props.isGroupedOver, props.colors)};
  box-shadow: ${({ isDragging }) => (isDragging ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : "none")};
  box-sizing: border-box;
  padding: 0px;
  min-height: 40px;
  margin-bottom: 10px;
  user-select: none;
  width: 100%;

  /* anchor overrides */
  color: #091e42;

  &:hover,
  &:active {
    color: #091e42;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: white;
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;

function getStyle(provided, style) {
  if (!style) return provided.draggableProps.style;

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

const Wrapper = styled.div`
  background-color: ${(props) => getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : "inherit")};
  padding: 0px;
  border: 0px;
  padding-bottom: 0;
  transition:
    background-color 0.2s ease,
    opacity 0.1s ease;
  user-select: none;
  width: 100%;
`;

const SubItem = ({ exerciselist, day, week, handleUpdateExercise }) => {
  const [exerciseData, setExerciseData] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { t, lang } = useLang();
  const style = "";

  const editExercise = (exercise) => {
    const i = exerciselist.exercises.findIndex((a) => a._id === exercise._id);
    exerciselist.exercises.splice(i, 1, exercise);
    handleUpdateExercise(exerciselist);
  };

  const deleteExercise = (exercise) => {
    const i = exerciselist.exercises.findIndex((a) => a._id === exercise._id);
    exerciselist.exercises.splice(i, 1);
    handleUpdateExercise(exerciselist);
  };
  const openInfoExercise = (item) => {
    const exerciseInformation = {
      id_exercise: { notes_exercise: item.notes_exercise, ...item.id_exercise },
    };
    setExerciseData(exerciseInformation.id_exercise);
    onOpen();
  };
  return (
    <>
      <Droppable key={day} droppableId={`${day}-${week}`} type="EXERCISE">
        {(dropProvided, dropSnapshot) => (
          <Wrapper
            isDraggingOver={dropSnapshot.isDraggingOver}
            style={{ backgroundColor: dropSnapshot.isDragging ? "transparent" : null }}
            isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
            {...dropProvided.droppableProps}>
            <div ref={dropProvided.innerRef} className="exercises_dropZone">
              {exerciselist.exercises?.map((exercise, index) => (
                <Draggable key={exercise._id} draggableId={exercise._id} index={index}>
                  {(dragProvided, dragSnapshot) => (
                    <Container
                      isDragging={dragSnapshot.isDragging}
                      ref={dragProvided.innerRef}
                      style={getStyle(dragProvided, style)}
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                      data-is-dragging={dragSnapshot.isDragging}
                      isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                      data-testid={exercise._id}
                      data-index={index}
                      aria-label={`${exercise?.id_exercise?.name[lang]}`}>
                      <article
                        className={stylesExercise.container}
                        style={{ backgroundColor: `${superSetColorsHex[exercise.colors.hard]}` }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: "space-between",
                          }}>
                          <h5 style={{ fontWeight: "bold" }}>
                            {exercise?.id_exercise?.name[lang] || (
                              <span style={{ textTransform: "uppercase", color: "red" }}>
                                {t("undefined_exercise")}
                              </span>
                            )}
                          </h5>
                          <Flex gridGap="5px">
                            <IconButton
                              onClick={() => openInfoExercise(exercise)}
                              size="xs"
                              variant="ghost"
                              icon={<VisibilityIcon fontSize="inherit" />}
                            />
                            <EditExercise
                              exercise={exercise}
                              saveExercise={editExercise}
                              date={{ day, week }}
                              key={exercise._id}
                              idx={index}
                              deleteExercise={deleteExercise}
                            />
                          </Flex>

                          {/* <Menu>
                            <MenuButton
                              as={IconButton}
                              aria-label="Options"
                              icon={
                                <img src={BrushIcon} alt="brush icon" style={{ width: "14px" }} />
                              }
                              size="xs"
                            />
                            <MenuList>
                              {superSetColors.map((color, id) => (
                                <MenuItem
                                  onClick={() => {
                                    handleUpdateExercise(
                                      editExercise({
                                        ...exercise,
                                        colors: { hard: color, soft: color },
                                      }),
                                    );
                                  }}>
                                  {color === "white" ? (
                                    <Badge colorScheme={color}>None</Badge>
                                  ) : (
                                    <Badge colorScheme={color}>
                                      Circuit {id + 1} {color}
                                    </Badge>
                                  )}
                                </MenuItem>
                              ))}
                            </MenuList>
                          </Menu> */}
                        </div>

                        <div className={stylesExercise.list}>
                          <p className={stylesExercise.exerciseData}>
                            {exercise.sets[0].load !== "" && exercise.sets[0].load !== "0" ? (
                              <>
                                {exercise.sets.length} Sets x{" "}
                                {exercise.sets?.map((s) => (
                                  <span>
                                    {s.load}
                                    {s.typeWeight === "others" ? "" : s.typeWeight}/{s.reps}
                                    {s?.typeReps ?? ""},{" "}
                                  </span>
                                ))}
                              </>
                            ) : (
                              <>
                                {exercise.sets.length} Sets x {exercise.sets[0].reps}
                                {exercise.sets[0].typeReps ?? ""}
                              </>
                            )}
                          </p>
                        </div>
                      </article>
                    </Container>
                  )}
                </Draggable>
              ))}
              {dropProvided.placeholder}
            </div>
          </Wrapper>
        )}
      </Droppable>
      {isOpen && exerciseData && (
        <Popup exercise={exerciseData} isOpen={isOpen} onClose={onClose} mode="info" />
      )}
    </>
  );
};

export default SubItem;
