/* eslint-disable no-unused-vars */
import React from "react";
import { Controller } from "react-hook-form";
import { Input } from "@chakra-ui/react";
import propTypes from "prop-types";
import useLang from "hooks/useLang";
import dayjs from "dayjs";

import DatePicker from "react-date-picker";
import { inputStyle } from "theme/commonStyles";

import CustomInput from "../main/CustomInput";

const DateInput = ({ control, def, name, place, required, rules }) => {
  const { lang } = useLang();
  return (
    <Controller
      control={control}
      defaultValue={def ?? ""}
      name={name}
      rules={rules}
      render={({ field }) => (
        <CustomInput label={place} required={required}>
          {/* <Input type="date" /> */}
          <div
            style={{
              backgroundColor: "#ececec",
              height: "40px",
              padding: "0px 1rem",
              borderRadius: "5px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
            }}>
            <DatePicker format={lang === "es" ? "dd/MM/yyy" : "MM/dd/yyy"} {...field} />
          </div>
        </CustomInput>
      )}
    />
  );
};

DateInput.defaultProps = {
  required: true,
  rules: null,
};

DateInput.propTypes = {
  control: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  place: propTypes.string.isRequired,
  def: propTypes.string.isRequired,
  required: propTypes.bool,
  rules: propTypes.shape({}),
};

export default DateInput;
