import React, { useState } from "react";
import {
  Flex,
  Button,
  Menu as MenuLang,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Avatar,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Tooltip,
} from "@chakra-ui/react";
import LogoutIcon from "@mui/icons-material/Logout";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import languages from "utils/languages";

import { updateUser } from "services/user";
import { useAuth } from "utils/authContext";
import { deleteInfo } from "reducers/user.reducer";
import { logout } from "reducers/trainer.reducer";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import UsaLogo from "assets/eeuu.png";
import SpainLogo from "assets/spain.png";
import useLang from "hooks/useLang";
import styles from "./IconsNav.module.scss";

const IconsNav = () => {
  const user = useSelector((state) => state.user);
  const auth = useAuth();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const onLogout = () => {
    dispatch(deleteInfo());
    dispatch(logout());
    auth.signout(() => console.log("out"));
    localStorage.removeItem("userAuth");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("role");
  };
  const { lang } = useLang();

  const [flag, setFlag] = useState("us");

  const updateLangUser = async (code) => {
    try {
      const response = await updateUser(user.id_user, { language: code });
      if (response.data) throw new Error(response.data.error);
    } catch (err) {
      console.log(err);
    }
  };

  const onLanguageChange = (code) => {
    setFlag(code === "en" ? "us" : code);
    document.querySelector("html").lang = code;
    i18next.changeLanguage(code);
    updateLangUser(code);
  };

  return (
    <>
      <Flex className={styles.headerNav}>
        <Button
          variant="ghost"
          as={Link}
          outline="none"
          to="/user/profile"
          color="#8F8F8F"
          display="flex"
          justifyContent="center"
          alignItems="center">
          <Avatar
            name={user.name}
            src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${user?.pic || ""}`}
            width="30px"
            height="30px"
            mr={2}
          />

          <span>
            {t("hello")}, {user.name}
          </span>
        </Button>
        <div>
          <MenuLang>
            <MenuButton as={Button} variant="ghost" fontSize="40px" padding={0} borderRadius="full">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  name="english lang"
                  src={lang === "en" ? UsaLogo : SpainLogo}
                  width="30px"
                  height="30px"
                />
              </Box>
            </MenuButton>
            <MenuList zIndex="101">
              {languages.map(({ code, name, countryCode }) => (
                <MenuItem key={code} color="ptText.500" onClick={() => onLanguageChange(code)}>
                  <span className={`flag-icon flag-icon-${countryCode}`} />
                  <span>{name}</span>
                </MenuItem>
              ))}
            </MenuList>
          </MenuLang>
          <Tooltip label={t("logout")}>
            <Button onClick={() => onOpen()} border="none" variant="ghost">
              <LogoutIcon color="gray" />
            </Button>
          </Tooltip>
        </div>
      </Flex>
      {isOpen && (
        <Modal closeOnOverlayClick={false} isOpen={isOpen} onClose={onClose} isCentered>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader textAlign="center" fontSize="2xl">
              {t("message_logout")}
            </ModalHeader>
            <ModalFooter justifyContent="center" style={{ gap: "20px" }}>
              <Button onClick={onLogout} bg="#b42519" as={Link} to="/login" colorScheme="blue">
                {t("yes")}
              </Button>
              <Button onClick={onClose}>{t("no")}</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
      {/* {user.token && user.role && (
        <Flex
          justifyContent="center"
          alignItems="center"
          bg="white"
          borderBottom="1px solid #f6f6f6"
          pt={5}>
          <ButtonGroup
            as="nav"
            size="sm"
            display="flex"
            flexDirection="row"
            color="#8F8F8F"
            style={{ gap: "12px" }}>
            <Button
              variant="ghost"
              borderRadius={0}
              border="2px solid white"
              _hover={{ borderBottom: "2px solid #b42519" }}
              as={Link}
              outline="none"
              to="/user/clients">
              {t("clients")}
            </Button>
            <Button
              variant="ghost"
              borderRadius={0}
              border="2px solid white"
              _hover={{ borderBottom: "2px solid #b42519" }}
              as={Link}
              outline="none"
              to="/user/exercises">
              {t("exercisesDB")}
            </Button>
            <Button
              variant="ghost"
              borderRadius={0}
              border="2px solid white"
              _hover={{ borderBottom: "2px solid #b42519" }}
              as={Link}
              outline="none"
              to="/user/evaluation">
              {t("evaluation")}
            </Button>
            <Button
              variant="ghost"
              borderRadius={0}
              border="2px solid white"
              _hover={{ borderBottom: "2px solid #b42519" }}
              as={Link}
              outline="none"
              to="/user/programs">
              {t("programs")}
            </Button>
            {user.role === "trainer" && (
              <MenuLang>
                <MenuButton
                  as={Button}
                  rightIcon={<ChevronDownIcon />}
                  variant="ghost"
                  size="sm"
                  borderRadius={0}
                  border="2px solid white"
                  _hover={{ borderBottom: "2px solid #b42519" }}>
                  {t("planning")}
                </MenuButton>
                <MenuList>
                  <MenuItem as={Link} to="/user/planning/month">
                    {t("planning_month")}
                  </MenuItem>
                  <MenuItem as={Link} to="/user/planning/week">
                    {t("planning_week")}
                  </MenuItem>
                  <MenuItem as={Link} to="/user/planning/day">
                    {t("planning_day")}
                  </MenuItem>
                  <MenuItem as={Link} to="/user/planning">
                    {t("planning_general")}
                  </MenuItem>
                </MenuList>
              </MenuLang>
            )}
          </ButtonGroup>
        </Flex>
      )} */}
      {/* <Menu onClose={onClose} isOpen={true} /> */}
    </>
  );
};

export default IconsNav;
