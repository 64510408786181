import React, { useEffect } from "react";
import ClientDashboard from "./ClientDashboard";

const Clients = () => {
  useEffect(() => {
    document.title = "Perfect Trainer App | Clients";
  }, []);
  return <ClientDashboard />;
};

export default Clients;
