/* eslint-disable no-return-assign */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useReducer, useState, useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  Text,
  Tooltip,
  useDisclosure,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Badge,
  IconButton,
  Icon,
} from "@chakra-ui/react";
import styled from "styled-components";
import EditIcon from "@material-ui/icons/Edit";
import CommentIcon from "@mui/icons-material/Comment";
import BrushIcon from "assets/icons/brush.png";
import useLang from "hooks/useLang";
import { superSetColors, superSetColorsHex } from "theme/commonStyles";
import {
  container,
  main,
  notes,
  notes__button,
} from "../../../../clients/ClientDashboard/DayDisplay/DayDisplay.module.css";
import stylesExercise from "../../../../clients/ClientDashboard/ExerciseDisplay/ExerciseDisplay.module.css";
import EditDay from "./EditDay/EditDay";

const getBackgroundColor = (isDragging, isGroupedOver, authorColors) => {
  if (isDragging) {
    return "white";
  }

  if (isGroupedOver) {
    return "#FFFFFF";
  }

  return "#FFFFFF";
};

const getBorderColor = (isDragging) => (isDragging ? "white" : "transparent");

const imageSize = 40;

const Container = styled.a`
  border-radius: 20px;
  border-color: ${(props) => props.colors.hard};
  background-color: ${(props) =>
    getBackgroundColor(props.isDragging, props.isGroupedOver, props.colors)};
  box-shadow: ${({ isDragging }) => (isDragging ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : "none")};
  box-sizing: border-box;
  padding: 0px;
  min-height: ${imageSize}px;
  margin-bottom: 10px;
  user-select: none;

  /* anchor overrides */
  color: #091e42;

  &:hover,
  &:active {
    color: #091e42;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: white;
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;

function getStyle(provided, style) {
  if (!style) {
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

// Previously this extended React.Component
// That was a good thing, because using React.PureComponent can hide
// issues with the selectors. However, moving it over does can considerable
// performance improvements when reordering big lists (400ms => 200ms)
// Need to be super sure we are not relying on PureComponent here for
// things we should be doing in the selector as we do not know if consumers
// will be using PureComponent
function QuoteItem(props) {
  const {
    quote,
    isDragging,
    isGroupedOver,
    provided,
    style,
    isClone,
    index,
    columns,
    setColumns,
    cloneState,
    listDaySelected,
    isChecked,
    onChange,
  } = props;

  const [isNoteDisplay, toggleNoteDisplay] = useState(false);
  const { lang, t } = useLang();
  const [value, setValue] = useState(quote);

  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    toggleNoteDisplay(false);
  }, [lang]);

  return (
    <Container
      isDragging={isDragging}
      isGroupedOver={isGroupedOver}
      isClone={isClone}
      colors={value?.colors || "red"}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={getStyle(provided, style)}
      data-is-dragging={isDragging}
      data-testid={quote._id}
      data-index={index}
      // aria-label={`${quote.author.name}`}
    >
      <article
        className={container}
        style={{ width: "320px", backgroundColor: `${superSetColorsHex[value.colors.hard]}` }}>
        <Box display="flex" flexDirection="row" fontSize=" 0.8rem" justifyContent="space-between">
          <Box display="flex" justifyContent="center" alignItems="center" style={{ gap: "10px" }}>
            {cloneState ? (
              <Checkbox
                colorScheme="red"
                value={quote}
                onChange={onChange}
                defaultChecked={isChecked}
              />
            ) : (
              <Menu>
                <MenuButton
                  as={IconButton}
                  aria-label="Options"
                  icon={<img src={BrushIcon} alt="brush icon" style={{ width: "14px" }} />}
                  size="xs"
                />
                <MenuList>
                  {superSetColors.map((color, idx) => {
                    if (color === "white") {
                      return (
                        <MenuItem
                          onClick={() => {
                            quote.colors = { hard: color, soft: color };
                            setValue((prev) => ({ ...prev, colors: { hard: color, soft: color } }));
                          }}>
                          None <Badge colorScheme={color}>{color}</Badge>
                        </MenuItem>
                      );
                    }
                    return (
                      <MenuItem
                        onClick={() => {
                          quote.colors = { hard: color, soft: color };
                          setValue((prev) => ({ ...prev, colors: { hard: color, soft: color } }));
                        }}>
                        Circuit <Badge colorScheme={color}>{` ${idx + 1} ${color}`}</Badge>
                      </MenuItem>
                    );
                  })}
                </MenuList>
              </Menu>
            )}
            <Text fontWeight="bold">{value.id_exercise.name[lang]}</Text>
          </Box>
        </Box>
        <div className={main}>
          {value.sets?.map((set) => (
            <article className={stylesExercise.list}>
              <p>
                <span className={stylesExercise.set}>Set {set.set_number}</span>
                {/* {exercise.id_exercise.name[lang]} */}
                <span>
                  {set.load}
                  {set.typeWeight === "others" ? "" : set.typeWeight} / {set.reps}
                  {set?.typeReps ?? ""}
                </span>
              </p>
            </article>
          ))}
        </div>
      </article>
      {/* <EditDay
        isOpen={isOpen}
        onClose={onClose}
        quotes={[quote]}
        columns={columns}
        setColumns={setColumns}
      /> */}
    </Container>
  );
}

export default React.memo(QuoteItem);
