import React from 'react';
import { Button, Flex } from '@chakra-ui/react';
import { Link } from 'react-router-dom';
import propTypes from 'prop-types';
import styles from './Options.module.scss';

const Optionimages = ({ url, textButton, pic }) => (
  <Flex className={`${styles.imagecontainer} ${pic === 'user' ? styles.userGuide : styles.terms}`}>
    <Link to={url}>
      <Button
        type="button"
        colorScheme="ptRed"
      >
        {textButton}
      </Button>
    </Link>
  </Flex>
);

Optionimages.propTypes = {
  pic: propTypes.string.isRequired,
  url: propTypes.func.isRequired,
  textButton: propTypes.func.isRequired,
};

export default Optionimages;
