/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable import/prefer-default-export */
export const generatePromptNutrition = (params) => {
  const { lang, protein, fat, carbs, meal, shake, foodAvoid, foodInclude, calories, diet, snack } =
    params;

  let message = ''

  if (lang === "en") {
    message = `Give me a ${calories} calorie diet (please be exact). Made of ${protein}% of protein, ${carbs}% of carbohydrates, ${fat}% or fats, ${diet && `a ${diet} as style eating plan.`} Include ${meal} meals, ${shake} Shakes, and ${snack} snacks. ${foodAvoid.length >= 1 && `Avoid ${foodAvoid.join(", ")} foods`} ${foodInclude.length >= 1 && `and include ${foodInclude.join(", ")}`} foods. Conclude with the total daily calories, grams and percentage of each macronutrient, likewise, at the end of each meal, snack and meal, add its respective total calories, grams and percentage of each macronutrient, and have it have a structure, that if for example the title total, then on the next line the grams of the macronutrient and in parentheses the percentage, and then on the line below continue with the same strictness with the next macronutrient. List the meals first, then the snacks or shakes. Name each meal with a number - do not use the name of a meal, that each plate of food you mention, for example a plate of turkey, salad, nuts, each of the foods you mention should say in parentheses the calories, carbohydrates, proteins and fats that that mentioned meal contains. Include instructions that explain that you can eat the meals in the order you want, for example, you can place the snacks or shakes between any main meal, and also that the unit of measurement of the energy used is always calories, not kilocalories, remove markdown formatting (Don't say it verbatim in the answer)`
    return message
  }

  if (lang === "es") {
    message = `Dame una dieta de ${calories} calorías (se exacto por favor). Compuesta por ${protein}% de proteínas, ${carbs}% de carbohidratos, ${fat}% de grasas, ${diet && `una ${diet} como plan de alimentación de estilo`}. Incluye ${meal} comida, ${shake} batido y ${snack} merienda. ${foodAvoid.length >= 1 && `Evita estos alimentos ${foodAvoid.join(", ")}`} ${foodInclude.length >= 1 && `e incluye estos alimentos ${foodInclude.join(", ")}`}. Concluye con el total de calorías diarias, gramos y porcentaje de cada macronutriente, de igual manera, al final de cada comida, merienda y batido, agrega su respectivo total de calorías, gramos y porcentaje de cada macronutriente, y que esta tenga una estructura, que si por ejemplo el titulo total, luego en la siguiente linea los gramos del macronutriente y entre paréntesis el porcentaje, y luego en la linea de abajo siga con la misma estructura con el siguiente macronutriente. Enumere primero las comidas, luego las meriendas o batidos. Nombra cada comida con un número - no uses el nombre de una comida, que cada plato de comida que mencione, por ejemplo que un plato de pechuga, ensalada, nueces, cada uno de los alimentos que menciones que diga entre paréntesis las calorías, carbohidratos, proteínas y grasas que contiene esa comida mencionada. Incluye instrucciones que expliquen que puedes comer las comidas en el orden que quieras, por ejemplo, puedes colocar las meriendas o los batidos entre cualquier comida principal, y además que la unidad de medida de la energía empleada siempre sea calorías, no kilocalorías, remueve el formato markdown (no lo digas textualmente en la respesta)`
    return message
  }

};

export const generateComplexPromptNutrition = (params) => {
  const {
    lang,
    protein,
    fat,
    carbs,
    meal,
    shake,
    foodAvoid,
    foodInclude,
    calories,
    diet,
    snack,
    complexForm,
  } = params;

  const message = `Provide an ${diet} style nutrition plan, for a ${complexForm.age} yr old ${complexForm.gender}, with a body fat of ${complexForm.body_fat} % (can leave out is they don’t know), body weight of ${complexForm.body_weight}, height of ${complexForm.body_height}.  Activity level is ${complexForm.activity_level}. Make the diet ${carbs} % Carbs, ${protein} % Protein, ${fat} % Fat and provide amounts and nutrient content.  Please make sure to include ${shake} protein shakes as meal – please use Whey protein and add quantity and nutritional profile. Don’t include ${foodAvoid} and focus on ${foodInclude}. Provide menus for O days.  What is the daily calories needed to safely drop P each week.  What is the estimated time to safely lose Q pounds?  Please use the Mifflin-St Jeor Equation if bodyfat is NOT provided, and the Mifflin-St Jeor Equation Katch-McArdle Formula if body fat it provided. `;

  return message;
};
