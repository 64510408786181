/* eslint-disable react/no-children-prop */
import "./App.css";
import "./theme/index.scss";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Switch, Route, useHistory } from "react-router-dom";
import { deleteInfo } from "reducers/user.reducer";
import { logout } from "reducers/trainer.reducer";
import Help from "domains/help";
import { useAuth } from "utils/authContext";
import reLoggedUser from "utils/reLoggedUser";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalCloseButton,
  ModalOverlay,
  Heading,
  Text,
  Box,
  Button,
  Flex,
  Divider,
  useDisclosure,
} from "@chakra-ui/react";
import useLang from "hooks/useLang";
import { deleteUser } from "services/user";
import Header from "components/header";
import Sidebar from "components/sidebar";
import Home from "./domains/home";
import Notification from "./components/common/Notification";
import User from "./domains/user";
import Loader from "./components/common/Loader";

const App = () => {
  const { t, lang } = useLang();
  const { message, status } = useSelector(({ notification }) => notification);
  const dispatch = useDispatch();
  const auth = useAuth();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { membership = null, id_user, email } = useSelector(({ user }) => user);

  useEffect(async () => {
    const token = localStorage.getItem("userAuth");
    if (history.location.pathname.includes("user")) {
      if (!token) {
        history.replace("/login");
      } else {
        await reLoggedUser(token, dispatch, auth, history, () => {
          setIsLoading(false);
        });
      }
    }
    if (["/login", "/signup", "/changepass"].includes(history.location.pathname)) {
      if (token) {
        history.replace("/user/dashboard");
        await reLoggedUser(token, dispatch, auth, history, () => {
          setIsLoading(false);
        });
      }
    }
    setIsLoading(false);

    /* if (history.location.pathname.includes("/user")) {
      if (!token) {
        
      }
      await reLoggedUser(token, dispatch, auth, history);
      history.push("/user/dashboard");
    } else if (["/login", "/signup", "/changepass"].includes(history.location.pathname) && token) {
      await reLoggedUser(token, dispatch, auth, history);
      history.push("/user/dashboard");
    } else {
      setIsLoading(false);
    } */

    //  const trainerInfo = await getTrainerByUserId(response.id_user); */
    /* if (response.role === "admin" || (response.token && trainerInfo)) {
          const responseToSend = {
            ...response,
            ...trainerInfo,
          };
          console.log("get in response to send", responseToSend);
          dispatch(addInfo(responseToSend));
          auth.signin(() => {
            history.replace(from);
          });
        } else throw new Error("something went wrong"); */
    // console.log(decode);
    /* const expirationTime = exp * 10000 - 60000;
        if (Date.now() >= expirationTime) {
          notificationDisplay(dispatch, "Your session has expired", "error");
          dispatch(deleteInfo());
          dispatch(logout());
          localStorage.removeItem("userAuth");
          auth.signout(() => console.log("signing out through token expiration"));
        } */
  }, []);

  /*  useEffect(() => {
    const user = localStorage.getItem("userAuth");
    if (user) {
      dispatch(loading());
      reLoggedUser(user, dispatch, auth, history);
      dispatch(loaded());
    }
  }, []); */
  const onLogout = () => {
    dispatch(deleteInfo());
    dispatch(logout());
    auth.signout(() => console.log("out"));
    localStorage.removeItem("userAuth");
    history.push("/login");
  };
  const removeAccount = async () => {
    try {
      const response = await deleteUser(id_user);
      if (response.data) console.log(response);
      else onLogout();
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <>
      {message && <Notification message={message} status={status} />}
      {isLoading ? (
        <Loader />
      ) : (
        <Switch>
          <Route path="/user">
            <div className="content">
              <Sidebar />
              <div className="main-content" id="main_content_sections">
                <Header />
                <main>
                  <User />
                </main>
              </div>

              <Modal isOpen={isOpen} onClose={onClose}>
                <ModalOverlay />
                <ModalContent py={8}>
                  <ModalCloseButton />
                  <ModalBody>
                    <Text textAlign="center" my={4}>
                      {t("confirmDelete")}
                    </Text>
                    <Flex justifyContent="center" gridGap={4}>
                      <Button colorScheme="gray" onClick={onClose}>
                        {t("no")}
                      </Button>
                      <Button colorScheme="ptRed" onClick={removeAccount}>
                        {t("yes")}
                      </Button>
                    </Flex>
                  </ModalBody>
                </ModalContent>
              </Modal>
            </div>
          </Route>
          <Route path="/help" children={<Help />} />
          <Route path="/" children={<Home />} />
        </Switch>
      )}
    </>
  );
};

export default App;
