/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import { Divider, Flex, Heading, ListItem, Text, UnorderedList } from "@chakra-ui/react";
import useLang from "hooks/useLang";
import moment from "moment";

const GoalResume = ({ goal }) => {
  const { t, lang } = useLang();
  return (
    <>
      {moment(goal?.created_at).format(lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY")}
      <Heading textAlign="center">{t("goalForm.clientGoals")}</Heading>

      <Heading as="h6" size="sm">
        {t("goalForm.primaryGoals")}
      </Heading>
      <UnorderedList>
        {goal.primary_goals.map((primaryGoal, index) =>
          primaryGoal !== "" ? (
            <ListItem>
              <Text key={index}>{primaryGoal}</Text>
            </ListItem>
          ) : null,
        )}
      </UnorderedList>
      <Heading as="h6" size="sm">
        {t("goalForm.timeFrame")}
      </Heading>
      <UnorderedList>
        {goal.time_frame.map((timeFrame, index) =>
          timeFrame !== "" ? (
            <ListItem>
              <Text key={index}>{timeFrame}</Text>
            </ListItem>
          ) : null,
        )}
      </UnorderedList>
      <Heading as="h6" size="sm">
        {t("goalForm.measuresSuccess")}
      </Heading>
      <Text>{goal.measures_success}</Text>
      {/*  */}
      <Divider my={4} />
      <Heading as="h4" size="md" textAlign="center">
        {t("goalForm.bodyComposition")}
      </Heading>
      <Flex gridGap={4}>
        <Heading as="h6" size="sm">
          {t("goalForm.loseWeight")}
        </Heading>
        <Text>{goal.lose_weight}</Text>
      </Flex>

      <Flex gridGap={8} justifyContent="">
        <Flex gridGap={4}>
          <Heading as="h6" size="sm">
            {t("goalForm.loseInches")}
          </Heading>
          <Text>{goal.lose_inches}</Text>
        </Flex>
        <Flex gridGap={4}>
          <Heading as="h6" size="sm">
            {t("goalForm.where")}
          </Heading>
          <Text>{goal.lose_inches_where}</Text>
        </Flex>
      </Flex>

      <Flex gridGap={8} justifyContent="">
        <Flex gridGap={4}>
          <Heading as="h6" size="sm">
            {t("goalForm.loseFat")}
          </Heading>
          <Text>{goal.lose_fat}</Text>
        </Flex>
        <Flex gridGap={4}>
          <Heading as="h6" size="sm">
            {t("goalForm.where")}
          </Heading>
          <Text>{goal.lose_fat_where}</Text>
        </Flex>
      </Flex>

      <Flex gridGap={4}>
        <Heading as="h6" size="sm">
          {t("goalForm.gainWeight")}
        </Heading>
        <Text>{goal.gain_weight}</Text>
      </Flex>

      <Flex gridGap={8} justifyContent="">
        <Flex gridGap={4}>
          <Heading as="h6" size="sm">
            {t("goalForm.gainMuscle")}
          </Heading>
          <Text>{goal.gain_muscle}</Text>
        </Flex>
        <Flex gridGap={4}>
          <Heading as="h6" size="sm">
            {t("goalForm.where")}
          </Heading>
          <Text>{goal.gain_muscle_where}</Text>
        </Flex>
      </Flex>
      {/*  */}
      <Divider my={4} />
      <Heading as="h4" size="md" textAlign="center">
        {t("goalForm.performanceFitness")}
      </Heading>
      <Heading as="h6" size="sm">
        {t("goalForm.activityEnhancement")}
      </Heading>
      <Text>
        {goal.activity_enhancement.split("-")[0]} {goal.activity_enhancement.split("-")[1]}
      </Text>
      <Heading as="h6" size="sm">
        {t("goalForm.physicalAbilities")}
      </Heading>
      <Text>{goal.physical_abilities}</Text>
      <Heading as="h6" size="sm">
        {t("goalForm.performanceComponents")}
      </Heading>
      <Text>{goal.performance_components}</Text>
      <Heading as="h6" size="sm">
        {t("goalForm.performanceQualities")}
      </Heading>
      <Text>{goal.performance_qualities}</Text>
      {/*  */}
      <Divider my={4} />
      <Heading as="h4" size="md" textAlign="center">
        {t("goalForm.diagnosedContraindications")}
      </Heading>
      {goal.contraindications.map((contraindication, index) => (
        <>
          <Heading as="h6" size="sm">
            {t("goalForm.condition")} {index + 1}:
          </Heading>
          <Text>{contraindication.condition}</Text>
          <Heading as="h6" size="sm">
            {t("goalForm.contraindicatedMovements")}:
          </Heading>
          <Text>{contraindication.contraindicate_movements}</Text>
        </>
      ))}
    </>
  );
};
export default GoalResume;
