/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Flex,
  Button,
  IconButton,
  InputGroup,
  InputLeftElement,
  Input,
  Box,
  Select,
} from "@chakra-ui/react";
import { AddIcon, Search2Icon } from "@chakra-ui/icons";
import ViewListIcon from "@mui/icons-material/ViewList";
import GridViewIcon from "@mui/icons-material/GridView";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTranslation } from "react-i18next";

const Options = ({
  displayMode,
  onClick,
  setDisplayMode,
  search,
  setSearch,
  actionFilter,
  setActionFilter,
  muscleFilter,
  categoryFilter,
  equipmentFilter,
  setMuscleFilter,
  setCategoryFilter,
  setEquipmentFilter,
}) => {
  const { t } = useTranslation();
  const [showFilters, setShowFilters] = useState(false);

  return (
    <Box w="100%" mb="1rem">
      <Flex alignItems="center" justify="space-between" w="100%" mb="1rem" gridGap={4}>
        <Flex gridGap="10px" alignItems="center" w="50%">
          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <Search2Icon color="gray.600" />
            </InputLeftElement>
            <Input
              variant="outline"
              type="text"
              placeholder="Search..."
              value={search}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            />
          </InputGroup>
          <Button
            colorScheme={
              actionFilter !== "" ||
              categoryFilter !== "" ||
              muscleFilter !== "" ||
              equipmentFilter !== ""
                ? "green"
                : "blackAlpha"
            }
            size="xs"
            leftIcon={<FilterAltIcon fontSize="inherit" />}
            onClick={() => setShowFilters(!showFilters)}>
            {t("filters")}
          </Button>
        </Flex>
        <Flex gridGap={4}>
          <IconButton
            isRound
            variant="ghost"
            colorScheme="blackAlpha"
            aria-label="Done"
            fontSize="20px"
            icon={displayMode === "grid" ? <GridViewIcon /> : <ViewListIcon />}
            onClick={() =>
              setDisplayMode((prev) => {
                localStorage.setItem("displayMode", prev === "grid" ? "list" : "grid");
                return prev === "grid" ? "list" : "grid";
              })
            }
          />
          <Button rightIcon={<AddIcon />} colorScheme="ptRed" onClick={() => onClick("", "add")}>
            {t("add_exercise")}
          </Button>
        </Flex>
      </Flex>
      <Flex gridGap={4} alignItems="end" minH="42px" wrap="wrap">
        {showFilters && (
          <>
            <div>
              <p style={{ fontSize: "10px" }}>{t("muscle_system")}</p>
              <Select
                size="xs"
                variant="filled"
                value={muscleFilter}
                onChange={(e) => setMuscleFilter(e.target.value)}>
                <option value="">{t("all")}</option>
                <option value="opt_all_body">{t("opt_all_body")}</option>
                <option value="opt_lower_body">{t("opt_lower_body")}</option>
                <option value="opt_legs">{t("opt_legs")}</option>
                <option value="opt_posterior_core">{t("opt_posterior_core")}</option>
                <option value="opt_lumbar">{t("opt_lumbar")}</option>
                <option value="opt_gluteus">{t("opt_gluteus")}</option>
                <option value="opt_hamstring">{t("opt_hamstring")}</option>
                <option value="opt_quadriceps">{t("opt_quadriceps")}</option>
                <option value="opt_adductors">{t("opt_adductors")}</option>
                <option value="opt_abductors">{t("opt_abductors")}</option>
                <option value="opt_calves">{t("opt_calves")}</option>
                <option value="opt_upper_body">{t("opt_upper_body")}</option>
                <option value="opt_arms">{t("opt_arms")}</option>
                <option value="opt_chest">{t("opt_chest")}</option>
                <option value="opt_back">{t("opt_back")}</option>
                <option value="opt_shoulder">{t("opt_shoulder")}</option>
                <option value="opt_biceps">{t("opt_biceps")}</option>
                <option value="opt_triceps">{t("opt_triceps")}</option>
                <option value="opt_forearms">{t("opt_forearms")}</option>
                <option value="opt_anterior_core">{t("opt_anterior_core")}</option>
                <option value="opt_abs">{t("opt_abs")}</option>
                <option value="opt_hip_flexors">{t("opt_hip_flexors")}</option>
                <option value="opt_lateral_core">{t("opt_lateral_core")}</option>
                <option value="opt_rotational_core">{t("opt_rotational_core")}</option>
                <option value="opt_other">{t("opt_other")}</option>
              </Select>
            </div>

            <div>
              <p style={{ fontSize: "10px" }}>{t("categories")}</p>
              <Select
                size="xs"
                variant="filled"
                value={categoryFilter}
                onChange={(e) => setCategoryFilter(e.target.value)}>
                <option value="">{t("all")}</option>
                <option value="6171d84aec64ada0efa05721">{t("opt_traditional")}</option>
                <option value="61a62b7fb2f0481a2d9db38e">{t("opt_functional")}</option>
                <option value="61be5e479abd1ebc3d540764">{t("opt_rehab")}</option>
                <option value="61a62ba3b2f0481a2d9db38f">{t("opt_power")}</option>
                <option value="61be5e799abd1ebc3d540767">{t("opt_metabolic")}</option>
                <option value="61be5e969abd1ebc3d54076a">{t("opt_saq")}</option>
                <option value="669a8c4f1d9afe6f1f05328a">{t("opt_flexibility_mobility")}</option>
                <option value="66bd27617fa55c9d9bd4b809">{t("opt_cardio")}</option>
                <option value="669a90631d9afe6f1f05328c">{t("opt_other")}</option>
              </Select>
            </div>

            <div>
              <p style={{ fontSize: "10px" }}>{t("action")}</p>
              <Select
                size="xs"
                variant="filled"
                value={actionFilter}
                onChange={(e) => setActionFilter(e.target.value)}>
                <option value="">{t("all")}</option>
                <option value="opt_level_change">{t("opt_level_change")}</option>
                <option value="opt_hip_hinge">{t("opt_hip_hinge")}</option>
                <option value="opt_push">{t("opt_push")}</option>
                <option value="opt_pull">{t("opt_pull")}</option>
                <option value="opt_push_pull">{t("opt_push_pull")}</option>
                <option value="opt_core_stiffness">{t("opt_core_stiffness")}</option>
                <option value="opt_rotation">{t("opt_rotation")}</option>
                <option value="opt_locomotion">{t("opt_locomotion")}</option>
                <option value="opt_skip">{t("opt_skip")}</option>
                <option value="opt_jumps">{t("opt_jumps")}</option>
                <option value="opt_tumbling">{t("opt_tumbling")}</option>
                <option value="opt_isometric">{t("opt_isometric")}</option>
                <option value="opt_other">{t("opt_other")}</option>
              </Select>
            </div>
            <div>
              <p style={{ fontSize: "10px" }}>{t("equipment")}</p>
              <Select
                size="xs"
                variant="filled"
                value={equipmentFilter}
                onChange={(e) => setEquipmentFilter(e.target.value)}>
                <option value="">{t("all")}</option>
                <option value="opt_bw">{t("opt_bw")}</option>
                <option value="opt_bb">{t("opt_bb")}</option>
                <option value="opt_db/kb">{t("opt_db/kb")}</option>
                <option value="opt_m">{t("opt_m")}</option>
                <option value="opt_bp">{t("opt_bp")}</option>
                <option value="opt_rb">{t("opt_rb")}</option>
                <option value="opt_mb">{t("opt_mb")}</option>
                <option value="opt_sb">{t("opt_sb")}</option>
                <option value="opt_plate">{t("opt_plate")}</option>
                <option value="opt_sus">{t("opt_sus")}</option>
                <option value="opt_ropes">{t("opt_ropes")}</option>
                <option value="opt_ez">{t("opt_ez")}</option>
                <option value="opt_sm">{t("opt_sm")}</option>
                <option value="opt_sled">{t("opt_sled")}</option>
                <option value="opt_lm">{t("opt_lm")}</option>
                <option value="opt_hg360">{t("opt_hg360")}</option>
                <option value="opt_agl">{t("opt_agl")}</option>
                <option value="opt_cones">{t("opt_cones")}</option>
                <option value="opt_hur">{t("opt_hur")}</option>
                <option value="opt_multiple">{t("opt_multiple")}</option>
                <option value="opt_other">{t("opt_other")}</option>
              </Select>
            </div>
          </>
        )}
      </Flex>
    </Box>
  );
};

export default Options;
