/* eslint-disable import/prefer-default-export */
import instance from "./_axiosConfig";

const api = instance;

const getDashboardStats = async (id) => {
  try {
    const response = await api.get(`/dashboard/trainer/${id}`);
    return response.data;
  } catch (error) {
    throw new Error(error);
  }
};

export { getDashboardStats };
