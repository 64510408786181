/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Flex,
  IconButton,
  Text,
  Tooltip,
  useDisclosure,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import ExpandCircleDownOutlinedIcon from "@mui/icons-material/ExpandCircleDownOutlined";
import StickyNote2OutlinedIcon from "@mui/icons-material/StickyNote2Outlined";
import DeleteIcon from "@mui/icons-material/Delete";
import dayjs from "dayjs";
import useLang from "hooks/useLang";
import EditIcon from "@mui/icons-material/Edit";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CloseIcon from "@mui/icons-material/Close";
import DragIcon from "assets/icons/drag.png";
import "../../../clients/ClientDashboard/DayDisplay/DayDisplay.module.css";
import EditDay from "./EditDay/EditDay";
import ExerciseEdit from "./ExerciseEdit/ExerciseEdit";
import SubItem from "./SubItem";

function getStyle(isDragging, provided, style) {
  if (!style) {
    if (isDragging) {
      console.log(isDragging);
      return { ...provided.draggableProps.style, height: "87px" };
    }
    return provided.draggableProps.style;
  }

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

function QuoteItem(props) {
  const {
    quote,
    isDragging,
    isGroupedOver,
    provided,
    style,
    isClone,
    index,
    columns,
    setColumns,
    setOrdered,
    cloneState,
    isChecked,
    onChange,
    cloneUniqueDay,
    setCloneUniqueDay,
    listdays,
    titleHeader,
    columnScroll,
    scrollHorizontalBoard,
  } = props;

  const [isNoteDisplay, toggleNoteDisplay] = useState(false);
  const { lang, t } = useLang();

  const [openDelete, setOpenDelete] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenExercises,
    onOpen: onOpenExercises,
    onClose: onCloseExercises,
  } = useDisclosure();

  useEffect(() => {
    toggleNoteDisplay(false);
  }, [lang]);

  const saveNote = () => {
    const textarea = document.getElementById(`textarea-${quote._id}`);
    const data = textarea?.value;

    const updatedNotes = listdays.map((objArr) =>
      objArr._id === quote._id
        ? {
            ...quote,
            notes: {
              en: lang === "en" ? data : quote.notes.en,
              es: lang === "es" ? data : quote.notes.es,
            },
          }
        : objArr,
    );

    let weekColumn = [...columns];
    const arrIndex = titleHeader - 1;
    weekColumn.splice(arrIndex, 1, updatedNotes);

    setColumns(weekColumn);
    const ContentListColumn = columnScroll.current;
    setTimeout(() => {
      const height = ContentListColumn.scrollTop;
      ContentListColumn.scrollTo(0, height);
    }, 100);
    toggleNoteDisplay(false);
    /* setColumns((prev) => prev.map((item, idx) => (item[0].week === quote.week ? updatedArr : item))); */
  };
  const handleConfirmDelete = () => {
    // Flatten the array of objects
    const updatedDay = listdays.filter(({ _id }) => _id !== quote._id);
    const orderBoard = updatedDay.map((object, i) => ({ ...object, day: 1 + i }));
    let weekColumn = [...columns];
    const arrIndex = titleHeader - 1;
    weekColumn.splice(arrIndex, 1, orderBoard);
    // Update the state variables
    setColumns(weekColumn);
    setOrdered(Object.keys(weekColumn));
    const ContentListColumn = columnScroll.current;
    setTimeout(() => {
      const height = ContentListColumn.scrollTop;
      ContentListColumn.scrollTo(0, height);
    }, 100);
  };
  const handleUpdateExercise = (exerciseList) => {
    // Flatten the array of objects

    const updatedDay = listdays
      .map((objArr) => (objArr._id === exerciseList._id ? exerciseList : objArr))
      .filter((day) => day.exercises?.length >= 1);

    const orderBoard = updatedDay.map((object, i) => ({ ...object, day: 1 + i }));

    let weekColumn = [...columns];
    const arrIndex = titleHeader - 1;
    weekColumn.splice(arrIndex, 1, orderBoard);

    setColumns(weekColumn);
    setOrdered(Object.keys(weekColumn));
    const ContentListColumn = columnScroll.current;
    const ContentMainScroll = scrollHorizontalBoard.current;
    setTimeout(() => {
      const height = ContentListColumn.scrollTop;
      const horizontalScroll = ContentMainScroll.scrollLeft;
      ContentMainScroll.scrollTo(horizontalScroll, 0);
      ContentListColumn.scrollTo(0, height);
    }, 100);
  };
  const expandAndCollapse = () => {
    const item = document.getElementById(`content-day-${quote.day}-${quote.week}`);
    const spandButton = document.getElementById(`spand-button-${quote.day}-${quote.week}`);
    if (item) {
      if (item.classList.contains("collapsed")) {
        item.classList.remove("collapsed");
        spandButton.classList.remove("btn-collapsed");
      } else {
        item.classList.add("collapsed");
        spandButton.classList.add("btn-collapsed");
      }
    }
  };
  const expand = () => {
    const item = document.getElementById(`content-day-${quote.day}-${quote.week}`);
    const spandButton = document.getElementById(`spand-button-${quote.day}-${quote.week}`);
    if (item) {
      if (item.classList.contains("collapsed")) {
        item.classList.remove("collapsed");
        spandButton.classList.remove("btn-collapsed");
      }
    }
  };
  const collapseAll = () => {
    const items = Array.from(document.getElementsByClassName("day_content"));
    const spandButton = Array.from(document.getElementsByClassName("spand-button"));
    if (items.length > 0) {
      items.forEach((element) => {
        element.classList.add("collapsed");
      });
      spandButton.forEach((element) => {
        element.classList.add("btn-collapsed");
      });
    }
  };

  return (
    <a
      className="day_container"
      isGroupedOver={isGroupedOver}
      ref={provided.innerRef}
      {...provided.draggableProps}
      {...provided.dragHandleProps}
      style={{
        ...getStyle(isDragging, provided, style),
        boxShadow: cloneUniqueDay[0]?._id === quote._id ? "inset 0px 0px 0px 3px #b42519" : "none",
      }}
      data-is-dragging={isDragging}
      data-testid={quote.id}
      data-index={index}
      aria-label={`${quote.author.name}`}>
      <article className="day_content" id={`content-day-${quote.day}-${quote.week}`}>
        <Flex
          justifyContent="space-between"
          borderBottom="1px solid #afafaf"
          paddingBottom=".5rem"
          gridGap={4}
          margin=".5rem 0px">
          <Flex gridGap={3} alignItems="center">
            {cloneState && (
              <Checkbox
                colorScheme="red"
                value={quote}
                onChange={onChange}
                defaultChecked={isChecked}
              />
            )}
            <img
              src={DragIcon}
              alt="drag icon"
              style={{
                width: "1rem",
                height: "1rem",
                filter:
                  "invert(75%) sepia(0%) saturate(0%) hue-rotate(179deg) brightness(93%) contrast(91%)",
              }}
            />
          </Flex>

          <Flex gridGap={3}>
            <Tooltip hasArrow label={t("edit_day")} closeOnClick closeOnMouseDown>
              <IconButton
                colorScheme="blackAlpha"
                type="button"
                size="xs"
                fontSize="16px"
                bg="#3a3a3a"
                minW="1.3rem"
                h="1.3rem"
                icon={<EditIcon fontSize="inherit" />}
                onClick={() => {
                  onOpen();
                  toggleNoteDisplay(false);
                }}
              />
            </Tooltip>
            <Tooltip
              hasArrow
              label={t(cloneUniqueDay[0]?._id !== quote._id ? "copy" : "cancel")}
              closeOnClick
              closeOnMouseDown>
              <IconButton
                size="xs"
                fontSize="20px"
                variant="ghost"
                color={cloneUniqueDay[0]?._id === quote._id ? "#bd2e2c" : "#afafaf"}
                minW="1.3rem"
                h="1.3rem"
                icon={
                  cloneUniqueDay[0]?._id === quote._id ? (
                    <CloseIcon fontSize="inherit" />
                  ) : (
                    <ContentCopyIcon fontSize="inherit" />
                  )
                }
                onClick={
                  cloneUniqueDay?.length >= 1 && cloneUniqueDay[0]?._id === quote._id
                    ? () => setCloneUniqueDay([])
                    : () => {
                        setCloneUniqueDay([quote]);
                        collapseAll();
                      }
                }
              />
            </Tooltip>
            <Tooltip label={t("notes")}>
              <IconButton
                size="xs"
                fontSize="20px"
                variant="ghost"
                color="#afafaf"
                minW="1.3rem"
                h="1.3rem"
                icon={
                  <StickyNote2OutlinedIcon
                    className={quote.notes[lang] !== "" ? "notesActive" : null}
                    fontSize="inherit"
                  />
                }
                onClick={() => {
                  toggleNoteDisplay(!isNoteDisplay);
                  expand();
                }}
              />
            </Tooltip>
            <Tooltip label={t("delete_day")}>
              <IconButton
                size="xs"
                fontSize="20px"
                variant="ghost"
                colorScheme="red"
                minW="1.3rem"
                h="1.3rem"
                icon={<DeleteIcon fontSize="inherit" />}
                onClick={() => setOpenDelete(true)}
              />
            </Tooltip>
          </Flex>
        </Flex>
        <Flex justifyContent="space-between" alignItems="center">
          <Flex alignItems="center" gridGap={2}>
            <Tooltip label={t("expand_collapse")}>
              <IconButton
                size="xs"
                fontSize="20px"
                variant="ghost"
                color="#afafaf"
                minW="1.3rem"
                h="1.3rem"
                id={`spand-button-${quote.day}-${quote.week}`}
                className="spand-button"
                onClick={expandAndCollapse}
                icon={<ExpandCircleDownOutlinedIcon />}
              />
            </Tooltip>
            <Text fontWeight="bold" textTransform="uppercase" fontSize="16px">{`${t("list_day")} ${
              quote.day
            }`}</Text>
          </Flex>

          <Text fontSize="small" color="gray">
            {quote.date
              ? dayjs(new Date(quote?.date).toUTCString()).format(
                  lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                )
              : t("no_date")}
          </Text>
        </Flex>

        <div className="day_exercises">
          {isNoteDisplay && (
            <div className="notes">
              <textarea id={`textarea-${quote._id}`} defaultValue={quote.notes[lang]} />
              <Button
                bg="#d6d6d6"
                color="black"
                colorScheme="black"
                borderRadius="full"
                size="xs"
                mt={2}
                onClick={() => {
                  saveNote();
                }}>
                {t("saveNote")}
              </Button>
            </div>
          )}
          <SubItem
            exerciselist={quote}
            day={quote.day}
            week={quote.week}
            handleUpdateExercise={handleUpdateExercise}
          />
          <Button
            bg="#fff"
            color="gray"
            colorScheme="black"
            borderRadius="full"
            border="dashed 2px #afafaf"
            onClick={() => onOpenExercises()}>
            {t("add_exercise")}
          </Button>
        </div>
      </article>
      {isOpen && (
        <EditDay
          isOpen={isOpen}
          onClose={onClose}
          quotes={[quote]}
          columns={columns}
          scrollHorizontalBoard={scrollHorizontalBoard}
          key={isOpen}
          setColumns={setColumns}
          setOrdered={setOrdered}
          titleHeader={titleHeader}
          columnScroll={columnScroll}
          listdays={listdays}
        />
      )}
      {isOpenExercises && (
        <ExerciseEdit
          isOpen={isOpenExercises}
          onClose={onCloseExercises}
          quotes={[quote]}
          scrollHorizontalBoard={scrollHorizontalBoard}
          titleHeader={titleHeader}
          columns={columns}
          columnScroll={columnScroll}
          key={isOpenExercises}
          listdays={listdays}
          setColumns={setColumns}
        />
      )}
      {openDelete && (
        <AlertDialog isOpen={openDelete} onClose={() => setOpenDelete(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader>{t("delete_day")}</AlertDialogHeader>
              <AlertDialogBody>{t("delete_confirmation")}</AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={() => setOpenDelete(false)}>{t("cancel")}</Button>
                <Button colorScheme="red" onClick={() => handleConfirmDelete()} ml={3}>
                  {t("delete")}
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </a>
  );
}

export default React.memo(QuoteItem);
