import React, { useReducer, useState } from "react";
import { useLocation } from "react-router";
import ListContainer from "./ListContainer";
import WorkoutCreator from "./WorkoutCreator";
import FromClient from "../workout/FromClient";

const NewRoutine = () => {
  const [isCreate, setIsCreate] = useReducer((prev) => !prev, false);
  const [selectData, setSelectData] = useState({});
  const location = useLocation();
  console.log("state", location.state);
  if (location.state?.workType === "fromclient") {
    return (
      <FromClient
        workout={location.state?.workType}
        client={location.state?.client}
        clientInfo={location.state?.clientInfo || location?.state}
      />
    );
  }

  if (location.state?.workType !== "custom" && !isCreate) {
    return (
      <ListContainer
        type={location.state?.workType}
        toggle={setIsCreate}
        setSelectData={setSelectData}
        clientInfo={location.state?.clientInfo || location?.state}
      />
    );
  }
  return (
    <WorkoutCreator
      setIsCreate={setIsCreate}
      workout={location.state?.workType}
      preInfo={selectData}
      client={location.state?.client}
      clientInfo={location.state?.clientInfo || location?.state}
    />
  );
};

export default NewRoutine;
