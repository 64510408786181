import React from "react";
import IconsNav from "./IconsNav";

const Header = () => (
  <header>
    <IconsNav />
  </header>
);

export default Header;
