/* eslint-disable react/prop-types */
import React from "react";
import { Flex, Icon, Text } from "@chakra-ui/react";

function TextWithIcon({ icon, text, isAddress }) {
  if (isAddress) {
    return (
      <Flex marginBottom={2}>
        <Icon as={icon} marginRight={2} style={{ color: "rgb(143, 143, 143)" }} key={icon} />
        <Flex direction="column">
          {text.map((line) => (
            <Text key={line}>{line}</Text>
          ))}
        </Flex>
      </Flex>
    );
  }
  return (
    <Flex marginBottom={2}>
      <Icon as={icon} marginRight={2} style={{ color: "rgb(143, 143, 143)" }} />
      <Text>{text}</Text>
    </Flex>
  );
}

export default TextWithIcon;
