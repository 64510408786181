/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/prop-types */
import React from "react";
import { Image } from "@chakra-ui/react";
import CloseIcon from "@mui/icons-material/Close";
import { uploadFile } from "services/files";
import generateId from "helpers/generateId";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { useTranslation } from "react-i18next";
import notificationDisplay from "utils/notificationDisplay";
import logo from "assets/img2.png";
import media from "assets/icons/media.png";
import { useDispatch } from "react-redux";
import style from "./fileUploader.module.scss";

const FileUploader = ({ simple, files, setFiles }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const uploadFileServer = async (file, index) => {
    try {
      const fileExtension = file.name.split(".").pop();
      const newFileName = `${generateId()}.${fileExtension}`;

      const reader = new FileReader();
      reader.onload = async () => {
        const newFile = new File([reader.result], newFileName, { type: file.type });
        const formData = new FormData();
        formData.append("imagen", newFile);
        const response = await uploadFile(formData);
        if (response.data) {
          setFiles([...files.filter((f, i) => index !== i)]);
        } else {
          const newFiles = [...files];
          newFiles.splice(index, 1, response.imagen);
          setFiles([...newFiles]);
        }
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.log(error);
    }
  };

  const fileDragHover = (e) => {
    const fileDrag = document.getElementById("file-drag");
    e.stopPropagation();
    e.preventDefault();

    fileDrag.className = e.type === "dragover" ? "hover" : "modal-body file-upload";
  };
  const handleFileUploader = (e) => {
    const newFile = e.target.files || e.dataTransfer.files;
    fileDragHover(e);
    if (newFile.length <= 0) return;

    const filesize = (newFile[0].size / 1024 / 1024).toFixed(4); // MB
    if (filesize <= 15) {
      const transformFile = (window.URL || window.webkitURL).createObjectURL(new Blob(newFile));
      console.log(`${transformFile}_${newFile[0].type}`);
      setFiles([...files, `${transformFile}_${newFile[0].type}`]);
      uploadFileServer(newFile[0], files.length);
    } else {
      console.log("file to longer");
      notificationDisplay(dispatch, t("fileTooLarge"), "error");
    }
  };

  const removeFile = (index) => {
    setFiles([...files.filter((f, i) => index !== i)]);
  };

  return (
    <>
      <div className={style.files}>
        {files.map((file, index) => (
          <div className={`${style.file} ${file.includes("blob") ? style.load : ""}`}>
            {!file.includes("mp4") && !file.includes("mov") ? (
              <Image
                src={
                  file.includes("blob")
                    ? file.split("_")[0]
                    : `${process.env.REACT_APP_FILE_API_URL}/file?file=${file}`
                }
                fallbackSrc={logo}
                alt="file exercise"
              />
            ) : (
              <video
                width="400"
                preload="metadata "
                src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${file}#t=0.1`}
                fallbackSrc={media}
                autoPlay
                playsinline
                muted
                loop>
                <track kind="captions" />
              </video>
            )}
            <span className={style.remove} onClick={() => removeFile(index)} aria-hidden="true">
              <CloseIcon />
            </span>
          </div>
        ))}
      </div>
      <div className={style.uploader}>
        <input
          id="file-upload"
          type="file"
          name="fileUpload"
          accept="image/*,video/*"
          onChange={handleFileUploader}
        />

        <label
          htmlFor="file-upload"
          id="file-drag"
          onDrop={handleFileUploader}
          onDragOver={fileDragHover}
          onDragLeave={fileDragHover}>
          <div id="start">
            <div>Drag files here or</div>
            <span className={style.button}>
              <FileUploadIcon />
              Select a file
            </span>

            <p className={style.types}>File types: .jpg, .png, .gif, .mp4</p>
            <p className={style.types} style={{ marginTop: "0" }}>
              Max file size: 15MB
            </p>
          </div>
        </label>
      </div>
    </>
  );
};

export default FileUploader;
