import React from "react";
import useLang from "hooks/useLang";
import styles from "./comingsoon.module.scss";

const ComingSoon = () => {
  const { t } = useLang();
  return (
    <div className={styles.root}>
      <div className={styles.container}>
        <p>{t("coming_soon")}</p>
      </div>
    </div>
  );
};

export default ComingSoon;
