import React from "react";
import AdminHelp from "components/adminHelp";

import { bigContainerStyle } from "theme/commonStyles";

const Help = () => (
  <div style={bigContainerStyle}>
    <AdminHelp />
  </div>
);

export default Help;
