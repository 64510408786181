/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  Text,
} from "@chakra-ui/react";
import useLang from "hooks/useLang";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import styles from "./itemSection.module.scss";

const InformationAbout = ({ onClose, isOpen, data, onCloseAll }) => {
  const { t } = useLang();
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      scrollBehavior="inside">
      <ModalContent h="650px">
        <ModalHeader display="flex" justifyContent="start" alignItems="center">
          <Button colorScheme="gray" variant="ghost" onClick={onClose} color="gray">
            <ArrowBackIosNewIcon />
          </Button>
          <span>{t(data.about)}</span>
        </ModalHeader>
        <ModalCloseButton onClick={onCloseAll} />
        <ModalBody pb={6}>
          <Box h="full">
            <iframe
              className={styles.video__container}
              width="853"
              height="480"
              src={data?.url.replace("watch?v=", "embed/")}
              title={data.about}
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            />
            <Box mt={3}>
              <Text whiteSpace="pre-wrap">{t(data.description)}</Text>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InformationAbout;
