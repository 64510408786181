/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { Draggable } from "react-beautiful-dnd";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import useLang from "hooks/useLang";
import QuoteList from "./list";
import EditWeek from "./EditWeek/EditWeek";

const Container = styled.div`
  display: flex;
  padding: 1rem;
  background-color: #e7e7e7;
  border-radius: 10px;
  flex-direction: column;
`;

const Header = styled.div`
  background-color: ${({ isDragging }) => (isDragging ? "transparent" : "transparent")};
  box-shadow: ${({ isDragging }) => (isDragging ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : "none")};
  margin-bottom: 15px;
  width: 320px;
`;

const Column = ({
  title,
  quotes,
  index,
  isScrollable,
  isCombineEnabled,
  useClone,
  columns,
  keyRefrest,
  setColumns,
  cloneState,
  listDaySelected,
  handleChangeRemove,
  handleChangeSelect,
  handleCancelClone,
  setOrdered,
  superSetMode,
  setSuperSetState,
}) => {
  const { t } = useLang();
  const titleNaN = Math.floor(index);
  const AiTitle = 1 + titleNaN;
  const [weeksfilter, setWeeksfilter] = useState(1 + quotes[quotes.length - 1]?.day);
  const [open, setopen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setWeeksfilter(1 + quotes[quotes.length - 1]?.day);
  }, [quotes]);

  const handleConfirmDelete = () => {
    // Gets all columns of the training routine.
    const allColumns = [...columns].flat();

    // Gets the current week of the training routine.
    const [{ week: currentWeek }] = quotes;

    // Filters the columns of the training routine to remove the current week.
    const filteredColumns = allColumns.filter(({ week }) => week !== currentWeek);

    // Updates the training routine with the filtered columns.
    const FormatDays = [...filteredColumns];

    // Extract the week property from each object in the array
    const array = FormatDays?.map(({ week }) => week);

    // Remove duplicates from the array
    const newarray = [...new Set(array)];

    // Group the objects by week property
    const dataFilter = newarray?.map((item) =>
      filteredColumns?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );

    // Update the state variable and close the modal
    setColumns(dataFilter);

    // Clear the state that save the last day
    setWeeksfilter([]);

    // Closes the confirmation dialog.
    setopen(false);
  };

  return (
    <Draggable>
      {(provided, snapshot) => (
        <Container>
          <Header>
            <Box>
              <Box
                borderRadius="20px"
                bg="#3a3a3a"
                p=".5rem 1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Box>
                  <Heading fontSize="xl" fontWeight="medium" color="white">
                    {`${t("list_day")} ${AiTitle}`}
                  </Heading>
                </Box>
                <Box>
                  <Box
                    color="gray"
                    fontSize="sm"
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    {/* <Button disabled size="sm" background="white" display="flex" justifyContent="center" alignItems="center" variant="ghost">
                      <CopyIcon fontSize="inherit" />
                      <Text fontSize="xs">
                        Duplicate
                      </Text>
                    </Button> */}
                    {/* <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<MoreVertIcon />}
                        background="white"
                        variant="ghost"
                      />
                      <MenuList>
                        <MenuItem type="button" onClick={() => onOpen()} icon={<EditIcon />}>
                          Edit
                        </MenuItem>
                        <MenuItem onClick={() => setopen(true)} color="red" icon={<DeleteIcon />}>
                          Delete
                        </MenuItem>
                      </MenuList>
                    </Menu> */}
                  </Box>
                </Box>
              </Box>
            </Box>
          </Header>
          <QuoteList
            listId={title}
            listType="QUOTE"
            style={{
              backgroundColor: snapshot.isDragging ? "transparent" : null,
            }}
            quotes={quotes}
            internalScroll={isScrollable}
            isCombineEnabled={Boolean(isCombineEnabled)}
            useClone={Boolean(useClone)}
            titleHeader={AiTitle}
            columns={columns}
            setColumns={setColumns}
            thelastday={weeksfilter}
            cloneState={cloneState}
            listDaySelected={listDaySelected}
            handleChangeRemove={handleChangeRemove}
            handleChangeSelect={handleChangeSelect}
            handleCancelClone={handleCancelClone}
            setOrdered={setOrdered}
          />
          {open && (
            <AlertDialog isOpen={open} onClose={() => setopen(false)}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader>Delete week</AlertDialogHeader>
                  <AlertDialogBody>{t("delete_confirmation")}</AlertDialogBody>
                  <AlertDialogFooter>
                    <Button onClick={() => setopen(false)}>{t("cancel")}</Button>
                    <Button colorScheme="red" onClick={() => handleConfirmDelete()} ml={3}>
                      {t("delete")}
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          )}
          {/* {onOpen && (
            <EditWeek
              isOpen={isOpen}
              onClose={onClose}
              AiTitle={AiTitle}
              columns={columns}
              setColumns={setColumns}
              quotes={quotes}
              key={keyRefrest}
            />
          )} */}
        </Container>
      )}
    </Draggable>
  );
};

export default Column;
