/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useState } from "react";
import { shape, string } from "prop-types";
import { IconButton, Badge, Flex, useDisclosure } from "@chakra-ui/react";
import Popup from "components/exercises/Popup";
import VisibilityIcon from "@mui/icons-material/Visibility";

import { superSetColorsHex } from "theme/commonStyles";
import { Draggable, Droppable } from "react-beautiful-dnd";
import useLang from "hooks/useLang";
import styled from "styled-components";

import stylesExercise from "components/clients/ClientDashboard/ExerciseDisplay/ExerciseDisplay.module.css";
import EditExercise from "./editExercise/editExercise";

export const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
  if (isDraggingOver) return "#FFEBE6";
  if (isDraggingFrom) return "#E6FCF8";
  return "transparent";
};

const getBackgroundColorSubItem = (isDragging, isGroupedOver) => {
  if (isDragging) return "white";
  if (isGroupedOver) return "#FFFFFF";

  return "#FFFFFF";
};

const Container = styled.a`
  border-radius: 20px;
  background-color: ${(props) =>
    getBackgroundColorSubItem(props.isDragging, props.isGroupedOver, props.colors)};
  box-shadow: ${({ isDragging }) => (isDragging ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : "none")};
  box-sizing: border-box;
  padding: 0px;
  min-height: 40px;
  margin-bottom: 10px;
  user-select: none;
  width: 100%;

  /* anchor overrides */
  color: #091e42;

  &:hover,
  &:active {
    color: #091e42;
    text-decoration: none;
  }

  &:focus {
    outline: none;
    border-color: white;
    box-shadow: none;
  }

  /* flexbox */
  display: flex;
`;

function getStyle(provided, style) {
  if (!style) return provided.draggableProps.style;

  return {
    ...provided.draggableProps.style,
    ...style,
  };
}

const Wrapper = styled.div`
  background-color: ${(props) => getBackgroundColor(props.isDraggingOver, props.isDraggingFrom)};
  display: flex;
  flex-direction: column;
  opacity: ${({ isDropDisabled }) => (isDropDisabled ? 0.5 : "inherit")};
  padding: 0px;
  border: 0px;
  padding-bottom: 0;
  transition:
    background-color 0.2s ease,
    opacity 0.1s ease;
  user-select: none;
  width: 100%;
`;

const DropZone = styled.div`
  man-height: 700px;
  padding-bottom: 0px;
`;

const SubItem = ({ categoryExercises, day, week, handleUpdateExercise }) => {
  const [exerciseData, setExerciseData] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { lang } = useLang();
  const style = "";

  const editExercise = (exercise) => {
    const i = categoryExercises.exercises.findIndex((a) => a._id === exercise._id);
    categoryExercises.exercises.splice(i, 1, exercise);
    handleUpdateExercise(categoryExercises);
  };

  const deleteExercise = (exercise) => {
    const i = categoryExercises.exercises.findIndex((a) => a._id === exercise._id);
    categoryExercises.exercises.splice(i, 1);
    handleUpdateExercise(categoryExercises);
  };
  const openInfoExercise = (item) => {
    const exerciseInformation = { id_exercise: { notes_exercise: item.notes_exercise, ...item.id_exercise } };
    setExerciseData(exerciseInformation.id_exercise);
    onOpen();
  };
  return (
    <>
      <Droppable key={day} droppableId={`${day}-${week}`} type="EXERCISE">
        {(dropProvided, dropSnapshot) => (
          <Wrapper
            isDraggingOver={dropSnapshot.isDraggingOver}
            style={{ backgroundColor: dropSnapshot.isDragging ? "transparent" : null }}
            isDraggingFrom={Boolean(dropSnapshot.draggingFromThisWith)}
            {...dropProvided.droppableProps}>
            <div ref={dropProvided.innerRef} className="exercises_dropZone">
              {categoryExercises.exercises?.map((category, index) => (
                <Draggable key={category._id} draggableId={category._id} index={index}>
                  {(dragProvided, dragSnapshot) => (
                    <Container
                      isDragging={dragSnapshot.isDragging}
                      ref={dragProvided.innerRef}
                      style={getStyle(dragProvided, style)}
                      {...dragProvided.draggableProps}
                      {...dragProvided.dragHandleProps}
                      data-is-dragging={dragSnapshot.isDragging}
                      isGroupedOver={Boolean(dragSnapshot.combineTargetFor)}
                      data-testid={category._id}
                      data-index={index}
                      aria-label={`${category.id_exercise}`}>
                      <article
                        className={stylesExercise.container}
                        style={{
                          backgroundColor: `${superSetColorsHex[category.colors?.hard || "#fff"]}`,
                        }}>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            gap: "10px",
                            justifyContent: "space-between",
                          }}>
                          <h5 style={{ fontWeight: "bold" }}>
                            {category.id_exercise ? (
                              <>{category.id_exercise.name[lang]}</>
                            ) : (
                              <Flex gridGap="5px">
                                {category.category?.name}
                                {category.muscle?.name && <Badge>{category.muscle.name}</Badge>}
                                {category.action?.name && (
                                  <Badge colorScheme="purple">{category.action.name}</Badge>
                                )}
                              </Flex>
                            )}
                          </h5>
                          <Flex gridGap="5px">
                            {category.id_exercise && (
                              <IconButton
                                onClick={() => openInfoExercise(category)}
                                size="xs"
                                variant="ghost"
                                icon={<VisibilityIcon fontSize="inherit" />}
                              />
                            )}
                            <EditExercise
                              exercise={category}
                              saveExercise={editExercise}
                              date={{ day, week }}
                              key={category._id}
                              idx={index}
                              deleteExercise={deleteExercise}
                            />
                          </Flex>
                        </div>
                        <div className={stylesExercise.list}>
                          <p className={stylesExercise.exerciseData}>
                            {category.sets[0].load !== "" && category.sets[0].load !== "0" ? (
                              <>
                                {category.sets.length} Sets x{" "}
                                {category.sets?.map((s) => (
                                  <span>
                                    {s.load}
                                    {s.typeWeight === "others" ? "" : s.typeWeight}/{s.reps}
                                    {s?.typeReps ?? ""},{" "}
                                  </span>
                                ))}
                              </>
                            ) : (
                              <>
                                {category.sets.length} Sets x {category.sets[0].reps}
                                {category.sets[0].typeReps ?? ""}
                              </>
                            )}
                          </p>
                        </div>
                      </article>
                    </Container>
                  )}
                </Draggable>
              ))}
              {dropProvided.placeholder}
            </div>
          </Wrapper>
        )}
      </Droppable>
      {isOpen && exerciseData && (
        <Popup exercise={exerciseData} isOpen={isOpen} onClose={onClose} mode="info" />
      )}
    </>
  );
};

export default SubItem;
