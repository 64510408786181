/* eslint-disable react/no-children-prop */
/* eslint-disable react/prop-types */
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Heading,
  Input,
  InputGroup,
  InputRightElement,
  Textarea,
} from "@chakra-ui/react";
import useLang from "hooks/useLang";
import { useEffect, useState } from "react";

const MeasurementsForm = ({ measurement, formStep, saveMeasurement }) => {
  const { t, lang } = useLang();
  const [weight, setWeight] = useState(measurement?.generalInformation?.weight || "");
  const [height, setHeight] = useState(measurement?.generalInformation?.height || "");
  const [bp, setBp] = useState(measurement?.generalInformation?.bp || "");
  const [hr, setHr] = useState(measurement?.generalInformation?.hr || "");
  const [arm, setArm] = useState(measurement?.circunference?.arm || "");
  const [chest, setChest] = useState(measurement?.circunference?.chest || "");
  const [abdominals, setAbdominals] = useState(measurement?.circunference?.abdominals || "");
  const [hip, setHip] = useState(measurement?.circunference?.hip || "");
  const [thigh, setThigh] = useState(measurement?.circunference?.thigh || "");
  const [calf, setCalf] = useState(measurement?.circunference?.calf || "");
  const [skinfoldChest, setSkinfoldChest] = useState(measurement?.skinfold?.chest || "");
  const [triceps, setTriceps] = useState(measurement?.skinfold?.triceps || "");
  const [subscapula, setSubscapula] = useState(measurement?.skinfold?.subscapula || "");
  const [midaxillary, setMidaxillary] = useState(measurement?.skinfold?.midaxillary || "");
  const [suprailiac, setSuprailiac] = useState(measurement?.skinfold?.abdominal || "");
  const [abdominalSkinfold, setAbdominalSkinfold] = useState(
    measurement?.skinfold?.abdominal || "",
  );
  const [skinfoldThigh, setSkinfoldThigh] = useState(measurement?.skinfold?.thigh || "");
  const [vertical, setVertical] = useState(measurement?.performance?.vertical || "");
  const [mb_chest, setMbChest] = useState(measurement?.performance?.mb_chest || "");
  const [yrd_300, setYard300] = useState(measurement?.performance?.yrd_300 || "");
  const [bench_1rm, setBench1rm] = useState(measurement?.performance?.bench_1rm || "");
  const [sq_1rm, setSq1rm] = useState(measurement?.performance?.sq_1rm || "");
  const [pain_scale, setPainScale] = useState(measurement?.pain_scale || "");
  const [therapist_notes, setTherapistNotes] = useState(measurement?.therapist_notes || "");
  const [trainer_notes, setTrainerNotes] = useState(measurement?.trainer_notes || "");
  /*  */
  const [otherGeneral, setOtherGeneral] = useState(measurement?.generalInformation?.others || []);
  const handleOtherGeneralChange = (index, input, value) => {
    const data = [...otherGeneral];
    data[index][input] = value;
    setOtherGeneral(data);
  };
  const addOtherGeneral = () => {
    const data = [...otherGeneral];
    data.push({ name: "", value: "" });
    setOtherGeneral(data);
  };
  /*  */
  const [otherCircunference, setOtherCircunference] = useState(
    measurement?.circunference?.others || [],
  );
  const handleOtherCircunferenceChange = (index, input, value) => {
    const data = [...otherCircunference];
    data[index][input] = value;
    setOtherCircunference(data);
  };
  const addOtherCircunference = () => {
    const data = [...otherCircunference];
    data.push({ name: "", value: "" });
    setOtherCircunference(data);
  };
  /*  */
  const [otherSkinfold, setOtherSkinfold] = useState(measurement?.skinfold?.others || []);
  const handleOtherSkinfoldChange = (index, input, value) => {
    const data = [...otherSkinfold];
    data[index][input] = value;
    setOtherCircunference(data);
  };
  const addOtherSkinfold = () => {
    const data = [...otherSkinfold];
    data.push({ name: "", value: "" });
    setOtherSkinfold(data);
  };
  /*  */
  const [otherPerformance, setOtherPerformance] = useState(measurement?.performance?.others || []);
  const handleOtherPerformanceChange = (index, input, value) => {
    const data = [...otherPerformance];
    data[index][input] = value;
    setOtherCircunference(data);
  };
  const addOtherPerformance = () => {
    const data = [...otherPerformance];
    data.push({ name: "", value: "" });
    setOtherPerformance(data);
  };

  const onSaveData = () => {
    const data = {
      generalInformation: {
        weight,
        height,
        bp,
        hr,
        others: otherGeneral,
      },
      circunference: {
        arm,
        chest,
        abdominals,
        hip,
        thigh,
        calf,
        others: otherCircunference,
      },
      skinfold: {
        chest: skinfoldChest,
        triceps,
        subscapula,
        midaxillary,
        suprailiac,
        abdominal: abdominalSkinfold,
        thigh: skinfoldThigh,
        others: otherSkinfold,
      },
      performance: {
        vertical,
        mb_chest,
        yrd_300,
        bench_1rm,
        sq_1rm,
        others: otherPerformance,
      },
      pain_scale,
      therapist_notes,
      trainer_notes,
    };
    saveMeasurement(data);
  };
  useEffect(() => {
    if (formStep === 10) onSaveData();
  }, [formStep]);
  return (
    <div style={{ width: "50%" }}>
      <Heading>{t("measurementForm.clientMeasurement")}</Heading>
      {formStep === 5 && (
        <div>
          <Heading as="h4" size="md">
            {t("goalForm.generalInformation")}
          </Heading>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.weight")}</FormLabel>

            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />
              <InputRightElement children={lang === "es" ? "kg" : "lb"} />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.height")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={height}
                onChange={(e) => setHeight(e.target.value)}
              />
              <InputRightElement children={lang === "es" ? "cm" : "in"} />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.bp")}</FormLabel>
            <Input
              mb={2}
              type="number"
              variant="outline"
              value={bp}
              onChange={(e) => setBp(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.hr")}</FormLabel>
            <Input
              mb={2}
              type="number"
              variant="outline"
              value={hr}
              onChange={(e) => setHr(e.target.value)}
            />
          </FormControl>
          <div>
            <Heading s="h6" size="xs" mt={8}>
              {t("measurementForm.othersMeasurements")}
            </Heading>{" "}
            {otherGeneral.map((item, index) => (
              <Flex gridGap={4}>
                <FormControl mt={4}>
                  <FormLabel>{t("table_name")}</FormLabel>
                  <Input
                    mb={2}
                    type="text"
                    variant="outline"
                    value={item.name}
                    onChange={(e) => handleOtherGeneralChange(index, "name", e.target.value)}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>{t("value")}</FormLabel>
                  <Input
                    mb={2}
                    type="text"
                    variant="outline"
                    value={item.value}
                    onChange={(e) => handleOtherGeneralChange(index, "value", e.target.value)}
                  />
                </FormControl>
              </Flex>
            ))}
            <Button size="sm" mt={2} onClick={addOtherGeneral}>
              {t("addOther")}
            </Button>
          </div>
        </div>
      )}
      {formStep === 6 && (
        <div>
          <Heading as="h4" size="md">
            {t("measurementForm.circunferenceMeasurements")}
          </Heading>
          <FormControl mt={4}>
            <FormLabel>Arm</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={arm}
                onChange={(e) => setArm(e.target.value)}
              />
              <InputRightElement children={lang === "es" ? "cm" : "in"} />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.chest")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={chest}
                onChange={(e) => setChest(e.target.value)}
              />
              <InputRightElement children={lang === "es" ? "cm" : "in"} />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.abdominals")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={abdominals}
                onChange={(e) => setAbdominals(e.target.value)}
              />
              <InputRightElement children={lang === "es" ? "cm" : "in"} />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.hip")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={hip}
                onChange={(e) => setHip(e.target.value)}
              />
              <InputRightElement children={lang === "es" ? "cm" : "in"} />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.thigh")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={thigh}
                onChange={(e) => setThigh(e.target.value)}
              />
              <InputRightElement children={lang === "es" ? "cm" : "in"} />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.calf")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={calf}
                onChange={(e) => setCalf(e.target.value)}
              />
              <InputRightElement children={lang === "es" ? "cm" : "in"} />
            </InputGroup>
          </FormControl>
          <div>
            <Heading s="h6" size="xs" mt={8}>
              {t("measurementForm.othersMeasurements")}
            </Heading>
            {otherCircunference.map((item, index) => (
              <Flex gridGap={4}>
                <FormControl mt={4}>
                  <FormLabel>{t("table_name")}</FormLabel>
                  <Input
                    mb={2}
                    type="text"
                    variant="outline"
                    value={item.name}
                    onChange={(e) => handleOtherCircunferenceChange(index, "name", e.target.value)}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>{t("value")}</FormLabel>
                  <Input
                    mb={2}
                    type="text"
                    variant="outline"
                    value={item.value}
                    onChange={(e) => handleOtherCircunferenceChange(index, "value", e.target.value)}
                  />
                </FormControl>
              </Flex>
            ))}
            <Button size="sm" mt={2} onClick={addOtherCircunference}>
              {t("addOther")}
            </Button>
          </div>
        </div>
      )}
      {formStep === 7 && (
        <div>
          <Heading as="h4" size="md">
            {t("measurementForm.skinfoldMeasurements")}
          </Heading>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.chest")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={skinfoldChest}
                onChange={(e) => setSkinfoldChest(e.target.value)}
              />
              <InputRightElement children="mm" />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.triceps")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={triceps}
                onChange={(e) => setTriceps(e.target.value)}
              />
              <InputRightElement children="mm" />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.subscapula")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={subscapula}
                onChange={(e) => setSubscapula(e.target.value)}
              />
              <InputRightElement children="mm" />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.midaxillary")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={midaxillary}
                onChange={(e) => setMidaxillary(e.target.value)}
              />
              <InputRightElement children="mm" />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.suprailiac")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={suprailiac}
                onChange={(e) => setSuprailiac(e.target.value)}
              />
              <InputRightElement children="mm" />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.abdominal")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={abdominalSkinfold}
                onChange={(e) => setAbdominalSkinfold(e.target.value)}
              />
              <InputRightElement children="mm" />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.thigh")}</FormLabel>
            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={skinfoldThigh}
                onChange={(e) => setSkinfoldThigh(e.target.value)}
              />
              <InputRightElement children="mm" />
            </InputGroup>
          </FormControl>
          <div>
            <Heading s="h6" size="xs" mt={8}>
              {t("measurementForm.othersMeasurements")}
            </Heading>
            {otherSkinfold.map((item, index) => (
              <Flex gridGap={4}>
                <FormControl mt={4}>
                  <FormLabel>{t("table_name")}</FormLabel>
                  <Input
                    mb={2}
                    type="text"
                    variant="outline"
                    value={item.name}
                    onChange={(e) => handleOtherSkinfoldChange(index, "name", e.target.value)}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>{t("value")}</FormLabel>
                  <Input
                    mb={2}
                    type="text"
                    variant="outline"
                    value={item.value}
                    onChange={(e) => handleOtherSkinfoldChange(index, "value", e.target.value)}
                  />
                </FormControl>
              </Flex>
            ))}
            <Button size="sm" mt={2} onClick={addOtherSkinfold}>
              {t("addOther")}
            </Button>
          </div>
        </div>
      )}
      {formStep === 8 && (
        <div>
          <Heading as="h4" size="md">
            {t("measurementForm.performanceMeasurements")}
          </Heading>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.vertical")}</FormLabel>

            <InputGroup>
              <Input
                mb={2}
                type="number"
                variant="outline"
                value={vertical}
                onChange={(e) => setVertical(e.target.value)}
              />
              <InputRightElement children={lang === "es" ? "cm" : "in"} />
            </InputGroup>
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.mbChest")}</FormLabel>
            <Input
              mb={2}
              type="number"
              variant="outline"
              value={mb_chest}
              onChange={(e) => setMbChest(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.yrd300")}</FormLabel>
            <Input
              mb={2}
              type="number"
              variant="outline"
              value={yrd_300}
              onChange={(e) => setYard300(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.bench1rm")}</FormLabel>
            <Input
              mb={2}
              type="number"
              variant="outline"
              value={bench_1rm}
              onChange={(e) => setBench1rm(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.sq1rm")}</FormLabel>
            <Input
              mb={2}
              type="number"
              variant="outline"
              value={sq_1rm}
              onChange={(e) => setSq1rm(e.target.value)}
            />
          </FormControl>
          <div>
            <Heading s="h6" size="xs" mt={8}>
              {t("measurementForm.othersMeasurements")}
            </Heading>
            {otherPerformance.map((item, index) => (
              <Flex gridGap={4}>
                <FormControl mt={4}>
                  <FormLabel>{t("table_name")}</FormLabel>
                  <Input
                    mb={2}
                    type="text"
                    variant="outline"
                    value={item.name}
                    onChange={(e) => handleOtherPerformanceChange(index, "name", e.target.value)}
                  />
                </FormControl>
                <FormControl mt={4}>
                  <FormLabel>{t("value")}</FormLabel>
                  <Input
                    mb={2}
                    type="text"
                    variant="outline"
                    value={item.value}
                    onChange={(e) => handleOtherPerformanceChange(index, "value", e.target.value)}
                  />
                </FormControl>
              </Flex>
            ))}
            <Button size="sm" mt={2} onClick={addOtherPerformance}>
              {t("addOther")}
            </Button>
          </div>
        </div>
      )}
      {formStep === 9 && (
        <div>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.painScale")}</FormLabel>
            <Input
              mb={2}
              type="number"
              variant="outline"
              value={pain_scale}
              onChange={(e) => setPainScale(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.notesTherapist")}</FormLabel>
            <Textarea
              mb={2}
              type="text"
              variant="outline"
              value={therapist_notes}
              onChange={(e) => setTherapistNotes(e.target.value)}
            />
          </FormControl>
          <FormControl mt={4}>
            <FormLabel>{t("measurementForm.notesFromTrainer")}</FormLabel>
            <Textarea
              mb={2}
              type="text"
              variant="outline"
              value={trainer_notes}
              onChange={(e) => setTrainerNotes(e.target.value)}
            />
          </FormControl>
        </div>
      )}
    </div>
  );
};

export default MeasurementsForm;
