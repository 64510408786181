/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { shape, string, number, bool } from "prop-types";
import useLang from "hooks/useLang";
import Board from "./components/Board";

const Custom = ({ name, phase, client, clientInfo }) => {
  const [filtered, setFiltered] = useState([]);
  
  const { t } = useLang();

  useEffect(() => {
    const sidebar = document.getElementsByClassName("sidebar")[0];
    window.onbeforeunload = (e) => {
      console.log(e);
      return "Leaving this page will reset the wizard";
    };
    window.history.pushState(null, document.title, window.location.href);
    window.onpopstate = () => {
      const leavePage = window.confirm(t("back_confirmation"));
      if (leavePage) {
        window.onpopstate = null;
        window.history.back();
      } else {
        window.history.pushState(null, document.title, window.location.href);
      }
    };
    if (sidebar) sidebar.setAttribute("class", "sidebar collapse");
    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
      if (sidebar) sidebar.setAttribute("class", "sidebar");
    };
  }, []);
  return (
    <div id="board">
      <Board
        initial={filtered}
        withScrollableColumns
        name={name}
        phase={phase}
        client={client}
        setFiltered={setFiltered}
        clientInfo={clientInfo}
      />
    </div>
  );
};

Custom.propTypes = {
  dayDeleted: bool.isRequired,
  date: shape({
    day: number.isRequired,
    week: number.isRequired,
  }).isRequired,
  actual: shape({
    day: number.isRequired,
    week: number.isRequired,
  }).isRequired,
  cell: shape({
    value: string.isRequired,
    row: shape({
      index: number.isRequired,
    }).isRequired,
    column: shape({
      id: number.isRequired,
    }).isRequired,
  }).isRequired,
  data: shape([]).isRequired,
  preData: shape({
    exercises: shape([]).isRequired,
    notes: string.isRequired,
  }).isRequired,
};

export default Custom;
