/* eslint-disable max-len */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  CheckboxGroup,
  Grid,
  Input,
  InputGroup,
  InputLeftElement,
} from "@chakra-ui/react";
import {
  categoriesOptions,
  bodyPartOptions,
  equipmentOptions,
} from "components/create/Features/options";

import { getTemplates } from "services/template";
import { useSelector } from "react-redux";
import { getPreprogrammed } from "services/preProgrammed";
import { SearchIcon } from "@chakra-ui/icons";
import Select from "react-select";
import useLang from "hooks/useLang";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ProgramsList from "../ProgramsList";
import TemplateList from "../TemplateList";

import mainStyle from "../../../programs/Main.module.css";

const ListContainer = ({ type, toggle, setSelectData, clientInfo }) => {
  // states
  const { id_user } = useSelector(({ user }) => user);
  const [isBodyPart, setIsBodyPart] = useState(false);
  const [bodyPartList, setBodyPartList] = useState([]);
  const [isImplements, setIsImplements] = useState(false);
  const [implementsList, setImplementsList] = useState([]);
  const [categories, setCategories] = useState([]);
  const { lang, t } = useLang();
  const [name, setName] = useState("");
  const [options, setOptions] = useState([[], [], []]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nPages, setNPages] = useState(0);
  const [data, setData] = useState([]);

  const [loading, setLoading] = useState(true);

  const getProgram = async (indexPage) => {
    try {
      setLoading(true);
      const op = options.flat().filter((a) => a !== "" || a !== null);
      let resp = [];
      if (type === "preprogrammed") {
        resp = await getPreprogrammed({
          lang,
          name,
          options: op,
          page: indexPage,
          user: id_user,
          combine: true,
        });
        setData(resp.preProgrammedWorkouts);
      }
      if (type === "template") {
        resp = await getTemplates({
          lang,
          name,
          options: op,
          page: indexPage,
          user: id_user,
          combine: true,
        });
        setData(resp.templates);
      }
      setNPages(resp.lastPage !== 0 ? resp.lastPage : 1);
      setCurrentPage(resp.page);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const handleChange = (info, actionMeta) => {
    console.log(info, actionMeta);
    const filteredData = info.map((item) => item.value);
    if (actionMeta.name === "category")
      setOptions((prev) => prev.map((item, id) => (id === 0 ? filteredData : item)));
    if (actionMeta.name === "bodyParts")
      setOptions((prev) => prev.map((item, id) => (id === 1 ? filteredData : item)));
    if (actionMeta.name === "equipment")
      setOptions((prev) => prev.map((item, id) => (id === 2 ? filteredData : item)));
  };
  useEffect(() => {}, []);
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      console.log("here? 2");
      getProgram();
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [name]);
  useEffect(() => {
    getProgram();
  }, [options]);
  const listFiltered = [bodyPartList, categories, implementsList];
  return (
    <div>
      <div>
        <Button
          as={Link}
          to={{
            pathname: "/user/clients",
            state: {
              clientInfo,
            },
          }}
          colorScheme="gray"
          variant="ghost"
          leftIcon={<ArrowBackIosNewIcon />}
          color="gray">
          {t("backBtn")}
        </Button>
      </div>
      <>
        <div className={mainStyle.search}>
          <InputGroup colorScheme="red" w="44%">
            <InputLeftElement>
              <SearchIcon />
            </InputLeftElement>
            <Input
              variant="outline"
              placeholder={t("search")}
              colorScheme="red"
              value={name}
              onChange={({ target }) => setName(target.value)}
            />
          </InputGroup>
          <div className={mainStyle.filters} style={{ zIndex: 200 }}>
            <Box py={1}>
              <Select
                isMulti
                name="category"
                options={categoriesOptions.map((item) => ({
                  value: item.value,
                  label: t(item.label),
                }))}
                colorScheme="red"
                placeholder={t("select_category")}
                variant="filled"
                classNamePrefix="select"
                className="basic-multi-select"
                onChange={handleChange}
              />
            </Box>
            <Box py={1}>
              <Select
                isMulti
                name="equipment"
                options={equipmentOptions.map((item) => ({
                  value: item.value,
                  label: t(item.label),
                }))}
                colorScheme="red"
                placeholder={t("select_implements")}
                variant="filled"
                classNamePrefix="select"
                className="basic-multi-select"
                onChange={handleChange}
              />
            </Box>
            <Box py={1}>
              <Select
                isMulti
                name="bodyParts"
                options={bodyPartOptions.map((item) => ({
                  value: item.value,
                  label: t(item.label),
                }))}
                colorScheme="red"
                placeholder={t("select_bodypart")}
                variant="filled"
                classNamePrefix="select"
                className="basic-multi-select"
                onChange={handleChange}
              />
            </Box>
          </div>
        </div>
      </>
      {type === "template" && (
        <TemplateList
          search={name}
          list={options}
          data={data}
          toggle={toggle}
          setFinalData={setSelectData}
          getProgram={getProgram}
          currentPage={currentPage}
          nPages={nPages}
          setCurrentPage={setCurrentPage}
          setNPages={setNPages}
          loading={loading}
        />
      )}
      {type === "preprogrammed" && (
        <ProgramsList
          search={name}
          list={options}
          data={data}
          toggle={toggle}
          setFinalData={setSelectData}
          getProgram={getProgram}
          currentPage={currentPage}
          nPages={nPages}
          setCurrentPage={setCurrentPage}
          setNPages={setNPages}
          loading={loading}
        />
      )}
    </div>
  );
};

export default ListContainer;
