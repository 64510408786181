/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
// ? External Dependecies
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import { func } from "prop-types";
import {
  Button,
  ButtonGroup,
  Container,
  IconButton,
  Select,
  FormControl,
  Input,
  Heading,
  Flex,
  FormLabel,
  Box,
} from "@chakra-ui/react";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
// ? Local Dependecies
import useAdd from "hooks/useAdd";
import { transformClientData } from "utils/transformData";
import notificationDisplay from "utils/notificationDisplay";
import { createClient } from "services/clients";

import { infoStyle, inputStyle } from "theme/commonStyles";
import TextInput from "components/common/TextInput";
import DateInput from "components/common/DateInput";
import { container } from "./Adder.module.css";

const groupStyle = {
  alignItems: "center",
  display: "flex",
  justifyContent: "flex-end",
  margin: "1rem 0",
};
const addressBtn = {
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "center",
  color: "#444444",
};

const ClientAdder = ({ setTab }) => {
  const [secondCity, setSecondCity] = useState(false);
  const { id_user } = useSelector(({ user }) => user);
  const { control, handleSubmit } = useForm();
  const { lang, t } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { mutate, isLoading, isSuccess, isError } = useAdd("clients", createClient);

  const onSubmit = (data) => mutate(transformClientData(data, id_user, lang));
  const onClose = () =>
    setTab({
      value: "close",
      isSelected: false,
      client: {},
    });

  if (isLoading) return <p style={infoStyle}>{t("data_addition")}</p>;
  if (isError) return <p style={infoStyle}>{t("something_wrong")}</p>;
  if (isSuccess) {
    onClose();
    notificationDisplay(dispatch, "Client added succesfully", "success");
  }
  return (
    <Container maxW="container.lg">
      <Box marginBottom="1rem">
        <Button
          size="xs"
          color="gray"
          colorScheme="gray"
          variant="outline"
          onClick={() => onClose()}
          leftIcon={<ArrowBackIosIcon fontSize="inherit" />}>
          {t("backBtn")}
        </Button>
      </Box>
      <form onSubmit={handleSubmit(onSubmit)} className={container}>
        <Heading size="md" marginBottom="1rem">
          {t("client_basic_info")}
        </Heading>
        <fieldset>
          <Flex gridGap="10px">
            <TextInput control={control} name="name" place={t("name")} />
            <TextInput control={control} name="lastName" place={t("lastName")} />
          </Flex>
          <Flex gridGap="10px">
            <TextInput control={control} name="phone" place={t("phonenumber")} />
            <TextInput control={control} name="email" place={t("email")} />
          </Flex>
          <Flex gridGap="10px" marginBottom="1rem">
            <DateInput w="100%" control={control} name="birth_date" place={t("birth_date")} />

            <div style={{ width: "100%" }}>
              <p style={{ marginBottom: "8px" }}>
                {t("gender")}
                <span style={{ color: "red" }}>{" *"}</span>
              </p>
              <Controller
                control={control}
                name="gender"
                defaultValue=""
                render={({ field }) => (
                  <Select
                    placeholder={t("select_gender")}
                    backgroundColor="#ececec"
                    focusBorderColor="ptRed.500"
                    color="#444444"
                    {...field}>
                    <option value="male">{t("opt_male")}</option>
                    <option value="female">{t("opt_female")}</option>
                  </Select>
                )}
              />
            </div>
          </Flex>

          <div role="group" style={addressBtn}>
            <TextInput control={control} name="street1" place={t("principal_address")} />
            <IconButton
              mb="4"
              icon={!secondCity ? <AddIcon /> : <MinusIcon />}
              onClick={() => setSecondCity(!secondCity)}
            />
          </div>

          {secondCity && (
            <FormControl>
              <FormLabel>{t("secondary_address")}</FormLabel>
              <Controller
                control={control}
                name="street2"
                render={({ field }) => (
                  <Input variant="filled" type="text" {...field} {...inputStyle} />
                )}
              />
            </FormControl>
          )}
          <Flex gridGap="10px">
            <TextInput control={control} name="city" place={t("city")} />
            <TextInput control={control} name="state" place={t("state")} />
            <TextInput control={control} name="zip" place={t("zip")} />
          </Flex>
        </fieldset>

        <fieldset>
          <TextInput control={control} name="speciality" place={t("main_goals")} />
        </fieldset>
        <ButtonGroup style={groupStyle} colorScheme="ptRed">
          <Button variant="outline" onClick={onClose} colorScheme="gray">
            {t("cancel")}
          </Button>
          <Button type="submit">{t("save")}</Button>
        </ButtonGroup>
      </form>
    </Container>
  );
};

ClientAdder.propTypes = {
  close: func.isRequired,
};

export default ClientAdder;
