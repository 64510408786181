/* eslint-disable no-unused-vars */
import React from 'react';
import {
  AlertDialog, AlertDialogBody, AlertDialogFooter,
  AlertDialogHeader, AlertDialogContent, AlertDialogOverlay,
  Button,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import propTypes from 'prop-types';

import { infoStyle } from 'theme/commonStyles';
import notificationDisplay from 'utils/notificationDisplay';

const DeleteWithRedirect = ({
  open, close, id, title, func, setTab, setClients,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    mutate, isLoading, isSuccess, isError,
  } = func();

  const onDelete = () => mutate(id);

  if (isSuccess) {
    setTab({ isSelected: false, value: 'close', client: {} });
    notificationDisplay(dispatch, t('succesful_delete'), 'success');
    setClients((prev) => {
      const prevFiltered = prev.filter((item) => item._id !== id);
      return prevFiltered;
    });
  }
  if (isError) {
    notificationDisplay(dispatch, t('something_wrong'), 'error');
    close();
  }
  return (
    <AlertDialog isOpen={open} onClose={close}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{title}</AlertDialogHeader>
          {
            isLoading && <AlertDialogBody style={infoStyle}>{t('deleting')}</AlertDialogBody>
          }
          {
            isError && <AlertDialogBody style={infoStyle}>{t('something_wrong')}</AlertDialogBody>
          }
          {
            isSuccess && <AlertDialogBody style={infoStyle}>{t('succesful_delete')}</AlertDialogBody>
          }
          {(!isLoading && !isSuccess) && (
          <>
            <AlertDialogBody>
              {t('delete_confirmation')}
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={close}>
                {t('cancel')}
              </Button>
              <Button colorScheme="red" onClick={onDelete} ml={3}>
                {t('delete')}
              </Button>
            </AlertDialogFooter>
          </>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

DeleteWithRedirect.propTypes = {
  open: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  id: propTypes.string.isRequired,
  title: propTypes.string.isRequired,
  func: propTypes.func.isRequired,
  setClients: propTypes.func.isRequired,
  setTab: propTypes.func.isRequired,
};

export default DeleteWithRedirect;
