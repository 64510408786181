/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import useLang from "hooks/useLang";
import {
  Button,
  Flex,
  Heading,
  Image,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  AbsoluteCenter,
  Divider,
  Box,
  Badge,
  Text,
} from "@chakra-ui/react";
import CheckIcon from "@mui/icons-material/Check";
import { superSetColors, superSetColorsHex } from "theme/commonStyles";
import logo from "assets/img2.png";
import Carousel from "components/common/Carousel";
import styles from "./sharedRoutine.module.scss";

const ExerciseCard = ({ exercise, week, day }) => {
  const { t, lang } = useLang();

  const [carouselStep, setCarouselStep] = useState(0);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [checksExercises, setLocalChecksExercises] = useState([]);
  const handleCarouselClick = (movement) => {
    setCarouselStep((prev) => (movement === "prev" ? prev - 1 : prev + 1));
  };

  const addCheck = (id) => {
    const localChecksExercises = JSON.parse(localStorage.getItem("localChecksExercises")) || [];
    const index = localChecksExercises.findIndex((item) => item.id === id);
    if (index >= 0) {
      localChecksExercises.splice(index, 1);
    } else localChecksExercises.push({ day, week, id });
    setLocalChecksExercises(localChecksExercises);
    localStorage.setItem("localChecksExercises", JSON.stringify(localChecksExercises));
  };
  const isChecked = (id) => {
    return checksExercises.findIndex((item) => item.id === id) >= 0;
  };
  useEffect(() => {
    setLocalChecksExercises(JSON.parse(localStorage.getItem("localChecksExercises")) || []);
  }, []);
  return (
    <article>
      <div className={styles.preview} onClick={() => (exercise.id_exercise ? onOpen() : null)}>
        {exercise.id_exercise?.images?.length <= 0 ? (
          <Image src={logo} fallbackSrc={logo} alt="file exercise" />
        ) : (
          <>
            {!exercise.id_exercise?.images[0].includes("mp4") &&
            !exercise.id_exercise?.images[0].includes("mov") ? (
              <Image
                src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${exercise.id_exercise?.images[0]}`}
                fallbackSrc={logo}
                alt="file exercise"
              />
            ) : (
              <video
                width="400"
                preload="metadata"
                fallbackSrc={exercise.id_exercise?.images[0] || logo}
                muted
                loop
                autoPlay
                playsInline
                allowFullScreen="false"
                src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${exercise.id_exercise?.images[0]}#t=0.1`}
                type="video/mp4"
              />
            )}
          </>
        )}
      </div>
      <Flex w="100%" flexDirection="column" justifyContent="space-between">
        <div>
          <Heading as="h4" size="md" onClick={() => (exercise.id_exercise ? onOpen() : null)}>
            {exercise.id_exercise?.name?.[lang] || (
              <span style={{ textTransform: "uppercase" }}>{t("undefined_exercise")}</span>
            )}
          </Heading>
          <p className={styles.exerciseData}>
            {exercise.sets[0].load !== "" && exercise.sets[0].load !== "0" ? (
              <>
                {exercise.sets.length} Sets x{" "}
                {exercise.sets?.map((s) => (
                  <span>
                    {s.load}
                    {s.typeWeight === "others" ? "" : s.typeWeight}/{s.reps}
                    {s?.typeReps ?? ""},{" "}
                  </span>
                ))}
              </>
            ) : (
              <>
                {exercise.sets.length} Sets x {exercise.sets[0].reps}
                {exercise.sets[0].typeReps ?? ""}
              </>
            )}
          </p>
        </div>
        {exercise.notes_exercise[lang] && (
          <div className={styles.cardNotes}>
            <Text>{exercise.notes_exercise[lang]}</Text>
            <Button
              size="xs"
              variant="link"
              colorScheme="teal"
              fontSize="11px"
              onClick={() => (exercise.id_exercise ? onOpen() : null)}>
              {t("showMore")}
            </Button>
          </div>
        )}
        <Flex justifyContent="space-between" alignItems="center">
          {exercise.colors?.hard && exercise.colors?.hard !== "white" ? (
            <span
              className={styles.circuit}
              style={{ backgroundColor: `${superSetColorsHex[exercise.colors?.hard]}` }}>
              {t("circuit")}
            </span>
          ) : null}
        </Flex>
      </Flex>
      <div className={styles.checkExercise}>
        <div
          className={`${styles.check} ${isChecked(exercise._id) ? styles.active : null}`}
          onClick={() => addCheck(exercise._id)}>
          {isChecked(exercise._id) && <CheckIcon fontSize="inherit" />}
        </div>
        {isChecked(exercise._id) && <div className={styles.checkText}>{t("done")}</div>}
      </div>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            style={{ backgroundColor: "white", top: "0px", right: "0px", borderRadius: "50%" }}
          />
          <ModalBody my={4}>
            <Carousel
              step={carouselStep}
              images={exercise.id_exercise?.images}
              click={handleCarouselClick}
            />
            <Heading as="h4" size="md" my={4}>
              {exercise.id_exercise?.name?.[lang] || ""}
              {exercise.colors?.hard && exercise.colors?.hard !== "white" ? (
                <span
                  className={styles.circuit}
                  style={{
                    margin: "0 10px",
                    backgroundColor: `${superSetColorsHex[exercise.colors.hard]}`,
                  }}>
                  {t("circuit")}
                </span>
              ) : null}
            </Heading>

            <div>
              {exercise.sets?.map((s, index) => (
                <div className={styles.exerciseDataExpand}>
                  <p>Set {index + 1}</p>
                  <p>
                    {s.load}
                    {s.typeWeight === "others" ? "" : s.typeWeight} / {s.reps}
                    {s?.typeReps ?? ""}
                  </p>
                </div>
              ))}
            </div>
            {exercise?.notes_exercise?.[lang] && (
              <>
                <Box position="relative" padding="8">
                  <Divider />
                  <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="12px" color="gray">
                    {t("notes_exercise")}
                  </AbsoluteCenter>
                </Box>
                <ol style={{ fontSize: "0.8rem", padding: "0.5rem 1rem 0.5rem 2rem" }}>
                  {exercise.notes_exercise[lang]}
                </ol>
              </>
            )}
            <Box position="relative" padding="8">
              <Divider />
              <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="12px" color="gray">
                {t("instructions")}
              </AbsoluteCenter>
            </Box>
            <ol style={{ fontSize: "0.8rem", padding: "0.5rem 1rem 0.5rem 2rem" }}>
              {exercise.id_exercise?.instructions?.[lang]
                .filter((instruction) => instruction !== "")
                .map((instruction) => (
                  <li key={instruction}>{instruction}</li>
                ))}
            </ol>
            <Box position="relative" padding="4">
              <Divider />
              <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="12px" color="gray">
                {t("muscle_system")}
              </AbsoluteCenter>
            </Box>
            {exercise.id_exercise?.muscle_system
              ?.filter((muscle) => muscle !== "")
              .map((muscle) => (
                <Badge colorScheme="gray" mr="0.5rem" key={muscle}>
                  {t(muscle)}
                </Badge>
              ))}
            <Box position="relative" padding="4">
              <Divider />
              <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="12px" color="gray">
                {t("equipment")}
              </AbsoluteCenter>
            </Box>
            {exercise.id_exercise?.equipment?.map((eq) => (
              <Badge colorScheme="gray" key={eq}>
                {t(eq)}
              </Badge>
            ))}
          </ModalBody>
        </ModalContent>
      </Modal>
    </article>
  );
};

export default ExerciseCard;
