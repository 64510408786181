/* eslint-disable react/prop-types */
import {
  Box,
  Button,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from "@chakra-ui/react";
import useLang from "hooks/useLang";
import { Search2Icon } from "@chakra-ui/icons";
import { useRef, useState } from "react";
import ItemSection from "./ItemSection";
import { dataListSections } from "./dataListSections";
import styles from "./itemSection.module.scss";

const HelpAboutAppModal = ({ isOpen, onClose }) => {
  const initialRef = useRef(null);
  const { t } = useLang();
  const [searchValue, setSearchValue] = useState("");
  
  return (
    <Modal
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={onClose}
      size="2xl"
      initialFocusRef={initialRef}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("title_help_modal")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Tabs variant="enclosed">
            <TabList>
              <Tab>{t("questions")}</Tab>
              {/* <Tab>{t("gloasary")}</Tab> */}
            </TabList>
            <TabPanels>
              <TabPanel>
                <Box>
                  <InputGroup border="1px solid gray" borderRadius="md">
                    <Input
                      pr="4.5rem"
                      type="text"
                      placeholder={t("write_questions")}
                      onChange={({ target }) => setSearchValue(target.value)}
                      value={searchValue}
                      size="lg"
                      ref={initialRef}
                    />
                    <InputRightElement width="4.5rem">
                      <Button size="lg" mt={2} ml={1}>
                        <Search2Icon />
                      </Button>
                    </InputRightElement>
                  </InputGroup>
                  <Box>
                    <ul className={styles.container__items}>
                      {dataListSections
                        ?.filter(({ section, about }) =>
                          new RegExp(searchValue, "gi").test(`${section} ${about}`),
                        )
                        .map((section) => (
                          <ItemSection key={section.id} data={section} onCloseAll={onClose} />
                        ))}
                    </ul>
                  </Box>
                </Box>
              </TabPanel>
              {/* <TabPanel>
                <p>two!</p>
              </TabPanel> */}
            </TabPanels>
          </Tabs>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default HelpAboutAppModal;
