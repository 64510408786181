/* eslint-disable consistent-return */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useReducer, useState, useRef, useEffect } from "react";
import { Flex, Heading, useDisclosure, Button } from "@chakra-ui/react";
import { useSelector, useDispatch } from "react-redux";
import { string, func } from "prop-types";

import { getAllEvaluationExercises } from "services/exercise";
import { createRoutine } from "services/customWorkout";
import { getFunctionaProgramModel } from "utils/exerciseModel";
import notificationDisplay from "utils/notificationDisplay";
import useLang from "hooks/useLang";

import TableDisplay from "components/routines/TableDisplay";
import RoutineInfo from "components/routines/workout/Features/RoutineInfo";
import hybridReducer, { initialState, deleteRow, updateData } from "./hybridReducer";
import ProgramExercises from "../Features/ProgramExercises";
import ProgramValue from "../Features/ProgramValue";
import BoardAssessments from "../Board";

const isEmpty = (data) => {
  const list = [
    "Core posterior",
    "Escapular empuje",
    "Rigidez core",
    "Rotadores externos cadera",
    "Escapular tracción",
    "Core anterior",
  ];

  return data.reduce((total, { exercise }) => list.indexOf(exercise) === -1, false);
};

const HybridProgram = ({ client, setStep }) => {
  const [state, dispatch] = useReducer(hybridReducer, initialState);
  const [isBtnLoading, toggleBtn] = useReducer((prev) => !prev, false);
  const [sessionSaved, toggleSession] = useReducer((prev) => !prev, false);
  const [position, setPosition] = useState({ rowIdx: null, columnIdx: null });
  const [exercise, setExercise] = useState("");
  const [value, setValue] = useState("");
  const [weakness, setWeakness] = useState(0);

  const [name, setName] = useState("");
  const [phase, setPhase] = useState("");

  const { _id: trainer } = useSelector(({ user }) => user);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef();
  const reduxDispatch = useDispatch();
  const { t, lang } = useLang();

  let response = "";

  const handleOpen = (rowIdx, columnIdx) => {
    setPosition({ rowIdx, columnIdx });
    onOpen();
  };
  const handleDelete = (idx) => {
    dispatch(deleteRow(idx));
    toggleSession();
  };

  const handleSave = async () => {
    if (!name) return notificationDisplay(dispatch, t("routine_name_req"), "error");
    const dataToSend = {
      source: "hybrid_program",
      client: client.split("___")[1],
      trainer,
      name,
      phase,
      sessions: state.data,
    };

    try {
      response = await createRoutine(getFunctionaProgramModel(dataToSend));
      notificationDisplay(
        reduxDispatch,
        t("succesful_addition", { data: response.customWorkout.workout_name[lang] }),
        "success",
      );
      setStep((prev) => prev - 1);
    } catch (e) {
      return notificationDisplay(dispatch, e.message, "error");
    }
  };

  const handleGenerateProgram = async () => {
    toggleBtn();
    const generateRandom = (size) => Math.floor(Math.random() * size);
    const fillFields = (data, exercises) => {
      data.forEach((item, idx) => {
        const weakList = exercises.filter((exer) => exer.weakness === item.weakness);
        if (item.traditional) {
          const weakListFiltered = weakList.filter((w) => w.category_id[0].name === "Traditional");
          const number = generateRandom(weakListFiltered.length);
          const selected = weakListFiltered[number];
          dispatch(updateData(selected._id, idx, "id"));
          return dispatch(updateData(selected.name[lang], idx, "exercise"));
        }
        const number = generateRandom(weakList.length);
        const selected = weakList[number];
        dispatch(updateData(selected._id, idx, "id"));
        dispatch(updateData(selected.name[lang], idx, "exercise"));
      });
    };

    try {
      const allExercises = await getAllEvaluationExercises();
      fillFields(state.data, allExercises);
      toggleBtn();
    } catch (e) {
      toggleBtn();
      return notificationDisplay(reduxDispatch, t("something_wrong", "error"));
    }
  };

  // set random exercieses in the field in the first render
  useEffect(() => {
    handleGenerateProgram();
  }, []);

  useEffect(() => {
    if (position.columnIdx && position.columnIdx !== "exercise") {
      setValue(state.data[position.rowIdx][position.columnIdx]);
    }
    if (position.columnIdx && position.columnIdx === "exercise") {
      setExercise(state.data[position.rowIdx][position.columnIdx]);
      setWeakness(state.data[position.rowIdx].weakness);
    }
  }, [position]);

  const date = {
    day: 1,
    week: 1,
  };

  return (
    <div>
      <Heading size="sm" textAlign="center" mb="10px">
        {t("hybrid_program")}
      </Heading>
      <BoardAssessments
        preInfo={getFunctionaProgramModel({ sessions: state.data })}
        workout="hybrid_program"
        client={client}
        isBtnLoading={isBtnLoading}
        handleGenerateProgram={handleGenerateProgram}
      />
    </div>
  );
};

HybridProgram.propTypes = {
  client: string.isRequired,
  setStep: func.isRequired,
};
export default HybridProgram;
