/* eslint-disable consistent-return */
/* eslint-disable import/prefer-default-export */
import instance from "./_axiosConfig";

const api = instance;

const generateNutrition = async (message) => {
  try {
    const response = await api.post("/nutrition/", message);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const createStylesDiets = async (data) => {
  try {
    const response = await api.post("/stylesdiets/create", data);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const createNutrition = async (data) => {
  try {
    const response = await api.post("/nutrition/create", data);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const getNutritionByClient = async (id) => {
  try {
    const response = await api.get(`/nutrition/${id}`);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const getNutritionByTrainer = async (id) => {
  try {
    const response = await api.get(`/nutrition/trainer/${id}`);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const getStylesDietsByTrainer = async (id) => {
  try {
    const response = await api.get(`/stylesdiets/trainer/${id}`);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const getNutritionShared = async (id) => {
  try {
    const response = await api.get(`/nutrition/shared/${id}`);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const deleteNutrition = async (id) => {
  try {
    const response = await api.delete(`/nutrition/${id}`);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const deleteStylesDiets = async (id) => {
  try {
    const response = await api.delete(`/stylesdiets/${id}`);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

const updateNutrition = async (id, data) => {
  try {
    const response = await api.put(`/nutrition/${id}`, data);
    return response.data;
  } catch (error) {
    console.log(error.response);
    return error.response;
  }
};

export {
  generateNutrition,
  createNutrition,
  getNutritionByClient,
  deleteNutrition,
  updateNutrition,
  getNutritionShared,
  getNutritionByTrainer,
  deleteStylesDiets,
  getStylesDietsByTrainer,
  createStylesDiets
};
