/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Controller, useForm, useFieldArray } from "react-hook-form";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  Button,
  Input,
  Checkbox,
  CheckboxGroup,
  HStack,
  Radio,
  RadioGroup,
  VStack,
  Text,
  Textarea,
  Flex,
  IconButton,
  SimpleGrid,
} from "@chakra-ui/react";
import { ArrowUpIcon, AddIcon, MinusIcon } from "@chakra-ui/icons";
import propTypes from "prop-types";

import useUploadFileS3 from "hooks/useUploadFileS3";
import useUpdate from "hooks/useUpdate";
import useDelete from "hooks/useDelete";
import { updateExercise, deleteExercise } from "services/exercise";
import { transformToEditExercise } from "utils/transformData";
import notificationDisplay from "utils/notificationDisplay";

import { infoStyle, tagStyle, inputStyle } from "theme/commonStyles";

import Toggle from "components/common/Toggle";
import DeleteAlert from "components/common/DeleteAlert";
import Carousel from "components/common/Carousel";
import FileUploader from "components/fileUploader/fileUploader";

const getInstructions = (instructions) => {
  const arrObj = [];
  instructions.forEach((instruction) => arrObj.push({ name: instruction }));
  return arrObj;
};

const Edit = ({ exercise }) => {
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [carouselStep, setCarouselStep] = useState(0);

  const [files, setFiles] = useState(exercise?.images || []);
  const [loading, setLoading] = useState(false);
  const lang = window.localStorage.getItem("i18nextLng");
  const { control, handleSubmit, register } = useForm({
    mode: "onChange",
    defaultValues: {
      instructions: getInstructions(exercise.instructions[lang].filter((item) => item)),
      muscle_system: exercise.muscle_system,
      equipment: exercise.equipment,
      action: exercise.action,
      category_id: exercise.category_id.map((a) => a._id),
    },
  });
  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
    control,
    name: "instructions",
  });
  const { mutate, isLoading, isError, isSuccess } = useUpdate(
    "exercises",
    exercise._id,
    updateExercise,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const handleDeleteClose = () => setDeleteOpen(false);
  const handleDeleteOpen = () => setDeleteOpen(true);

  const onSubmit = async (data) => {
    const dataToSend = transformToEditExercise(data, exercise, files, lang);
    delete dataToSend.tableData;
    delete dataToSend.avatar;

    mutate(dataToSend);
  };
  if (isLoading) return <p style={infoStyle}>{t("data_update")}</p>;
  if (isError) return <p style={infoStyle}>{t("something_wrong")}</p>;
  if (isSuccess) return <p style={infoStyle}>{t("succesful_update")}</p>;
  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        {/*  <Flex align="center" justify="center" as="fieldset">
          <Flex align="center" justify="center" direction="column">
            <Carousel
              step={carouselStep}
              click={handleCarouselClick}
              images={imgArr}
              mode="edit"
              erase={deleteImage}
            />
            <Button
              colorScheme="ptRed"
              my="1rem"
              rightIcon={<ArrowUpIcon />}
              onClick={() => document.querySelector('.file').click()}
              isLoading={loading}
            >
              {t('upload_image')}
            </Button>
            <input
              accept="image/*"
              className="file"
              type="file"
              {...register('images')}
              style={{ display: 'none' }}
            />
          </Flex>
        </Flex> */}

        <p style={tagStyle}>{t("exercise_name")}</p>
        <Flex gridGap={4} my="1rem">
          <Controller
            control={control}
            defaultValue={exercise.name.en}
            name="enName"
            render={({ field }) => (
              <Input variant="filled" placeholder={t("exercise_name_placeholder_en")} {...field} />
            )}
          />
          <Controller
            control={control}
            defaultValue={exercise.name.es}
            name="esName"
            render={({ field }) => (
              <Input variant="filled" placeholder={t("exercise_name_placeholder_es")} {...field} />
            )}
          />
        </Flex>
        <p style={tagStyle}>{t("muscle_system")}</p>
        <Controller
          control={control}
          name="muscle_system"
          defaultValue={[...exercise.muscle_system]}
          render={({ field }) => (
            <CheckboxGroup {...field} colorScheme="red">
              <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} spacing={10} my={3} px={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="opt_all_body">{t("opt_all_body")}</Checkbox>
                  <Checkbox value="opt_lower_body">{t("opt_lower_body")}</Checkbox>
                  <Checkbox value="opt_legs">{t("opt_legs")}</Checkbox>
                  <Checkbox value="opt_posterior_core">{t("opt_posterior_core")}</Checkbox>
                  <Checkbox value="opt_lumbar">{t("opt_lumbar")}</Checkbox>
                  <Checkbox value="opt_gluteus">{t("opt_gluteus")}</Checkbox>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="opt_hamstring">{t("opt_hamstring")}</Checkbox>
                  <Checkbox value="opt_quadriceps">{t("opt_quadriceps")}</Checkbox>
                  <Checkbox value="opt_adductors">{t("opt_adductors")}</Checkbox>
                  <Checkbox value="opt_abductors">{t("opt_abductors")}</Checkbox>
                  <Checkbox value="opt_calves">{t("opt_calves")}</Checkbox>
                  <Checkbox value="opt_upper_body">{t("opt_upper_body")}</Checkbox>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="opt_arms">{t("opt_arms")}</Checkbox>
                  <Checkbox value="opt_chest">{t("opt_chest")}</Checkbox>
                  <Checkbox value="opt_back">{t("opt_back")}</Checkbox>
                  <Checkbox value="opt_shoulder">{t("opt_shoulder")}</Checkbox>
                  <Checkbox value="opt_biceps">{t("opt_biceps")}</Checkbox>
                  <Checkbox value="opt_triceps">{t("opt_triceps")}</Checkbox>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="opt_forearms">{t("opt_forearms")}</Checkbox>
                  <Checkbox value="opt_anterior_core">{t("opt_anterior_core")}</Checkbox>
                  <Checkbox value="opt_abs">{t("opt_abs")}</Checkbox>
                  <Checkbox value="opt_hip_flexors">{t("opt_hip_flexors")}</Checkbox>
                  <Checkbox value="opt_lateral_core">{t("opt_lateral_core")}</Checkbox>
                  <Checkbox value="opt_rotational_core">{t("opt_rotational_core")}</Checkbox>
                  <Checkbox value="opt_other">{t("opt_other")}</Checkbox>
                </div>
              </SimpleGrid>
            </CheckboxGroup>
          )}
        />
        <p style={tagStyle}>{t("categories")}</p>
        <Controller
          control={control}
          name="category_id"
          defaultValue={exercise.category_id.map((x) => x._id)}
          render={({ field }) => (
            <CheckboxGroup {...field} colorScheme="red" as="fieldset">
              <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} spacing={10} my={3} px={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="6171d84aec64ada0efa05721">{t("opt_traditional")}</Checkbox>
                  <Checkbox value="61a62b7fb2f0481a2d9db38e">{t("opt_functional")}</Checkbox>
                  <Checkbox value="61be5e479abd1ebc3d540764">{t("opt_rehab")}</Checkbox>
                  <Checkbox value="61a62ba3b2f0481a2d9db38f">{t("opt_power")}</Checkbox>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="61be5e799abd1ebc3d540767">{t("opt_metabolic")}</Checkbox>
                  <Checkbox value="61be5e969abd1ebc3d54076a">{t("opt_saq")}</Checkbox>
                  <Checkbox value="669a8c4f1d9afe6f1f05328a">
                    {t("opt_flexibility_mobility")}
                  </Checkbox>
                  <Checkbox value="66bd27617fa55c9d9bd4b809">{t("opt_cardio")}</Checkbox>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="669a90631d9afe6f1f05328c">{t("opt_other")}</Checkbox>
                </div>
              </SimpleGrid>
            </CheckboxGroup>
          )}
        />
        <p style={tagStyle}>{t("action")}</p>
        <Controller
          control={control}
          name="action"
          defaultValue={exercise.action}
          render={({ field }) => (
            <CheckboxGroup {...field} colorScheme="red">
              <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} spacing={10} my={3} px={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="opt_level_change">{t("opt_level_change")}</Checkbox>
                  <Checkbox value="opt_hip_hinge">{t("opt_hip_hinge")}</Checkbox>
                  <Checkbox value="opt_push">{t("opt_push")}</Checkbox>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="opt_pull">{t("opt_pull")}</Checkbox>
                  <Checkbox value="opt_push_pull">{t("opt_push_pull")}</Checkbox>
                  <Checkbox value="opt_core_stiffness">{t("opt_core_stiffness")}</Checkbox>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="opt_rotation">{t("opt_rotation")}</Checkbox>
                  <Checkbox value="opt_locomotion">{t("opt_locomotion")}</Checkbox>
                  <Checkbox value="opt_skip">{t("opt_skip")}</Checkbox>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="opt_jumps">{t("opt_jumps")}</Checkbox>
                  <Checkbox value="opt_tumbling">{t("opt_tumbling")}</Checkbox>
                  <Checkbox value="opt_isometric">{t("opt_isometric")}</Checkbox>
                  <Checkbox value="opt_other">{t("opt_other")}</Checkbox>
                </div>
              </SimpleGrid>
            </CheckboxGroup>
          )}
        />
        <p style={tagStyle}>{t("equipment")}</p>
        <Controller
          control={control}
          name="equipment"
          render={({ field }) => (
            <CheckboxGroup {...field} colorScheme="red">
              <SimpleGrid columns={{ base: 2, md: 2, lg: 4 }} spacing={10} my={3} px={4}>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="opt_bw">{t("opt_bw")}</Checkbox>
                  <Checkbox value="opt_bb">{t("opt_bb")}</Checkbox>
                  <Checkbox value="opt_db/kb">{t("opt_db/kb")}</Checkbox>
                  <Checkbox value="opt_m">{t("opt_m")}</Checkbox>
                  <Checkbox value="opt_bp">{t("opt_bp")}</Checkbox>
                  <Checkbox value="opt_rb">{t("opt_rb")}</Checkbox>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="opt_mb">{t("opt_mb")}</Checkbox>
                  <Checkbox value="opt_sb">{t("opt_sb")}</Checkbox>
                  <Checkbox value="opt_plate">{t("opt_plate")}</Checkbox>
                  <Checkbox value="opt_sus">{t("opt_sus")}</Checkbox>
                  <Checkbox value="opt_ropes">{t("opt_ropes")}</Checkbox>
                  <Checkbox value="opt_ez">{t("opt_ez")}</Checkbox>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="opt_sm">{t("opt_sm")}</Checkbox>
                  <Checkbox value="opt_sled">{t("opt_sled")}</Checkbox>
                  <Checkbox value="opt_lm">{t("opt_lm")}</Checkbox>
                  <Checkbox value="opt_hg360">{t("opt_hg360")}</Checkbox>
                  <Checkbox value="opt_agl">{t("opt_agl")}</Checkbox>
                  <Checkbox value="opt_cones">{t("opt_cones")}</Checkbox>
                </div>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <Checkbox value="opt_hur">{t("opt_hur")}</Checkbox>
                  <Checkbox value="opt_multiple">{t("opt_multiple")}</Checkbox>
                  <Checkbox value="opt_other">{t("opt_other")}</Checkbox>
                </div>
              </SimpleGrid>
            </CheckboxGroup>
          )}
        />
        {/* testing */}
        <p style={tagStyle}>evaluation</p>
        <Controller
          control={control}
          name="weakness"
          defaultValue={exercise.weakness ? String(exercise.weakness) : ""}
          render={({ field }) => (
            <SimpleGrid columns={4} spacing={10} my={3} px={4}>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {" "}
                <Radio value="">None</Radio>
                <Radio value="1">Core Posterior</Radio>
                <Radio value="6">{t("core_stiffness")}</Radio>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <Radio value="2">{t("core_anterior")}</Radio>
                <Radio value="3">{t("escapular_push")}</Radio>
              </div>
              <div style={{ display: "flex", flexDirection: "column" }}>
                {" "}
                <Radio value="4">{t("escapular_pull")}</Radio>
                <Radio value="5">{t("rotation")}</Radio>
              </div>
            </SimpleGrid>
          )}
        />

        <Text color="#d9ad42" fontWeight="700" alignSelf="start" my="1rem">
          {t("instructions")}
        </Text>
        {fields.map(({ id, name }, index) => (
          <>
            <Input
              key={id}
              variant="filled"
              name={`instructions.${index}.name`}
              defaultValue={name}
              placeholder={`Step: ${index + 1}`}
              {...register(`instructions.${index}.name`)}
              {...inputStyle}
              w="90%"
              mb={4}
            />
            <IconButton
              isRound
              colorScheme="red"
              variant="ghost"
              aria-label="remove instructon"
              icon={<MinusIcon />}
              type="button"
              ml={4}
              onClick={() => remove(index)}
            />
          </>
        ))}
        <Button
          isRound
          aria-label="add instruction"
          leftIcon={<AddIcon />}
          onClick={() => append({})}
          type="button">
          {t("add_instruction")}
        </Button>

        <div>
          <p style={tagStyle}>{t("exercise_files")}</p>
          <FileUploader files={files} setFiles={setFiles} />
        </div>

        <Flex my={4} gridGap={4} justifyContent="end">
          <Button variant="ghost" colorScheme="red" onClick={handleDeleteOpen}>
            Delete exercise
          </Button>
          <Button type="submit" colorScheme="ptRed">
            {t("save")}
          </Button>
        </Flex>
      </form>
      {deleteOpen && (
        <DeleteAlert
          open={deleteOpen}
          close={handleDeleteClose}
          id={exercise._id}
          title={t("delete_exercise")}
          func={() => useDelete("exercises", deleteExercise)}
        />
      )}
    </>
  );
};

Edit.propTypes = {
  exercise: propTypes.shape({
    _id: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    muscle_system: propTypes.shape([]).isRequired,
    images: propTypes.shape([]).isRequired,
    equipment: propTypes.shape([]).isRequired,
    category_id: propTypes.shape([]).isRequired,
    action: propTypes.shape([]).isRequired,
    abbrevations: propTypes.shape([]).isRequired,
    instructions: propTypes.shape([]).isRequired,
    weakness: propTypes.number.isRequired,
  }).isRequired,
};

export default Edit;
