import React from "react";
import { Controller } from "react-hook-form";
import { Input, Textarea } from "@chakra-ui/react";
import propTypes from "prop-types";

import { inputStyle } from "theme/commonStyles";

import CustomInput from "../main/CustomInput";

const TextInput = ({ control, def, name, place, required, type, rules }) => (
  <Controller
    control={control}
    defaultValue={def}
    name={name}
    rules={rules}
    render={({ field }) => (
      <CustomInput label={place} required={required}>
        {type === "text" ? (
          <Input variant="filled" placeholder={place} {...inputStyle} {...field} />
        ) : (
          <Textarea placeholder={place} {...inputStyle} {...field} />
        )}
      </CustomInput>
    )}
  />
);

TextInput.defaultProps = {
  type: "text",
  required: true,
  def: "",
  rules: null,
};

TextInput.propTypes = {
  control: propTypes.func.isRequired,
  name: propTypes.string.isRequired,
  place: propTypes.string.isRequired,
  def: propTypes.string,
  required: propTypes.bool,
  type: propTypes.string,
  rules: propTypes.shape({}),
};

export default TextInput;
