/* eslint-disable no-unused-vars */
import React from 'react';
import { Button, HStack, IconButton } from '@chakra-ui/react';
import { ArrowBackIcon } from '@chakra-ui/icons';
import propTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ReturnBtn = ({ setStep, initialNumber, mode }) => {
  const { t } = useTranslation();
  return (
    <HStack w="90%" justify="flex-end" mb="0.5rem" position="sticky" top="10" zIndex="100">
      {
        mode === 'help'
          ? (
            <Button
              colorScheme="red"
              leftIcon={<ArrowBackIcon />}
              as={Link}
              to="/help"
              variant="link"
            >
              {t('return')}
            </Button>
          )
          : (
            <IconButton
              colorScheme="red"
              aria-label="return button"
              icon={<ArrowBackIcon />}
              onClick={() => {
                setStep(initialNumber);
              }}
            />
          )
      }
    </HStack>
  );
};

ReturnBtn.defaultProps = {
  setStep: null,
  initialNumber: 1,
  mode: 'normal',
};

ReturnBtn.propTypes = {
  setStep: propTypes.func,
  initialNumber: propTypes.number,
  mode: propTypes.string,
};

export default ReturnBtn;
