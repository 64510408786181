/* eslint-disable max-len */
/* eslint-disable consistent-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Flex,
} from "@chakra-ui/react";
import ProgramName from "components/create/Features/ProgramName";
import { loaded, loading } from "reducers/loader.reducer";
import Select from "react-select";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import notificationDisplay from "utils/notificationDisplay";
import { useTranslation } from "react-i18next";
import Selector from "components/create/Features/Selector";
import {
  bodyPartOptions,
  categoriesOptions,
  equipmentOptions,
} from "components/create/Features/options";
import { createPreprogrammed } from "services/preProgrammed";

const ModalCreatePreprogram = ({ isOpen, onClose, createPreprogram }) => {
  const [name, setName] = useState({ en: "", es: "" });
  const { handleSubmit, control } = useForm({ mode: "onChange" });
  const { id_user } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [filterOptions, setFilterOptions] = useState([[], [], []]);
  const handleChange = (data, actionMeta) => {
    const dataMapped = data.map((d) => d.value);
    if (actionMeta.name === "category")
      setFilterOptions((prev) => prev.map((item, id) => (id === 0 ? dataMapped : item)));
    if (actionMeta.name === "bodyParts")
      setFilterOptions((prev) => prev.map((item, id) => (id === 1 ? dataMapped : item)));
    if (actionMeta.name === "equipment")
      setFilterOptions((prev) => prev.map((item, id) => (id === 2 ? dataMapped : item)));
  };

  const handleSave = async () => {
    if (!name.en) return notificationDisplay(dispatch, t("en_name_required"), "error");
    if (!name.es) return notificationDisplay(dispatch, t("es_name_required"), "error");
    dispatch(loading());
    const dataToSend = {
      workout_name: name,
      sessions: createPreprogram,
      trainer: id_user,
      filterOptions,
      preProgrammed: true,
      date: null,
    };

    let response = "";
    try {
      response = await createPreprogrammed(dataToSend);
      if (response.data) throw new Error(t("error_save"));

      notificationDisplay(dispatch, t("data_creation"), "success");
      onClose();
    } catch (error) {
      notificationDisplay(dispatch, error.message, "error");
    } finally {
      dispatch(loaded());
    }
  };

  return (
    <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("create_new_program_preprogrammed")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box w={["100%", "50%"]}>
            <ProgramName enName={name.en} esName={name.es} setName={setName} />
            <Flex flexDirection="column" gridGap="1rem" mt="1rem">
              <Controller
                name="category"
                control={control}
                render={({ field }) => (
                  <Select
                    isMulti
                    name="category"
                    options={categoriesOptions.map((item) => ({
                      value: item.value,
                      label: t(item.label),
                    }))}
                    colorScheme="red"
                    placeholder={t("select_category")}
                    variant="filled"
                    classNamePrefix="select"
                    className="basic-multi-select"
                    onChange={handleChange}
                  />
                )}
              />
              <Controller
                name="bodyParts"
                control={control}
                render={({ field }) => (
                  <Select
                    isMulti
                    name="bodyParts"
                    options={bodyPartOptions.map((item) => ({
                      value: item.value,
                      label: t(item.label),
                    }))}
                    colorScheme="red"
                    placeholder={t("select_bodypart")}
                    variant="filled"
                    classNamePrefix="select"
                    className="basic-multi-select"
                    onChange={handleChange}
                  />
                )}
              />
              <Controller
                name="equipment"
                control={control}
                render={({ field }) => (
                  <Select
                    isMulti
                    name="equipment"
                    options={equipmentOptions.map((item) => ({
                      value: item.value,
                      label: t(item.label),
                    }))}
                    colorScheme="red"
                    placeholder={t("select_implements")}
                    variant="filled"
                    classNamePrefix="select"
                    className="basic-multi-select"
                    onChange={handleChange}
                  />
                )}
              />
            </Flex>
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => handleSave()} colorScheme="ptRed">
            {t("save_routine")}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default ModalCreatePreprogram;
