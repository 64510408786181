/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getToDoByUser, saveToDo, updateToDo, deleteToDo } from "services/todo";
import { Input, InputGroup, InputRightElement, Button } from "@chakra-ui/react";
import AddIcon from "@mui/icons-material/Add";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import useLang from "hooks/useLang";
import DoneIcon from "@mui/icons-material/Done";
import styles from "./Dashboard.module.scss";
import todoIcon from "../../assets/icons/ico-todolist.svg";

const TodoList = () => {
  const [tasks, setTasks] = useState([]);
  const [task, setTask] = useState("");
  const [updateTask, setUpdateTask] = useState("");
  const [idUpdate, setIdUpdate] = useState(null);
  const [loadingSaveTask, setLoadingSaveTask] = useState(false);
  const { id_user } = useSelector(({ user }) => user);
  const { t } = useLang();
  const addTask = async () => {
    try {
      setLoadingSaveTask(true);
      const response = await saveToDo({
        name: task,
        id_user,
      });
      if (response.data) throw new Error(response.data.error);
      setTasks([response.todo, ...tasks]);
      setTask("");
      setLoadingSaveTask(false);
    } catch (error) {
      setLoadingSaveTask(false);
      console.log(error);
    }
  };
  const checkTask = async (id, value) => {
    try {
      const response = await updateToDo(id, {
        cheked: value,
      });
      if (response.data) throw new Error(response.data.error);
      const allTasks = tasks;
      const index = allTasks.findIndex((a) => a._id === id);
      allTasks[index] = response.todo;
      console.log(index, allTasks);
      setTasks([...allTasks]);
    } catch (error) {
      console.log(error);
    }
  };
  const changeTask = async (id) => {
    try {
      const response = await updateToDo(id, {
        name: updateTask,
      });
      if (response.data) throw new Error(response.data.error);
      const allTasks = tasks;
      const index = allTasks.findIndex((a) => a._id === id);
      allTasks[index] = response.todo;
      console.log(index, allTasks);
      setTasks([...allTasks]);
      setIdUpdate(null);
      setUpdateTask("");
    } catch (error) {
      console.log(error);
    }
  };
  const deleteTask = async (id) => {
    try {
      const response = await deleteToDo(id);
      if (response.data) throw new Error(response.data.error);
      const allTasks = tasks;
      const index = allTasks.findIndex((a) => a._id === id);
      allTasks.splice(index, 1);
      console.log(index, allTasks);
      setTasks([...allTasks]);
    } catch (error) {
      console.log(error);
    }
  };
  const getTasks = async () => {
    try {
      const response = await getToDoByUser(id_user);
      if (response.data) throw new Error(response.data.error);
      setTasks(response);
    } catch (error) {
      console.log(error);
    }
  };
  const handleClick = () => {
    addTask(task);
  };
  useEffect(() => {
    getTasks();
  }, []);
  return (
    <div className={styles.todo}>
      <div className={styles.header}>
        <img src={todoIcon} alt="icon" />
        <p>{t("todoList")}</p>
      </div>
      <div>
        <InputGroup size="md">
          <Input
            variant="filled"
            placeholder={t("addTask")}
            name="task_name"
            value={task}
            onChange={(e) => setTask(e.target.value)}
          />
          <InputRightElement width="4.5rem">
            <Button
              disabled={task === ""}
              isLoading={loadingSaveTask}
              size="sm"
              variant="ghost"
              onClick={handleClick}>
              <AddIcon />
            </Button>
          </InputRightElement>
        </InputGroup>
      </div>
      {tasks.map((item) => (
        <div className={`${styles.task} ${item.cheked ? styles.done : null}`} key={item._id}>
          <div style={{ width: "85%" }}>
            {!item.cheked && idUpdate && idUpdate === item._id ? (
              <InputGroup size="md">
                <Input
                  variant="filled"
                  placeholder="Add task"
                  name="task_name"
                  value={updateTask}
                  onChange={(e) => setUpdateTask(e.target.value)}
                />
                <InputRightElement width="4.5rem">
                  <Button
                    disabled={updateTask === ""}
                    isLoading={loadingSaveTask}
                    size="sm"
                    variant="ghost"
                    onClick={() => changeTask(item._id)}>
                    <DoneIcon />
                  </Button>
                </InputRightElement>
              </InputGroup>
            ) : (
              <div className={styles.name}>
                <p>{item.name}</p>
              </div>
            )}
          </div>

          <div className={styles.options}>
            {!item.cheked && !idUpdate && idUpdate !== item._id && (
              <EditIcon
                className={styles.icon}
                onClick={() => {
                  setUpdateTask(item.name);
                  setIdUpdate(item._id);
                }}
              />
            )}
            <DeleteIcon className={styles.deleteIcon} onClick={() => deleteTask(item._id)} />
            {!item.cheked ? (
              <div
                onClick={() => checkTask(item._id, true)}
                style={{ color: "#ccc", cursor: "pointer" }}>
                <RadioButtonUncheckedIcon />
              </div>
            ) : (
              <div
                onClick={() => checkTask(item._id, false)}
                style={{ color: "#6bca21", cursor: "pointer" }}>
                <CheckCircleIcon />
              </div>
            )}
          </div>
        </div>
      ))}

      {/* <div className={`${styles.task}`}>
        <div className={styles.name}>Name of Task 4</div>
        <div className={`${styles.date} ${styles.warn}`}>Oct 20, 2023</div>
      </div>
      <div className={`${styles.task} ${styles.done}`}>
        <div className={styles.name}>Name of Task 4</div>
      </div> */}
    </div>
  );
};
export default TodoList;
