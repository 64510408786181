/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import useLang from "hooks/useLang";
import Board from "./components/Board";

const AlternativeBoard = ({
  children,
  preInfo,
  workout,
  clientInfo,
  routineId,
  nameWorkout,
  sharedFilter,
  updateSharedFilter,
  setShowRenderPdf,
  showRenderPdf,
  onOpenCreate,
  handleUpdateDnd,
  setCreatePreprogram,
  setcopyAlertData,
  setProgramData,
  allDataCurrentProgram,
  verifyBetween,
  setVerifyBetween,
}) => {
  const [filtered, setFiltered] = useState([]);
  const { t } = useLang();

  useEffect(() => {
    const daysField = preInfo?.map((i) => ({
      ...i,
      id: i._id,
      author: {
        id: i._id,
        name: i.week,
        colors: {
          soft: "white",
          hard: "white",
        },
      },
    }));
    const FormatDays = [...daysField];
    const array = FormatDays?.map(({ week }) => week);
    const newarray = [...new Set(array)];
    const dataFilter = newarray?.map((item) =>
      daysField?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );
    setFiltered(dataFilter);
  }, [preInfo]);

  useEffect(() => {
    const sidebar = document.getElementsByClassName("sidebar")[0];
    window.onbeforeunload = (e) => {
      console.log(e);
      return "Leaving this page will reset the wizard";
    };
    window.history.pushState(null, document.title, window.location.href);
    window.onpopstate = () => {
      const leavePage = window.confirm(t("back_confirmation"));
      if (leavePage) {
        window.onpopstate = null;
        window.history.back();
      } else {
        window.history.pushState(null, document.title, window.location.href);
      }
    };
    if (sidebar) sidebar.setAttribute("class", "sidebar collapse");
    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
      if (sidebar) sidebar.setAttribute("class", "sidebar");
    };
  }, []);

  return (
    <div id="board">
      <Board
        initial={filtered}
        withScrollableColumns
        workout={workout}
        setVerifyBetween={setVerifyBetween}
        setFiltered={setFiltered}
        routineId={routineId}
        clientInfo={clientInfo}
        nameWorkout={nameWorkout}
        originalData={preInfo}
        sharedFilter={sharedFilter}
        verifyBetween={verifyBetween}
        updateSharedFilter={updateSharedFilter}
        onOpenCreate={onOpenCreate}
        setCreatePreprogram={setCreatePreprogram}
        setShowRenderPdf={setShowRenderPdf}
        showRenderPdf={showRenderPdf}
        handleUpdateDnd={handleUpdateDnd}
        setcopyAlertData={setcopyAlertData}
        setProgramData={setProgramData}
        allDataCurrentProgram={allDataCurrentProgram}>
        {children}
      </Board>
    </div>
  );
};

AlternativeBoard.propTypes = {};

export default AlternativeBoard;
