/* eslint-disable no-unused-vars */
import React from "react";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  Button,
} from "@chakra-ui/react";
import propTypes from "prop-types";
import { useMutation, useQueryClient } from "react-query";
import { deleteExercise } from "services/exercise";
import { useTranslation } from "react-i18next";

const infoStyle = {
  padding: "2rem 0",
  textAlign: "center",
};

const DeleteAlert = ({ open, close, id }) => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();
  const { mutate, isLoading, isSuccess, isError } = useMutation(
    (idExercise) => deleteExercise(idExercise),
    {
      onMutate: async (updatedData) => {
        await queryClient.cancelMutations("exercises");

        const previous = queryClient.getQueryData("exercises");
        // queryClient.setQueryData('exercises', (old) => [...old, updatedData]);
        return { previous };
      },
      onError: (context) => {
        queryClient.setQueryData("exercises", context.previous);
      },
      onSettled: () => {
        queryClient.invalidateQueries("exercises");
        // reset();
        // close();
      },
    },
  );
  const onDelete = () => mutate(id);
  return (
    <AlertDialog isOpen={open} onClose={close}>
      <AlertDialogOverlay>
        <AlertDialogContent>
          <AlertDialogHeader>{t("delete_exercise")}</AlertDialogHeader>
          {isLoading && <p style={infoStyle}>Deleting Exercise</p>}
          {isError && <p style={infoStyle}>Something went wrong</p>}
          {isSuccess && (
            <>
              <p style={infoStyle}>Exercise was deleted succesfully</p>
              <Button onClick={close}>{t("cancel")}</Button>
            </>
          )}
          {!isLoading && !isSuccess && (
            <>
              <AlertDialogBody>{t("delete_exercise_confirmation")}</AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={close}>{t("cancel")}</Button>
                <Button colorScheme="red" onClick={onDelete} ml={3}>
                  {t("delete")}
                </Button>
              </AlertDialogFooter>
            </>
          )}
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

DeleteAlert.propTypes = {
  open: propTypes.bool.isRequired,
  close: propTypes.func.isRequired,
  id: propTypes.string.isRequired,
};

export default DeleteAlert;
