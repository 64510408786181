import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  VStack,
  Avatar,
  Text,
} from "@chakra-ui/react";

import propTypes from "prop-types";
import { useTranslation } from "react-i18next";

import Toggle from "components/common/Toggle";

const tagStyle = {
  display: "block",
  color: "#d9ad42",
  fontWeight: 700,
  width: "100%",
};
const alignCenter = {
  textAlign: "center",
};

const Popup = ({ isOpen, onClose, info }) => {
  const { t } = useTranslation();
  return (
    <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{info.name}</ModalHeader>
        <ModalCloseButton style={{ color: "#ececec" }} />
        <ModalBody style={alignCenter}>
          <VStack>
            <Avatar src={info.pic} size="md" />
            <Text>
              <span style={tagStyle}>Speciality</span>
              {info.speciality}
            </Text>
            <Text>
              <span style={tagStyle}>{t("job_description")}</span>
              {info.job_description}
            </Text>
            <Toggle component={<Text>{`${info.name}' Direction`}</Text>}>
              <Text>
                <span style={tagStyle}>{t("phone")}</span>
                {info.phone}
              </Text>
              <Text>
                <span style={tagStyle}>{t("city")}</span>
                {info.address.city}
              </Text>
              <Text>
                <span style={tagStyle}>{t("state")}</span>
                {info.address.state}
              </Text>
              <Text>
                <span style={tagStyle}>{t("zip_code")}</span>
                {info.address.zip}
              </Text>
            </Toggle>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

Popup.propTypes = {
  onClose: propTypes.func.isRequired,
  isOpen: propTypes.bool.isRequired,
  info: propTypes.shape({
    name: propTypes.string.isRequired,
    pic: propTypes.string.isRequired,
    speciality: propTypes.string.isRequired,
    job_description: propTypes.string.isRequired,
    phone: propTypes.string.isRequired,
    address: propTypes.shape({
      city: propTypes.string.isRequired,
      state: propTypes.string.isRequired,
      zip: propTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default Popup;
