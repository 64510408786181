/* eslint-disable prefer-const */
import React from "react";
import { Box, Flex, Button, Checkbox, Text } from "@chakra-ui/react";
import useLang from "hooks/useLang";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { loading, loaded } from "reducers/loader.reducer";
import { addInfo } from "reducers/user.reducer";
import { CloseIcon } from "@chakra-ui/icons";
import { func, bool } from "prop-types";
import { updateTrainer } from "services/trainer";
import { transformEditProfileData } from "utils/transformData";
import styles from "./Profile.module.scss";

const ShowFields = ({ setShowFieldPdf, showFieldPdf }) => {
  const { t } = useLang();
  const { control, handleSubmit } = useForm();
  const dispatch = useDispatch();
  const trainer = useSelector((state) => state.trainer);
  const user = useSelector((state) => state.user);

  const onSubmit = async (data) => {
    dispatch(loading());
    let userData = { ...user };
    delete userData.showField;
    delete userData.address;

    const newData = {
      ...userData,
      state: trainer.address.state,
      city: trainer.address.city,
      zip: trainer.address.zip,
      street1: trainer.address.street1,
      street2: trainer.address.street2,
      show_address: data.show_address,
      show_phone: data.show_phone,
      show_job_description: data.show_job_description,
      show_certifications: data.show_certifications,
      show_speciality: data.show_speciality,
      show_pic: data?.show_pic,
      show_logo: data?.show_logo,
    };

    try {
      const response = await updateTrainer(
        trainer._id,
        transformEditProfileData(newData, user.name, user.pic, user.logo),
      );
      if (response.data) throw new Error(response.data.error);
      dispatch(addInfo({ ...user, ...response.trainer }));
      dispatch(loaded());
      setShowFieldPdf(!showFieldPdf);
    } catch (error) {
      dispatch(loaded());
    }
    dispatch(loaded());
  };
  return (
    <div className={styles.infoContainer}>
      <div className={styles.card}>
        <Box mb={4}>
          <Text fontSize="2xl">{t("header_custom_pdf")}</Text>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div>
            <p>{t("certifications")}</p>
            <Controller
              control={control}
              defaultValue={user?.showField?.certifications}
              name="show_certifications"
              render={({ field }) => (
                <Box mb={2}>
                  <Checkbox
                    defaultChecked={user?.showField?.certifications}
                    colorScheme="red"
                    {...field}>
                    <Text fontSize="12px">{t("show_field_pdf")}</Text>
                  </Checkbox>
                </Box>
              )}
            />
          </div>
          <div>
            <p>{t("job_description")}</p>
            <Controller
              control={control}
              defaultValue={user?.showField?.job_description}
              name="show_job_description"
              render={({ field }) => (
                <Box mb={2}>
                  <Checkbox
                    defaultChecked={user?.showField?.job_description}
                    colorScheme="red"
                    {...field}>
                    <Text fontSize="12px">{t("show_field_pdf")}</Text>
                  </Checkbox>
                </Box>
              )}
            />
          </div>
          <div>
            <p>{t("phonenumber")}</p>
            <Controller
              control={control}
              defaultValue={user?.showField?.phone}
              name="show_phone"
              render={({ field }) => (
                <Box mb={2}>
                  <Checkbox defaultChecked={user?.showField?.phone} colorScheme="red" {...field}>
                    <Text fontSize="12px">{t("show_field_pdf")}</Text>
                  </Checkbox>
                </Box>
              )}
            />
          </div>
          <div>
            <p>{t("profile_address")}</p>
            <Controller
              control={control}
              defaultValue={user?.showField?.address}
              name="show_address"
              render={({ field }) => (
                <Box mb={2}>
                  <Checkbox defaultChecked={user?.showField?.address} colorScheme="red" {...field}>
                    <Text fontSize="12px">{t("show_field_pdf")}</Text>
                  </Checkbox>
                </Box>
              )}
            />
          </div>
          <div>
            <p>{t("speciality")}</p>
            <Controller
              control={control}
              defaultValue={user?.showField?.speciality}
              name="show_speciality"
              render={({ field }) => (
                <Box mb={2}>
                  <Checkbox
                    defaultChecked={user?.showField?.speciality}
                    colorScheme="red"
                    {...field}>
                    <Text fontSize="12px">{t("show_field_pdf")}</Text>
                  </Checkbox>
                </Box>
              )}
            />
          </div>
          <div>
            <p>{t("picture_profile")}</p>
            <Controller
              control={control}
              defaultValue={user?.showField?.pic}
              name="show_pic"
              render={({ field }) => (
                <Box mb={2}>
                  <Checkbox
                    defaultChecked={user?.showField?.pic}
                    colorScheme="red"
                    {...field}>
                    <Text fontSize="12px">{t("show_field_pdf")}</Text>
                  </Checkbox>
                </Box>
              )}
            />
          </div>
          <div>
            <p>{t("picture_logo")}</p>
            <Controller
              control={control}
              defaultValue={user?.showField?.logo}
              name="show_logo"
              render={({ field }) => (
                <Box mb={2}>
                  <Checkbox
                    defaultChecked={user?.showField?.logo}
                    colorScheme="red"
                    {...field}>
                    <Text fontSize="12px">{t("show_field_pdf")}</Text>
                  </Checkbox>
                </Box>
              )}
            />
          </div>
          <Flex gridGap={4} mt={8}>
            <Button variant="outline" onClick={() => setShowFieldPdf(!showFieldPdf)}>
              {t("cancel")}
            </Button>
            <Button colorScheme="ptRed" type="submit">
              {t("save_changes")}
            </Button>
          </Flex>
        </form>
      </div>
    </div>
  );
};

ShowFields.propTypes = {
  setShowFieldPdf: func.isRequired,
  showFieldPdf: bool.isRequired,
};

export default ShowFields;
