const Button = {
  variants: {
    outline: {},
    solid: {
      _hover: {
        opacity: ".8",
      },
    },
  },
};

export default Button;
