/* eslint-disable react/jsx-filename-extension */
import React, { Suspense } from "react";
import ReactDOM from "react-dom";

import "./index.css";
import { Provider } from "react-redux";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { BrowserRouter as Router } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import { GoogleOAuthProvider } from "@react-oauth/google";

import ErrorBoundary from "components/common/ErrorBoundary";
import ProvideAuth from "./utils/authContext";
import "./translator";

import App from "./App";
import store from "./store";
import theme from "./theme/chakraTheme";
import "flag-icon-css/css/flag-icon.min.css";
import Loader from "./components/common/Loader";

const queryClient = new QueryClient();

ReactDOM.render(
  <Suspense fallback={Loader}>
    <React.StrictMode>
      <GoogleOAuthProvider clientId="63126014045-cov6re06h38audhm3svsuv3d8vuu1p68.apps.googleusercontent.com">
        <ChakraProvider theme={theme}>
          <Provider store={store}>
            <QueryClientProvider client={queryClient}>
              <ProvideAuth>
                <Router>
                  <ErrorBoundary>
                    <App />
                  </ErrorBoundary>
                  {/* <ReactQueryDevtools /> */}
                </Router>
              </ProvideAuth>
            </QueryClientProvider>
          </Provider>
        </ChakraProvider>
      </GoogleOAuthProvider>
    </React.StrictMode>
  </Suspense>,
  document.getElementById("root"),
);
