/* eslint-disable import/prefer-default-export */
import instance from "./_axiosConfig";

const api = instance;
const getSharedEvent = async (id) => {
  try {
    const response = await api.get(`/events/shared?client=${id}`);
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export { getSharedEvent };
