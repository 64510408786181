/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Badge,
  Box,
  Button,
  Flex,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { CloseIcon } from "@chakra-ui/icons";
import { getTemplatesCombined } from "services/template";
import { useSelector } from "react-redux";
import LoadingProgramList from "components/routines/dnd/AlternativeBoard/components/Combine/LoadingProgramList";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import useLang from "hooks/useLang";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import generateId from "helpers/generateId";
import StylesPrograms from "../MorePrograms/Combine.module.css";
import ProgramList from "../MorePrograms/ProgramList";

const MoreTemplates = ({
  onClose,
  isOpen,
  days,
  setFiltered,
  columns,
  lastday,
  currentWeek,
  currentClient,
}) => {
  const { id_user } = useSelector(({ user }) => user);
  const { lang, t } = useLang();
  const [data, setData] = useState(null);
  const [programData, setProgramData] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [loadingData, setloadingData] = useState(false);
  const [activeButton, setactiveButton] = useState("");
  const [completed, setCompleted] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [typeList, setTypeList] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [nPages, setNPages] = useState(0);
  const [nameWorkOut, setnameWorkOut] = useState({ es: "", en: "" });

  const handleGetTemplate = async () => {
    setactiveButton("template");
    setloadingData(true);
    const resopnse = await getTemplatesCombined({ lang, user: id_user, page: 0, itemsPerPage: 10 });
    const programs = resopnse.templates;
    setloadingData(false);
    setData(programs);
    setTypeList("template");
    setNPages(resopnse.lastPage !== 0 ? resopnse.lastPage : 1);
    setCurrentPage(resopnse.page);
  };
  const getProgram = async (page) => {
    const resopnse = await getTemplatesCombined({
      name: searchValue,
      lang,
      user: id_user,
      page,
      itemsPerPage: 10,
    });
    const programs = resopnse.templates;
    setData(programs);
    setNPages(resopnse.lastPage !== 0 ? resopnse.lastPage : 1);
    setCurrentPage(resopnse.page);
    document.getElementById("step-2").scroll({ top: 0, behavior: "smooth" });
  };
  const goToNextPage = () => {
    if (currentPage !== nPages) getProgram(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 0) getProgram(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 0) getProgram(0);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) getProgram(nPages - 1);
  };
  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (typeList === "template") getProgram();
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [searchValue]);

  const handleClear = () => {
    setProgramData([]);
    setData(null);
    setWeeks([]);
    setCompleted([]);
    onClose();
    setactiveButton("");
  };

  const handleClickProgram = (program) => {
    const FormatDays = [...program.sessions];
    const array = FormatDays?.map(({ week }) => week);
    const newarray = [...new Set(array)];

    const dataFilter = newarray?.map((item) =>
      program.sessions?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );
    setProgramData(dataFilter);
    setnameWorkOut({
      en: program?.workout_name?.en || "",
      es: program?.workout_name?.es || "",
    });
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Move day in the list source to
    if (result.source.droppableId === "tasks" && result.destination.droppableId === "completed") {
      const arrayOfObjects = programData.flat();
      const removed = arrayOfObjects.find(({ _id }) => _id === sourceIndex);
      setProgramData([...programData]);
      setCompleted([
        ...completed,
        {
          ...removed,
          _id: generateId(),
        },
      ]);
    }

    // Move the list completo to tasks again
    // if (
    //   result.source.droppableId === 'completed'
    //   && result.destination.droppableId === 'completed'
    // ) {
    //   const resultInitial = Array.from([...completed[result.source.droppableId]]);
    //   const [removed] = resultInitial.splice(sourceIndex, 1);
    //   resultInitial.splice(destinationIndex, 0, removed);
    //   const resultArr = {
    //     ...completed,
    //     [result.source.droppableId]: resultInitial,
    //   };
    //   console.log('take the day', resultArr);
    // }
  };

  const handleSave = () => {
    const ReasginedWeeks = [...weeks, [...completed]];
    const order = ReasginedWeeks.map((arr, i) => {
      const arrMap = arr.map((obj, j) => {
        const subArrMap = {
          ...obj,
          day: 1 + j,
          week: 1 + i,
          _id: generateId(),
          categoryList: obj.categoryList.map((c) => {
            const setIdExercise = {
              ...c,
              action: {
                name: c.action.name,
                id: c.action._id
              },
              category: {
                name: c.category.name,
                id: c.category._id
              },
              muscle: {
                name: c.muscle.name,
                id: c.muscle._id
              },
              _id: generateId(),
              sets: c.sets.map((e) => {
                const newId = {
                  ...e,
                  _id: generateId(),
                };
                return newId;
              }),
            };
            return setIdExercise;
          }),
        };
        return subArrMap;
      });
      return arrMap;
    });
    setWeeks(order);
    setCompleted([]);
    setProgramData([]);
  };

  const handleSaveAll = () => {
    if (days.length === 0) {
      const order = weeks.map((arr, i) => {
        const arrMap = arr.map((obj) => {
          const subArrMap = {
            ...obj,
            id: generateId(),
            author: {
              id: generateId(),
              name: obj.week,
              colors: {
                soft: "white",
                hard: "white",
              },
            },
            week: obj.week,
            date: null,
          };
          return subArrMap;
        });
        return arrMap;
      });
      setFiltered(order);
    } else {
      const lastWeek = [...days].pop();
      const IncWeek = 1 + lastWeek.week;
      const ReasginedWeeks = [...weeks];
      const order = ReasginedWeeks.map((arr, i) => {
        const arrMap = arr.map((obj) => {
          const subArrMap = {
            ...obj,
            id: generateId(),
            author: {
              id: generateId(),
              name: IncWeek + i,
              colors: {
                soft: "white",
                hard: "white",
              },
            },
            week: IncWeek + i,
            date: null,
          };
          return subArrMap;
        });
        return arrMap;
      });
      const arrayOfObjects = order.reduce((acc, val) => acc.concat(val), []);
      const JoinData = days.concat(arrayOfObjects);
      const FormatDays = [...JoinData];
      const array = FormatDays?.map(({ week }) => week);
      const newarray = [...new Set(array)];
      const dataFilter = newarray?.map((item) =>
        JoinData?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
      );
      const orderBoard = dataFilter.map((arr, i) =>
        arr.map((obj, j) => ({ ...obj, day: 1 + j, week: 1 + i })),
      );
      setFiltered(orderBoard);
    }
    setWeeks([]);
    setCompleted([]);
    setProgramData([]);
    setData(null);
    onClose();
  };

  const handleRemoveDaySelected = (i) => {
    setCompleted((prev) => prev.filter((element, index) => index !== i));
  };

  const handleRemoveDayConfirmed = (currentday) => {
    const arrayOfObjects = weeks.reduce((acc, val) => acc.concat(val), []);
    const removeWeek = arrayOfObjects.filter((element) => element !== currentday);
    const FormatDays = [...removeWeek];
    const array = FormatDays?.map(({ week }) => week);
    const newarray = [...new Set(array)];
    const dataFilter = newarray?.map((item) =>
      removeWeek?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );
    const orderBoard = dataFilter.map((arr, i) =>
      arr.map((obj, j) => ({ ...obj, day: 1 + j, week: 1 + i })),
    );
    setWeeks(orderBoard);
  };

  

  const handleRemoveWeekConfirmed = (current) => {
    const removeDay = weeks.filter((element) => element !== current);
    const sortDays = removeDay.map((obj, j) => ({
      ...obj,
      day: 1 + j,
      week: currentWeek,
      _id: generateId(),
      categoryList: obj.categoryList.map((i) => ({
        ...i,
        _id: generateId(),
        sets: i.sets.map((e) => ({
          ...e,
          _id: generateId(),
        })),
      })),
    }));
    setWeeks(sortDays);
  };

  return (
    <Modal
      onClose={() => {
        handleClear();
      }}
      size="6xl"
      closeOnOverlayClick={false}
      isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent height="700px">
        <ModalBody p={0}>
          <Box>
            <Box className={StylesPrograms.info} m={0} p={0}>
              <Box
                bg="#eeeeee"
                minHeight="700px"
                width="600px"
                className={StylesPrograms.list}
                p={3}
                overflowY="auto">
                <div className={StylesPrograms.input_icon}>
                  <SearchIcon className={StylesPrograms.icon_search} />
                  <Input
                    value={searchValue}
                    onChange={({ target }) => setSearchValue(target.value)}
                    variant="flushed"
                    placeholder={t("search")}
                    borderRadius="20px"
                    pl={12}
                    border="1px solid gray"
                    backgroundColor="white"
                  />
                </div>
                {loadingData ? (
                  <LoadingProgramList />
                ) : (
                  <>
                    {data?.length <= 0 ? (
                      <p>{t("client_no_have_program")}</p>
                    ) : (
                      <>
                        {data
                          ?.filter(
                            (program) =>
                              new RegExp(searchValue, "gi").test(program.workout_name.en) ||
                              new RegExp(searchValue, "gi").test(program.workout_name.es),
                          )
                          ?.sort((a, b) => new Date(b.created_at) - new Date(a.created_at))
                          ?.map((program) => (
                            <ProgramList
                              name={program.workout_name}
                              sub={program.source}
                              phase={program.phase || "1"}
                              createdDate={dayjs(program.created_at).format(
                                lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                              )}
                              handleClick={() => handleClickProgram(program)}
                              isActive={programData.workout_name?.en === program.workout_name.en}
                              key={program._id}
                            />
                          ))}
                        {typeList === "template" ? (
                          <nav>
                            <ul className="pagination">
                              <li>
                                <IconButton
                                  isRound
                                  variant="ghost"
                                  colorScheme="blackAlpha"
                                  aria-label="Done"
                                  fontSize="20px"
                                  disabled={currentPage === 0}
                                  icon={<FirstPageIcon />}
                                  onClick={goToFirstPage}
                                />
                              </li>
                              <li>
                                <IconButton
                                  isRound
                                  variant="ghost"
                                  colorScheme="blackAlpha"
                                  aria-label="Done"
                                  fontSize="20px"
                                  disabled={currentPage === 0}
                                  icon={<ArrowBackIosIcon />}
                                  onClick={goToPrevPage}
                                />
                              </li>
                              <li>
                                {currentPage + 1}/{nPages}
                              </li>
                              <li>
                                <IconButton
                                  isRound
                                  variant="ghost"
                                  colorScheme="blackAlpha"
                                  aria-label="Done"
                                  fontSize="20px"
                                  disabled={currentPage + 1 === nPages}
                                  icon={<ArrowForwardIosIcon />}
                                  onClick={goToNextPage}
                                />
                              </li>
                              <li>
                                <IconButton
                                  isRound
                                  variant="ghost"
                                  colorScheme="blackAlpha"
                                  aria-label="Done"
                                  fontSize="20px"
                                  disabled={currentPage + 1 === nPages}
                                  icon={<LastPageIcon />}
                                  onClick={goToLastPage}
                                />
                              </li>
                            </ul>
                          </nav>
                        ) : null}
                      </>
                    )}
                  </>
                )}
              </Box>
              <Box p={5} className={StylesPrograms.box}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" flexDirection="row" justifyContent="start">
                    <Text fontWeight="semibold" fontSize="20px">
                      {t("add_more_template")}{" "}
                      <span style={{ color: "#b42519" }}>
                        {currentClient?.split("__")[0]
                          ? `${t("to")} ${currentClient?.split("__")[0]}`
                          : ""}
                      </span>{" "}
                      :
                    </Text>
                  </Box>
                  <Box>
                    <ModalCloseButton />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" my={5}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="start"
                    style={{ gap: "20px" }}>
                    <Button
                      bg={activeButton === "template" && "#b42519"}
                      color={activeButton === "template" && "white"}
                      border="1px solid black"
                      _focus={{
                        bg: "#b42519",
                        border: "1px solid #b42519",
                        color: "white",
                      }}
                      _hover={{
                        bg: "#b42519",
                        border: "1px solid #b42519",
                        color: "white",
                      }}
                      onClick={() => handleGetTemplate()}>
                      {t("from_a_template")}
                    </Button>
                  </Box>
                  <Box mr={5}>
                    {weeks.length >= 1 ? (
                      <Button
                        onClick={() => handleSaveAll()}
                        bg="#6bca21"
                        border="none"
                        color="white"
                        _hover={{ background: "#6bca21", border: "mome" }}>
                        {t("import_template")}
                      </Button>
                    ) : (
                      <Button disabled bg="#eeeeee">
                        {t("import_template")}
                      </Button>
                    )}
                  </Box>
                </Box>
                <Text textTransform="uppercase" color="#b42519" fontWeight="semibold">
                  {!nameWorkOut[lang]
                    ? t("select_routine_word")
                    : nameWorkOut[lang] ?? nameWorkOut.en}
                </Text>
                <Box display="flex" flexDirection="row" overflowY="auto">
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="tasks">
                      {(provided) => (
                        <>
                          <div
                            className="tasks"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              background: "white",
                              width: "300px",
                              height: "500px",
                              padding: "1rem",
                              overflowY: "auto",
                            }}>
                            <h2>{t("list_week")}</h2>
                            {Object.keys(programData).length >= 1 && (
                              <>
                                <Accordion defaultIndex={[0]} allowMultiple>
                                  {programData?.map((task, index) => (
                                    <AccordionItem my={3} bg="white" color="black">
                                      <h2>
                                        <AccordionButton
                                          border="1px solid gray"
                                          borderRadius="10px">
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            color="gray"
                                            fontWeight="semibold">
                                            <Text fontWeight="bold">
                                              {t("list_week")} {index + 1}
                                            </Text>
                                            <Text>{t("week_name")}</Text>
                                          </Box>
                                          <AccordionIcon />
                                        </AccordionButton>
                                      </h2>
                                      <AccordionPanel p={0}>
                                        {task?.map((e) => (
                                          <Draggable key={e._id} draggableId={e._id} index={e._id}>
                                            {(provided) => (
                                              <Box
                                                className="task"
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                padding="1rem"
                                                borderRadius="10px"
                                                border="1px solid lightgray"
                                                bg="#f6f6f6"
                                                mt={2}
                                                mb={2}
                                                key={e._id}>
                                                <Text
                                                  display="inline"
                                                  color="gray"
                                                  fontWeight="bold"
                                                  mb={2}>
                                                  {t("list_day")} {e.day}
                                                </Text>
                                                {e.categoryList?.map((exercose) => (
                                                  <Box key={exercose._id}>
                                                    <Text fontSize="sm">
                                                      {(
                                                        <>
                                                          {exercose.category?.name}
                                                          {exercose.muscle?.name && (
                                                            <Badge>{exercose.muscle.name}</Badge>
                                                          )}
                                                          {exercose.action?.name && (
                                                            <Badge colorScheme="purple">
                                                              {exercose.action.name}
                                                            </Badge>
                                                          )}
                                                        </>
                                                      ) || (
                                                        <span
                                                          style={{
                                                            textTransform: "uppercase",
                                                            color: "red",
                                                          }}>
                                                          {t("undefined_exercise")}
                                                        </span>
                                                      )}
                                                    </Text>
                                                    <Text color="#b42519" fontSize="12px">
                                                      {exercose.sets[0].load !== "" &&
                                                      exercose.sets[0].load !== "0" ? (
                                                        <>
                                                          {exercose.sets.length} Sets x{" "}
                                                          {exercose.sets?.map((s) => (
                                                            <span>
                                                              {s.load}
                                                              {s.typeWeight === "others"
                                                                ? ""
                                                                : s.typeWeight}
                                                              /{s.reps}
                                                              {s?.typeReps ?? ""},{" "}
                                                            </span>
                                                          ))}
                                                        </>
                                                      ) : (
                                                        <>
                                                          {exercose.sets.length} Sets x{" "}
                                                          {exercose.sets[0].reps}
                                                          {exercose.sets[0].typeReps ?? ""}
                                                        </>
                                                      )}
                                                    </Text>
                                                  </Box>
                                                ))}
                                              </Box>
                                            )}
                                          </Draggable>
                                        ))}
                                      </AccordionPanel>
                                    </AccordionItem>
                                  ))}
                                </Accordion>
                              </>
                            )}
                            {provided.placeholder}
                          </div>
                        </>
                      )}
                    </Droppable>
                    <Droppable droppableId="completed">
                      {(provided) => (
                        <div
                          className="completed"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: "#f6f6f6",
                            width: "300px",
                            padding: "1rem",
                            height: "500px",
                            overflowY: "auto",
                          }}>
                          <h2>{t("seleted")}</h2>
                          {completed?.map((task, index) => (
                            <Draggable key={task._id} draggableId={task._id} index={index}>
                              {(provided) => (
                                <Box
                                  className="task"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  padding="1rem"
                                  borderRadius="10px"
                                  border="1px solid lightgray"
                                  bg="white"
                                  mt={2}
                                  mb={2}>
                                  <Box display="flex" justifyContent="space-between">
                                    <Text
                                      display="inline"
                                      color="gray"
                                      fontWeight="semibold"
                                      mb={2}>
                                      {t("list_day")} {task.day}
                                    </Text>
                                    <button
                                      type="button"
                                      className={StylesPrograms.btn_delete_element}
                                      onClick={() => handleRemoveDaySelected(index)}>
                                      <CloseIcon />
                                    </button>
                                  </Box>
                                  {task.categoryList?.map((exercose) => (
                                    <Box key={exercose._id}>
                                      <Text fontSize="sm">
                                        {" "}
                                        <>
                                          {exercose.category?.name}
                                          {exercose.muscle?.name && (
                                            <Badge>{exercose.muscle.name}</Badge>
                                          )}
                                          {exercose.action?.name && (
                                            <Badge colorScheme="purple">
                                              {exercose.action.name}
                                            </Badge>
                                          )}
                                        </>
                                      </Text>
                                      <Text color="#b42519" fontSize="12px">
                                        {exercose.sets[0].load !== "" &&
                                        exercose.sets[0].load !== "0" ? (
                                          <>
                                            {exercose.sets.length} Sets x{" "}
                                            {exercose.sets?.map((s) => (
                                              <span>
                                                {s.load}
                                                {s.typeWeight === "others" ? "" : s.typeWeight}/
                                                {s.reps}
                                                {s?.typeReps ?? ""},{" "}
                                              </span>
                                            ))}
                                          </>
                                        ) : (
                                          <>
                                            {exercose.sets.length} Sets x {exercose.sets[0].reps}
                                            {exercose.sets[0].typeReps ?? ""}
                                          </>
                                        )}
                                      </Text>
                                    </Box>
                                  ))}
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <Box width="300px" height="500px" overflowY="auto">
                    {completed.length >= 1 && (
                      <Button
                        bg="#f07300"
                        borderRadius="8px"
                        color="white"
                        w="full"
                        onClick={handleSave}>
                        {t("confirm_days")}
                      </Button>
                    )}
                    <Accordion defaultIndex={[0]} allowMultiple>
                      {weeks?.map((obj, i) => (
                        <AccordionItem my={3} bg="white" color="black">
                          <h2>
                            <AccordionButton border="1px solid gray" borderRadius="10px" p={3}>
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                color="gray"
                                fontWeight="semibold">
                                <Text
                                  display="flex"
                                  justifyContent="start"
                                  alignItems="center"
                                  style={{ gap: "10px" }}>
                                  <button
                                    type="button"
                                    className={StylesPrograms.btn_delete_element}
                                    onClick={() => handleRemoveWeekConfirmed(obj)}>
                                    <CloseIcon />
                                  </button>
                                  {t("list_week")} {i + 1}
                                </Text>
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel p={0}>
                            {obj.map((e) => (
                              <Box
                                padding="1rem"
                                borderRadius="10px"
                                border="1px solid lightgray"
                                bg="white"
                                mt={2}
                                mb={2}>
                                <Flex justifyContent="space-between">
                                  <Text display="inline" color="gray" fontWeight="semibold">
                                    {t("list_day")} {e.day}
                                  </Text>
                                  <button
                                    type="button"
                                    className={StylesPrograms.btn_delete_element}
                                    onClick={() => handleRemoveDayConfirmed(e)}>
                                    <CloseIcon />
                                  </button>
                                </Flex>

                                {e.categoryList?.map((exercose) => (
                                  <Box key={exercose._id}>
                                    <Text fontSize="sm">
                                      <>
                                        {exercose.category?.name}
                                        {exercose.muscle?.name && (
                                          <Badge>{exercose.muscle.name}</Badge>
                                        )}
                                        {exercose.action?.name && (
                                          <Badge colorScheme="purple">{exercose.action.name}</Badge>
                                        )}
                                      </>
                                    </Text>
                                    <Text color="#b42519" fontSize="12px">
                                      {exercose.sets[0].load !== "" &&
                                      exercose.sets[0].load !== "0" ? (
                                        <>
                                          {exercose.sets.length} Sets x{" "}
                                          {exercose.sets?.map((s) => (
                                            <span>
                                              {s.load}
                                              {s.typeWeight === "others" ? "" : s.typeWeight}/
                                              {s.reps}
                                              {s?.typeReps ?? ""},{" "}
                                            </span>
                                          ))}
                                        </>
                                      ) : (
                                        <>
                                          {exercose.sets.length} Sets x {exercose.sets[0].reps}
                                          {exercose.sets[0].typeReps ?? ""}
                                        </>
                                      )}
                                    </Text>
                                  </Box>
                                ))}
                              </Box>
                            ))}
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MoreTemplates;
