/* eslint-disable react/prop-types */
import React from "react";
import { Box, Button, Input, Text } from "@chakra-ui/react";
import useLang from "hooks/useLang";
import FoodsAddNew from "../../../assets/FoodList/ico cesta.svg";
import styles from "../nutrition.module.scss";
import { foodListAvoid, foodListInclude } from "./FoodData";

const FoodList = ({
  foodAddInclude,
  foodAddAvoid,
  setFoodAddAvoid,
  setFoodAddInclude,
  setFoodAvoid,
  setFoodInclude,
  foodAvoid,
  setNewFood,
  newFood,
  foodInclude,
  newFoodAvoid,
  setNewFoodAvoid,
}) => {
  const { t } = useLang();

  // function for array list the included food
  const handleAddListInclude = (e) => {
    e.preventDefault()
    setFoodAddInclude([...foodAddInclude, newFood]);
    setNewFood("");
    setFoodInclude([...foodInclude, newFood]);
  };

  const handleListFoodInclude = (food) => {
    if (foodInclude.find((e) => e === food)) {
      const removeFood = foodInclude.filter((e) => e !== food);
      setFoodInclude([...removeFood]);
    } else {
      setFoodInclude([...foodInclude, food]);
    }
  };

  const handleRemoveNewFood = (food) => {
    const removeFood = foodInclude.filter((e) => e !== food);
    setFoodInclude([...removeFood]);

    const removeNewFood = foodAddInclude.filter((e) => e !== food);
    setFoodAddInclude([...removeNewFood]);
  };

  // functions for array list the avoid food
  const handleAddListAvoid = (e) => {
    e.preventDefault()
    setFoodAddAvoid([...foodAddAvoid, newFoodAvoid]);
    setNewFoodAvoid("");
    setFoodAvoid([...foodAvoid, newFoodAvoid]);
  };

  const handleListFoodAvoid = (food) => {
    if (foodAvoid.find((e) => e === food)) {
      const removeFood = foodAvoid.filter((e) => e !== food);
      setFoodAvoid([...removeFood]);
    } else {
      setFoodAvoid([...foodAvoid, food]);
    }
  };

  const handleRemoveNewFoodAvoid = (food) => {
    const removeFood = foodAvoid.filter((e) => e !== food);
    console.log(removeFood);
    setFoodAvoid([...removeFood]);

    const removeNewFood = foodAddAvoid.filter((e) => e !== food);
    setFoodAddAvoid([...removeNewFood]);
  };

  return (
    <Box
      bg="white"
      borderRadius="10px"
      display="flex"
      p={5}
      w="full"
      flexDirection="column"
      flexWrap="wrap"
      justifyContent="space-between"
      gridGap={6}>
      <Text fontWeight="bold" fontSize="x-large">
        {t("nutrition_section.meals_per_day")}
      </Text>
      <Box
        display="flex"
        flexDirection="column"
        w="full"
        justifyContent="space-between"
        gridGap={4}>
        <Text fontWeight="bold">{t("foods.include")}</Text>
        <Box display="flex" gridGap={3} flexWrap="wrap">
          {foodListInclude.map(({ item, image, id }) => (
            <Button
              variant="solid"
              key={id}
              bg={foodInclude.find((e) => e === t(item)) ? "#d5f9dd" : "#f6f6f6"}
              color="gray"
              isDisabled={foodAvoid.find((e) => e === t(item))}
              display="flex"
              justifyContent="center"
              gridGap={2}
              onClick={() => handleListFoodInclude(t(item))}
              alignItems="center">
              <img src={image} alt={item} className={styles.image_food} />
              <span>{t(item)}</span>
            </Button>
          ))}
          {foodAddInclude?.map((food) => (
            <Box
              variant="solid"
              key={food}
              bg="#d5f9dd"
              color="gray"
              display="flex"
              borderRadius={5}
              py={2}
              px={3}
              justifyContent="center"
              gridGap={2}
              alignItems="center">
              <img src={FoodsAddNew} alt="beef" className={styles.image_food} />
              <span>{food}</span>
              <Button size="xs" bg="transparent" onClick={() => handleRemoveNewFood(food)}>
                X
              </Button>
            </Box>
          ))}
        </Box>
        <form onSubmit={handleAddListInclude}>
          <Box display="flex" gridGap={3}>
            <Input
              placeholder={t("foods.write_article")}
              border="1px solid black"
              value={newFood}
              onChange={(e) => setNewFood(e.target.value)}
            />
            <Button bg="black" type="submit" color="white" >
              {t("add")}
            </Button>
          </Box>
        </form>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        w="full"
        justifyContent="space-between"
        gridGap={4}>
        <Text fontWeight="semibold">{t("foods.not_include")}</Text>
        <Box display="flex" gridGap={3} flexWrap="wrap">
          {foodListAvoid.map(({ item, image, id }) => (
            <Button
              variant="solid"
              key={id}
              bg={foodAvoid.find((e) => e === t(item)) ? "#f4d9db" : "#f6f6f6"}
              color="gray"
              display="flex"
              onClick={() => handleListFoodAvoid(t(item))}
              isDisabled={foodInclude.find((e) => e === t(item))}
              justifyContent="center"
              gridGap={2}
              alignItems="center">
              <img src={image} alt={item} className={styles.image_food} />
              <span>{t(item)}</span>
            </Button>
          ))}
          {foodAddAvoid?.map((food) => (
            <Box
              variant="solid"
              key={food}
              bg="#f4d9db"
              color="gray"
              display="flex"
              borderRadius={5}
              py={2}
              px={3}
              justifyContent="center"
              gridGap={2}
              alignItems="center">
              <img src={FoodsAddNew} alt="beef" className={styles.image_food} />
              <span>{food}</span>
              <Button size="xs" bg="transparent" onClick={() => handleRemoveNewFoodAvoid(food)}>
                X
              </Button>
            </Box>
          ))}
        </Box>
        <form onSubmit={handleAddListAvoid}>
          <Box display="flex" gridGap={3}>
            <Input
              placeholder={t("foods.write_article")}
              border="1px solid black"
              value={newFoodAvoid}
              onChange={(e) => setNewFoodAvoid(e.target.value)}
            />
            <Button bg="black" type="submit" color="white">
              {t("add")}
            </Button>
          </Box>
        </form>
      </Box>
    </Box>
  );
};

export default FoodList;
