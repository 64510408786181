/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */

import { useEffect, useState } from "react";
import {
  saveNewGoal,
  saveNewMeasurement,
  updateGoal,
  updateMeasurement,
} from "services/evaluations";
import { Button, Flex } from "@chakra-ui/react";
import notificationDisplay from "utils/notificationDisplay";
import { useDispatch, useSelector } from "react-redux";
import useLang from "hooks/useLang";
import GoalForm from "./goalForm";
import MeasurementsForm from "./measurementsForm";

import styles from "./goalForm.module.scss";

const GoalsAndMeasurements = ({
  client,
  goal,
  measurement,
  setStep,
  setNewGoal,
  setNewMeasurement,
}) => {
  const { t } = useLang();
  const [formStep, setFormStep] = useState(1);
  const userData = useSelector(({ user }) => user);
  const notiDispatch = useDispatch();

  const saveGoal = async (data) => {
    try {
      console.log(data);
      const id = goal?._id || "";
      const res =
        id === ""
          ? await saveNewGoal({ ...data, user: userData._id, client: client.split("___")[1] })
          : await updateGoal(goal._id, data);
      if (res.data) throw new Error(res.data.message);
      setNewGoal(res);

      // notificationDisplay(notiDispatch, t("bio_start"), "success");
    } catch (exception) {
      console.log(exception);
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };
  const saveMeasurement = async (data) => {
    console.log("save measurement");
    try {
      const id = measurement?._id || "";
      const res =
        id === ""
          ? await saveNewMeasurement({ ...data, client: client.split("___")[1] })
          : await updateMeasurement(measurement._id, data);
      if (res.data) throw new Error(res.data.message);
      setNewMeasurement(res);
      setStep((prev) => prev + 1);
      notificationDisplay(notiDispatch, t("measurement_start"), "success");
    } catch (exception) {
      console.log(exception);
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };
  const handleBack = () => {
    if (formStep === 1) {
      const leavePage = window.confirm(t("back_confirmation"));
      if (leavePage) {
        setStep(1);
      }
    } else setFormStep((prev) => prev - 1);
  };
  const handleNext = () => {
    setFormStep((prev) => prev + 1);
    /* if (formStep === 9) setStep((prev) => prev + 1); */
    // if (formStep === 4) saveGoal();
    // console.log(formStep, measurement);
    /* if (formStep === 9) {
      saveMeasurement();
    } */
  };

  return (
    <div className={styles.root}>
      {formStep < 5 && (
        <GoalForm goal={goal} formStep={formStep} setNewGoal={setNewGoal} saveGoal={saveGoal} />
      )}
      {formStep >= 5 && (
        <MeasurementsForm
          measurement={measurement}
          formStep={formStep}
          setNewMeasurement={setNewMeasurement}
          saveMeasurement={saveMeasurement}
        />
      )}
      <div style={{ width: "100%" }}>
        <Flex mt={4} gridGap={4} justifyContent="flex-end" alignItems="center">
          <div>{formStep} / 9</div>
          <Button variant="ghost" onClick={handleBack}>
            {t("previous")}
          </Button>
          <Button colorScheme="red" onClick={handleNext}>
            {t("next")}
          </Button>
        </Flex>
      </div>
    </div>
  );
};
export default GoalsAndMeasurements;
