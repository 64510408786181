/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
  Text,
  AlertDialog,
  AlertDialogOverlay,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { bool, func, shape } from "prop-types";
import useLang from "hooks/useLang";
import generateId from "helpers/generateId";
import useExerciseEditValueContext from "./useExerciseEditValueContext";
import ExerciseEditContent from "./ExerciseEditContent";

const ExerciseEditContext = createContext();

const ExerciseEdit = ({
  isOpen,
  onClose,
  quotes,
  columns,
  scrollHorizontalBoard,
  setColumns,
  currentClient,
  titleHeader,
  listdays,
  columnScroll,
}) => {
  const { t } = useLang();
  const [openDialog, setopenDialog] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const inicialValue = [
    {
      author: {
        id: generateId(),
        name: quotes[0].day,
        colors: {
          soft: "white",
          hard: "white",
        },
      },
      _id: generateId(),
      id: generateId(),
      completed: false,
      date: null,
      day: quotes[0].day,
      session: "",
      week: quotes[0].week,
      notes: {
        en: "",
        es: "",
      },
      exercises: [],
    },
  ];

  const [values, obj] = useExerciseEditValueContext(inicialValue, isEdit);

  const dateInfo = { day: quotes[0].day, week: quotes[0].week };

  const handleSaveEdit = () => {
    const joinExercises = quotes[0].exercises.concat(values[0].exercises);
    const joinData = quotes.map((item) => ({ ...item, exercises: joinExercises }));
    const updatedDay = listdays.map((objArr) =>
      objArr._id === joinData[0]._id ? joinData[0] : objArr,
    );
    let weekColumn = [...columns];
    const arrIndex = titleHeader - 1;
    weekColumn.splice(arrIndex, 1, updatedDay);
    setColumns(weekColumn);
    const ContentListColumn = columnScroll.current;
    const ContentMainScroll = scrollHorizontalBoard.current;
    setTimeout(() => {
      const height = ContentListColumn.scrollTop;
      const horizontalScroll = ContentMainScroll.scrollLeft;
      ContentMainScroll.scrollTo(horizontalScroll, 0);
      ContentListColumn.scrollTo(0, height);
    }, 100);
    onClose();
  };

  const handleConfirmClose = () => {
    if (quotes[0] !== values[0]) {
      setopenDialog(true);
      return;
    }
    onClose();
  };

  useEffect(() => {
    setIsEdit(true);
  }, [quotes]);

  return (
    <ExerciseEditContext.Provider
      value={{
        ...obj,
      }}>
      <Modal
        closeOnOverlayClick={false}
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={() => handleConfirmClose()}
        size="xl">
        <ModalOverlay />
        <ModalContent borderTop="13px solid #b42519" borderRadius={10}>
          <ModalHeader display="flex" justifyContent="space-between">
            <Box>
              <Text display="inline">
                {`${t("add_exercise")} ${t("in_day")} `}
                <Text display="inline" color="#b42519">
                  {quotes[0].day}
                </Text>
                {` ${t("list_week")} `}
                <Text display="inline" color="#b42519">
                  {quotes[0].week}
                </Text>
              </Text>
            </Box>
            <Box>
              <ModalCloseButton />
            </Box>
          </ModalHeader>
          <ModalBody>
            <Box>
              {values.map((day) => (
                <ExerciseEditContent
                  inicialValue={day}
                  isEdit={isEdit}
                  date={dateInfo}
                  currentClient={currentClient}
                  onCloseMain={onClose}
                />
              ))}
              <Box display="flex" justifyContent="flex-end" marginTop={2} marginBottom={2}>
                <Button
                  type="submit"
                  bg="#6bca21"
                  color="white"
                  _hover={{ bg: "#6bca21" }}
                  onClick={() => handleSaveEdit()}>
                  {t("save")}
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
      {openDialog && (
        <AlertDialog isOpen={openDialog} onClose={() => setopenDialog(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
                {t("delete_confirmation")}
              </AlertDialogHeader>
              <AlertDialogBody>{t("confirm_save_or_discard")}</AlertDialogBody>
              <AlertDialogFooter>
                <Box
                  display="flex"
                  flexDirection="column"
                  w="full"
                  gridGap={2}
                  justifyContent="center"
                  alignItems="center">
                  <Button w="70%" border="1px solid #b42519" onClick={() => setopenDialog(false)}>
                    {t("backBtn")}
                  </Button>
                  <Button
                    w="70%"
                    bg="#b42519"
                    color="white"
                    _hover={{ bg: "white", border: "1px solid #b42519", color: "black" }}
                    onClick={() => onClose()}>
                    {t("discard")}
                  </Button>
                  <Button
                    w="70%"
                    bg="#6bca21"
                    color="white"
                    _hover={{ bg: "#6bca21" }}
                    onClick={() => handleSaveEdit()}>
                    {t("save")}
                  </Button>
                </Box>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
    </ExerciseEditContext.Provider>
  );
};

const useExerciseEditContext = () => useContext(ExerciseEditContext);
export default ExerciseEdit;

export { useExerciseEditContext };
