/* eslint-disable import/prefer-default-export */
export const dataListSections = [
  {
    id: 1,
    section: "clients",
    about: "add_client",
    url: "https://www.youtube.com/watch?v=RZUN3RcDm5Y",
    description: 'help_center.add_client_description'
  },
  {
    id: 2,
    section: "profile",
    about: "edit_profile",
    url: "https://www.youtube.com/watch?v=T7dLavYwWIU",
    description: 'help_center.edit_profile_description'
  },
  {
    id: 3,
    section: "exercises",
    about: "help_sections_upload_exercises",
    url: "https://www.youtube.com/watch?v=LpUuuQYRcZo",
    description: 'help_center.upload_exercises_description'
  },
  {
    id: 4,
    section: "evaluation",
    about: "help_sections_do_evaluation",
    url: "https://www.youtube.com/watch?v=9R8OV2TlDYM",
    description: 'help_center.do_eveluation_description'
  },
  {
    id: 5,
    section: "programs",
    about: "create_program",
    url: "https://www.youtube.com/watch?v=Sph2HoDtKsQ",
    description: 'help_center.create_program_description'
  },
  {
    id: 6,
    section: "clients",
    about: "help_sections_create_program_client",
    url: "https://www.youtube.com/watch?v=SJClkF3uKBg",
    description: 'help_center.create_program_client_description'
  },
  {
    id: 7,
    section: "workout",
    about: "help_sections_create_circuit",
    url: "https://www.youtube.com/watch?v=WcJ36D8jSvc",
    description: 'help_center.create_circuit_description'
  },
  {
    id: 8,
    section: "workout",
    about: "help_sections_import_from_client",
    url: "https://www.youtube.com/watch?v=o5lHVhyS7FQ",
    description: 'help_center.import_programs_client_description'
  },
  {
    id: 9,
    section: "workout",
    about: "help_sections_repeat_weeks",
    url: "https://www.youtube.com/watch?v=ARYH-G7uEC8",
    description: 'help_center.repeat_weeks_description'
  },
  {
    id: 10,
    section: "workout",
    about: "help_sections_app_features",
    url: "https://www.youtube.com/watch?v=Inkx93kydH0",
    description: 'help_center.app_features_description'
  },
];
