import axios from "axios";

const instance = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_FILE_API_URL,
  headers: { language: localStorage.getItem("i18nextLng") || "en" },
});

const api = instance;
const uploadFile = async (data) => {
  try {
    const response = await api.post("/file/upload/single", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

const uploadMultipleFile = async (data) => {
  try {
    const response = await api.post("/file/upload/multiple", data, {
      headers: { "Content-Type": "multipart/form-data" },
    });
    return response.data;
  } catch (error) {
    return error.response;
  }
};

export { uploadFile, uploadMultipleFile };
