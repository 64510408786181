/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { shape, string } from "prop-types";
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  DrawerFooter,
  Flex,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Link, useHistory } from "react-router-dom";
import Select from "react-select";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useLang from "hooks/useLang";
import { getClientRoutines } from "services/customWorkout";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import useGetAll from "hooks/useGetAll";
import notificationDisplay from "utils/notificationDisplay";
import { getTrainerClients } from "services/clients";
import Board from "./components/Board";
import RoutineInfo from "../Features/RoutineInfo";
import ProgramList from "../Combine/ProgramList";

const FromClient = ({ client, workout, clientInfo }) => {
  const { lang, t } = useLang();
  const { id_user } = useSelector(({ user }) => user);
  const [data, setData] = useState([]);
  const [emplyValue, setemplyValue] = useState("");
  const [filtered, setFiltered] = useState([]);
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [clients, setClients] = useState();
  const history = useHistory();
  const { isOpen: isOpenDrawer, onOpen: onOpenDrawer, onClose: onCloseDrawer } = useDisclosure();

  const [options, setOptions] = useState([]);

  const getClients = async () => {
    try {
      const response = await getTrainerClients(id_user);
      if (response.data) throw new Error(response.data.error);
      setClients(response);
      setOptions(
        response.sort((a, b) => (a.updated_at > b.updated_at ? -1 : 1)).map(({ name: nameClient, lastName, _id }) => {
          const returnOptions = {
            value: _id,
            label: `${nameClient} ${lastName || ""}`,
          };
          return returnOptions;
        }),
      );
    } catch (err) {
      notificationDisplay(err.message, "something went wrong", "error");
    }
  };

  const ReAsigned = (program) => {
    console.log(program);
    const FormatDaysSession = [...program.sessions];
    const daysField = FormatDaysSession.map((i) => {
      const add = {
        ...i,
        id: i._id,
        author: {
          id: i._id,
          name: i.week,
          colors: {
            soft: "white",
            hard: "white",
          },
        },
      };
      return add;
    });
    const FormatDays = [...daysField];
    const array = FormatDays?.map(({ week }) => week);
    const newarray = [...new Set(array)];
    const dataFilter = newarray?.map((item) =>
      daysField?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );
    console.log(dataFilter);
    setSelectedProgram(program);
    setFiltered(dataFilter);
  };
  const SelectClientList = async ({ value }) => {
    try {
      const response = await getClientRoutines(value);
      if (response.data) throw new Error(response.data.error);
      if (response.length > 0) {
        setFiltered([]);
        setemplyValue("");
        setData(response);
        setSelectedProgram(response[0]);
        setemplyValue(value);
        ReAsigned(response[0]);
      } else {
        setFiltered([]);
        setData([]);
      }
    } catch (err) {
      notificationDisplay(err.message, "something went wrong", "error");
    }
  };
  useEffect(async () => {
    await getClients();
  }, []);

  /* useEffect(async () => {
    if (clients && clients.length > 0) {
      const response = await getClientRoutines(clients[0]._id);
      if (response.length > 0) {
        setemplyValue(clients[0]._id);
        await ReAsigned(response[0]);
      } else {
        setFiltered([]);
        setData([]);
      }
    }
  }, [clients]); */

  useEffect(() => {
    const sidebar = document.getElementsByClassName("sidebar")[0];
    window.onbeforeunload = (e) => {
      console.log(e);
      return "Leaving this page will reset the wizard";
    };
    window.history.pushState(null, document.title, window.location.href);
    window.onpopstate = () => {
      const leavePage = window.confirm(t("back_confirmation"));
      if (leavePage) {
        window.onpopstate = null;
        window.history.back();
      } else {
        window.history.pushState(null, document.title, window.location.href);
      }
    };
    if (sidebar) sidebar.setAttribute("class", "sidebar collapse");
    return () => {
      window.onbeforeunload = null;
      window.onpopstate = null;
      if (sidebar) sidebar.setAttribute("class", "sidebar");
    };
  }, []);

  const goBack = () => {
    const leavePage = window.confirm(t("back_confirmation"));
    if (leavePage) {
      history.push({
        pathname: "/user/clients",
        state: {
          clientInfo,
        },
      });
    }
  };
  return (
    <div>
      <Box>
        <Box w="100%" mb="1rem" display="flex" justifyContent="space-between" alignItems="center">
          <Button
            onClick={() => goBack()}
            color="gray"
            size="xs"
            variant="outline"
            leftIcon={<ArrowBackIosNewIcon fontSize="inherit" />}>
            {t("backBtn")}
          </Button>
          <Text fontSize="xs">
            {t("creating_program")}
            <span
              style={{
                color: "#b42519",
                fontWeight: "bold",
              }}>
              {` ${clientInfo.name || client.split("___")[0]} ${(clientInfo?.lastName || client.split("___")[2]) ?? ""
                }`}
            </span>
          </Text>
          <div />
        </Box>
        <Flex gridGap="1rem" alignItems="flex-start" className="boardContent">
          <div id="board">
            <Board
              initial={filtered}
              withScrollableColumns
              client={client}
              workout={workout}
              clientInfo={clientInfo}
              setFiltered={setFiltered}>
              <Flex gridGap="10px">
                <Box>
                  <Text fontSize="xs" fontWeight="bold">
                    {selectedProgram?.workout_name[lang]}
                  </Text>
                  <Text fontSize="xs">
                    {selectedProgram?.id_client?.name} {selectedProgram?.id_client?.lastName}
                  </Text>
                </Box>
                <Button size="sm" colorScheme="teal" onClick={onOpenDrawer}>
                  {t("select_routine_word")}
                </Button>
              </Flex>
              <Box>
                {filtered.length < 1 && emplyValue === "" && (
                  <Flex
                    width="100%"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center">
                    <Text mb="1rem">{t("searchClient")}</Text>
                    <Button colorScheme="teal" onClick={onOpenDrawer}>
                      {t("select_routine_word")}
                    </Button>
                  </Flex>
                )}
                {/* {filtered?.length === 0 && data?.length === 0 && emplyValue !== "" && (
                  <Box width="100%" display="flex" justifyContent="center" alignItems="center">
                    <p>This client has not a programs</p>
                  </Box>
                )} */}
              </Box>
            </Board>
          </div>
        </Flex>
      </Box>

      <Drawer isOpen={isOpenDrawer} placement="right" onClose={onCloseDrawer}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader mb="1rem" />
          <DrawerBody>
            <div>
              <Box mb="1rem">
                <Select
                  options={options}
                  onChange={SelectClientList}
                  defaultValue={emplyValue}
                  placeholder={t("select_a_client")}
                />
              </Box>

              {data?.map((program) => (
                <ProgramList
                  name={program.workout_name}
                  sub={program.source}
                  phase={program.phase || "1"}
                  createdDate={dayjs(program.created_at).format(
                    lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                  )}
                  isActive={program._id === selectedProgram?._id}
                  handleClick={() => ReAsigned(program)}
                  key={program._id}
                />
              ))}
              {data.length <= 0 && <Text textAlign="center">{t("routines_not_found")}</Text>}
            </div>
          </DrawerBody>

          <DrawerFooter borderTopWidth="1px">
            <Button variant="outline" mr={3} onClick={onCloseDrawer}>
              {t("close")}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </div>
  );
};

FromClient.propTypes = {
  client: string.isRequired,
  workout: string.isRequired,
  clientInfo: shape({}).isRequired,
};

export default FromClient;
