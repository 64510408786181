/* eslint-disable react/no-unknown-property */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import {
  Box,
  Grid,
  Flex,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  VStack,
  Button,
  Input,
  Textarea,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import propTypes from "prop-types";

import { inputStyle } from "theme/commonStyles";

import general from "./general.module.scss";
import { changeRadioCheckState } from "./radiosState";

const General = ({ radios, className, setRadios, radiosDefault, side }) => {
  const [addNote, setAddNote] = useState(false);
  const { t } = useTranslation();

  const handleRadioClick = ({ target }) => setRadios((prev) => changeRadioCheckState(prev, target));

  return (
    <>
      <Box className={className.root}>
        {radios.map((radio) => (
          <input
            className={`${className.check} ${radio.checked ? general.mark : general.great}`}
            id={radio.name}
            key={radio.name}
            name="exercise"
            onClick={handleRadioClick}
            type="radio"
          />
        ))}
      </Box>
    </>
  );
};
General.propTypes = {
  setRadios: propTypes.func.isRequired,
  radios: propTypes.shape([]).isRequired,
  radiosDefault: propTypes.shape([]).isRequired,
  side: propTypes.string.isRequired,
  className: propTypes.shape({
    root: propTypes.string.isRequired,
    check: propTypes.string.isRequired,
  }).isRequired,
};

export default General;
