import {
  Avatar,
  Badge,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import { getAllTrainers } from "services/trainer";
import useLang from "hooks/useLang";
import styles from "./management.module.scss";

const Management = () => {
  const [trainers, settrainers] = useState([]);
  const { t } = useLang();
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(15);
  const [searchValue, setSearchValue] = useState("");
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const [nPages, setNPages] = useState(0);

  useEffect(() => {
    setNPages(
      Math.ceil(
        trainers?.filter(
          ({ name, lastName }) =>
            name?.toLowerCase().includes(searchValue.toLowerCase()) ||
            lastName?.toLowerCase().includes(searchValue.toLowerCase()),
        ).length / recordsPerPage,
      ),
    );
  }, [searchValue, trainers]);

  const goToNextPage = () => {
    if (currentPage !== nPages) setCurrentPage(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 1) setCurrentPage(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 1) setCurrentPage(1);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) setCurrentPage(nPages);
  };

  useEffect(() => {
    const getTrainers = async () => {
      try {
        const response = await getAllTrainers();
        if (response.data) throw new Error("have a error");
        settrainers(response.trainers);
      } catch (error) {
        console.log(error);
      }
    };
    getTrainers();
  }, []);

  return (
    <div>
      <header className={styles.header}>
        <Input
          variant="outline"
          type="text"
          w="lg"
          border="1px solid gray"
          placeholder={`${"search"}...`}
          value={searchValue}
          onChange={({ target }) => setSearchValue(target.value)}
        />
        <Text fontSize="2xl">
          {t("tatal_user")}: <span style={{ fontWeight: "800" }}>{trainers.length} </span>
          <span style={{ fontSize: "16px" }}>({t("total_user_warn")})</span>
        </Text>
      </header>
      <TableContainer border="1px solid lightgray" borderRadius="15px">
        <Table variant="striped" bg="#FFFFFF">
          <Thead>
            <Tr>
              <Th>#</Th>
              <Th>{t("fullname")}</Th>
              <Th>{t("email")}</Th>
              <Th>{t("membership_status")}</Th>
              <Th>{t("speciality")}</Th>
              <Th>{t("job_description")}</Th>
            </Tr>
          </Thead>
          <Tbody>
            {trainers
              ?.filter(({ name, lastName }) =>
                new RegExp(searchValue, "gi").test(`${name} ${lastName}`),
              )
              ?.sort((a, b) => (a.created_at > b.created_at ? -1 : 1))
              ?.slice(searchValue !== "" ? 0 : indexOfFirstRecord, indexOfLastRecord)
              ?.map((trainer) => (
                <Tr>
                  <Td>
                    <Avatar
                      src={`${process.env.REACT_APP_API_URL_PROD}/file?file=${trainer?.pic}`}
                      name={`${trainer.name} ${trainer?.lastName ?? ""}`}
                      size="sm"
                    />
                  </Td>
                  <Td>{`${trainer.name} ${trainer?.lastName ?? ""}`}</Td>
                  <Td>{trainer?.id_user?.email}</Td>
                  <Td>
                    <Badge ml="1" colorScheme="green">
                      {trainer?.id_user?.membership}
                    </Badge>
                  </Td>
                  <Td>{t(trainer.speciality)}</Td>
                  <Td>{t(trainer.job_description)}</Td>
                </Tr>
              ))}
          </Tbody>
        </Table>
      </TableContainer>
      <nav>
        <ul className="pagination">
          <li>
            <IconButton
              isRound
              variant="ghost"
              colorScheme="blackAlpha"
              aria-label="Done"
              fontSize="20px"
              disabled={currentPage === 1}
              icon={<FirstPageIcon />}
              onClick={goToFirstPage}
            />
          </li>
          <li>
            <IconButton
              isRound
              variant="ghost"
              colorScheme="blackAlpha"
              aria-label="Done"
              fontSize="20px"
              icon={<ArrowBackIosIcon />}
              onClick={goToPrevPage}
              disabled={currentPage === 1}
            />
          </li>
          <li>
            {searchValue !== "" ? 1 : currentPage}/{nPages <= 0 ? 1 : nPages}
          </li>
          <li>
            <IconButton
              isRound
              variant="ghost"
              colorScheme="blackAlpha"
              aria-label="Done"
              fontSize="20px"
              icon={<ArrowForwardIosIcon />}
              onClick={goToNextPage}
              disabled={currentPage >= nPages}
            />
          </li>
          <li>
            <IconButton
              isRound
              variant="ghost"
              colorScheme="blackAlpha"
              aria-label="Done"
              fontSize="20px"
              disabled={currentPage === nPages}
              icon={<LastPageIcon />}
              onClick={goToLastPage}
            />
          </li>
        </ul>
      </nav>
    </div>
  );
};

export default Management;
