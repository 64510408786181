/* eslint-disable no-undef */
/* eslint-disable prefer-const */
/* eslint-disable no-unused-vars */
/* eslint-disable no-shadow */
/* eslint-disable react/prop-types */
import React, { useState } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import Select from "react-select";
import { getPreprogrammed } from "services/preProgrammed";
import { getTrainerClients } from "services/clients";
import useGetAll from "hooks/useGetAll";
import { CloseIcon } from "@chakra-ui/icons";
import { getClientRoutines } from "services/customWorkout";
import { useSelector } from "react-redux";
import SearchIcon from "@mui/icons-material/Search";
import dayjs from "dayjs";
import useLang from "hooks/useLang";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import generateId from "helpers/generateId";
import StylesPrograms from "./Combine.module.css";
import ProgramList from "./ProgramList";

const MorePrograms = ({
  onClose,
  isOpen,
  days,
  columns,
  onCloseMain,
  handleUpdateDnd,
  routineId,
  lastday,
  currentClient,
  currentWeek,
}) => {
  const { id_user } = useSelector(({ user }) => user);
  const { lang, t } = useLang();
  const [data, setData] = useState(null);
  const [programData, setProgramData] = useState([]);
  const [searchValue, setSearchValue] = useState();
  const [completed, setCompleted] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [nameWorkOut, setnameWorkOut] = useState("");
  const {
    data: dataNameClient,
    isLoading,
    isError,
    refetch,
  } = useGetAll("", id_user, getTrainerClients);

  const handleGetPrepromamed = async () => {
    const resopnse = await getPreprogrammed();
    const dataUser = resopnse?.filter((item) => item.trainer === id_user || !item.trainer);
    setData(dataUser);
  };
  const options = dataNameClient?.map(({ name, _id }) => {
    const returnOptions = {
      value: _id,
      label: name,
    };
    return returnOptions;
  });

  const SelectClientList = async ({ value }) => {
    const response = await getClientRoutines(value);
    setData(response);
  };
  const handleClear = () => {
    setProgramData([]);
    setData(null);
    setWeeks([]);
    setCompleted([]);
    onClose();
  };

  const handleClickProgram = (program) => {
    const FormatDays = [...program.sessions];
    const array = FormatDays?.map(({ week }) => week);
    const newarray = [...new Set(array)];

    const dataFilter = newarray?.map((item) =>
      program.sessions?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
    );
    setProgramData(dataFilter);
    setnameWorkOut(program.workout_name.en);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const sourceIndex = result.source.index;
    const destinationIndex = result.destination.index;

    // Move day in the list source to
    if (result.source.droppableId === "tasks" && result.destination.droppableId === "completed") {
      const arrayOfObjects = programData.flat();
      const removed = arrayOfObjects.find(({ _id }) => _id === sourceIndex);
      setProgramData([...programData]);
      setCompleted([
        ...completed,
        {
          ...removed,
          _id: generateId(),
        },
      ]);
    }

    // Move the list completo to tasks again
    // if (
    //   result.source.droppableId === 'completed'
    //   && result.destination.droppableId === 'completed'
    // ) {
    //   const resultInitial = Array.from([...completed[result.source.droppableId]]);
    //   const [removed] = resultInitial.splice(sourceIndex, 1);
    //   resultInitial.splice(destinationIndex, 0, removed);
    //   const resultArr = {
    //     ...completed,
    //     [result.source.droppableId]: resultInitial,
    //   };
    //   console.log('take the day', resultArr);
    // }
  };

  const handleSave = () => {
    const ReasginedWeeks = [...weeks, ...completed];
    const order = ReasginedWeeks.map((obj, j) => ({
      ...obj,
      day: 1 + j,
      week: currentWeek,
      _id: generateId(),
      exercises: obj.exercises.map((i) => ({
        ...i,
        _id: generateId(),
        sets: i.sets.map((e) => ({
          ...e,
          _id: generateId(),
        })),
      })),
    }));
    const flatOrder = order.flat();
    setWeeks(flatOrder);
    setCompleted([]);
    setProgramData([]);
  };

  const handleSaveAll = () => {
    if (days === undefined || days.length === 0) {
      const order = weeks.map((obj, i) => ({
        ...obj,
        id: generateId(),
        author: {
          id: generateId(),
          name: 1,
          colors: {
            soft: "white",
            hard: "white",
          },
        },
        week: 1,
      }));
      handleUpdateDnd(routineId, order);
    } else {
      const order = weeks.map((obj, i) => ({
        ...obj,
        id: generateId(),
        author: {
          id: generateId(),
          name: currentWeek,
          colors: {
            soft: "white",
            hard: "white",
          },
        },
        session: obj.session ?? "",
        day: lastday + i,
      }));
      const arrayOfObjects = columns.reduce((acc, val) => acc.concat(val), []);
      const cocatValues = arrayOfObjects.concat(order);
      handleUpdateDnd(routineId, cocatValues);
    }
    handleClear();
    onCloseMain();
  };

  const handleRemoveDaySelected = (i) => {
    setCompleted((prev) => prev.filter((element, index) => index !== i));
  };

  const handleRemoveWeekConfirmed = (current) => {
    const removeDay = weeks.filter((element) => element !== current);
    const sortDays = removeDay.map((obj, j) => ({
      ...obj,
      day: 1 + j,
      week: currentWeek,
      _id: generateId(),
      exercises: obj.exercises.map((i) => ({
        ...i,
        _id: generateId(),
        sets: i.sets.map((e) => ({
          ...e,
          _id: generateId(),
        })),
      })),
    }));
    setWeeks(sortDays);
  };
  return (
    <Modal
      returnFocusOnClose={false}
      closeOnOverlayClick={false}
      onClose={() => {
        handleClear();
        onCloseMain();
      }}
      size="6xl"
      isOpen={isOpen}>
      <ModalOverlay />
      <ModalContent height="700px">
        <ModalBody p={0}>
          <Box>
            <Box className={StylesPrograms.info} m={0} p={0}>
              <Box
                bg="#eeeeee"
                minHeight="700px"
                width="600px"
                className={StylesPrograms.list}
                p={5}
                overflowY="auto">
                <div className={StylesPrograms.input_icon}>
                  <SearchIcon className={StylesPrograms.icon_search} />
                  <Input
                    value={searchValue}
                    onChange={({ target }) => setSearchValue(target.value)}
                    variant="flushed"
                    placeholder={t("search")}
                    borderRadius="20px"
                    pl={12}
                    border="1px solid gray"
                    backgroundColor="white"
                  />
                </div>
                {data?.length <= 0 ? (
                  <p>This client has no programs</p>
                ) : (
                  <>
                    {data
                      ?.filter(
                        (program) =>
                          new RegExp(searchValue, "gi").test(program.workout_name.en) ||
                          new RegExp(searchValue, "gi").test(program.workout_name.es),
                      )
                      ?.map((program) => (
                        <ProgramList
                          name={program.workout_name}
                          sub={program.source}
                          phase={program.phase || "1"}
                          createdDate={dayjs(program.created_at).format(
                            lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                          )}
                          handleClick={() => handleClickProgram(program)}
                          isActive={programData.workout_name?.en === program.workout_name.en}
                          key={program._id}
                        />
                      ))}
                  </>
                )}
              </Box>
              <Box p={5} className={StylesPrograms.box}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                  <Box display="flex" flexDirection="row" justifyContent="start">
                    <Text fontWeight="semibold" fontSize="20px">
                      {t("add_more_exercise_title")}{" "}
                      <span style={{ color: "#b42519" }}>{currentClient ?? t('my')}</span> {t("from")}:
                    </Text>
                  </Box>
                  <Box>
                    <ModalCloseButton />
                  </Box>
                </Box>
                <Box display="flex" justifyContent="space-between" my={5}>
                  <Box
                    display="flex"
                    flexDirection="row"
                    justifyContent="start"
                    style={{ gap: "20px" }}>
                    <Button
                      width={36}
                      _focus={{
                        bg: "#b42519",
                        border: "1px solid #b42519",
                        color: "white",
                      }}
                      onClick={() => handleGetPrepromamed()}>
                      {t("preprogrammed_workout")}
                    </Button>
                    <Box width={44}>
                      <Select options={options} onChange={SelectClientList} />
                    </Box>
                  </Box>
                  <Box mr={5}>
                    {weeks.length >= 1 ? (
                      <Button
                        onClick={() => handleSaveAll()}
                        bg="#6bca21"
                        border="none"
                        color="white"
                        _hover={{ background: "#6bca21", border: "mome" }}>
                        {t("import_program")}
                      </Button>
                    ) : (
                      <Button disabled bg="#eeeeee">
                        {t("import_program")}
                      </Button>
                    )}
                  </Box>
                </Box>
                <Text
                  letterSpacing="0.5em"
                  textTransform="uppercase"
                  color="#b42519"
                  fontWeight="semibold">
                  {!nameWorkOut ? t("select_routine_word") : nameWorkOut}
                </Text>
                <Box display="flex" flexDirection="row" overflowY="auto">
                  <DragDropContext onDragEnd={handleDragEnd}>
                    <Droppable droppableId="tasks">
                      {(provided) => (
                        <>
                          <div
                            className="tasks"
                            {...provided.droppableProps}
                            ref={provided.innerRef}
                            style={{
                              background: "white",
                              width: "300px",
                              height: "500px",
                              padding: "1rem",
                              overflowY: "auto",
                            }}>
                            <h2>{t("list_week")}</h2>
                            {Object.keys(programData).length >= 1 && (
                              <>
                                <Accordion defaultIndex={[0]} allowMultiple>
                                  {programData?.map((task, index) => (
                                    <AccordionItem my={3} bg="white" color="black">
                                      <h2>
                                        <AccordionButton
                                          border="1px solid gray"
                                          borderRadius="10px">
                                          <Box
                                            as="span"
                                            flex="1"
                                            textAlign="left"
                                            color="gray"
                                            fontWeight="semibold">
                                            <Text fontWeight="bold">
                                              {t("list_week")} {index + 1}
                                            </Text>
                                            <Text>{t("week_name")}</Text>
                                          </Box>
                                          <AccordionIcon />
                                        </AccordionButton>
                                      </h2>
                                      <AccordionPanel p={0}>
                                        {task?.map((e) => (
                                          <Draggable key={e._id} draggableId={e._id} index={e._id}>
                                            {(provided) => (
                                              <Box
                                                className="task"
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                ref={provided.innerRef}
                                                padding="1rem"
                                                borderRadius="10px"
                                                border="1px solid lightgray"
                                                bg="#f6f6f6"
                                                mt={2}
                                                mb={2}
                                                key={e._id}>
                                                <Text
                                                  display="inline"
                                                  color="gray"
                                                  fontWeight="bold"
                                                  mb={2}>
                                                  {t("list_day")} {e.day}
                                                </Text>
                                                {e.exercises?.map((exercose) => (
                                                  <Box
                                                    key={exercose._id}
                                                    display="flex"
                                                    justifyContent="space-between">
                                                    <Text fontSize="sm">
                                                      {exercose.id_exercise.name[lang]}
                                                    </Text>
                                                    <Text color="#b42519">
                                                      {exercose.sets.length} Sets
                                                    </Text>
                                                  </Box>
                                                ))}
                                              </Box>
                                            )}
                                          </Draggable>
                                        ))}
                                      </AccordionPanel>
                                    </AccordionItem>
                                  ))}
                                </Accordion>
                              </>
                            )}
                            {provided.placeholder}
                          </div>
                        </>
                      )}
                    </Droppable>
                    <Droppable droppableId="completed">
                      {(provided) => (
                        <div
                          className="completed"
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            background: "#f6f6f6",
                            width: "300px",
                            padding: "1rem",
                            height: "500px",
                            overflowY: "auto",
                          }}>
                          <h2>{t("seleted")}</h2>
                          {completed?.map((task, index) => (
                            <Draggable key={task._id} draggableId={task._id} index={index}>
                              {(provided) => (
                                <Box
                                  className="task"
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  ref={provided.innerRef}
                                  padding="1rem"
                                  borderRadius="10px"
                                  border="1px solid lightgray"
                                  bg="white"
                                  mt={2}
                                  mb={2}>
                                  <Box display="flex" justifyContent="space-between">
                                    <Text
                                      display="inline"
                                      color="gray"
                                      fontWeight="semibold"
                                      mb={2}>
                                      {t("list_day")} {task.day}
                                    </Text>
                                    <button
                                      type="button"
                                      className={StylesPrograms.btn_delete_element}
                                      onClick={() => handleRemoveDaySelected(index)}>
                                      <CloseIcon />
                                    </button>
                                  </Box>
                                  {task.exercises?.map((exercose) => (
                                    <Box
                                      key={exercose._id}
                                      display="flex"
                                      justifyContent="space-between">
                                      <Text fontSize="sm">{exercose.id_exercise.name[lang]}</Text>
                                      <Text color="#b42519">{exercose.sets.length} Sets</Text>
                                    </Box>
                                  ))}
                                </Box>
                              )}
                            </Draggable>
                          ))}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>

                  <Box width="300px" height="500px" overflowY="auto">
                    {completed.length >= 1 && (
                      <Button
                        bg="#f07300"
                        borderRadius="8px"
                        color="white"
                        w="full"
                        onClick={handleSave}>
                        {t("confirm_days")}
                      </Button>
                    )}
                    <Accordion defaultIndex={[0]} allowMultiple>
                      {weeks?.map((obj, i) => (
                        <AccordionItem my={3} bg="white" color="black">
                          <h2>
                            <AccordionButton border="1px solid gray" borderRadius="10px" p={3}>
                              <Box
                                as="span"
                                flex="1"
                                textAlign="left"
                                color="gray"
                                fontWeight="semibold">
                                <Text
                                  display="flex"
                                  justifyContent="start"
                                  alignItems="center"
                                  style={{ gap: "10px" }}>
                                  <button
                                    type="button"
                                    className={StylesPrograms.btn_delete_element}
                                    onClick={() => handleRemoveWeekConfirmed(obj)}>
                                    <CloseIcon />
                                  </button>
                                  {t("list_day")} {obj.day}
                                </Text>
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel p={0}>
                            <Box
                              padding="1rem"
                              borderRadius="10px"
                              border="1px solid lightgray"
                              bg="white"
                              mt={2}
                              mb={2}>
                              {obj.exercises?.map((exercose) => (
                                <Box
                                  key={exercose._id}
                                  display="flex"
                                  justifyContent="space-between">
                                  <Text fontSize="sm">{exercose.id_exercise.name[lang]}</Text>
                                  <Text color="#b42519">{exercose.sets.length} Sets</Text>
                                </Box>
                              ))}
                            </Box>
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default MorePrograms;
