/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import CopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import ControlCameraIcon from "@mui/icons-material/ControlCamera";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { Draggable } from "react-beautiful-dnd";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogOverlay,
  Box,
  Button,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import useLang from "hooks/useLang";
import QuoteList from "./list";
import EditWeek from "./EditWeek/EditWeek";

const Container = styled.div`
  display: flex;
  padding: 1rem;
  border-radius: 10px;
  max-height: calc(100vh - 13rem);
  flex-direction: column;
  width: 310px;
  min-width: 310px;
  background-color: ${({ isDragging }) => (isDragging ? "#e7e7e7" : "#e7e7e7")};
  box-shadow: ${({ isDragging }) => (isDragging ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : "none")};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
  background-color: ${({ isDragging }) => (isDragging ? "#e7e7e7" : "#e7e7e7")};
  transition: background-color 0.2s ease;
`;

const Column = ({
  title,
  quotes,
  index,
  isScrollable,
  isCombineEnabled,
  useClone,
  columns,
  keyRefrest,
  setColumns,
  cloneState,
  listDaySelected,
  handleChangeRemove,
  handleChangeSelect,
  handleCancelClone,
  setOrdered,
  setSuperSetState,
  setFiltered,
  onCloneWeek,
  cloneUniqueDay,
  setCloneUniqueDay,
  scrollHorizontalBoard,
  currentClient,
  isBtnLoading,
}) => {
  const { t } = useLang();
  const titleNaN = Math.floor(index);
  const AiTitle = 1 + titleNaN;
  const [weeksfilter, setWeeksfilter] = useState(1 + quotes[quotes?.length - 1]?.day || 1);
  const [open, setopen] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    setWeeksfilter(1 + quotes[quotes?.length - 1]?.day || 1);
  }, [quotes, columns, cloneState]);

  const handleConfirmDelete = () => {
    if (quotes.length > 0) {
      // Gets all columns of the training routine.
      const allColumns = columns.flat();
      // Gets the current week of the training routine.
      const [{ week: currentWeek }] = quotes;
      // Filters the columns of the training routine to remove the current week.
      const filteredColumns = allColumns.filter(({ week }) => week !== currentWeek);
      // Updates the training routine with the filtered columns.
      const FormatDays = [...filteredColumns];
      // Extract the week property from each object in the array
      const array = FormatDays?.map(({ week }) => week);
      // Remove duplicates from the array
      const newarray = [...new Set(array)];
      // Group the objects by week property
      const dataFilter = newarray?.map((item) =>
        filteredColumns?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
      );
      const orderBoard = dataFilter.map((arr, i) =>
        arr.map((obj, j) => ({ ...obj, day: 1 + j, week: 1 + i })),
      );
      setColumns(orderBoard);
      setOrdered(Object.keys(orderBoard));
      setopen(false);
    } else {
      columns.splice(index, 1);
      setColumns(columns);
      setOrdered(Object.keys(columns));
      setopen(false);
    }
  };
  const cloneWeek = () => {
    const allColumns = columns.flat();
    const [{ week: currentWeek }] = quotes;
    const filteredColumns = allColumns.filter(({ week }) => week === currentWeek);
    const FormatDays = [...filteredColumns];
    onCloneWeek(filteredColumns);
  };
  return (
    <Draggable draggableId={title} index={index}>
      {(provided, snapshot) => (
        <Container ref={provided.innerRef} {...provided.draggableProps}>
          <Header isDragging={snapshot.isDragging}>
            <Box
              isDragging={snapshot.isDragging}
              {...provided.dragHandleProps}
              aria-label={`${title} quote list`}>
              <Box
                borderRadius="20px"
                bg="#3a3a3a"
                p=".5rem 1rem"
                display="flex"
                justifyContent="space-between"
                alignItems="center">
                <Box>
                  <Heading fontSize="xl" fontWeight="medium" color="white">
                    {`${t("list_week")} ${AiTitle}`}
                  </Heading>
                </Box>
                <Box>
                  <Box
                    color="gray"
                    fontSize="sm"
                    display="flex"
                    justifyContent="center"
                    alignItems="center">
                    {/* <Button size="sm" background="white" display="flex" justifyContent="center" alignItems="center" variant="ghost">
                      <CopyIcon fontSize="inherit" />
                      <Text fontSize="xs">
                        Duplicate
                      </Text>
                    </Button> */}
                    <Menu>
                      <MenuButton
                        as={IconButton}
                        aria-label="Options"
                        icon={<MoreVertIcon />}
                        color="white"
                        variant="ghost"
                        colorScheme="black"
                      />
                      <MenuList>
                        {/* <MenuItem
                          type="button"
                          onClick={() => onOpen()}
                          icon={<EditIcon />}
                          isDisabled={quotes.length <= 0}>
                          {t("edit")}
                        </MenuItem> */}
                        <MenuItem
                          type="button"
                          onClick={() => cloneWeek()}
                          icon={<ContentCopyIcon />}
                          isDisabled={quotes.length <= 0}>
                          {t("duplicate")}
                        </MenuItem>
                        <MenuItem
                          onClick={() => setopen(true)}
                          color="red"
                          icon={<DeleteIcon />}
                          isDisabled={columns.length <= 1}>
                          {t("delete")}
                        </MenuItem>
                        {/* <MenuItem
                          onClick={() => setSuperSetState({ data: quotes, position: index })}
                          icon={<AutorenewIcon />}>
                          {t("set_circuit")}
                        </MenuItem> */}
                      </MenuList>
                    </Menu>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Header>
          <QuoteList
            listId={title}
            listType="QUOTE"
            quotes={quotes}
            internalScroll={isScrollable}
            isCombineEnabled={Boolean(isCombineEnabled)}
            useClone={Boolean(useClone)}
            titleHeader={AiTitle}
            columns={columns}
            setColumns={setColumns}
            thelastday={weeksfilter}
            scrollHorizontalBoard={scrollHorizontalBoard}
            cloneState={cloneState}
            listDaySelected={listDaySelected}
            handleChangeRemove={handleChangeRemove}
            handleChangeSelect={handleChangeSelect}
            isBtnLoading={isBtnLoading}
            handleCancelClone={handleCancelClone}
            setOrdered={setOrdered}
            setCloneUniqueDay={setCloneUniqueDay}
            cloneUniqueDay={cloneUniqueDay}
            currentClient={currentClient}
          />
          {open && (
            <AlertDialog isOpen={open} onClose={() => setopen(false)}>
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader>{t("delete_week")}</AlertDialogHeader>
                  <AlertDialogBody>{t("delete_confirmation")}</AlertDialogBody>
                  <AlertDialogFooter>
                    <Button onClick={() => setopen(false)}>{t("cancel")}</Button>
                    <Button colorScheme="red" onClick={() => handleConfirmDelete()} ml={3}>
                      {t("delete")}
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          )}
          {isOpen && (
            <EditWeek
              isOpen={isOpen}
              onClose={onClose}
              AiTitle={AiTitle}
              currentClient={currentClient}
              columns={columns}
              setColumns={setColumns}
              quotes={quotes}
              key={isOpen}
            />
          )}
        </Container>
      )}
    </Draggable>
  );
};

export default Column;
