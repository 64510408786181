/* eslint-disable prefer-const */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  useDisclosure,
  setOrdered,
} from "@chakra-ui/react";
import { bool, func, shape } from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import generateId from "helpers/generateId";
import useAddDayValueContext from "./useAddDayValueContext";
import DayContent from "./DayContent";

const AddDayContext = createContext();

const AddDay = ({
  isOpen,
  onClose,
  date,
  titleHeader,
  quotes,
  handleSubmitCopySameProgram,
  routineId,
  columns,
  setColumns,
  thelastday: lastday,
}) => {
  const { isOpen: isOpenContent, onOpen: onOpenContent, onClose: onCloseContent } = useDisclosure();
  const [isEdit, setisEdit] = useState(false);

  const inicialValue = [
    {
      author: {
        ...lastday.author,
        id: generateId(),
        name: 1 + lastday.week,
      },
      _id: generateId(),
      id: generateId(),
      completed: false,
      date: null,
      day: lastday,
      session: "",
      week: titleHeader,
      notes: {
        en: "",
        es: "",
      },
      exercises: [],
    },
  ];

  const [values, obj] = useAddDayValueContext(inicialValue, isEdit);

  const dateInfo = { day: inicialValue[0].day, week: inicialValue[0].week };

  const handleSaveDay = () => {
    let copyDays = [...quotes];
    let weekColumn = [...columns];
    const arrPosition = titleHeader - 1;
    let cocatValues = copyDays.concat(values);
    weekColumn.splice(arrPosition, 1, cocatValues);
    setColumns(weekColumn);
    setisEdit(false);
    // setOrdered(Object.keys(weekColumn));
    onClose();
  };

  useEffect(() => {
    setisEdit(true);
  }, [lastday]);

  return (
    <AddDayContext.Provider
      value={{
        ...obj,
      }}>
      <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent borderTop="13px solid #b42519" borderRadius={10}>
          <ModalHeader>
            <p>
              Add day <span style={{ color: "#b42519" }}>{lastday}</span> in week{" "}
              <span style={{ color: "#b42519" }}>{titleHeader}</span>
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {values.map((day) => (
                <DayContent
                  inicialValue={day}
                  isEdit={isEdit}
                  date={dateInfo}
                  isOpen={isOpenContent}
                  onClose={onCloseContent}
                />
              ))}
              <Box display="flex" justifyContent="space-between" marginTop={2} marginBottom={2}>
                <Button
                  color="gray"
                  border="1px solid #E3E3E3"
                  borderRadius="8px"
                  bg="transparent"
                  _hover={{ color: "#b42519", border: "1px solid #b42519" }}
                  leftIcon={<AddIcon />}
                  onClick={onOpenContent}>
                  Add Exercise
                </Button>
                <Button
                  type="submit"
                  bg="#6bca21"
                  color="white"
                  _hover={{ bg: "#6bca21" }}
                  onClick={() => handleSaveDay()}>
                  Done
                </Button>
              </Box>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </AddDayContext.Provider>
  );
};

const useAddDayContext = () => useContext(AddDayContext);
export default AddDay;

export { useAddDayContext };
