import React from "react";
import {
  Box,
  Button,
  Heading,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";

import useLang from "hooks/useLang";
import { bool } from "prop-types";
import TemplateImg from "../../assets/routine/ico_template.png";
import PreprogramImg from "../../assets/routine/ico_preprogram.png";

const ModalType = ({ isOpen, onClose }) => {
  const { t } = useLang();

  return (
    <Modal returnFocusOnClose={false} isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("select_type_program")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6} my={10}>
          <Heading textAlign="center" mb="1rem">
            {t("selectToCreate")}
          </Heading>
          <Box
            display="flex"
            flexDirection="row"
            flexWrap="wrap"
            justifyContent="center"
            alignItems="center"
            gridGap={6}>
            <Box
              border="1px solid lightgray"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              padding="2rem 3rem"
              bg="white"
              borderRadius="10px"
              w="18rem"
              minW="16rem"
              style={{ gap: "20px" }}>
              <Text color="#b42519" textAlign="center" fontSize="20px" fontWeight="700">
                {t("template_workout")}
              </Text>
              <Image src={TemplateImg} alt="template program" width="100px" height="100px" />
              <Text color="gray" fontSize="16px">
                {t("create_new_program")}
              </Text>
              <Button
                padding="0.5rem 1rem"
                border="1px solid #b42519"
                color="#b42519"
                borderRadius="18px"
                _hover={{
                  bg: "#b42519",
                  border: "1px solid #b42519",
                  color: "white",
                }}
                to={{
                  pathname: "/user/create",
                  state: {
                    mode: "template",
                  },
                }}
                as={Link}>
                {t("template_workout")}
              </Button>
            </Box>
            <Box
              border="1px solid lightgray"
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              padding="2rem 3rem"
              bg="white"
              borderRadius="10px"
              w="18rem"
              minW="16rem"
              style={{ gap: "20px" }}>
              <Text color="#b42519" textAlign="center" fontSize="20px" fontWeight="700">
                {t("preprogrammed_workout")}
              </Text>
              <Image src={PreprogramImg} alt="template program" width="100px" height="100px" />
              <Text color="gray" fontSize="16px">
                {t("create_new_program")}
              </Text>
              <Button
                padding="0.5rem 1rem"
                border="1px solid #b42519"
                color="#b42519"
                borderRadius="18px"
                _hover={{
                  bg: "#b42519",
                  border: "1px solid #b42519",
                  color: "white",
                }}
                to={{
                  pathname: "/user/create",
                  state: {
                    mode: "preprograms",
                  },
                }}
                as={Link}>
                {t("preprogrammed_workout")}
              </Button>
            </Box>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

ModalType.propTypes = {
  isOpen: bool.isRequired,
  onClose: bool.isRequired,
};

export default ModalType;
