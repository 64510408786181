/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import useLang from "hooks/useLang";
import {
  AbsoluteCenter,
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import DownloadIcon from "@mui/icons-material/Download";
import notFound from "assets/notfound.png";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "react-qr-code";
import ShareIcon from "@mui/icons-material/Share";
import { getClientById } from "services/clients";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { getNutritionByClient } from "services/nutrition";
import styles from "./clientnutrition.module.scss";
import DrawerInformationNutrition from "./DrawerInformationNutrition";
import NutritionPdf from "./NutritionPdf";

const ClientNutrition = ({ clientId }) => {
  const { t, lang } = useLang();
  const [diets, setDiets] = useState([]);
  const trainer = useSelector(({ user }) => user);
  const [client, setClient] = useState({});
  const toast = useToast();
  const [nutritionDetails, setnutritionDetails] = useState({})
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [showRenderPdf, setShowRenderPdf] = useState({
    _id: "",
    state: false,
  });

  const copyClipboard = () => {
    const copyText = document.getElementById("nutrition-url");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard.writeText(copyText.value);
    toast({
      position: "bottom",
      render: () => (
        <Box color="white" p={3} bg="black" textAlign="center">
          {t("copiedClipboard")}
        </Box>
      ),
    });
  };

  const loaderGeneratePdf = (id) => {
    setTimeout(() => {
      setShowRenderPdf({
        _id: id,
        state: true,
      });
    }, 500);
  };

  const loadDiets = async () => {
    try {
      const res = await getNutritionByClient(clientId);
      setDiets(res.diets);
    } catch (error) {
      console.error(error);
    }
  };

  const loadDataClient = async () => {
    try {
      const res = await getClientById(clientId);
      setClient(res.client);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadDiets();
    loadDataClient();
  }, []);

  return (
    <div style={{ marginTop: "1em" }}>
      {diets.length <= 0 && (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
          <Image src={notFound} w="10rem" alt="not found" />
          <Heading fontSize="2xl">
            {t('not_have_diet')}
          </Heading>
        </Box>
      )}
      <div className={styles.container}>
        {diets?.map((diet) => (
          <div key={diet._id} className={styles.box}>
            <div onClick={() => { setnutritionDetails(diet); onOpen() }} className={styles.box_information}>
              <div className={styles.box_flex}>
                <p className={styles.diet__NAME}>{diet.name}</p>
                <p className={styles.badge}>{t(diet.goals)}</p>
              </div>
              <p className={styles.creation}>
                {t("created_at")}:{" "}
                {dayjs(diet.created_at).format(lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY")}
              </p>
            </div>
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ gap: "1em" }}
              alignItems="center">
              {showRenderPdf._id === diet._id && showRenderPdf.state ? (
                <NutritionPdf diet={diet} client={client} trainer={trainer} />
              ) : (
                <Button
                  size="sm"
                  variant="outline"
                  colorScheme="teal"
                  leftIcon={<DownloadIcon fontSize="inherit" />}
                  onClick={() => loaderGeneratePdf(diet._id)}>
                  {t("generasting_pdf")}
                </Button>
              )}
              <Popover>
                <PopoverTrigger>
                  <IconButton
                    size="sm"
                    variant="outline"
                    colorScheme="teal"
                    icon={<ShareIcon fontSize="inherit" />}
                  />
                </PopoverTrigger>
                <Portal>
                  <PopoverContent>
                    <PopoverArrow />
                    <PopoverCloseButton />
                    <PopoverBody>
                      <Text
                        fontWeight="bold"
                        textAlign="center"
                        my={4}
                        fontSize="xs"
                        colorScheme="gray">
                        {t("scanQr")}
                      </Text>
                      <div
                        style={{
                          height: "auto",
                          margin: "0 auto",
                          maxWidth: 120,
                          width: "100%",
                        }}>
                        <QRCode
                          size={256}
                          style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                          value={`${window.location.protocol}//${window.location.host}/shared-nutrition/${diet._id}`}
                          viewBox="0 0 256 256"
                        />
                      </div>
                      <Box position="relative" my={6} p={2}>
                        <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs">
                          {t("orCopyLink")}
                        </AbsoluteCenter>
                      </Box>
                      <Flex gridGap={4} alignItems="center">
                        <Input
                          disabled
                          readOnly
                          value={`${window.location.protocol}//${window.location.host}/shared-nutrition/${diet._id}`}
                          cursor="pointer"
                          id="nutrition-url"
                          fontSize="12px"
                          onClick={copyClipboard}
                        />
                        <IconButton
                          isRound
                          size="sm"
                          colorScheme="red"
                          variant="ghost"
                          onClick={copyClipboard}
                          icon={<ContentCopyIcon fontSize="inherit" />}
                        />
                      </Flex>
                      <Flex my={2} justifyContent="flex-end">
                        <Button
                          leftIcon={<VisibilityIcon fontSize="inherit" />}
                          as="a"
                          href={`${window.location.protocol}//${window.location.host}/shared-nutrition/${diet._id}`}
                          target="_blank"
                          size="sm">
                          Preview
                        </Button>
                      </Flex>
                    </PopoverBody>
                  </PopoverContent>
                </Portal>
              </Popover>
            </Box>
          </div>
        ))}
      </div>
      {isOpen && (
        <DrawerInformationNutrition
          isOpen={isOpen}
          key={nutritionDetails._id}
          onClose={onClose}
          diet={nutritionDetails}
          setDiets={setDiets}
        />
      )}
    </div>
  );
};

export default ClientNutrition;
