/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-bitwise */
/* eslint-disable no-mixed-operators */
/* eslint-disable prefer-const */
/* eslint-disable array-callback-return */
/* eslint-disable import/no-cycle */
/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect, useState, useRef } from "react";
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import CopyIcon from "@material-ui/icons/FileCopy";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import CloseIcon from "@mui/icons-material/Close";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import { useDispatch, useSelector } from "react-redux";
import { createRoutine } from "services/customWorkout";
import { Box, Button, Flex, IconButton, Text, Tooltip, useDisclosure } from "@chakra-ui/react";
import useLang from "hooks/useLang";
import generateId from "helpers/generateId";
import notificationDisplay from "utils/notificationDisplay";
import useTour from "hooks/useTour";
import Step1MainBoard from "assets/videos/Board/Step1_Main_Board.gif";
import Column from "./Column";
import { reorderColumns, reorderExercisesMap, reorderQuoteMap } from "./reorder";
import styles from "../../../../clients/ClientDashboard/ClientRoutineDisplay/ClientRoutineDisplay.module.css";
import Combine from "../../Preprogrammed/components/Combine";
import SuperSet from "../../SuperSet";
import RoutineInfo from "../../Features/RoutineInfo";
import AddDay from "./AddDay/AddDay";

const Board = ({
  isCombineEnabled,
  initial,
  useClone,
  withScrollableColumns,
  keyRefrest,
  client,
  setFiltered,
  clientInfo,
}) => {
  const [columns, setColumns] = useState(initial);
  const [ordered, setOrdered] = useState(Object.keys(initial));
  const { isOpen: isOpenAddDay, onOpen: onOpenAddDay, onClose: onCloseAddDay } = useDisclosure();
  const { _id } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const [listDaySelected, setListDaySelected] = useState([]);
  const [cloneUniqueDay, setCloneUniqueDay] = useState([]);
  const [cloneState, setCloneState] = useState(false);
  const { t } = useLang();
  const [sortWeeks, setSortWeeks] = useState([]);
  const [name, setName] = useState("");
  const scrollHorizontalBoard = useRef(null);
  const [phase, setPhase] = useState("");
  const [inittour, setinittour] = useState("reset");
  const history = useHistory();
  const STEPSMAIN = [
    {
      content: <h2>{t("scratch_steps_1")}</h2>,
      locale: {
        skip: t("skip"),
        next: t("next"),
        back: t("backBtn"),
        last: t("last"),
      },
      placement: "center",
      target: "body",
    },
    {
      placement: "right",
      locale: {
        skip: t("skip"),
        next: t("next"),
        back: t("backBtn"),
        last: t("last"),
      },
      styles: {
        options: {
          width: 300,
        },
      },
      target: "#stepMain-1",
      title: t("scratch_steps_2"),
      content: (
        <div>
          <img src={Step1MainBoard} alt="Step in main board" />
        </div>
      ),
    },
    {
      placement: "bottom",
      locale: {
        skip: t("skip"),
        next: t("next"),
        back: t("backBtn"),
        last: t("last"),
      },
      styles: {
        options: {
          width: 300,
        },
      },
      target: "#stepMain-2",
      title: t("scratch_steps_3"),
    },
    {
      placement: "right",
      locale: {
        skip: t("skip"),
        next: t("next"),
        back: t("backBtn"),
        last: t("last"),
      },
      styles: {
        options: {
          width: 300,
        },
      },
      target: "#stepMain-3",
      title: t("scratch_steps_4"),
    },
    {
      placement: "bottom",
      locale: {
        skip: t("skip"),
        next: t("next"),
        back: t("backBtn"),
        last: t("last"),
      },
      styles: {
        options: {
          width: 300,
        },
      },
      target: "#stepMain-4",
      title: t("scratch_steps_5"),
    },
    {
      placement: "bottom",
      locale: {
        skip: t("skip"),
        next: t("next"),
        back: t("backBtn"),
        last: t("last"),
      },
      styles: {
        options: {
          width: 300,
        },
      },
      target: "#stepMain-5",
      title: t("scratch_steps_6"),
    },
  ];

  const [tour, resetTour, run] = useTour(STEPSMAIN, inittour);
  const { isOpen: isOpenMore, onOpen: onOpenMore, onClose: onCloseMore } = useDisclosure();
  const [superSetState, setSuperSetState] = useState({ data: [], position: 0 });

  const handleCancelClone = () => {
    setListDaySelected([]);
    setCloneState(false);
  };

  const handleChangeSelect = (items) => () => {
    // const reset = { ...items, day: 0, _id: undefined };
    setListDaySelected([
      ...listDaySelected,
      {
        ...items,
        author: {
          ...items.author,
        },
        exercises: items.exercises.map((exercise) => ({
          ...exercise,
          _id: generateId(),
        })),
      },
    ]);
  };

  const handleChangeRemove = (item) => () => {
    setListDaySelected((items) => items.filter((i) => i._id !== item._id));
  };

  const onDragEnd = (result) => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    if (result.type === "EXERCISE") {
      const reorderExercises = reorderExercisesMap({
        quoteMap: columns,
        source,
        destination,
      });
      const arrayOfArrays = Object.values(reorderExercises.quoteMap).map((arr) => [...arr]);
      setColumns(arrayOfArrays);
      setOrdered(Object.keys(reorderExercises.quoteMap));
      return;
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      const sortColumns = reorderColumns({
        columnsMap: columns,
        source,
        destination,
      });
      setOrdered(Object.keys(sortColumns.columnsMap));
      setColumns(sortColumns.columnsMap);
      return;
    }

    const data = reorderQuoteMap({
      quoteMap: columns,
      source,
      destination,
    });

    const arrayOfArrays = Object.values(data.quoteMap).map((arr) => [...arr]);

    setColumns(arrayOfArrays);
    setOrdered(Object.keys(data.quoteMap));
  };
  const cloneWeek = (week) => {
    const arrayOfObjects = columns.reduce((acc, val) => acc.concat(val), []);
    const lastWeek = arrayOfObjects[arrayOfObjects.length - 1];
    const changeWeek = week.map((item) => {
      const copy = {
        ...item,
        week: lastWeek.week + 1,
        _id: generateId(),
        id: generateId(),
        exercises: item.exercises.map((i) => {
          const setIdExercise = {
            ...i,
            _id: generateId(),
            sets: i.sets.map((e) => {
              const newId = {
                ...e,
                _id: generateId(),
              };
              return newId;
            }),
          };
          return setIdExercise;
        }),
      };
      return copy;
    });
    const concatNewDay = columns.concat([changeWeek]);
    setColumns(concatNewDay);
    setOrdered(Object.keys(concatNewDay));
  };
  const handleAddWeek = () => {
    const arrayOfObjects = columns.reduce((acc, val) => acc.concat(val), []);
    const lastWeek = arrayOfObjects[arrayOfObjects.length - 1];
    const lastday = columns[columns.length - 1];
    const generateWeek = lastday.map((item) => ({
      ...item,
      week: lastWeek.week + 1,
      _id: generateId(),
      id: generateId(),
      exercises: item.exercises.map((i) => ({
        ...i,
        _id: generateId(),
        sets: i.sets.map((e) => ({
          ...e,
          _id: generateId(),
        })),
      })),
    }));

    const concatNewDay = columns.concat([[]]);
    setColumns(concatNewDay);
    setOrdered(Object.keys(concatNewDay));
    const ContentMainScroll = scrollHorizontalBoard.current;
    const horizontalScroll = ContentMainScroll.scrollWidth;
    setTimeout(() => {
      ContentMainScroll.scrollTo(horizontalScroll, 0);
    }, 100);
  };

  const handleSave = async () => {
    const routine = {
      id_trainer: _id,
      id_client: client.split("___")[1],
      filterOptions: [[], [], []],
      workout_name: { en: name, es: "" },
      source: "custom",
      preProgrammed: false,
      phase,
      sessions: columns.flat(),
      date: null,
    };

    try {
      const response = await createRoutine(routine);
      if (response.data) {
        return notificationDisplay(dispatch, "something went wrong", "error");
      }

      setTimeout(() => {
        history.push({
          pathname: "/user/clients",
          state: {
            clientInfo,
          },
          programReturn: {
            response: response.customWorkout,
          },
        });
      }, 1000);
    } catch (error) {
      console.log(error.message);
    }
  };

  const validacionSave = () => {
    if (name === "") {
      notificationDisplay(dispatch, "Name is empty", "error");
    }
    if (phase === "") {
      notificationDisplay(dispatch, "Phase is empty", "error");
    }
    if (columns.length <= 0) {
      notificationDisplay(dispatch, t("errors.empty_program"), "error");
    }
  };

  useEffect(() => {
    setColumns(initial);
    setOrdered(Object.keys(initial));
    setSortWeeks(initial.reduce((acc, val) => acc.concat(val), []));
  }, [initial, inittour]);

  if (superSetState.data?.length > 0) {
    return (
      <SuperSet
        setSuperSetState={setSuperSetState}
        superSetState={superSetState}
        setColumns={setColumns}
      />
    );
  }
  const goBack = () => {
    const leavePage = window.confirm(t("back_confirmation"));
    if (leavePage) {
      history.push({
        pathname: "/user/clients",
        state: {
          clientInfo,
        },
      });
    }
  };

  const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
    if (isDraggingOver) return "#FFEBE6";
    if (isDraggingFrom) return "#E6FCF8";
    return "transparent";
  };
  const collapseAndExpandAll = () => {
    const items = Array.from(document.getElementsByClassName("day_content"));
    const spandButton = Array.from(document.getElementsByClassName("spand-button"));
    if (items.length > 0) {
      if (items.some((a) => a.classList.contains("collapsed"))) {
        items.forEach((element) => {
          element.classList.remove("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.remove("btn-collapsed");
        });
      } else {
        items.forEach((element) => {
          element.classList.add("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.add("btn-collapsed");
        });
      }
    }
  };
  const onDragStart = (result) => {
    if (result.type === "DAYS") {
      const items = Array.from(document.getElementsByClassName("day_content"));
      const spandButton = Array.from(document.getElementsByClassName("spand-button"));
      if (items.length > 0) {
        items.forEach((element) => {
          element.classList.add("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.add("btn-collapsed");
        });
      }
    }
  };

  return (
    <>
      <Box>
        <Flex mb="1rem" w="100%" justifyContent="space-between" alignItems="center">
          <Button
            onClick={() => goBack()}
            color="gray"
            size="xs"
            variant="outline"
            leftIcon={<ArrowBackIosNewIcon fontSize="inherit" />}
            name="back">
            {t("backBtn")}
          </Button>
          <Text fontSize="xs">
            {t("creating_program")}
            <span
              style={{
                color: "#b42519",
                fontWeight: "bold",
              }}>
              {` ${clientInfo.name || client.split("___")[0]} ${
                (clientInfo?.lastName || client.split("___")[2]) ?? ""
              }`}
            </span>
          </Text>
          <div />
        </Flex>
        <Flex gridGap="1rem" alignItems="flex-start" className="boardContent">
          <Box className="form-new-program">
            <RoutineInfo setPhase={setPhase} phase={phase} setName={setName} name={name} />
            {name.trim() !== "" && phase.trim() !== "" && columns.length >= 1 ? (
              <Button onClick={() => handleSave()} colorScheme="ptRed" mt="1rem">
                {t("save_program")}
              </Button>
            ) : (
              <Button onClick={() => validacionSave()} colorScheme="ptRed" mt="1rem">
                {t("save_program")}
              </Button>
            )}
          </Box>
          <Box w="100%">
            {tour}
            <Flex justifyContent="space-between" gridGap="10px" flexWrap="wrap" mb={4}>
              <Flex gridGap={2}>
                <Button
                  size="sm"
                  id="stepMain-5"
                  onClick={() =>
                    cloneState
                      ? handleCancelClone()
                      : (setCloneState(true), onDragStart({ type: "DAYS" }))
                  }
                  variant="outline"
                  leftIcon={
                    cloneState ? <CloseIcon fontSize="inherit" /> : <CopyIcon fontSize="inherit" />
                  }>
                  {cloneState ? t("clone_cancel") : t("clone")}
                </Button>
                <Button size="sm" variant="outline" onClick={() => collapseAndExpandAll()}>
                  {t("expand_or_collapse")}
                </Button>
                <Button
                  size="sm"
                  id="stepMain-4"
                  variant="outline"
                  colorScheme="orange"
                  leftIcon={<AddIcon fontSize="inherit" />}
                  onClick={onOpenMore}>
                  {t("more_exercise")}
                </Button>
              </Flex>
              <Tooltip
                hasArrow
                label={run ? "The tour is dissabled" : "Click here for help"}
                bg="black">
                <IconButton
                  isRound
                  onClick={() => resetTour()}
                  colorScheme={run ? "red" : "blue"}
                  size="sm"
                  icon={<QuestionMarkIcon fontSize="inherit" />}
                />
              </Tooltip>
            </Flex>
            <Box className="board-container" ref={scrollHorizontalBoard}>
              <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
                <Droppable droppableId="board" type="COLUMN" direction="horizontal">
                  {(provided, snapshot) => (
                    <div
                      id="stepMain-1"
                      className="board_column"
                      style={{
                        backgroundColor: `${getBackgroundColor(
                          snapshot.isDraggingOver,
                          snapshot.isDraggingFrom,
                        )}`,
                        justifyContent: columns.length === 0 ? "center" : "start",
                        alignItems: columns.length === 0 ? "center" : "start",
                      }}
                      {...provided.droppableProps}
                      ref={provided.innerRef}>
                      {ordered?.map((key, index) => (
                        <Column
                          key={key}
                          index={index}
                          title={key}
                          quotes={columns[key]}
                          isScrollable={withScrollableColumns}
                          isCombineEnabled={isCombineEnabled}
                          useClone={useClone}
                          columns={columns}
                          keyRefrest={keyRefrest}
                          setColumns={setFiltered}
                          setOrdered={setOrdered}
                          scrollHorizontalBoard={scrollHorizontalBoard}
                          cloneState={cloneState}
                          listDaySelected={listDaySelected}
                          handleChangeSelect={handleChangeSelect}
                          handleChangeRemove={handleChangeRemove}
                          handleCancelClone={handleCancelClone}
                          setSuperSetState={setSuperSetState}
                          onCloneWeek={cloneWeek}
                          setCloneUniqueDay={setCloneUniqueDay}
                          cloneUniqueDay={cloneUniqueDay}
                          currentClient={client}
                        />
                      ))}
                      {provided.placeholder}
                      {columns.length === 0 && (
                        <Box>
                          <Button
                            backgroundColor="white"
                            color="#b42519"
                            _hover={{ backgroundColor: "#b42519", color: "white" }}
                            variant="outline"
                            borderRadius="full"
                            p="2em"
                            id="stepMain-3"
                            width="320px"
                            mt={8}
                            onClick={() => onOpenAddDay()}>
                            <AddIcon />
                            {t("create_firts_day")}
                          </Button>
                        </Box>
                      )}
                      {columns.length >= 1 && !cloneState && (
                        <button
                          className={styles.Btn__add_week}
                          type="button"
                          id="stepMain-2"
                          onClick={() => handleAddWeek()}>
                          <Text mr={3}>{t("add_week")}</Text>
                          <AddIcon />
                        </button>
                      )}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            </Box>
          </Box>
        </Flex>
      </Box>

      <Combine
        isOpen={isOpenMore}
        onClose={onCloseMore}
        days={sortWeeks}
        setFiltered={setFiltered}
        client={client}
      />
      <AddDay
        isOpen={isOpenAddDay}
        onClose={onCloseAddDay}
        columns={columns}
        setColumns={setFiltered}
        key={isOpenAddDay}
        setOrdered={setOrdered}
        currentClient={client}
      />
    </>
  );
};

// Board.defaultProps = {
//   isCombineEnabled: false,
// };

// Board.propTypes = {
//   isCombineEnabled: PropTypes.bool,
// };

export default Board;
