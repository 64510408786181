/* eslint-disable no-nested-ternary */
/* eslint-disable no-extra-boolean-cast */
import React from "react";
import { func, shape, string } from "prop-types";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import useLang from "hooks/useLang";
import { Link } from "react-router-dom";
import { Avatar, Badge, Box, Button, Flex, IconButton, Text, Tooltip } from "@chakra-ui/react";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import AccessibilityIcon from "@mui/icons-material/Accessibility";
import FolderSharedIcon from "@mui/icons-material/FolderShared";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import PendingActionsIcon from "@mui/icons-material/PendingActions";
import ClientPlanning from "components/clients/ClientDashboard/ClientPlanning";
import styles from "./ClientLabel.module.css";

const ClientLabel = ({ setTab, tab, client }) => {
  const { t } = useLang();
  const handleClick = ({ target }) =>
    setTab({
      value: target.id,
      isSelected: Boolean(target.id !== "close"),
      client: Boolean(target.id !== "close") ? client : {},
    });

  return (
    <Box className={styles.container__label}>
      <header className={styles.label}>
        <div>
          <Box display="flex" justifyContent="start" alignItems="center" style={{ gap: "10px" }}>
            {tab.isSelected && (
              <Button
                type="button"
                className={styles.close}
                onClick={handleClick}
                id="close"
                fontSize="20px"
                bg="transparent"
                fontWeight="bold"
                minWidth="0px"
                padding="0px"
                _hover="none">
                <ArrowBackIosNewIcon style={{ pointerEvents: "none" }} />
              </Button>
            )}
            <Button
              bg="transparent"
              display="flex"
              style={{ gap: "10px" }}
              border="none"
              _hover={{ color: "lightgray" }}
              type="button"
              onClick={handleClick}
              id="profile"
              className={styles.buttonHeader}>
              <Avatar
                name={`${client.name} ${client?.lastName}`}
                bg="gray"
                color="white"
                alt="deault profile"
                src={client.pic || ""}
                style={{ pointerEvents: "none" }}
                onClick={handleClick}
                id="profile"
                size="sm"
              />
              <Flex gridGap="10px">
                <Text fontSize="1.25rem" onClick={handleClick} id="profile">
                  {client.name} {client?.lastName}
                </Text>
                <Badge
                  onClick={handleClick}
                  id="profile"
                  bg={!client.isGuest ? `${client?.status === "active" ? "#6bca21" : "#b42519"}` : "#f07300"}
                  color="white"
                  borderRadius="20px"
                  padding="0px 6px"
                  fontSize="10px"
                  size="xs"
                  display="flex"
                  alignItems="center">
                  {!client.isGuest ? t(client?.status) : t("guest")}
                </Badge>
              </Flex>
            </Button>
          </Box>
        </div>
        <Flex
          gridGap="10px"
          justifyContent="end"
          alignItems="center"
          className={styles.buttonGroup}>
          <Flex gridGap="10px">
            <Button
              as={Link}
              to={`evaluation/${client.name}___${client._id}`}
              colorScheme="blackAlpha"
              variant="outline"
              size="xs"
              leftIcon={<AccessibilityIcon fontSize="inherit" />}
              borderRadius="20px">
              {t("new_program_assessments")}
            </Button>
            {tab.value !== "programs" && (
              <Button
                colorScheme="blackAlpha"
                size="xs"
                onClick={handleClick}
                id="programs"
                borderRadius="20px"
                leftIcon={<AssignmentOutlinedIcon fontSize="inherit" />}
                disabled={client.isGuest && client?.customWorkouts?.routinecount <= 0}>
                {t("programs")}
              </Button>
            )}
          </Flex>

          <Flex gridGap="10px" justifyContent="end">
            <Tooltip label={t("nutrition")}>
              <IconButton
                id="profile"
                isRound
                colorScheme="gray"
                color="#808080"
                variant="solid"
                aria-label="Done"
                size="sm"
                disabled={client?.customWorkouts?.routinecount <= 0}
                icon={<RestaurantIcon />}
                onClick={() => handleClick({ target: { id: "nutrition" } })}
              />
            </Tooltip>
            <Tooltip label={t("history")}>
              <IconButton
                id="profile"
                isRound
                colorScheme="gray"
                color="#808080"
                variant="solid"
                aria-label="Done"
                size="sm"
                disabled={client?.customWorkouts?.routinecount <= 0}
                icon={<PendingActionsIcon />}
                onClick={() => handleClick({ target: { id: "history" } })}
              />
            </Tooltip>
            <ClientPlanning client={client} />
            {tab.value !== "profile" && (
              <Tooltip label={t("showProfile")}>
                <IconButton
                  id="profile"
                  isRound
                  colorScheme="gray"
                  color="#808080"
                  variant="solid"
                  aria-label="Done"
                  size="sm"
                  icon={<FolderSharedIcon />}
                  onClick={() => handleClick({ target: { id: "profile" } })}
                />
              </Tooltip>
            )}
          </Flex>
        </Flex>
      </header>
    </Box>
  );
};

ClientLabel.propTypes = {
  client: shape({}).isRequired,
  setTab: func.isRequired,
  tab: shape({
    value: string.isRequired,
  }).isRequired,
};

export default ClientLabel;
