/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import generateId from "helpers/generateId";
import useLang from "hooks/useLang";
import moment from "moment";
import { useEffect, useState } from "react";
import isSameDate from "utils/isSameDate";

const useEditWeekValueContext = (initialValue, isEdit) => {
  const [values, setValues] = useState(initialValue);
  const { lang } = useLang();

  useEffect(() => {
    if (isEdit) setValues(initialValue);
  }, [isEdit]);

  const changeDate = (date, newValue) => {
    const newDate = moment(newValue).format();
    setValues((prev) =>
      prev.map((day) => (isSameDate(day, date) ? { ...day, date: newDate } : day)),
    );
  };
  const changeDay = (data, newValue) =>
    setValues((prev) =>
      prev.map((day) => (isSameDate(day, data) ? { ...day, day: newValue } : day)),
    );
  const changeWeek = (data, newValue) =>
    setValues((prev) =>
      prev.map((day) => (isSameDate(day, data) ? { ...day, week: newValue } : day)),
    );
  const changeSession = (data, newValue) =>
    setValues((prev) =>
      prev.map((day) => (isSameDate(day, data) ? { ...day, session: newValue } : day)),
    );
  const addExercise = (date, item) => {
    const exerciseToAdd = {
      _id: generateId(),
      id_exercise: { ...item },
      sets: [
        {
          set_number: 1,
          load: "0",
          reps: "0",
          typeWeight: "kg",
          typeReps: "reps",
        },
      ],
    };

    setValues((prev) =>
      prev.map((value) =>
        isSameDate(value, date)
          ? { ...value, exercises: value.exercises.concat(exerciseToAdd) }
          : value,
      ),
    );
  };

  const changeExercise = (date, item, id) => {
    const dayToChange = values.find((value) => isSameDate(value, date));
    const exerciseToChange = dayToChange.exercises.find((_, idx) => idx === id);
    const exerciseUpdated = { ...exerciseToChange, id_exercise: { ...item } };
    const editedExercise = dayToChange.exercises.map((obj, idx) =>
      idx === id ? exerciseUpdated : obj,
    );
    setValues((prev) =>
      prev.map((day) => (isSameDate(day, date) ? { ...day, exercises: editedExercise } : day)),
    );
  };

  const deleteExercise = (date, id) => {
    const dayToChange = values.find((value) => isSameDate(value, date));
    const exercisesFiltered = dayToChange.exercises.filter((_, idx) => idx !== id);

    const dayModified = { ...dayToChange, exercises: exercisesFiltered };
    setValues((prev) => prev.map((day) => (isSameDate(day, date) ? dayModified : day)));
  };

  const deleteDay = (id) => setValues((prev) => prev.filter(({ _id }) => _id !== id));

  const addRow = (date, id) => {
    /* Finding the day that is being changed. */
    const dayToChange = values.find((value) => isSameDate(value, date));
    /* Finding the exercise that is being changed. */
    const exerciseToChange = dayToChange.exercises.find((_, idx) => idx === id);

    const setToCopy = exerciseToChange.sets.at(-1);
    /* Copying the last set in the array and adding 1 to the set number. */
    const setsModified = exerciseToChange.sets.concat({
      ...setToCopy,
      set_number: setToCopy.set_number + 1,
    });

    /* Copying the exerciseToChange object and adding the setsModified array to it. */
    const exerciseModified = { ...exerciseToChange, sets: setsModified };
    const newExercises = dayToChange.exercises.map((exercise, idx) =>
      idx === id ? exerciseModified : exercise,
    );

    const dayModified = { ...dayToChange, exercises: newExercises };
    setValues((prev) => prev.map((day) => (isSameDate(day, date) ? dayModified : day)));
  };

  const deleteRow = (date, id, set_number) => {
    const dayToChange = values.find((value) => isSameDate(value, date));
    const exerciseToChange = dayToChange.exercises.find((_, idx) => idx === id);

    const setsModified = exerciseToChange.sets
      .filter((set) => set.set_number !== set_number)
      .map((set, idx) => ({ ...set, set_number: idx + 1 }));

    const newExercises = dayToChange.exercises.map((exercise, idx) =>
      idx === id ? { ...exercise, sets: setsModified } : exercise,
    );
    const dayModified = { ...dayToChange, exercises: newExercises };

    setValues((prev) => prev.map((day) => (isSameDate(day, date) ? dayModified : day)));
  };
  const changeNote = (newValue, date) => {
    setValues((prev) =>
      prev.map((day) =>
        isSameDate(day, date) ? { ...day, notes: { ...day.notes, [lang]: newValue } } : day,
      ),
    );
  };
  const changeValue = (targetValue, date, id) => {
    const dayToChange = values.find((value) => isSameDate(value, date));
    const exerciseToChange = dayToChange.exercises.find((_, idx) => idx === id);
    const newSets = exerciseToChange.sets.map((set) =>
      set.set_number === targetValue.set_number ? targetValue : set,
    );
    const newExercises = dayToChange.exercises.map((exercise, idx) =>
      idx === id ? { ...exercise, sets: newSets } : exercise,
    );
    setValues((prev) =>
      prev.map((value) =>
        isSameDate(value, date) ? { ...value, exercises: newExercises } : value,
      ),
    );
  };

  return [
    values,
    {
      changeDate,
      changeDay,
      changeWeek,
      changeSession,
      changeValue,
      deleteRow,
      addRow,
      addExercise,
      changeExercise,
      deleteExercise,
      deleteDay,
      changeNote,
    },
  ];
};

export default useEditWeekValueContext;
