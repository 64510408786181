/* eslint-disable react/prop-types */
/* eslint-disable max-len */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-children-prop */
/* eslint-disable no-lone-blocks */
import React, { useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useDispatch } from "react-redux";
import { AddIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import {
  Button,
  ButtonGroup,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  NumberIncrementStepper,
  NumberDecrementStepper,
  Input,
  IconButton,
  Select,
  Flex,
  Box,
  Checkbox,
  FormControl,
  FormLabel,
} from "@chakra-ui/react";
import propTypes from "prop-types";
import useLang from "hooks/useLang";
import { transformAddProfile } from "utils/transformData";
import { inputStyle, selectStyle } from "theme/commonStyles";
import generateId from "helpers/generateId";
import { uploadFile } from "services/files";
import styles from "./Data.module.scss";
import CustomInput from "../CustomInput";

import notificationDisplay from "../../../utils/notificationDisplay";
import { createTrainer } from "../../../services/trainer";
// import transformData from '../../../utils/transformData';
// import useUploadFileS3 from "../../../hooks/useUploadFileS3";
import TextInput from "../../common/TextInput";

const SPECIALITY_OPTIONS = [
  "personal_trainer",
  "strength_coach",
  "athletic_trainer",
  "sports_coach",
  "other",
];
const JOB_DESCRIPTION_OPTIONS = ["fulltime", "independent", "strength", "other"];

const Data = ({ move, info, set }) => {
  const user_data = localStorage.getItem("user_data");
  const [otherSpeciality, setOtherSpeciality] = useState("");
  const [otherJob, setOtherJob] = useState("");
  const [termCheck, setTermCheck] = useState(false);
  const [loading, setLoading] = useState(false);
  const { lang } = useLang();
  if (user_data) {
    // eslint-disable-next-line no-param-reassign
    info.id_user = JSON.parse(user_data).id_user;
  }
  const [secondCity, setSecondCity] = useState(false);
  const {
    handleSubmit,
    control,
    register,
    watch,
    formState: { errors },
  } = useForm();
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [picture, setPicture] = useState(null);

  // const onPrev = () => move((prev) => prev - 1);

  const onSubmit = async (data) => {
    try {
      const newData = { ...data };
      setLoading(true);
      if (data.speciality === "other") {
        newData.speciality = otherSpeciality;
      }
      if (data.job_description === "other") {
        newData.job_description = otherJob;
      }
      const dataResponse = await createTrainer(transformAddProfile(newData, info.id_user, picture));

      if (dataResponse.data) throw new Error(dataResponse.data.error.message || dataResponse.data);
      set(dataResponse);
      move((prev) => prev + 1);
      setLoading(true);
    } catch (exception) {
      setLoading(false);
      notificationDisplay(dispatch, exception.message, "error");
    }
  };
  const uploadFilePicture = async (file) => {
    try {
      // Get the file extension (e.g., .jpg, .png)
      const fileExtension = file.name.split(".").pop();

      // Create a new file name with the UUID and original extension
      const newFileName = `${generateId()}.${fileExtension}`;

      const reader = new FileReader();
      reader.onload = async () => {
        const newFile = new File([reader.result], newFileName, { type: file.type });
        const formData = new FormData();
        formData.append("imagen", newFile);
        const response = await uploadFile(formData);
        if (response.data) console.log(response);
        setPicture(response.imagen);
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <form onSubmit={handleSubmit(onSubmit)} className={styles.root}>
      <fieldset className={styles.avatarContainer}>
        <div className={styles.avatarWrapper}>
          <img
            className={`${styles.profilePic} pic`}
            src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${picture}`}
            alt=""
          />
          <div
            className={styles.uploadButton}
            onClick={() => document.querySelector(".file").click()}
            role="button"
            tabIndex={0}>
            <i className={`fa fa-arrow-circle-up ${styles.arrow}`} aria-hidden="true" />
          </div>
          <input
            accept="image/*"
            className={`${styles.fileUpload} file`}
            type="file"
            onChange={(e) => uploadFilePicture(e.target.files[0])}
          />
        </div>
      </fieldset>
      <Flex gridGap={4} w="100%">
        <Box w="40%">
          <TextInput
            variant="filled"
            control={control}
            def={info.name || ""}
            name="name"
            place={t("name")}
            rules={{ maxLength: 50 }}
          />
          {errors.name?.type === "maxLength" && (
            <span className={styles.message}>{t("error_max", { num: 50 })}</span>
          )}
        </Box>
        <Box w="40%">
          <TextInput
            variant="filled"
            control={control}
            def={info.lastName || ""}
            name="lastName"
            place={t("lastName")}
            rules={{ maxLength: 50 }}
          />
          {errors.lastName?.type === "maxLength" && (
            <span className={styles.message}>{t("error_max", { num: 50 })}</span>
          )}
        </Box>
        <Box w="20%">
          <Controller
            control={control}
            defaultValue={info.age || "18"}
            name="age"
            render={({ field }) => (
              <CustomInput label={t("age")} required>
                <NumberInput
                  backgroundColor="#ececec"
                  focusBorderColor="ptRed.500"
                  max={70}
                  min={18}
                  mb="1rem"
                  {...field}>
                  <NumberInputField />
                  <NumberInputStepper>
                    <NumberIncrementStepper />
                    <NumberDecrementStepper />
                  </NumberInputStepper>
                </NumberInput>
              </CustomInput>
            )}
          />
        </Box>
      </Flex>
      <Flex w="100%" gridGap={4}>
        <Box w="50%">
          <Controller
            control={control}
            defaultValue={info.gender || ""}
            name="gender"
            render={({ field }) => (
              <CustomInput label={t("gender")} required>
                <Select
                  placeholder={t("select_gender")}
                  {...field}
                  backgroundColor="#ececec"
                  mb="1rem"
                  focusBorderColor="ptRed.500">
                  <option value="male">{t("opt_male")}</option>
                  <option value="female">{t("opt_female")}</option>
                </Select>
              </CustomInput>
            )}
          />
        </Box>
        <Box w="50%">
          <TextInput
            control={control}
            def={info.phone || ""}
            name="phone"
            place={t("phonenumber")}
            rules={{ maxLength: 20, pattern: /[0-9]+/ }}
          />
          {errors.phone?.type === "maxLength" && (
            <span className={styles.message}>{t("error_max", { num: 20 })}</span>
          )}
          {errors.phone?.type === "pattern" && (
            <span className={styles.message}>{t("error_character_number")}</span>
          )}
        </Box>
      </Flex>
      <Box w="100%">
        <Controller
          control={control}
          defaultValue={info.street1 || ""}
          name="street1"
          rules={{ maxLength: 300 }}
          render={({ field }) => (
            <CustomInput label={t("principal_address")} required>
              <div role="group" className={styles.addressBtn}>
                <Input
                  backgroundColor="#ececec"
                  focusBorderColor="ptRed.500"
                  id="street1"
                  mb="1rem"
                  placeholder={t("principal_address")}
                  {...field}
                />
                <IconButton icon={<AddIcon />} onClick={() => setSecondCity(!secondCity)} />
              </div>
              {errors.street1?.type === "maxLength" && (
                <span className={styles.message}>{t("error_max", { num: 300 })}</span>
              )}
            </CustomInput>
          )}
        />
        {secondCity && (
          <TextInput
            control={control}
            def={info.street2 || ""}
            name="street2"
            place={t("secondary_address")}
          />
        )}
      </Box>
      <Box w="100%">
        <TextInput
          control={control}
          def={info.city || ""}
          name="city"
          place={t("city")}
          rules={{ maxLength: 100 }}
        />
        {errors.city?.type === "maxLength" && (
          <span className={styles.message}>{t("error_max", { num: 100 })}</span>
        )}
      </Box>
      <Flex w="100%" gridGap={4}>
        <Box w="50%">
          {" "}
          <TextInput
            control={control}
            def={info.state || ""}
            name="state"
            place={t("state")}
            rules={{ maxLength: 100 }}
          />
          {errors.state?.type === "maxLength" && (
            <span className={styles.message}>{t("error_max", { num: 100 })}</span>
          )}
        </Box>
        <Box w="50%">
          {" "}
          <TextInput
            control={control}
            def={info.zip || ""}
            name="zip"
            place={t("zip")}
            rules={{ pattern: /[0-9]+/ }}
          />
          {errors.zip?.type === "pattern" && (
            <span className={styles.message}>{t("error_character_number")}</span>
          )}
        </Box>
      </Flex>

      <Flex gridGap={4} mb="1rem" w="100%">
        <Box flex="1 1 50%">
          <FormControl>
            <FormLabel>{t("speciality")}</FormLabel>
            <Select variant="filled" {...register("speciality")} {...selectStyle}>
              {SPECIALITY_OPTIONS.map((option) => (
                <option value={option}>{t(option)}</option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box flex="1 1 50%">
          <FormControl>
            <FormLabel>
              {t("describe_another")} {t("speciality")}
            </FormLabel>
            <Input
              disabled={watch("speciality") !== "other"}
              variant="filled"
              type="text"
              value={otherSpeciality}
              onChange={(e) => {
                setOtherSpeciality(e.target.value);
              }}
              {...inputStyle}
            />
          </FormControl>
        </Box>
      </Flex>
      <Flex gridGap={4}>
        <Box flex="1 1 50%">
          <FormControl>
            <FormLabel>{t("job_description")}</FormLabel>
            <Select variant="filled" {...register("job_description")} {...selectStyle}>
              {JOB_DESCRIPTION_OPTIONS.map((option) => (
                <option value={option}>{t(option)}</option>
              ))}
            </Select>
          </FormControl>
        </Box>
        <Box flex="1 1 50%">
          <FormControl>
            <FormLabel>
              {t("describe_another")} {t("job_description")}
            </FormLabel>
            <Input
              disabled={watch("job_description") !== "other"}
              variant="filled"
              type="text"
              value={otherJob}
              onChange={(e) => {
                setOtherJob(e.target.value);
              }}
              {...inputStyle}
            />
          </FormControl>
        </Box>
      </Flex>
      <div className={styles.terms}>
        <Checkbox
          colorScheme="ptRed"
          value={termCheck}
          onChange={() => setTermCheck((prev) => !prev)}
          borderRadius="5px"
          border=".7px solid #b42519"
        />
        <span>
          {t("read_termi")}
          <a
            target="_blank"
            href={
              lang === "en"
                ? "https://perfecttrainerapp.com/terms-and-conditions-agreement/"
                : "https://perfecttrainerapp.com/es/acuerdo-terminos-y-condiciones/"
            }
            className={styles.term_text} rel="noreferrer">
            {t("Terms_conditions")}
          </a>
        </span>
      </div>
      <ButtonGroup className={styles.btnGroup} isAttached>
        {/* <Button
          className={styles.btn}
          colorScheme="ptRed"
          leftIcon={<ArrowBackIcon />}
          onClick={onPrev}>
          {t("prev")}
        </Button> */}
        <Button
          disabled={!termCheck}
          className={styles.btn}
          colorScheme="ptRed"
          type="submit"
          isLoading={loading}>
          {t("continue")}
        </Button>
      </ButtonGroup>
    </form>
  );
};
Data.propTypes = {
  move: propTypes.func.isRequired,
  isCanceled: propTypes.bool.isRequired,
  set: propTypes.func.isRequired,
  info: propTypes.shape({
    age: propTypes.string.isRequired,
    city: propTypes.string.isRequired,
    date: propTypes.string.isRequired,
    gender: propTypes.string.isRequired,
    name: propTypes.string.isRequired,
    job: propTypes.string.isRequired,
    phone: propTypes.string.isRequired,
    street1: propTypes.string.isRequired,
    street2: propTypes.string.isRequired,
    state: propTypes.string.isRequired,
    zip: propTypes.string.isRequired,
    speciality: propTypes.string.isRequired,
    basic: propTypes.shape({
      id_user: propTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};
export default Data;
