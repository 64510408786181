/* eslint-disable guard-for-in */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable consistent-return */
import React, { Children, useEffect, useRef, useState } from "react";
// ? Dependecies
import { bool, shape, string, func } from "prop-types";
import QRCode from "react-qr-code";
import { useHistory } from "react-router-dom";
import { DragDropContext, Droppable } from "react-beautiful-dnd";
import AddIcon from "@mui/icons-material/Add";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import ShareIcon from "@mui/icons-material/Share";
import VisibilityIcon from "@mui/icons-material/Visibility";
import DownloadIcon from "@mui/icons-material/Download";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import {
  AbsoluteCenter,
  Box,
  Button,
  Divider,
  Flex,
  IconButton,
  Input,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverTrigger,
  Portal,
  Select,
  Text,
  useDisclosure,
  useToast,
  Tooltip,
} from "@chakra-ui/react";
import CopyIcon from "@material-ui/icons/FileCopy";
import { useDispatch, useSelector } from "react-redux";
// ? helpers and Services and custom Hooks
import generateId from "helpers/generateId";
import { updateRoutine } from "services/customWorkout";
import WorkOutNewPdf from "utils/workOutNewPdf";
import notificationDisplay from "utils/notificationDisplay";
import useLang from "hooks/useLang";
// ? Components or others
import Column from "./Column";
import { reorderColumns, reorderExercisesMap, reorderQuoteMap } from "./reorder";
import styles from "../../../../clients/ClientDashboard/ClientRoutineDisplay/ClientRoutineDisplay.module.css";
import Combine from "./Combine";

const Board = ({
  children,
  isCombineEnabled,
  initial,
  withScrollableColumns,
  setFiltered,
  routineId,
  sharedFilter,
  setShowRenderPdf,
  updateSharedFilter,
  onOpenCreate,
  showRenderPdf,
  handleUpdateDnd,
  nameWorkout,
  clientInfo,
  setCreatePreprogram,
  setcopyAlertData,
  setProgramData,
  originalData,
  allDataCurrentProgram,
  setVerifyBetween,
  verifyBetween,
}) => {
  // ? states
  const [columns, setColumns] = useState(initial);
  const [ordered, setOrdered] = useState(Object.keys(initial));
  const [listDaySelected, setListDaySelected] = useState([]);
  const [cloneState, setCloneState] = useState(false);
  const [cloneUniqueDay, setCloneUniqueDay] = useState([]);
  const [numberOfWeeks, setNumberOfWeeks] = useState(0);
  const [confirmStateChanged, setconfirmStateChanged] = useState(false);
  const [sortWeeks, setSortWeeks] = useState([]);
  const history = useHistory();
  const [superSetState, setSuperSetState] = useState({ data: [], position: 0 });
  const [name, setName] = useState("");
  const [phase, setPhase] = useState("");
  const datatrainer = useSelector(({ user }) => user);
  const scrollHorizontalBoard = useRef(null);
  const toast = useToast();

  // ? Hooks
  const { t } = useLang();
  const { _id } = useSelector(({ user }) => user);
  const dispatch = useDispatch();
  const { isOpen: isOpenMore, onOpen: onOpenMore, onClose: onCloseMore } = useDisclosure();

  const handleCancelClone = () => {
    setListDaySelected([]);
    setCloneState(false);
  };

  const handleChangeSelect = (items) => () => {
    // const reset = { ...items, day: 0, _id: undefined };
    setListDaySelected([
      ...listDaySelected,
      {
        ...items,
        author: {
          ...items.author,
        },
        exercises: items.exercises.map((exercise) => ({
          ...exercise,
          _id: generateId(),
        })),
      },
    ]);
  };

  const handleCreatePreprogram = () => {
    const preprogram = columns.flat().map((item) => {
      const newPreprogram = {
        day: item.day,
        week: item.week,
        completed: item.completed,
        exercises: item.exercises,
      };
      return newPreprogram;
    });
    setCreatePreprogram(preprogram);
    onOpenCreate();
  };

  const copyClipboard = () => {
    const copyText = document.getElementById("routine-url");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard.writeText(copyText.value);
    toast({
      position: "bottom",
      render: () => (
        <Box color="white" p={3} bg="black" textAlign="center">
          {t("copiedClipboard")}
        </Box>
      ),
    });
  };
  const changeNumberOfWeeks = async (value) => {
    try {
      const response = await updateRoutine(routineId, { sharedFilter: value });
      if (response.data) throw new Error(response.data.error);
      setNumberOfWeeks(value);
      updateSharedFilter();
    } catch (err) {
      console.log(err);
    }
  };

  const handleChangeRemove = (item) => () => {
    setListDaySelected((items) => items.filter((i) => i._id !== item._id));
  };

  const onDragEnd = (result) => {
    // dropped nowhere
    if (!result.destination) {
      return;
    }

    const { source, destination } = result;

    if (result.type === "EXERCISE") {
      const reorderExercises = reorderExercisesMap({
        quoteMap: columns,
        source,
        destination,
      });
      const arrayOfArrays = Object.values(reorderExercises.quoteMap).map((arr) => [...arr]);
      setVerifyBetween(true);
      setcopyAlertData(arrayOfArrays)
      setColumns(arrayOfArrays);
      setOrdered(Object.keys(reorderExercises.quoteMap));
      return;
    }

    // did not move anywhere - can bail early
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return;
    }

    // reordering column
    if (result.type === "COLUMN") {
      const sortColumns = reorderColumns({
        columnsMap: columns,
        source,
        destination,
      });
      setVerifyBetween(true);
      setcopyAlertData(sortColumns.columnsMap)
      setOrdered(Object.keys(sortColumns.columnsMap));
      setColumns(sortColumns.columnsMap);
      return;
    }

    const data = reorderQuoteMap({
      quoteMap: columns,
      source,
      destination,
    });

    const arrayOfArrays = Object.values(data.quoteMap).map((arr) => [...arr]);
    setVerifyBetween(true);
    setcopyAlertData(arrayOfArrays);
    setColumns(arrayOfArrays);
    setOrdered(Object.keys(data.quoteMap));
  };
  const cloneWeek = (week) => {
    const arrayOfObjects = columns.reduce((acc, val) => acc.concat(val), []);
    const lastWeek = arrayOfObjects[arrayOfObjects.length - 1];
    const changeWeek = week.map((item) => {
      const copy = {
        ...item,
        week: lastWeek.week + 1,
        _id: generateId(),
        id: generateId(),
        exercises: item.exercises.map((i) => {
          const setIdExercise = {
            ...i,
            _id: generateId(),
            sets: i.sets.map((e) => {
              const newId = {
                ...e,
                _id: generateId(),
              };
              return newId;
            }),
          };
          return setIdExercise;
        }),
      };
      return copy;
    });
    const concatNewDay = columns.concat([changeWeek]);
    setColumns(concatNewDay);
    setOrdered(Object.keys(concatNewDay));
  };
  const handleAddWeek = () => {
    const arrayOfObjects = columns.reduce((acc, val) => acc.concat(val), []);
    const lastWeek = arrayOfObjects[arrayOfObjects.length - 1];
    const lastday = columns[columns.length - 1];
    const generateWeek = lastday.map((item) => {
      const setItem = {
        ...item,
        week: lastWeek.week + 1,
        _id: generateId(),
        id: generateId(),
        exercises: item.exercises.map((i) => {
          const setIdExercise = {
            ...i,
            _id: generateId(),
            sets: i.sets.map((e) => {
              const newId = {
                ...e,
                _id: generateId(),
              };
              return newId;
            }),
          };
          return setIdExercise;
        }),
      };
      return setItem;
    });

    const concatNewDay = columns.concat([[]]);
    setColumns(concatNewDay);
    setOrdered(Object.keys(concatNewDay));
    const ContentMainScroll = scrollHorizontalBoard.current;
    const horizontalScroll = ContentMainScroll.scrollWidth;
    setTimeout(() => {
      ContentMainScroll.scrollTo(horizontalScroll, 0);
    }, 100);
  };

  const loaderGeneratePdf = (id) => {
    setTimeout(() => {
      setShowRenderPdf({
        _id: id,
        state: true,
      });
      document.getElementById("download-pdf")?.click();
    }, 500);
  };

  const handleSave = () => {
    const daysUpdated = columns.flat().map((obj) => {
      let newObj = { ...obj };
      delete newObj.id;
      delete newObj.author;
      return newObj;
    });
    setconfirmStateChanged(false);
    setVerifyBetween(false);
    handleUpdateDnd(routineId, daysUpdated);
    setProgramData({ ...allDataCurrentProgram, sessions: daysUpdated });
    notificationDisplay(dispatch, t("data_update"), "success");
  };

  const validacionSave = () => {
    if (name === "") {
      notificationDisplay(dispatch, "Name is empty", "error");
    }
    if (phase === "") {
      notificationDisplay(dispatch, "Phase is empty", "error");
    }
    if (columns.length <= 0) {
      notificationDisplay(dispatch, t("errors.empty_program"), "error");
    }
  };

  useEffect(() => {
    setColumns(initial);
    setOrdered(Object.keys(initial));
    setcopyAlertData(initial);
    setSortWeeks(initial.reduce((acc, val) => acc.concat(val), []));
  }, [initial]);

  const getBackgroundColor = (isDraggingOver, isDraggingFrom) => {
    if (isDraggingOver) return "#FFEBE6";
    if (isDraggingFrom) return "#E6FCF8";
    return "transparent";
  };

  useEffect(() => {
    setNumberOfWeeks(sharedFilter);
  }, [sharedFilter]);

  const collapseAndExpandAll = () => {
    const items = Array.from(document.getElementsByClassName("day_content"));
    const spandButton = Array.from(document.getElementsByClassName("spand-button"));
    if (items.length > 0) {
      if (items.some((a) => a.classList.contains("collapsed"))) {
        items.forEach((element) => {
          element.classList.remove("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.remove("btn-collapsed");
        });
      } else {
        items.forEach((element) => {
          element.classList.add("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.add("btn-collapsed");
        });
      }
    }
  };
  const onDragStart = (result) => {
    if (result.type === "DAYS") {
      const items = Array.from(document.getElementsByClassName("day_content"));
      const spandButton = Array.from(document.getElementsByClassName("spand-button"));
      if (items.length > 0) {
        items.forEach((element) => {
          element.classList.add("collapsed");
        });
        spandButton.forEach((element) => {
          element.classList.add("btn-collapsed");
        });
      }
    }
  };

  const keyRefrest = [
    columns?.flat().map((i) => i.day),
    columns?.flat().map((i) => i.week),
    columns?.flat().map((i) => i?.date),
    columns?.flat().map((i) => i?.notes?.es),
    columns?.flat().map((i) => i?.notes?.en),
    columns?.flat().map((i) => i.exercises.map((e) => e.colors.hard)),
    columns?.flat().map((i) => i.exercises.map((e) => e.id_exercise)),
    columns?.flat().map((i) => i.exercises.map((e) => e.sets.map((x) => x.set_number))),
    columns?.flat().map((i) => i.exercises.map((e) => e.sets.map((x) => x.load))),
    columns?.flat().map((i) => i.exercises.map((e) => e.sets.map((x) => x.reps))),
    columns?.flat().map((i) => i.exercises.map((e) => e.sets.map((x) => x.typeWeight))),
    columns?.flat().map((i) => i.exercises.map((e) => e.sets.map((x) => x.typeReps))),
  ];

  const compareArraysObjets = (original, current) => {
    const cleanProperties = current.map((obj) => {
      let newObj = { ...obj };
      delete newObj.id;
      delete newObj.author;
      return newObj;
    });
    if (original.length !== cleanProperties.length) {
      return false;
    }

    for (let i = 0; i < original.length; i++) {
      if (JSON.stringify(original[i]) !== JSON.stringify(cleanProperties[i])) {
        return false;
      }
    }

    return true;
  };

  return (
    <>
      <Box mb={2}>
        <Flex justifyContent="space-between" marginY="10px" flexWrap="wrap" gridGap="10px">
          {Children.map(children, (child) => (
            <>{child}</>
          ))}
        </Flex>
        <Flex justifyContent="space-between" flexWrap="wrap" gridGap="10px">
          <Flex gridGap="10px" flexWrap="wrap">
            <Button
              size="sm"
              onClick={() =>
                cloneState
                  ? handleCancelClone()
                  : (setCloneState(true), onDragStart({ type: "DAYS" }))
              }
              variant="outline"
              leftIcon={
                cloneState ? <CloseIcon fontSize="inherit" /> : <CopyIcon fontSize="inherit" />
              }>
              {cloneState ? t("clone_cancel") : t("clone")}
            </Button>
            <Button size="sm" variant="outline" onClick={() => collapseAndExpandAll()}>
              {t("expand_or_collapse")}
            </Button>

            <Button
              size="sm"
              variant="outline"
              colorScheme="orange"
              leftIcon={<AddIcon fontSize="inherit" />}
              onClick={onOpenMore}>
              {t("more_exercise")}
            </Button>
            <Flex gridGap="10px" flexWrap="wrap">
              {showRenderPdf._id === routineId && showRenderPdf.state ? (
                <WorkOutNewPdf
                  workouts={columns.flat()}
                  key={keyRefrest}
                  nameWorkout={nameWorkout}
                  datatrainer={datatrainer}
                  clientInfo={clientInfo}
                />
              ) : (
                <>
                  {!compareArraysObjets(originalData.flat(), columns.flat()) ||
                  verifyBetween === true ||
                  confirmStateChanged ? (
                    <Tooltip label={t("changes_be_saved")}>
                      <Box>
                        <Button
                          size="sm"
                          variant="outline"
                          colorScheme="teal"
                          disabled
                          leftIcon={<DownloadIcon fontSize="inherit" />}>
                          {t("generasting_pdf")}
                        </Button>
                      </Box>
                    </Tooltip>
                  ) : (
                    <Button
                      size="sm"
                      variant="outline"
                      colorScheme="teal"
                      leftIcon={<DownloadIcon fontSize="inherit" />}
                      onClick={() => loaderGeneratePdf(routineId)}>
                      {t("generasting_pdf")}
                    </Button>
                  )}
                </>
              )}
              <Tooltip label={t("shareProgram")}>
                <Popover>
                  <PopoverTrigger>
                    {!compareArraysObjets(originalData.flat(), columns.flat()) ||
                    verifyBetween === true ||
                    confirmStateChanged ? (
                      <Tooltip label={t("changes_be_saved")}>
                        <Box>
                          <IconButton
                            size="sm"
                            variant="outline"
                            disabled
                            colorScheme="teal"
                            icon={<ShareIcon fontSize="inherit" />}
                          />
                        </Box>
                      </Tooltip>
                    ) : (
                      <IconButton
                        size="sm"
                        variant="outline"
                        colorScheme="teal"
                        icon={<ShareIcon fontSize="inherit" />}
                      />
                    )}
                  </PopoverTrigger>
                  <Portal>
                    <PopoverContent>
                      <PopoverArrow />
                      <PopoverCloseButton />
                      <PopoverBody>
                        <Text
                          fontWeight="bold"
                          textAlign="center"
                          my={4}
                          fontSize="xs"
                          colorScheme="gray">
                          {t("scanQr")}
                        </Text>
                        <div
                          style={{
                            height: "auto",
                            margin: "0 auto",
                            maxWidth: 120,
                            width: "100%",
                          }}>
                          <QRCode
                            size={256}
                            style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                            value={`${window.location.protocol}//${window.location.host}/shared-routine/${routineId}`}
                            viewBox="0 0 256 256"
                          />
                        </div>
                        <Box position="relative" my={6} p={2}>
                          <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs">
                            {t("orCopyLink")}
                          </AbsoluteCenter>
                        </Box>
                        <Flex gridGap={4} alignItems="center">
                          <Input
                            disabled
                            readOnly
                            value={`${window.location.protocol}//${window.location.host}/shared-routine/${routineId}`}
                            cursor="pointer"
                            id="routine-url"
                            fontSize="12px"
                            onClick={copyClipboard}
                          />
                          <IconButton
                            isRound
                            size="sm"
                            colorScheme="red"
                            variant="ghost"
                            onClick={copyClipboard}
                            icon={<ContentCopyIcon fontSize="inherit" />}
                          />
                        </Flex>
                        <Box position="relative" my={6}>
                          <Divider />
                          <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs">
                            {t("showWeeks")}
                          </AbsoluteCenter>
                        </Box>
                        <Select
                          placeholder=""
                          value={numberOfWeeks}
                          onChange={(e) => changeNumberOfWeeks(e.target.value)}>
                          <option value="0">{t("all")} </option>
                          <option value="1">{t("latest")} 1</option>
                          <option value="2">{t("latest")} 2</option>
                          <option value="3">{t("latest")} 3</option>
                          <option value="4">{t("latest")} 4</option>
                          <option value="5">{t("latest")} 5</option>
                          <option value="6">{t("latest")} 6</option>
                          <option value="7">{t("latest")} 7</option>
                          <option value="8">{t("latest")} 8</option>
                          <option value="9">{t("latest")} 9</option>
                          <option value="10">{t("latest")} 10</option>
                        </Select>
                        <Flex my={2} justifyContent="flex-end">
                          <Button
                            leftIcon={<VisibilityIcon fontSize="inherit" />}
                            as="a"
                            href={`${window.location.protocol}//${window.location.host}/shared-routine/${routineId}`}
                            target="_blank"
                            size="sm">
                            Preview
                          </Button>
                        </Flex>
                      </PopoverBody>
                    </PopoverContent>
                  </Portal>
                </Popover>
              </Tooltip>
            </Flex>
          </Flex>
          <Flex gridGap="10px">
            {!compareArraysObjets(originalData.flat(), columns.flat()) ||
            verifyBetween === true ||
            confirmStateChanged ? (
              <Tooltip label={t("changes_be_saved")}>
                <Box>
                  <Button
                    size="sm"
                    variant="outline"
                    colorScheme="red"
                    disabled
                    leftIcon={<SaveIcon fontSize="inherit" />}>
                    {t("create_preprogrammed")}
                  </Button>
                </Box>
              </Tooltip>
            ) : (
              <Button
                size="sm"
                variant="outline"
                onClick={handleCreatePreprogram}
                colorScheme="red"
                leftIcon={<SaveIcon fontSize="inherit" />}>
                {t("create_preprogrammed")}
              </Button>
            )}
            <Button size="sm" colorScheme="green" w="6rem" onClick={() => handleSave()}>
              {t("save")}
            </Button>
          </Flex>
        </Flex>
      </Box>
      <Box
        className="board-container board-container-client"
        overflow="scroll"
        ref={scrollHorizontalBoard}>
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Droppable droppableId="board" type="COLUMN" direction="horizontal">
            {(provided, snapshot) => (
              <div
                className="board_column"
                style={{
                  backgroundColor: `${getBackgroundColor(
                    snapshot.isDraggingOver,
                    snapshot.isDraggingFrom,
                  )}`,
                  justifyContent: columns.length === 0 ? "center" : "start",
                  alignItems: columns.length === 0 ? "center" : "start",
                }}
                {...provided.droppableProps}
                ref={provided.innerRef}>
                {ordered?.map((key, index) => (
                  <Column
                    key={key}
                    index={index}
                    title={key}
                    setVerifyBetween={setVerifyBetween}
                    quotes={columns[key]}
                    cloneState={cloneState}
                    handleChangeSelect={handleChangeSelect}
                    handleChangeRemove={handleChangeRemove}
                    handleCancelClone={handleCancelClone}
                    listDaySelected={listDaySelected}
                    isScrollable={withScrollableColumns}
                    isCombineEnabled={isCombineEnabled}
                    columns={columns}
                    setColumns={setFiltered}
                    setFiltered={setFiltered}
                    setconfirmStateChanged={setconfirmStateChanged}
                    setOrdered={setOrdered}
                    setSuperSetState={setSuperSetState}
                    onCloneWeek={cloneWeek}
                    setCloneUniqueDay={setCloneUniqueDay}
                    cloneUniqueDay={cloneUniqueDay}
                    scrollHorizontalBoard={scrollHorizontalBoard}
                    currentClient={`${clientInfo.name}__${clientInfo._id}`}
                  />
                ))}
                {provided.placeholder}
                <button
                  className={styles.Btn__add_week}
                  type="button"
                  onClick={() => handleAddWeek()}>
                  <Text mr={3}>{t("add_week")}</Text>
                  <AddIcon />
                </button>
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </Box>

      {isOpenMore && (
        <Combine
          isOpen={isOpenMore}
          onClose={onCloseMore}
          days={sortWeeks}
          setFiltered={setFiltered}
          client={`${clientInfo.name}-${clientInfo._id}`}
        />
      )}
    </>
  );
};

Board.propTypes = {
  isCombineEnabled: bool.isRequired,
  initial: shape([]).isRequired,
  containerHeight: bool.isRequired,
  withScrollableColumns: bool.isRequired,
  name: string.isRequired,
  phase: string.isRequired,
  client: shape([]).isRequired,
  setFiltered: func.isRequired,
};

export default Board;
