/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useReducer, useState } from "react";
import { Button, Box, Heading, Badge, Text } from "@chakra-ui/react";
import CommentIcon from "@mui/icons-material/Comment";
import dayjs from "dayjs";
import useLang from "hooks/useLang";
import { superSetColors } from "theme/commonStyles";
import HistoryClientColumn from "./HistoryClientColumn";

const HistoryClientRoutineProvider = ({ trainer, data }) => {
  const { t, lang } = useLang();
  const [isNoteDisplay, toggleNoteDisplay] = useState(false);

  const array = data?.map(({ week }) => week);
  const newarray = [...new Set(array)];

  const dataFilter = newarray?.map((item) =>
    data?.sort((a, b) => a.week - b.week).filter(({ week }) => item === week),
  );

  const orderWeek = Object.keys(dataFilter);

  return (
    <Box w="100%" display="flex" flexDirection="row" overflowX="auto">
      {orderWeek?.map((key, index) => (
        <HistoryClientColumn weeks={dataFilter[key]} index={index} />
      ))}
    </Box>
  );
};

export default HistoryClientRoutineProvider;
