/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import propTypes from "prop-types";
import { VStack, Button, Heading, Flex, Textarea, IconButton, Input, Text } from "@chakra-ui/react";
import { InfoIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { inputStyle } from "theme/commonStyles";
import General from "./pictures/General";

const SingleEvaluation = ({
  title,
  local,
  legRep,
  notes,
  side,
  move,
  radios,
  className,
  general,
}) => {
  const [generalNote, setGeneralNote] = useState(general === undefined ? "" : general[side]);
  const [point, setPoint] = useState(
    radios.map(
      (radio) => notes?.find((note) => note.name === radio.name && note.side === side) || radio,
    ),
  );
  const [reps, setReps] = useState(legRep || 0);
  const { t } = useTranslation();

  const inc = () => {
    if (reps < 20) setReps(reps + 1);
  };
  const dec = () => {
    if (reps > 0) setReps(reps - 1);
  };
  return (
    <Flex py="1rem" gridGap={4} flexWrap="wrap" justifyContent="center">
      <Flex flexDirection="column" w="650px" justifyContent="center" alignItems="center">
        <Heading size="md" textAlign="center" mb={4}>
          {title}
        </Heading>
        <General
          radios={point}
          setRadios={setPoint}
          className={className}
          radiosDefault={notes}
          side={side}
        />
      </Flex>

      <Flex flexDirection="column" w="320px" pb={4} bg="white" border="16px" padding="1rem">
        <VStack align="stretch" mb={4}>
          <label htmlForm="reps" style={{ fontSize: "1.1rem" }}>
            {t("succesful_reps")}
          </label>
          <Flex gridGap={4}>
            <IconButton
              isRound
              icon={<RemoveIcon fontSize="inherit" />}
              onClick={dec}
              disabled={reps <= 0}
            />
            <Input
              variant="filled"
              type="number"
              min={0}
              max={20}
              value={reps}
              onChange={(e) => setReps(e.target.value)}
            />
            <IconButton
              isRound
              icon={<AddIcon fontSize="inherit" />}
              onClick={inc}
              disabled={reps >= 20}
            />
          </Flex>
        </VStack>
        <VStack align="stretch" mb={8}>
          <p>{t("general_notes")}</p>
          <Textarea
            variant="filled"
            value={generalNote}
            onChange={({ target }) => setGeneralNote(target.value)}
          />
        </VStack>
        <Flex justifyContent="space-between" alignItems="center">
          <Button
            disabled={local === 1}
            variant="solid"
            colorScheme="blackAlpha"
            leftIcon={<ArrowBackIosNewIcon />}
            onClick={() => move(point, generalNote, reps, side, local, "prev")}>
            {t("prev_eval")}
          </Button>
          <Text>{local}/11</Text>
          {local === 11 ? (
            <Button
              variant="solid"
              colorScheme="red"
              disabled={reps < 0}
              onClick={() => move(point, generalNote, reps, side, local, "save")}>
              {t("continue")}
            </Button>
          ) : (
            <Button
              variant="solid"
              colorScheme="red"
              rightIcon={<ArrowForwardIosIcon />}
              onClick={() => move(point, generalNote, reps, side, local, "next")}>
              {t("next_eval")}
            </Button>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

SingleEvaluation.propTypes = {
  general: propTypes.string.isRequired,
  move: propTypes.func.isRequired,
  title: propTypes.string.isRequired,
  local: propTypes.number.isRequired,
  legRep: propTypes.number.isRequired,
  notes: propTypes.shape([]).isRequired,
  side: propTypes.string.isRequired,
  radios: propTypes.shape([]).isRequired,
  className: propTypes.shape({}).isRequired,
};

export default SingleEvaluation;
