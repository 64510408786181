/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState, useRef } from "react";

import { useParams } from "react-router-dom";
// ? External Dependencies
import { useTranslation } from "react-i18next";
import {
  Button,
  Menu as MenuLang,
  MenuButton,
  MenuList,
  MenuItem,
  Box,
  Avatar,
  Text,
  Heading,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Flex,
  useToast,
  Divider,
  AbsoluteCenter,
  Input,
  Badge,
  IconButton,
} from "@chakra-ui/react";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import QRCode from "react-qr-code";
import { EditIcon, CloseIcon, CheckCircleIcon } from "@chakra-ui/icons";
// ? local Dependencies
import { getEvaluation } from "services/evaluations";
import InfoIcon from "@mui/icons-material/Info";
import UsaLogo from "assets/eeuu.png";
import SpainLogo from "assets/spain.png";
import useLang from "hooks/useLang";
import languages from "utils/languages";
import { getSharedEvent } from "services/events";
import CalendarScreen from "components/calendar/CalendarScreen";
import i18next from "i18next";
import { useDispatch } from "react-redux";
import { eventLoaded } from "actions/events";
import styles from "./sharedPlanning.module.scss";
import logo from "../../../assets/3.png";

const SharedPlanning = () => {
  const dispatch = useDispatch();
  const { t, lang } = useLang();
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);
  const currentYear = new Date().getFullYear();
  const { isOpen, onOpen, onClose } = useDisclosure();

  const toast = useToast();
  const onLanguageChange = (code) => {
    i18next.changeLanguage(code);
  };
  const onGetEvents = async () => {
    try {
      const response = await getSharedEvent(id);
      if (response.data) throw new Error("something went wrong");
      setData(response);
      dispatch(eventLoaded(response.event));
    } catch (err) {
      console.log(err);
    }
  };
  useEffect(() => {
    onGetEvents();
  }, []);

  const copyClipboard = () => {
    const copyText = document.getElementById("routine-url");

    // Select the text field
    copyText.select();
    copyText.setSelectionRange(0, 99999); // For mobile devices

    navigator.clipboard.writeText(copyText.value);
    toast({
      position: "bottom",
      render: () => (
        <Box color="white" p={3} bg="black" textAlign="center">
          {t("copiedClipboard")}
        </Box>
      ),
    });
  };
  return (
    <section className={styles.root}>
      <header>
        <div className={styles.header}>
          <div onClick={onOpen}>
            <InfoIcon />
          </div>
          <img src={logo} alt="logo" className={styles.logo} />
          <MenuLang>
            <MenuButton as={Button} variant="ghost" fontSize="40px" padding={0} borderRadius="full">
              <Box display="flex" justifyContent="center" alignItems="center">
                <Avatar
                  name="english lang"
                  src={lang === "en" ? UsaLogo : SpainLogo}
                  width="30px"
                  height="30px"
                />
              </Box>
            </MenuButton>
            <MenuList zIndex="101">
              {languages.map(({ code, name, countryCode }) => (
                <MenuItem key={code} color="ptText.500" onClick={() => onLanguageChange(code)}>
                  <span className={`flag-icon flag-icon-${countryCode}`} />
                  <span>{name}</span>
                </MenuItem>
              ))}
            </MenuList>
          </MenuLang>
        </div>
      </header>

      {data.id_client?.status === "inactive" && (
        <div className={styles.content}>
          <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column" gridGap={4}>
            <Text textAlign="center" fontSize="2xl" fontWeight="bold">
              {t('title_disable_link')}
            </Text>
            <Text textAlign="center">
              {t('message_disable_link')}
            </Text>
          </Box>
        </div>
      )}

      {data.id_client?.status !== "inactive" && (
        <div className={styles.content}>
          <Heading size="lg" as="h3">
            {t("clientPlanning", { client: data.client?.name })}{" "}
          </Heading>
          <CalendarScreen client={id} />
        </div>
      )}

      <div className={styles.footer}>
        <div className={styles.term}>
          <a href="#a">{t("privacy_policy")}</a>
          <a href="#a">{t("terms_use")}</a>
          <a href="#a">Legal</a>
        </div>
        <span>
          Copyright © {currentYear} The Institute Of Human Performance | {t("copyright_text")}
        </span>
      </div>
      {/*  */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton
            style={{
              backgroundColor: "white",
              top: "0px",
              right: "0px",
              borderRadius: "50%",
            }}
          />
          <ModalBody my={4}>
            <div className={styles.usersInfo}>
              <div>
                <div className={styles.trainerInfo}>
                  <Avatar
                    size="lg"
                    name={data.trainer?.name || "PT"}
                    src={`${process.env.REACT_APP_FILE_API_URL}/file?file=${data.trainer?.pic !== "" ? data.trainer?.pic : data.trainer?.logo
                      }`}
                  />
                  <div>
                    <span className={styles.badge}>Trainer</span>
                    <Heading size="md" as="h4">
                      {data.trainer?.name || ""}
                    </Heading>
                    <Text>{t(data.trainer?.speciality || "")}</Text>
                  </div>
                </div>
                <Text textAlign="center" fontSize="12px" color="gray">
                  {data.phone}
                </Text>
                <Text textAlign="center" fontSize="12px" color="gray">
                  {data.trainer?.address?.city}, {data.trainer?.address?.state}.{" "}
                  {data.trainer?.address?.street1} {data.trainer?.address?.street2}.{" "}
                  {data.trainer?.address?.zip}{" "}
                </Text>
              </div>
              <Box position="relative" my={8}>
                <Divider />
                <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs" color="gray">
                  {t("clientInfo")}
                </AbsoluteCenter>
              </Box>
              <div>
                <Heading textAlign="center" size="md" as="h4">
                  {data.client?.name} {data.client?.lastName}
                </Heading>
                <Text textAlign="center">{data.client?.email}</Text>
              </div>
            </div>
            <Box position="relative" my={8}>
              <Divider />
              <AbsoluteCenter fontWeight="bold" bg="white" px="4" fontSize="xs" color="gray">
                {t("sharePlanning")}
              </AbsoluteCenter>
            </Box>
            <Text textAlign="center" my={4} fontSize="xs" colorScheme="gray">
              {t("scanQr")}
            </Text>
            <div style={{ height: "auto", margin: "0 auto", maxWidth: 120, width: "100%" }}>
              <QRCode
                size={256}
                style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                value={`${window.location.protocol}//${window.location.host}/shared-planning/${id}`}
                viewBox="0 0 256 256"
              />
            </div>
            <Box position="relative" my={6}>
              <Text textAlign="center" bg="white" px="4" fontSize="xs">
                {t("orCopyLink")}
              </Text>
            </Box>
            <Flex gridGap={4} alignItems="center">
              <Input
                disabled
                readOnly
                value={`${window.location.protocol}//${window.location.host}/shared-planning/${id}`}
                cursor="pointer"
                id="routine-url"
                fontSize="12px"
                onClick={copyClipboard}
              />
              <IconButton
                isRound
                size="sm"
                colorScheme="red"
                variant="ghost"
                onClick={copyClipboard}
                icon={<ContentCopyIcon fontSize="inherit" />}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
    </section>
  );
};

export default SharedPlanning;
