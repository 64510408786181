import { extendTheme } from "@chakra-ui/react";
import Button from "./componentStyles/buttonTheme";
import Input from "./componentStyles/InputTheme";

const theme = extendTheme({
  colors: {
    ptRed: {
      500: "#b42519",
    },
    ptYellow: {
      500: "#d9ad42",
    },
    ptGray: {
      500: "#252625",
    },
    ptText: {
      500: "#444",
    },
    ptBack: {
      500: "#ececec",
    },
  },
  components: {
    Button,
    Input,
  },
});

export default theme;
