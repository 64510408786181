import React, { useEffect, useState } from "react";
import { Switch, Route, useRouteMatch, useHistory } from "react-router-dom";

import { useSelector, useDispatch } from "react-redux";
import useLang from "hooks/useLang";
import Clients from "components/clients";
import Profile from "components/Profile";
import Planning from "components/planning";
import Routines from "components/routines";
import Evaluation from "components/evaluation";
import Exercises from "components/exercises";
import Customer from "components/customer";
import Trainers from "components/trainers";
import Create from "components/create";
import Programs from "components/programs";
import Feedback from "components/Feedback";
import Dashboard from "components/dashboard";
import Stats from "components/stats";
import Management from "components/management";
import zelle from "assets/icons/zelle.png";
import paypal from "assets/icons/paypal.svg";
import Nutrition from "components/nutrition";
import contact from "assets/icons/003-datos-de-contacto.png";
import payment from "assets/icons/002-transferencia-mvil.png";
import validation from "assets/icons/004-ordenador-porttil.png";
import access from "assets/icons/001-control-de-acceso.png";
import { useAuth } from "utils/authContext";
import { deleteInfo } from "reducers/user.reducer";
import { logout } from "reducers/trainer.reducer";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Heading,
  Text,
  Box,
  Button,
  Flex,
  Divider,
  useDisclosure,
  useToast,
} from "@chakra-ui/react";

import { STRIPE_TABLE_IDS, STRIPE_TABLE_IDS_NOTRIAL } from "utils/stripeids";
import styles from "../../components/main/signup/register.module.scss";

const MEMBERSHIPS = ["trialing", "active"];
const User = () => {
  const { path } = useRouteMatch();

  const history = useHistory();
  const dispatch = useDispatch();
  const { t, lang } = useLang();
  const [showSubscriptionModal, setShowSubscriptionModal] = useState(false);
  const [steps, setSteps] = useState(1);
  const { membership = null, id_user, email, role: roleCurrent } = useSelector(({ user }) => user);

  const { isOpen, onOpen, onClose } = useDisclosure();
  const auth = useAuth();

  const toast = useToast();

  useEffect(() => {
    /*   toast({
      title: t("titleMaintenance"),
      description: t("infoMaintenance"),
      status: "info",
      position: "top-right",
      duration: null,
      isClosable: true,
    }); */
    setTimeout(() => {
      const role = localStorage.getItem("role");
      console.log("timeout?", membership);
      setShowSubscriptionModal(!MEMBERSHIPS.includes(membership) && role === "trainer");
    }, 1000);
  }, []);

  const onLogout = () => {
    dispatch(deleteInfo());
    dispatch(logout());
    auth.signout(() => console.log("out"));
    localStorage.removeItem("userAuth");
    history.push("/login");
  };
  return (
    <>
      <Switch>
        <Route path={`${path}/planning`} component={Planning} />
        <Route path={`${path}/dashboard`} component={Dashboard} />
        <Route exact path={`${path}/clients`} component={Clients} />
        <Route exact path={`${path}/profile`} component={Profile} />
        <Route exact path={`${path}/routines`} component={Routines} />
        <Route exact path={`${path}/exercises`} component={Exercises} />
        <Route exact path={`${path}/evaluation/:client_id`} component={Evaluation} />
        <Route exact path={`${path}/evaluation`} component={Evaluation} />
        <Route exact path={`${path}/customer`} component={Customer} />
        <Route exact path={`${path}/trainers`} component={Trainers} />
        <Route exact path={`${path}/create`} component={Create} />
        <Route exact path={`${path}/programs`} component={Programs} />
        <Route exact path={`${path}/programs/:program_type`} component={Programs} />
        <Route exact path={`${path}/feedback`} component={Feedback} />
        <Route exact path={`${path}/stats`} component={Stats} />
        <Route exact path={`${path}/nutrition`} component={Nutrition} />
        {roleCurrent === "admin" && (
          <Route exact path={`${path}/management`} component={Management} />
        )}
      </Switch>
      <Modal closeOnOverlayClick={false} isOpen={showSubscriptionModal} size="6xl">
        <ModalOverlay />
        <ModalContent py={16}>
          <Box mb={8}>
            <Heading textAlign="center">{t("subscribe_now")}</Heading>
            <Text textAlign="center">{t("text_subscription")}</Text>
          </Box>

          <ModalBody>
            {steps === 1 ? (
              <>
                <stripe-pricing-table
                  style={{ width: "90%" }}
                  pricing-table-id={
                    membership === "cancelled"
                      ? STRIPE_TABLE_IDS_NOTRIAL[lang]
                      : STRIPE_TABLE_IDS[lang]
                  }
                  publishable-key={process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}
                  client-reference-id={id_user}
                  customer-email={email}
                  locale={lang}
                />
                <Divider mb={8} />
                <div
                  className={styles.alternativePage}
                  onClick={() => setSteps(2)}
                  aria-hidden="true">
                  <p>{t("alternativePay.buttonAction")}</p>
                  <Flex alignItems="center" gridGap="1rem" justifyContent="center">
                    <img src={zelle} alt="zelle" />
                    <img src={paypal} alt="paypal" />
                  </Flex>
                </div>
                <Divider mt={8} />
                <Text textAlign="center" my={4} fontSize="sm">
                  {t("orYouCan")}
                </Text>
                <Flex justifyContent="center" gridGap={4}>
                  <Button size="sm" variant="ghost" onClick={onLogout}>
                    {t("logout")}
                  </Button>
                  <Button size="sm" colorScheme="blackAlpha" onClick={onOpen}>
                    {t("cancelAccount")}
                  </Button>
                </Flex>
              </>
            ) : null}
            {steps === 2 ? (
              <div>
                <div className={styles.instructionsTitle}>
                  <Heading size="lg" textAlign="center" mb="1rem">
                    {t("alternativePay.instructionsTitle")}
                  </Heading>
                  <p>
                    <b>{t("alternativePay.importantNote")}:</b>{" "}
                    {t("alternativePay.importantNoteText")}
                  </p>
                </div>

                <Flex
                  m={8}
                  className={styles.instructions}
                  gridGap={4}
                  flexDirection="column"
                  flexWrap="wrap"
                  justifyContent="center">
                  <Flex gridGap={4} flexWrap="wrap" justifyContent="center">
                    <div className={styles.itemInstruction}>
                      <span>1</span>
                      <img src={contact} alt="icon" />
                      <b>{t("alternativePay.contact")}</b>
                      <p>
                        {t("alternativePay.contactText")}{" "}
                        <a href="https://wa.link/8cunpd" target="_blank" rel="noreferrer">
                          Whatsapp
                        </a>
                      </p>
                    </div>
                    <div className={styles.itemInstruction}>
                      <span>2</span>
                      <img src={payment} alt="icon" />
                      <b>{t("alternativePay.paymentInformation")}</b>
                      <p>{t("alternativePay.paymentInformationText")} </p>
                    </div>
                  </Flex>
                  <Flex gridGap={4} flexWrap="wrap" justifyContent="center">
                    <div className={styles.itemInstruction}>
                      <span>3</span>
                      <img src={validation} alt="icon" />
                      <b>{t("alternativePay.validation")}</b>
                      <p>{t("alternativePay.validationText")}</p>
                    </div>
                    <div className={styles.itemInstruction}>
                      <span>4</span>
                      <img src={access} alt="icon" />
                      <b>{t("alternativePay.access")}</b>
                      <p>{t("alternativePay.accessText")}</p>
                    </div>
                  </Flex>
                </Flex>
                <Flex gridGap={4} justifyContent="center">
                  <Button onClick={() => setSteps(1)} type="submit" variant="outline">
                    {t("backBtn")}
                  </Button>
                </Flex>
              </div>
            ) : null}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};

export default User;
