/* eslint-disable react/prop-types */

import notificationDisplay from "utils/notificationDisplay";
import { useDispatch, useSelector } from "react-redux";
import { saveNewMeasurement } from "services/evaluations";
import useLang from "hooks/useLang";
import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { useState } from "react";
import MeasurementsForm from "./measurementsForm";

const AddMeasurementModal = ({ client, measurement, getMeasurementsClient }) => {
  const { t } = useLang();
  const [formStep, setFormStep] = useState(5);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const notiDispatch = useDispatch();

  const saveMeasurement = async (data) => {
    try {
      const res = await saveNewMeasurement({ ...data, client: client.split("___")[1] });
      if (res.data) throw new Error(res.data.message);
      notificationDisplay(notiDispatch, t("bio_start"), "success");
      getMeasurementsClient();
      onClose();
    } catch (exception) {
      console.log(exception);
      // notificationDisplay(dispatch, exception.message, "error");
    }
  };
  const handleBack = () => {
    if (formStep !== 1) setFormStep((prev) => prev - 1);
  };
  const handleNext = () => {
    setFormStep((prev) => prev + 1);
  };
  const openModal = () => {
    setFormStep(5);
    onOpen();
  };
  return (
    <>
      <Flex justifyContent="flex-end">
        <Button onClick={openModal} colorScheme="red">
          {t("add_day")}
        </Button>
      </Flex>

      <Modal isOpen={isOpen} onClose={onClose} size="2xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{t("addMeasurement")}</ModalHeader>
          <ModalCloseButton />
          <ModalBody display="flex" justifyContent="center">
            <MeasurementsForm
              measurement={measurement}
              formStep={formStep}
              saveMeasurement={saveMeasurement}
            />
          </ModalBody>

          <ModalFooter>
            <Flex justifyContent="space-between">
              <Flex gridGap={4} justifyContent="flex-end" alignItems="center">
                <Button disabled={formStep === 5} variant="ghost" onClick={handleBack}>
                  {t("backBtn")}
                </Button>
                <Button colorScheme="red" onClick={handleNext}>
                  {t("next")}
                </Button>
              </Flex>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};
export default AddMeasurementModal;
