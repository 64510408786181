/* eslint-disable no-param-reassign */
/* eslint-disable import/named */
/* eslint-disable func-names */
import axios from "axios";

const routes = [
  "perfil",
  "dashboard",
  "todo",
  "trainer",
  "template",
  "template_combined",
  "muscle",
  "preprogrammed",
  "preprogrammed_combined",
  "recomendation",
  "help",
  "upload",
  "exercise",
  "biomechanicalanalysis",
  "customWorkout",
  "client",
  "category",
  "action",
  "nutrition",
  "stylesdiets"
];
// Next we make an 'instance' of it
const instance = axios.create({
  // .. where we make our configurations
  baseURL: process.env.REACT_APP_API_URL_PROD,
});

const refreshTokenService = async () => {
  try {
    const refresToken = localStorage.getItem("refreshToken");
    const response = await instance.post("/auth/refreshToken", { token: refresToken });

    const { token } = response.data;
    localStorage.setItem("userAuth", token);
    return response.data;
  } catch (error) {
    return error.response;
  }
};
// Where you would set stuff like your 'Authorization' header, etc ...

// Also add/ configure interceptors && all the other cool stuff
// Add a request interceptor
instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    if (routes.some((route) => config.url.includes(route))) {
      config.headers.Authorization = localStorage.getItem("userAuth");
    }
    config.headers.language = localStorage.getItem("i18nextLng") || "en";
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
instance.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response;
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    const originalConfig = error.config;

    if (error.response) {
      // Access Token was expired
      if (error.response.status === 500) {
        console.log("500");
        localStorage.removeItem("refreshToken");
        localStorage.removeItem("userAuth");
        localStorage.removeItem("role");
        window.location.reload();
        return Promise.reject(error.response.data);
      }
      console.log("retry?", originalConfig._retry);
      if (error.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;

        try {
          await refreshTokenService();
          return axios(originalConfig);
        } catch (_error) {
          if (_error.response && _error.response.data) {
            return Promise.reject(_error.response.data);
          }

          return Promise.reject(_error);
        }
      }

      if (error.response.status === 403 && error.response.data) {
        localStorage.clear();
        window.location.reload();
        return Promise.reject(error.response.data);
      }
    } else {
      localStorage.removeItem("refreshToken");
      localStorage.removeItem("userAuth");
      localStorage.removeItem("role");
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

export default instance;
