/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/label-has-associated-control */
import { Box, IconButton, Text } from "@chakra-ui/react";
import useLang from "hooks/useLang";

const AmountFood = ({ setSnack, setMeal, setShake, snack, meal, shake }) => {
  const { t } = useLang();
  return (
    <Box
      display="flex"
      justifyContent="space-between"
      bg="white"
      flexDirection="column"
      borderRadius="10px"
      p="1em"
      gridGap={4}>
      <Text fontWeight="bold" fontSize="x-large">{t("nutrition_section.amount_food")}</Text>
      <Box display="flex" gridGap={3} flexDirection="column">
        <Text fontWeight="semibold">{t("nutrition_section.meals_per_day")}</Text>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          gridGap={3}>
          <Box>
            <IconButton
              isRound
              icon={<span>1</span>}
              onClick={() => setMeal(meal === 1 ? 0 : 1)}
              size={meal === 1 ? "lg" : "md"}
              bg={meal === 1 ? "#b42519" : "#f6f6f6"}
              color={meal === 1 ? "white" : "black"}
            />
          </Box>
          <Box>
            <IconButton
              isRound
              icon={<span>2</span>}
              onClick={() => setMeal(meal === 2 ? 0 : 2)}
              size={meal === 2 ? "lg" : "md"}
              bg={meal === 2 ? "#b42519" : "#f6f6f6"}
              color={meal === 2 ? "white" : "black"}
            />
          </Box>
          <Box>
            <IconButton
              isRound
              icon={<span>3</span>}
              onClick={() => setMeal(meal === 3 ? 0 : 3)}
              size={meal === 3 ? "lg" : "md"}
              bg={meal === 3 ? "#b42519" : "#f6f6f6"}
              color={meal === 3 ? "white" : "black"}
            />
          </Box>
          <Box>
            <IconButton
              isRound
              icon={<span>4</span>}
              onClick={() => setMeal(meal === 4 ? 0 : 4)}
              size={meal === 4 ? "lg" : "md"}
              bg={meal === 4 ? "#b42519" : "#f6f6f6"}
              color={meal === 4 ? "white" : "black"}
            />
          </Box>
          <Box>
            <IconButton
              isRound
              icon={<span>5</span>}
              onClick={() => setMeal(meal === 5 ? 0 : 5)}
              size={meal === 5 ? "lg" : "md"}
              bg={meal === 5 ? "#b42519" : "#f6f6f6"}
              color={meal === 5 ? "white" : "black"}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" gridGap={3} flexDirection="column">
        <Text fontWeight="semibold">{t("nutrition_section.snack")}</Text>
        <Box
          display="flex"
          justifyContent="start"
          alignItems="center"
          flexDirection="row"
          gridGap={3}>
          <Box>
            <IconButton
              size={snack === 1 ? "lg" : "md"}
              bg={snack === 1 ? "#b42519" : "#f6f6f6"}
              color={snack === 1 ? "white" : "black"}
              isRound
              icon={<span>1</span>}
              onClick={() => setSnack(snack === 1 ? 0 : 1)}
            />
          </Box>
          <Box>
            <IconButton
              isRound
              icon={<span>2</span>}
              onClick={() => setSnack(snack === 2 ? 0 : 2)}
              size={snack === 2 ? "lg" : "md"}
              bg={snack === 2 ? "#b42519" : "#f6f6f6"}
              color={snack === 2 ? "white" : "black"}
            />
          </Box>
          <Box>
            <IconButton
              isRound
              icon={<span>3</span>}
              onClick={() => setSnack(snack === 3 ? 0 : 3)}
              size={snack === 3 ? "lg" : "md"}
              bg={snack === 3 ? "#b42519" : "#f6f6f6"}
              color={snack === 3 ? "white" : "black"}
            />
          </Box>
          <Box>
            <IconButton
              isRound
              icon={<span>4</span>}
              onClick={() => setSnack(snack === 4 ? 0 : 4)}
              size={snack === 4 ? "lg" : "md"}
              bg={snack === 4 ? "#b42519" : "#f6f6f6"}
              color={snack === 4 ? "white" : "black"}
            />
          </Box>
        </Box>
      </Box>
      <Box display="flex" gridGap={3} flexDirection="column">
        <Text fontWeight="semibold">{t("nutrition_section.protein_skake")}</Text>
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="start"
          alignItems="center"
          gridGap={3}>
          <Box>
            <IconButton
              isRound
              icon={<span>1</span>}
              onClick={() => setShake(shake === 1 ? 0 : 1)}
              size={shake === 1 ? "lg" : "md"}
              bg={shake === 1 ? "#b42519" : "#f6f6f6"}
              color={shake === 1 ? "white" : "black"}
            />
          </Box>
          <Box>
            <IconButton
              isRound
              icon={<span>2</span>}
              onClick={() => setShake(shake === 2 ? 0 : 2)}
              size={shake === 2 ? "lg" : "md"}
              bg={shake === 2 ? "#b42519" : "#f6f6f6"}
              color={shake === 2 ? "white" : "black"}
            />
          </Box>
          <Box>
            <IconButton
              isRound
              icon={<span>3</span>}
              onClick={() => setShake(shake === 3 ? 0 : 3)}
              size={shake === 3 ? "lg" : "md"}
              bg={shake === 3 ? "#b42519" : "#f6f6f6"}
              color={shake === 3 ? "white" : "black"}
            />
          </Box>
          <Box>
            <IconButton
              isRound
              icon={<span>4</span>}
              onClick={() => setShake(shake === 4 ? 0 : 4)}
              size={shake === 4 ? "lg" : "md"}
              bg={shake === 4 ? "#b42519" : "#f6f6f6"}
              color={shake === 4 ? "white" : "black"}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AmountFood;
