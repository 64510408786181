/* eslint-disable react/jsx-boolean-value */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable consistent-return */
/* eslint-disable prefer-const */
/* eslint-disable no-plusplus */
/* eslint-disable no-const-assign */
/* eslint-disable max-len */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
// ? External Dependecies
import {
  Flex,
  Grid,
  Avatar,
  Icon,
  Text,
  Center,
  Button,
  Textarea,
  GridItem,
  Heading,
  Box,
  Select,
  FormControl,
  FormLabel,
  Switch,
  Input,
  Radio,
  RadioGroup,
  Stack,
} from "@chakra-ui/react";

import useLang from "hooks/useLang";
import { EditIcon, CloseIcon, DeleteIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import moment from "moment";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import PhoneIcon from "@mui/icons-material/Phone";
import MailIcon from "@mui/icons-material/Mail";
import WorkIcon from "@mui/icons-material/Work";
import WcIcon from "@mui/icons-material/Wc";
import CakeIcon from "@mui/icons-material/Cake";
import FlagCircleIcon from "@mui/icons-material/FlagCircle";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import StarIcon from "@mui/icons-material/Star";
import EditIcon2 from "@mui/icons-material/Edit";
import { Controller, useForm } from "react-hook-form";
import ClearIcon from "@mui/icons-material/Clear";
import dayjs from "dayjs";

// ? Local Dependencies
import useDelete from "hooks/useDelete";
import { deleteClient } from "services/clients";
import DeleteWithRedirect from "components/common/DeleteWithRedirect";
import DateInput from "components/common/DateInput";
import TextInput from "components/common/TextInput";
import { getClientRoutines } from "services/customWorkout";
import { inputStyle } from "theme/commonStyles";
import GoalIcon from "assets/icons/new_goal.png";

import exercise from "assets/icons/ico_exercise.svg";
import useGetAll from "hooks/useGetAll";
import styles from "../../../Profile/Profile.module.scss";
import InfoClinet from "./infoClient";
import EditClient from "./editClient";
import TextWithIcon from "./TextWithIcon";
import BoxInfoCard from "./BoxInfoCard";
import ClientAssessment from "../ClientAssessment";

const setDirection = (direction) => {
  const string = [];
  if (direction.street1) string.push(`${direction.street1},`);
  if (direction.street2) string.push(`${direction.street2},\n`);
  if (direction.city && direction.state && direction.zip)
    string.push(`${direction.city}, ${direction.state}, ${direction.zip}`);
  return string;
};

const ClientProfile = ({ client, setTab, handleUpdateClient, setClients }) => {
  const [edit, setEdit] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [currentAge, setcurrentAge] = useState("");
  const { data: NumbersRuutine } = useGetAll("routines", client._id, getClientRoutines);
  const handleClose = () => setDialogOpen(false);
  const { lang, t } = useLang();
  const handleOpen = () => setDialogOpen(true);

  // to edit
  const { handleSubmit, control, register, watch, reset } = useForm({
    defaultValues: { isGuest: client.isGuest },
  });
  const onSubmit = (data) => {
    const newData = {
      ...data,
      address: {
        street1: data.street1,
        street2: data.street2,
        city: data.city,
        state: data.state,
        zip: data.zip,
      },
    };
    delete newData.street1;
    delete newData.street2;
    delete newData.city;
    delete newData.zip;
    delete newData.state;

    // console.log(newData)
    handleUpdateClient(client._id, newData);
    /* setTab({
      isSelected: false,
      value: "close",
      client: {},
    }); */
  };

  const getAge = () => {
    let today = new Date();
    let birthDate = new Date(client.birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    let m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) return setcurrentAge(age--);
    setcurrentAge(age);
  };

  useEffect(() => {
    getAge();
  }, []);
  const showPrograms = () => {
    setTab({
      isSelected: true,
      value: "programs",
      client,
    });
  };

  return (
    <Box>
      {!edit ? (
        <>
          <Flex
            marginY={4}
            padding="1.5rem"
            direction="column"
            backgroundColor="white"
            borderRadius="16px">
            <Text textAlign="end" color="gray" fontSize="12px">
              {t("profile_joined")}{" "}
              {dayjs(client?.created_at).format(lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY")}
            </Text>

            <TextWithIcon icon={MailIcon} text={client.email} />
            <TextWithIcon icon={PhoneIcon} text={client.phone} />
            {client.birthdate && (
              <TextWithIcon
                icon={CakeIcon}
                text={`${moment(client.birthdate).format(
                  lang === "en" ? "MM/DD/YYYY" : "DD/MM/YYYY",
                )} (${currentAge} ${t("years_old")})`}
              />
            )}
            {client.address && (
              <TextWithIcon icon={LocationOnIcon} text={setDirection(client.address)} isAddress />
            )}
            {client.speciality && <TextWithIcon icon={FlagCircleIcon} text={client.speciality} />}
            {client.gender && <TextWithIcon icon={WcIcon} text={client.gender} />}
            <Flex gridGap={4} justifyContent="flex-end">
              {client.isGuest && (
                <Button
                  colorScheme="green"
                  onClick={() => {
                    setEdit(true);
                    reset({ isGuest: false });
                  }}>
                  {t("upgradeClient")}
                </Button>
              )}
              <Button
                leftIcon={<EditIcon2 fontSize="inherit" />}
                variant="outline"
                onClick={() => {
                  setEdit(true);
                  reset({ isGuest: client.isGuest });
                }}>
                {t("edit")}
              </Button>
              <Button
                leftIcon={<DeleteIcon fontSize="inherit" />}
                onClick={handleOpen}
                colorScheme="red">
                {t("delete")}
              </Button>
            </Flex>
          </Flex>
          <Flex marginY={4} gridGap={4} align="center" justify="center">
            <ClientAssessment client={client} />
            <Box className="infoCard" onClick={showPrograms} cursor="pointer">
              <BoxInfoCard
                title={t("programs")}
                value={NumbersRuutine?.length || 0}
                icon={exercise}
                pointer
              />
            </Box>
          </Flex>
        </>
      ) : (
        <>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Box>
              {client.isGuest && (
                <FormControl display="flex" alignItems="center">
                  <Switch {...register("isGuest")} id="isGuest" />
                  <FormLabel htmlFor="isGuest" mb="0" mx={4}>
                    {t("isGuest")}
                  </FormLabel>
                </FormControl>
              )}
              <Flex gridGap={4}>
                <TextInput control={control} def={client.name} name="name" place={t("name")} />
                <TextInput
                  control={control}
                  def={client.lastName}
                  name="lastName"
                  place={t("lastName")}
                />
              </Flex>
              <Flex gridGap={4}>
                <TextInput
                  control={control}
                  def={client.phone}
                  name="phone"
                  place={t("phonenumber")}
                />
                <TextInput control={control} def={client.email} name="email" place={t("email")} />
              </Flex>
              {watch("isGuest") === false && (
                <>
                  <Flex gridGap={4} marginBottom="1rem">
                    <DateInput
                      control={control}
                      def={client.birthdate}
                      name="birthdate"
                      place={t("birth_date")}
                      required={false}
                    />
                    <div style={{ width: "100%" }}>
                      <p style={{ marginBottom: "0.5rem" }}>{t("gender")}</p>
                      <Controller
                        control={control}
                        name="gender"
                        defaultValue=""
                        required={false}
                        render={({ field }) => (
                          <Select
                            placeholder={t("select_gender")}
                            backgroundColor="#ececec"
                            focusBorderColor="ptRed.500"
                            color="#444444"
                            {...field}>
                            <option value="male">{t("opt_male")}</option>
                            <option value="female">{t("opt_female")}</option>
                          </Select>
                        )}
                      />
                    </div>
                  </Flex>
                  <TextInput
                    control={control}
                    def={client.address.street1}
                    name="street1"
                    place={t("principal_address")}
                  />

                  <Box flex="1 1 100%">
                    <FormControl>
                      <FormLabel>{t("secondary_address")}</FormLabel>
                      <Controller
                        control={control}
                        defaultValue={client.address.street2}
                        name="street2"
                        render={({ field }) => (
                          <Input variant="filled" type="text" {...field} {...inputStyle} />
                        )}
                      />
                    </FormControl>
                  </Box>

                  <Flex gridGap={4}>
                    <TextInput
                      control={control}
                      def={client.address.city}
                      name="city"
                      place={t("city")}
                      required={false}
                    />

                    <TextInput
                      control={control}
                      def={client.address.state}
                      name="state"
                      place={t("state")}
                      required={false}
                    />
                  </Flex>
                  <Flex gridGap={4}>
                    <Box w="50%">
                      <TextInput
                        control={control}
                        def={client.address.zip}
                        name="zip"
                        place={t("zip")}
                        required={false}
                      />
                    </Box>

                    <Box w="50%" display="flex" flexDirection="column">
                      <label>{t('client_status')}</label>
                      <Box display="flex" gridGap={3}>
                        <Controller
                          control={control}
                          defaultValue={client?.status}
                          name="status"
                          render={({ field }) => (
                            <RadioGroup defaultValue={client?.status ?? "active"} {...field}>
                              <Stack spacing={5} direction='row'>
                                <Radio colorScheme='green' value="active" >
                                  {t('active')}
                                </Radio>
                                <Radio colorScheme='red' value="inactive">
                                  {t('inactive')}
                                </Radio>
                              </Stack>
                            </RadioGroup>
                          )}
                        />
                      </Box>
                    </Box>
                  </Flex>
                  <TextInput
                    control={control}
                    def={client.speciality}
                    name="speciality"
                    place={t("main_goals")}
                    required={false}
                  />
                </>
              )}

              <Flex alignItems="center" justifyContent="flex-end" gridGap={4}>
                <Button variant="outline" colorScheme="gray" onClick={() => setEdit(false)}>
                  {t("cancel")}
                </Button>
                <Button className={styles.btn} colorScheme="ptRed" type="submit">
                  {t("save_changes")}
                </Button>
              </Flex>
            </Box>
          </form>
        </>
      )}
      {dialogOpen && (
        <DeleteWithRedirect
          open={dialogOpen}
          close={handleClose}
          id={client._id}
          title={t("delete_client")}
          func={() => useDelete("clients", deleteClient)}
          setTab={setTab}
          setClients={setClients}
        />
      )}
    </Box>
    // <main className={styles.root}>
    //   <section className={styles.container}>
    //     <div className={styles.options}>
    //       <IconButton icon={edit ? <CloseIcon /> : <EditIcon />} aria-label="edit" onClick={() => setEdit(!edit)} />
    //       {
    //         !edit && (
    //           <IconButton
    //             fontSize="20px"
    //             icon={<DeleteIcon />}
    //             onClick={() => handleOpen()}
    //             variant="solid"
    //             backgroundColor="red"
    //             color="white"
    //             ml={4}
    //           />
    //         )
    //       }
    //     </div>
    // {
    //   (client && edit) && <EditClient setEdit={setEdit} client={client} handleUpdateClient={handleUpdateClient} />
    // }
    //     {
    //       (client && !edit) && <InfoClinet client={client} />
    //     }
    //   </section>
    // </main>
  );
};

export default ClientProfile;
