import { useQueryClient, useMutation } from "react-query";

const useAdd = (word, func) => {
  const queryClient = useQueryClient();
  return useMutation((data) => func(data), {
    onMutate: async (updatedData) => {
      await queryClient.cancelMutations(word);
      console.log("word", word);
      const previous = queryClient.getQueryData(word);
      console.log("previous", previous);
      queryClient.setQueryData(word, (old) => {
        console.log("old", updatedData);
        return [...old, updatedData];
      });
      return { previous };
    },
    onError: async (error, context) => {
      const errorObj = error;
      const errorObjAsJSON = await errorObj.json();
      const { message } = errorObjAsJSON;
      console.log("error", message);
      console.log("error response mutation", error.response);
      queryClient.setQueryData(word, context.previous);
    },
    onSettled: () => {
      queryClient.invalidateQueries(word);
    },
  });
};

export default useAdd;
