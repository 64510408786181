/* eslint-disable import/no-cycle */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
import React, { createContext, useContext, useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  Box,
} from "@chakra-ui/react";
import { bool, func, shape } from "prop-types";
import useLang from "hooks/useLang";
import useEditWeekValueContext from "./useEditWeekValueContext";
import EditWeekContent from "./EditWeekContent";

const EditWeekContext = createContext();

const EditWeek = ({
  isOpen,
  onClose,
  date,
  AiTitle,
  quotes,
  handleUpdateDnd,
  routineId,
  columns,
  setColumns,
}) => {
  const { t } = useLang();
  const [boardWeeks, setBoardWeeks] = useState(quotes);
  // const reducerForColumn = boardWeeks.reduce((acc, val) => acc.concat(val), []);
  // const filterWeek = reducerForColumn.filter(({ week }) => week === AiTitle);

  useEffect(() => {
    setBoardWeeks(quotes);
  }, [columns]);

  const isEdit = true;
  const [values, obj] = useEditWeekValueContext(boardWeeks, isEdit);

  const handleSaveEdit = () => {
    const filterWeeks = columns.filter(({ week }) => week !== AiTitle);
    const reducerForValues = values.reduce((acc, val) => acc.concat(val), []);
    const editedWeeks = [...filterWeeks, ...reducerForValues];
    console.log("edited all", editedWeeks);
    // setBoardWeeks([]);
    // onClose();
  };

  const showButton = values.map((day) => day.exercises.length > 0).every(Boolean);

  return (
    <EditWeekContext.Provider
      value={{
        ...obj,
      }}>
      <Modal
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={() => {
          onClose();
          setBoardWeeks([]);
        }}>
        <ModalOverlay />
        <ModalContent borderTop="13px solid #b42519" borderRadius={10}>
          <ModalHeader>
            <p>
              {" "}
              Edit week <span style={{ color: "#b42519" }}>{AiTitle}</span>
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box>
              {values.map((day) => (
                <EditWeekContent
                  inicialValue={day}
                  isEdit={isEdit}
                  date={{ day: day.day, week: day.week }}
                />
              ))}
              {showButton && (
                <Button
                  type="submit"
                  bg="#6bca21"
                  color="white"
                  _hover={{ bg: "#6bca21" }}
                  float="right"
                  onClick={() => handleSaveEdit()}>
                  Save
                </Button>
              )}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </EditWeekContext.Provider>
  );
};

const useEditWeekContext = () => useContext(EditWeekContext);
export default EditWeek;

export { useEditWeekContext };
