/* eslint-disable prefer-const */
/* eslint-disable no-param-reassign */
/* eslint-disable no-plusplus */
/* eslint-disable max-len */
/* eslint-disable import/no-cycle */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import React, { createContext, useContext, useRef, useState } from "react";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Box,
  useDisclosure,
  AlertDialogOverlay,
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogBody,
  AlertDialogFooter,
} from "@chakra-ui/react";
import { bool, func, shape } from "prop-types";
import AddIcon from "@mui/icons-material/Add";
import MoreTemplateDays from "components/programs/MoreTemplatesDays";
import useLang from "hooks/useLang";
import generateId from "helpers/generateId";
import useAddDayValueContext from "./useAddDayValueContext";
import DayContent from "./DayContent";

const AddDayContext = createContext();

const AddDay = ({
  isOpen,
  onClose,
  titleHeader,
  quotes,
  columns,
  setColumns,
  isEdit,
  toggleEdit,
  columnScroll,
  scrollHorizontalBoard,
  thelastday: lastday,
}) => {
  const { isOpen: isOpenContent, onOpen: onOpenContent, onClose: onCloseContent } = useDisclosure();
  const {
    isOpen: isOpenTemplates,
    onOpen: onOpenTemplates,
    onClose: onCloseTemplates,
  } = useDisclosure();
  const [openDialog, setopenDialog] = useState(false);
  const { t } = useLang();
  const mainDiv = useRef(null);

  const inicialValue =
    columns.length === 0
      ? [
          {
            author: {
              id: generateId(),
              name: 1,
              colors: {
                soft: "white",
                hard: "white",
              },
            },
            _id: generateId(),
            id: generateId(),
            completed: false,
            date: null,
            day: 1,
            session: "",
            week: 1,
            notes: {
              en: "",
              es: "",
            },
            categoryList: [],
          },
        ]
      : [
          {
            author: {
              ...lastday?.author,
              id: generateId(),
              name: 1 + lastday?.week,
              colors: {
                soft: "white",
                hard: "white",
              },
            },
            _id: generateId(),
            id: generateId(),
            completed: false,
            date: null,
            day: lastday,
            session: "",
            week: titleHeader,
            notes: {
              en: "",
              es: "",
            },
            categoryList: [],
          },
        ];

  const [values, obj] = useAddDayValueContext(inicialValue, isEdit);
  const dateInfo =
    columns.length === 0
      ? { day: 1, week: 1 }
      : { day: inicialValue[0].day, week: inicialValue[0].week };

  const handleSaveDay = () => {
    if (columns.length === 0) {
      toggleEdit();
      const arrValues = [values];
      setColumns(arrValues);
    } else {
      toggleEdit();
      let copyDays = [...quotes];
      let weekColumn = [...columns];
      const arrPosition = titleHeader - 1;
      let cocatValues = copyDays.concat(values);
      weekColumn.splice(arrPosition, 1, cocatValues);
      setColumns(weekColumn);
      const ContentListColumn = columnScroll.current;
      const ContentMainScroll = scrollHorizontalBoard.current;
      setTimeout(() => {
        const height = ContentListColumn.scrollHeight;
        const horizontalScroll = ContentMainScroll.scrollLeft;
        ContentMainScroll.scrollTo(horizontalScroll, 0);
        ContentListColumn.scrollTo(0, height);
      }, 100);
    }
    onClose();
  };

  const handleConfirmClose = () => {
    console.log(values);
    if (values[0]?.categoryList.length <= 0) {
      onClose();
      return;
    }
    if (quotes !== values[0]) {
      setopenDialog(true);
      return;
    }
    onClose();
  };

  return (
    <AddDayContext.Provider
      value={{
        ...obj,
      }}>
      <Modal
        closeOnOverlayClick={false}
        returnFocusOnClose={false}
        isOpen={isOpen}
        onClose={() => handleConfirmClose()}
        size="xl"
        scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent borderTop="13px solid #b42519" borderRadius={10}>
          <ModalHeader>
            <p>
              {t("add_day")} <span style={{ color: "#b42519" }}>{lastday}</span> {t("in_week")}{" "}
              <span style={{ color: "#b42519" }}>{titleHeader}</span>
            </p>
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody ref={mainDiv}>
            <Box>
              {values.map((day) => (
                <DayContent
                  inicialValue={day}
                  isEdit={isEdit}
                  date={dateInfo}
                  mainDiv={mainDiv}
                  isOpen={isOpenContent}
                  onClose={onCloseContent}
                />
              ))}
            </Box>
          </ModalBody>
          <ModalFooter display="flex" justifyContent="space-between" marginTop={2} marginBottom={2}>
            <Button
              color="gray"
              border="1px solid #E3E3E3"
              borderRadius="8px"
              bg="transparent"
              _hover={{ color: "#b42519", border: "1px solid #b42519" }}
              leftIcon={<AddIcon />}
              onClick={onOpenContent}>
              {t("add_category")}
            </Button>
            <Button
              color="gray"
              border="1px solid #E3E3E3"
              borderRadius="8px"
              bg="transparent"
              _hover={{ color: "#b42519", border: "1px solid #b42519" }}
              onClick={onOpenTemplates}>
              {t("more_template")}
            </Button>
            {values.map((day) => (
              <>
                {day.categoryList.length >= 1 && (
                  <Button
                    type="submit"
                    bg="#6bca21"
                    color="white"
                    _hover={{ bg: "#6bca21" }}
                    onClick={() => handleSaveDay()}>
                    {t("save")}
                  </Button>
                )}
              </>
            ))}
          </ModalFooter>
        </ModalContent>
      </Modal>
      {openDialog && (
        <AlertDialog isOpen={openDialog} onClose={() => setopenDialog(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold" textAlign="center">
                {t("delete_confirmation")}
              </AlertDialogHeader>
              <AlertDialogBody>{t("confirm_save_or_discard")}</AlertDialogBody>
              <AlertDialogFooter>
                <Box
                  display="flex"
                  flexDirection="column"
                  w="full"
                  gridGap={2}
                  justifyContent="center"
                  alignItems="center">
                  <Button w="70%" border="1px solid #b42519" onClick={() => setopenDialog(false)}>
                    {t("backBtn")}
                  </Button>
                  <Button
                    w="70%"
                    bg="#b42519"
                    color="white"
                    _hover={{ bg: "white", border: "1px solid #b42519", color: "black" }}
                    onClick={() => onClose()}>
                    {t("discard")}
                  </Button>
                  <Button
                    w="70%"
                    bg="#6bca21"
                    color="white"
                    _hover={{ bg: "#6bca21" }}
                    onClick={() => handleSaveDay()}>
                    {t("save")}
                  </Button>
                </Box>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      )}
      {isOpenTemplates && (
        <MoreTemplateDays
          isOpen={isOpenTemplates}
          onClose={onCloseTemplates}
          onCloseMain={onClose}
          key={isOpenTemplates}
          days={quotes}
          lastday={lastday}
          currentWeek={titleHeader}
          columns={columns}
          setFiltered={setColumns}
        />
      )}
    </AddDayContext.Provider>
  );
};

const useAddDayContext = () => useContext(AddDayContext);
export default AddDay;

export { useAddDayContext };
