import React from 'react'
import useLang from 'hooks/useLang'
import { Box, Button } from '@chakra-ui/react'
import DownloadIcon from "@mui/icons-material/Download";
import NutritionEnglish from '../../assets/workbooks/10-4 complete outline FINAL.pdf'
import WorkoutEnglish from '../../assets/workbooks/10-4 English STUDY GUIDE FINAL Dec 29.pd.pdf'
import NutritionSpanish from '../../assets/workbooks/10-4 MENÚs 2021-Abr-07.pdf'
import WorkoutSpanish from '../../assets/workbooks/10-4 WORKBOOK 2021-Abr-07.pdf'

const Workbooks = () => {
    const { t, lang } = useLang()
    const downloadPdfNutrition = () => {
        const link = document.createElement("a");
        link.href = lang === "en" ? NutritionEnglish : NutritionSpanish; // Ruta al archivo PDF en la carpeta public
        link.download =
            lang === "en" ? "10-4 complete outline FINAL.pdf" : "10-4 MENÚs 2021-Abr-07.pdf"; // Nombre con el que se descargará el archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const downloadPdfWorkout = () => {
        const link = document.createElement("a");
        link.href = lang === "en" ? WorkoutEnglish : WorkoutSpanish; // Ruta al archivo PDF en la carpeta public
        link.download =
            lang === "en" ? "10-4 English STUDY GUIDE FINAL Dec 29.pd.pdf" : "10-4 WORKBOOK 2021-Abr-07.pdf"; // Nombre con el que se descargará el archivo
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };
    return (
        <Box display="flex" gridGap={7} flexDirection="column" justifyContent="center" alignItems="center">
            <Button
                mt={4}
                leftIcon={<DownloadIcon fontSize="inherit" />}
                variant="solid"
                colorScheme="ptRed"
                onClick={() => downloadPdfNutrition()}>
                {t('plan_menus')}
            </Button>
            <Button
                mt={4}
                leftIcon={<DownloadIcon fontSize="inherit" />}
                variant="solid"
                colorScheme="ptRed"
                onClick={() => downloadPdfWorkout()}>
                {t('plan_10_pounds_4_weeks')}
            </Button>
        </Box>
    )
}

export default Workbooks
