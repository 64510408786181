/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
import { useProgramContext } from "context/ProgramContext";
import {
  Box,
  Image,
  InputGroup,
  InputLeftElement,
  Input,
  Skeleton,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { deleteTemplate, getTemplates } from "services/template";
import { deletePreprogrammed, getPreprogrammed } from "services/preProgrammed";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import FirstPageIcon from "@mui/icons-material/FirstPage";
import LastPageIcon from "@mui/icons-material/LastPage";
import {
  categoriesOptions,
  bodyPartOptions,
  equipmentOptions,
} from "components/create/Features/options";
import { SearchIcon } from "@chakra-ui/icons";
import useLang from "hooks/useLang";
import List from "./List";
import styles from "./Main.module.css";
import Preview from "./Preview";

const Main = () => {
  const { page, handleCurrentRecord, isError } = useProgramContext();
  const { lang, t } = useLang();
  const [name, setName] = useState("");
  const [options, setOptions] = useState([[], [], []]);
  const [currentPage, setCurrentPage] = useState(1);
  const [nPages, setNPages] = useState(0);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    document.title = "Perfect Trainer App | Programs";
  }, []);

  const handleChange = (info, actionMeta) => {
    const filteredData = info.map((item) => item.value);
    handleCurrentRecord({ id: null });
    if (actionMeta.name === "category")
      setOptions((prev) => prev.map((item, id) => (id === 0 ? filteredData : item)));
    if (actionMeta.name === "bodyParts")
      setOptions((prev) => prev.map((item, id) => (id === 1 ? filteredData : item)));
    if (actionMeta.name === "equipment")
      setOptions((prev) => prev.map((item, id) => (id === 2 ? filteredData : item)));
  };
  const handleDeleteProgram = async (id) => {
    if (page.mode === "userPreprograms") {
      const response = await deletePreprogrammed(id);
    } else {
      const response = await deleteTemplate(id);
    }
    setData((prev) => prev.filter((item) => item._id !== id));
    /* onClose(); */
  };

  const getProgram = async (indexPage) => {
    try {
      setLoading(true);
      const op = options.flat().filter((a) => a !== "" || a !== null);
      console.log(op);
      let resp = [];
      if (page.mode === "preprograms" || page.mode === "userPreprograms") {
        resp = await getPreprogrammed({
          lang,
          name,
          options: op,
          page: indexPage,
          user: page.mode === "userPreprograms" ? page.owner : null,
        });
        setData(resp.preProgrammedWorkouts);
      }
      if (page.mode === "template" || page.mode === "userTemplate") {
        resp = await getTemplates({
          lang,
          name,
          options: op,
          page: indexPage,
          user: page.mode === "userTemplate" ? page.owner : null,
        });
        setData(resp.templates);
      }
      setNPages(resp.lastPage !== 0 ? resp.lastPage : 1);
      setCurrentPage(resp.page);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };
  const goToNextPage = () => {
    if (currentPage !== nPages) getProgram(currentPage + 1);
  };
  const goToPrevPage = () => {
    if (currentPage !== 0) getProgram(currentPage - 1);
  };
  const goToFirstPage = () => {
    if (currentPage !== 0) getProgram(0);
  };
  const goToLastPage = () => {
    if (currentPage !== nPages) getProgram(nPages - 1);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      getProgram();
    }, 600);

    return () => clearTimeout(delayDebounceFn);
  }, [name]);
  useEffect(() => {
    getProgram();
  }, [options]);
  useEffect(() => {
    getProgram();
  }, [page.mode]);

  if (isError) return <p className={styles.message}>Something wrong...</p>;
  return (
    <div className={styles.container}>
      {(page.type === "list" || page.type === "preview") && (
        <>
          <div className={styles.search}>
            <InputGroup className={styles.searchInput} colorScheme="red" w="44%">
              <InputLeftElement>
                <SearchIcon />
              </InputLeftElement>
              <Input
                variant="outline"
                placeholder={t("search")}
                colorScheme="red"
                value={name}
                onChange={({ target }) => setName(target.value)}
              />
            </InputGroup>
            <div className={styles.filters} style={{ zIndex: 200 }}>
              <Box minWidth="12rem">
                <Select
                  isMulti
                  name="category"
                  options={categoriesOptions.map((item) => ({
                    value: item.value,
                    label: t(item.label),
                  }))}
                  colorScheme="red"
                  placeholder={t("select_category")}
                  variant="filled"
                  classNamePrefix="select"
                  className="basic-multi-select"
                  onChange={handleChange}
                />
              </Box>
              <Box minWidth="12rem">
                <Select
                  isMulti
                  name="equipment"
                  options={equipmentOptions.map((item) => ({
                    value: item.value,
                    label: t(item.label),
                  }))}
                  colorScheme="red"
                  placeholder={t("select_implements")}
                  variant="filled"
                  classNamePrefix="select"
                  className="basic-multi-select"
                  onChange={handleChange}
                />
              </Box>
              <Box minWidth="12rem">
                <Select
                  isMulti
                  name="bodyParts"
                  options={bodyPartOptions.map((item) => ({
                    value: item.value,
                    label: t(item.label),
                  }))}
                  colorScheme="red"
                  placeholder={t("select_bodypart")}
                  variant="filled"
                  classNamePrefix="select"
                  className="basic-multi-select"
                  onChange={handleChange}
                />
              </Box>
            </div>
          </div>
          <div className={styles.content}>
            <div>
              {!loading ? (
                <List
                  page={page}
                  data={data}
                  options={options}
                  name={name}
                  handleDeleteProgram={handleDeleteProgram}
                />
              ) : (
                <Flex w="100%" flexDirection="column" gridGap={4}>
                  <Skeleton height="5rem" w="100%" />
                  <Skeleton height="5rem" w="100%" />
                  <Skeleton height="5rem" w="100%" />
                  <Skeleton height="5rem" w="100%" />
                  <Skeleton height="5rem" w="100%" />
                </Flex>
              )}

              <nav>
                <ul className="pagination">
                  <li>
                    <IconButton
                      isRound
                      variant="ghost"
                      colorScheme="blackAlpha"
                      aria-label="Done"
                      fontSize="20px"
                      disabled={currentPage === 0}
                      icon={<FirstPageIcon />}
                      onClick={goToFirstPage}
                    />
                  </li>
                  <li>
                    <IconButton
                      isRound
                      variant="ghost"
                      colorScheme="blackAlpha"
                      aria-label="Done"
                      fontSize="20px"
                      disabled={currentPage === 0}
                      icon={<ArrowBackIosIcon />}
                      onClick={goToPrevPage}
                    />
                  </li>
                  <li>
                    {currentPage + 1}/{nPages}
                  </li>
                  <li>
                    <IconButton
                      isRound
                      variant="ghost"
                      colorScheme="blackAlpha"
                      aria-label="Done"
                      fontSize="20px"
                      disabled={currentPage + 1 === nPages}
                      icon={<ArrowForwardIosIcon />}
                      onClick={goToNextPage}
                    />
                  </li>
                  <li>
                    <IconButton
                      isRound
                      variant="ghost"
                      colorScheme="blackAlpha"
                      aria-label="Done"
                      fontSize="20px"
                      disabled={currentPage + 1 === nPages}
                      icon={<LastPageIcon />}
                      onClick={goToLastPage}
                    />
                  </li>
                </ul>
              </nav>
            </div>

            {page.type === "preview" && <Preview />}
            {page.type === "list" && (
              <div className={styles.contentPreview}>
                <span>{t("select_routine_word")}</span>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
};

export default Main;
